import React from "react";
import TermsConditionsTemplate from "../Templates/TermsConditionsTemplate";
import CoachBgImg from "../../../assets/Coach/Section4_BgImg.jpg";

const CoachSection4 = () => {
  return (
    <TermsConditionsTemplate expertType="Coach" bgImg={CoachBgImg} />
  );
};

export default CoachSection4;
