import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import sampleImage from "../../../assets/Carrer/careerImage.png"
import Dataset from './DataSet';
import img from "../../../assets/MainMenu/section18image.png";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Section3 = () => {
    const [expandedCard, setExpandedCard] = useState(1);
    const { ref, inView } = useInView({
        triggerOnce: false, // Trigger animation only once
        threshold: 0.25 // Trigger when 50% of the section is in view
    });

    const handleCardClick = (cardIndex) => {
        if (expandedCard === cardIndex) {
            setExpandedCard(0);
        } else {
            setExpandedCard(cardIndex);
        }
    };

    return (
        <motion.section
            ref={ref}
            className={`w-full p-4 bg-[#EFF2FB] ${inView ? 'animate__animated animate__fadeInUp' : ''}`}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
            transition={{ ease: 'easeInOut', duration: 0.5 }}
        >
            <div className="max-w-screen-xl mx-auto bg-[#EFF2FB]">
                <h1 className='text-lg md:text-xl lg:text-3xl xl:text-5xl px-10 pt-8 xl:px-16 text-blue-600 font-bold'>Title</h1>
                <div className="grid grid-cols-12 my-12 md:gap-2 px-4 md:px-12 lg:px-20 lg:gap-4 xl:gap-6">
                    {/* Left Section */}
                   
                    {/* Right Section */}
                    <div className="col-span-12 md:col-span-7 p-4">
                        {/* Cards */}
                        <div className="space-y-4">
                            {Dataset.map(card => (
                                <motion.div
                                    key={card.id}
                                    className={`px-2 py-2 md:px-4 md:py-4  lg:px-6 lg:py-6 xl:px-8 xl:py-8 border-l-4 ${expandedCard === card.id ? 'border-black' : 'border-transparent'} border-b p-4transition-all duration-1000 ease-in-out`} style={{ transition: 'border-color 0.7s ease-in-out' }}
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
                                    transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
                                >
                                    <div className="flex items-center justify-between cursor-pointer" onClick={() => handleCardClick(card.id)}>
                                        <h4 className="text-lg md:text-2xl lg:text-3xl font-semibold mb-4"><span className='text-black font-bold'>{card.title}</span></h4>
                                        <FontAwesomeIcon icon={expandedCard === card.id ? faAngleUp : faAngleDown} />
                                    </div>
                                    <div className={expandedCard === card.id ? 'opacity-100 max-h-96 overflow-hidden transition-opacity duration-1000 ease-in' : 'opacity-0 max-h-0 overflow-hidden transition-opacity duration-1000 ease-in-out'}>
                    {/* Card Details */}
                    <p className='md:text-md lg:text-lg' style={{ lineHeight: '140%' }}>{card.description}</p>
                  </div>
                                    
                                </motion.div>
                            ))}
                        </div>
                    </div>
                     <div className="col-span-12 md:col-span-5 p-2">
                        <img src={sampleImage} alt="Sample" className="" />
                    </div>
                </div>
            </div>
        </motion.section>
    );
};

export default Section3;
