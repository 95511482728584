import img1 from "../../../assets/Publication/Card1.png";
import img2 from "../../../assets/Publication/Card2.png";
import img3 from "../../../assets/Publication/Card3.png";
import img4 from "../../../assets/Publication/Card4.png";
import img5 from "../../../assets/Publication/Card5.png";
import img6 from "../../../assets/Publication/Card6.png";

const publicationData = {
  topic: "Envision Infinite Publishing Horizons",
  items: [
    {
      img: img1,
      title: "Institute Magazines",
    },

    {
      img: img2,
      title: "TED Talks",
    },
    {
      img: img3,
      title: "Newsletters",
    },
    {
      img: img4,
      title: "Acitivty Books",
    },
    {
      img: img5,
      title: "Reports",
    },
    {
      img: img6,
      title: "eBooks",
    },
  ],
};

export default publicationData;
