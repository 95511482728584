import React from "react";
import img_1 from "../../../assets/ThinkSkillIndia/img_18.jpg";
import img_2 from "../../../assets/ThinkSkillIndia/img_19.jpg";

const data = {
  title1: (
    <>
      Guidance for
      <span className="text-yellow-400"> Growth </span>is our
      <span className="text-yellow-400"> core principal </span>
    </>
  ),
  title2: (
    <>
      Comprehensive skill management through an integrated Intelligent Intuitive
      platform
    </>
  ),
  desc: (
    <>
      <span className="text-yellow-400"> Growth </span>is the goal and with our
      help, we can move you closer to that{" "}
      <span className="text-yellow-400"> goal. </span>
      <br />
      <br />
      <span className="text-yellow-400"> Our entire business </span> is built on
      the premise that we have the expertise to
      <span className="text-yellow-400"> help growth. </span>
    </>
  ),
};

const Section15 = () => {
  return (
    <div className="w-full flex flex-col font-semibold xl:gap-12 lg:gap-10 md:gap-8 gap-4 bg-blue-500 h-full text-white xl:py-24 lg:py-20 md:py-16 py-8 xl:px-28 lg:px-24 md:px-20 px-4 ">
      <div className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-center  ">
        {data.title1}
      </div>
      <div className="xl:text-4xl lg:text-3xl md:text-2xl text-xl text-center ">
        {data.title2}
      </div>
      <div className="flex flex-col md:flex-row xl:gap-8 lg:gap-6 md:gap-4 gap-2">
        <img
          src={img_1}
          className="w-full xl:w-[40vw] lg:w-[40vw] md:w-[40vw] rounded-3xl "
        />
        <div className="flex flex-col  xl:gap-8 lg:gap-6 md:gap-4">
          <div className="xl:text-2xl lg:text-xl md:text-lg text-lg ">{data.desc}</div>
          <img src={img_2} className="w-full object-cover rounded-3xl mt-0 md:mt-12" />
        </div>
      </div>
    </div>
  );
};

export default Section15;
