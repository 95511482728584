
import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../About Hpds/imageCarousel.css"
import sectionImage from '../../../assets/HPDSxEngine/Section8image1.png'; // Import your section image

import img1 from "../../../assets/HPDSxEngine/Section8image2.png";
import img2 from "../../../assets/HPDSxEngine/Section8image3.png";
import img3 from "../../../assets/HPDSxEngine/Section8image4.png";
import img4 from "../../../assets/HPDSxEngine/Section8image5.png";
import img5 from "../../../assets/HPDSxEngine/Section8image6.png";
import img6 from "../../../assets/HPDSxEngine/Section8image7.png";
import img7 from "../../../assets/HPDSxEngine/Section8image7.png";
import { useInView } from 'react-intersection-observer';

const Section8 = () => {
    const dataset = [
        {
            image_url: img1,
            title: <span className='text-blue-500'>Title 1</span>,
            description:
                "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skills.",
        },
        {
            image_url: img2,
            title: <span className='text-green-500'>Title 2</span>,
            description:
                "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skills.",
        },
        {
            image_url: img3,
            title: <span className='text-yellow-500'>Title3</span>,
            description:
                "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skills.",
        },
        {
            image_url: img4,
            title: <span className='text-red-500'>New Students Development Paradiagram</span>,
            description:
                "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skills.",
        },
        {
            image_url: img5,
            title: <span className='text-blue-500'>Increase academic success of students</span>,
            description:
                "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skill.",
        },
        {
            image_url: img6,
            title: <span className='text-green-500'>Improving collage and skills Readiness</span>,
            description:
                "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skill.",
        },
        {
            image_url: img7,
            title: <span className='text-yellow-500'>Improve Students Success</span>,
            description:
                "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skill.",
        },
    ];

    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const innerContainerRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [animateText, setAnimateText] = useState(false);
    const { ref, inView } = useInView();
    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                transition: { duration: 0.6, ease: "easeInOut" , delay:0.2 }
            });
        }
    }, [controls, inView]);

    useEffect(() => {
        const innerContainer = innerContainerRef.current;

        const handleScroll = () => {
            const scrollPosition = innerContainer.scrollTop;
            const containerHeight = innerContainer.clientHeight;
            const totalHeight = innerContainer.scrollHeight;

            if (scrollPosition + containerHeight >= totalHeight) {
                innerContainer.scrollTop = 0;
                setCurrentItemIndex((prevIndex) => (prevIndex + 1) % dataset.length);
                setIsLoaded(false);
            }
        };

        innerContainer.addEventListener('scroll', handleScroll);
        return () => {
            innerContainer.removeEventListener('scroll', handleScroll);
        };
    }, [dataset.length]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoaded(true);
        }, 500);

        return () => clearTimeout(timeout);
    }, [currentItemIndex]);

    useEffect(() => {
        if (isLoaded) {
            setAnimateText(true);
            const timeout = setTimeout(() => {
                setAnimateText(false);
            }, 1000); // Adjust the duration as needed
            return () => clearTimeout(timeout);
        }
    }, [isLoaded]);
    return (
        <motion.section
            ref={ref}
            className=" py-4 md:py-12 px-4 md:px-0"
            initial={{ opacity: 0, y: 80 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
        >
            <div className="text-center mb-8">
                <img src={sectionImage} alt="Section Image" className="mx-auto max-w-full mb-4" />
                <p className="md:text-lg lg:text-xl xl:text-2xl font-semibold  -mt-8"><span className='text-blue-500' style={{ lineHeight: '140%' }}>Discover the HPDSx</span> experiences built <span className='text-blue-500'>to bring</span> Your <span className='text-blue-500'>favourite</span> HPDSx <span className='text-blue-500'>tools to life.</span> </p>
            </div>
            <div className="text-center">
                <p className="text-lg md:text-lg lg:text-xl xl:text-2xl  mb-4 px-4 md:px-12 lg:px-24 xl:px-40">The <span className='text-blue-500' style={{ lineHeight: '140%' }}>HPDSx is helpful</span> not only in describing how <span className='text-blue-500'>LEARNERS</span> are developing, but also <span className='text-blue-500'>in predicting health, education, emotional and social outcomes. ​ HPDSx</span> data are a <span className='text-blue-500'>key component of Transforming Human Potential Development Systems (HPDS).</span> which is an initiative to <span className='text-blue-500'>guide learner</span> efforts to improve <span className='text-blue-500'>Bridging the gap between </span> the <span className='text-blue-500'>“Talent” and the “Employer”</span>  systems.
                    This helps them to reach adulthood and work environment and ready to succeed.​</p>
            </div>

            <div className="md:px-4 lg:px-8 xl:px-32 pb-8  xl:py-16 lg:py-14 md:py-4">
                <div className="flex-col justify-center items-center gap-2 md:gap-5 flex xl:px-28 lg:px-20 md:px-12">

                </div>
                <div className="flex flex-col md:flex-row gap-4 py-8 md:py-16">
                    {/* Left Section for Images */}
                    <div className="w-full md:w-2/5   md:px-6 lg:px-8 xl:px-10  " >
                        <img
                            src={isLoaded ? dataset[currentItemIndex].image_url : ""}
                            alt={``}
                            className={`w-full object-contain mt-10 lg:mt-0  h-60 md:h-80 lg:h-full  ${isLoaded ? "fade-in" : ""}`}
                        />
                    </div>

                    {/* Right Section for Text, Description, and Progress Bar */}
                    <div className="w-full md:w-3/5 relative" ref={innerContainerRef} style={{ overflowY: 'scroll', height: window.innerWidth >= 1024 ? '500px' : window.innerWidth >= 768 ? '400px' : '300px', scrollbarWidth: 'none', '-ms-overflow-style': 'none' }}>
                        <div className >
                            <div className={` mt-12 md:mt-0 mx-12 text-md md:text-xl lg:text-2xl xl:text-4xl font-bold ${isLoaded ? 'slide-in' : ''} ${animateText ? 'fade-in' : ''}`}>
                                {isLoaded && dataset[currentItemIndex].title}
                            </div>
                            <div className={` mx-12 text-sm md:text-md lg:text-lg xl:text-xl text-gray-600 my-4 ${isLoaded ? 'slide-in' : ''} ${animateText ? 'fade-in' : ''}`}>
                                {isLoaded && dataset[currentItemIndex].description}
                            </div>
                        </div>

                        {/* Vertical Progress Bar */}
                        <div className="absolute top-10 right-200 h-full flex flex-col items-center" >
                            {/* Big Circle */}
                            <div className="w-9 h-9 bg-white border border-4 border-gray-200 rounded-full relative">
                                <div className="w-3 h-3 bg-blue-500 rounded-full absolute top-2 left-2"></div>
                            </div>

                            {/* Small Dots and Connecting Lines */}
                            {dataset.map((item, index) => (
                                <div key={index} className="flex flex-col items-center">
                                    {index !== 0 && index < 2 ? (
                                        <div className="w-4 h-4 rounded-full bg-blue-500 mb-4"></div>
                                    ) : null}

                                    {/* Connecting Line (except for the last item) */}
                                    {index < 1 && (
                                        <div className="w-[2px]  h-[200px] md:h-[300px] lg:h-[400px] bg-blue-500">
                                            {index === 0 ? (
                                                <hr className="border-dotted border-2 border-white h-full" />
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </motion.section>
    );
};

export default Section8;
