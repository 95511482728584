import React from "react";
import LeftSideImage from "../Templates/LeftSideImage";
import smmain from "../../../assets/NonProfitOrganisation/Section1_image.png";

const Section1 = () => {
  const data = {
    title: (
      <>
        Enhance Your Staff capabilities and effectiveness in areas such as
        leadership, management, fundrasing, or program development
      </>
    ),
    description: (
      <>
        With our resources and expertise, we aim to empower nonprofit
        professionals to excel in their roles, driving greater impact and
        success for their organizations' missions.
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <LeftSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl} 
      />
    </div>
  );
};

export default Section1;
