import React from "react";
import LandingCover from "../../components/Volunteer/LandingCover";
import VolunteerSection1 from "../../components/Volunteer/Section1";
import VolunteerSection3 from "../../components/Volunteer/Section3";
import VolunteerSection4 from "../../components/Volunteer/Section4";
import VolunteerSection2 from "../../components/Volunteer/Section2";
import VolunteerSection5 from "../../components/Volunteer/Section5";

export default function Volunteer() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <VolunteerSection1 />
      <VolunteerSection2 />
      <VolunteerSection3 />
      <VolunteerSection4 />
      <VolunteerSection5 />
    </div>
  );
}
