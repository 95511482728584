import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./imageCarousel.css"

import img1 from "../../../assets/About Hpds/section4Img1.png";
import img2 from "../../../assets/About Hpds/section4Img2.png";
import img3 from "../../../assets/About Hpds/section4Img3.png";
import img4 from "../../../assets/About Hpds/section4Img4.png";
import img5 from "../../../assets/About Hpds/section4Img5.png";

const Section4 = () => {
  const dataset = [
    {
      image_url: img1,
      title: "Subject Choice",
      description:
        "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skills.",
    },
    {
      image_url: img2,
      title: "Skill Choice",
      description:
        "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skills.",
    },
    {
      image_url: img3,
      title: "Career Choice",
      description:
        "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skills.",
    },
    {
      image_url: img4,
      title: "Learning Choice",
      description:
        "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skills.",
    },
    {
      image_url: img5,
      title: "Entrepreneur Choice",
      description:
        "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skill.",
    },
  ];

  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const innerContainerRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [animateText, setAnimateText] = useState(false);

  useEffect(() => {
    const innerContainer = innerContainerRef.current;

    const handleScroll = () => {
      const scrollPosition = innerContainer.scrollTop;
      const containerHeight = innerContainer.clientHeight;
      const totalHeight = innerContainer.scrollHeight;

      if (scrollPosition + containerHeight >= totalHeight) {
        innerContainer.scrollTop = 0;
        setCurrentItemIndex((prevIndex) => (prevIndex + 1) % dataset.length);
        setIsLoaded(false);
      }
    };

    innerContainer.addEventListener('scroll', handleScroll);
    return () => {
      innerContainer.removeEventListener('scroll', handleScroll);
    };
  }, [dataset.length]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, [currentItemIndex]);

  useEffect(() => {
    if (isLoaded) {
      setAnimateText(true);
      const timeout = setTimeout(() => {
        setAnimateText(false);
      }, 1000); // Adjust the duration as needed
      return () => clearTimeout(timeout);
    }
  }, [isLoaded]);

  return (
    <div className="px-3 pb-8  xl:py-16 lg:py-14 md:py-10">
      <div className="flex-col justify-center items-center gap-2 md:gap-5 flex xl:px-28 lg:px-20 md:px-12">
        <div className="text-center text-stone-900 text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold xl:mx-36">
          Co-creating the digital building blocks:
        </div>
        <div className="opacity-60 text-center text-stone-900 text-sm md:text-lg lg:text-2xl xl:text-3xl font-semibold xl:-mx-6">
          Five building blocks for Human Potential Development Transformation
        </div>
      </div>
      <div className="flex gap-4 py-16">
        {/* Left Section for Images */}
        <div className="w-2/5  xl:px-10 lg:px-8 md:px-6" >
          <img
            src={isLoaded ? dataset[currentItemIndex].image_url : ""}
            alt={``}
            className={` mt-10 lg:mt-0  h-60 md:h-80 lg:h-full  ${isLoaded ? "fade-in" : ""}`}
          />
        </div>

        {/* Right Section for Text, Description, and Progress Bar */}
        <div className="w-3/5 p-8 relative" ref={innerContainerRef} style={{ overflowY: 'scroll', height: window.innerWidth >= 1024 ? '500px' : window.innerWidth >= 768 ? '400px' : '300px' , scrollbarWidth: 'none', '-ms-overflow-style': 'none'}}>
          <div className >
            <div className={` mx-12 text-md md:text-xl lg:text-2xl xl:text-4xl font-bold ${isLoaded ? 'slide-in' : ''} ${animateText ? 'fade-in' : ''}`}>
              {isLoaded && dataset[currentItemIndex].title}
            </div>
            <div className={` mx-12 text-sm md:text-md lg:text-lg xl:text-xl text-gray-600 my-4 ${isLoaded ? 'slide-in' : ''} ${animateText ? 'fade-in' : ''}`}>
              {isLoaded && dataset[currentItemIndex].description}
            </div>
          </div>

          {/* Vertical Progress Bar */}
          <div className="absolute top-10 right-200 h-full flex flex-col items-center" >
            {/* Big Circle */}
            <div className="w-9 h-9 bg-white border border-4 border-gray-200 rounded-full relative">
              <div className="w-3 h-3 bg-blue-500 rounded-full absolute top-2 left-2"></div>
            </div>

            {/* Small Dots and Connecting Lines */}
            {dataset.map((item, index) => (
              <div key={index} className="flex flex-col items-center">
                {index !== 0 && index < 2 ? (
                  <div className="w-4 h-4 rounded-full bg-blue-500 mb-4"></div>
                ) : null}

                {/* Connecting Line (except for the last item) */}
                {index < 1 && (
                  <div className="w-[2px]  h-[200px] md:h-[300px] lg:h-[400px] bg-blue-500">
                    {index === 0 ? (
                      <hr className="border-dotted border-2 border-white h-full" />
                    ) : null}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
