import React from "react";

const Grid = ({ title, data }) => {
  return (
    <div className="flex flex-col items-center justify-center md:py-5 lg:w-8/12 w-11/12 mx-auto bg-white">
      <div className="py-10 items-start text-center mx-auto w-full">
        <div className="font-bold text-4xl">{title}</div>
      </div>

      <div className="grid sm:grid-cols-2 md:grid-cols-3  gap-x-10 gap-y-10 mx-auto mt-10 ">
        {data.map((item, index) => (
          <div key={index} className="box-border  ">
            <img
              src={item.img}
              alt={item.title}
              className="w-full h-auto object-cover rounded-md"
            />
            <p className="font-semibold py-2 px-2">&bull; {item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Grid;
