const DatasetSec7 = [
    {
      id: 1,
      description: <>We are <span className='text-red-500 font-bold'>Consuming</span> Too Much of Information​</>
    },
    {
      id: 2,
      description: <>Consuming large volumes of information <span className='text-red-500 font-bold'>Tires our Mind</span></>
    },
    {
      id: 3,
      description: <>Information overdose may <span className='text-red-500 font-bold'>Trigger Anxiety</span></>
    },
    {
      id: 4,
      description: <>Too much data is <span className='text-red-500 font-bold'>Stressing us out</span></>
    },
    {
      id: 5,
      description: <>Creates a psychological <span className='text-red-500 font-bold'>Levelling Effect</span></>
    }
  ];
  
  export default DatasetSec7;