import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import backgroundImage from '../../../assets/HPDSxMenu/Section9imagebg.png';

const Section8 = () => {
    // Use useInView to detect when the section is in view
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.05,
    });

    return (
        <div className="relative">
            {/* Background Image */}
            <div
                className="absolute inset-0 bg-cover bg-center"
                style={{ backgroundImage: `url(${backgroundImage})` }}
            ></div>

            {/* Content */}
            <div className="relative z-10 flex flex-col items-center justify-center py-8 md:py-4 lg:py-8 xl:py-16">
                {/* Motion-div for the animated text */}
                <motion.div
                    initial={{ opacity: 0, y: 80 }} // Initial animation state (text sliding from bottom)
                    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }} // Trigger animation when section is in view
                    transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }} // Animation transition properties
                    ref={ref} // Assign the ref obtained from useInView
                    className="text-white leading-relaxed text-center text-2xl md:text-3xl md:mx-12 lg:mx-20 xl:mx-32" // Styling for the text
                    style={{ lineHeight: '140%' }} // Additional styling for line-height
                >
                    Globally Competent Students investigate the world as Human Potential Development Scientist
                </motion.div>
            </div>
        </div>
    );
};

export default Section8;
