import React from 'react'
import img1 from '../../../assets/Imagecreate_eve_hero.jpg'
import img2 from '../../../assets/Imagecreate_eve_hero-1.jpg'
import img3 from '../../../assets/Imagecreate_eve_hero-2.jpg'
import img4 from '../../../assets/Imagecreate_eve_hero-3.jpg'
import img5 from '../../../assets/Imagecreate_eve_hero-4.jpg'

const HeroSection = () => {
  return (
    <div className='createEvents_hero_bg flex justify-center h-[85vh] '>
      <div className="lg:w-10/12 md:w-11/12 flex lg:gap-5 md:gap-2">

        <div className="grid grid-rows-2 lg:gap-4 md:gap-2 grid-flow-col lg:w-5/12 md:w-6/12 pb-16 items-end">
          <div className='md:py-20 py-7'>
            <div className='text-white text-3xl -rotate-90 my-auto'>
              Create
            </div>
          </div>
          <div className="row-span-1 col-span-1 lg:w-48 md:w-32"><img src={img2} alt="study" /></div>
          <div className="row-span-2 col-span-2"><img src={img1} alt="girl" /></div>
        </div>
        <div className="grid grid-rows-2 grid-flow-col lg:gap-5 md:gap-2 lg:w-7/12 md:w-6/12 pb-16 h-6/12 items-end justify-center">
          <div className="col-span-3 flex flex-col ">
            <div className='text-white text-lg md:text-xl  lg:text-2xl xl:text-3xl font-semibold'>Events</div>
            <div className='text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl  font-semibold'>Launch your Event, Grow your Audience, Spark Influence</div>
            <div className='text-white lg:text-sm md:text-xs'>Start an impactful event, nurture a dedicated audience, and craft experiences that resonate, creating a community for long-term influence and connection-building.</div>
          </div>
          <div className="col-span-1"><img src={img3} alt="study" /></div>
          <div className="col-span-1"><img src={img4} alt="study" /></div>
          <div className="col-span-1"><img src={img5} alt="study" /></div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection