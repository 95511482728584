import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import imgURL from "../../../assets/ThinkSkillIndia/img1_sec9.png";

const data = {
  item1: (
    <>
      Explore how <span className="text-blue-500">THINKSKILLINDIA</span> is
      Empowering youth to Achieve more with AI​
    </>
  ),
  item2: imgURL,
};

const Section9 = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  return (
    <motion.div
      className="w-full h-full xl:p-16 lg:p-12 md:p-8 p-4"
      ref={ref}
      initial={{ opacity: 0, x: -70 }}
      animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -70 }}
      transition={{ duration: 0.9, ease: "easeInOut", delay: 0.4 }}
    >
      <motion.div
        className="font-bold xl:text-5xl lg:text-4xl md:text-3xl text-2xl"
        initial={{ opacity: 0, x: -70 }}
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -70 }}
        transition={{ duration: 1, ease: "easeInOut", delay: 0.3 }}
      >
        {data.item1}
      </motion.div>
      <motion.img
        src={data.item2}
        className="w-full h-full xl:my-12 lg:my-8 md:my-4 my-4 rounded-2xl"
        initial={{ opacity: 0, scale: 0.5, x: 60 }}
        animate={{
          opacity: inView ? 1 : 0,
          scale: inView ? 1 : 0.5,
          x: inView ? 0 : 60,
        }}
        transition={{ duration: 1, ease: "easeInOut", delay: 0.3 }}
      />
    </motion.div>
  );
};

export default Section9;
