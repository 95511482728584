import React from "react";
import LeftSideImage from "../Templates/LeftSideImage";
import smmain from "../../../assets/HomePage/Institution/imain.webp";

const Section5 = () => {
  const data = {
    title: (
      <>
        Tools to <span className="font-bold">Automate Your Assessment</span>
      </>
    ),
    description: (
      <>
        Enjoy effortless assessment management with our platform's automated
        features. Instant grading, adaptive difficulty, seamless reporting, and
        LMS integration ensure efficient administration and tracking
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <LeftSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section5;
