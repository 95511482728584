import React from 'react'
import Btn_S1 from '../../assets/club_hero_img.png'
import S1 from '../../assets/club_s1.png'
import S2 from '../../assets/club_s2.png'
import S3 from '../../assets/club_s3.png'
import S4 from '../../assets/club_s4.png'

import SlideWithImage from "../components/Templates/SlideWithImage"
import HeroSection from "../components/Club/HeroSection"

const Club = () => {
  const heroSectionInfo = {
    img:Btn_S1,
    title: 'Join a close-knit circle that provides a collaborative environment for your growth, both personally and in your career',
    btn: {txt: 'Browse all Club'}
  };

  const slideInfo1={
    img:S1,
    title: <div className=''>Lorem Ipsum Lorem Ipsum Lorem Ipsum</div>,
    subTit: <div className="font-normal text-base">Collaborate with senior professionals, colleagues, and the management committee to acquire valuable expertise and mentorship</div>,
  };

  const slideInfo2={
    img:S2,
    title: "Lend your helping hand in organizing events for both social and professional issue",
    subTit: <div className="font-normal  text-base">Your assistance helps in orchestrating impactful gatherings that cater to various societal and professional concerns</div>,

  };

  const slideInfo3={
    img:S3,
    title: 'Network and work closely with individuals across all age & ethnicity',
    subTit: <div className="font-normal  text-base">Through close collaboration, leverage collective perspectives for comprehensive and inclusive outcomes</div>,

  };

  const slideInfo4={
    img:S4,
    title: 'Come together and create your own club for a common recognised goal',

  };


  return (
    <div>
      <HeroSection slideInfo={heroSectionInfo} contStyle ={`flex-col-reverse md:flex-row-reverse  club_hero_bg md:py-20 py-10`}/>
      <SlideWithImage slideInfo={slideInfo1} contStyle ={` md:py-20 py-10 bg-neutral-100 flex-col flex-row`}/>
      <SlideWithImage slideInfo={slideInfo2} contStyle ={`bg-white md:py-20 py-10 flex-col md:flex-row-reverse `}/>
      <SlideWithImage slideInfo={slideInfo3} contStyle ={`bg-white md:py-20 py-10 flex-col flex-row`}/>
      <SlideWithImage slideInfo={slideInfo4} contStyle ={` md:py-20 py-10 bg-neutral-100 flex-col md:flex-row-reverse`}/>
    </div>
  )
}

export default Club;