import React from 'react';

const Section5 = () => {
  return (
    <section className="w-full py-6 md:py-8 lg:py-16 xl:py-24">
      <div className=" ">
        {/* Heading */}
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-center font-bold  mb-6 xl:mb-8 px-4">What can you expect from the internship program</h2>
        
        {/* Grid */}
        <div className="grid grid-cols-12 gap-8  md:mr-0 px-4 md:px-8 lg:px-16 xl:px-24">
          {/* Left side (col-span-6) */}
          <div className="col-span-12 md:col-span-6 px-2 mr-12 md:mr-0 md:px-0">
            <div className="grid grid-cols-1 gap-4">
              {/* Card 1 */}
              <div className="bg-white p-2 md:p-4 rounded-lg">
                <h3 className="text-xl md:text-lg lg:text-xl xl:text-2xl font-semibold mb-2 md:mb-4 text-blue-500">Dynamics Team</h3>
                <p className="text-md md:text-lg lg:text-xl xl:text-2xl mr-4 md:mr-0 text-justify">From the moment you join, you’ll be working in a team, valued for the unique contribution you make and the fresh ideas you bring</p>
              </div>
              {/* Card 2 */}
              <div className="bg-white  p-2 md:p-4 rounded-lg">
                <h3 className="text-xl md:text-lg lg:text-xl xl:text-2xl font-semibold mb-2 md:mb-4 text-blue-500">Project Insight</h3>
                <p className="text-md md:text-lg lg:text-xl xl:text-2xl text-justify">You'll gain hands-on project experience working with professional and experienced teams</p>
              </div>

              <div className="bg-white  p-2 md:p-4 rounded-lg">
                <h3 className="text-xl md:text-lg lg:text-xl xl:text-2xl font-semibold mb-2 md:mb-4 text-blue-500">Project Immersion</h3>
                <p className="text-md md:text-lg lg:text-xl xl:text-2xl text-justify">You'll be encouraged to think for yourself and use your initiative within your team delivering pioneering solutions for our clients</p>
              </div>
            </div>
          </div>
          
          {/* Right side (col-span-6) */}
          <div className="col-span-12 md:col-span-6 px-2 mr-12 md:mr-0 md:px-0">
            <div className="grid grid-cols-1 gap-4">
              {/* Card 3 */}
              <div className="bg-white  p-2 md:p-4 rounded-lg">
                <h3 className="text-xl md:text-lg lg:text-xl xl:text-2xl font-semibold mb-2 md:mb-4 text-blue-500">Versatile Skillset</h3>
                <p className="text-md md:text-lg lg:text-xl xl:text-2xl text-justify">You will also develop the kind of rounded business skills that will serve you well regardless of where your future takes you</p>
              </div>
              {/* Card 4 */}
              <div className="bg-white  p-2 md:p-4 rounded-lg">
                <h3 className="text-xl md:text-lg lg:text-xl xl:text-2xl font-semibold mb-2 md:mb-4 text-blue-500">Holistic Development</h3>
                <p className="text-md md:text-lg lg:text-xl xl:text-2xl text-justify">You’ll get all the learning support you need to develop yourself both personally and professionally</p>
              </div>

              <div className="bg-white  p-2 md:p-4 rounded-lg">
                <h3 className="text-xl md:text-lg lg:text-xl xl:text-2xl font-semibold mb-2 md:mb-4 text-blue-500">Onboarding Excellence</h3>
                <p className="text-md md:text-lg lg:text-xl xl:text-2xl text-justify">When you join: You'll participate in a full induction program including a warm welcome, key skills, technical training and team building. You will also be assigned a mentor that will help you navigate everything even the smallest things to help you settle and start to grow your network!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
