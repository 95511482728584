import React from 'react';
import backgroundimage from "../../../assets/CustomerSuccess/Section7imagebg.png"
import img1 from "../../../assets/CustomerSuccess/Section7image1.png"
import img2 from "../../../assets/CustomerSuccess/Section7image2.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight} from "@fortawesome/free-solid-svg-icons";

const Section6 = () => {
  return (
    <section className="relative bg-cover bg-center py-12 px-6 md:px-0" style={{ backgroundImage: `url(${backgroundimage})` }}>
      <div className="">
        <div className="grid grid-cols-12 text-white">
          {/* Left Section */}
          <div className="col-span-12 md:col-span-3 lg:col-span-4 justify-end ">
            <img src={img1} alt="Left Image" className="w-full md:pt-24" />
          </div>

          {/* Middle Section */}
          <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col justify-center py-8 text-center md:text-left">
            <h3 className="text-md md:text-md lg:text-lg  font-bold mb-2 ">DOWNLOAD DOCUMENTS AND CERTIFICATIONS</h3>
            <h2 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mb-4 "style={{ lineHeight: '140%' }}>Access the Intelligent Document Assests</h2>
            
            <p className="text-lg md:text-xl lg:text-2xl mb-4"style={{ lineHeight: '140%' }}>Explore documentation on security, privacy, and compliance at HPDSX.</p>
            <button className=" hover:bg-blue-600 text-white py-2 px-4 rounded focus:outline-none w-fit text-xs mx-auto md:mx-0" style={{ backgroundColor: 'rgb(0, 134, 232)' }}>ACCESS DOCUMENTS <span className='mr-4'><FontAwesomeIcon icon={faAngleRight} style={{color: "#ffffff",}} /></span></button>
          </div>

          {/* Right Section */}
          <div className="col-span-12 md:col-span-3 lg:col-span-4 justify-start">
            <img src={img2} alt="Right Image" className="w-full" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section6;
