import React from 'react';
import backgroundImage from '../../../assets/TranstechCollaboratory/Section4imagebg.png'; // Import the background image

const Section4 = () => {
  return (
    <section className="relative bg-cover bg-center py-12 md:py-32 md:mx-12 my-8" style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className=" text-center">
        <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold mb-4 px-4 md:px-24"style={{ lineHeight: '140%' }}> UPMYMIND digital support and solutions for different Mind health, wellness wellbeing  challenges, from prevention and management platforms to counselling and tele-therapy, content and curriculum, support for social emotional learning and organizational analytics for tracking and troubleshooting
</h2>
        
      </div>
    </section>
  );
};

export default Section4;
