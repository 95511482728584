import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img from "../../../assets/MainMenu/Section3img 2.png";

const Section3 = () => {
  const backgroundColor = '#0086E8';
  const { ref, inView } = useInView({
    triggerOnce: false, // Only trigger animation once
    threshold: 0.15 // Trigger animation when 20% of the section is visible
  });

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0, y: -50 }} // Initial animation state (text sliding from right)
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }} // Trigger animation when section is in view
      transition={{ ease: 'easeIn', duration: 0.6, delay: 0.2 }} // Animation transition properties
      className=" text-white  pb-8 xl:-mt-12 "
      style={{ background: backgroundColor }}
    >
      <div className="grid grid-cols-12 gap-2 md:gap-4 lg:gap-8">
        {/* Left side with text */}
        <motion.div
          className="col-span-12 md:col-span-6 py-4 md:py-12 lg:py-20 xl:py-28 px-4 md:px-12 lg:px-20"
          
        >
          <div className="text-left justify-center items-center content-evenly">
            <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4">THINKSKILLINDIA</h2>
            <div className='h-[8px] w-3/5 bg-yellow-400 mb-8'></div>
            <h3 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold mb-4 text-justify" style={{ lineHeight: '140%' }}>Creating <span className='text-yellow-400'>Futuristic Skill System</span> For India's Bright Future</h3>
            <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl mb-4 font-medium text-justify" style={{ lineHeight: '140%' }}>The Indian University Students Platform <span className='text-yellow-400'>TSIO</span> is a contemporary platform created to provide an <span className='text-yellow-400'>innovative community</span> to <span className='text-yellow-400'>University Students</span> across India and abroad.</p>
          </div>
        </motion.div>
        {/* Right side with image */}
        <motion.div
          className="col-span-12 md:col-span-6 "
          initial={{ opacity: 0, y: 80 }} // Initial animation state (image sliding from left)
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }} // Trigger animation when section is in view
          transition={{ ease: 'easeIn', duration: 0.6, delay: 0.2 }} // Animation transition properties
        >
          <img src={img} alt="Image Description" className=" w-full rounded-lg " />
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Section3;
