import React from 'react';

import Section1image from '../../../assets/Focus/Section3image1.png';

const Section3 = () => {
  return (
    <div className='w-full'>
      <div className='max-w-screen'>
        <div className="w-full md:h-[100vh] lg:h-[116vh]  xl:h-[144vh]  flex justify-center items-center  font-semibold " style={{ background: 'rgb(10, 45, 77)' }}>
          <div className="leading-relaxed text-white px-4 md:px-4 lg:px-12 xl:px-20 ">

            <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl mt-8 md:mt-12 lg:mt-16 xl:mt-20  md:px-4 lg:px-12 xl:px-20   font-semibold leading-loose text-justify" style={{ lineHeight: '140%' }}><span className='text-yellow-400'>Our goal</span> is to <span className='text-yellow-400'>encourage and motivate</span> the everyday <span className='text-yellow-400'>man and woman</span> by giving him/her the tools to overcome everyday challenges and meet women <span className='text-yellow-400'>who have faced</span> and overcome the same <span className='text-yellow-400'>challenges</span>. By helping one another, women can transform not only our peers but the world.
            </div>

            <img src={Section1image} alt="" className="mt-8 " />

          </div>
        </div>
      </div>
    </div>

  );
};

export default Section3;