import React, { useEffect } from "react";
import { motion } from "framer-motion";
import ImageToImport from "../../../assets/ChangeAgent/Section2image1.png";
import { useInView } from "react-intersection-observer";

const Section2 = () => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      // Start animation when the section is in view
      // You can customize this animation as needed
      console.log("Section 2 is in view!");
    }
  }, [inView]);

  return (
    <motion.section
      className="md:pt-0 py-8 md:py-4 lg:py-12 md:px-20"
      ref={ref}
      initial={{ opacity: 0, y: 75 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 75 }}
      transition={{ ease: "easeInOut", duration: 1.2 }} // Customize transition as needed
    >
      <div className="text-center">
        <h2 className="pt-8 md:pt-0 text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-8 px-4 md:px-0">
          You are a{" "}
          <span className="" style={{ color: "rgb(0, 134, 232)" }}>
            Change Agent
          </span>
        </h2>
        <p
          className="md:text-xl lg:text-2xl xl:text-3xl text-gray-700 mb-8 text-center px-4 md:px-0 font-medium"
          style={{ lineHeight: "140%" }}
        >
          A conscious leader willing to realize a happier world by bringing
          together a diverse number of{" "}
          <span className="" style={{ color: "rgb(0, 134, 232)" }}>
            people, institutions, influencers, and activists.
          </span>{" "}
          Your focus is to maximize the power of community by holding space and
          inviting individuals to share and learn together.
        </p>
        <div className="flex justify-center">
          <img
            src={ImageToImport}
            alt="Description Image"
            className="w-full md:max-w-full h-auto"
          />
        </div>
      </div>
    </motion.section>
  );
};

export default Section2;
