import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img1 from '../../../assets/MainMenu/Section6image.png';

const Section6 = () => {
    // Use useInView to detect when the section is in view
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.25,
    });

    return (
        <motion.section
            initial={{ opacity: 0, y: 70 }} // Initial animation state (section sliding from bottom)
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 70 }} // Trigger animation when section is in view
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }} // Animation transition properties
            className="w-full py-12 px-4 md:px-12 lg:px-20 xl:px-28"
            ref={ref} // Assign the ref obtained from useInView
        >
            <div className="max-w-screen">
                <div className="mb-8">
                    <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold" style={{ lineHeight: '140%' }}>Lead to a disruptive transition in the next 5-10 years, in interplay with more industry-specific change accelerators​</h2>
                </div>
                <div className="grid grid-cols-12 gap-4">
                    {/* Left side (colspan-6) */}
                    <motion.div
                        initial={{ opacity: 0, y: 60 }} // Initial animation state (text sliding from bottom)
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 60 }} // Trigger animation when section is in view
                        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }} // Animation transition properties
                        className="col-span-12 md:col-span-6 py-4 md:py-6 lg:py-8 xl:py-20"
                    >
                        <div className="rounded">
                            <p className="text-gray-700 md:text-xl lg:text-2xl xl:text-3xl text-justify" style={{ lineHeight: '140%' }}>The Platform addresses the future of work preparing youth, which is likely to be volatile, uncertain, and complex, with workplaces facing challenges in harnessing the growth potential of new technologies due to skill shortages.</p>
                        </div>
                    </motion.div>
                    {/* Right side (colspan-6) */}
                    <motion.div
                        initial={{ opacity: 0, x: -30 }} // Initial animation state (image sliding from top)
                        animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -30 }} // Trigger animation when section is in view
                        transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }} // Animation transition properties
                        className="col-span-12 md:col-span-6"
                    >
                        <div className="p-6 rounded">
                            <img src={img1} alt="Right Side Image" className="w-full rounded" />
                        </div>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    );
};

export default Section6;
