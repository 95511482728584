import icon1 from "../../../../assets/Hpdsx/CoverIcon1.jpg";
import icon2 from "../../../../assets/Hpdsx/CoverIcon2.svg";
import icon3 from "../../../../assets/Hpdsx/CoverIcon3.svg";
import icon4 from "../../../../assets/Hpdsx/CoverIcon4.svg";
import icon5 from "../../../../assets/Hpdsx/CoverIcon5.svg";
import icon6 from "../../../../assets/Hpdsx/CoverIcon6.svg";
import icon7 from "../../../../assets/Hpdsx/CoverIcon7.svg";

const features = [
  { id: 1, name: "My Profile", icon: icon1 },
  { id: 2, name: "My Potential", icon: icon2 },
  { id: 3, name: "My Plans", icon: icon3 },
  { id: 4, name: "Presentation", icon: icon4 },
  { id: 5, name: "My Posting", icon: icon5 },
  { id: 6, name: "My Peers", icon: icon6 },
  { id: 7, name: "My Program", icon: icon7 },
];

export default features;
