import React from "react";
import LandingCover from "../../components/Scientist/LandingCover";
import ScientistSection1 from "../../components/Scientist/ScientistSection1";
import ScientistSection2 from "../../components/Scientist/ScientistSection2";
import ScientistSection3 from "../../components/Scientist/ScientistSection3";
import ScientistSection4 from "../../components/Scientist/ScientistSection4";

export default function Scientist() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <ScientistSection1 />
      <ScientistSection2 />
      <ScientistSection3 />
      <ScientistSection4 />
    </div>
  );
}
