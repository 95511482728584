import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import BgImg from "../../../../assets/Blogs/BgImg.png";
import CoverImg1 from "../../../../assets/Blogs/CoverImg1.jpg";
import CoverImg2 from "../../../../assets/Blogs/CoverImg2.jpg";

const BlogHeader = () => {
  const bgImgStyle = {
    backgroundImage: `url(${BgImg})`,
  };

  return (
    <div
      className="h-full"
      style={bgImgStyle}
    >
      <div className="w-full h-full mx-auto grid grid-cols-12 gap-0 bg-auto">
      <div className="col-span-12 md:col-span-6 order-2 md:order-none">
        <div className="flex flex-col px-4 py-6 xl:pl-28 xl:pr-6 lg:pl-20 lg:pr-8 xl:py-32 lg:py-36 md:pl-12 md:pr-3 md:py-28">
          <div className="mb-6 text-2xl xl:text-4xl lg:text-3xl md:text-2xl font-bold  font-Segoe UI">
            Every story needs to be heard
          </div>
          <div className="mb-1 font-semibold text-sm xl:text-lg lg:text-sm md:text-xs">
            Where your thoughts, ideas and perspective <br />
            are embraced.
          </div>
          <div className=" mt-4 px-4 py-2 w-fit hover:bg-black hover:text-white cursor-pointer rounded-full border border-black hover:border-black flex items-center justify-start ">
            <div className="text-xs font-bold">Create your Blog</div>
            <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
          </div>
        </div>
        
      </div>
      <img
        className="col-span-6 md:col-span-3 h-full w-full object-cover xl:object-cover lg:object-fit md:object-fit"
        src={CoverImg1}
        alt="Seminar"
      />
      <img
        className="col-span-6 md:col-span-3 h-full w-full object-cover xl:object-cover lg:object-fit md:object-fit"
        src={CoverImg2}
        alt="Speaker"
      />
    </div>
    </div>
  );
};

export default BlogHeader;
