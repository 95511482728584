import img1 from "../../../assets/LocalActivities/seminars.jpg";
import img2 from "../../../assets/LocalActivities/competitions.jpg";
import img3 from "../../../assets/LocalActivities/workshops.jpg";
import img4 from "../../../assets/LocalActivities/hackathons.jpg";
import img5 from "../../../assets/LocalActivities/networkingEvents.jpg";
import img6 from "../../../assets/LocalActivities/communityEvents.jpg";
import img7 from "../../../assets/LocalActivities/educationalPrograms.jpg";
import img8 from "../../../assets/LocalActivities/meetups.jpg";
import img9 from "../../../assets/LocalActivities/panelDisussions.jpg";

const localActivityData = {
  topic: "Discover. Connect. Grow.",
  items: [
    {
      img: img1,
      title: "Seminars",
    },
    {
      img: img2,
      title: "Competitions",
    },

    {
      img: img3,
      title: "Workshops",
    },
    {
      img: img4,
      title: "Hackathons",
    },
    {
      img: img5,
      title: "Networking Events",
    },
    {
      img: img6,
      title: "Community Events",
    },
    {
      img: img7,
      title: "Educational Programs",
    },
    {
      img: img8,
      title: "Meetups",
    },
    {
      img: img9,
      title: "Panel Discussions",
    },
  ],
};

export default localActivityData;
