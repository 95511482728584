import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import backgroundImage from '../../../assets/MainMenu/Section14imagebg.png';

import Section4Button1 from '../../../assets/HPDSxEngine/Section4Button1.png';
import Section4Button2 from '../../../assets/HPDSxEngine/Section4Button2.png';
import Section4Button3 from '../../../assets/HPDSxEngine/Section4Button3.png';

import DatasetSec4 from './DatasetSec15';

const Section15 = () => {
  const [activeDataset, setActiveDataset] = useState(DatasetSec4[0]);
  const { ref, inView } = useInView();

  const handleButtonClick = (id) => {
    const dataset = DatasetSec4.find(item => item.id === id);
    setActiveDataset(dataset);
  };

  const buttonDatasets = [
    { id: 1, image: Section4Button1, title: 'Title 1' },
    { id: 2, image: Section4Button2, title: 'title 2' },
    { id: 3, image: Section4Button3, title: 'Title 3' },
  ];

  return (
    <motion.section
      ref={ref}
      className="w-full relative h-full bg-cover bg-center pt-12 md:pt-24"
      style={{ backgroundImage: `url(${backgroundImage})` }}
      initial={{ opacity: 0, y: 80 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }}
      transition={{ ease: 'easeInOut', duration: 0.6 }}
    >
      <div className="mx-auto">
        <div className='grid grid-cols-12 gap-2'>
          <div className="col-span-12 md:col-span-6 px-4 md:px-8 lg:px-16 xl:px-20">
            <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 " style={{ lineHeight: '140%' }}>
              Are people high on <span className='text-blue-500'>Energy</span> more successful in their Careers?
            </h1>
            <h1 className="text-lg md:text-lg lg:text-xl xl:text-2xl  mb-4 " style={{ lineHeight: '140%' }}>​If yes, what are those factors that determine this?</h1>
          </div>
          <div className="col-span-12 md:col-span-6 px-4 md:px-12 lg:px-20 lg:py-12">
            <p className="text-xl md:text-xl lg:text-2xl mb-4 font-bold" style={{ lineHeight: '140%' }}>Thinkskillindia platform to engage in meaningful, on-going relationships that involve frequent online and real-world development of the students.</p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full">View Details</button>
          </div>
        </div>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-12 gap-4 mt-8 md:mt-12 lg:mt-6 xl:mt-4 bg-white mx-4 md:mx-12 lg:mx-20 xl:mx-40"
          initial={{ opacity: 0 }}
          animate={{ opacity: inView ? 1 : 0 }}
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
        >
          {buttonDatasets.map(button => (
           <div key={button.id} className="col-span-1 md:col-span-4 my-2 mx-2">
           <motion.div
             className={`text-center cursor-pointer md:text-sm lg:text-sm hover:bg-blue-400 text-black font-bold py-3 px-8 rounded flex items-center justify-center md:border-r-2 ${activeDataset.id === button.id ? 'bg-blue-400 text-white border-blue-400 hover:border-transparent' : 'border-blue-400 hover:border-transparent'}`}
             onClick={() => handleButtonClick(button.id)}
             whileHover={{ scale: 1.1 }}
             whileTap={{ scale: 0.9 }}
           >
                <img src={button.image} alt="Image Description" className='mr-2' />
                {button.title}
              </motion.div>
            </div>
          ))}
        </motion.div>

        {activeDataset && (
          <motion.div
            className="col-span-12 bg-white rounded-xl p-4 md:p-4 lg:p-6 xl:p-8 my-12 md:my-16 lg:mt-12 xl:mt-20 mx-4 md:mx-12 lg:mx-20 xl:mx-24"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
          >
            <div className="grid grid-cols-12 gap-6 py-8 md:py-0 lg:py-8 xl:py-12 " style={{ lineHeight: '140%' }}>
              <div className="col-span-12 md:col-span-6 p-4">
                <h2 className="text-lg md:text-lg lg:text-xl xl:text-2xl font-bold mb-4 text-justify">{activeDataset.heading}</h2>
                <p className="text-lg md:text-lg lg:text-xl xl:text-2xl text-gray-600 mb-4 py-8 text-justify">{activeDataset.description}</p>
              </div>
              <div className="col-span-12 md:col-span-6 flex md:items-center md:justify-center lg:justify-center lg:items-start xl:items-center xl:justify-center">
                <motion.img
                  src={activeDataset.rightimage}
                  alt="Image"
                  className="md:w-full lg:w-full  rounded-lg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0 }}
                  transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2}}
                />
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.section>
  );
};

export default Section15;
