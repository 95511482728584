
import SampleImage1 from "../../../assets/Enterprise/Section4image1.png"
import SampleImage2 from "../../../assets/Enterprise/Section4image1.png"
import SampleImage3 from "../../../assets/Enterprise/Section4image1.png"

const dataset = [
  {
    id: 1,
    title: <>Skills Success Hub</>,
    description: <>HPDSx Platform is dedicated to helping individuals acquire the knowledge, skills, and competencies they need to succeed in the job market. This can include everything from basic education and training to more advanced skills development and professional development opportunities.​</>,
    image: SampleImage1 
  },
  {
    id: 2,
    title: <>Transformative Workforce 
    Development</>,
    description: "Personalize development for skills, roles, and goals, make learning powerful relevant and connected by meeting youth.",
    image: SampleImage2 
  },
  {
    id: 3,
    title: <>The Million HPDS Development Initiative</>,
    description: "Empowering to explore and engage with passions to weave learning into Data-driven skills intelligence in collective intelligence systems.",
    image: SampleImage3 
  },
];

export default dataset;
