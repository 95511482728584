import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import imgURL from "../../../assets/ThinkSkillIndia/img_8.jpg";

const Section3 = () => {
  const data = {
    item1: (
      <>
        Unemployment must be renamed{" "}
        <span className="text-yellow-400"> Career Shifters </span>
      </>
    ),
    item2: (
      <>
        For the past decade, the India has focused nationally,{" "}
        <span className="text-yellow-400">
          on closing achievement gaps between the lowest- and highest-performing
          students
        </span>
        , A legitimate and useful agenda, but one that skirts the competitive
        demand for advanced skills.
      </>
    ),
    item3: (
      <>
        THINKSKILLINDIA helps fast-growing companies access the talent they need
        to <span className="text-yellow-400">Compete and Succeed Globally</span>
        , while creating new{" "}
        <span className="text-yellow-400">Career Pathways</span> for workers to
        rapidly{" "}
        <span className="text-yellow-400">
          Transition into High demand Roles.
        </span>
      </>
    ),
    item4: imgURL,
  };

  const { ref, inView } = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="w-full xl:h-[90vh] lg:h-[100vh] md:h-[90vh]  h-full flex flex-col md:flex-row"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }}
    >
      <div className="w-full flex flex-col xl:gap-12 lg:gap-8 md:gap-4 h-full font-medium xl:text-3xl lg:text-2xl md:text-xl text-lg bg-blue-500  xl:p-16 lg:p-12 md:p-10 p-4 text-white">
        <motion.div
          style={{ lineHeight: "140%" }}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -100 },
          }}
          transition={{ duration: 0.7, ease: "easeInOut", delay: 0.5 }}
        >
          {data.item2}
        </motion.div>
        <motion.div
          style={{ lineHeight: "140%" }}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -100 },
          }}
          transition={{ duration: 0.7, ease: "easeInOut", delay: 0.7 }}
        >
          {data.item3}
        </motion.div>
      </div>
      <div
        className="w-full h-full  "
        style={{
          backgroundImage: `url(${imgURL})`,
          backgroundSize: "cover", // Maintain original image size
          backgroundPosition: "center", // Center the image
        }}
      >
        <div className="w-full h-full flex justify-center items-center xl:px-16 lg:px-8 md:px-2 px-4 bg-blue-500 bg-opacity-40  ">
          <motion.div
            className=" text-white xl:text-7xl lg:text-6xl md:text-5xl text-4xl xl:leading-tight lg:leading-tight md:leading-10 font-bold md:py-0 py-12"
            style={{ lineHeight: "140%" }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            transition={{ duration: 0.7, ease: "easeInOut", delay: 0.9 }}
          >
            {data.item1}
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Section3;
