import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import CoverSideImg from "../../../assets/Mentor/MentorSideImg.png";

const LandingCover = () => {
  return (
    <div className="h-full xl:h-full lg:h-[70vh] md:h-[70vh] flex bg-neutral-200 md:bg-white px-6 pt-12 xl:pl-20 xl:pr-8 lg:pl-8 lg:pr-2 md:px-8 xl:mx-12 lg:mx-12 md:mx-4">
      <div className="w-full h-full flex flex-col md:flex-row ">
        <div className="flex flex-col flex-1 xl:py-12 lg:py-20 md:py-12 lg:my-6 md:my-0">
          <div className="mb-2 pb-2 font-semibold text-xl xl:text-4xl md:text-2xl lg:text-3xl xl:pt-16 md:pt-1 cursor-pointer">
            <div className="mb-1">
              Mentor young potential to <br />
              realise their Dreams
            </div>
          </div>
          <div className="mb-1 font-normal text-stone-900 text-sm xl:text-lg lg:text-md md:text-xs cursor-pointer">
            <div className="hidden md:block">
              Catalyze a varied, cross-sector initiative that emphasizes <br />
              connections and amplifies prospects for young individuals, <br />
              spanning from educational institutions to professional settings
              and beyond
            </div>
            <div className="block md:hidden tracking-tight mb-4">
              Catalyze a varied, cross-sector initiative that emphasizes
              connections and amplifies prospects for young individuals,
              spanning from educational institutions to professional settings
              and beyond
            </div>
          </div>
          <div className=" mt-4 px-4 py-2 w-fit hover:bg-purple-500 hover:text-white cursor-pointer rounded-full border border-black hover:border-white flex items-center justify-start ">
            <div className="text-xs font-bold  ">Become a Mentor</div>
            <FontAwesomeIcon icon={faChevronRight} className=" ml-2" />
          </div>
        </div>
        <div className="flex-1 flex justify-end items-center">
          <div
            className="w-96 md:w-full h-72 xl:h-[580px] lg:h-[460px] md:h-[330px] bg-contain xl:bg-cover lg:bg-cover md:bg-cover bg-no-repeat mt-10 xl:ml-12  lg:ml-1 md:ml-6 xl:mt-6 md:pt-6"
            style={{ backgroundImage: `url(${CoverSideImg})` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
