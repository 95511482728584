import React from "react";
import BgImg from "../../../assets/About Hpds/LandingBgImg.png";
import CoverSideImg from "../../../assets/About Hpds/SideCoverImg.png";

const LandingCover = () => {
  const bgImageStyle = {
    backgroundImage: `url(${BgImg})`,
    backgroundPosition: "center",
  };

  return (
    <div
      className="w-full grid grid-cols-12 gap-0 md:gap-8 xl:gap-20 px-0 pt-10 xl:px-28 xl:py-16 lg:px-20 lg:py-8 md:px-12 md:py-4 bg-auto md:bg-cover bg-no-repeat"
      style={bgImageStyle}
    >
      <div className="px-3 md:px-0 order-2 md:order-1 col-span-12 md:col-span-4 flex flex-col cursor-pointer">
        <div className="w-28 h-2 lg:h-3 mt-6 md:mt-24 xl:mt-32 bg-gradient-to-r from-blue-500 to-fuchsia-400"></div>
        <div className="mb-0 md:mb-2 pt-3 xl:pt-12 pb-4 md:pb-1 xl:pb-4 font-semibold text-2xl xl:text-4xl md:text-xl lg:text-3xl  leading-10">
          Help you become What you want to be
        </div>
        <div className="mb-20 md:mb-1 text-md md:text-xs lg:text-md xl:text-lg lg:text-md md:text-sm">
          <div>
            Anything of better for your life purpose, something to aim for, you
            can plan, you can hope, you can dream, HPDSx Engine helps you get it
            done. Build your routine, find your focus, sharpen your mind,
            develop your creative attitude.
          </div>
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-12 md:col-span-8 xl:py-16 md:py-16 lg:py-8">
        <div className="w-full h-full rounded-none md:rounded-xl">
          <img
            src={CoverSideImg}
            alt="blank"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
