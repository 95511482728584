import Section4Image1 from '../../../assets/Enterprise/Section7image1.png';
import Section4Image2 from '../../../assets/Enterprise/Section7image1.png';
import Section4Image3 from '../../../assets/Enterprise/Section7image1.png';


const DatasetSec4 = {
    buttons: [
        {
            id: 1,
            heading: "Business Growth Partnership",
            description: "THINKSKILLINDIA Platform provides an invaluable partnership opportunity for businesses and entrepreneurs looking to boost their growth and competitive edge. ",
            image: Section4Image1
        },
        {
            id: 2,
            heading: "Industry-Education Collaboration",
            description: "Conscious mind empowerment to drive your thoughts to focus and be in line with your long-term goals creating a unique blend of essence in your lifestyle",
            image: Section4Image2
        },
        {
            id: 3,
            heading: "Skill Gap Solutions",
            description: "Core genius transformation to uncover the untapped potential within you and turn your aspirations into reality by establishing a strong foundation",
            image: Section4Image3
        },
        {
            id: 4,
            heading: "Workplace Learning Enhancemen",
            description: "Core genius transformation to uncover the untapped potential within you and turn your aspirations into reality by establishing a strong foundation",
            image: Section4Image3
        },
      
    ]
};

export default DatasetSec4;
