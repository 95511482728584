import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import backgroundImage from '../../../assets/HPDSxEngine/Section5imagebg.png';
import DatasetSec11 from './DatasetSec4';

const Section11 = () => {
  const [expandedCard, setExpandedCard] = useState(1);

  const handleCardClick = (cardIndex) => {
    if (expandedCard === cardIndex) {
      setExpandedCard(0); 
    } else {
      setExpandedCard(cardIndex);
    }
  };

  return (
    <div className="  text-white" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className='px-4 md:px-8 lg:px-12 xl:px-28 py-16 '>
        <h3 className="text-center text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-bold md:py-4 lg:py-6 xl:py-8" style={{ lineHeight: '140%' }}> Workforce Development</h3>
        <div className="grid grid-cols-12 mt-8 md:my-12 gap-6 md:gap-2">
        <div className="col-span-12 md:col-span-6">
            {expandedCard !== 0 && (
              <img src={DatasetSec11.find(card => card.id === expandedCard).image} alt="Card Image" className="md:w-full xl:w-4/5" />
            )}
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="space-y-4">
            {DatasetSec11.map(card => (
                <div key={card.id} className={`md:px-4 md:py-4 lg:px-6 lg:py-6 xl:px-8 xl:py-8 border-l-4 ${expandedCard === card.id ? 'border-white' : 'border-transparent'} border-b p-4 transition-all duration-1000 ease-in-out`} style={{ transitionDelay: `${expandedCard === card.id ? '0.2s' : '0.2s'}` }}>
                  <div className="flex items-center justify-between cursor-pointer" onClick={() => handleCardClick(card.id)}>
                    <h4 className="md:text-2xl lg:text-3xl font-semibold"><span className='text-white font-bold'>{card.title}</span></h4>
                    <FontAwesomeIcon icon={expandedCard === card.id ? faAngleUp : faAngleDown} />
                  </div>
                  <div className={expandedCard === card.id ? 'opacity-100 max-h-96 overflow-hidden transition-opacity duration-1000 ease-in-out' : 'opacity-0 max-h-0 overflow-hidden transition-opacity duration-1000 ease-in-out'}>
                    {/* Card Details */}
                    <p className='md:text-lg lg:text-xl xl:text-2xl mt-4' style={{ lineHeight: '140%' }}>{card.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section11;
