import React from "react";
import platformDataSet from "../DataSet/platformDataset";
import LeftImageGrid from "../Templates/LeftImageGrid";

const Section5 = () => {
  const data = platformDataSet[12];

  return (
    <div>
      <LeftImageGrid
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
        linkColor={data.linkColor}
      />
    </div>
  );
};

export default Section5;
