import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";

// import MoreServices from "../SME/MoreServices";

export default function Assessment() {
  return (
    // <div className="border border-gray-300 bg-white py-6 xl:py-16 rounded-xl shadow-lg m-2 md:m-6 xl:mx-14 lg:m-8 xl:my-10">
    <div className="flex flex-col">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <div className="text-center text-stone-900 text-2xl xl:text-5xl lg:text-4xl md:text-3xl  font-medium py-4 md:py-12 px-4" style = {{lineHeight : '140%'}}>
        Unlock the <span className="font-bold">potential of personalized</span>{" "}
        <br></br>
        assessments <span className="font-bold">crafted</span> to your needs
        with <br></br>
        <span className="font-bold">our platform</span>.
      </div>
      <Section6 />
    </div>
  );
}
