import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faLinesLeaning } from '@fortawesome/free-solid-svg-icons';
import backgroundImage from '../../../assets/HPDSxEngine/Section4imagebg2.png';
import Section4image1 from '../../../assets/HPDSxEngine/Section4image1.png';
import Section4Button1 from '../../../assets/HPDSxEngine/Section4Button1.png';
import Section4Button2 from '../../../assets/HPDSxEngine/Section4Button2.png';
import Section4Button3 from '../../../assets/HPDSxEngine/Section4Button3.png';
import Section4Button4 from '../../../assets/HPDSxEngine/Section4Button4.png';
import DatasetSec4 from './DatasetSec4';
import { useInView } from 'react-intersection-observer';

const Section4 = () => {
  const [activeDataset, setActiveDataset] = useState(DatasetSec4[0]);
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView();
 
  useEffect(() => {
    setLoaded(true);
}, []);

  useEffect(() => {
    if (inView) {
      console.log('Section 4 is in view!');
      // Add your additional animations or logic here
    }
  }, [inView]);





  const handleButtonClick = (id) => {
    const dataset = DatasetSec4.find(item => item.id === id);
    setActiveDataset({ ...dataset, isAnimating: true });

    // Set a timeout to remove the animation after a certain duration
    setTimeout(() => {
      setActiveDataset({ ...dataset, isAnimating: false });
    }, 1000); // Adjust the duration as needed
  };

  const buttonDatasets = [
    { id: 1, image: Section4Button1, title: '21st Century Philosophy' },
    { id: 2, image: Section4Button2, title: 'Mastering Skills' },
    { id: 3, image: Section4Button3, title: 'Skills for Today and Tomorrow' },
    { id: 4, image: Section4Button4, title: 'Skills for High Performance' }
  ];

  return (
    <motion.section
      ref={ref}
      className="h-full bg-cover bg-center md:flex-row py-20 md:py-24 lg:py-16 xl:py-28"
      style={{ backgroundImage: `url(${backgroundImage})` }}
      initial={{ opacity: 0, y: 80 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
      transition={{ ease: 'easeInOut', duration: 0.7 }}
    >
      <div className='grid grid-cols-12 gap-2'>
        <div className="col-span-12 md:col-span-6 px-4 md:px-8 lg:px-16 xl:px-32">
          <button className="bg-white border-2 border-gray-200 hover:bg-black hover:text-white text-black font-bold px-5 rounded-full mb-4">Features</button>
          <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 ">
            Reimagine
            <span className='mx-4'>
              <FontAwesomeIcon
                icon={faLinesLeaning}
                style={{ color: "#000000", transform: `rotate(-120deg)` }}
              />
            </span>
          </h1>
          <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 ">What's possible for you!</h1>
        </div>
        <div className="col-span-12 md:col-span-6 px-4 md:px-12 lg:px-20 lg:py-12">
          <p className="text-xl md:text-2xl lg:text-3xl mb-4 ">Real-Time discovery: Expected services Human Potential Developments System </p>
          <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded-full">View Details</button>
        </div>
      </div>

      {/* Row of 4 buttons with descriptions and icons */}
      <motion.div className="grid grid-cols-2 md:grid-cols-4 gap-2 mt-8 md:mt-12 xl:mt-4 bg-white mx-4 md:mx-[5px] lg:mx-20 xl:mx-40"
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }} // Use inView to trigger animations
      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.3 }}
      >
        <div className="col-span-2 md:col-span-4  flex flex-col md:flex-row justify-between  md:p-0 lg:p-2 rounded-xl ">
        {buttonDatasets.map(button => (
           <div key={button.id} className="col-span-2 md:col-span-4">
           <motion.div
             className={`text-center cursor-pointer md:text-sm lg:text-sm hover:bg-blue-400 text-black font-bold py-3 px-8 rounded flex items-center justify-center md:border-r-2 ${activeDataset.id === button.id ? 'bg-blue-400 text-white border-blue-400 hover:border-transparent' : 'border-blue-400 hover:border-transparent'}`}
             onClick={() => handleButtonClick(button.id)}
             whileHover={{ scale: 1.1 }}
             whileTap={{ scale: 0.9 }}
           >
                <img src={button.image} alt="Image Description" className='mr-2' />
                {button.title}
              </motion.div>
            </div>
          ))}
        </div>
      </motion.div>

      {activeDataset && loaded && (
        <motion.div
          className="col-span-12 bg-white rounded-xl p-4 md:p-4 lg:p-6 my-12 md:my-8 lg:my-12 xl:my-20 mx-4 md:mx-0 lg:mx-20 xl:mx-40"
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ ease: 'easeInOut', duration: 0.7 }}
        >
          <div className="grid grid-cols-12 gap-4 lg:py-8 xl:py-12 " style={{ lineHeight: '140%' }}>
            {/* Left section */}
            <motion.div
              className="col-span-12 md:col-span-6"
              initial={{ opacity: 0, y: 50 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
              transition={{ ease: 'easeInOut', duration: 0.7, delay: 0.2 }}
            >
              <h2 className="text-lg md:text-lg lg:text-xl xl:text-2xl font-bold mb-4" >{activeDataset.heading}</h2>
              <p className="text-md md:text-md lg:text-lg xl:text-xl text-gray-600 mb-4">{activeDataset.description}</p>
              <hr className="border-gray-300 my-4" />
              {/* Cards grid */}
              <motion.div
                className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-2 xl:gap-4"
                initial={{ opacity: 0 }}
                animate={inView ? { opacity: 1 } : { opacity: 0 }}
                transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.4 }}
              >
                {activeDataset.cards.map(card => (
                  <motion.div
                    key={card.id}
                    className="bg-white rounded-lg p-2 md:p-2 xl:p-8 flex"
                    initial={{ opacity: 0 }}
                    animate={inView ? { opacity: 1 } : { opacity: 0 }}
                    transition={{ ease: 'easeInOut', duration: 0.6 }}
                  >
                    <img src={card.image} alt={`Card ${card.id}`} className="w-8 h-8 md:w-16 md:h-16 mr-1 md:mr-2  xl:mr-3" />
                    <p className="text-[12px] md:text-sm lg:text-md  font-semibold">{card.description}</p>
                  </motion.div>
                ))}
              </motion.div>
            </motion.div>
            {/* Right section */}
            <motion.div
              className="col-span-12 md:col-span-6 flex md:items-center md:justify-center lg:justify-center lg:items-start xl:items-center xl:justify-center"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={inView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.9 }}
              transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
            >
              <img src={activeDataset.rightimage} alt="Image" className="w-full md:w-4/5 lg:w-full  rounded-lg" />
            </motion.div>
          </div>
        </motion.div>
      )}

    </motion.section>
  );
};

export default Section4;
