import React from "react";
import cardData from "./section5Dataset"; 

const PlatformSectionFive = () => {
  return (
    <div className="w-full h-full bg-white">
      <div className=" w-full h-full py-8 xl:py-20 lg:py-12 md:py-4 px-4 flex flex-col items-center">
        <div className="text-center text-stone-900 text-xl xl:text-4xl lg:text-3xl md:text-2xl  font-semibold mb-4">
        How can <span className="font-bold"> Think Skill India</span> platform help you? 
        </div>
        <div className="flex  mx-auto justify-center items-center flex-wrap xl:mt-8 lg:mt-4 md:mt-0 px-4">
          <div className="w-full grid grid-cols-12 xl:gap-8 lg:gap-6 md:gap-4 gap-8"> 
            {cardData.map((item, index) => (
              <div className="col-span-12 md:col-span-4  xl:px-4 lg:px-4  px-0 " key={index}>
                <div className="xl:w-full lg:w-full md:w-full w-[80vw]  h-56 flex flex-col justify-center items-center border-2 border-grey-100 bg-white rounded-lg hover:shadow-md hover:rounded-md">
                  <div className="h-5/12 w-full flex justify-center items-center">
                    <img
                      className="w-16 h-16 xl:w-20 xl:h-20 lg:w-20 lg:h-20 md:w-24 md:h-24 rounded-md object-cover"
                      src={item.image}
                      alt={item.alt || "Card Image Alt Text"}
                    />
                  </div>
                  <div className="w-full h-7/12 text-center py-3 px-3">
                    <div className="text-center w-full text-black xl:text-xl lg:text-lg md:text-sm font-semibold mt-2 mb-2">
                      {item.title}
                    </div>
                    
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformSectionFive;
