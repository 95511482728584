
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Ttl_Img_Card from '../Templates/Ttl_Img_Card';
import RoundPill from '../Templates/RoundPill';


const FlexSlide = ({ slideInfo, contStyle }) => {
  const d=slideInfo.data;
  const style='rounded-3xl shadow lg:w-44 md:w-40 ';
  return (
    <div className={`md:flex justify-between ${contStyle} my-5`}>

      <div className="items-start md:w-5/12 mx-auto md:my-20 p-5">
        {slideInfo.icon && (
          <div className='w-14 h-14'>
            <img src={slideInfo.icon} />
          </div>
        )}
        <div className="font-semibold text-4xl my-4">{slideInfo.title}</div>
        <div className="text-sm font-semibold">{slideInfo.subTit}</div>
        {slideInfo.btn && (
          <button className='py-1 px-4 rounded-full my-4 border border-black border-solid hover:bg-neutral-100 hover:shadow-md' onClick={slideInfo.btn.function}>
            {slideInfo.btn.txt} <FontAwesomeIcon icon={faAngleRight} />
          </button>
        )}
      </div>
      <div className="md:w-5/12 flex justify-center md:pr-5 lg:gap-6 md:gap-3">
        <div className="h-full md:pt-16 m-3 flex flex-col lg:gap-6 gap-4 w-1/2">
          <div className={style}>
          <Ttl_Img_Card img={d.at(0).img} title={d.at(0).title}  icon={<RoundPill  digit={'1'} style={`bg-purple-200 text-purple-600`}/>}/>

          </div>
          <div className={style}>
          <Ttl_Img_Card img={d.at(2).img} title={d.at(1).title}  icon={<RoundPill  digit={'3'} style={`bg-purple-200 text-purple-600`}/>}/>

          </div>
        </div>
        <div className="h-full md:pb-16 m-3 flex flex-col lg:gap-6 gap-4 w-1/2">
          <div className={style}>
          <Ttl_Img_Card img={d.at(1).img} title={d.at(2).title}  icon={<RoundPill  digit={'2'} style={`bg-purple-200 text-purple-600`}/>}/>

          </div>
          <div className={style}>
          <Ttl_Img_Card img={d.at(3).img} title={d.at(3).title}  icon={<RoundPill  digit={'4'} style={`bg-purple-200 text-purple-600`}/>}/>

          </div>
        </div>
      </div>
    </div>
  )
}

export default FlexSlide