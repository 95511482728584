import sc1 from "../../../../assets/Hpdsx/Section6_Img1.svg";
import sc2 from "../../../../assets/Hpdsx/Section6_Img2.svg";
import sc3 from "../../../../assets/Hpdsx/Section6_Img3.svg";
import sc4 from "../../../../assets/Hpdsx/Section6_Img4.svg";

const sixthSectionData = [
  {
    id: 1,
    title: "Be a Subject Matter Expert",
    bgColor: "bg-emerald-300",
    imageUrl: sc2,
  },
  {
    id: 2,
    title: "Be a Teacher",
    bgColor: "bg-white",
    imageUrl: sc1,
  },
  {
    id: 3,
    title: "Be a Content Coach",
    bgColor: "bg-red-200",
    imageUrl: sc3,
  },
  {
    id: 4,
    title: "Be a Counselor",
    bgColor: "bg-stone-200",
    imageUrl: sc1,
  },
  {
    id: 5,
    title: "Create your Learning Path",
    bgColor: "bg-stone-200",
    imageUrl: sc2,
  },
  {
    id: 6,
    title: "Be a Teacher",
    bgColor: "bg-white",
    imageUrl: sc1,
  },
  {
    id: 7,
    title: "Be a Teacher",
    bgColor: "bg-white",
    imageUrl: sc3,
  },
  {
    id: 8,
    title: "Be a Teacher",
    bgColor: "bg-white",
    imageUrl: sc1,
  },
  {
    id: 9,
    title: "Be a Teacher",
    bgColor: "bg-white",
    imageUrl: sc2,
  },
  {
    id: 10,
    title: "Be a Teacher",
    bgColor: "bg-white",
    imageUrl: sc1,
  },
  {
    id: 11,
    title: "Be a Teacher",
    bgColor: "bg-white",
    imageUrl: sc3,
  },
  {
    id: 12,
    title: "Be a Teacher",
    bgColor: "bg-white",
    imageUrl: sc1,
  },
];

export default sixthSectionData;
