import React from 'react';
import backgroundImage from '../../../assets/HPDSxEngine/Section11imagebg.png'; // Import your background image

const Section11 = () => {
    return (
        <section
            className="h-[150vh] md:h-[120vh] lg:h-[125vh] xl:h-[110vh] bg-gray-200  bg-top md:bg-cover    lg:bg-center pb-8 md:pb-12 py-12 md:py-0 lg:py-12 lg:pb-12 pt-12 md:pt-0 xl:pt-12 px-4 md:px-0"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="  py-24 md:py-56  md:pb-12 pt-24 md:pt-44 lg:pt-32 xl:pt-40">
                <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl   text-black mb-4 px-4 md:px-24 lg:px-32 xl:px-40" style={{ lineHeight: '140%' }}>We develop our people to lead from day one... and the learning never stops</h1>

                <p className="text-md md:text-xl lg:text-2xl text-black px-4 md:px-24 lg:px-32 xl:px-40 text-justify" style={{ lineHeight: '140%' }}>Our commitment to the professional development of people is sacrosanct. We focus on technical, client relationship and technology skills, and reinforced by on-the-job learning. Our lifelong learning approach ensures our people continue to succeed at every stage of their career.

                    As well as financial support, in-house professional development support includes introductory sessions, tutorials, preparation workshops, helpline and mentoring, of which our allowances exceed the recommendations of the relevant professional bodies. </p>
            </div>
        </section>
    );
};

export default Section11;
