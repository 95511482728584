import Section4Image1 from '../../../assets/HPDSxMenu/Section4image1.png';
import Section4Image2 from '../../../assets/HPDSxMenu/Section4image2.png';
import Section4Image3 from '../../../assets/HPDSxMenu/Section4image3.png';


const DatasetSec4 = {
  buttons: [
    {
      id: 1,
      heading: "Creative Will",
      description: "Creative will power development to hone your skills and put your laziness on the line in pursuit of real you something greater",
      image: Section4Image1
    },
    {
      id: 2,
      heading: "Conscious Mind",
      description: "Conscious mind empowerment to drive your thoughts to focus and be in line with your long-term goals creating a unique blend of essence in your lifestyle",
      image: Section4Image2 
    },
    {
      id: 3,
      heading: "Core Genius",
      description: "Core genius transformation to uncover the untapped potential within you and turn your aspirations into reality by establishing a strong foundation",
      image: Section4Image3 
    }
  ]
};

export default DatasetSec4;
