import React from "react";
import Section1 from "../../components/TalentCollaboratory/Section1";
import Section2 from "../../components/TalentCollaboratory/Section2";
import Section3 from "../../components/TalentCollaboratory/Section3";
import Section4 from "../../components/TalentCollaboratory/Section4";
import Section5 from "../../components/TalentCollaboratory/Section5";
import Section6 from "../../components/TalentCollaboratory/Section6";
import Section7 from "../../components/TalentCollaboratory/Section7";
import Section8 from "../../components/TalentCollaboratory/Section8";
import Section9 from "../../components/TalentCollaboratory/Section9";
import Section10 from "../../components/TalentCollaboratory/Section10";
import Section11 from "../../components/TalentCollaboratory/Section11";

export const Talent = () => {
  return (
    <div className="flex flex-col font-poppins">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 />
    </div>
  );
};
