import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight , faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import backgroundImage from '../../../assets/Platform/Platformbg_img.png'; 

const LandingCover = () => {
  const containerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 20px', 
    textAlign: 'center',
  };

  const divStyle = {
    marginBottom: '20px',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  };

  const buttonStyle = {
    padding: '8px',
    textAlign: 'center',
    
    color: 'rgba(138, 99, 247, 1)',
    borderRadius: '25px',
    marginRight: '10px',
    
  };

  const borderButtonStyle = {
    ...buttonStyle,
    color: 'black',
    border: '2px solid black',
  };

  return (
    <div style={containerStyle}>
      <div style={divStyle} className="xl:text-3xl lg:text-2xl md:text-xl text-lg font-semibold text-black">
      Think Skil India- Technology Platform
      </div>
      <div style={divStyle} className="xl:text-4xl lg:text-3xl md:text-2xl text-xl font-bold text-blue-500">
      Human Potential Development System 
      </div>
      <div style={divStyle} className="xl:text-xl lg:text-lg md:text-md text-sm font-medium text-black">
      Not just Designed... But Engineered to Work
      </div>
      <div style={buttonContainerStyle}>
      <button style={borderButtonStyle} className=' hover:bg-neutral-100 hover:shadow-md cursor:pointer bg-tansparent xl:text-sm lg:text-sm md:text-sm text-xs  hover:scale-105 hover:bg-white'>Explore Now <span><FontAwesomeIcon icon={faChevronRight} /></span></button>
        <button style={buttonStyle} className='cursor:pointer bg-tansparent xl:text-sm lg:text-sm md:text-sm text-xs hover:scale-105 hover:bg-white '><span className='mr-2'><FontAwesomeIcon icon={faCirclePlay} /></span>Watch the video</button>
        
      </div>
    </div>
  );
};

export default LandingCover;


