import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import img_1 from "../../../assets/ThinkSkillIndia/img_20.png";

const Section1 = () => {
  const data1 = (
    <>
      Lead to a <span className="text-blue-500">disruptive transition</span> in
      the next <span className="text-blue-500">5-10 years</span>, in interplay
      with more{" "}
      <span className="text-blue-500">
        industry-specific change accelerators
      </span>
    </>
  );
  const data2 = {
    item1: (
      <>
        India’s GDP is expected to see the greatest rise in share of world GDP
        by 2050 (from 2% to 13%), making it the country with the second highest
        GDP by 2050. Some factors propelling this are an increased focus on
        manufacturing, skilled engineering and domestic growth, as well as a
        growing working class population (410 million in 2010 to 541 million by
        2025).
      </>
    ),
    item2: (
      <>
        India has plenty of unemployed Intellectual horsepower. We must continue
        to retrain the ones that work for a better future.
      </>
    ),

    item3: (
      <>
        India to compete, grow and generate jobs, we must ensure that we have
        the people who can lead the digital innovation and transformation of our
        industries.
      </>
    ),
    item4: (
      <>
        For New technologies are a key engine for growth fueled by the ideas of
        highly skilled professionals and business leaders. Demand is growing
        throughout industry to improve the quality of education and skills.
      </>
    ),
    item5: (
      <>
        We are not living a time of change, but a change of times. The concepts
        of leadership, talent, skill and attitude coined in the 20th century
        need be revised to match the pace of this new digital era.
      </>
    ),
  };

  const { ref, inView } = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="max-w-screen-2xl mx-auto  h-full xl:px-24 lg:px-16 md:px-8 xl:py-20 lg:py-16 md:py-12 py-12 px-4"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 80 },
      }}
      transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }}
    >
      <div
        className=" text-base md:text-2xl lg:text-4xl xl:text-4xl  font-semibold"
        style={{ lineHeight: "50px" }}
      >
        {data1}
      </div>
      <div className={`w-full xl:text-2xl lg:text-xl md:text-lg text-md`}>
        <motion.div
          className=" max-w-screen-xl mx-auto h-full grid grid-cols-12 gap-x-2 pt-10 pb-2 "
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.2,
                delayChildren: 0.5,
              },
            },
          }}
        >
          {/* Item 1 - from left */}
          <motion.div
            className="w-full h-full col-span-12 md:col-span-8 flex gap-2 md:gap-4 xl:py-10 lg:py-6 md:py-4 xl:px-6 lg:px-4 md:px-4  py-2 px-2 rounded-lg text-md"
            style={{ backgroundColor: "#FFF6E5" }}
            variants={{
              visible: {
                opacity: 1,
                x: 0,
              },
              hidden: {
                opacity: 0,
                x: -80,
              },
            }}
            transition={{ duration: 1.3, ease: "easeInOut", delay: 0.2 }}
          >
            <div className="text-lg text-justify">{data2.item1}</div>
            <img src={img_1} className="md:w-40 lg:w-48 xl:w-56 w-28" />
          </motion.div>
          {/* Item 2 - from top */}
          <motion.div
            className=" w-full col-span-12 md:col-span-4 flex gap-2 md:gap-0 xl:py-10 lg:py-6 md:py-4 xl:px-6 lg:px-4 md:px-4 py-2 px-2 rounded-lg font-semibold"
            style={{ backgroundColor: "#FFEBB8" }}
            variants={{
              visible: {
                opacity: 1,
                y: 0,
              },
              hidden: {
                opacity: 0,
                y: -80,
              },
            }}
            transition={{ duration: 1.3, ease: "easeInOut", delay: 0.4 }}
          >
            <div className="text-lg text-justify">{data2.item2}</div>
          </motion.div>
        </motion.div>
        <div className=" max-w-screen-xl mx-auto  h-full grid grid-cols-12 gap-x-2  ">
          {/* Item 3 - from bottom */}
          <motion.div
            className="w-full h-full col-span-12 md:col-span-4 flex gap-2 md:gap-0 xl:py-10 lg:py-6 md:py-4 xl:px-6 lg:px-4 md:px-4 py-2 px-2 rounded-lg font-semibold"
            style={{ backgroundColor: "#FFEBB8" }}
            variants={{
              visible: {
                opacity: 1,
                y: 0,
              },
              hidden: {
                opacity: 0,
                y: 80,
              },
            }}
            transition={{ duration: 1.3, ease: "easeInOut", delay: 0.4 }}
          >
            <div className="text-lg text-justify">{data2.item3}</div>
          </motion.div>
          {/* Item 4 - from right */}
          <motion.div
            className=" w-full col-span-12 md:col-span-8 flex  mt-2 md:mt-0 xl:py-10 lg:py-6 md:py-4 xl:px-6 lg:px-4 md:px-4 py-2 px-2 bg-yellow-500 rounded-lg "
            style={{ backgroundColor: "#FFDD86" }}
            variants={{
              visible: {
                opacity: 1,
                x: 0,
              },
              hidden: {
                opacity: 0,
                x: 80,
              },
            }}
            transition={{ duration: 1.3, ease: "easeInOut", delay: 0.4 }}
          >
            <div className="text-lg text-justify">{data2.item4}</div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Section1;
