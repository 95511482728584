
import Section9Image1 from '../../../assets/ChangeAgent/Section9image1.png';
import Section9Image2 from '../../../assets/ChangeAgent/Section9image2.png';
import Section9Image3 from '../../../assets/ChangeAgent/Section9image3.png';
import Section9Image4 from '../../../assets/ChangeAgent/Section9image4.png';
import Section9Image5 from '../../../assets/ChangeAgent/Section9image5.png';


const dataset = [
    {
      id: 1,
      heading: <>People</>,
      description: <>This game changing professional programs, human potential development and skill engineering, management and development of the 21st century, to the millions of you who are at a crossroads in life—who are affected by the current economic crisis and feeling helpless as to what you can do to secure your future.
      </>,
      image: Section9Image1,
    },
    {
      id: 2,
      heading: "Society",
      description: <>
      The reality of building capacity for the 21st century is that we do not know what the work of the future will be like or how technology will influence health and financial issues.
      </>,
      image: Section9Image2, // Replace this with the image for HPDSX Fueling
    },
    {
      id: 3,
      heading: "Economy",
      description: <>The reality of building capacity for the 21st century is that we do not know what the work of the future will be like or how technology will influence health and financial issues.
        
      </>,
      image: Section9Image3, // Replace this with the image for HPDSX Igniting
    },
    {
      id: 4,
      heading: "Industry",
      description: <>The reality of building capacity for the 21st century is that we do not know what the work of the future will be like or how technology will influence health and financial issues.
      </>,
      image: Section9Image4, // Replace this with the image for HPDSX Lifting up
    },
    {
      id: 5,
      heading: "Future",
      description: <>​The reality of building capacity for the 21st century is that we do not know what the work of the future will be like or how technology will influence health and financial issues.
      </>,
      image: Section9Image5, // Replace this with the image for HPDSX Orbiting
    },
  ];
  
  export default dataset;
  