import React from "react";
import volunteeringData from "./volunteerData";

const VolunteerSection2 = () => {
  return (
    <div className="bg-neutral-100">
      <div className="max-w-screen-xl mx-auto h-full py-8 xl:py-20 lg:py-12 md:py-4 px-4 flex flex-col items-center">
        <div className="text-center text-stone-900 text-xl xl:text-4xl lg:text-3xl md:text-2xl font-semibold">
          Key Areas that Volunteering Helps Build
        </div>
        <div className="flex max-w-screen-lg mx-auto justify-center items-center flex-wrap mt-8 xl:mt-8 lg:mt-6 md:mt-6">
          <div className="w-full grid grid-cols-12 gap-4 xl:gap-x-6 xl:gap-y-10 lg:gap-x-3 lg:gap-y-6">
            {volunteeringData.map((item, index) => (
              <div className="col-span-12 lg:col-span-4 md:col-span-6 md:p-3 md:px-4" key={index}>
                <div className="w-56 md:w-72 h-72 flex flex-col justify-center items-center rounded-xl  hover:shadow-lg bg-white">
                  <div className="w-full flex justify-center items-center">
                    <img
                      className="object-cover w-32 h-32 xl:w-32 xl:h-32 lg:w-32 lg:h-32 md:w-32 md:h-32 rounded-full"
                      src={item.imageUrl}
                      alt={item.alt}
                    />
                  </div>
                  <div className="w-full text-center py-3 px-3">
                    <div className="text-center w-full text-stone-900 xl:text-xl lg:text-lg md:text-xl font-bold my-2">
                      {item.title}
                    </div>
                    <div className="w-full text-center tracking-tighter text-stone-600 xl:text-lg lg:text-sm md:text-md">
                      {item.description}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteerSection2;
