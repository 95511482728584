import React, { useState } from 'react';
import { motion } from 'framer-motion'; 
import { useInView } from 'react-intersection-observer'; // Import useInView

import Section4Button1 from '../../../assets/HPDSxEngine/Section4Button1.png';
import Section4Button2 from '../../../assets/HPDSxEngine/Section4Button2.png';
import Section4Button3 from '../../../assets/HPDSxEngine/Section4Button3.png';

import DatasetSec4 from './DatasetSec14';

const Section14 = () => {
  const backgroundColor = '#0086E8';
  const [activeDataset, setActiveDataset] = useState(DatasetSec4[0]);

  const handleButtonClick = (id) => {
    const dataset = DatasetSec4.find(item => item.id === id);
    setActiveDataset(dataset);
  };

  const { ref, inView } = useInView(); // Initialize useInView

  // Dataset for the buttons
  const buttonDatasets = [
    { id: 1, image: Section4Button1, title: 'Title 1' },
    { id: 2, image: Section4Button2, title: 'title 2' },
    { id: 3, image: Section4Button3, title: 'Title 3' },
  ];

  return (
    <motion.section
      ref={ref} // Attach ref to motion component
      className="w-full py-12 -mt-44"
      style={{ background: backgroundColor }}
      initial={{ opacity: 0, y: 80 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }} // Use inView to trigger animations
      transition={{ ease: 'easeInOut', duration: 0.6 }}
    >
      <div className="max-w-screen mx-auto ">
        <h2 className="text-xl md:text-xl lg:text-3xl xl:text-4xl font-bold text-white text-center py-6 md:py-4 lg:py-12 xl:py-16">​WHAT IS <span className='text-yellow-400'>ENERGY</span> AT TEENAGE AND YOUNG AGE?</h2>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8 md:mt-12 lg:mt-6 xl:mt-4 bg-white mx-4 md:mx-12 lg:mx-20 xl:mx-40 rounded-lg p-3"
          initial={{ opacity: 0 }}
          animate={{ opacity: inView ? 1 : 0 }} // Use inView to trigger animations
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }}
        >
          {buttonDatasets.map(button => (
           <div key={button.id} className="col-span-1 md:col-span-1 justify-center items-center">
           <motion.div
             className={`text-center cursor-pointer md:text-sm lg:text-sm hover:bg-blue-400 hover:text-white text-black font-bold py-3 rounded-lg flex items-center justify-center md:border-r-2 ${activeDataset.id === button.id ? 'bg-blue-400 text-white border-blue-400 hover:border-transparent' : 'border-blue-400 hover:border-transparent'}`}
             onClick={() => handleButtonClick(button.id)}
             whileHover={{ scale: 1.1 }}
             whileTap={{ scale: 0.9 }}
           >
                <img src={button.image} alt="Image Description" className='mr-2' />
                {button.title}
              </motion.div>
            </div>
          ))}
        </motion.div>

        {activeDataset && (
          <motion.div
            className="col-span-12 bg-white rounded-xl p-3 md:p-4 lg:p-6 xl:p-8 my-8 md:my-8 lg:my-12 xl:my-20 mx-4 md:mx-12 lg:mx-20 xl:mx-40"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }} // Use inView to trigger animations
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
          >
            <div className="grid grid-cols-12 gap-4 lg:py-8 xl:py-12 " style={{ lineHeight: '140%' }}>
              {/* Left section */}
              <div className="md:col-span-6 col-span-12 p-4 xl:py-8">
                <h2 className="text-lg md:text-lg lg:text-xl xl:text-2xl font-bold mb-4 text-justify">{activeDataset.heading}</h2>
                <p className="text-lg md:text-lg lg:text-xl xl:text-2xl text-gray-600 mb-4 py-8 text-justify">{activeDataset.description}</p>
              </div>

              {/* Right section */}
              <div className="md:col-span-6 col-span-12 flex md:items-center md:justify-center lg:justify-center lg:items-start xl:items-center xl:justify-center">
                <motion.img
                  src={activeDataset.rightimage}
                  alt="Image"
                  className="w-full md:w-4/5 lg:w-full  rounded-lg"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: inView ? 1 : 0 }} // Use inView to trigger animations
                  transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
                />
              </div>
              
              
              {/* Right section */}
               {activeDataset.otherimage && activeDataset.content && (
    <>
      <div className=" md:col-span-3 col-span-12 flex md:items-center md:justify-center lg:justify-center lg:items-start xl:items-center xl:justify-center">
        <motion.img
          src={activeDataset.otherimage}
          alt="Additional Image"
          className="w-full md:w-4/5 lg:w-full rounded-md"
          initial={{ opacity: 0 }}
          animate={{ opacity: inView ? 1 : 0 }} // Use inView to trigger animations
          transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
        />
      </div>
      <div className="md:col-span-9 col-span-12 p-6 flex items-center justify-center">
        <p className="text-lg md:text-lg lg:text-xl xl:text-2xl text-gray-600 mb-4 text-justify">
          {activeDataset.content}
        </p>
      </div>
    </>
  )}
            </div>
          </motion.div>
        )}
      </div>
    </motion.section>
  );
};

export default Section14;
