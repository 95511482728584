import Section2_img from "../../../assets/Platform/Section2_img.png"


const sectiontwoData = [
    {
        id: 1,
        titleheading: "What does the Platform do?",
        description:
            "The Platform assists with the assessment, feedback, management, and monitoring of their mental ill health and maintenance of well-being by collecting personal and health information from a young person, their clinician(s), and supportive others. This information is stored, scored, and reported back to the young person, their clinicians, and the service provider to promote genuine collaborative care",
        imageUrl: Section2_img,
        bgColor: "white",
        button: false,
        buttonName: "",
    },

];
export default sectiontwoData;