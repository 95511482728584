import CardImage1 from "../../../assets/TalentCollaboratory/7Application/talentApp1.png";
import CardImage2 from "../../../assets/TalentCollaboratory/7Application/talentApp2.png";
import CardImage3 from "../../../assets/TalentCollaboratory/7Application/talentApp3.png";
import CardImage4 from "../../../assets/TalentCollaboratory/7Application/talentApp4.png";
import CardImage5 from "../../../assets/TalentCollaboratory/7Application/talentApp5.png";
import CardImage6 from "../../../assets/TalentCollaboratory/7Application/talentApp6.png";
import CardImage7 from "../../../assets/TalentCollaboratory/7Application/talentApp7.png";

const DatasetSec5 = [
  {
    id: 1,
    description: (
      <>
        Promote{" "}
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          Productivity & Efficiency
        </span>
      </>
    ),
    image: CardImage2,
  },
  {
    id: 2,
    description: (
      <>
        Collaborate and{" "}
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          Build
        </span>
      </>
    ),
    image: CardImage7,
  },
  {
    id: 3,
    description: (
      <>
        Alignment with{" "}
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          future skill{" "}
        </span>{" "}
        requirement
      </>
    ),
    image: CardImage6,
  },
  {
    id: 4,
    description: (
      <>
        Address{" "}
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          Lifelong Learning
        </span>
      </>
    ),
    image: CardImage1,
  },
  {
    id: 5,
    description: (
      <>
        Track the problem of{" "}
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          cognitive Overload
        </span>
      </>
    ),
    image: CardImage3,
  },
  {
    id: 6,
    description: (
      <>
        Create Uniquie Insights, critical to{" "}
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          decision making
        </span>{" "}
      </>
    ),
    image: CardImage4,
  },
  {
    id: 7,
    description: (
      <>
        Continually re-asses the set of{" "}
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          competency
        </span>{" "}
        required
      </>
    ),
    image: CardImage5,
  },
];

export default DatasetSec5;
