import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import BgImg from "../../../assets/Seminar/BgImg.png";
import CoverSideImg from "../../../assets/Seminar/CoverSideImg.png";

const LandingCover = () => {
  const bgImageStyle = {
    backgroundImage: `url(${BgImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div
      className="w-full h-full grid grid-cols-6"
      style={bgImageStyle}
    >
      <div className="order-2 md:order-1 col-span-6 md:col-span-3 place-content-start flex flex-col text-white py-16 xl:pl-28 xl:py-28 lg:pl-20 lg:py-24 md:pl-12 md:py-16">
        <div className="mb-2 md:mt-6 lg:mt-12 xl:mt-28 pb-4 font-semibold text-xl xl:text-4xl md:text-2xl lg:text-3xl cursor-pointer">
          <div className="mx-6 md:mx-0">
            Join Seminars to exchange, share and engage in discussions on
            subjects that you love
          </div>
        </div>
        <div className="ml-6 md:ml-0 mt-4 px-4 py-2 w-fit hover:bg-blue-500 hover:text-white cursor-pointer rounded-full border border-white hover:border-blue-100 flex items-center justify-start ">
          <div className="text-xs font-bold  ">Browse all Seminars</div>
          <FontAwesomeIcon icon={faChevronRight} className=" ml-2" />
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-6 md:col-span-3 flex justify-start items-start xl:-pl-4 xl:pt-28 lg:pt-16 md:pt-14">
        <div className="w-full h-full rounded-xl">
          <img
            src={CoverSideImg}
            alt="blank"
            className="w-full h-full object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
