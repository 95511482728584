import React from "react";
import LeftSideImage from "../Templates/LeftSideImage";
import smmain from "../../../assets/HomePage/Institution/imain.webp";

const Section3 = () => {
  const data = {
    title: (
      <>
        Study <span className="font-bold">Smarter</span>, Not{" "}
        <span className="font-bold">Harder</span>
      </>
    ),
    description: (
      <>
        Create tests that fit you perfectly. Score top grades and get ready for
        your dream job. It's all about you, your success, and your future !!
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <LeftSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section3;
