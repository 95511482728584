import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import cover_bg from "../../../assets/Communities/img_4.jpg";

const Section3 = () => {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };
  return (
    <div
      className="w-full h-[40vh] "
      style={{
        backgroundImage: `url(${cover_bg})`,
        backgroundSize: "cover", // Maintain original image size
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className=" text-black w-full h-full flex flex-col justify-center items-center cursor-pointer ">
        <div className="font-bold text-center  text-white text-3xl xl:text-6xl md:text-4xl lg:text-5xl ">
          <span className="text-yellow-400">Create </span>
          your Own
          <span className="text-yellow-400"> Community </span>
        </div>
        <div className="flex gap-5 font-semibold py-4 md:py-6 lg:py-10 ">
          <button
            className="text-white bg-violet-600 px-6 py-3 text-sm lg:text-base rounded-md hover:border hover:border-white hover:text-white hover:bg-transparent"
            type="submit"
            onClick={handleInputChange}
          >
            Get Started
            <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Section3;
