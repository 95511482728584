import React from 'react';
import LeftImage from '../../../assets/Collaboration/Section5image1.png'; // Import your left image here
import RightImage from '../../../assets/Collaboration/Section5image2.png'; // Import your right image here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Section5 = () => {
  return (
    <section className="w-full  py-8 md:py-20">
      <div className="max-w-screen grid grid-cols-12 gap-2 md:gap-2 lg:gap-4 xl:gap-8 px-4 md:px-4 lg:px-8 xl:px-16">
        {/* Left section */}
        <div className="col-span-12 md:col-span-3 lg:col-span-4">
          <img src={LeftImage} alt="Left Image" className="w-full h-auto" />
        </div>

        {/* Middle section */}
        <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col pt-8 md:pt-0">
          <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 text-blue-400"style={{ lineHeight: '140%' }}>Create a Experience Industry Love</h2>
          <p className="text-md md:text-lg lg:text-xl md:pt-0 xl:pt-4"style={{ lineHeight: '140%' }}>Everything you <span className='text-red-500'>need to measure and manage your ----------- experience</span></p>
          <p className="text-md md:text-lg lg:text-xl"style={{ lineHeight: '140%' }}>Our co-design environments are carefully designed <span className='text-green-500'>based on successful learning engagements to help students to work with industry experts and other partners </span>within highly focused collaborative environment.</p>
        
          <div className="md:ml-6 md:my-2  xl:my-10 md:ml-0 mt-4 px-6 py-3 w-fit text-white  hover:text-white cursor-pointer rounded-full border border-white hover:border-blue-100 flex items-center justify-start "style = {{background : 'rgb(0, 176, 240)'}}>
          <div className="text-sm font-bold  ">Let's Start</div>
          <FontAwesomeIcon icon={faArrowRight} className=" ml-2" />
        </div>
        
        </div>



        {/* Right section */}
        <div className="col-span-12 md:col-span-3 lg:col-span-4 pt-8 md:pt-0">
          <img src={RightImage} alt="Right Image" className="w-full h-auto" />
        </div>
      </div>
    </section>
  );
};

export default Section5;
