import React from "react";
import SideCoverImg from "../../../assets/Mission&Vision/SideCoverImg.png";

const LandingCover = () => {
  return (
    <div className="w-full h-full grid grid-cols-12 gap-0">
      <div className="order-2 md:order-1 col-span-12 md:col-span-4 bg-black ">
        <div className="flex flex-col px-6 py-8 xl:px-16 lg:px-6 xl:py-44 lg:py-28 md:px-8 md:py-36">
          <div className="opacity-90 text-white xl:text-xl lg:text-xl  font-semibold font-['Segoe UI'] uppercase tracking-widest mb-2">
            Mission and Vision
          </div>
          <div className="text-fuchsia-500 mb-6 text-2xl xl:text-4xl lg:text-3xl md:text-xl font-semibold font-Segoe UI">
            Develops Individual's <br />
            Mindsets behaviors, <br />
            Skill and Capabilities
          </div>
        </div>
      </div>
      <div className="col-span-12 md:col-span-8 order-1 md:order-2">
        <img
          className="h-full w-full object-cover"
          src={SideCoverImg}
          alt="Mission"
        />
      </div>
    </div>
  );
};

export default LandingCover;
