import Section4image1 from "../../../assets/Internship/Section9image1.png"
import Section4image2 from "../../../assets/Internship/Section9image2.png"



const dataset = [
    {
      id: 1,
      title: "Global Career Trajectory",
      heading:"A unique career journey with international exposure",
      description: "There are opportunities to make friends and build connections from all over the world. When you join, you join a powerful and global network of talented professionals and international clients. We support you to embrace opportunities and assignments across the firm and the global network, developing you for life within and beyond.",
      

      rightimage:Section4image1
    },
    {
      id: 2,
      title: "Innovative Collaboration Culture",
      heading:"Harnessing Collaboration and Innovation",
      description: "We promote a culture of collaboration ensuring we lead with purpose, values and innovation. Innovation is our key to success and we practice it in everything we do - from enhancing the services we offer clients and improving the way we do things within our business, to our development process. ​",
     
      rightimage:Section4image2
    },
   
    
  ];
  
  export default dataset;