import React from 'react';
import backgroundImage from '../../../assets/Enterprise/Section2imagebg.png'; // Import the background image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import icon1 from "../../../assets/CustomerSuccess/Section2cardicon1.png" ; 
import icon2 from "../../../assets/CustomerSuccess/Section2cardicon2.png" ; 
import icon3 from "../../../assets/CustomerSuccess/Section2cardicon3.png" ; 
import icon4 from "../../../assets/CustomerSuccess/Section2cardicon4.png" ; 
import image from "../../../assets/CustomerSuccess/Section2image1.png"

import img from "../../../assets/Support/Section3image.png"

const Section3 = () => {
    return (
        <section className="relative bg-cover bg-center py-24" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="px-4 md:px-8 lg:px-16 xl:px-24 text-black">

                <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl  font-bold md:px-8 mb-8 text-black" style={{ lineHeight: '140%' }}>Everything you need all in one place</h1>
                <div className='text-lg md:text-xl md:px-8 lg:text-2xl xl:text-3xl'>
                    The Hub has the support and services to help you get the most out of HPDSX.
                </div>
                <div className="grid grid-cols-12 gap-4 pt-8 md:pt-12 lg:pt-16 xl:pt-20">
                    {/* Image on the left */}
                    <div className="col-span-12 md:col-span-6 lg:col-span-4">
                        <img src={img} alt="Image" className="w-full h-full object-cover" />
                    </div>
                    {/* Cards on the right */}
                    <div className="col-span-12 md:col-span-6 lg:col-span-8">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            {/* Card 1 */}
                            <div className="bg-white rounded-lg p-4 flex flex-col justify-between ">
                                <img src={icon1} alt="Card 2 Image" className="w-16 h-16 " />
                                <h3 className="md:text-xl lg:text-2xl font-semibold " style={{ lineHeight: '140%' }}>Discover and Explore HPDSX</h3>
                                <div className='md:text-lg lg:text-xl text-gray-400'>Build your routine, Find your focus, Sharpen your mind & Develop your Potential</div>
                                <div className="flex items-center justify-start gap-3 w-full pt-8 md:pt-8 lg:pt-12 xl:pt-20">

                                <button className="text-blue-500 hover:text-blue-700 focus:outline-none"><FontAwesomeIcon icon={faAngleRight} className=' text-white rounded-lg p-4 'style={{ backgroundColor: 'rgb(0, 134, 232)' }} />
                                </button>

                                    <button className=''style={{ color: 'rgb(0, 134, 232)' }}>
                                        View Services
                                    </button>
                                </div>
                            </div>

                            {/* Card 2 */}
                            <div className="bg-white rounded-lg p-4 flex flex-col justify-between ">
                                <img src={icon2} alt="Card 2 Image" className="w-16 h-16 mb-4" />
                                <h3 className="md:text-xl lg:text-2xl font-semibold mb-2" style={{ lineHeight: '140%' }}>Subject Matter Expert</h3>
                                <div className='md:text-lg lg:text-xl text-gray-400'>Grow your expertise, take bold steps, and build an incredebile path, developing mastery and reprogramming through delieberate practice.</div>
                                <div className="flex items-center justify-start gap-3 w-full pt-8 md:pt-8 lg:pt-12 xl:pt-20">

                                <button className="text-blue-500 hover:text-blue-700 focus:outline-none"><FontAwesomeIcon icon={faAngleRight} className=' text-white rounded-lg p-4 'style={{ backgroundColor: 'rgb(0, 134, 232)' }} />
                                </button>

                                    <button className='' style={{ color: 'rgb(0, 134, 232)' }}>
                                    Transform My People 
                                    </button>
                                
                        
                                    
                                </div>
                            </div>

                            {/* Card 3 */}
                            <div className="bg-white rounded-lg p-4 flex flex-col justify-between ">
                                <img src={icon3} alt="Card 3 Image" className="w-16 h-16 mb-4" />
                                <h3 className="md:text-xl lg:text-2xl font-semibold mb-2" style={{ lineHeight: '140%' }}>Success Journey Maps</h3>
                                <div className='md:text-lg lg:text-xl text-gray-400'>Build the Capacity to perform Forward planning task for anything and everything.</div>
                                <div className="flex items-center  justify-start gap-3 w-full pt-8 md:pt-8 lg:pt-12 xl:pt-20">

                                <button className="text-blue-500 hover:text-blue-700 focus:outline-none"><FontAwesomeIcon icon={faAngleRight} className=' text-white rounded-lg p-4 ' style={{ backgroundColor: 'rgb(0, 134, 232)' }}/>
                                </button>

                                    <button className=''style={{ color: 'rgb(0, 134, 232)' }}>
                                    Explore Checklists
                                    </button>
                                </div>
                                    
                                
                            </div>

                            {/* Card 4 */}
                            <div className="bg-white rounded-lg p-4 flex flex-col justify-between ">
                                <img src={icon4} alt="Card 4 Image" className="w-16 h-16 mb-4" />
                                <h3 className="md:text-xl lg:text-2xl font-semibold mb-2" style={{ lineHeight: '140%' }}>Account Info</h3>
                                <div className='md:text-lg lg:text-xl text-gray-400'>See your account details, certifications, HPDSX Community and more.</div>
                                <div className="flex items-center justify-start gap-3 w-full pt-8 md:pt-8 lg:pt-12 xl:pt-20">

                                <button className="text-blue-500 hover:text-blue-700 focus:outline-none"><FontAwesomeIcon icon={faAngleRight} className='bg-blue-700 text-white rounded-lg p-4 'style={{ backgroundColor: 'rgb(0, 134, 232)' }} />
                                </button>

                                    <button className=''style={{ color: 'rgb(0, 134, 232)' }}>
                                    View Profile
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='pt-8 md:py-16 md:px-16'>
                    <img src={image} className='w-full h-full'/>
                </div>
            </div>
        </section>
    );
};

export default Section3;
