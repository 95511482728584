import c1 from "../../../../assets/Hpdsx/Section4_Img1.svg";
import c2 from "../../../../assets/Hpdsx/Section4_Img2.svg";
import c3 from "../../../../assets/Hpdsx/Section4_Img3.svg";
import c4 from "../../../../assets/Hpdsx/Section4_Img4.jpg";

const fourthSectionData = [
  {
    id: 1,
    name: "Innovation",
    imageUrl: c1,
  },
  {
    id: 2,
    name: "Entrepreneurship",
    imageUrl: c2,
  },
  {
    id: 3,
    name: "Mental Health & Wellness",
    imageUrl: c3,
  },
  {
    id: 4,
    name: "Motivation",
    imageUrl: c2,
  },
  {
    id: 5,
    name: "Innovation",
    imageUrl: c1,
  },
  {
    id: 6,
    name: "Entrepreneurship",
    imageUrl: c2,
  },
  {
    id: 7,
    name: "Mental Health & Wellness",
    imageUrl: c3,
  },
  {
    id: 8,
    name: "Motivation",
    imageUrl: c2,
  },
  {
    id: 9,
    name: "Innovation",
    imageUrl: c1,
  },
  {
    id: 10,
    name: "Entrepreneurship",
    imageUrl: c2,
  },
  {
    id: 11,
    name: "Mental Health & Wellness",
    imageUrl: c3,
  },
  {
    id: 12,
    name: "Motivation",
    imageUrl: c2,
  },
];

export default fourthSectionData;
