import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import sampleImage from "../../../assets/HPDSxMenu/Section11image1.png";
import DatasetSec11 from "./DatasetSec11";

const Section11 = () => {
  const backgroundColor = "#0086E8";
  const [expandedCard, setExpandedCard] = useState(1);

  const handleCardClick = (cardIndex) => {
    if (expandedCard === cardIndex) {
      setExpandedCard(0);
    } else {
      setExpandedCard(cardIndex);
    }
  };

  return (
    <div className="w-full">
      <div
        className="max-w-screen text-white"
        style={{ background: backgroundColor }}
      >
        <div className="px-4 md:px-8 lg:px-12 xl:px-20 md:py-16 py-8">
          {/* Heading */}
          <h2 className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold py-4 md:py-4 lg:py-6 xl:py-8">
            Your specific challenges need specific answers:
          </h2>

          {/* Subheading */}
          <h3
            className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold py-4 md:py-4 lg:py-6 xl:py-8"
            style={{ lineHeight: "140%" }}
          >
            HPDSX will help you find the most suitable solution to drive you
            toward a remarkable result
          </h3>

          {/* Grid */}
          <div className="grid grid-cols-12 my-8 md:my-12 md:gap-2 gap-4">
            {/* Left Section */}
            <div className="col-span-12 md:col-span-6">
              <img
                src={DatasetSec11?.find((e) => e.id === expandedCard)?.img}
                alt="Sample"
                className="w-full h-full object-cover object-left rounded-lg"
              />
            </div>

            {/* Right Section */}
            <div className="col-span-12 md:col-span-6">
              {/* Cards */}
              <div className="space-y-4">
                {DatasetSec11.map((card) => (
                  <div
                    key={card.id}
                    className={`md:px-4 md:py-4 lg:px-6 lg:py-6 xl:px-8 xl:py-8 border-l-4 ${
                      expandedCard === card.id
                        ? "border-white"
                        : "border-transparent"
                    } border-b p-4 transition-all duration-1000 ease-in-out`}
                    style={{ transition: "border-color 0.7s ease-in-out" }}
                  >
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => handleCardClick(card.id)}
                    >
                      <h4 className="md:text-2xl lg:text-3xl font-semibold mb-2 md:mb-4">
                        <span className="text-white font-bold">
                          {card.title}
                        </span>
                      </h4>
                      <FontAwesomeIcon
                        icon={
                          expandedCard === card.id ? faAngleUp : faAngleDown
                        }
                      />
                    </div>
                    <div
                      className={
                        expandedCard === card.id
                          ? " opacity-100 max-h-96 overflow-hidden transition-opacity duration-1000 ease-in"
                          : "opacity-0 max-h-0 overflow-hidden transition-opacity duration-1000 ease-in-out"
                      }
                    >
                      {/* Card Details */}
                      <p
                        className="md:text-lg lg:text-xl xl:text-2xl text-justify"
                        style={{ lineHeight: "140%" }}
                      >
                        {card.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section11;
