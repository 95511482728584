import React from 'react';
import backgroundImage from '../../../assets/HPDSxEngine/Section1imagebg.png';
import Section1Image from '../../../assets/HPDSxEngine/Section1image1.png'

const Section1 = () => {
  return (
    <section
      className="  bg-cover bg-center flex flex-col justify-center items-center py-12 md:py-20"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <h1 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl  text-white text-center font-bold mb-4 pt-4 md:pt-6 lg:pt-16 px-4 md:px-12 lg:px-28 xl:px-56"style={{ lineHeight: '140%' }}>Will Increase their <span className='text-yellow-400'>Marketability</span>, <span className='text-yellow-400'>Employability</span> and <span className='text-yellow-400'>Readiness</span> for Citizenship</h1>
      <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl  text-white font-semibold mb-6 px-8"style={{ lineHeight: '140%' }}><span className='text-yellow-400'>Help you</span> become <span className='text-yellow-400'>What</span> you <span className='text-yellow-400'>want to be</span></h2>
      <p className="text-md md:text-xl  lg:text-2xl text-white  text-center px-4 md:px-12 lg:px-28 xl:px-56"style={{ lineHeight: '140%' }}>Anything of better for your life purpose, something to aim for, you can plan, you can hope, you can dream, HPDSx Engine helps you get it done. Build your routine, find your focus, sharpen your mind, develop your creative attitude.</p>
      <img src={Section1Image} alt="Description" className="pt-12  md:mt-8  lg:mt-12 xl:mt-24 md:w-4/5 xl:w-3/5 h-4/5" />
    </section>
  );
};

export default Section1;
