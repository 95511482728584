import React, { useRef } from "react";

import Section1image from "../../../assets/HPDSxMenu/Section1image.png";

const Section1 = () => {
  return (
    <div className="w-full flex justify-center items-center font-semibold">
      <div
        className="max-w-screen py-20"
        style={{ backgroundColor: "#0086E8" }}
      >
        <div className="leading-relaxed text-white text-center justify-center items-center px-4 md:px-4 lg:px-16 xl:px-32">
          <div className="text-xl md:text-xl lg:text-2xl xl:text-3xl pt-4 md:pt-2 xl:pt-4">
            HUMAN POTENTIAL DEVELOPMENT SCIENCE
          </div>
          <div
            className="text-xl md:text-3xl lg:text-4xl xl:text-5xl my-4 md:my-4 lg:my-8 xl:my-12 md:px-12 lg:px-24 xl:px-40 font-semibold leading-loose"
            style={{ lineHeight: "1.5" }}
          >
            Discover the platform that gives you support, motivate and inspire
            to be the{" "}
            <span className="text-yellow-300">BEST VERSION OF YOURSELVES</span>{" "}
            for Action, Success and Happiness positively Impacts your Life.
          </div>
        </div>

        <div className="flex justify-center px-4 md:px-0">
          <img
            src={Section1image}
            alt=""
            className="mt-4"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Section1;
