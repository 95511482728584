import React from 'react';
import card1 from "../../../assets/Collaboratory/icons.png"
import card2 from "../../../assets/Collaboratory/icons (1).png"
import card3 from "../../../assets/Collaboratory/icons (2).png"
import card4 from "../../../assets/Collaboratory/icons (3).png"
import card5 from "../../../assets/Collaboratory/icons (4).png"
import card6 from "../../../assets/Collaboratory/icons (5).png"
import card7 from "../../../assets/Collaboratory/icons (6).png"
import card8 from "../../../assets/Collaboratory/icons (7).png"
import card9 from "../../../assets/Collaboratory/icons (8).png"
import card10 from "../../../assets/Collaboratory/icons (9).png"
import card11 from "../../../assets/Collaboratory/icons (10).png"
import card12 from "../../../assets/Collaboratory/icons (11).png"
import card13 from "../../../assets/Collaboratory/icons (12).png"
import card14 from "../../../assets/Collaboratory/icons (13).png"
import card15 from "../../../assets/Collaboratory/icons (14).png"
import card16 from "../../../assets/Collaboratory/icons (15).png"


// Sample dataset
const cardData = [
  { id: 1, image: card1, title: "Skill Data Reccomender" },
  { id: 2, image: card2, title: "Skill Base Career Recommender" },
  { id: 3, image: card3, title: "Skill Based Learner Engagement" },
  { id: 4, image: card4, title: "Skill Development Analytics and Insights" },
  { id: 5, image: card5, title: "Mapping skills" },
  { id: 6, image: card6, title: "Skill proficiency levels" },
  { id: 7, image: card7, title: "Skill Gap Recommender" },
  { id: 8, image: card8, title: "Skill Category Repository" },
  { id: 9, image: card9, title: "Specific Skill Information" },
  { id: 10, image: card10, title: "Skill Content" },
  { id: 11, image: card11, title: "Employee project and Odd job matching functionality" },
  { id: 12, image: card12, title: "Skill Based Mentoring and Coaching Functionality" },
  { id: 13, image: card13, title: "Career Aspiration Roadmap" },
  { id: 14, image: card14, title: "AI Based Recommender for Skills and Interests" },
  { id: 15, image: card15, title: "Encouraging Collaborative Work" },
  { id: 16, image: card16, title: "Matching Algorithim of user profile" },
  

];

const Section10 = () => {
  return (
    <section className="py-24">
      <div className=" text-center px-4 md:px-12 lg:px-24 xl:px-36">
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold mb-4 md:px-16">HPDSx Engine use AI to empowers organisation to <span className='text-blue-500' style={{ lineHeight: '140%' }}>Detect, Map, Infer, Update & Maintain skills data</span> </h2>
        <p className="text-lg text-gray-700 mb-8 mt-4">Plus Funnels To Get Customers To Find You</p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2  ">
          {cardData.map((card) => (
            <div key={card.id} className="text-center bg-gray-100 p-3 md:p-4 lg:p-6 xl:p-12 justify-center items-center hover:bg-slate-200 cursor-pointer " >
              <img src={card.image} alt={card.title} className="mx-auto hover:text-white" />
              <h3 className="text-md font-semibold mt-4">{card.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section10;
