import CardImage1 from '../../../../assets/Articles/sm_img1.png';
import CardImage2 from '../../../../assets/Articles/sm_img2.png';
import CardImage3 from '../../../../assets/Articles/sm_img3.png';

export const cardData = [
  {
    image: CardImage1,
    title: "Deep Dives and Comprehensive Guides",
    content: "Series of articles focusing on in-depth knowledge within specific fields or expertise",
  },
  {
    image: CardImage2,
    title: "Interviews",
    content: "Articles featuring conversations or Q&A sessions with individuals, experts, or personalities, highlighting their insights or experiences.",
  },
  {
    image: CardImage3,
    title: "Case Studies",
    content: "Detailed examinations of specific cases, scenarios, or projects, often used in academic or professional settings to analyze and draw conclusions.",
  },
  {
    image: CardImage1,
    title: "Technical or Academic Articles",
    content: " Dive into complex subjects, research findings, or technical topics within specific fields, often targeted towards experts or scholars.",
  },
  {
    image: CardImage2,
    title: "Profiles or Biographies",
    content: "Highlight the life, achievements, or characteristics of an individual, company, or organization.",
  },
  {
    image: CardImage3,
    title: "How-To Guides or Tutorials",
    content: "Provide step-by-step instructions, guidance, or tutorials on performing a specific task, skill, or process",
  },
  
];

export default cardData;