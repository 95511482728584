import React from "react";
import backgroundImage from "../../../assets/Collaboratory/Section6imagebg.png"; // Import the background image
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faPen } from "@fortawesome/free-solid-svg-icons";
import imga from "./newImages/imga.png";
import imgb from "./newImages/imgb.png";
import imgc from "./newImages/imgc.png";
import imgd from "./newImages/imgd.png";
import imge from "./newImages/imge.png";
import imgf from "./newImages/imgf.png";
import imgg from "./newImages/imgg.png";
import imgh from "./newImages/imgh.png";
import imgi from "./newImages/imgi.png";

import quote from "../TalentCollaboratory/Vector.png";

const data = [
  {
    text: (
      <>
        To <b>Value</b>, <b>Support, Develop</b> and <b>Utilize</b> the full
        potential of our staff, working with each other across our community to
        make the HPDSx Academy a stimulating and successful place to work.
      </>
    ),
    img: imgb,
    color: "#5C5BB7",
  },
  {
    text: (
      <>
        To provide a <b>Modern, Efficient</b> and <b>Stimulating</b> working and
        learning environment to sustain world-class academic and support
        activities.
      </>
    ),
    img: imgd,
    color: "#A53C03",
  },
  {
    text: (
      <>
        To <b>Maintain</b> and <b>enhance</b> our overall{" "}
        <b>Financial Strength</b> in order to deliver our strategic goals and
        enhance the Academy’s competitive position.
      </>
    ),
    img: imgf,
    color: "#3C6E56",
  },
  {
    text: (
      <>
        To ensure more <b>Quality Classroom time</b>, as many{" "}
        <b>Resources, Activities and Assessments</b> as possible are being put
        online so learners can study in their own time and accelerate their
        learning if they want to.
      </>
    ),
    img: "https://img.freepik.com/free-photo/woman-standing-children-sitting-desks_259150-60228.jpg?t=st=1715403846~exp=1715407446~hmac=90e24d3a30d1b8268ac40e354c457b153b92267cdb187e825a1fb53cf8e8c177&w=996",
    color: "#E71FA9",
  },
  {
    text: (
      <>
        This will address Four issues:
        <ul className=" gap-y-">
          {" "}
          <li className=" mt-4">
            <FontAwesomeIcon className="mr-4 text-sm" icon={faCircle} />
            Using <b>Student Learning</b> outcome results to improve teaching
            and learning.{" "}
          </li>
          <li className=" mt-4">
            <FontAwesomeIcon className="mr-4 text-sm" icon={faCircle} />
            The <b>Institutional Assessment</b> needs of workforce development
            organizations.
          </li>{" "}
          <li className=" mt-4">
            <FontAwesomeIcon className="mr-4 text-sm" icon={faCircle} />
            The <b>Assessment Needs</b> of individuals to help make informed
            choices about their careers.
          </li>{" "}
          <li className=" mt-4">
            <FontAwesomeIcon className="mr-4 text-sm" icon={faCircle} />
            The <b>Practical Needs</b> of practitioners for information about
            how to select and use different assessment tools.
          </li>
        </ul>
      </>
    ),
    img: imga,
    color: "#5C5BB7",
  },
  {
    text: (
      <>
        To <b>Build</b> on the <b>Academic Council</b> in Teaching and Learning
        quality systems and to extend quality assurance / improvement systems.
      </>
    ),
    img: imge,
    color: "#A53C03",
  },
  {
    text: (
      <>
        <b>Training design</b> and <b>Delivery Framework</b> modeled that will
        train and facilitate a few clearly guidelines that will work miracle
        with participants transforming and unusual way of thinking, doing and
        communicating into an easy and rewarding career path as well as personal
        development.
      </>
    ),
    img: imgg,
    color: "#3C6E56",
  },
  {
    text: (
      <>
        We will provide a <b>World-class Education for all Students</b> to serve
        our students by innovating and collaborating with educators, schools,
        parents, and community partners. Together, as a team, we prepare
        students to live, work, and thrive in a highly connected world.
      </>
    ),
    img: imga,
    color: "#E71FA9",
  },
  {
    text: (
      <>
        Promote <b>Individual Success and Responsibility for Accomplishments</b>
        through fostering a Student-Centered Learning Culture, Student Success
        and Academic Enhancement into the institutional core and emphasizes how
        best the Academy can engage and support students as they progress,
        achieve, and graduate.
      </>
    ),
    img: imgi,
    color: "#5C5BB7",
  },
];

const Section6 = () => {
  return (
    <section
      className="relative bg-cover bg-center py-16 px-4 md:px-8 lg:px-16 xl:px-24"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className=" ">
        <h2
          className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold mb-4 text-white text-center py-4"
          style={{ lineHeight: "140%" }}
        >
          The HPDSx Engine Is The Secret To{" "}
          <span className="text-blue-400">
            Re-Engineering Education, Classroom
          </span>{" "}
          and <span className="text-blue-400">Institutions</span>
        </h2>
        <div className="w-full grid grid-cols-12">
          {data?.map((e, index) => {
            if (index === 4) {
              return (
                <div key={index} className="col-span-12 w-full h-full p-6">
                  <div className="w-full h-full grid grid-cols-12">
                    <div className="col-span-12 md:col-span-6">
                      <img className="w-full h-full object-cover" src={e.img} />
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div
                        className={`w-full h-full  text-white p-2`}
                        style={{ backgroundColor: `${e.color}` }}
                      >
                        <div className="p-2">
                          <img className="w-16 h-10 object-cover" src={quote} />
                        </div>
                        <div className="p-2 text-xl font-normal ">
                          {e?.text}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key={index}
                  className="col-span-12 md:col-span-6 w-full h-full p-6"
                >
                  <div className="w-full h-full grid grid-cols-12">
                    <div className="col-span-12 md:col-span-6">
                      <img className="w-full h-full object-cover" src={e.img} />
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <div
                        className={`w-full h-full  text-white p-2`}
                        style={{ backgroundColor: `${e.color}` }}
                      >
                        <div className="p-2">
                          <img className="w-16 h-10 object-cover" src={quote} />
                        </div>
                        <div className="p-2 text-xl font-normal ">
                          {e?.text}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </section>
  );
};

export default Section6;
