import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import backgroundImage from "../../../assets/MainMenu/Section12imagebg.png";

const SectionWithBackgroundImage = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger the animation only once
    threshold: 0.25, // Percentage of the section visible to trigger the animation
  });

  return (
    <section
      ref={ref}
      className="relative bg-cover bg-center py-40"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute inset-0 flex justify-center items-center">
        <motion.div
          initial={{ opacity: 0, y: 80 }} // Initial animation state
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }} // Animation when in view
          transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }} // Animation transition
          className="text-center text-black font-semibold"
        >
          <h2 className="text-3xl md:text-4xl lg:text-5xl mb-4">We give young people the </h2>
          <p className="text-3xl md:text-4xl lg:text-5xl">Skills to Shape their Future</p>
        </motion.div>
      </div>
    </section>
  );
};

export default SectionWithBackgroundImage;
