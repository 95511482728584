import React from 'react'
import HeroImg from '../../assets/chapter_hero_img.png'
import S1 from '../../assets/chapter_s1.png'
import S2 from '../../assets/chapter_s2.png'
import S3 from '../../assets/chapter_s3.png'
import gImg1 from '../../assets/chapter_smImg1.png'
import gImg2 from '../../assets/chapter_smImg2.png'
import gImg3 from '../../assets/chapter_smImg3.png'
import SlideWithImage from "../components/Templates/SlideWithImage"
import HeroSection from "../components/Summits/HeroSection"
import Grid_1x3 from "../components/Summits/Grid_1x3"

const Summits = () => {
  const heroSectionInfo = {
    img:HeroImg,
    title: 'Reunite with your institute graduates based on location region or other shared interests',
    btn: {txt: 'Find chapters'}
  };

  const slideInfo1={
    img:S1,
    title: <div className='font-normal'>Fostering Togetherness, Embracing Fellowship</div>,
    subTit: <div className="font-normal">Through various activities, events, and engagements, we promote inclusivity, empathy, and understanding among alumni</div>,
    preTitle: 'COMMUNITY AFFAIRS'
  };

  const slideInfo2={
    img:S2,
    title: 'Alumni Professional Roundtables',
    subTit: <div className="font-normal">Hosting discussions or roundtables on industry-specific topics, allowing alumni to exchange ideas and expertise</div>,
    preTitle: 'INDUSRTRY INSIGHT EXCHANGE '
  };

  const slideInfo3={
    img:S3,
    title: 'Alumni-Led Fundraising for Educational Advancement and Growth',
    subTit: <div className="font-normal">Initiating fundraising efforts to support scholarships, facilities, or programs at the alma mater, contributing to its growth and development</div>,
    preTitle: 'EMPOWERING THE FUTURE'
  };


  const data = [
    {
      img: gImg1, 
      title: <div className='text-black'>Networking Opportunities</div>, 
      subTtl: 'Host networking events where former students can connect with others in their field or profession. These connections can lead to job opportunities, mentorship, or collaborations.'
    },
    {
      img: gImg2, 
      title: <div className='text-black'>Professional Development</div>, 
      subTtl: 'Workshops, seminars, or speaker series are organized which provide valuable insights, advice, and skills enhancement in various professional areas.'
    },
    {
      img: gImg3, 
      title: <div className='text-black'> Support System</div>, 
      subTtl: 'Being part of an alumni chapter means having a community of like-minded individuals who share a common background. This support system can offer advice, guidance, and emotional support during personal or professional transitions.'
    },
  ]
  return (
    <div>
      <HeroSection slideInfo={heroSectionInfo} contStyle ={`  py-20 flex-col md:flex-row-reverse chapter_hero_bg text-white`}/>
      <SlideWithImage slideInfo={slideInfo1} contStyle ={`bg-neutral-100  py-16 `}/>
      <Grid_1x3 title= {'Key features of a chapter'} data={data}/>
      <SlideWithImage slideInfo={slideInfo2} contStyle ={`bg-white  md:py-20 py-7 flex-row-reverse`}/>
      <SlideWithImage slideInfo={slideInfo3} contStyle ={`bg-white  md:py-20 py-7`}/>
    </div>
  )
}

export default Summits