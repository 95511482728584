import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import DatasetSec3 from "./DatasetSec3";
import Section3imagebg from "../../../assets/HPDSxMenu/Section3imagebg.png";

const Section3 = () => {
  const { leftSection, rightSection } = DatasetSec3;
  const { ref, inView } = useInView({ triggerOnce: false });

  return (
    <div className="w-full">
      <div className="max-w-screen">
        <div
          className="grid grid-cols-12"
          style={{
            backgroundImage: `url(${Section3imagebg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Left Section */}
          <motion.div
            className="col-span-12 md:col-span-6 flex flex-col px-4 md:px-8 lg:px-12 xl:px-20 py-8 md:py-16 lg:py-24 xl:py-32 relative z-10"
            ref={ref}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: inView ? 1 : 0, y: 0 }}
            transition={{ duration: 0.8, ease: "easeInOut", delay: 0.5 }}
          >
            <div
              className="t font-bold text-xl md:text-xl lg:text-2xl xl:text-3xl py-2"
              style={{ lineHeight: "120%", color: "rgb(0, 134, 232)" }}
            >
              {leftSection.title}
            </div>
            <div
              className="font-bold text-xl md:text-3xl lg:text-4xl xl:text-5xl py-4 justify-center items-center"
              style={{ lineHeight: "140%" }}
            >
              {leftSection.subTitle}
            </div>
            <div
              className="font-medium md:text-lg lg:text-xl xl:text-xl my-2 justify-center items-center text-justify"
              style={{ lineHeight: "140%" }}
            >
              {leftSection.description}
            </div>

            <div className="grid grid-cols-12   justify-center items-center">
              {leftSection.cards.map((card) => (
                <div
                  key={card.id}
                  className="col-span-12 md:col-span-4 p-4 rounded-lg flex flex-col items-center justify-center"
                >
                  <img
                    src={card.image}
                    alt={card.title}
                    className="w-20 md:w-4/5 lg:w-3/5 mb-2"
                  />
                  <p className="text-lg md:text-lg xl:text-xl text-center font-bold">
                    {card.title}
                  </p>
                </div>
              ))}
            </div>

            <div
              className="font-medium text-lg md:text-lg lg:text-xl xl:text-xl my-[2px] px-2 justify-center items-center"
              style={{ lineHeight: "140%" }}
            >
              {rightSection.additionalDescription}
            </div>
          </motion.div>

          {/* Right Section */}
          <motion.div
            className="col-span-12 md:col-span-6 flex justify-center items-center relative z-10 px-2 pb-8 md:px-0 md:pb-0"
            initial={{ opacity: 0, x: 70 }}
            animate={{ opacity: inView ? 1 : 0, x: 0 }}
            transition={{ duration: 1, ease: "easeInOut", delay: 0.5 }}
          >
            <img
              src={rightSection.backgroundImage}
              alt="Image"
              className="w-full md:w-full lg:w-4/5"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
