import React from "react";
import section6Img from "../../../assets/About Hpds/section6img.png";
import commonDataSet from "../DataSet/commonDataSet";

const Section5 = () => {
  const data = commonDataSet[32];

  const { imageUrl, title, description } = data;

  return (
    <div className="px-4 py-8 md:px-12 lg:px-20 xl:px-28 xl:py-24 lg:py-14 md:py-12 bg-sky-50">
      <div className="grid grid-cols-12 gap-4 md:gap-1 xl:gap-10 flex flex-row justify-between">
        <div className="order-2 md:order-1 col-span-12 md:col-span-6">
          <div className="flex-col justify-start items-start gap-0 md:gap-4">
            <div className="w-28 h-2 xl:h-3 mt-6 md:mt-24 lg:mt-28 xl:mt-56 bg-gradient-to-r from-blue-500 to-fuchsia-400"></div>
            <div className="text-stone-900 xl:text-4xl lg:text-3xl md:text-2xl text-lg font-semibold mb-4 md:mb-8 pt-1 xl:pt-8 lg:pt-6 md:pt-4 text-left cursor-pointer">
              {title}
            </div>
            <div className="text-stone-900 text-xs xl:text-lg lg:text-md md:text-xs text-left cursor-pointer">
              {description}
            </div>
          </div>
        </div>

        <div className="order-1 md:order-2 col-span-12 md:col-span-6">
          <img
            className="w-72 h-full md:w-full md:h-80 lg:h-full rounded-xl hover:shadow-2xl hover:border-gray-300"
            src={imageUrl}
            alt={title}
          />
        </div>
        <div className="order-3 mt-6 col-span-12">
          <img className="w-full h-full rounded-md" src={section6Img} />
        </div>
      </div>
    </div>
  );
};

export default Section5;
