import React from "react";
import img_2 from "../../../assets/Collaboratory/Section1image2.png";
import img_3 from "../../../assets/TalentCollaboratory/Section1_image1.png";
import title_sm from "./Images/title-sm.webp"
import title from "./Images/title.png"

import cover_bg from "../../../assets/TranstechCollaboratory/Section1imagebg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight , faCirclePlay ,faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const data = {
  item1: (
    <>
      We help realising that our minds, bodys & emotions must be engaged and transformed for learning and overall development.
    </>
  ),
  item2: (
    <>
     HPDSX ENGINE will Increasingly ADOPT the MENTAL MODEL OF Individuals with a set of teachable, learnable be haviours that all human beings can continue to develop and improve throughout there lifetime
    </>
  ),
  item3: <>Get Started In Less Than 60 Seconds • Cancel Anytime</>,
};

const Section1 = () => {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };
  return (
    <div
      className="h-[165vh] md:h-full md:w-full xl:py-20 lg:py-36 md:py-20 py-8 px-4 xl:px-24 lg:px-16 md:px-8 "
      style={{
        backgroundImage: `url(${cover_bg})`,
        backgroundSize: "cover", // Maintain original image size
        backgroundPosition: "center", // Center the image
      }}
    >
      <div className="flex flex-col text-white items-center mt-8 md:mt-2  md:px-10  md:pb-24 text-2xl xl:text-4xl md:text-3xl  cursor-pointer ">
        <div className=" text-center xl:text-3xl lg:text-2xl md:text-xl text-base " style={{ lineHeight: '140%' }}>
          {data.item1}
        </div>
        <img src={title} className="  w-full" />
        <div className=" text-center text-lg xl:text-3xl lg:text-2xl md:text-xl xl:px-8 lg:px-16 md:px-12 " style={{ lineHeight: '140%' }}>
          {data.item2}
        </div>
        <div className=" flex xl:gap-5 lg:gap-3 md:gap-1 gap-3 py-8 xl:py-8 lg:py-6 md:py-4 font-semibold">
          <button
            className="text-white bg-blue-500 lg:px-8 md:px-4 px-[4px] lg:py-4 md:py-2 py-[2px] text-sm md:text-md lg:text-lg rounded-full hover:border hover:border-blue-500 hover:text-blue-500 hover:bg-transparent whitespace-nowrap"
            type="submit"
            onClick={handleInputChange}
          >
            Get Started Now
            <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
          </button>
          <button
          className="  hover:text-blue-500 hover:bg-transparent">
            <FontAwesomeIcon icon={faCirclePlay} size= "x" style={{color: "#1285de",}}  className=""/>
          </button>
          <button className="text-white font-bold bg-transparent text-sm md:text-lg">
            How it works
          </button>
        </div>
        <div className="xl:text-md lg:text-sm text-xs lg:-mt-4 -mt-2">
          {data.item3}
        </div>

        <div className="w-full flex flex-col md:flex-row items-center md:items-start justify-around  my-6 md:my-12 lg:my-20 font-semibold">
        <div className="text-sm lg:text-sm xl:text-xl"><span className="mr-2"><FontAwesomeIcon icon={faCircleCheck} style={{color: "#356dd0",}} /></span>One platform, infinite possibilities</div>
        <div className="text-sm lg:text-sm xl:text-xl"><span className="mr-2"><FontAwesomeIcon icon={faCircleCheck} style={{color: "#356dd0",}} /></span>Developing the Minds of the Future</div>
        <div className="text-sm lg:text-sm xl:text-xl"><span className="mr-2"><FontAwesomeIcon icon={faCircleCheck} style={{color: "#356dd0",}} /></span>Infused with our Vedic Teachings</div>
        </div>
        <img
          src={img_3}
          className="w-full h-full xl:py-10  "
        />
      </div>
    </div>
  );
};

export default Section1;
