import React from 'react';
import YourImage from '../../../assets/Collaboration/Section3image1.png'; // Import your image here

const Section3 = () => {
  return (
    <section className="w-full bg-gray-200 py-8 md:py-20 ">
      <div className="max-w-screen text-center text-blue-500 font-semibold px-4 md:px-0">
        <p className="text-xl md:text-xl lg:text-2xl  mb-8  md:px-20 lg:px-32 xl:px-44"style={{ lineHeight: '140%' }}>HPDSx Platform supports the entire Institutional journey with a single, Modern Interface. <span className='text-green-500'>Your Platform to Excellent teaching, Innovative research, and the Personal and Intellectual growth of its Students in a diverse Academic community.</span></p>
        <img src={YourImage} alt="Description" className="mx-auto w-full md:w-4/5" />
      </div>
    </section>
  );
};

export default Section3;
