import React from "react";
import LandingCover from "../../components/Organisations/LandingCover";
import CoverIndex from "../../components/Organisations/CoverIndex";
import Organisation from "../../components/Organisations/Organisation";

export default function Organisations() {
  return (
    <div className="flex flex-col bg-orange-50">
      <LandingCover />
      <CoverIndex />
      <Organisation />
    </div>
  );
}
