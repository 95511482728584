import React from 'react';
import img1 from "../../../assets/SkillCollaboratory/Section3image1.png"
import img2 from "../../../assets/SkillCollaboratory/Section3image2.png"
import img3 from "../../../assets/SkillCollaboratory/Section3image3.png"
import img4 from "../../../assets/SkillCollaboratory/Section3image4.png"
import img5 from "../../../assets/SkillCollaboratory/Section3image5.png"
import img6 from "../../../assets/SkillCollaboratory/Section3image6.png"
import img7 from "../../../assets/SkillCollaboratory/Section3image7.png"

const Section2 = () => {
  return (
    <section className="  py-20 md:pt-20">
      <div className="">
        <div className='text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center font-medium  px-4 md:px-8 lg:px-16 xl:px-24 font-semibold' style={{ lineHeight: '140%' }}>
        Develop the Knowledge, Perspective and <br></br>Abilities that Global Employers are looking for 
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-20 md:gap-8 lg:gap-6 xl:gap-16  mx-12 md:mx-24 lg:mx-12 lg:ml-20 xl:ml-20 pt-10 md:pt-12">

           
          <div className="relative">
            <div className="bg-purple-900  h-96">Card 1</div>
            <img src={img1} alt="Image 1" className="absolute bottom-4 right-4 w-full h-96" />
          </div>
          <div className="relative">
            <div className="bg-purple-900  h-96">Card 2</div>
            <img src={img2} alt="Image 2" className="absolute bottom-4 right-4 w-full h-96" />
          </div>
          <div className="relative">
            <div className="bg-purple-900  h-96">Card 3</div>
            <img src={img3} alt="Image 3" className="absolute bottom-4 right-4 w-full h-96" />
          </div>
          <div className="relative">
            <div className="bg-purple-900  h-96">Card 4</div>
            <img src={img4} alt="Image 4" className="absolute bottom-4 right-4 w-full h-96" />
          </div>
          
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-12 md:gap-8 lg:gap-6 xl:gap-16 mx-12 md:mx-24 lg:mx-0 lg:ml-40 xl:ml-72  my-20 ">
        
          <div className="relative">
            <div className="bg-purple-900  h-96">Card 5</div>
            <img src={img5} alt="Image 5" className="absolute bottom-4 right-4 w-full h-96" />
          </div>
          
          <div className="relative">
            <div className="bg-purple-900  h-96">Card 6</div>
            <img src={img6} alt="Image 6" className="absolute bottom-4 right-4 w-full h-96" />
          </div>
          
          <div className="relative">
            <div className="bg-purple-900  h-96">Card 7</div>
            <img src={img7} alt="Image 7" className="absolute bottom-4 right-4 w-full h-96" />
          </div>
          
          
        </div>
      </div>
    </section>
  );
};

export default Section2;
