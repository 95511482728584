import React from "react";
import RightSideImage from "../Templates/RightSideImage";
import smmain from "../../../assets/NonProfitOrganisation/Section2_image.png";

const Section1 = () => {
  const data = {
    description: (
      <>
        Leverage HPDS advocacy and Influence in Industries and Communities and
        advance important social or environmental goals and amplify the impact
        of your work
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <RightSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section1;
