



import platformIncImg01 from "../../../assets/Platform/platformIncImg01.png";
import platformIncImg02 from "../../../assets/Platform/platformIncImg02.png";
import platformIncImg03 from "../../../assets/Platform/platformIncImg03.png";
import platformIncImg04 from "../../../assets/Platform/platformIncImg04.png";





const dataSet3 = [
  {
   
    bannerTitle: "What Does Platform Include?",
    bannerDec:
      "The Platform assists with the assessment, feedback, management, and monitoring of their mental ill health and maintenance of well-being by collecting personal and health ",
    items: [
      {
        itemImg: platformIncImg01,
        itemTitle: "Online psychological interventions",
        itemDesc:
          "Provide just-in-time, evidence-based interventions to help you manage your mental health and build positive work habits over time to prevent burnout and reduce stress.",
      },
      {
        itemImg: platformIncImg02,
        itemTitle: "Deliver Personalized and Measurement-Based Care",
        itemDesc:
          "To help people learn and implement concrete coping skills to better manage negative events and emotions.",
      },
      {
        itemImg: platformIncImg03,
        itemTitle: "Digital Learning",
        itemDesc:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      {
        itemImg: platformIncImg04,
        itemTitle: "Consulting",
        itemDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      },
      
      
    ],
  },
  
  
];

export default dataSet3;