import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "tailwindcss/tailwind.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sectionData from "./firstSectionData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const FirstSection = () => {
  const sliderRef = useRef(null);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToScroll: 1,
    slidesToShow: slidesToShow,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1160) {
        setSlidesToShow(3.05); // xl or more
      } else if (window.innerWidth >= 1024) {
        setSlidesToShow(3); // lg
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(3); // md
      } else {
        setSlidesToShow(1); // sm
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMoveLeft = () => {
    if (sliderRef.current && currentSlide > 0) {
      sliderRef.current.slickPrev();
    }
  };

  const handleMoveRight = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className="max-w-screen-xl mx-6 xl:mx-28 lg:mx-24 md:mx-16 py-6 md:py-12 bg-white relative">
      <div className="text-2xl font-semibold font-Poppins">
        Your Journey starts here
      </div>
      <div className="flex items-center justify-center mt-8">
        {currentSlide > 0 && (
          <div className="-ml-4 xl:-ml-6 absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
            <div
              className="w-12 h-12 rounded-full flex items-center justify-center hover:border border-gray-400 shadow-lg hover:shadow-2xl  bg-white cursor-pointer text-black"
              onClick={handleMoveLeft}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
            </div>
          </div>
        )}
        <Slider
          {...settings}
          className="flex w-full overflow:hidden"
          ref={sliderRef}
        >
          {sectionData.map((section) => (
            <div
              key={section.id}
              className="outline-none focus:outline-none relative"
            >
              <div className="flex-col justify-start items-start gap-4 mx-3 xl:pl-4">
                <img
                  className="xl:w-full xl:h-full lg:w-full lg:h-full md:w-full md:h-full relative rounded-lg object-cover"
                  src={section.imageUrl}
                  alt={section.title}
                />
                <div className="text-stone-900 xl:text-xl lg:text-lg md:text-sm font-semibold font-Poppins">
                  {section.title}
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="-mr-4 xl:-mr-6 absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
          <div
            className="w-12 h-12 rounded-full flex items-center justify-center shadow-lg hover:shadow-2xl hover:border border-gray-400 bg-white cursor-pointer text-black"
            onClick={handleMoveRight}
          >
            <FontAwesomeIcon icon={faChevronRight} className="text-lg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
