import React from 'react'
import Ttl_Img_Card from './Ttl_Img_Card'

const Grid_2x3 = ({ title, data }) => {
  return (
    <div className="flex flex-col items-center justify-center md:py-5 lg:w-8/12 w-11/12 mx-auto bg-white">
      <div className="py-10 items-start text-center mx-auto w-full">
        <div className="font-semibold text-4xl">{title}</div>
      </div>
      <div class="grid grid-cols-2 md:grid-cols-3 lg:gap-5 md:gap-3 md:pb-20 justify-center w-full">
        {data.map(d => (
          <div className=''>
            <Ttl_Img_Card img={d.img} title={d.title} subTtl={d.subTtl} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Grid_2x3