import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import bgImage from '../../../assets/HPDSxMenu/Section7imagebg.png';
import DatasetSec7 from './DatasetSec7';

const Section7 = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Allow animation to trigger multiple times
    threshold: 0.3, // Adjust as needed
  });

  const sectionVariants = {
    hidden: { opacity: 0, y: 80 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.7, ease: 'easeInOut', delay: 0.4 },
    },
  };

  return (
    <motion.div
      ref={ref}
      className="bg-cover bg-center md:py-4 xl:py-16 h-[120vh] flex flex-col justify-end text-center"
      style={{ backgroundImage: `url(${bgImage})` }}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={sectionVariants}
    >
      <div className="container mx-auto px-4">
        {/* Cards */}
        <div className="grid grid-cols-1 md:grid-cols-10 xl:gap-8 lg:gap-4 md:gap-2 gap-5">
          {DatasetSec7.map((card, index) => (
            <motion.div
              key={card.id}
              className={`col-span-1 md:col-span-2 bg-gray-200 p-4 md:p-2 lg:p-4 xl:p-6 rounded-lg bg-opacity-80 md:bg-opacity-70`}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={{
                hidden: { opacity: 0, y: 80 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: { duration: 0.8, ease: 'easeInOut', delay: index * 0.5 },
                },
              }}
            >
              <p className="text-xl md:text-xl lg:text-2xl xl:text-2xl font-semibold">
                {card.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default Section7;
