
import s4 from "./s4.png"
import s5 from "./s5.png"
import s6 from "./s6.png"
const dataset = [
    {
      id: 1,
      title: <><span className="text-blue-500">4. Academic</span> achievement system</>,
      description: <>Whatever your end goal is — developing the next life-changing app, fixing a major world problem, and making an impact in a particular field— take some time to find out why you are so drawn to that goal and why it means so much to you.​</>,
      img: s4
    },
    {
      id: 2,
      title: <><span className="text-blue-500">5. Professional</span> management system</>,
      description: <>Be genuine and know your worth. You may think it’s your idea that makes you special; but it’s actually your unique perspective, hard work, and skill that make you stand out.​​</>,
      img: s5
    },
    {
      id: 3,
      title: <><span className="text-blue-500">6. Personal</span> improvement system</>,
      description: <>Think about what you want to achieve, why it’s important to you, and how you’re going to do it. Leave the rest — insecurity about your age, the whispers of naysayers, fear of not being taken seriously — to the side, as it’s not useful.</>,
      img: s6
    },
  ];
  
  export default dataset;
  