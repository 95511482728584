import React from "react";
import Section1 from "../../components/Enterprise/Section1";
import Section2 from "../../components/Enterprise/Section2";
import Section3 from "../../components/Enterprise/Section3";
import Section4 from "../../components/Enterprise/Section4";
import Section5 from "../../components/Enterprise/Section5";
import Section6 from "../../components/Enterprise/Section6";
import Section7 from "../../components/Enterprise/Section7";
import Section8 from "../../components/Enterprise/Section8";



export const Enterprise = () => {
    return (
        <div className="flex flex-col font-poppins">
   <Section1/>
    <Section2/>
    
    <Section3/>
    <Section4/>
    
     <Section5/>
    <Section6/>
    <Section7/>
    <Section8/>
   
    
    </div>
  )
}

