import React from "react";
import hero_img from "../../../assets/Publication/hero_img.png";

const LandingCover = () => {
  return (
    <div className="w-full md:h-[90vh] grid grid-cols-12 gap-0 md:gap-4 xl:gap-8 pl-2 px-4 xl:pt-12 xl:px-12 xl:py-12 lg:px-8 lg:py-8 md:px-4 md:py-4 bg-gradient-to-tr from-blue-400 to-cyan-200" >
      <div className="  text-white md:px-5 order-2 md:order-1 col-span-12 md:col-span-6 flex flex-col cursor-pointer  justify-center items-center">
        <div className="mb-0 md:mb-2 pb-4 md:pb-1 xl:pb-4 font-bold text-2xl md:text-3xl xl:text-5xl lg:text-4xl leading-tight md:leading-tight lg:leading-tight xl:leading-tight">
          Transforming Ideas into Captivating Publications - Your Club,
          Your Story!
        </div>
        <div className="text-lg lg:text-xl xl:text-3xl py-4 md:py-6 lg:py-8 xl:py-12 ">
          <div>
            We specialize in crafting transformative storytelling experiences.
            From capturing your journey to creating engaging activity books for
            young minds, we bring your visions to life with passion and
            creativity.
          </div>
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-12 md:col-span-6 flex flex-col justify-center items-center py-6 xl:py-12 md:py-1 lg:py-8">
        <div className="w-full">
          <img src={hero_img} alt="blank" className="w-full xl:w-5/6" />
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
