import React from "react";
import LandingCover from "../../components/Seminar/LandingCover";
import SeminarSection2 from "../../components/Seminar/SeminarSection2";
import SeminarSection1 from "../../components/Seminar/SeminarSection1";
import SeminarSection3 from "../../components/Seminar/SeminarSection3";
import SeminarSection4 from "../../components/Seminar/SeminarSection4";

export default function Scientist() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <SeminarSection1 />
      <SeminarSection2 />
      <SeminarSection3 />
      <SeminarSection4 />
    </div>
  );
}
