


import Section3Card1 from '../../../assets/HPDSxEngine/Section3Card1.png'
import Section3Card2 from '../../../assets/HPDSxEngine/Section3Card2.png'
import Section3Card3 from '../../../assets/HPDSxEngine/Section3Card3.png'
import Section3Card4 from '../../../assets/HPDSxEngine/Section3Card4.png'
import Section3Card5 from '../../../assets/HPDSxEngine/Section3Card5.png'
import Section3Card6 from '../../../assets/HPDSxEngine/Section3Card6.png'
import Section3Card7 from '../../../assets/HPDSxEngine/Section3Card7.png'
import Section3Card8 from '../../../assets/HPDSxEngine/Section3Card8.png'

const datasetSec3 = [
    {
      id: 1,
      title: "My E-Profile",
      image: Section3Card1,
      description: "Description for My E-Profile"
    },
    {
      id: 2,
      title: "My E-Skills",
      image: Section3Card2,
      description: "Description for My E-Skills"
    },
    {
      id: 3,
      title: "My E-PLE",
      image: Section3Card3,
      description: "Description for My E-PLE"
    },
    {
      id: 4,
      title: "My E-Portfolio",
      image: Section3Card4,
      description: "Description for My E-Portfolio"
    },
    {
      id: 5,
      title: "My E-Adviser",
      image: Section3Card5,
      description: "Description for My E-Adviser"
    },
    {
      id: 6,
      title: "My E-Resources",
      image: Section3Card6,
      description: "Description for My E-Resources"
    },
    {
      id: 7,
      title: "My E-Expertise",
      image: Section3Card7,
      description: "Description for My E-Expertise"
    },
    {
      id: 8,
      title: "My E-SkillScore",
      image: Section3Card8,
      description: "Description for My E-SkillScore"
    },
    // Add more objects for additional cards if needed
  ];
  
  export default datasetSec3;
  