import React from "react";
import RightSideImage from "../../components/Templates/RightSideImage";
import img_4 from "../../../assets/LocalActivities/img_4.jpg";

const Section4 = () => {
  const data = {
    title: <>Make a Difference Together</>,
    description: (
      <>
        Our platform provides a space to share your initiatives with a wider
        audience. Whether it's a volunteer effort or a community event, we're
        here to gently support you in connecting with like-minded individuals
        who share your vision.
      </>
    ),
    imageUrl: img_4,
  };

  return (
    <div>
      <RightSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section4;
