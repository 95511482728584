import ImpactImg from "../../../assets/Instructor/Section2_Img1.jpg";
import NetworkImg from "../../../assets/Instructor/Section2_Img2.jpg";
import LeadershipImg from "../../../assets/Instructor/Section2_Img3.jpg";

const instructorData = [
  {
    id: 1,
    title: "Impact",
    description:
      "Influence and shape the lives of your students, making a meaningful impact on their learning and development.",
    shadow: false,
    topmargin: false,
    imageUrl: ImpactImg,
    alt: "Impact",
  },
  {
    id: 2,
    title: "Network",
    description:
      "Network with fellow educators, administrators, and professionals, fostering collaborations and sharing best practices.",
    shadow: true,
    topmargin: true,
    imageUrl: NetworkImg,
    alt: "Network",
  },
  {
    id: 3,
    title: "Leadership",
    description:
      "Hone one's ability to inspire, guide, and empower others towards shared goals, fostering innovation and resilience in diverse teams for collective success.",
    shadow: false,
    topmargin: false,
    imageUrl: LeadershipImg,
    alt: "Leadership",
  },
];

export default instructorData;
