import React from 'react'

const Impacts = ({ title, data }) => {
  const impacts = data;
  return (
    <div className='m-auto w-10/12 py-10'>
      <div className='text-4xl font-semibold text-center m-auto lg:py-20 py-7'>
        {title}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-3 justify-center">
        {impacts.map(i => (
          <div class="mx-auto overflow-hidden">
            <img class="mx-auto rounded" src={i.img} alt="Square Image" />
            <p class=" my-2 font-semibold">{i.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Impacts