import React from "react";
import LandingCover from "../../components/Students/LandingCover";
import StudentsBox from "../../components/Students/StudentsBox";

export default function Student() {
  return (
    <div className="flex flex-col bg-neutral-100">
      <LandingCover />
      <StudentsBox />
    </div>
  );
}
