import React from 'react';
import backgroundImage from '../../../assets/Enterprise/Section2imagebg.png'; // Import the background image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import icon from "../../../assets/Enterprise/Section2icon.png"
import img from "../../../assets/Enterprise/Section2image1.png"

const Section2 = () => {
  return (
    <section className="relative bg-cover bg-center py-24" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className=" px-4 md:px-12 lg:px-16 xl:px-24 text-black">
        <h2 className="text-md md:text-lg lg:text-xl xl:text-2xl text-center mb-4">-- OUR SOCIAL ENTERPRISE --</h2>
        <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-center font-bold  xl:px-24 mb-8" style={{ lineHeight: '140%' }}>Offering a range of Innovative services to support all Indians</h1>

        <div className="grid grid-cols-12 md:gap-2 lg:gap-3 xl:gap-8 pt-6 md:pt-20">
          {/* Image on the left */}
          <div className="col-span-12  md:col-span-3">
            <img src={img} alt="Image" className=" w-full h-full  " />
          </div>
          {/* Cards on the right */}
          <div className=" col-span-12 md:col-span-9  ">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-8 md:mt-0">
              {/* Card 1 */}
              <div className="bg-white rounded-lg p-3 lg:p-4">
                <img src={icon} alt="Card 1 Image" className="w-16 h-16 mb-4" />
                <h3 className="md:text-md lg:text-lg xl:text-xl font-semibold mb-2 "style={{ lineHeight: '140%' }}>Unions, Industry and Trades</h3>
                <p className="md:text-sm lg:text-md xl:text-lg text-gray-500"style={{ lineHeight: '140%' }}>Access innovative skills development programs and training facilities in today's fastest-growing industries.</p>
              </div>
              {/* Card 2 */}
              <div className="bg-white rounded-lg p-3 lg:p-4">
                <img src={icon} alt="Card 2 Image" className="w-16 h-16 mb-4" />
                <h3 className="md:text-md lg:text-lg xl:text-xl font-semibold mb-2 "style={{ lineHeight: '140%' }}>Workforce Development, New Employees</h3>
                <p className="md:text-sm lg:text-md xl:text-lg text-gray-500"style={{ lineHeight: '140%' }}>Develop the skills that employers are looking for, making you more productive, creative, innovative, and in-demand.</p>
              </div>
              {/* Card 3 */}
              <div className="bg-white rounded-lg p-3 lg:p-4">
                <img src={icon} alt="Card 3 Image" className="w-16 h-16 mb-4" />
                <h3 className="md:text-md lg:text-lg xl:text-xl font-semibold mb-2 "style={{ lineHeight: '140%' }}>​Unemployed and Underemployed Individuals</h3>
                <p className="md:text-sm lg:text-md xl:text-lg text-gray-500"style={{ lineHeight: '140%' }}>Get support from employment centers to access skills development resources and become job ready.</p>
              </div>

              <div className="bg-white rounded-lg p-3 lg:p-4">
                <img src={icon} alt="Card 3 Image" className="w-16 h-16 mb-4" />
                <h3 className="md:text-md lg:text-lg xl:text-xl font-semibold mb-2 "style={{ lineHeight: '140%' }}>Youth and Students</h3>
                <p className="md:text-sm lg:text-md xl:text-lg text-gray-500"style={{ lineHeight: '140%' }}>Access TSIO and apprenticeship opportunities through a platform that connects employment partners, educators, tutors, and coaches.</p>
              </div>

              <div className="bg-white rounded-lg p-3 lg:p-4">
                <img src={icon} alt="Card 3 Image" className="w-16 h-16 mb-4" />
                <h3 className="md:text-md lg:text-lg xl:text-xl font-semibold mb-2 "style={{ lineHeight: '140%' }}>​Businesses and Entrepreneurs</h3>
                <p className="md:text-sm lg:text-md xl:text-lg text-gray-500"style={{ lineHeight: '140%' }}>Access skills development programs and resources focused on innovation and building a strong business for today's market.</p>
              </div>

              <div className="bg-white rounded-lg p-3 lg:p-4">
                <img src={icon} alt="Card 3 Image" className="w-16 h-16 mb-4" />
                <h3 className="md:text-md lg:text-lg xl:text-xl font-semibold mb-2 "style={{ lineHeight: '140%' }}>Government</h3>
                <p className="md:text-sm lg:text-md xl:text-lg text-gray-500"style={{ lineHeight: '140%' }}>Collaborate with us to access a network of partners, technology, and resources to build and contribute to Canada's economic growth.</p>
              </div>

              
              {/* Add more cards here */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
