import React from 'react';
import backgroundImage from '../../../assets/Support/Section4imagebg.png'; // Import the background image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Section11 = () => {
  return (
    <section className="relative bg-cover bg-center py-20" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="container mx-auto px-4 flex flex-col justify-center items-center">
        <h1 className="text-2xl md:text-3xl lg:text-5xl text-center text-white font-semibold justify-center items-center">Ignite Your Carrer</h1>
        <div className='text-2xl md:text-3xl lg:text-4xl xl:text-6xl text-center text-white py-8 font-bold'style={{ lineHeight: '140%' }}>Join HPDS's Dynamic Team</div>
        <div className='justify-center items-center'>
            <button className='bg-blue-500 text-white px-4 py-2 text-md text-center rounded-lg'> 
               See All Carrer Opportunities <span className='mx-2'><FontAwesomeIcon icon ={faChevronRight}/></span>
            </button>
        </div>
      </div>
    </section>
  );
};

export default Section11;
