import React from 'react'
import BG from "../../../assets/heroSection2_bg.jpg"
import coverimg from "../../../assets/coverimg_comp.jpg"

const HeroSection = () => {
  return (
    <div className="flex justify-start bg-cover text-blue-800 py-8 lg:p-32 md:px-16 md:py-10 h-[90vh]" style={{ backgroundImage: `url(${BG})` }}>
      <div className='flex flex-col md:flex-row justify-start md:justify-between gap-6 md:gap-0'>
        <div className="items-start w-11/12 md:w-5/12 mx-auto md:mx-0">
          <div className="font-semibold  text-lg md:text-2xl lg:text-4xl mb-8">Drive the new way of learning through compelling competitions</div>
          <div className="text-lg md:text-sm ">Start an impactful event, nurture a dedicated audience, and craft experiences that resonate, creating a community for long-term influence and connection-building</div>
        </div>

        <div className='items-start  w-11/12 md:w-6/12 mx-auto md:mx-0'>
          <img src={coverimg} 
          alt="blank"
          className="w-full h-[300px] rounded-bl-3xl md:h-[300px] lg:h-[340px] xl:h-[400px] object-cover md:mb-0"/>
        </div>
      </div>
    </div>
  )
}

export default HeroSection