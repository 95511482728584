import React from 'react';
import icon1 from "../../../assets/Internship/Section4icon1.png"
import icon2 from "../../../assets/Internship/Section4icon2.png"
import icon3 from "../../../assets/Internship/Section4icon3.png"
import icon4 from "../../../assets/Internship/Section4icon4.png"
import icon5 from "../../../assets/Internship/Section4icon5.png"

const Section4 = () => {
  return (
    <section className="bg-blue-500 py-12">
      <div className="  px-4">
        {/* Heading */}
        <h2 className="mx-auto text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white lg:mb-6 xl:mb-8 text-center px-4 md:px-8 lg:px-12 xl:px-24">Where will be the <span className='text-yellow-400'>Program take place</span></h2>
        
        {/* Grid */}
        <div className="grid grid-cols-12 gap-4 lg:gap-6 xl:gap-8 py-4 md:py-6 lg:py-8 px-4 md:px-8 lg:px-12 xl:px-24 text-white">
          {/* Left side (5 cards) */}
          <div className="col-span-12 md:col-span-7 grid grid-cols-1 md:grid-cols-1 gap-12 md:gap-4">
            {/* Card 1 */}
            <div className=" md:p-2 flex flex-col md:flex-row rounded-lg items-center">
              <img src={icon1} alt="Card 1" className="w-12 h-12 mx-4 my-2 md:my-0" />
              <p className="text-lg md:text-xl lg:text-xl xl:text-2xl text-justify">There are opportunities available in Hyderabad, Gurgaon, Pune  and Chennai offices.</p>
            </div>
            {/* Card 2 */}
            <div className=" md:p-2 flex flex-col md:flex-row rounded-lg items-center">
              <img src={icon2} alt="Card 2" className="w-12 h-12 mx-4 my-2 md:my-0" />
              <p className="text-lg md:text-xl lg:text-xl xl:text-2xl text-justify">Currently, our intention is to host our interns using a hybrid format, working from our offices as well as remotely. </p>
            </div>
            {/* Card 3 */}
            <div className=" md:p-2 flex flex-col md:flex-row rounded-lg items-center">
              <img src={icon3} alt="Card 3" className="w-12 h-12 mx-4 my-2 md:my-0" />
              <p className="text-lg md:text-xl lg:text-xl xl:text-2xl text-justify">Our goal is for you to get the HPDS experience in-person, whilst still allowing you flexibility and ensuring the safety of all interns. </p>
            </div>
            {/* Card 4 */}
            <div className=" md:p-2 flex flex-col md:flex-row rounded-lg items-center">
              <img src={icon4} alt="Card 4" className="w-12 h-12 mx-4 my-2 md:my-0" />
              <p className="text-lg md:text-xl lg:text-xl xl:text-2xl text-justify">However, given current circumstances, this will remain subject to change, and we may be required to change the format of the internship at a later stage. </p>
            </div>
            {/* Card 5 */}
            <div className=" md:p-2 flex flex-col md:flex-row rounded-lg items-center">
              <img src={icon5} alt="Card 5" className="w-12 h-12 mx-4 my-2 md:my-0" />
              <p className="text-lg md:text-xl lg:text-xl xl:text-2xl text-justify">
Details of Works, our hybrid work approach will be intimated to you.</p>
            </div>
          </div>
          
          {/* Right side (Image) */}
          <div className="col-span-12 md:col-span-5 mt-12 md:mt-0">
            <img src="right_image.jpg" alt="Right Image" className="w-full h-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section4;
