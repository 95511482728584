import React from "react";
import LandingCover from "../../components/NonProfitOrganisation/LandingCover";
import Sections from "../../components/NonProfitOrganisation/sections";

export default function NonProfitOrganisation() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <Sections />
    </div>
  );
}
