import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons';
import DatasetSec4 from './DatasetSec9';
import backgroundImage from '../../../assets/HPDSxEngine/Section5imagebg.png';

const Section4 = () => {
  const { buttons } = DatasetSec4;
  const [selectedButton, setSelectedButton] = useState(buttons[0]);
  const [loaded, setLoaded] = useState(false);
  const controlsLeft = useAnimation();
  const controlsRight = useAnimation();

   

    const handleButtonClick = async (button) => {
        await Promise.all([
          controlsLeft.start({ opacity: 0, x: -50, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } }),
          controlsRight.start({ opacity: 0, x: -50, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } })
        ]);
        setSelectedButton(button);
        await Promise.all([
          controlsLeft.start({ opacity: 1, x: 0, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } }),
          controlsRight.start({ opacity: 1, x: 0, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } })
        ]);
      };

  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.3
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  

  return (
    <motion.div
      className="h-[150vh] md:h-[100vh] lg:h-[110vh] relative"
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1 , transition: { duration: 0.6, ease: 'easeIn', delay: 0.2 }},
        hidden: { opacity: 0 , transition: { duration: 0.6, ease: 'easeIn', delay: 0.2 } }
      }}
    >
      <div className="absolute inset-0 flex flex-col text-white">
        <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-loose mb-4 pt-8 md:pt-12 lg:pt-24 mx-4 md:mx-12 lg:mx-20 xl:mx-28 font-semibold" style={{ lineHeight: '140%' }}>
          <span className='text-white '>Human Potential Development Profiles and Reports</span>
        </h1>
        <div className="grid grid-grid-cols-1 xl:grid-cols-4 lg:grid-cols-3  md:grid-cols-3  gap-2 md:gap-3 lg:gap-4 xl:gap-10 py-4 md:py-4 mx-4  md:mx-8 lg:mx-16  xl:mx-24">
          {buttons.map(button => (
            <motion.button
              key={button}
              className={`text-center  hover:bg-yellow-400  hover:text-white md:text-sm lg:text-md  font-semibold py-3 lg:px-8 xl:px-6 rounded-xl whitespace-nowrap border ${selectedButton === button ? 'text-white bg-yellow-400 border-yellow-400 hover:border-transparent' : 'text-yellow-400 border-yellow-400 hover:border-transparent'} transition duration-300 ease-in-out`}
              onClick={() => handleButtonClick(button)}

              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {button.heading}
              </motion.button>
          ))}
        </div>
        <motion.div
          className="grid grid-cols-12 gap-4 lg:mb-16 "
          initial="hidden"
          animate={controls}
          variants={{
            visible: {
              opacity: 1,
              x: 0,
              transition: { duration: 1.3, ease: 'easeOut', delay: 0.3 }
            },
            hidden: { opacity: 0, x: -100 }
          }}
        >
          {/* Left Section */}
          <motion.div
            className="col-span-12 md:col-span-6 lg:col-span-6 text-left px-4 md:px-8 lg:px-12 xl:px-24 my-8 md:my-8 lg:my-20"
            style={{ overflow: 'hidden' }}
            variants={{
              visible: { opacity: 1, x: 0, transition: { duration: 1.3, ease: 'easeOut', delay: 0.6 } },
              hidden: { opacity: 0, x: -100 }
            }}
          >
            {loaded && (
              <>
                <motion.div
                  initial={{ opacity : 1, x: 0}}
                  animate={controlsLeft}
                  variants={{
                    visible: { opacity: 1, x: 0, transition: { duration: 1.3, ease: 'easeOut', delay: 0.6 } },
                    hidden: { opacity: 0, x: -100 }
                  }}
                >
                  <div className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-8" style={{ lineHeight: '140%' }}>
                    {selectedButton.heading}
                  </div>
                  <div className='text-lg md:text-lg lg:text-xl xl:text-2xl font-semibold' style={{ lineHeight: '140%' }}>
                    {selectedButton.description}
                  </div>
                </motion.div>
              </>
            )}
          </motion.div>
          {/* Right Section */}
          <motion.div
            className="col-span-12 md:col-span-6 lg:col-span-6 flex px-4 md:px-0  justify-center items-center"
            style={{ overflow: 'hidden' }}
            initial={{ opacity : 1, x: 0}}
            animate={controlsRight}
            variants={{
              visible: { opacity: 1, x: 0, transition: { duration: 1.3, ease: 'easeOut', delay: 0.6 } },
              hidden: { opacity: 0, x: 100 }
            }}
          >
            {loaded && (
              <motion.img
                src={selectedButton.image}
                alt="Right Section Image"
                className="w-full md:mr-16 md:w-full lg:w-2/3"
                initial={{ opacity : 1, x: 0}}
                animate = {controlsRight}
                variants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 1.3, ease: 'easeOut', delay: 0.6 } },
                  hidden: { opacity: 0, x: 100 }
                }}
              />
            )}
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Section4;
