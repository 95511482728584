import React from 'react';
import { motion } from 'framer-motion';
import img1 from "../../../assets/MainMenu/Section4image1.png";
import img2 from "../../../assets/MainMenu/Section4image2.png";
import img3 from "../../../assets/MainMenu/Section4image4.png";
import { useInView } from 'react-intersection-observer';
import flagImage from "../../../assets/MainMenu/flag.png"

const Section4 = () => {
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.2,
    });

    return (
        <motion.section
            initial={{ opacity: 0, y: 80 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
            className="w-full py-20"
            ref={ref}
        >
            <div className="max-w-screen mx-auto">
                <h2 className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-8 px-4 md:px-12 lg:px-20 xl:px-28" style={{ lineHeight: '140%' }}>Helps <span className='text-[#0086E8]'>Youth</span> become what they want to be</h2>
                <div className="grid grid-cols-12 gap-2 md:gap-4 lg:gap-8 py-4">
                    {/* Left side with image */}
                    <motion.div
                        initial={{ opacity: 0, x: -30 }}
                        animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -30 }}
                        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.3 }}
                        className="col-span-12 md:col-span-6 mx-4 md:mx-0 md:ml-12 lg:ml-20 xl:ml-28"
                    >
                        <img src={img1} alt="" className="md:w-full xl:w-5/6 rounded-lg" />
                    </motion.div>
                    {/* Right side with text */}
                    <motion.div
                        initial={{ opacity: 0, y: 80 }}
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
                        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.3 }}
                        className="col-span-12 md:col-span-6 flex items-center "
                    >
                        <p className="px-4 md:px-8 lg:px-16 ">
                            <div className='text-xl md:text-xl lg:text-2xl xl:text-3xl font-medium' style={{ lineHeight: '140%' }}>THINKSKILLINDIA platform will work to deliver major key objectives over the <span className='text-[#0086E8]'>next five years</span> </div>
                            <ul className="list-none space-y-2 mt-4">
                                <li className="flex items-start">
                                    <svg className="mt-2 mr-2 flex-shrink-0 h-4 w-4 text-[#0086E8]" fill="currentColor" viewBox="0 0 8 8">
                                        <circle cx="4" cy="4" r="4"/>
                                    </svg>
                                    <span className="text-lg lg:text-xl xl:text-2xl font-medium">Outcomes for young people</span>
                                </li>
                                <li className="flex items-start">
                                    <svg className="mt-2 mr-2 flex-shrink-0 h-4 w-4 text-[#0086E8]" fill="currentColor" viewBox="0 0 8 8">
                                        <circle cx="4" cy="4" r="4"/>
                                    </svg>
                                    <span className="text-lg lg:text-xl xl:text-2xl font-medium">Strengthen the youth equipping to work in partnership with institutions, Industry and Government</span>
                                </li>
                                <li className="flex items-start">
                                    <svg className="mt-2 mr-2 flex-shrink-0 h-4 w-4 text-[#0086E8]" fill="currentColor" viewBox="0 0 8 8">
                                        <circle cx="4" cy="4" r="4"/>
                                    </svg>
                                    <span className="text-lg lg:text-xl xl:text-2xl font-medium">Future of work skills development of the youth into the workforce.</span>
                                </li>
                            </ul>
                        </p>
                    </motion.div>
                </div>
                <div className="grid grid-cols-12 md:gap-4 lg:gap-8 py-12">
                    {/* Right side with text */}
                    <motion.div
                        initial={{ opacity: 0, y: 80 }}
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
                        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.3 }}
                        className="col-span-12 md:col-span-6 flex items-center order-2 md:order-1"
                    >
                        <p className="px-4 md:px-12 lg:px-20 xl:px-28 py-4 md:py-0">
                           <div className='text-2xl lg:text-3xl xl:text-4xl mb-4 text-[#0086E8] flex font-semibold'>
                                <span className='mr-2'>
                                    <img src={flagImage} alt="Flag" className="h-11 w-11" /> {/* Adjust height and width as needed */}
                                </span>
                                Goal
                            </div>
                            <div className='text-lg lg:text-xl xl:text-2xl text-justify' style={{ lineHeight: '140%' }}>It's goal is to combat the backwardness and subjugation of India through the growing population of Indian youth, particularly students, in ensuing constant strategic conversations, raising awareness on topical and pressing challenges mitigating the growth of India, organizing solution-based online events, liaising with groups and government, as well as engaging other relevant stakeholders. </div>
                        </p>
                    </motion.div>
                    <div className="col-span-12 md:col-span-6 order-1 md:order-2">
                        <motion.img
                            initial={{ opacity: 0, x: 30 }}
                            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 30 }}
                            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.3 }}
                            src={img2}
                            alt="Image Description"
                            className="w-5/6 rounded-lg mx-auto md:mx-0"
                        />
                    </div>
                </div>
                <div className='text-xl md:text-2xl lg:text-3xl xl:text-4xl py-4 md:py-8 px-4 md:px-12 lg:px-20 xl:px-28 font-semibold' style={{ lineHeight: '140%' }}>We are on the verge of the next stage of human flourishing, where we will finally use technology to build services and products that enable personal transformation at scale. </div>
                <div>
                    <motion.img
                        initial={{ opacity: 0, y: -80 }}
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: -80 }}
                        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.3 }}
                        src={img3}
                        alt="Image Description"
                        className="w-5/6 md:w-5/6 mx-auto my-2 md:my-4"
                    />
                </div>
            </div>
        </motion.section>
    );
};

export default Section4;
