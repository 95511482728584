
import Section7Image1 from "../../../assets/Carrer/section4Image.png"
import Section7Image2 from "../../../assets/About Hpds/section4Img2.png";
import Section7Image3 from "../../../assets/About Hpds/section4Img3.png";
import Section7Image4 from "../../../assets/About Hpds/section4Img4.png";
import Section7Image5 from "../../../assets/About Hpds/section4Img5.png";

const dataset = [
    {
      id: 1,
      heading: "Growth Mindset",
      description: <>Our core conviction is that we require a culture based on a growth attitude. It begins with the conviction that everyone has the capacity to modify their perspective, that potential is developed rather than predetermined, and that everyone can grow and develop. 
          We must have an endless curiosity and a thirst for knowledge. In order to achieve mastery, we must be prepared to embrace uncertainty, take calculated chances, and learn from our errors fast. Failure is a necessary part of the process. Furthermore, we must be receptive to other people's perspectives so that our own achievement is not diminished by theirs.
      </>,
      image: Section7Image1,
    },
    {
      id: 2,
      heading: "Customer Obsessed",
      description: <>
      Qualitative insights to enhance your employees’ career profile, and take better decisions for your customers and partners.
      </>,
      image: Section7Image2, // Replace this with the image for HPDSX Fueling
    },
    {
      id: 3,
      heading: "Diverse & Inclusive",
      description: <>Our platform leverages cutting-edge AI technology to deliver accurate, reliable, and actionable insights into the skills and abilities of candidates and employees.
      </>,
      image: Section7Image3, // Replace this with the image for HPDSX Igniting
    },
  ];
  
  export default dataset;
  