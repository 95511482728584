import Section4image1 from "../../../assets/Enterprise/Section5image.png"
import Section4image2 from "../../../assets/Enterprise/Section5image.png"



const dataset = [
    {
      id: 1,
      title: "Empowerment through Skill Support",
      heading:"Empowerment through Skill Support",
      description: "THINKSKILLINDIA Platform  will work with employment centers, job centers and job banks to support job seekers in their communities with access to skills development programs, job search assistance, resume and cover letter writing assistance, interview preparation, and job placement services.",
      

      rightimage:Section4image1
    },
    {
      id: 2,
      title: "Career Connection Initiative",
      heading:"THINKSKILLINDIA is trying to enable personal transformation which is the individual process of expanding consciousness.",
      description: "During personal transformation, you will become more critically aware of your old and new views and can integrate them into a new self-definition.​",
     
      rightimage:Section4image2
    },
   
    
  ];
  
  export default dataset;