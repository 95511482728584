import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import img_1 from "../../../assets/ThinkSkillIndia/img1_sec10.png";
import img_2 from "../../../assets/ThinkSkillIndia/img2_sec10.png";
import img_3 from "../../../assets/ThinkSkillIndia/img3_sec10.png";
import img_4 from "../../../assets/ThinkSkillIndia/img4_sec10.png";
import img_5 from "../../../assets/ThinkSkillIndia/img5_sec10.png";
import img_6 from "../../../assets/ThinkSkillIndia/img6_sec10.png";
import img_7 from "../../../assets/ThinkSkillIndia/img7_sec10.png";
import img_8 from "../../../assets/ThinkSkillIndia/img8_sec10.png";
import img_9 from "../../../assets/ThinkSkillIndia/img9_sec10.png";

const Section10 = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  const data1 = (
    <>
      THINKSKILLINDIA PLATFORM IS THE DISRUPTION DRIVER CREATING THE FOUNDATION
      FOR TRANSFORMING SCHOOLS AND UNIVERSITIES INTO A HIGH-TECH INDUSTRY.
    </>
  );

  const data2 = [
    {
      title: (
        <>
          <span className="font-semibold">
            Successful educational innovation
          </span>{" "}
          and transformation must, however, be based on sustainability, scope,
          and scale
        </>
      ),
      img: img_1,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            Transforming schools and universities
          </span>{" "}
          from old learning systems to a participatory culture, engaging 
          participants, and promoting  evidence-based decision making
        </>
      ),
      img: img_2,
    },
    {
      title: (
        <>
          <span className="font-semibold">Student and Teacher as engines </span>
          of learning to promote an open curriculum enabled by the AI based 
          Comprehensive Intuitive THINKSKILLINDIA platform
        </>
      ),
      img: img_3,
    },
    {
      title: (
        <>
          <span className="font-semibold">The platform enables AI Powered</span>{" "}
          rapid pedagogies and Interventions from traditional to Real-time
          Discovery Sessions, Virtual Instruction, and Seminars to Webinars…
        </>
      ),
      img: img_4,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            THINKSKILLINDIA platform empowers
          </span>{" "}
          schools and universities to evolve from a mostly “lecture-based
          learning” system toward “problem-based learning” methodologies.
        </>
      ),
      img: img_5,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            A well-designed AI Based Experience
          </span>{" "}
          schools and universities will able to develop digital learning
          methodologies and provide digital learning contexts, tools, and
          support systems to students
        </>
      ),
      img: img_6,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            THINKSKILLINDIA platform will be the{" "}
          </span>
          school and the university digital platform; instant messaging tools;
          video - conferencing tools; and educational  and learning assets;
          combined with instant chats, email and conversations to maintain
          individualized contact with students and alumni
        </>
      ),
      img: img_7,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            THINKSKILLINDIA Platform engages
          </span>{" "}
          resources available to provide multiple options for teaching, such as
          giving lectures by video conference, sharing material, interacting
          through chats, creating debate forums or work groups, supervising
          practical activities, evaluating and tutoring students, recording
          explanations and making them available to students and alumni
        </>
      ),
      img: img_8,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            {" "}
            THINKSKILLINDIA platform with all the{" "}
          </span>{" "}
          sources supported, by educational methodology to maintain students'
          attention and keep them involved in the course and to ensure  clarity
          of the educational objective of each activity, design of the 
          material, plan students' work time, and use the right tools for each
          activity
        </>
      ),
      img: img_9,
    },
  ];
  return (
    <motion.div
      className="w-full h-full xl:px-24 lg:px-16 md:px-8 px-4   md:py-6 py-8 pb-20"
      ref={ref}
      initial={{ opacity: 0, y: -80 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -80 }}
      transition={{ duration: 1, ease: "easeIn", delay: 0.2 }}
    >
      <motion.div
        className="xl:text-4xl lg:text-3xl md:text-2xl text-xl font-bold"
        initial={{ opacity: 0 }}
        animate={{ opacity: inView ? 1 : 0 }}
        transition={{ duration: 1, ease: "easeIn", delay: 0.2 }}
        style={{ lineHeight: "140%" }}
      >
        {data1}
      </motion.div>
      <div className="w-full mt-4 h-full grid grid-cols-2 md:grid-cols-3 text-md xl:text-lg  xl:gap-6 lg:gap-5 md:gap-4 gap-4">
        {data2.map((data, index) => (
          <motion.div
            className="flex flex-col gap-3 py-4"
            key={data.title}
            initial={{
              opacity: 0,
              x: index % 3 === 0 ? -70 : index % 3 === 1 ? 0 : 70,
              y: index < 3 ? -70 : 0,
            }}
            animate={{
              opacity: inView ? 1 : 0,
              x: inView ? 0 : index % 3 === 0 ? -70 : index % 3 === 1 ? 0 : 70,
              y: inView ? 0 : index < 3 ? -70 : 0,
            }}
            transition={{ duration: 1, ease: "easeInOut", delay: 0.2 }}
          >
            <img
              src={data.img}
              className="xl:w-12 lg:w-9 md:w-6 xl:h-12 lg:h-9 md:h-6 w-9 h-9"
              alt={data.title}
            />
            <div className="text-justify">{data.title}</div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};
export default Section10;
