import React from 'react';

function NotFound() {
  return (
    <div className="h-screen flex items-center justify-center flex-col">
      <h1 className="text-3xl font-bold">404 - Not Found!</h1>
      <p className="text-lg">Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}

export default NotFound;
