import React from "react";
import BgImg from "../../../assets/ContactUs/BgImg.png";
import Section1 from "./Section1";
import Section2 from "./Section2";

const bgImageStyle = {
  backgroundImage: `url(${BgImg})`,
  backgroundSize: "contain",
};

export default function Home() {
  return (
    <div
      className="w-full h-[400vh] md:h-[310vh] lg:h-[280vh] xl:h-[350vh] bg-no-repeat"
      style={bgImageStyle}
    >
      <div className="flex items-center justify-center text-lg xl:text-4xl lg:text-3xl md:text-2xl text-white font-bold my-8 xl:my-28 lg:my-20 md:my-12">
        We're Here to Help You Shine!
      </div>
      <div>
        <Section1 />
        <Section2 />
      </div>
    </div>
  );
}
