import React from "react";
import sectiontwoData from "./section2Dataset";
import LeftSideImageFlexTemplate from "../Templates/LeftSideImageFlexTemplate";


const SpeakerMotivate = () => {
    const data= sectiontwoData[0];
  
    return (
      <div>
        <LeftSideImageFlexTemplate
          title={data.titleheading}
          description={data.description}
          imageUrl={data.imageUrl}
          bgColor={data.bgColor}
        />
      </div>
    );
  };
  
  export default SpeakerMotivate;