import React from "react";
import Section1image2 from "../../../assets/ChangeAgent/Section1image2.png";

const ImageSection = () => {
  return (
    <section className="bg-white h-[12vh] md:h-[64vh] lg:h-[96vh] relative ">
      <div className=" mx-auto">
        <div className="absolute top-[-96%] md:top-[-20%] left-1/2 transform -translate-x-1/2 w-full flex justify-center">
          <img
            src={Section1image2}
            alt="Center Top Image"
            className=" w-full md:w-5/6 xl:w-3/4 md:[90vh] lg:h-full object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default ImageSection;
