import sm1 from "../../../assets/HomePage/Sme/sm1.webp";
import sm2 from "../../../assets/HomePage/Sme/sm2.webp";
import sm3 from "../../../assets/HomePage/Sme/sm3.webp";
import sm4 from "../../../assets/HomePage/Sme/sm4.webp";
import stu5 from "../../../assets/HomePage/Student/s5.webp";
import i1 from "../../../assets/HomePage/Institution/i1.webp"
import i2 from "../../../assets/HomePage/Institution/i2.webp";
import i3 from "../../../assets/HomePage/Institution/i3.webp";
import i4 from "../../../assets/HomePage/Institution/i4.webp";
import o1 from "../../../assets/HomePage/Organization/o1.webp";
import o2 from "../../../assets/HomePage/Organization/o2.webp";
import o3 from "../../../assets/HomePage/Organization/o3.webp";
import o4 from "../../../assets/HomePage/Organization/o4.webp";
import stu1 from "../../../assets/HomePage/Student/s1.webp"
import stu2 from "../../../assets/HomePage/Student/s2.webp";
import stu3 from "../../../assets/HomePage/Student/s3.webp";
import stu4 from "../../../assets/HomePage/Student/s4.webp";
// import stu1 from "../../../assets/HomePage/Student/s1.webp";


const platformDataSet = [
  {
    id: 1,
    title: "Discover and Eliminate ignorance and weakness early",
    imageUrl: sm1,
    description:
      "HPDS Way of learning, how to build your potential all 6 cards",
    linkColor: "blue-500",
  },
  {
    id: 2,
    title: "Support your mind to make a global impactt",
    imageUrl: sm2,
    description: "HPDS way - Meaningful learning all 4 topics",
    linkColor: "black",
  },
  {
    id: 3,
    title: "Reimagine Human life to enable transformation",
    imageUrl: sm3,
    description:
      "HPDS Methadology - Building potential, Assessment, Short term goals",
    linkColor: "black",
  },
  {
    id: 4,
    title:
      "Connecting with people across the globe, global organizations, and looking at new ways of learning and sharing with each other",
    imageUrl: sm4,
    description: (
      <>
        Mentor, Referee, Coach, Counselor, Alumni, Instructor
        <br />
        Subject specific materials - Folder, pdf, video, book, chat,
      </>
    ),
    linkColor: "black",
  },
  {
    id: 5,
    title: "More services",
    imageUrl: stu5,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    linkColor: "black",
  },
  {
    id: 6,
    title: "Create your own Digital Learning Platform",
    imageUrl: i1,
    description:
      "Spark creativity and collaboration in any learning environment—whether in the classroom, remote, or blended—with intuitive, inclusive design",
    linkColor: "blue-500",
  },
  {
    id: 7,
    title: "Empower your Experts",
    imageUrl: i2,
    description:
      "Program cards on topics for Teaching experts, 1 subject certification image, Video call screenshot",
    linkColor: "black",
  },
  {
    id: 8,
    title: "Student Collaboration",
    imageUrl: i3,
    description:
      "Screenshot of 6P profile, global competence in hpds way screenshots",
    linkColor: "black",
  },
  {
    id: 9,
    title: "Bring Education Learning more successful together",
    imageUrl: i4,
    description: (
      <>
        Mentor, Referee, Coach, Counselor, Alumni, Instructor
        <br />
        Subject specific materials - Folder, pdf, video, book, chat,
      </>
    ),
    linkColor: "black",
  },
  {
    id: 10,
    title: "Individual Assessment",
    imageUrl: o1,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    linkColor: "blue-500",
  },
  {
    id: 11,
    title: "Student Assessment Model",
    imageUrl: o2,
    description:
      "Screenshots of Create an Assessment, Start an Assessment, Inside Assessment, After submission view of assesssment",
    linkColor: "black",
  },
  {
    id: 12,
    title: "Global Competency Development",
    imageUrl: o3,
    description:
      "Screenshot of 6P profile, global competence in hpds way screenshots",
    linkColor: "black",
  },
  {
    id: 13,
    title: "Pick up where you have left",
    imageUrl: o4,
    description: "Screenshots of all expert profile designed initially",
    linkColor: "black",
  },
  {
    id: 14,
    title: "Create your unique success profiler",
    imageUrl: stu1,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    linkColor: "blue-500",
  },
  {
    id: 15,
    title: "Student engagement",
    imageUrl: stu2,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    linkColor: "black",
  },
  {
    id: 16,
    title: "Self help care",
    imageUrl: stu3,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    linkColor: "black",
  },
  {
    id: 17,
    title: "Expert staff care",
    imageUrl: stu4,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    linkColor: "black",
  },
];

export default platformDataSet;
