import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import sectionImage from '../../../assets/HPDSxMenu/Section1image.png'; // Import your section image
import img from "../../../assets/Enterprise/Section2image1.png"
import icon from "../../../assets/Enterprise/Section2icon.png"
import icon1 from "../../../assets/HPDSxEngine/icon-1.png"
import icon2 from "../../../assets/HPDSxEngine/icon-2.png"
import icon3 from "../../../assets/HPDSxEngine/icon-3.png"
import icon4 from "../../../assets/HPDSxEngine/icon-4.png"
import icon5 from "../../../assets/HPDSxEngine/icon-5.png"
import icon6 from "../../../assets/HPDSxEngine/icon-6.png"
import icon7 from "../../../assets/HPDSxEngine/icon-7.png"
import icon8 from "../../../assets/HPDSxEngine/icon-8.png"
import icon9 from "../../../assets/HPDSxEngine/icon-9.png"
import icon10 from "../../../assets/HPDSxEngine/icon-10.png"
import icon11 from "../../../assets/HPDSxEngine/icon-11.png"
import icon12 from "../../../assets/HPDSxEngine/icon-12.png"
import icon13 from "../../../assets/HPDSxEngine/icon-13.png"
import icon14 from "../../../assets/HPDSxEngine/icon-14.png"
import icon15 from "../../../assets/HPDSxEngine/icon-15.png"
import icon16 from "../../../assets/HPDSxEngine/icon-16.png"
import icon17 from "../../../assets/HPDSxEngine/icon-17.png"
import icon18 from "../../../assets/HPDSxEngine/icon-18.png"
import icon19 from "../../../assets/HPDSxEngine/icon-19.png"
import icon20 from "../../../assets/HPDSxEngine/icon-20.png"
import icon21 from "../../../assets/HPDSxEngine/icon-21.png"
import icon22 from "../../../assets/HPDSxEngine/icon-22.png"
import icon23 from "../../../assets/HPDSxEngine/icon-23.png"
import icon24 from "../../../assets/HPDSxEngine/icon-24.png"
import icon25 from "../../../assets/HPDSxEngine/icon-25.png"
import icon26 from "../../../assets/HPDSxEngine/icon-26.png"
import icon27 from "../../../assets/HPDSxEngine/icon-27.png"
import icon28 from "../../../assets/HPDSxEngine/icon-28.png"
import icon29 from "../../../assets/HPDSxEngine/icon-29.png"
import icon30 from "../../../assets/HPDSxEngine/icon-30.png"
import icon31 from "../../../assets/HPDSxEngine/icon-31.png"
import image2 from "../../../assets/HPDSxEngine/Section8-Image2.png"
import bgImage from "../../../assets/HPDSxEngine/Section10Bg.png"

const Section10 = () => {

    const { ref, inView } = useInView();

    useEffect(() => {
      if (inView) {
        console.log('Section 2 is in view!');
        // You can add additional animations or logic here
      }
    }, [inView]);
    return (
      <motion.section
      className="py-12 md:py-12 px-4 md:px-0"
      style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}  
      ref={ref}
      initial={{ opacity: 0, y: 80 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
      transition={{ ease: 'easeInOut', duration: 0.6 , delay:0.2 }}
        >
        <div className="text-center mb-8 mx-4  md:mx-16 lg:mx-24 xl:mx-40">
            <h1 className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl md:text-5xl font-bold mb-4 text-blue-500">Explore the <span className='text-yellow-400'>HPDSx ENGINE</span>​</h1>
            <h2 className="text-md md:text-lg lg:text-xl xl:text-2xl  mb-4 md:px-16 lg:px-16 xl:px-28 text-green-400">Want to become part of <span className='font-bold'>​“THE MAGIC BOX”
                That changes lives daily?</span></h2>
            <p className="text-md md:text-lg lg:text-xl xl:text-2xl text-gray-600 md:px-8 lg:px-12 xl:px-16">The HPDSx Collaboratory Project is a voluntary global participatory think tank of futurists, scholars, scientists, business planners, and policymakers who work for international organizations, governments, corporations, NGOs, and universities and who volunteer their time to improve the State of the Future education, skill and work.</p>
        </div>
        <div className="grid grid-cols-12 md:gap-2 lg:gap-3 xl:gap-8 pt-6 md:pt-20 px-8">
          {/* Image on the left */}
          <div className="col-span-12  md:col-span-4">
            <img src={img} alt="Image" className=" w-full h-full  " />
          </div>
          {/* Cards on the right */}
          <div className=" col-span-12 md:col-span-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-8 md:mt-0">
              {/* Card 1 */}
               <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 h-full flex flex-col justify-between">
                <h3 className="text-2xl font-bold text-[#00B0F0] mb-4 lg:pr-16">Network Association</h3>
                <div className="space-y-6 mt-2">
                  <div className="flex items-center">
                    <img src={icon1} alt="Parent's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Parent’s Association</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon2} alt="Principal's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Principal’s Association</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon3} alt="Teachers network of Unions" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Teachers network of Unions</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon4} alt="Student Groups" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Student Groups</span>
                  </div>
                </div>
                <div className="mt-4 flex items-center pt-10">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-xl inline-flex items-center">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                  </button>
                  <span className='text-blue-500 text-xl ml-3'>Explore product</span>
                </div>
              </div>
              {/* Card 2 */}
             
              {/* Card 3 */}
               <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 flex flex-col justify-between">
                <h3 className="text-2xl font-bold text-[#00B050] mb-4">School</h3>
                <div className="space-y-6 mt-2">
                  <div className="flex items-center">
                    <img src={icon5} alt="Parent's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Primary, Secondary or High School</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon6} alt="Principal's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Government School</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon7} alt="Teachers network of Unions" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Religious School</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon8} alt="Student Groups" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Student Groups</span>
                  </div>
                </div>
                <div className="mt-4 flex items-center pt-10">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-xl inline-flex items-center">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                  </button>
                  <span className='text-blue-500 text-xl ml-3'>Explore product</span>
                </div>
              </div>

                <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 h-full flex flex-col justify-between">
                <h3 className="text-2xl font-bold text-[#FF1010] mb-4">NGO</h3>
                <div className="space-y-6 mt-2">
                  <div className="flex items-center">
                    <img src={icon9} alt="Parent's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Philanthropic foundations</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon10} alt="Principal's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Social Enterprises</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon11} alt="Teachers network of Unions" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Service and Technology Providers</span>
                  </div>
                </div>
                <div className="mt-4 flex items-center pt-10">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-xl inline-flex items-center">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                  </button>
                  <span className='text-blue-500 text-xl ml-3'>Explore product</span>
                </div>
              </div>

               <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 flex flex-col justify-between">
                <h3 className="text-2xl font-bold text-[#A204DA] mb-4 lg:pr-16">Public Service Providers</h3>
                <div className="space-y-6 mt-2">
                  <div className="flex items-center">
                    <img src={icon12} alt="Parent's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Early Childhood Education</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon13} alt="Principal's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Healthcare Providers</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon14} alt="Teachers network of Unions" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Police and Public security</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon15} alt="Student Groups" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Local Government Agencies</span>
                  </div>
                </div>
                <div className="mt-4 flex items-center pt-10">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-xl inline-flex items-center">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                  </button>
                  <span className='text-blue-500 text-xl ml-3'>Explore product</span>
                </div>
              </div>

               <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 flex flex-col justify-between">
                <h3 className="text-2xl font-bold text-[#FFC000] mb-4 lg:pr-16">Creative and Cultural Organisation</h3>
                <div className="space-y-6 mt-2">
                  <div className="flex items-center">
                    <img src={icon16} alt="Parent's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Cultural  - Interest Organizations</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon17} alt="Principal's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Cultural  Institutions</span>
                  </div>
                </div>
                <div className="mt-4 flex items-center pt-10">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-xl inline-flex items-center">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                  </button>
                  <span className='text-blue-500 text-xl ml-3'>Explore product</span>
                </div>
              </div>

               <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 flex flex-col justify-between">
                <h3 className="text-2xl font-bold text-[#068273] mb-4 lg:pr-16">Research Institution</h3>
                <div className="space-y-6 mt-2">
                  <div className="flex items-center">
                    <img src={icon18} alt="Parent's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Universities</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon19} alt="Principal's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Specialized Institutes</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon20} alt="Teachers network of Unions" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Think Tanks</span>
                  </div>
                </div>
                <div className="mt-4 flex items-center pt-10">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-xl inline-flex items-center">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                  </button>
                  <span className='text-blue-500 text-xl ml-3'>Explore product</span>
                </div>
              </div>

              
              {/* Add more cards here */}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 md:gap-2 lg:gap-3 xl:gap-8 pt-6 md:pt-8 px-8">
          <div className="col-span-12 md:col-span-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-8 md:mt-0">
              {/* Card 1 */}
               <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 h-full flex flex-col justify-between">
                <h3 className="text-2xl font-bold text-[#FF1010] mb-4 lg:pr-16">Local Partner</h3>
                <div className="space-y-6 mt-2">
                  <div className="flex items-center">
                    <img src={icon21} alt="Parent's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Religious Places</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon22} alt="Principal's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Community Group</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon23} alt="Teachers network of Unions" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Special Interest Group</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon31} alt="Student Groups" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Activity providers</span>
                  </div>
                </div>
                <div className="mt-4 flex items-center pt-10">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-xl inline-flex items-center">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                  </button>
                  <span className='text-blue-500 text-xl ml-3'>Explore product</span>
                </div>
              </div>
              {/* Card 2 */}
             
              {/* Card 3 */}
               <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 flex flex-col justify-between">
                <h3 className="text-2xl font-bold text-[#068273] mb-4">Business</h3>
                <div className="space-y-6 mt-2">
                  <div className="flex items-center">
                    <img src={icon24} alt="Parent's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Local Employers</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon25} alt="Principal's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Corporate Sponsors</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon26} alt="Teachers network of Unions" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>EdTech Companies</span>
                  </div>
                </div>
                <div className="mt-4 flex items-center pt-10">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-xl inline-flex items-center">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                  </button>
                  <span className='text-blue-500 text-xl ml-3'>Explore product</span>
                </div>
              </div>

                <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 h-full flex flex-col justify-between">
                <h3 className="text-2xl font-bold text-[#FF5C00] mb-4">Individual</h3>
                <div className="space-y-6 mt-2">
                  <div className="flex items-center">
                    <img src={icon27} alt="Parent's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Inspiring Teachers</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon28} alt="Principal's Association" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Thought Leaders</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon29} alt="Teachers network of Unions" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Consultants</span>
                  </div>
                  <div className="flex items-center">
                    <img src={icon30} alt="Teachers network of Unions" className="h-8 w-8 mr-2"/>
                    <span className='text-md'>Entrepreneurs</span>
                  </div>
                </div>
                <div className="mt-4 flex items-center pt-10">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-3 rounded-xl inline-flex items-center">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                  </button>
                  <span className='text-blue-500 text-xl ml-3'>Explore product</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12  md:col-span-4">
            <img src={image2} alt="Image" className=" w-full h-full  " />
          </div>
        </div>
        </motion.section>
    );
};

export default Section10;
