import React, { useRef, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import Section6image1 from "../../../assets/HPDSxMenu/Section6image1.png";

const Section6 = () => {
  const backgroundColor = "#0086E8";
  const textRef = useRef(null);
  const imageRef = useRef(null);

  const [{ opacity: textOpacity }, setTextSpring] = useSpring(() => ({
    opacity: 0,
  }));
  const [{ opacity: imageOpacity }, setImageSpring] = useSpring(() => ({
    opacity: 0,
  }));

  const handleScroll = () => {
    if (textRef.current && imageRef.current) {
      const textRect = textRef.current.getBoundingClientRect();
      const imageRect = imageRef.current.getBoundingClientRect();

      const textVisible =
        textRect.top < window.innerHeight && textRect.bottom >= 0;
      const imageVisible =
        imageRect.top < window.innerHeight && imageRect.bottom >= 0;

      setTextSpring({
        opacity: textVisible ? 1 : 0,
        config: { duration: 1000, delay: 500 },
      });
      setImageSpring({
        opacity: imageVisible ? 1 : 0,
        config: { duration: 1000, delay: 1000 },
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className="py-12 md:py-24 text-white"
      style={{ background: backgroundColor }}
    >
      {/* Section Heading */}
      <div className="mx-2 md:mx-2 lg:mx-4">
        <animated.h1
          ref={textRef}
          className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-bold text-center mb-8"
        >
          HPDS <span className="text-yellow-500">Address</span> today’s{" "}
          <span className="text-yellow-500">Biggest challenges</span> and{" "}
          <span className="text-yellow-500">Impact</span>
        </animated.h1>

        {/* Description */}
        <animated.h2
          ref={textRef}
          className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-center mb-10"
        >
          Human Body with Digital Mind
        </animated.h2>

        {/* Grid */}
        <div className="grid grid-cols-12 gap-3 md:gap-2 lg:gap-4">
          {/* Left Section (Image) */}
          <div className="col-span-12 md:col-span-6 flex items-center justify-end md:mx-4 lg:mx-0">
            <animated.img
              ref={imageRef}
              src={Section6image1}
              alt="Image"
              className="object-contain w-full md:w-full md:h-full lg:w-5/6 lg:h-5/6 xl:w-4/5 xl:h-4/5"
              style={{ opacity: imageOpacity }}
            />
          </div>

          {/* Right Section (Text) */}
          <div className="col-span-12 md:col-span-6 flex flex-col items-start xl:pt-8 lg:w-5/6">
            {/* Heading */}
            <animated.h2
              ref={textRef}
              className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-bold leading-relaxed lg:mt-0 xl:mt-8"
              style={{ lineHeight: "140%", opacity: textOpacity }}
            >
              Digital hell can make Human Life hell unnecessarily​
            </animated.h2>

            {/* Description */}
            <animated.p
              ref={textRef}
              className="text-xl leading-relaxed md:text-xl lg:text-2xl xl:text-3xl pt-4 md:pt-8 font-medium text-justify"
              style={{ lineHeight: "140%", opacity: textOpacity }}
            >
              Persuasive design exploits triggers, emotions and automatic
              cognitive scripts to produce non-reflective and sometimes
              irrational states of mind affecting mental health of non ignited
              and non-awakened minds.​
            </animated.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
