import React from "react";
import Section1 from "../../components/TranstechCollaboratory/Section1";
import Section2 from "../../components/TranstechCollaboratory/Section2";
import Section3 from "../../components/TranstechCollaboratory/Section3";
import Section4 from "../../components/TranstechCollaboratory/Section4";
import Section5 from "../../components/TranstechCollaboratory/Section5";
import Section6 from "../../components/TranstechCollaboratory/Section6";
import Section7 from "../../components/TranstechCollaboratory/Section7";
import Section8 from "../../components/TranstechCollaboratory/Section8";
import Section9 from "../../components/TranstechCollaboratory/Section9";
import Section10 from "../../components/TranstechCollaboratory/Section10";
import Section11 from "../../components/TranstechCollaboratory/Section11";
import Section12 from "../../components/TranstechCollaboratory/Section12";
import Section13 from "../../components/TranstechCollaboratory/Section13";
import Section14 from "../../components/TranstechCollaboratory/Section14";
import Section15 from "../../components/TranstechCollaboratory/Section15";

export const Transtech = () => {
  return (
    <div className="flex flex-col font-poppins">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 />
      <Section12 />
      <Section13 />
      <Section14 />
      <Section15 />
    </div>
  );
};
