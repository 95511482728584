import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const RightImageGrid = ({ title, description, imageUrl, linkColor }) => {
  return (
    <div
      className={
        "px-6 py-8 xl:px-20 lg:px-12 md:px-6 xl:py-16 lg:py-16 md:py-12 grid grid-cols-12 gap-4 md:gap-6 lg:gap-8 xl:gap-12"
      }
    >
      <div className="order-2 md:order-1 col-span-12 md:col-span-5 flex-col justify-start items-start">
        <div className="text-stone-900 xl:text-4xl lg:text-3xl md:text-xl text-lg font-semibold mb-4 xl:mb-8 xl:pt-28 lg:pt-12 md:pt-8 text-left cursor-pointer">
          {title}
        </div>
        <div className="text-xs text-left self-stretch text-stone-900 xl:text-md lg:text-sm md:text-xs cursor-pointer">
          {description}
        </div>

        <div
          className={`mt-4 cursor-pointer text-xs xl:text-sm font-bold flex justify-start text-${linkColor}`}
        >
          <div>Explore more at Think Skill India</div>
          <FontAwesomeIcon
            icon={faChevronRight}
            className="font-bold mt-1 ml-2"
          />
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-12 md:col-span-7">
        <img
          className="w-96 md:w-full md:h-full"
          src={imageUrl}
          alt={title}
        />
      </div>
    </div>
  );
};

export default RightImageGrid;
