import React from "react";
import mainImage from "../../../assets/MainMenu/Section23Header.png"
import img2 from "../../../assets/MainMenu/Section23img1.png"
import img3 from "../../../assets/MainMenu/Section23img2.png"
import img4 from "../../../assets/MainMenu/Section23img3.png"
import img5 from "../../../assets/MainMenu/Section23img4.png"
import img6 from "../../../assets/MainMenu/Section23img5.png"
import img7 from "../../../assets/MainMenu/Section23img6.png"
import img8 from "../../../assets/MainMenu/Section23img7.png"
import img9 from "../../../assets/MainMenu/Section23img8.png"
import img10 from "../../../assets/MainMenu/Section23img9.png"
import img11 from "../../../assets/MainMenu/Section23img10.png"

const Section23 = () => {
  return (
    <section className="w-full py-12 xl:py-24 ">
      <div className=" max-w-screen">
        {/* Heading */}
        <div className="text-center mb-8 px-4 md:px-12 lg:px-28">
          <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold ">
            What is there in for you?
          </h2>
        </div>

        {/* Image */}
        <div className="text-center mb-8">
          <img
            src={mainImage}
            alt="Image"
            className="w-5/6 h-auto mx-auto"
          />
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-5 gap-4 px-4 md:px-12 lg:px-28 pt-10 text-sm  text-black">
        {/* First row */}
 
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-3 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-blue-200">
         Recharge and Re-Design My Life with Goal Transformative Mastery
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-3 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-pink-200 md:mt-20">
          ​Reframe ambition and shape destiny for future
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-3 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-amber-200">
          Roadmap to personal transformation skills.
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-3 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-green-200  md:mt-20">
          Identifying Pathways into Creative Transformative Attitude
        </div>
        <div className="col-span-1  text-md md:text-lg font-semibold md:p-3 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-red-200">
          Acquisition of job and work specific skills. 
        </div>
 
        {/* Second row */}
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img2}
          />
        </div>
        <div className="col-span-1 border-2  rounded  shadow text-center h-56 bg-gray-200">
          <img
            className="w-full h-full object-cover"
            src={img3}
          />
        </div>
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img4}
          />
        </div>
        <div className="col-span-1 border-2  rounded  shadow text-center h-56 bg-gray-200">
          <img
            className="w-full h-full object-cover"
            src={img5}
          />
        </div>
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img6}
          />
        </div>
        {/* Third row */}
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-3 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-lime-200  md:-mt-20">
          ​​Architect a career path and learning journey  map.
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-3 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-sky-200">
          ​​Develop connections for learning and collaboration
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-3 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-cyan-200  md:-mt-20">
          Exercising Leadership with well-designed programs.
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-3 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-orange-200">
          Identifying Pathways into Creative Transformative Attitude
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-3 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-pink-200  md:-mt-20">
          Acquisition of job and work specific skills. 
        </div>
        {/* Fourth row */}
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img7}          />
        </div>
        <div className="col-span-1 border-2  rounded  shadow text-center h-56 bg-gray-200">
          <img
            className="w-full h-full object-cover"
            src={img8}          />
        </div>
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img9}          />
        </div>
        <div className="col-span-1 border-2  rounded  shadow text-center h-56 bg-gray-200">
          <img
            className="w-full h-full object-cover"
            src={img10}          />
        </div>
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img11}          />
        </div>
      </div>
      </div>
    </section>
  );
};

export default Section23;
