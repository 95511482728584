import React from "react";
import Section1 from "../../components/HPDSxEngine/Section1";
import Section2 from "../../components/HPDSxEngine/Section2";
import Section3 from "../../components/HPDSxEngine/Section3";
import Section4 from "../../components/HPDSxEngine/Section4";
import Section5 from "../../components/HPDSxEngine/Section5";
import Section6 from "../../components/HPDSxEngine/Section6";
import Section7 from "../../components/HPDSxEngine/Section7";
import Section8 from "../../components/HPDSxEngine/Section8";
import Section9 from "../../components/HPDSxEngine/Section9";
import Section10 from "../../components/HPDSxEngine/Section10";
import Section11 from "../../components/HPDSxEngine/Section11";
import Section12 from "../../components/HPDSxEngine/Section12";


export const HPDSxEngine = () => {
    return (
        <div className="flex flex-col font-poppins">
     <Section1/>
     
    <Section2/>
    
    <Section3/>
    <Section4/>
    
    <Section5/>
    <Section6/>
    <Section7/>
    <Section8/>
    <Section9/>
    <Section10/>
    <Section11/>
    <Section12/>
    
    
    </div>
  )
}

