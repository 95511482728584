import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import CoverSideImg from "../../../assets/Focus/Section1image1.png";

const Section1 = () => {


  return (
    <div className="w-full">
      <div className="max-w-screen">
        <div
          className="w-full h-full grid grid-cols-6 bg-blue-800"

        >

          <div className="order-2 md:order-1 col-span-6 md:col-span-3  flex flex-col text-white py-16 xl:pl-28 xl:py-28 lg:pl-20 lg:py-24 md:pl-12 md:py-16">
            <div className="mb-2 md:mt-6 lg:mt-12 xl:mt-28 pb-4  text-xl  md:text-2xl lg:text-3xl xl:text-4xl cursor-pointer">
              <div className="mx-6 md:mx-0 py-4" style={{ lineHeight: '140%' }}>Our Disruptive focus</div>
              <div className="mx-6 md:mx-0 " style={{ lineHeight: '140%' }}>
                Are you ready to make a difference in the lives <span className=" font-bold"></span><span className="font-bold"> Around the World? </span>
              </div>
            </div>
            <div className="ml-6 md:ml-0 mt-4 px-6 py-3 w-fit hover:bg-blue-500 hover:text-white cursor-pointer rounded-full border border-white hover:border-blue-100 flex items-center justify-start ">
              <div className="text-xs font-bold  ">Let's Start</div>
              <FontAwesomeIcon icon={faArrowRight} className=" ml-2" />
            </div>
          </div>

          <div className="order-1 md:order-2 col-span-6 md:col-span-3 flex justify-start items-start  xl:pt-28 lg:pt-16 md:pt-14">
            <div className="w-full h-full rounded-xl">
              <img
                src={CoverSideImg}
                alt="blank"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
