import React from "react";
import commonDataSet from "../../DataSet/commonDataSet";
import LeftSideImageFlexTemplate from "../../Templates/LeftSideImageFlexTemplate";

const ArticleFirst = () => {
  const data = commonDataSet[20];

  return (
    <div>
      <LeftSideImageFlexTemplate
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
        bgColor={data.bgColor}
        button={data.button}
        buttonName={data.buttonName}
      />
    </div>
  );
};

export default ArticleFirst;
