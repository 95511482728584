
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import Ttl_Img_Card from '../Templates/Ttl_Img_Card';
import RoundPill from '../Templates/RoundPill';


const FlexSlide = ({ slideInfo, contStyle }) => {
  const d = slideInfo.data;
  const style = 'rounded-3xl shadow ';
  return (
    <div className={`md:flex py-10 justify-between ${contStyle}`}>

      <div className=" items-start md:w-4/12 w-11/12 m-auto">
        {slideInfo.icon && (
          <div className='w-14 h-14'>
            <img src={slideInfo.icon} />
          </div>
        )}
        <div className="font-semibold text-3xl md:text-4xl my-4">{slideInfo.title}</div>
        <div className="text-sm font-semibold">{slideInfo.subTit}</div>
        {slideInfo.btn && (
          <button className='py-1 px-4 rounded-full my-4 border border-black border-solid hover:bg-neutral-100 hover:shadow-md' onClick={slideInfo.btn.function}>
            {slideInfo.btn.txt} <FontAwesomeIcon icon={faAngleRight} />
          </button>
        )}
      </div>
      <div className="md:w-7/12 w-11/12 flex gap-2 justify-around md:mr-5 m-auto">
        <div className="h-full md:pt-56 md:pb-20 ">

          <div className={style}>
            <Ttl_Img_Card img={d.at(0).img} title={d.at(0).title}  icon={<RoundPill  digit={'1'} style={`bg-blue-200 text-blue-600`}/>}/>
          </div>

        </div>
        <div className="h-full md:pt-36 ">

          <div className={style}>
            <Ttl_Img_Card img={d.at(1).img} title={d.at(1).title}  icon={<RoundPill  digit={'2'} style={`bg-blue-200 text-blue-600`}/>}/>
          </div>

        </div>
        <div className="h-full  md:pt-16  ">

          <div className={style}>
            <Ttl_Img_Card img={d.at(2).img} title={d.at(2).title}  icon={<RoundPill  digit={'3'} style={`bg-blue-200 text-blue-600`}/>}/>
          </div>

        </div>
      </div>
    </div>
  )
}

export default FlexSlide