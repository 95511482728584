import img1 from "../../../assets/Workshop/Section2_Img1.jpg";
import img2 from "../../../assets/Workshop/Section2_Img2.jpg";
import img3 from "../../../assets/Workshop/Section2_Img3.jpg";
import img4 from "../../../assets/Workshop/Section2_Img4.jpg";
import img5 from "../../../assets/Workshop/Section2_Img5.jpg";
import img6 from "../../../assets/Workshop/Section2_Img6.jpg";

const workshopData = [
  {
    id: 1,
    title: "Leadership Development",
    description:
      "Focusing on leadership skills, effective communication, decision-making, and team management for aspiring or current leaders.",
    imageUrl: img1,
    alt: "Leadership Development",
  },
  {
    id: 2,
    title: "Mindfulness and Stress Reduction",
    description:
      "Teaching meditation techniques, mindfulness practices, and stress management strategies for overall well-being.",
    imageUrl: img2,
    alt: "Mindfulness and Stress Reduction",
  },
  {
    id: 3,
    title: "Coding and Software Development",
    description:
      "Offering workshops on programming languages, web development, app creation, or software engineering for tech enthusiasts and professionals.",
    imageUrl: img3,
    alt: "Coding and Software Development",
  },
  {
    id: 4,
    title: "Public Speaking and Presentation Skills",
    description:
      "Providing tips on effective public speaking, confident presentation delivery, storytelling, and overcoming stage fright for improved communication.",
    imageUrl: img4,
    alt: "Public Speaking and Presentation Skills",
  },
  {
    id: 5,
    title: "Creative Writing and Storytelling",
    description:
      "Exploring various writing styles, storytelling techniques, character development, and narrative structures for aspiring writers.",
    imageUrl: img5,
    alt: "Creative Writing and Storytelling",
  },
  {
    id: 6,
    title: "Photography and Visual Storytelling",
    description:
      "Covering photography techniques, composition, editing skills, and visual storytelling for photography enthusiasts and professionals.",
    imageUrl: img6,
    alt: "Photography and Visual Storytelling",
  },
];

export default workshopData;
