import React from 'react'

const Ttl_Img_Card = ({img, title, subTtl, icon}) => {
  return (
    <div className="bg-white lg:p-6 lg:py-9  md:p-3  py-3 w-full h-full overflow-visible rounded-3xl">
      <div className='relative left-3 lg:-top-12 -top-6' style={{"z-index":"10"}}>{icon}</div>
      <div className="flex justify-center">
        <img
          src={img}  // Replace with the actual image URL
          alt="Card Image"
          className="w-3/5 md:w-1/2 rounded-md"
        />
      </div>

      <div className="text-center lg:text-xl mt-5 text-base w-11/12">
        <h3 className="font-semibold  text-1D4ED8">{title}</h3>
      </div>

      <div className="text-center text-xs md:text-base mt-1 w-11/12">
        <p className="">{subTtl}</p>
      </div>
    </div>
  )
}

export default Ttl_Img_Card