import s1 from "./s1.png"
import s2 from "./s2.png"
import s3 from "./s3.png"


const dataset = [
  {
    id: 1,
    title: (
      <>
        <span className="text-yellow-400">1. Emotional</span> guidance system
      </>
    ),
    description: (
      <>
        Think about what you want to achieve, why it’s important to you, and how
        you’re going to do it. Leave the rest — insecurity about your age, the
        whispers of naysayers, fear of not being taken seriously — to the side,
        as it’s not useful.
      </>
    ),
    img: s1
  },
  {
    id: 2,
    title: (
      <>
        <span className="text-yellow-400">2. Potential</span> development system
      </>
    ),
    description: (
      <>
        Your why is just as important as your what. If you don’t understand
        yourself enough to know why you want to get where you want to go, you
        may not know yourself well enough to help get you there.{" "}
      </>
    ),
    
    img: s2
  },
  {
    id: 3,
    title: (
      <>
        3. Intelligent<span className="text-yellow-400"> Goal</span> system
      </>
    ),
    description: (
      <>
        I was just blindly walking down a set path to an unexamined end goal.
        When I questioned myself about why I wanted to be a CEO, I learned the
        things that were foundational to my success: I loved problem-solving and
        using my diverse experience in varied fields
      </>
    ),
    
    img: s3
  },
];

export default dataset;
