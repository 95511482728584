import React from "react";
import LandingCover from "../../components/Mission&Vision/LandingCover";
import Section1 from "../../components/Mission&Vision/Section1";
import Section3 from "../../components/Mission&Vision/Section3";
import Section4 from "../../components/Mission&Vision/Section4";
import Section2 from "../../components/Mission&Vision/Section2";
import Section5 from "../../components/Mission&Vision/Section5";

export default function Layout() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </div>
  );
}
