import React from "react";


import CoverSideImg from "../../../assets/Focus/Section5image1.png";

const Section5 = () => {


  return (
    <div className="w-full">
      <div className="max-w-screen">


        <div
          className="w-full h-full grid grid-cols-12 bg-purple-500"

        >
          
            <div className=" col-span-12 md:col-span-6 place-content-start flex flex-col text-white py-16 xl:pl-20 xl:py-20 lg:pl-20 lg:py-16 md:pl-12 md:py-16">
              <div className="mb-2 mt-8  md:mt-6 lg:mt-12 xl:mt-28 pb-4  text-xl xl:text-3xl md:text-xl lg:text-2xl cursor-pointer">

                <div className="mx-4 md:mx-0 text-lg text-justify" style={{ lineHeight: '140%' }}>
                  There are <span className='text-yellow-500'>no rules for achieving your goals and living</span> the lives that you want to live, but <span className='text-yellow-500'>we aim to help</span> along the way. <span className='text-yellow-500'>You can master </span> your everyday experiences in the real world.
                </div>
              </div>

            </div>

            <div className=" col-span-12 md:col-span-6 flex justify-start items-start xl:-pl-4 xl:pt-20 lg:pt-16 md:pt-14">
              <div className="w-full h-full rounded-xl">
                <img
                  src={CoverSideImg}
                  alt="blank"
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
          
        </div>

      </div>
    </div>
  );
};

export default Section5;
