import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import img_1 from "../../../assets/ThinkSkillIndia/img_17.jpg";

const data = {
  title: (
    <>
      We need to act accordingly:
      <br /> Every aspect of
      <span className="text-blue-400"> our education system</span>—PreK-12
    </>
  ),
  desc: (
    <>
      Postsecondary and adult education, after-school and youth development,
      workforce development and training, and teacher preparation programs—must
      be aligned to prepare citizens with the 21st century skills they need to
      compete.
    </>
  ),
  img: img_1,
};

const Section14 = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  return (
    <motion.div
      className="w-full flex flex-col xl:gap-14 lg:gap-10 md:gap-6 gap-3 h-full xl:py-24 lg:py-20 md:py-16 py-8 xl:px-32 lg:px-28 md:px-16 px-4"
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }}
    >
      <div className="text-2xl xl:text-5xl lg:text-4xl md:text-3xl   text-center  font-bold">
        {data.title}
      </div>
      <div className="xl:text-2xl lg:text-xl md:text-lg text-md   text-center  font-semibold" style={{ lineHeight: "140%" }}>
        {data.desc}
      </div>
      <motion.img
        src={data.img}
        className="w-full xl:px-24 lg:px-20  rounded-3xl "
        initial={{ opacity: 0, scale: 0.8, x: 30 }}
        animate={{ opacity: inView ? 1 : 0, scale: 1, x: 0 }}
        transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }}
      />
    </motion.div>
  );
};

export default Section14;
