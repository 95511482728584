import React from "react";
import Cover from "../../components/hpdsXhub/Hpdsx/Cover"
import FirstSection from "../../components/hpdsXhub/Hpdsx/FirstSection"
import SecondSection from  "../../components/hpdsXhub/Hpdsx/SecondSection"
import ThirdSection from "../../components/hpdsXhub/Hpdsx/ThirdSection"
import FourthSection from  "../../components/hpdsXhub/Hpdsx/FourthSection"
import FifthSection from "../../components/hpdsXhub/Hpdsx/FifthSection"
import SixthSection from "../../components/hpdsXhub/Hpdsx/SixthSection"
import SeventhSection from  "../../components/hpdsXhub/Hpdsx/SeventhSection"
import EightSection from  "../../components/hpdsXhub/Hpdsx/EightSection"

export default function General() {
  return (
    <div className="flex flex-col">
      <Cover />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
      <SeventhSection />
      <EightSection />
    </div>
  );
}
