import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import imgURL_2 from "../../../assets/MainMenu/skill-1.jpg";
import imgURL_1 from "../../../assets/MainMenu/skill-2.jpg";
import imgURL_3 from "../../../assets/MainMenu/skill-3.jpg";
import icon_1 from '../../../assets/HPDSxMenu/Section14image1.png';
import icon_2 from '../../../assets/HPDSxMenu/Section14image2.png';
import icon_3 from '../../../assets/HPDSxMenu/Section14image3.png';
import icon_4 from '../../../assets/HPDSxMenu/Section14image4.png';

const Section22 = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });
    const data1 = (
      <>
        Skills for Learning, Skill for Work and, Skill for Life
      </>
    );
  
    const data2 = {
      img1: imgURL_1,
      img2: imgURL_2,
      img3: imgURL_3,
      item1: {
        icon: icon_1,
        heading: <>Skills Programmes Roadmap</>,
        desc: (
          <>
            Roadmap will drive actions that enhance connections for Indigenous Indian Students to skills, jobs, and business opportunities.
          </>
        ),
      },
      item2: {
        icon: icon_2,
        heading: <>The Student Consultancy</>,
        desc: (
          <>
            Equips students with key and demonstrable consulting and teamwork skills to help local businesses, charities, and community organizations.
          </>
        ),
      },
      item3: {
        icon: icon_3,
        heading: <>Body Image Anxieties</>,
        desc: (
          <>
            Specialized form of online counseling that focuses specifically on body image issues. It may involve techniques such as exposure therapy, mindfulness, and cognitive restructuring
          </>
        ),
      },
      item4: {
        icon: icon_4,
        heading: <>Collaborative Learning Experience</>,
        desc: (
          <>
            Dive deep into learning with interactive lessons, earn professional development hours, acquire certifications, and find programs that help meet your goals.
          </>
        ),
      },
      item5: {
        icon: icon_1,
        heading: <>Becoming an adult with responsibilities</>,
        desc: (
          <>
            Learning new skills and developing those you most enjoy will contribute crucially to your overall employability as the majority of organisations look for both academic qualifications and interests beyond academia.
          </>
        ),
      },
      item6: {
        icon: icon_2,
        heading: <>Comprehensive Counseling</>,
        desc: (
          <>
            Help students develop growth mindset, navigate their emotions, and build a foundation for success.
          </>
        ),
      },
      item7: {
        icon: icon_3,
        heading: <>Academic and Career paths</>,
        desc: (
          <>
            Whether you are looking to build new skills on your own or connect with workforce development programs, learning partners, local universities, or non-profit organizations, we can help you achieve your goals.
          </>
        ),
      },
      item8: {
        icon: icon_4,
        heading: <>Mind health and wellness</>,
        desc: (
          <>
            Enhance the creation and availability of educational and multinational opportunities that increase cooperation and interaction.
          </>
        ),
      },
      item9: {
        icon: icon_1,
        heading: <>Future Leaders Innovation Programme </>,
        desc: (
          <>
            Platform to share the latest developments and encourage strong peer-to-peer communication across the community.
          </>
        ),
      },
    };
    return (
      <motion.div
      className="w-full h-full xl:px-24 lg:px-16 md:px-8 px-4 xl:py-20 lg:py-12 md:py-8 py-8 bg-yellow-300"
      ref={ref}
      initial={{ opacity: 0, y: 60 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
      transition={{ duration: 0.5, ease: "easeIn", delay: 0.2 }}
    >
        <div className="text-2xl xl:text-4xl lg:text-3xl md:text-2xl font-semibold text-center">
          {data1}
        </div>
        <div className={`w-full xl:text-lg lg:text-base md:text-sm `}>
          <div className=" w-full h-full grid grid-cols-2 md:grid-cols-5 gap-x-2 gap-4 md:gap-2 pt-6 xl:pt-12 lg:pt-10 md:pt-8 ">
            <img
              className="col-span-2 w-full h-full  rounded-2xl"
              src={data2.img1}
            />
            <div className="col-span-1 flex flex-col xl:text-base lg:text-sm md:text-xs gap-2 bg-white p-3 rounded-2xl">
              <img
                className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-8 h-8"
                src={data2.item1.icon}
              />
              <div className="font-semibold">{data2.item1.heading}</div>
              <div>{data2.item1.desc}</div>
            </div>
            <div className="col-span-1 flex flex-col xl:text-base lg:text-sm md:text-xs gap-2 bg-white p-3 rounded-2xl">
              <img
                className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-8 h-8"
                src={data2.item2.icon}
              />
              <div className="font-semibold">{data2.item2.heading}</div>
              <div>{data2.item2.desc}</div>
            </div>
            <div className="col-span-2 md:col-span-1 flex flex-col xl:text-base lg:text-sm md:text-xs gap-2 bg-white p-3 rounded-2xl">
              <img
                className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-8 h-8"
                src={data2.item3.icon}
              />
              <div className="font-semibold">{data2.item3.heading}</div>
              <div>{data2.item3.desc}</div>
            </div>
          </div>
          <div className=" w-full h-full grid grid-cols-2 md:grid-cols-5 gap-x-2 gap-4 md:gap-2 pt-2 ">
            <div className="col-span-2 md:col-span-1 flex flex-col xl:text-base lg:text-sm md:text-xs gap-2 bg-white p-3 rounded-2xl">
              <img
                className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-8 h-8"
                src={data2.item4.icon}
              />
              <div className="font-semibold">{data2.item4.heading}</div>
              <div>{data2.item4.desc}</div>
            </div>
            <img
              className="col-span-2 w-full h-full max-h-96 rounded-2xl"
              src={data2.img2}
            />
            <div className="col-span-1 flex flex-col xl:text-base lg:text-sm md:text-xs gap-2 bg-white p-3 rounded-2xl">
              <img
                className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-8 h-8"
                src={data2.item5.icon}
              />
              <div className="font-semibold">{data2.item5.heading}</div>
              <div>{data2.item5.desc}</div>
            </div>
            <div className="col-span-1 flex flex-col xl:text-base lg:text-sm md:text-xs gap-2 bg-white p-3 rounded-2xl">
              <img
                className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-8 h-8"
                src={data2.item6.icon}
              />
              <div className="font-semibold">{data2.item6.heading}</div>
              <div>{data2.item6.desc}</div>
            </div>
          </div>
          <div className=" w-full h-full grid grid-cols-2 md:grid-cols-5 gap-x-2 gap-4 md:gap-2 pt-2 ">
            <div className="col-span-1 flex flex-col xl:text-base lg:text-sm md:text-xs gap-2 bg-white p-3 rounded-2xl">
              <img
                className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-8 h-8"
                src={data2.item7.icon}
              />
              <div className="font-semibold">{data2.item7.heading}</div>
              <div>{data2.item7.desc}</div>
            </div>
            <div className="col-span-1 flex flex-col xl:text-base lg:text-sm md:text-xs gap-2 bg-white p-3 rounded-2xl">
              <img
                className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-8 h-8"
                src={data2.item8.icon}
              />
              <div className="font-semibold">{data2.item8.heading}</div>
              <div>{data2.item8.desc}</div>
            </div>
            <img
              className="col-span-2 w-full h-full  rounded-2xl"
              src={data2.img3}
            />
            <div className="col-span-2 md:col-span-1 flex flex-col xl:text-base lg:text-sm md:text-xs gap-2 bg-white p-3 rounded-2xl">
              <img
                className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-8 h-8"
                src={data2.item9.icon}
              />
              <div className="font-semibold">{data2.item9.heading}</div>
              <div>{data2.item9.desc}</div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  };
  
  export default Section22;