import React from "react";
import img_1 from "../../../assets/Communities/img_3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Section1 = () => {
  const data = {
    title: <>Technical Communities</>,
    description: (
      <>
        HPDS develops communities around particular technology challenges or
        cutting-edge subject areas. Their areas of coverage may be general in
        nature, which include multidisciplinary topics or emerging concepts.
        Discover which technical community best matches your ​interest​s​.
      </>
    ),
    imageUrl: img_1,
    buttonContent: <>Join HPDS Community</>,
  };
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };
  return (
    <div className="w-full h-auto my-10">
      <div className="font-bold w-full text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-blue-500 px-8 md:px-10 lg:px-12  py-5 lg:py-10" style = {{color : '#0086E8'}}>
        {data.title}
      </div>
      <div className=" px-8 md:px-10 md:px-20 w-full h-auto mx-auto grid grid-cols-12 ">
        <div className="md:col-span-6 col-span-12 ">
          <img src={data.imageUrl} className="w-4/5" />
        </div>
        <div className="md:col-span-6 col-span-12 leading-normal pt-5 lg:pt-20 text-lg md:text-xl lg:text-2xl xl:text-3xl text-justify">
          {data.description}
          <div className=" py-8 ">
            <button
              className="text-white  px-6 py-3 text-sm lg:text-lg rounded-lg hover:border-2 hover:border-blue-500 hover:text-yellow-400 mx-auto"
              type="submit"
              onClick={handleInputChange}
              style = {{backgroundColor : '#0086E8'}}
            >
              Join HPDS Community
              <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
