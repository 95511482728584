import React from "react";
import cardData from "./articlethirdData"; 

const ArticleThird = () => {
  return (
    <div className="w-full h-full bg-neutral-100">
    <div className="max-w-screen-xl mx-auto h-full py-8 xl:py-20 lg:py-12 md:py-4 px-4 flex flex-col items-center">
      <div className="text-center text-stone-900 mb-6 text-2xl xl:text-4xl lg:text-3xl md:text-2xl font-semibold">
        Key Features of Articles
      </div>
      <div className="flex max-w-screen-lg mx-auto justify-center items-center flex-wrap xl:mt-8 lg:mt-4 md:mt-0">
        <div className="w-full grid grid-cols-12 gap-2 md:gap-8">
          {cardData.map((item, index) => (
            <div className="col-span-12 md:col-span-4 p-2 px-4" key={index}>
              <div className="w-64 md:w-full h-96 flex flex-col justify-center items-center bg-white rounded-lg hover:shadow-md hover:rounded-md">
                <div className="h-5/12 w-full flex justify-center items-center">
                  <img
                    className="w-32 h-32 xl:w-40 xl:h-40 lg:w-36 lg:h-36 md:w-24 md:h-24 rounded-md object-cover"
                    src={item.image}
                    alt={item.alt || "Card Image Alt Text"}
                  />
                </div>
                <div className="w-full h-7/12 text-center py-3 px-3">
                  <div className="text-center w-full text-blue-700 xl:text-xl lg:text-lg md:text-sm font-semibold mt-2 mb-2">
                    {item.title}
                  </div>
                  <div className="w-full text-center text-stone-900 xl:text-md lg:text-sm md:text-xs">
                    {item.content}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default ArticleThird;
