import Img1 from "../../../assets/Competitions/Section1_Img1.jpg";
import Img2 from "../../../assets/Competitions/Section1_Img2.jpg";
import Img3 from "../../../assets/Competitions/Section1_Img3.jpg";

const section1Data = [
  {
    id: 1,
    title: "International Olympiad in Informatics (IOI)",
    category: "Knowledge",
    status: "Ongoing",
    image: Img1,
  },
  {
    id: 2,
    title: "Google Code Jam",
    category: "Knowledge",
    status: "Ongoing",
    image: Img2,
  },
  {
    id: 3,
    title: "Topcoder Open (TCO)",
    category: "Knowledge",
    status: "Ongoing",
    image: Img3,
  },
  {
    id: 4,
    title: "HackerRank Contests",
    category: "Knowledge",
    status: "Ongoing",
    image: Img1,
  },
  {
    id: 5,
    title: "International Olympiad in Informatics (IOI)",
    category: "Knowledge",
    status: "Ongoing",
    image: Img1,
  },
  {
    id: 6,
    title: "Google Code Jam",
    category: "Knowledge",
    status: "Ongoing",
    image: Img2,
  },
  {
    id: 7,
    title: "Topcoder Open (TCO)",
    category: "Knowledge",
    status: "Ongoing",
    image: Img3,
  },
  {
    id: 8,
    title: "HackerRank Contests",
    category: "Knowledge",
    status: "Ongoing",
    image: Img1,
  },
];

export default section1Data;
