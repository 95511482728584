import Section4Image1 from '../../../assets/HPDSxEngine/Section9image1.png';
import Section4Image2 from '../../../assets/HPDSxEngine/Section9image2.png';
import Section4Image3 from '../../../assets/HPDSxEngine/Section9image3.png';


const DatasetSec4 = {
  buttons: [
    {
      id: 1,
      heading: "Insightful HPD Profiles",
      description: "HPD profile reports provide a comprehensive set of maps, tables, and contextual information about how the learner are doing developmentally in a given community, along with detailed information about how to under-stand the HPDI results.",
      image: Section4Image1
    },
    {
      id: 2,
      heading: "Lifelong Learning Platform",
      description: "​Giving the students and professional a life long learning & skill management platform that is feature rich, highly interactive, self service, mobile and secure using the HPDI. ",
      image: Section4Image2 
    },
    {
      id: 3,
      heading: "Elevating Career Development",
      description: "Transforming the “Career Management” framework and taking the EVM (Economic Value Addition) to new levels​use this information to implement programs that will help children succeed as they progress through the education and professional years.",
      image: Section4Image3 
    }
  ]
};

export default DatasetSec4;
