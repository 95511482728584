import React from "react";
import Section1 from "../../components/Counsils/Section1";
import Section2 from "../../components/Counsils/Section2";
import Section3 from "../../components/Counsils/Section3";
import Section4 from "../../components/Counsils/Section4";
import Section5 from "../../components/Counsils/Section5";



export const Counsils = () => {
    return (
        <div className="flex flex-col font-poppins">
    <Section1/>
    <Section2/>
    
    <Section3/>
    <Section4/>
    
   <Section5/>  

    
    </div>
  )
}

