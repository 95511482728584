import learn1 from '../../../../assets/Learn&Grow/learn1.png';
import learn2 from '../../../../assets/Learn&Grow/learn2.png';
import learn3 from "../../../../assets/Learn&Grow/learn3.png";

const learnData = [
    {
      id: 1,
      title: "Employability Profile",
      bgColor: "bg-emerald-300",
      imageUrl: `${learn1}`,
    },
    {
      id: 2,
      title: "Employability Skill",
      bgColor: "bg-white",
      imageUrl: `${learn2}`,
    },
    {
      id: 3,
      title: "Career Readiness",
      bgColor: "bg-red-200",
      imageUrl: `${learn3}`,
    },
    {
      id: 4,
      title: "Career Readiness",
      bgColor: "bg-stone-200",
      imageUrl: `${learn1}`,
    },
    {
      id: 5,
      title: "Employability Skill",
      bgColor: "bg-stone-200",
      imageUrl: `${learn2}`,
    },
    {
      id: 6,
      title: "Employability Profile",
      bgColor: "bg-white",
      imageUrl: `${learn3}`,
    },
    {
      id: 7,
      title: "Employability Profile",
      bgColor: "bg-white",
      imageUrl: `${learn2}`,
    },
    {
      id: 8,
      title: "Employability Profile",
      bgColor: "bg-white",
      imageUrl: `${learn3}`,
    },
    {
      id: 9,
      title: "Employability Profile",
      bgColor: "bg-white",
      imageUrl: `${learn1}`,
    },
    {
      id: 10,
      title: "Employability Profile",
      bgColor: "bg-white",
      imageUrl: `${learn2}`,
    },
    {
      id: 11,
      title: "Employability Profile",
      bgColor: "bg-white",
      imageUrl: `${learn1}`,
    },
    {
      id: 12,
      title: "Employability Profile",
      bgColor: "bg-white",
      imageUrl: `${learn1}`,
    },
  ];
  export default learnData;