import React from 'react';
import Image1 from '../../../assets/Carrer/HowToApply.png';
import Image2 from "../../../assets/Carrer/ApplyProcedure.png"

const Section10 = () => {
  return (
     <div className="p-4 w-full">
      <div className='max-w-screen-xl mx-auto'>
    <div className='p-6 md:p-12 lg:p-20 xl:px-24'>
      <img src={Image1} alt="Descriptive Alt Text" className="w-3/5" />
      </div>
      <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold text-blue-500 mx-12 mb-6">
        Apply Now 
      </h2>
      <div className='p-6 md:p-12 lg:p-20'>
      <img src={Image2} alt="Descriptive Alt Text" className="w-full" />
      </div>
      </div>
    </div>
  );
};

export default Section10;
