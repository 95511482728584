import React from 'react';
import fullWidthImage from '../../../assets/Carrer/journey.png';
import image2 from "../../../assets/Carrer/first step.png"

const Section7 = () => {
  return (
     <div className="p-4 w-full">
      <div className='max-w-screen-xl mx-auto'>
      <h2 className="text-3xl md:text-4xl font-bold text-blue-500 mx-8 my-8">
        HPDS is  a stage full of opportunities
      </h2>
      <div className='p-6 md:p-12'>
      <img src={fullWidthImage} alt="Descriptive Alt Text" className="w-full" />
      </div>
      <div className='md:p-12 w-9/12'>
      <img src={image2} alt="Descriptive Alt Text" className="w-full" />
      </div>
    </div>
    </div>
  );
};

export default Section7;
