import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const SlideWithImage = ({slideInfo, contStyle }) => {
  return (
    <div className={`md:flex justify-center  ${contStyle}`}>
      <div className='w-4/5 md:w-2/5 rounded-lg items-center m-auto'>
        <img src={slideInfo.img}  />
      </div>
      <div className=" py-5 px-[3vw] items-start lg:w-1/2 md:w-1/2 w-11/12">
        {slideInfo.icon && (
          <div className='w-14 h-14 '>
            <img src={slideInfo.icon}  />
          </div>
        )}
        {slideInfo.preTitle && (
          <div className='text-1D4ED8 font-semibold'>
            {slideInfo.preTitle} 
          </div>
        )}
        <div className="font-semibold lg:text-4xl md:text-3xl text-2xl mb-4">{slideInfo.title}</div>
        <div className="text-sm font-semibold">{slideInfo.subTit}</div>
        {slideInfo.btn && (
          <button className={`py-1 px-4 rounded-full my-4 border border-black border-solid hover:bg-neutral-100 hover:shadow-md`} onClick={slideInfo.btn.function}>
            {slideInfo.btn.txt} <FontAwesomeIcon icon={faAngleRight} />
          </button>
        )}
      </div>
    </div>
  )
}

export default SlideWithImage