import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintbrush } from "@fortawesome/free-solid-svg-icons";
import DatasetSec4 from "./DatasetSec7";
import image from "../../../assets/TalentCollaboratory/section8image1.png";

const Section4 = () => {
  const { buttons } = DatasetSec4;
  const [selectedButton, setSelectedButton] = useState(buttons[0]);
  const [loaded, setLoaded] = useState(false);
  const controlsLeft = useAnimation();
  const controlsRight = useAnimation();

  const handleButtonClick = async (button) => {
    await Promise.all([
      controlsLeft.start({
        opacity: 0,
        x: -50,
        transition: { ease: "easeInOut", duration: 0.3, delay: 0.1 },
      }),
      controlsRight.start({
        opacity: 0,
        x: -50,
        transition: { ease: "easeInOut", duration: 0.3, delay: 0.1 },
      }),
    ]);
    setSelectedButton(button);
    await Promise.all([
      controlsLeft.start({
        opacity: 1,
        x: 0,
        transition: { ease: "easeInOut", duration: 0.3, delay: 0.1 },
      }),
      controlsRight.start({
        opacity: 1,
        x: 0,
        transition: { ease: "easeInOut", duration: 0.3, delay: 0.1 },
      }),
    ]);
  };

  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className="w-full h-full bg-gradient-to-br from-[#0086E8] to-[#3D008B]">
      <div className="p-4 flex flex-col text-white">
        <h1
          className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-loose mb-4 pt-8 md:pt-12 lg:pt-24 mx-4 md:mx-12 lg:mx-20 xl:mx-64 font-semibold text-center"
          style={{ lineHeight: "140%" }}
        >
          <span className="text-white  ">
            Integrated Student{" "}
            <span className="text-yellow-500">Wellness Platform</span>{" "}
          </span>
        </h1>

        <div
          className="py-2 text-md md:text-lg lg:text-xl xl:text-2xl text-justify xl:px-28 lg:px-20 md:px-12 px-4"
          style={{ lineHeight: "140%" }}
        >
          HPDSx Platform Student Mental Health and Wellbeing aims to assist
          schools and institutions to develop a ‘whole-of-human being’ approach.
          It identifies key action areas for promoting student mental health and
          wellbeing as well as institutional enablers for achieving those
          actions. For each action, the platform identifies priority activities
          and possible measures of progress that can be adapted for different
          environments, acknowledging that individual institutional approaches
          will vary according to local contexts and priorities.
        </div>

        <div className="py-4">
          <img src={image} className="mx-auto" />
        </div>

        <div
          className="py-6 text-md md:text-lg lg:text-xl xl:text-2xl text-justify xl:px-24 lg:px-20 md:px-12 px-4 text-yellow-400"
          style={{ lineHeight: "140%" }}
        >
          OUR EMERGING SOLUTIONS WITH Analytics, measurement, and monitoring
          systems enable schools, universities, and workplaces to adopt
          data-informed approaches to mental health management. in ANCIENT
          TEACHING AND LEARNINGS deliver positive outcomes.
        </div>

        <div className="grid grid-grid-cols-1 xl:grid-cols-6 lg:grid-cols-4  md:grid-cols-3  gap-2 md:gap-3 lg:gap-3 xl:gap-10 py-4 md:py-4 mx-4  md:mx-8 lg:mx-16  xl:mx-24">
          {buttons.map((button) => (
            <motion.button
              key={button?.id}
              className={`text-center  hover:bg-yellow-400  hover:text-white md:text-sm lg:text-md  font-semibold py-3 lg:px-8 xl:px-6 rounded-xl whitespace-nowrap border ${
                selectedButton === button
                  ? "text-white bg-yellow-400 border-yellow-400 hover:border-transparent"
                  : "text-yellow-400 border-yellow-400 hover:border-transparent"
              } transition duration-300 ease-in-out`}
              onClick={() => handleButtonClick(button)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {button.heading1}
            </motion.button>
          ))}
        </div>
        <div className="grid grid-cols-12 gap-4 lg:mb-16 pt-10">
          {/* Left Section */}
          <div className="col-span-12 md:col-span-6 lg:col-span-6 text-left px-4 md:px-8 lg:px-12 xl:pl-24 xl:pr-12 my-8 md:my-8 lg:my-20 lg:ml-10 xl:ml-0 ">
            <>
              <div>
                <div
                  className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-8"
                  style={{ lineHeight: "140%" }}
                >
                  {selectedButton.heading}
                </div>
                <div
                  className="text-sm md:text-md lg:text-lg xl:text-xl text-justify "
                  style={{ lineHeight: "140%" }}
                >
                  {selectedButton.description}
                </div>
              </div>
            </>
          </div>
          {/* Right Section */}
          <div
            className="col-span-12 md:col-span-6 lg:col-span-6 flex px-4 md:px-0  justify-center items-center"
            style={{ overflow: "hidden" }}
            initial={{ opacity: 1, x: 0 }}
            animate={controlsRight}
            variants={{
              visible: {
                opacity: 1,
                x: 0,
                transition: { duration: 1.3, ease: "easeOut", delay: 0.6 },
              },
              hidden: { opacity: 0, x: 100 },
            }}
          >
            <img
              src={selectedButton.image}
              alt="Right Section"
              className="w-full md:mr-16 md:w-full lg:w-2/3"
            />
          </div>
        </div>

        <div
          className="py-8 md:py-0 xl:pb-10 text-md md:text-lg lg:text-xl xl:text-2xl text-justify xl:px-28 lg:px-20 md:px-12 px-4 "
          style={{ lineHeight: "140%" }}
        >
          Understanding the factors that predict children’s successful
          adaptation is a fundamental interest of parents, teachers, and those
          societal agencies that wish to find ways in which to guide children on
          a positive developmental path.
        </div>
      </div>
    </div>
  );
};

export default Section4;
