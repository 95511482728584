import React from 'react';
import backgroundImage from '../../../assets/ChangeAgent/Section1imagebg.png'
import Section1image1 from '../../../assets/ChangeAgent/Section1image1.png'


const SectionWithBackgroundImage = () => {
    return (
        <div className="relative h-[80vh] md:h-[100vh] xl:h-[90vh] bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="absolute top-20 md:top-32 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mb-16">
                <img src={Section1image1} alt="Top Center Image" className="w-[100vw] md:w-full " />
            </div>
            <div className="absolute bottom-40 left-1/2 transform -translate-x-1/2 text-center w-full ">
                <h1 className=" md:text-2xl lg:text-3xl xl:text-4xl  text-white py-4">Do you want to become a part of</h1>
                <h1 className=" md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white py-4">HUMAN POTENTIAL DEVELOPMENT SCIENTIST</h1>
                <h1 className=" md:text-2xl lg:text-3xl xl:text-4xl text-white pt-0 pb-0 md:pt-4 md:pb-8">that changes lives?</h1>

               
            </div>
            
        </div>

    );
};

export default SectionWithBackgroundImage;
