import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import imgURL from "../../../assets/ThinkSkillIndia/img1_sec8.jpg";

const Section8 = () => {
  const { ref, inView } = useInView({ threshold: 0.15 });

  const data = {
    item1: (
      <>
        Today’s students face high complexity, but tomorrow’s students cope with
        even more.
      </>
    ),
    item2: (
      <>
        We are on the verge of the next stage of human flourishing, where we
        will finally use technology to build services and products that enable
        personal transformation at scale. Fulfilment, Meaning, Belonging,
        Purpose, Self-Awareness, Self-Knowledge, Connection, and the means to
        find them become increasingly important and valued.
      </>
    ),
    item3: (
      <>
        <ul className="list-disc ml-4 pl-4">
          <li className="mb-4">Millennials focus on purpose and meaning</li>
          <li className="mb-4">
            Technological Unemployment & Power to the Individual
          </li>
          <li>Advances in behavioral psychology & AI</li>
        </ul>
      </>
    ),
    item4: imgURL,
  };

  return (
    <motion.div
      className="w-full xl:h-[92vh] lg:h-[100vh] md:h-[90vh]  flex flex-col md:flex-row"
      ref={ref}
      initial={{ opacity: 0, y: 70 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 70 }}
      transition={{ duration: 0.7, ease: "easeIn", delay: 0.2 }}
    >
      <div
        className="w-full h-full"
        style={{
          backgroundImage: `url(${imgURL})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="w-full h-full flex justify-center pt-12 md:pt-28 lg:pt-32 xl:pt-24  xl:px-32 lg:px-20 md:px-12 px-4 bg-blue-500 bg-opacity-40">
          <div
            className="text-white xl:text-5xl lg:text-4xl md:text-3xl text-2xl xl:leading-tight lg:leading-10 md:leading-10 font-bold"
            style={{ lineHeight: "140%" }}
          >
            {data.item1}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col xl:gap-8 lg:gap-6 md:gap-4 h-full xl:text-3xl lg:text-2xl md:text-xl text-lg bg-blue-500 xl:p-16 lg:p-12 md:p-8 p-4 text-white">
        <div>{data.item2}</div>
        <div className="font-semibold">{data.item3}</div>
      </div>
    </motion.div>
  );
};

export default Section8;
