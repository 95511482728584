import CardImage1 from '../../../assets/HPDSxMenu/Section5image1.png';
import CardImage2 from '../../../assets/HPDSxMenu/Section5image2.png';
import CardImage3 from '../../../assets/HPDSxMenu/Section5image3.png';
import CardImage4 from '../../../assets/HPDSxMenu/Section5image4.png';



const DatasetSec5 = [
  {
    id: 1,
    title:<><span className='text-blue-500'>Learning & development</span></>,
    description: <>You’ll be well-supported throughout the summer and have plenty of scope to expand your skills. Examples include</>,
    image: CardImage1
  },
  {
    id: 2,
    title:<><span className='text-green-500'>Insight Sessions</span></>,
    description: <>Internship is a great opportunity to meet a variety of people and get an insight into the work carried out within each of business and technology areas. Our insight sessions take place throughout our internship program, showcasing topics such as innovation in Technology etc</>,
    image: CardImage2
  },
  {
    id: 3,
    title:<><span className='text-yellow-500'>Presentation Skills</span></>,
    description: <>Internship is a great opportunity to meet a variety of people and get an insight into the work carried out within each of business and technology areas. Our insight sessions take place throughout our internship program, showcasing topics such as innovation in Technology etc</>,
    image: CardImage3
  },
  {
    id: 4,
    title:<><span className='text-red-500'>Skills for Life Sessions</span></>,
    description: <> This program gives an introduction to skills that enable you to succeed in all aspects of your life and includes everything from Professional, Brand, Finance, Healthy Eating etc</>,
    image: CardImage4
  },
  
];

export default DatasetSec5;
