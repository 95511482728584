import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import imgURL from "../../../assets/ThinkSkillIndia/img_9.png";

const Section5 = () => {
  const { ref, inView } = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="w-full h-full p-4 md:p-20"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, scale: 0.9 },
        hidden: { opacity: 0, scale: 0.5 },
      }}
      transition={{ duration: 0.7, ease: "easeInOut", delay: 0.3 }}
    >
      <motion.img
        src={imgURL}
        alt="ThinkSkillIndia"
        className="max-w-screen-xl   h-[700px] mx-auto  object-cover"
      />
    </motion.div>
  );
};

export default Section5;
