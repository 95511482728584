import React from "react";
import LandingSection from "../../components/About Hpds/LandingCover";
import Section2 from "../../components/About Hpds/Section2";
import Section3 from "../../components/About Hpds/Section3";
import Section5 from "../../components/About Hpds/Section5";
import Section4 from "../../components/About Hpds/Section4";
import Section1 from "../../components/About Hpds/Section1";

export default function Layout() {
  return (
    <div className="flex flex-col">
      <LandingSection />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4/>
      <Section5 />
    </div>
  );
}
