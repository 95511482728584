import React from "react";
import LandingCover from "../../components/Workshop/LandingCover";
import WorkshopSection1 from "../../components/Workshop/WorkshopSection1";
import WorkshopSection3 from "../../components/Workshop/WorkshopSection3";
import WorkshopSection4 from "../../components/Workshop/WorkshopSection4";
import WorkshopSection2 from "../../components/Workshop/WorkshopSection2";

export default function Scientist() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <WorkshopSection1 />
      <WorkshopSection2 />
      <WorkshopSection3 />
      <WorkshopSection4 />
    </div>
  );
}
