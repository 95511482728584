import React from 'react';
import backgroundImage from '../../../assets/Internship/Section10imagebg.png'; // Import the background image
import icon1 from '../../../assets/Internship/imgicon1.png'
import icon2 from '../../../assets/Internship/imgicon2.png'
import icon3 from '../../../assets/Internship/imgicon3.png'

const Section11 = () => {
    return (
        <section className="bg-cover bg-center py-20" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className=" ">
                <div className="grid grid-cols-12 gap-4">
                    {/* First Card (col-span-12) */}
                    <div className="col-span-12 px-4 md:px-8 lg:px-16 xl:px-36">
                        <div className=" rounded-lg p-6">
                            <div className="flex flex-col md:flex-row items-center ">
                                <img src={icon1} alt="Image 3" className="w-16 h-16 mr-4" />

                                <div className='flex flex-col'>
                                    <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold mb-4 text-blue-500">Registered Office Address:</h2>
                                    <p className='md:pr-24 text-lg md:text-xl lg:text-2xl xl:text-3xl  '>Human Potential Development Science Infotech Pvt. Ltd​.
                                        SY:11, We works, Kondapur main road, Laxmi cyber city, Whitefields,
                                        Kondapur,  Hyderabad, Telangana 500081, India ​</p>
                                    <p className='py-4 md:pr-24 text-lg md:text-xl lg:text-2xl xl:text-3xl  '>
                                        Human Potential Development Science  LLC,​7373 e. Doubletree Ranch RD STE 135, Scottsdale, AZ 85258, usa</p>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/* Second Card (col-span-6) */}
                    <div className="col-span-12 md:col-span-6 px-4 md:px-8 lg:px-16">
                        <div className=" rounded-lg p-6">
                            <div className="flex flex-col md:flex-row items-center justify-center">
                                <img src={icon2} alt="Image 3" className="w-16 h-16 mr-4" />

                                <div className='flex flex-col'>
                                    <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl -ml-10 md:ml-0  font-bold  mb-4 text-blue-500">Contact number:</h2>
                                    <p className='text-lg md:text-xl lg:text-2xl xl:text-3xl  -ml-10 md:ml-0 '>India +91-7032694239</p>

                                </div>
                            </div>

                            {/* Add more lines of text as needed */}
                        </div>
                    </div>
                    {/* Third Card (col-span-6) */}
                    <div className="col-span-12 md:col-span-6  px-4 md:px-8 lg:px-16">
                        <div className=" rounded-lg p-6">
                            <div className="flex flex-col md:flex-row items-center justify-center ">
                                <img src={icon3} alt="Image 3" className="w-16 h-16 mr-4" />

                                <div>
                                    <h2 className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-bold   mb-4 text-blue-500">Email Address:</h2>
                                    <p className='text-lg md:text-xl lg:text-2xl xl:text-3xl '>campushire@hpdstech.com​</p>
                                    <p className='text-lg md:text-xl lg:text-2xl xl:text-3xl '>contact@hpds.us</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section11;
