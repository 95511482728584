import React from 'react';
import headingImage from '../../../assets/Carrer/section2Image.png'; // Adjust path as needed


const Section2 = () => {
  return (
    <div className='w-full bg-[#EFF2FB] p-6 lg:px-12'>
    <div className="max-w-screen-xl mx-auto">
      <div className="text-center">
        <img src={headingImage} alt="Life at HPDS" className="mx-auto" />
        <p className="mt-4 font-semibold text-xl md:text-2xl lg:text-4xl xl:text-5xl max-w-2xl mx-auto" style={{lineHeight: '1.4'}}>
          Providing Assistance and Investing in our Staff
        </p>
        <p className="mt-6 text-md md:text-lg lg:text-xl xl:text-2xl max-w-screen-lg mx-auto">
          Our staff must be able to perform at their highest level if we are to fulfill our objective of enabling everyone on the globe to accomplish more. Discover the ways that HPDS values, encourages, and supports its workforce.
        </p>
      </div>
      <div className="grid grid-cols-12 gap-6 mt-8 lg:pt-16 xl:pt-20">
        <div className="col-span-12 md:col-span-4 px-6">
            <div className='bg-gray-300 w-full h-full rounded-tr-full border border-gray-400'></div>
        </div>
        <div className="col-span-12 md:col-span-8 pr-8">
          <p className="text-lg md:text-2xl lg:text-4xl xl:text-5xl font-bold mb-2">Live Life to  <span className='text-[#0086E8]'>The Fullest</span></p>
          
          <p className="mt-4 text-sm md:text-md lg:text-lg xl:text-2xl">
            You'll take chances, push limits, and develop beyond what you ever imagined working with HPDS. Additionally, you won't travel alone. We recognize that the services we provide are vital to your life and career, and we take great pride in placing our employees at the heart of all we do.
          </p>
          <p className="mt-4 text-sm md:text-md lg:text-lg xl:text-2xl">
            We provide competitive pay, bonuses, and stock awards to qualified employees based on individual performance, along with top-notch benefits meant to support you and your family's quality of life. These are just a few of the things we offer. Other benefits include options to invest in your future, live a healthy lifestyle, and enjoy your time at HPDS. Giving you more power. You can therefore empower the planet.
          </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Section2;
