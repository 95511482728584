import React from 'react';

const BoxComponent = () => {
  const backgroundColor = '#0086E8';
  
  return (
    <div className="-mt-8 md:-mt-16 lg:-mt-32 xl:-mt-32"style={{ width: '100%', height: '40px', backgroundColor }}></div>
  );
};

export default BoxComponent;
