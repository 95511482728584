import e1 from "../../../../assets/Hpdsx/Section7_Img1.svg";
import e2 from "../../../../assets/Hpdsx/Section7_Img2.svg";
import e3 from "../../../../assets/Hpdsx/Section7_Img3.svg";

const seventhSectionData = [
  {
    id: 1,
    title: "Establish",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
    button: "Button 1",
    imageUrl: e1,
  },
  {
    id: 2,
    title: "Evaluate",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type. ",
    button: "Button 2",
    imageUrl: e2,
  },
  {
    id: 3,
    title: "Engage",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type. ",
    button: "Button 3",
    imageUrl: e3,
  },
  {
    id: 4,
    title: "Execute",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type. ",
    button: "Button 4",
    imageUrl: e1,
  },
  {
    id: 5,
    title: "Ensure Closure",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
    button: "Button 5",
    imageUrl: e2,
  },
];

export default seventhSectionData;
