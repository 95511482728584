import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import imgURL from "../../../assets/ThinkSkillIndia/img_4.jpg";

const Section3 = () => {
  const data1 = {
    item1: <>A Different set of skills for 21st century</>,
    item2: (
      <>
        POWERS THE <span className="text-blue-500">WEALTH</span> OF{" "}
        <span className="text-blue-500">INDIA</span>
      </>
    ),
    item3: <>THINKSKILLINDIA</>,
    item4: (
      <>
        To help our country make the most of our{" "}
        <span className="text-blue-500"> talent advantage </span>,
        <span className="text-blue-500"> Building </span> a more{" "}
        <span className="text-blue-500"> Inclusive Economy </span> in the
        process.
      </>
    ),
  };
  const data2 = {
    item1: (
      <>
        Your ability to act on your imagination is going to be so decisive
        in driving your future and the standard of living of your country.
        So the school, the state, the country that empowers, nurtures,
        enables imagination among its students and citizens, that’s who’s going
        to be the winner.​
      </>
    ),
    item2: (
      <>
        The best employers the world over will be looking for the
        most competent, most creative, and most innovative people on the face
        of the earth and will be willing to pay them top dollar for their
        services.​
      </>
    ),
    item3: (
      <>
        This will be true not just for top professionals and managers, but
        up and down the length and breadth of the workforce. those
        countries that produce the most important new products and services
        can capture a premium in world markets that will enable them to pay
        high wages to their citizens​
      </>
    ),
    item4: imgURL,
    item5: (
      <>
        India leads the world in several high-growth, ICT-intensive industries,
        including technology, media and telecommunications, and could dominate
        in emerging industries, such as advanced manufacturing, biotechnology,
        digital media, geospatial technology, nanotechnology, photonics and
        renewable energy. ​
      </>
    ),
  };

  const { ref, inView } = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="max-w-screen-2xl mx-auto  h-full font-extrabold px-4 py-8  xl:px-24 lg:px-16 md:px-8 xl:py-16 lg:py-10 md:py-12"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }}
    >
      <div
        className="xl:text-5xl lg:text-4xl md:text-4xl text-xl text-center"
        style={{ lineHeight: "140%" }}
      >
        {data1.item1}
      </div>
      <div
        className="xl:text-5xl lg:text-4xl md:text-4xl text-2xl text-center font-extrabold pt-2"
        style={{ lineHeight: "140%" }}
      >
        {data1.item2}
      </div>
      <div className="grid grid-cols-12 xl:mt-16 lg:mt-12 md:mt-8 mt-6 font-semibold gap-4 text-xs lg:text-sm xl:text-base xl:my-10 lg:my-6 md:my-2 my-4">
        <motion.div
          className="col-span-12 md:col-span-3 flex  flex-col gap-4 "
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: -80 },
          }}
          transition={{ duration: 0.7, ease: "easeInOut", delay: 0.3 }}
        >
          <div
            className=" h-3/5 md:h-1/2 rounded-xl border shadow-md  xl:px-4 lg:px-2 md:px-1 px-2 pb-2 "
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 134, 232, 0.4)" }}
          >
            <div className="xl:text-5xl lg:text-3xl md:text-xl text-lg px-3 py-2 text-end text-slate-300">
              01
            </div>
            {data2.item1}
          </div>
          <div
            className=" h-3/5 md:h-1/2 rounded-xl border shadow-md  xl:px-4 lg:px-2 md:px-1 px-2 pb-2 "
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 134, 232, 0.4)" }}
          >
            <div className="xl:text-5xl lg:text-3xl md:text-xl text-lg px-3 py-2 text-end text-slate-300">
              03
            </div>
            {data2.item3}
          </div>
        </motion.div>
        <div className="col-span-12 md:col-span-6 flex flex-col justify-stretch font-bold xl:text-2xl lg:text-lg md:text-md text-md gap-4 rounded-xl text-center ">
          <div className="xl:text-4xl lg:text-2xl md:text-lg text-lg">
            {data1.item3}
          </div>
          <div className="xl:px-10 lg:px-6 md:px-2">{data1.item4}</div>

          <button className="bg-blue-400 rounded-full p-3 md:p-2 w-1/2 mx-auto text-white">
            Learn more
          </button>
          <img
            src={data2.item4}
            alt="THINKSKILLINDIA"
            className="rounded-xl xl:mt-5 lg:mt-4 md:mt-1"
          />
        </div>
        <motion.div
          className="col-span-12 md:col-span-3 flex flex-col gap-4 "
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 80 },
          }}
          transition={{ duration: 0.7, ease: "easeInOut", delay: 0.3 }}
        >
          <div
            className=" h-3/5 md:h-1/2 rounded-xl border shadow-md  xl:px-4 lg:px-2 md:px-1 px-2 pb-2 "
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 134, 232, 0.4)" }}
          >
            <div className="xl:text-5xl lg:text-3xl md:text-xl px-3 py-2 text-end text-slate-300">
              02
            </div>
            {data2.item2}
          </div>
          <div
            className=" h-3/5 md:h-1/2 rounded-xl border shadow-md  xl:px-4 lg:px-2 md:px-1 px-2 pb-2 "
            style={{ boxShadow: "0px 0px 10px 0px rgba(0, 134, 232, 0.4)" }}
          >
            <div className="xl:text-5xl lg:text-3xl md:text-xl px-3 py-2 text-end text-slate-300">
              04
            </div>
            {data2.item5}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Section3;
