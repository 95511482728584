import React from 'react';
import bgimg from "../../../assets/Collaboratory/Section3imagebg.png"
import img1 from "../../../assets/Collaboratory/Section3image1.png"
import img2 from "../../../assets/Collaboratory/Section3image2.png"
import img3 from "../../../assets/Collaboratory/Section3image3.png"
import img4 from "../../../assets/Collaboratory/Section3image4.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCirclePlay, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import image from "../../../assets/TalentCollaboratory/section8image1.png"

const Section4 = () => {
    const sectionStyle = {
        backgroundImage: `url(${bgimg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    const handleInputChange = (e) => {
        console.log("Button pressed");
    };

    return (

        <section className="bg-cover text-center bg-center py-8" style={sectionStyle}>
            <div className="flex justify-center pt-8 px-4">
                <img src={img1} alt="Top Image" className="" />
            </div>
            <div className=" px-4 md:px-8 lg:px-16 xl:px-24">
                <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-4xl font-medium mt-8 text-white  md:px-12 lg:px-24 xl:px-32 text-center font-semibold" style={{ lineHeight: '140%' }}>Where Every Young Person’s <br></br>
                    Skills and Competencies is Valued and Supported</h2>
                <p className="text-lg md:text-lg lg:text-xl xl:text-2xl text-white mt-4 md:px-12 lg:px-20 xl:px-32 text-justify" style={{ lineHeight: '140%' }}>Multiple skill sets have been put forth as essential to prepare our future workforce. They range from learning, thinking, and innovation skills, such as thinking creatively and using systems thinking, to skills associated with life and careers, such as designing, evaluating, and managing one’s own work for ongoing improvement and adapting to change.</p>
                <p className="text-lg md:text-lg lg:text-xl xl:text-2xl text-white my-4 md:px-12 lg:px-20 xl:px-32 text-justify" style={{ lineHeight: '140%' }}>Employer’s perspective, everything students do during the next three to four years counts. Employers expect graduates to not only have a strong academic background but also discipline based knowledge and a range of a range of employability skills and attributes. </p>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 lg:gap-4 xl:gap-8 mt-8">
                    <div className="text-center bg-white rounded-2xl p-4">
                        <img src={img2} alt="Image 1" className="" />
                        <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-400">Academic Success
                            <br></br>Toolkit</h3>
                        <p className="text-md md:text-lg lg:text-xl text-gray-700 mt-4 text-justify font-medium">Academic skills  helps students to learn and absorb new things.  It enables individuals to be a more effective learner to perform better. Students often find it difficult to adjust to the rigors of the college curriculum, developed effective study habits and their academic performance in college, effective strategies for studying and time management often feel overwhelmed and anxious about tests and other demands.  With the help of academic skills students will master the concept and will carry out the task efficiently.  </p>
                    </div>
                    <div className="text-center bg-white rounded-2xl p-4">
                        <img src={img3} alt="Image 2" className="" />
                        <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-400">Career Success
                            <br></br>Toolkit</h3>
                        <p className="text-md md:text-lg lg:text-xl text-gray-700 mt-4 text-justify font-medium">Self-knowledge and the use of that knowledge for effective career planning exploring the world of work, how it operates and how they can fit into that world.

                            Understanding changing workplace, career decision-making and planning; job search and employment acquisition; workplace ethics and attitudes; workplace health, safety, and legal matters; employee and employer rights and responsibilities; diversity; and 21st century skills</p>
                    </div>
                    <div className="text-center bg-white rounded-2xl p-4">
                        <img src={img4} alt="Image 3" className="" />
                        <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-400">Employability Success  <br></br> Toolkit
                        </h3>
                        <p className="text-md md:text-lg lg:text-xl  text-gray-700 mt-4 text-justify font-medium">A set of achievements – skills, understandings and personal attributes – that makes graduates more likely to gain employment and be successful in their chosen occupations, which benefits themselves, the workforce, the community and the economy</p>
                    </div>
                </div>
                <div className='py-8 justify-center '>



                    <button
                        className="justify-center text-center text-white bg-blue-500 px-8 py-4 text-sm md:text-md lg:text-lg rounded-full hover:border hover:border-blue-500 hover:text-blue-500 hover:bg-transparent"
                        type="submit"
                        onClick={handleInputChange}
                    >
                        Get Started Now
                        <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                    </button>
                </div>

                <div className="text-center xl:text-md lg:text-sm text-xs  text-white">
                    Get Started In Less Than 60 Seconds • Cancel Anytime
                </div>
            </div>
        </section>
    );
};

export default Section4;
