import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import headerImg from "../../../assets/MainMenu/Section21Image.png"
import img1 from "../../../assets/MainMenu/Section21img1.png"
import img2 from "../../../assets/MainMenu/Section21img2.png"
import img3 from "../../../assets/MainMenu/Section21img3.png"
import img4 from "../../../assets/MainMenu/Section21img4.png"
import img5 from "../../../assets/MainMenu/Section21img5.png"
import img6 from "../../../assets/MainMenu/Section21img6.png"
import img7 from "../../../assets/MainMenu/Section21img7.png"
import img8 from "../../../assets/MainMenu/Section21img8.png"
import img9 from "../../../assets/MainMenu/Section21img9.png"

const Section21 = () => {
  const backgroundColor = '#0086E8';
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1 // Trigger animation when 50% of the section is in view
  });

  return (
    <motion.section
      ref={ref}
      className="w-full py-12 md:py-24"
      initial={{ opacity: 0, y: 80 }} // Initial state (not in view)
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }} // Animate when in view
      transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Transition with ease-in-out
      style={{ background: backgroundColor }}
    >
      <div className="max-w-screen">
        {/* Heading */}
        <div className="text-center mb-8 mx-4 md:px-12 lg:px-28">
          <h2 className="text-2xl md:text-5xl font-bold text-white">How does the Platform Work?</h2>
        </div>

        {/* Image */}
        <motion.div
          className="text-center mb-8 p-2 md:p-6 lg:p-8 xl:p-10"
          initial={{ opacity: 0, y: -80 }} // Initial state (not in view)
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -80 }} // Animate when in view
          transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Transition with ease-in-out
        >
          <img src={headerImg} alt="Image" className="w-full" />
        </motion.div>

        {/* Text */}
        <motion.div
          className="text-xl md:text-3xl text-white px-4 md:px-12 lg:px-28"
          initial={{ opacity: 0 }} // Initial state (not in view)
          animate={{ opacity: inView ? 1 : 0 }} // Animate when in view
          transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Transition with ease-in-out
          style={{ lineHeight: '140%' }}
        >
          As a global multi-stakeholder and solution-oriented initiative supporting students and educational institutions in addressing the practical challenges THE FUTURE OF WORK PREPARING YOUTH, the Platform will:
        </motion.div>

        {/* Grid */}
        <div className="grid grid-cols-10 gap-4 px-4 md:px-12 lg:px-20 xl:px-28 py-8 md:py-20">
          {/* First list */}
          <div className="col-span-10 md:col-span-5 px-4 md:px-2">
            <ul className="text-white list-none text-lg md:text-xl lg:text-2xl xl:text-3xl space-y-8">
              <li className='flex items-center '>
                <img src={img1} alt="Icon" className="h-16 w-16 mr-4"/>
                Support and amplify ongoing national and regional processes
              </li>
              <li className='flex items-center '>
                <img src={img2} alt="Icon" className="h-16 w-16 mr-4"/>
                Sharing progress and setbacks.
              </li>
              <li className='flex items-center '>
                <img src={img3} alt="Icon" className="h-16 w-16 mr-4"/>
                ​​​Build a global network of experts and stakeholders.
              </li>
              <li className='flex items-center '>
                <img src={img4} alt="Icon" className="h-16 w-16 mr-4"/>
                ​Host peer learning exchanges at global, regional & national levels.
              </li>
              <li className='flex items-center'>
                <img src={img5} alt="Icon" className="h-16 w-16 mr-4"/>
                Advancing together in a collaborative manner.
              </li>
            </ul>
          </div>
          {/* Second list */}
          <div className="col-span-10 md:col-span-5 px-4 md:px-2">
            <ul className="text-white list-none text-lg md:text-xl lg:text-2xl xl:text-3xl space-y-8">
              <li className='flex items-center '>
                <img src={img6} alt="Icon" className="h-16 w-16 mr-4"/>
                Identifying and discussing challenges
              </li>
              <li className='flex items-center '>
                <img src={img7} alt="Icon" className="h-16 w-16 mr-4"/>
                ​​Exchanging on positive practices and effective implementation.
              </li>
              <li className='flex items-center '>
                <img src={img8} alt="Icon" className="h-16 w-16 mr-4"/>
                ​Create capacity building and training opportunities.
              </li>
              <li className='flex items-center'>
                <img src={img9} alt="Icon" className="h-16 w-16 mr-4"/>
                Exploring opportunities for international cooperation and peer support.
              </li>
            </ul>
          </div>
        </div>

        
      </div>
    </motion.section>
  );
};

export default Section21;
