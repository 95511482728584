import React from "react";
import LandingSection from "../../components/Coach/LandingSection";
import CoachSection1 from "../../components/Coach/CoachSection1";
import CoachSection3 from "../../components/Coach/CoachSection3";
import CoachSection4 from "../../components/Coach/CoachSection4";
import CoachSection2 from "../../components/Coach/CoachSection2";

export default function Layout() {
  return (
    <div className="flex flex-col">
      <LandingSection />
      <CoachSection1 />
      <CoachSection2 />
      <CoachSection3 />
      <CoachSection4 />
    </div>
  );
}
