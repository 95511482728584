import image1 from "../../../assets/MainMenu/Section14Image1.png"
import image2 from "../../../assets/MainMenu/Section14Image2.png"
import image3 from "../../../assets/MainMenu/Section14Image3.png"
import image5 from "../../../assets/MainMenu/section14tab1.png"
const dataset = [
    {
      id: 1,
      title: "Title 1",
      heading:"Human energy is one resource that helps students regulate their behaviors and emotions to meet performance expectations",
      description: "Most of the students report feeling overworked, and pressured, to the point of anxiety, and depression. Actually, the problem is also getting worse due to the severe competition and rapid technological changes.",
      rightimage :image1,
      otherimage : image5,
      content: "Like a battery, human energy can be depleted over time if not regularly guided with a well-structured human guidance system. The depletion of human energy is actually a type of energy crisis that must not be ignored at this juncture of 21st Century.​"
    },
    {
      id: 2,
      title: "Title 2",
      heading:"THINKSKILLINDIA is trying to enable personal transformation which is the individual process of expanding consciousness.",
      description: "During personal transformation, you will become more critically aware of your old and new views and can integrate them into a new self-definition.​",
      rightimage :image2,
    },
    {
      id: 3,
      title: "Title 3",
      heading:"We all know Intellectual individuals who work through difficult time with a smile",
      description: "These people are usually motivated and as an important factor in their success is their energy. This energy is not just their PHYSICAL STRENGTH OR STAMINA, OR THEIR MENTAL POWER, but a much more elusive characteristic that drives motivation and gives a nation or institution or family competitive edge.​",
      rightimage :image3
    },
    
  ];
  
  export default dataset;