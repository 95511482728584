import React from 'react';
import bgimage from "../../../assets/Council/Section2imagebg.png"
import Section2img1 from "../../../assets/Council/Section3image1.png"
import Section2img2 from "../../../assets/Council/Section3image2.png"
import Section2img3 from "../../../assets/Council/Section3image3.png"

const Section2 = () => {
  
  const cards = [
    { id: 1, title: 'Card 1', description: 'Description for Card 1', image: Section2img1 },
    { id: 2, title: 'Card 2', description: 'Description for Card 2', image: Section2img2 },
    { id: 3, title: 'Card 3', description: 'Description for Card 3', image:  Section2img3},
    { id: 4, title: 'Card 4', description: 'Description for Card 4', image:  Section2img1},
    { id: 5, title: 'Card 5', description: 'Description for Card 5', image: Section2img2 },
    { id: 6, title: 'Card 6', description: 'Description for Card 6', image:  Section2img3}
  ];

  return (
    <section className="relative bg-cover bg-center py-12 xl:py-24" style={{ backgroundImage: `url(${bgimage})` }}>
      <div className="px-2 md:px-12 lg:px-20 xl:px-32 ">
        <h2 className="text-3xl xl:text-5xl  text-white font-bold mb-8 text-center px-4 xl:px-24" style={{ lineHeight: '140%' }}><span className='text-yellow-400' >HPDS Offer Councils</span> Various mode of Engagements</h2>
        <div className="grid grid-cols-12 gap-4">
          {cards.map(card => (
            <div key={card.id} className="col-span-12 md:col-span-4">
              <div className=" p-4 rounded-lg shadow-md">
                <img src={card.image} alt={card.title} className="w-full h-full object-cover rounded-md mb-4" />
                
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section2;
