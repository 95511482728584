import React from 'react'
import Btn_S1 from '../../assets/create_program_hero_img.png'
import S1 from '../../assets/create_program_s1.png'
import S2 from '../../assets/create_program_s2.png'
import S3 from '../../assets/create_program_s3.png'
import sm_img1 from '../../assets/create_program_smImg1.png'
import sm_img2 from '../../assets/create_program_smImg2.png'
import sm_img3 from '../../assets/create_program_smImg3.png'
import sm_img4 from '../../assets/create_program_smImg4.png'
import SlideWithImage from "../components/Templates/SlideWithImage"
import HeroSection from "../components/CreateProgram/HeroSection"
import FlexSlide from '../components/StartChapter/FlexSlide'

const CreateProgram = () => {
  const heroSectionInfo = {
    img:Btn_S1,
    title: 'Design your Comprehensive Programs with ease',
    subTit: <div className="font-normal lg:text-base md:text-xs">Customize your curriculum to suit your needs. Design lessons and incorporate diverse materials to create interactive courses. Introduce assignments to assess your students' analytical abilities.</div>,
    btn: {txt: 'Create your Program'}
  };

  const slideInfo1={
    img:S1,
    title: <div className=''>Inviting Peers to Co-Create Programs</div>,
    subTit: <div className="font-normal text-base">Engage in collaborative efforts with your peers to jointly create programs. Explore the existing instructor community to discover potential co-hosts.</div>,
  };

  const slideInfo2={
    img:S2,
    title: "Invite your Institute to host the program and assign multiple instructors as per program's curriculum",
  };

  const slideInfo3={
    img:S3,
    title: 'Engage and Evaluate learner',
    subTit: <div className="font-normal  text-base">Track yardsticks such as the amount of time spent on a chapter, test scores, assignment submission status, etc. You can also collect learners feedback to refine and modify your teaching methodologies.</div>,

  };

  const slideInfo4={    
    title: <div className=''>Hosting your <strong >Program </strong>is as simple as ever</div>, 
    data: [
      {img: sm_img1, title: <div className='text-base text-black'>Plan your location</div>},
      {img: sm_img2, title: <div className='text-base text-black'>Invite instruction</div>},
      {img: sm_img3, title: <div className='text-base text-black'>Create the program content</div>},
      {img: sm_img4, title: <div className='text-base text-black'>Host your program</div>},
    ]
  };

  return (
    <div>
      <HeroSection slideInfo={heroSectionInfo} contStyle ={`flex-row-reverse create_program_hero_bg text-white`}/>
      <SlideWithImage slideInfo={slideInfo1} contStyle ={`bg-white  md:py-20 py-7 `}/>
      <SlideWithImage slideInfo={slideInfo2} contStyle ={`  md:py-20 py-7 text-white create_program_bg1 flex-row-reverse`}/>
      <SlideWithImage slideInfo={slideInfo3} contStyle ={`bg-white  md:py-20 py-7 `}/>
      <FlexSlide slideInfo={slideInfo4} contStyle ={`create_program_bg2 w-fullz`}/>
    </div>
  )
}

export default CreateProgram