import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function Section6() {
  const [formData, setFormData] = useState({
    organizationName: "",
    organizationType: "",
    organizationWebsite: "",
    organizationContact: "",
    personPosition: "",
    contactEmail: "",
    fistAndLastName: "",
    position: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });
  const [isChecked, setIsChecked] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = (e) => {
    if (!isChecked) {
      console.log("Please accept terms and conditions ");
      return;
    }
    e.preventDefault();

    // logic for sending the data to server
    console.log("Form submitted:", formData);

    // Reset the form data
    setFormData({
      organizationName: "",
      organizationType: "",
      organizationWebsite: "",
      organizationContact: "",
      personPosition: "",
      contactEmail: "",
      fistAndLastName: "",
      position: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
    });
    setIsChecked(false);
    e.target.reset();
  };
  return (
    <div className=" py-12 xl:py-20 px-4 mx-auto w-full md:w-5/6 xl:w-4/6 border rounded-t-xl md:rounded-l-xl my-10 bg-violet-100">
      <form onSubmit={handleSubmit}>
        <div className="w-full px-6 py-6 md:py-6 lg:py-4 xl:py-8 flex-col justify-start items-start gap-8 md:gap-4   xl:gap-6 inline-flex ">
          <div className="flex-col w-full md:flex-row  justify-between items-start  gap-3 xl:gap-7  inline-flex">
            <div className=" w-full md:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1">
                Organization Name
              </div>
              <input
                className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                placeholder="Instituation Name "
                type="text"
                name="organizationName"
                value={formData.organizationName}
                onChange={handleInputChange}
              />
            </div>
            <div className=" w-full md:w-1/2 h-auto text-sm flex-col justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1  ">
                Organization Type
              </div>
              <div className="relative">
                <select
                  className="pl-2 lg:pl-4 pr-8 py-3 w-full shadow-md rounded-full text-gray-400 appearance-none"
                  name="organizationType"
                  value={formData.organizationType}
                  onChange={handleInputChange}
                >
                  <option value="" disabled selected>
                    Business email address only
                  </option>
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </select>
                <div className="absolute inset-y-0 right-5 flex items-center pr-2 pointer-events-none">
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="text-slate-950"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex-col w-full md:flex-row  justify-between items-start  gap-3 xl:gap-7  inline-flex">
            <div className=" w-full md:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1">
                Organization Website
              </div>
              <input
                className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                placeholder="Website url"
                type="text"
                name="organizationWebsite"
                value={formData.organizationWebsite}
                onChange={handleInputChange}
              />
            </div>
            <div className=" w-full md:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1">
                Organization Contact
              </div>
              <input
                className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                placeholder="(+91) 123 123 1234"
                type="text"
                name="organizationContact"
                value={formData.organizationContact}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex-col w-full md:flex-row  justify-between items-start  gap-3 xl:gap-7  inline-flex">
            <div className=" w-full md:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1">
                Contact Person Postion
              </div>
              <input
                className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                placeholder="Ceo,Manager"
                type="text"
                name="personPosition"
                value={formData.personPosition}
                onChange={handleInputChange}
              />
            </div>
            <div className=" w-full md:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1">
                Contact Email
              </div>
              <input
                className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                placeholder="xyz12@gmail.com"
                type="text"
                name="contactEmail"
                value={formData.contactEmail}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex-col w-full md:flex-row  justify-between items-start  gap-3 xl:gap-7  inline-flex">
            <div className=" w-full md:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1">Full Name</div>
              <input
                className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                placeholder="First, Last name"
                type="text"
                name="fistAndLastName"
                value={formData.fistAndLastName}
                onChange={handleInputChange}
              />
            </div>
            <div className=" w-full md:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1">
                Position/Title
              </div>
              <input
                className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                placeholder="xyz12@gmail.com"
                type="text"
                name="position"
                value={formData.position}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex-col w-full md:flex-row  justify-between items-start  gap-3 xl:gap-7  inline-flex">
            <div className=" w-full md:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1">City</div>
              <input
                className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                placeholder="You text here"
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              />
            </div>
            <div className=" w-full md:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1">State</div>
              <input
                className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                placeholder="You text here"
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="flex-col w-full md:flex-row  justify-between items-start  gap-3 xl:gap-7  inline-flex">
            <div className=" w-full md:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1">
                Zip/Postal code
              </div>
              <input
                className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                placeholder="754535"
                type="text"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleInputChange}
              />
            </div>
            <div className=" w-full md:w-1/2 h-auto text-sm flex-col justify-evenly items-stretch inline-flex">
              <div className="text-slate-900 font-semibold my-1  ">Country</div>
              <div className="relative">
                <select
                  className="pl-2 lg:pl-4 pr-8 py-3 w-full shadow-md rounded-full text-gray-400 appearance-none"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                >
                  <option value="" disabled selected>
                    You text here
                  </option>
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </select>
                <div className="absolute inset-y-0 right-5 flex items-center pr-2 pointer-events-none">
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="text-slate-950"
                  />
                </div>
              </div>
            </div>
          </div>
          <label htmlFor="termsCheckbox" className="items-start md:items-center">
            <input
              type="checkbox"
              id="termsCheckbox"
              className="form-checkbox h-4 w-4 border-1 border-indigo-500 mx-2"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <span className="md:ml-2 text-xs md:text-sm w-2/5  md:w-full" >
              I agree to all the terms and conditions of the platform
            </span>
          </label>

          <button
            type="submit"
            onClick={handleInputChange}
            className="text-black px-6 py-3 text-xl rounded-2xl font-semibold border-2 border-black border-b-4 hover:bg-neutral-100 hover:shadow-md"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

// <div className="flex-col xl:flex-row flex justify-between items-start gap-6 xl:gap-3 inline-flex">
//             <div className="h-8 md:h-10 lg:h-12 xl:h-20 flex-col justify-start items-start gap-2 inline-flex">
//               <div className="text-slate-900 font-semibold text-xs lg:text-sm">
//                 First Name
//               </div>
//               <input
//                 className="h-6 md:h-8 lg:h-10 xl:h-12 pl-2 lg:pl-4 pr-0 md:pr-20 lg:pr-44 xl:pr-11 py-3 rounded border border-gray-400 justify-start items-center placeholder:text-gray-400 text-base inline-flex"
//                 placeholder="Enter your first name"
//                 type="text"
//                 name="fistName"
//               />
//             </div>
//             <div className="h-8 md:h-10 lg:h-12 xl:h-20 flex-col justify-start items-start gap-2 inline-flex">
//               <div className="text-slate-900 text-xs lg:text-sm font-semibold">
//                 Last Name
//               </div>
//               <input
//                 className="h-6 md:h-8 lg:h-10 xl:h-12 pl-2 lg:pl-4 pr-0 md:pr-20 lg:pr-44 xl:pr-11 py-3 rounded border border-gray-400 justify-start items-center placeholder:text-gray-400 text-base inline-flex"
//                 placeholder="Enter your last name"
//                 type="text"
//                 name="lastName"
//               />
//             </div>
//           </div>
