import React, { useState } from 'react';
import img1 from "../../../assets/Internship/Section7image1.png"
import img2 from "../../../assets/Internship/Section7image2.png"
import img3 from "../../../assets/Internship/Section7image3.png"

const Section7 = () => {
  


  // Function to handle button click and show corresponding image
  const handleButtonClick = (image) => {
    setSelectedImage(image);
  };

  // Sample data for cards
  const cardsData = [
    { id: 1, image: img1, description: 'We hope after your summer with us that you will come away with a selection of skills that will give you an amazing start to your career including ' },
    { id: 2, image: img2, description: 'EMOTIONAL COMPETENCE, including working in diverse teams and building relationships to help you succeed' },
    { id: 3, image: img3, description: 'EMPLOYABILITY COMPETENCE,Including presentation and communication skills, time management, business acumen, corporate and personal responsibility' },
  ];

  const [selectedImage, setSelectedImage] = useState(cardsData[0].image);

  return (
    <section className="w-full -mt-20 md:mt-0 py-12 bg-blue-500">
      <div className="">
        {/* Heading */}
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-center text-white mb-4 md:mb-8 px-4 md:px-12 lg:px-16 xl:px-24 py-4">What will you learn</h2>
        
        {/* Grid */}
        <div className="grid grid-cols-12 gap-8 px-2 md:px-12 lg:px-16 xl:px-24">
          {/* Left section (col-span-6) */}
          <div className="col-span-12 md:col-span-6 mr-16 md:mr-0">
            {selectedImage && (
              <img src={selectedImage} alt="Selected Image" className="w-full h-auto rounded-lg shadow" />
            )}
          </div>
          
          {/* Right section (col-span-6) */}
          <div className="col-span-12 md:col-span-6 mr-16 md:mr-0">
            {cardsData.map((card, index) => (
              <div key={card.id} className="flex flex-row items-center justify-between  p-2 rounded-lg mb-4">
                <button
                 className={`px-4 py-2 rounded-full mx-4 ${
                    selectedImage === card.image
                      ? 'text-white bg-green-500'
                      : 'text-black bg-white border border-gray-300'
                  }`}
                  onClick ={() => handleButtonClick(card.image)}
                >
                  {`0${index + 1}`}
                </button>
                <p className="text-md md:text-lg lg:text-xl xl:text-2xl text-white text-justify">{card.description}</p>
                
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section7;
