import React from "react";
import { Link } from "react-router-dom/dist";
import FootContent from "./FootContent";

const Footer = () => {
  return (
    <div className="w-full  bg-neutral-100 bottom-0 text-xs">
      <div className="w-full p-10 flex justify-center items-center bg-[#0086E8] flex-col">
        <div className="flex-col justify-start items-center gap-4 inline-flex">
          <div className="text-center  text-xl xl:text-4xl lg:text-3xl md:text-2xl  font-semibold text-yellow-300">
            Looking for a specific answer or to talk to someone?
          </div>
          <div className=" text-center  text-xs md:text-sm lg:text-md xl:text-lg font-semibold text-white">
            Let us know, Our team of experts is on hand to answer your
            questions.
          </div>
        </div>
        <Link to={"/contact"} className="no-underline ">
          <button className="bg-yellow-400 hover:bg-yellow-500 text-white  text-md md:text-md lg:text-lg font-semibold px-4 py-2 md:px-6 md:py-2 rounded-2xl md:my-4 lg:my-6 ">
            Contact Us
          </button>
        </Link>
      </div>
      <div className="flex flex-col gap-3 p-3">
        <FootContent />
        <div className="flex justify-between w-full px-12">
          <div className="flex flex-wrap gap-3 font-medium">
            <Link>Home</Link>
            <Link>Sitemap</Link>
            <Link>Contact</Link>
            <Link>Privacy</Link>
            <Link>Terms & Condition</Link>
            <Link>Feedback</Link>
          </div>
          <div className="text-lg font-semibold ">@HPDS</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
