import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import bgImage from "../../../assets/Collaboratory/sec5_img1.jpg";
import arrow from "../../../assets/Collaboratory/sec6_arrow.png";

const withHPDSx = [
  <>The “Whole-Child” approach to mental health</>,
  <>The “Whole-Person” approach</>,
  <>Placing a focus on self-care and awareness</>,
  <>Awareness and support into everyday routines</>,
  <>Overall understanding of your mental health</>,
  <>New assessment and treatment approaches</>,
  <>Urgent and critical for the well-being</>,
  <>Access to appropriate supports when and where they need them</>,
  <>Supports wherever you live</>,
  <>Digital solutions for prevention and management</>,
  <>Analytics and educational content</>,
  <>Tools to identify individual and cohort-related trends</>,
  <>Select appropriate programs</>,
  <>Facilitate communication about student development</>,
  <>Self-help resources, guided therapy sessions</>,
  <>Video conferencing and text-based solutions</>,
  <>N-Demand virtual access to licensed therapists</>,
  <>Providers trained in crisis counseling</>,
];

const woHPDSx = [
  <>Feeling overwhelmed by academic workload</>,
  <>Lack of motivation to study or attend classes</>,
  <>Social withdrawal and isolation from peers</>,
  <>Difficulty concentrating in class</>,
  <>Low self-esteem and negative self-talk</>,
  <>Strained relationships with family members</>,
  <>Imposter syndrome and feeling inadequate</>,
  <>Performance anxiety in extracurricular activities</>,
  <>Balancing personal life with academic demands.</>,
];

const data1 = (
  <>
    Do You Have Challenges in building workforce talent, Streamlining Customer
    Experience and{" "}
    <span className="text-blue-500">Smooth Integration of AI </span>
    Solutions?{" "}
  </>
);

const data2 = (
  <>
    <span className="text-blue-400"> Creating Impactful</span> and{" "}
    <span className="text-blue-400"> Recreational Working Environment</span> for
    your people and organisation saving you time and hassle.{" "}
  </>
);
const item3 = <>Get Started In Less Than 60 Seconds • Cancel Anytime</>;
const Section5 = () => {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };
  return (
    <div className="w-full  flex flex-col xl:px-24 lg:px-20 md:px-16 px-4 justify-center items-center py-10 md:py-16 lg:py-24">
      <div className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-bold   py-8 text-center">
        So, Why <span className="text-blue-400 ">UPMYMIND HPDSx Engine?</span>
      </div>

      <div className="flex flex-col xl:px-28 lg:px-16 md:px-12 px-4 text-md md:text-lg lg:text-xl xl:text-2xl mb-8 text-center">
        UPMYMIND platform is all about bringing everyone together to share and
        exchange what we are living, and learning.
      </div>
      <div
        className="h-4/5 flex justify-between items-center text-white rounded-2xl"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* Left Section */}
        <div className="grid-cols-1 md:grid-cols-2 flex flex-col md:flex-row">
          <div className="w-full  h-full font-semibold xl:px-24 lg:px-16 md:px-8 px-2 flex flex-col items-center xl:py-24 lg:py-20 md:py-16 py-2">
            <h2 className="xl:text-4xl lg:text-3xl md:text-2xl text-xl xl:mb-12 lg:mb-10 md:mb-8 mb-4 pt-16">
              With HPDSx
            </h2>
            <ul>
              {withHPDSx.map((list) => (
                <li className="flex items-center xl:text-2xl lg:text-xl md:text-lg text-base xl:mb-8 lg:mb-6 md:mb-4 mb-2 justify-start">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-white bg-green-500 rounded-full p-1 xl:mr-5 lg:mr-4 md:mr-3 mr-2"
                  />
                  <span>{list}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="w-full   h-full font-semibold xl:px-24 lg:px-16 md:px-8 px-2 flex flex-col items-center xl:py-24 lg:py-20 md:py-16 py-4">
            <h2 className="xl:text-4xl lg:text-3xl md:text-2xl text-xl xl:mb-12 lg:mb-10 md:mb-8 mb-6 pt-16 ">
              Without HPDSx
            </h2>
            <ul>
              {woHPDSx.map((list) => (
                <li className="flex items-center xl:text-2xl lg:text-xl md:text-lg text-base xl:mb-8 lg:mb-6 md:mb-4 mb-2 justify-start">
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-white bg-red-500 rounded-full p-[5px] xl:mr-5 lg:mr-4 md:mr-3 mr-2"
                  />
                  <span>{list}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div
        className="py-6 text-lg md:text-xl lg:text-2xl xl:text-3xl text-center px-4 md:px-8 lg:px-16 xl:px-24"
        style={{ lineHeight: "140%" }}
      >
        We are providing a state-of-the-art informatics platform that serves as
        a foundation to improve mental health for, and importantly, with
        individuals backed by machine learning data from across country.
      </div>

      <div className="xl:mt-8 lg:mt-6 md:mt-4 mt-2 flex flex-col justify-center items-center">
        <div className="xl:py-4 lg:py-3 py-2 font-semibold">
          <button
            className="text-white bg-blue-500 px-6 py-3  xl:text-lg lg:text-base md:text-sm text-xs rounded-full hover:border hover:border-blue-500 hover:text-blue-500 hover:bg-transparent"
            type="submit"
            onClick={handleInputChange}
          >
            Get Started Now
            <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
          </button>
        </div>
        <div className="text-xs text-center">{item3}</div>
      </div>
      {/* Background Image */}
    </div>
  );
};

export default Section5;
