import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import backgroundImage from '../../../assets/ChangeAgent/Section3imagebg.png';
import ImageToImport from '../../../assets/ChangeAgent/Section3image1.png';

const Section3 = () => {
    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView) {
            // Start animation when the section is in view
            // You can customize this animation as needed
            console.log('Section 3 is in view!');
        }
    }, [inView]);

    return (
        <motion.section
            className="bg-cover md:bg-cover h-[80vh] md:h-[90vh]"
            style={{ backgroundImage: `url(${backgroundImage}) ` }}
            ref={ref}
            initial={{ opacity: 0, y: 50, scale: 0.9 }} // Initial scale
            animate={inView ? { opacity: 1, y: 0, scale: 1 } : { opacity: 0, y: 50, scale: 0.9 }} // Target scale
            transition={{ ease: 'easeInOut', duration: 1.2 }} // Customize transition as needed
        >
            <div className="py-12">
                <div className="grid grid-cols-12 md:mx-12 lg:mx-20 xl:mx-40 gap-2 md:pt-12 lg:pt-0">
                    <div className="col-span-12 md:col-span-5 lg:col-span-6 flex items-center justify-center">
                        <div>
                            <h2 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-black md:mb-0 lg:mb-4 px-8 md:px-0" style={{ lineHeight: '140%' }}>Discover you Profession,
                                Forge your Path </h2>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-7 lg:col-span-6 flex items-center justify-center">
                        <motion.img // Wrap image with motion.img to apply animation to the image
                            src={ImageToImport}
                            alt="Right Image"
                            className="w-full"
                            initial={{ scale: 0.9 }} // Initial scale
                            animate={inView ? { scale: 1 } : { scale: 0.8 }} // Target scale
                            transition={{ ease: 'easeInOut', duration: 1.2 }} // Customize transition as needed
                        />
                    </div>
                </div>
            </div>
        </motion.section>
    );
};

export default Section3;
