import React from "react";
import HeroTemplate from "../Templates/HeroTemplate";
import smmain from "../../../assets/HomePage/Institution/imain.webp";

const Section1 = () => {
  const data = {
    title: "Empower your students and teachers to achieve more",
    description: (
      <>
        Teachers and students get access to the best tools for learning anywher
        in the
        <br />
        classroom, remotely or in a blended environment for free
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <HeroTemplate
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section1;
