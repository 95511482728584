import WritingImg from "../../../../assets/Blogs/image 53.jpg";
import KnowledgeImg from "../../../../assets/Blogs/image 54.jpg";
import ThinkingImg from "../../../../assets/Blogs/image 55.jpg";

const blogSecondData = [
  {
    id: 1,
    title: "Enhanced Writing Skills",
    description:
      "Blogging regularly helps improve writing skills, grammar, and overall communication abilities.",
    shadow: false,
    topmargin: false,
    imageUrl: WritingImg,
    alt: "Knowledge",
    button: true,
    buttonName: "Start Now",
  },
  {
    id: 2,
    title: "Knowledge Retention",
    description:
      "Writing about what you learn reinforces your understanding and retention of information.",
    shadow: true,
    topmargin: true,
    imageUrl: KnowledgeImg,
    alt: "Knowledge Retention",
    button: true,
    buttonName: "Start Now",
  },
  {
    id: 3,
    title: "Critical Thinking",
    description:
      "It encourages critical thinking and analysis while forming opinions and arguments on various topics.",
    shadow: false,
    topmargin: false,
    imageUrl: ThinkingImg,
    alt: "Critical Thinking",
    button: true,
    buttonName: "Start Now",
  },
];

export default blogSecondData;
  