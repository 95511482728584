import React from "react";
import Section1 from "../../components/HPDSxMenu/Section1";
import Section2 from "../../components/HPDSxMenu/Section2";
import Section3 from "../../components/HPDSxMenu/Section3";
import Section4 from "../../components/HPDSxMenu/Section4";
import Section5 from "../../components/HPDSxMenu/Section5";
import Section6 from "../../components/HPDSxMenu/Section6";
import Section7 from "../../components/HPDSxMenu/Section7";
import Section8 from "../../components/HPDSxMenu/Section8";
import Section9 from "../../components/HPDSxMenu/Section9";
import Section10 from "../../components/HPDSxMenu/Section10";
import Section11 from "../../components/HPDSxMenu/Section11";
import Section12 from "../../components/HPDSxMenu/Section12";
import Section13 from "../../components/HPDSxMenu/Section13";
import Section14 from "../../components/HPDSxMenu/Section14";
import Section15 from "../../components/HPDSxMenu/Section15";
import Section16 from "../../components/HPDSxMenu/Section16";
import Sectiontrial from "../../components/HPDSxMenu/Sectiontrial";

export const HPDSxMenu = () => {
  return (
    <div className="flex flex-col font-poppins">
      <Section1 />
      <Section2 />
      <Section16 />
      <Section3 />
      <Section4 />
      <Section15 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Section11 />
      <Section12 />
      <Section13 />
      <Section14 />
      {/* <Sectiontrial/>  */}
    </div>
  );
};
