import React from "react";
import HeroTemplate from "../Templates/HeroTemplate";
import smmain from "../../../assets/HomePage/Sme/smmain.webp";

const Section1 = () => {
  const data = {
    title: "Personal Growth hobbies to help you excel in Life",
    description:
      "Our Global Experts help you build growth mindsets, dream big and go after your dreams.",
    imageUrl: smmain,
  };

  return (
    <div>
      <HeroTemplate
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section1;
