import Section2image1 from '../../../assets/HPDSxMenu/Section2image1.png';
import Section2image2 from '../../../assets/HPDSxMenu/Section2image2.png';
import Section2image3 from '../../../assets/HPDSxMenu/Section2image3.png';



const DatasetSec2 = [
    {
      id: 1,
      title: <>Founding <span className='' style={{ color: 'rgb(0, 134, 232)' }}>Philosophy</span></>,
      description: <>Enable a <span className='font-bold'>great life</span> with <span className='font-bold'>greater opportunities</span> for the people in 21st Century.</>,
      image: Section2image1
    },
    {
      id: 2,
      title: <><span className='font-bold'>Value</span></>,
      description: <>Awakening their <span className='font-bold'>geometric principles</span> with a <span className='font-bold'>positive belief system</span> realizing <span className='font-bold'>true potential</span> always, saying yes filling lives with hymns of action.</>,
      image: Section2image2
    },
    {
      id: 3,
      title: <>Guiding <span className='' style={{ color: 'rgb(0, 134, 232)' }}>Principle</span></>,
      description: "Nurturing core genius, creative will and conscious mind.",
      image: Section2image3
    }
  ];
  
  export default DatasetSec2;
  