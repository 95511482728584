import React from "react";
import bgImg from "../../../../assets/Learn&Grow/Learn&GrowCoverBgImg.png";
import SideCoverImg from "../../../../assets/Learn&Grow/SideCoverImg.png";

export default function Cover() {
  const bgImageStyle = {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <div className="h-full" style={bgImageStyle}>
      <div className="flex flex-col md:flex-row max-w-screen-xl mx-auto ">

      {/* Left Content */}
      <div className="order-2 md:order-1 flex flex-1 flex-col justify-center">
        <div className="text-white font-bold font-Segoe UI xl:ml-28 lg:ml-24 md:ml-16 text-3xl mx-4 my-14 lg:text-3xl xl:text-4xl md:text-2xl xl:my-16 lg:my-16 md:my-8 cursor-pointer">
          <div className="mb-2">
            6 Ways{" "}
            <span className="text-blue-600">HPDS</span>
            <span className="ml-2">
              can
              <br />
              build your skills
            </span>
          </div>
        </div>
      </div>

      {/* Right Content */}
      <div className="order-1 md:order-2 flex flex-1 justify-end items-right xl:my-28 md:my-20 lg:my-28 xl:mr-28 lg:mr-24 md:mr-16">
        <div className="w-full h-full xl:w-full xl:h-full lg:w-full lg:h-full md:w-96 md:h-56 cursor-pointer">
          <img
            src={SideCoverImg}
            alt="blank"
            className="w-full h-full object-cover md:object-contain rounded-none md:rounded-lg"
          />
        </div>
      </div>
      </div>
    </div>
  );
}

