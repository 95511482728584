import CardImage1 from "../../../assets/HPDSxMenu/Section3image3.png";
import CardImage2 from "../../../assets/HPDSxMenu/Section3image2.png";
import CardImage3 from "../../../assets/HPDSxMenu/Section3image1.png";
import Section3image4 from "../../../assets/HPDSxMenu/Section3image4.png";

const DatasetSec3 = {
  leftSection: {
    title: (
      <>
        <span className="font-bold">ENVISIONING TOMORROW :</span>
      </>
    ),
    subTitle: (
      <>
        The HPDS{" "}
        <span className="" style={{ color: "rgb(0, 134, 232)" }}>
          Future
        </span>{" "}
        of Being{" "}
        <span className="" style={{ color: "rgb(0, 134, 232)" }}>
          Human Community
        </span>
      </>
    ),
    description:
      "The HPDS Future of Being Human community is committed to activating, growing, and convening a unique multi-sector community of ",
    cards: [
      { id: 1, image: CardImage1, title: "Visionaries" },
      { id: 2, image: CardImage2, title: "Thought leaders" },
      { id: 3, image: CardImage3, title: "Future Builders" },
    ],
  },
  rightSection: {
    backgroundImage: Section3image4,
    additionalDescription:
      "who are inspired by what it might mean to be human a hundred years from now, and how this in turn catalyzes our ideas and actions in the present.",
  },
};

export default DatasetSec3;
