import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import imgURL_1 from "../../../assets/ThinkSkillIndia/img_12.jpg";
import imgURL_2 from "../../../assets/ThinkSkillIndia/img_13.jpg";
import imgURL_3 from "../../../assets/ThinkSkillIndia/img_14.jpg";
import icon_1 from "../../../assets/ThinkSkillIndia/icon_1.png";
import icon_2 from "../../../assets/ThinkSkillIndia/icon_2.png";
import icon_3 from "../../../assets/ThinkSkillIndia/icon_3.png";
import icon_4 from "../../../assets/ThinkSkillIndia/icon_4.png";

const Section12 = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });
  const data1 = (
    <>
      Sets a national vision and plan for learning enabled by technology through
      building on the work of leading education researchers; school,
      universities and higher education leaders; teachers; developers;
      entrepreneurs; and non-profit organizations. We can finally bridge a gap
      between the exponential growth of technology and our own inner development
      and growth.
    </>
  );

  const data2 = {
    img1: imgURL_1,
    img2: imgURL_2,
    img3: imgURL_3,
    item1: {
      icon: icon_1,
      heading: <>Accessibility</>,
      desc: (
        <>
          Accessibility to various self-learning resources outside of
          college/campus classroom with the interaction of facilitators with
          expertise through the TSIO Applications.
        </>
      ),
    },
    item2: {
      icon: icon_2,
      heading: <>Promotion and Empowerment</>,
      desc: (
        <>
          Promotion and Empowerment of leadership, entrepreneurship and
          fostering knowledge and skills on various social and economic
          crosscutting issues in their respective societies
        </>
      ),
    },
    item3: {
      icon: icon_3,
      heading: <>Providing Training & Capacity building</>,
      desc: (
        <>
          Providing training and capacity building on different problematic
          economic and social issues thorough conferences, webinars etc.
        </>
      ),
    },
    item4: {
      icon: icon_4,
      heading: <>Recognition and empower</>,
      desc: (
        <>
          Recognition and empower various talents of Indian university students
          through various Platform competitions and clubs.
        </>
      ),
    },
    item5: {
      icon: icon_1,
      heading: <>Being ambassadors</>,
      desc: (
        <>
          Being ambassadors of major global goals and creating awareness
          including Climate change, Corruption, Peace, Women Empowerment,
          science & technology, good governance etc.
        </>
      ),
    },
    item6: {
      icon: icon_2,
      heading: <>Brand creation</>,
      desc: (
        <>
          Creation of brand that advances on providing solutions for the
          creation of employment, advocacy of science and technology in India
          equal opportunity in accessing national interests, mentoring
          university students to be exemplary role models etc
        </>
      ),
    },
    item7: {
      icon: icon_3,
      heading: <>Encouragement</>,
      desc: (
        <>
          Encouragement of sports, art and cultural exchange through various
          games, exhibitions and other re creative activities.
        </>
      ),
    },
    item8: {
      icon: icon_4,
      heading: <>Creation and Availability</>,
      desc: (
        <>
          Enhance the creation and availability of educational and multinational
          opportunities that increase cooperation and interaction.
        </>
      ),
    },
    item9: {
      icon: icon_1,
      heading: <>Installment of Indian spirit and togetherness </>,
      desc: (
        <>
          Installment of Indian spirit and togetherness on creating an image of
          how India can bloom through its esteemed youths
        </>
      ),
    },
  };
  return (
    <motion.div
      className="w-full h-full xl:px-24 lg:px-16 md:px-8 px-4 xl:py-20 lg:py-12 md:py-8 py-8 bg-yellow-300"
      ref={ref}
      initial={{ opacity: 0, y: 60 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
      transition={{ duration: 0.6, ease: "easeIn", delay: 0.2 }}
    >
      <div
        className="xl:text-xl lg:text-lg md:text-base font-semibold"
        style={{ lineHeight: "140%" }}
      >
        {data1}
      </div>
      <div className={`w-full xl:text-lg lg:text-base md:text-sm `}>
        <div className=" w-full h-full grid grid-cols-2 md:grid-cols-5 gap-x-2 xl:pt-12 lg:pt-10 md:pt-8  pt-4">
          <img
            className="col-span-2 w-full h-full  rounded-2xl"
            src={
              "https://images.pexels.com/photos/6936400/pexels-photo-6936400.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            }
          />
          <div className="col-span-1 flex flex-col xl:text-base lg:text-sm md:text-xs gap-2 bg-white  p-2 rounded-2xl">
            <img
              className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-6 h-6"
              src={data2.item1.icon}
            />
            <div className="font-semibold">{data2.item1.heading}</div>
            <div>{data2.item1.desc}</div>
          </div>
          <div className="col-span-1  md:col-span-1 flex flex-col text-sm xl:text-base lg:text-sm md:text-xs gap-4 md:gap-2 bg-white  p-2 rounded-2xl">
            <img
              className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-6 h-6"
              src={data2.item2.icon}
            />
            <div className="font-semibold">{data2.item2.heading}</div>
            <div>{data2.item2.desc}</div>
          </div>
          <div className="col-span-2 md:col-span-1 flex flex-col text-sm xl:text-base  lg:text-sm md:text-xs gap-4 md:gap-2 bg-white  p-2 rounded-2xl mt-2 md:mt-0 ">
            <img
              className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-6 h-6"
              src={data2.item3.icon}
            />
            <div className="font-semibold">{data2.item3.heading}</div>
            <div>{data2.item3.desc}</div>
          </div>
        </div>
        <div className=" w-full h-full grid grid-cols-2 md:grid-cols-5 gap-x-2 pt-2 ">
          <div className="col-span-2 md:col-span-1 flex flex-col text-sm xl:text-base lg:text-sm md:text-xs gap-4 md:gap-2 bg-white  p-2 rounded-2xl mt-2 md:mt-0">
            <img
              className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-6 h-6"
              src={data2.item4.icon}
            />
            <div className="font-semibold">{data2.item4.heading}</div>
            <div>{data2.item4.desc}</div>
          </div>
          <img
            className="col-span-2 w-full h-full  rounded-2xl mt-2 md:mt-0"
            src={data2.img2}
          />
          <div className="col-span-1 flex flex-col text-sm xl:text-base lg:text-sm md:text-xs gap-2 bg-white  p-2 rounded-2xl mt-2 md:mt-0">
            <img
              className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-6 h-6"
              src={data2.item5.icon}
            />
            <div className="font-semibold">{data2.item5.heading}</div>
            <div>{data2.item5.desc}</div>
          </div>
          <div className="col-span-1 flex flex-col text-sm xl:text-base lg:text-sm md:text-xs gap-2 bg-white  p-2 rounded-2xl mt-2 md:mt-0">
            <img
              className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-6 h-6"
              src={data2.item6.icon}
            />
            <div className="font-semibold">{data2.item6.heading}</div>
            <div>{data2.item6.desc}</div>
          </div>
        </div>
        <div className=" w-full h-full grid grid-cols-2 md:grid-cols-5 gap-x-2 pt-2 ">
          <div className="col-span-1 flex flex-col text-sm xl:text-base lg:text-sm md:text-xs gap-2 bg-white  p-2 rounded-2xl mt-2 md:mt-0">
            <img
              className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-6 h-6"
              src={data2.item7.icon}
            />
            <div className="font-semibold">{data2.item7.heading}</div>
            <div>{data2.item7.desc}</div>
          </div>
          <div className="col-span-1 flex flex-col text-sm xl:text-base lg:text-sm md:text-xs gap-2 bg-white  p-2 rounded-2xl mt-2 md:mt-0">
            <img
              className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-6 h-6"
              src={data2.item8.icon}
            />
            <div className="font-semibold">{data2.item8.heading}</div>
            <div>{data2.item8.desc}</div>
          </div>
          <img
            className="col-span-2 w-full h-full  rounded-2xl mt-2 md:mt-0"
            src={data2.img3}
          />
          <div className="col-span-2 md:col-span-1 flex flex-col text-sm xl:text-base lg:text-sm md:text-xs gap-2 bg-white  p-2 rounded-2xl mt-2 md:mt-0">
            <img
              className="xl:w-8 lg:w-6 md:w-4 xl:h-8 lg:h-6 md:h-4 w-6 h-6"
              src={data2.item9.icon}
            />
            <div className="font-semibold">{data2.item9.heading}</div>
            <div>{data2.item9.desc}</div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Section12;
