import React from 'react'

const HeroSection = () => {
  const height = '80vh';
  return (
    <div className={`flex justify-between create_post_hero_bg text-white h-[${height}]`}>
      <div className="items-start m-auto w-1/3">

        <div className="font-semibold text-4xl mb-3 ">
          <div className='font-normal'>Empower your expression by <strong className='font-semibold'>creating post</strong></div>
        </div>
        <div className="text-sm font-semibold">
          <div className="font-normal text-base">Create unique narratives across blogs and articles, resonating deeply with your audience's interests and passions</div>
        </div>
      </div>

      <div className='w-1/2 rounded-lg'>
        <img src="" alt="" />
      </div>
    </div>
  )
}

export default HeroSection