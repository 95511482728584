import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const HeroSection = ({slideInfo, contStyle }) => {
  return (
    <div className={`flex justify-center ${contStyle} pt-[5vw]`}>
      <div className='w-2/5 rounded-lg'>
        <img src={slideInfo.img}  />
      </div>
      <div className="py-5 px-[3vw] items-start w-1/2 ">
        {slideInfo.icon && (
          <div className='w-14 h-14'>
            <img src={slideInfo.icon}  />
          </div>
        )}
        <div className="font-semibold lg:text-4xl md:text-2xl my-4">{slideInfo.title}</div>
        <div className="text-sm font-semibold">{slideInfo.subTit}</div>
        {slideInfo.btn && (
          <button className='py-1 px-4 rounded-full my-4 border border-white border-solid hover:bg-neutral-100 hover:shadow-md' onClick={slideInfo.btn.function}>
            {slideInfo.btn.txt} <FontAwesomeIcon icon={faAngleRight} />
          </button>
        )}
      </div>
    </div>
  )
}

export default HeroSection