import React from 'react';
import backgroundImage from '../../../assets/Support/Section4imagebg.png'; // Import the background image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Section4 = () => {
  return (
    <section className="relative bg-cover bg-center py-32" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="container mx-auto px-4">
        <h1 className="text-5xl text-center text-white font-bold justify-center items-center">Have Any Query ?</h1>
        <div className='text-3xl text-center text-white py-8'style={{ lineHeight: '140%' }}>Our team of experts are always ready to resolve</div>
        <div className='justify-center items-center pl-80 ml-80'>
            <button className='bg-blue-500 text-white px-4 py-2 text-md text-center rounded-lg'> 
                                    Contact Us <span className='mx-2'><FontAwesomeIcon icon ={faChevronRight}/></span>
            </button>
        </div>
      </div>
    </section>
  );
};

export default Section4;
