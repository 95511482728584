import React from 'react';
import leftImage from '../../../assets/Carrer/section5Image.png'; // Make sure to import the correct image path

const Section5 = () => {
  return (
    <div className='w-full bg-[#EFF2FB] py-6'>
    <div className="max-w-screen-xl mx-auto grid grid-cols-12 gap-2">
      <div className="col-span-12 md:col-span-6 p-8 lg:p-12 xl:p-16">
        <img src={leftImage} alt="Decorative" className="w-full" />
      </div>
      <div className="col-span-12 md:col-span-6 text-left space-y-6 md:py-24">
        <h2 className="text-xl md:text-3xl lg:text-5xl xl:text-6xl font-bold mb-4">
          <span className="text-black">Bringing a </span>
          <span className="text-blue-500">Difference</span>
        </h2>
        <p className="text-gray-600 text-md md:text-lg lg:text-xl xl:text-2xl py-4">
          We will succeed in our objective to empower every individual and organisation on the earth if we pledge to be customer-obsessed, more diverse and inclusive, and functioning as One HPDS. Beyond that, we'll change the world and get great meaning from our job. We are inspired daily to use our technology and inventiveness to enable others to do more and accomplish more because we are in awe of what people can do when they dare.
        </p>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-xl transition duration-300">
         { "Join HPDS >"}
        </button>
      </div>
    </div>
    </div>
  );
};

export default Section5;
