import React from "react";
import LandingCover from "../../components/LocalActivities/LandingCover";
import Activity from "../../components/LocalActivities/Activity";

export default function LocalActivities() {
  return (
    <div className="flex flex-col ">
      <LandingCover />
      <Activity />
    </div>
  );
}
