import React, { useState } from "react";
import img1 from "../../../assets/LocalActivities/searchActivities.jpg";
import img2 from "../../../assets/LocalActivities/listAnActivity.jpg";

const dataSet = [
  {
    img: img1,
    title: "Search the Activities Happening around you",
    buttonContent: "Search Activities",
  },
  {
    img: img2,
    title: "Want to list a Local Activity?",
    buttonContent: "List an Activity",
  },
];

export default function Section6() {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };

  return (
    <div className=" w-full h-auto px-10 md:px-20">
      <div className="grid sm:grid-cols-1 md:grid-cols-2  gap-x-10 gap-y-8 mx-auto my-10 ">
        {dataSet.map((item, index) => (
          <div
            key={index}
            className=" flex flex-col px-10 py-5 justify-end box-border h-[35vh] md:h-[50vh] w-full rounded-3xl"
            style={{
              backgroundImage: `url(${item.img})`,
              backgroundSize: "contain", // Maintain original image size
              backgroundPosition: "center", // Center the image
              backgroundRepeat: "no-repeat",
            }}
          >
            <p className=" font-semibold text-3xl md:text-5xl text-white py-2 px-2">
              {item.title}
            </p>
            <div className="">
              {" "}
              <button
                className="text-white px-6 py-3 text-lg md:text-xl rounded-2xl font-semibold border-2 border-white border-b-2 hover:border-blue-500 hover:text-blue-500  hover:shadow-md"
                type="submit"
                onClick={handleInputChange}
              >
                {item.buttonContent}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
