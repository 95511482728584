import React from "react";
import bgimg from "../../../assets/Collaboratory/Section3imagebg.png";
import img1 from "../../../assets/Collaboratory/Section3image1.png";
import img2 from "../../../assets/TalentCollaboratory/KeyFeatures/talentKeyFeature1.png";
import img3 from "../../../assets/TalentCollaboratory/KeyFeatures/talentKeyFeature2.png";
import img4 from "../../../assets/TalentCollaboratory/KeyFeatures/talentKeyFeature3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCirclePlay,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

const Section4 = () => {
  const sectionStyle = {
    backgroundImage: `url(${bgimg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const handleInputChange = (e) => {
    console.log("Button pressed");
  };

  return (
    <section className="bg-cover bg-center py-8" style={sectionStyle}>
      <div className="flex justify-center pt-8 px-4">
        <img src={img1} alt="Top Image" className="" />
      </div>
      <div className="text-center px-4 md:px-8 lg:px-16 xl:px-24">
        <h2
          className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold mt-8 text-white  md:px-12 lg:px-16 xl:px-24"
          style={{ lineHeight: "140%" }}
        >
          Gives Managers and Leaders real-time insights on ,{" "}
          <span className="text-yellow-400">
            in-demand skills and more responsive, intuitive
          </span>{" "}
          and intelligent ways to match workers to new opportunities.
        </h2>
        <p
          className="text-md md:text-lg lg:text-xl xl:text-2xl text-white mt-4"
          style={{ lineHeight: "140%" }}
        >
          HPDSx Platform is one of the most valuable methods for building your
          workforce
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 lg:gap-8 mt-8">
          <div className="text-center bg-white rounded-2xl p-4">
            <img src={img4} alt="Image 1" className="" />
            <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-500">
              Potential<br></br>Development
            </h3>
            <p className="text-md md:text-lg lg:text-xl text-gray-700 mt-4 text-justify font-medium">
              Use these same insights to provide individualized guidance and
              support to workers in operations to help them perform and their
              personal best. 
            </p>
          </div>
          <div className="text-center bg-white rounded-2xl p-4">
            <img src={img2} alt="Image 2" className="" />
            <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-500">
              Productivity <br></br>Improvement
            </h3>
            <p className="text-md md:text-lg lg:text-xl text-gray-700 mt-4 text-justify font-medium">
              uses AI-based insights on how each team member performs when
              actually using the skills in daily operations to enable targeted
              reskilling and upskilling, keeping your team operating at maximum
              productivity
            </p>
          </div>
          <div className="text-center bg-white rounded-2xl p-4">
            <img src={img3} alt="Image 3" className="" />
            <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-500">
              Performance <br></br> Transformation
            </h3>
            <p className="text-md md:text-lg lg:text-xl  text-gray-700 mt-4 text-justify font-medium">
              Use these same insights to provide individualized guidance and
              support to workers in operations to help them perform and their
              personal best. .
            </p>
          </div>
        </div>
        <div className="py-8">
          <div
            className="py-4 xl:text-3xl lg:text-2xl md:text-xl text-lg text-white text-justify px-10"
            style={{ lineHeight: "140%" }}
          >
            HPDSx platform is developing data-driven resources to support the
            organizations and individuals. It’s the process of learning who has
            which skills, analyzing how they are applying those skills on the
            job, and making decisions on how you can better cultivate them
            through learning opportunities.
          </div>

          <button
            className="text-white bg-blue-500 px-8 py-4 text-sm md:text-md lg:text-lg rounded-full hover:border hover:border-blue-500 hover:text-blue-500 hover:bg-transparent"
            type="submit"
            onClick={handleInputChange}
          >
            Get Started Now
            <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
          </button>
        </div>

        <div className="xl:text-md lg:text-sm text-xs  text-white">
          Get Started In Less Than 60 Seconds • Cancel Anytime
        </div>
      </div>
    </section>
  );
};

export default Section4;
