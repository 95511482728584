import React from "react";


import CoverSideImg from "../../../assets/Focus/Section2image1.png";

const Section2 = () => {


    return (
        <div className="w-full">
            <div className="max-w-screen">
                <div
                    className="w-full h-full grid grid-cols-6 bg-blue-500"

                >

                    <div className="order-1 md:order-1 col-span-6 md:col-span-3 place-content-start flex flex-col text-white   xl:pt-20  lg:pt-16  md:pt-12">
                        <div className="w-full h-full rounded-xl">
                            <img
                                src={CoverSideImg}
                                alt="blank"
                                className="w-full h-full object-contain"
                            />
                        </div>
                    </div>

                    <div className="order-1 md:order-1 col-span-6 md:col-span-3 place-content-start flex flex-col text-white py-16 md:pl-12 xl:pl-12 py-16  xl:pt-20  lg:pt-16  md:pt-12">


                        <div className="mb-2 mt-2  md:mt-6 lg:mt-12 xl:mt-28 pb-4  text-2xl xl:text-4xl md:text-2xl lg:text-3xl cursor-pointer">
                            <div className="mx-4 md:mx-0 lg:mx-6  py-4 text-2xl xl:text-4xl md:text-3xl lg:text-4xl font-bold " style={{ lineHeight: '140%' }}>Work more intuitively with <span className="text-yellow-400">HumanX Power Platform</span></div>
                            <div className="mx-4 md:mx-0 lg:mx-6 text-lg  xl:text-2xl md:text-xl lg:text-2xl text-justify">

                                We bring people and technology together with <span className="text-yellow-500">intuitive, human tools</span> that radically change the approach to skills and competencies. This is <span className="text-yellow-500">how a long-term</span>, definable shift is going to happen. Our <span className="text-yellow-500">ideas and solutions are built for a rapidly-changing world.</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Section2;
