import Section4Image1 from "../../../assets/HPDSxMenu/Section4image1.png";
import Section4Image2 from "../../../assets/HPDSxMenu/Section4image2.png";
import Section4Image3 from "../../../assets/HPDSxMenu/Section4image3.png";
import diffUser1 from "./Images/diffUser1.png"
import diffUser2 from "./Images/diffUser2.png"
import diffUser3 from "./Images/diffUser3.png"
import diffUser4 from "./Images/diffUser4.png"
import diffUser5 from "./Images/diffUser5.png"
import diffUser6 from "./Images/diffUser6.png"
import diffUser7 from "./Images/diffUser7.png"
import diffUser8 from "./Images/diffUser8.png"
import diffUser9 from "./Images/diffUser9.png"
import diffUser10 from "./Images/diffUser10.png"
import diffUser11 from "./Images/diffUser11.png"
import diffUser12 from "./Images/diffUser12.png"
import diffUser13 from "./Images/diffUser13.png"
import diffUser14 from "./Images/diffUser14.png"



const DatasetSec4 = {
  buttons: [
    {
      id: 1,
      heading: "Home Maker",
      heading1: "Invisible Struggles: Mental Health in Homemakers",
      description:
        "Sometimes, a very normal looking person may also be a victim of mental breakdown and anxiety. He may be working out fine, laughing, smiling, talking and all, but somewhere deep inside and within, he may be crying his heart out. It just does not visibly appear so on the outside. This situation has aggravated a lot because of various reasons like loss of jobs, work from home, salary reductions and cost cuttings etc. The effect of these problems fell on the families overall, but the most suffered category was – THE HOMEMAKERS, or in other words, THE HOUSEWIVES.",
      image: diffUser1,

      heading2: "A Transformation Platform Built For Stressed Out Mothers",
      description2:
        "Sometimes, a very normal looking person may also be a victim of mental breakdown and anxiety. He may be working out fine, laughing, smiling, talking and all, but somewhere deep inside and within, he may be crying his heart out. It just does not visibly appear so on the outside. This situation has aggravated a lot because of various reasons like loss of jobs, work from home, salary reductions and cost cuttings etc. The effect of these problems fell on the families overall, but the most suffered category was – THE HOMEMAKERS, or in other words, THE HOUSEWIVES.",
      image2: diffUser2,
    },
    {
      id: 2,
      heading: "Children",
      heading1: "Navigating School Challenges",
      description:
        "School life is challenging and often stressful. Children experience those challenges in a number of different ways: a difficulty adjusting to curriculum, tests, exams, confusion in deciding on learning path, or struggles in relationships, or making friends or getting along with a classmate. Many students feel anxious, depressed, confused, or overwhelmed at some point during their school experience and need help dealing with feelings and problems that seem beyond their control.",
      image: diffUser3,

      heading2:
        "Aplatform for children to learn the human software  and internal gymnastics",
      description2:
        "Emotionally and intellectually disenfranchised children internalize their anger and act out in unprecedented violence toward self and others. Traditional education has not addressed the growing emotional and intellectual needs of our children and teenagers; the connectedness of mind, body, and spirit. Currently, the situation regarding at-risk children is cumbersome and oblique in regards to understanding the ‘big picture’ and the solutions necessary to empower our children. A new myth is in process of creation and you can participate right now, today, Internet and digital resources.",
      image2: diffUser4,
    },
    {
      id: 3,
      heading: "Youth",
      heading1: "Title",
      description:
        "Individuals are born with their own unique talents, capabilities and core values.  But the fact is that most individuals are unable to utilize their potentialities in an appropriate way due to their inabilities to recognize their capabilities and inability to adjust and perform with the emerging demands of different life events, where new challenges are to deal regularly and efficiently. In this connection individual potential development play a vital role to make an individual more efficient and capable for handling life events more effectively by alleviating the weaknesses and enabling individuals to be self directed and self motivated.",
      image: diffUser5,

      heading2: "A Transformation Platform Built For YOUTH MINDS",
      description2:
        "Many youth are caught in a frustrating correctional system that is challenged to reduce recidivism. Repairing emotional rips and tears in the family unit, if there is one, challenges yet another overburdened system. The survivors are doing a fantastic job in nurturing those they can, sharing volumes of experience, harvesting their past and showing the rest of us the path to a new approach, which shares accountability and responsibility. A nurturing environment that provides emotional and intellectual freedom to respond and grow personally and socially demonstrates a caregiver community’s love.",
      image2: diffUser6,
    },
    {
      id: 4,
      heading: "Teachers",
      heading1: "Core Genius",
      description:
        "In a hectic and often stressful work day teachers are focussed on helping their students learn in the best possible way and on student wellbeing, with little time to think about their own wellbeing. Teachers and staff members are on the frontlines of daily school life. From creating lesson plans, to grading papers, to attending to students' social-emotional needs, the caring adults in our classrooms are juggling immense workloads. The teaching profession can be equal parts rewarding and stressful.",
      image: diffUser7,

      heading2: "A transformation Platform Built For TEACHERS ",
      description2:
        "Building teachers’ social-emotional competence, including via mindfulness training that can improve teachers’ overall effectiveness and wellbeing, help equip them with the tools needed to respond to the unique stressors of the teaching profession, and potentially reduce burnout and attrition.",
      image2: diffUser8,
    },
    {
      id: 5,
      heading: "Knowledge Worker",
      heading1: "Title",
      description:
        "We live in a knowledge age but operate ourselves in a controlling industrial age model. The mind-set of the industrial age dominates today’s environment. Everyone carry the controlling mindset to home and relations. It shows in the communications and dealing with collegues at work place, friends outside and family members at home. The dominating deseased mind set show the way relations managed with spouces and the way thinking, doing and communicationg with children for managing, guiding, motivating and maintaining discipline.",
      image: diffUser9,

      heading2: "A Transformation Platform Built For KNOWLEDGE WORKERS",
      description2:
        "Building capacity for action, social-emotional competence, improve overall effectiveness and wellbeing of individual and organisation. Help equip with the tools needed to respond to the unique stressors. Discover how to live in the present moment in an enjoyable way rather than worrying about the past or being concerned about the future.",
      image2: diffUser10,
    },
    {
      id: 6,
      heading: "Corporate",
      heading1: "Title",
      description:
        "Industrial development mind set and education taught wide spread reluctance to take initiative, to act independently, fuels formal communications to direct imperative provocative thoughts. This is wide spread in every sphere including spiritual practices in order to get followers to act only. That’s what in the industrial age codependency created in mind set of human beings. This weakness reinforced for a long century and took toward diseased mind-set and dangerous health problems. There is an unconscious pact of blame by believing that other must change before their own circumstances can improve. This cycle of communication is predominant in the society, culture, in families between parents and children",
      image: diffUser11,

      heading2: "A Transformation Platform Built For CORPORATE",
      description2:
        "UPMYMIND comprehensive Behavioral Health empowers institutions to offer your people the right level of care when they need it most. Our extensive network of behavioral health providers, combined with virtual companions and evidence-based digital wellness and wellbeing programs, allow the organization to offer your workforce  engaging, personalized care at scale. Our Platform allows you to use our ready-made program and lesson plans in and out of the learning and development. With one click, you can complete transition activities during the day or assign development plan to employee at work.",
      image2: diffUser12,
    },
    {
      id: 7,
      heading: "Educational Institution",
      heading1: "Title",
      description:
        "HPDSx Platform help schools, colleges and universities to improve the mental health and wellbeing of their staff. Institutions can also carry out research and advocate for changes in Government policy for the benefit of the education workforce. Wellness programs can be organised for the teachers and the learners. Platform can support for the improvement of academic performance of the learners and the teachers can give their best in the teaching-learning process. The academic institutions can organize and develop a wellness program that could guide, assist and educate the teachers and that can guide, assist and educate the teachers to be physically fit, emotionally stable, spiritually involved, and have a healthy career as a professional.",
      image: diffUser13,

      heading2: "A Transformation Platform Built For Schools",
      description2:
        "To inspire, educate, and empower teachers, parents, and counsellors to increase the amount and quality of physical and emotional education they offer their students and children by providing an innovative and engaging variety of active play and mindfulness activities that can easily integrate into any daily school or home routine.",
      image2: diffUser14,
    },
  ],
};

export default DatasetSec4;
