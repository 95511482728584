
// Hamburger.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import FootContent from './FootContent';


const Hamburger = () => {
  const [isAllOpen, setIsAllOpen] = useState(false);
  const [isEngineOpen, setIsEngineOpen] = useState(false);
  const [isPlatformOpen, setIsPlatformOpen] = useState(false);
  const [isResOpen, setIsResOpen] = useState(false);


  
  const toggleAll = () => {
    setIsAllOpen(!isAllOpen);
  };
  const toggleEngine = () => {
    setIsEngineOpen(!isEngineOpen);
  };
  const togglePlatform = () => {
    setIsPlatformOpen(!isPlatformOpen);
  };
  const toggleRes = () => {
    setIsResOpen(!isResOpen);
  };


  return (
    <div>

      <div className="md:hidden">
        <FontAwesomeIcon icon={faBars}
          className="text-black cursor-pointer text-2xl w-4  mx-3"
          onClick={toggleAll}
        />
      </div>

      {isAllOpen && (
        <div className='absolute bg-neutral-100 w-[120vw] px-4 -left-1 mt-4 border-b border-black text-sm'>

        <FootContent/>
        </div>
      )}

      {isAllOpen && (
        <div className="sticky inset-0 bg-black bg-opacity-50 z-10" onClick={toggleAll}></div>
      )}




    </div>
  );
};

export default Hamburger;
