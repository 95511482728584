import React, { useState, useEffect } from 'react';
import { motion , useAnimation} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons';
import Section4Image from '../../../assets/HPDSxMenu/Section4image1.png'; // Import your image for the right section
import DatasetSec13 from './DatasetSec7';
import { useInView } from 'react-intersection-observer';

const Section13 = () => {
    const [selectedButton, setSelectedButton] = useState(1);
    const [fadeIn, setFadeIn] = useState(false);
    const { ref, inView } = useInView();
    const controlsLeft = useAnimation();
  const controlsRight = useAnimation();

    useEffect(() => {
        setFadeIn(true); // Set fadeIn to true initially
    }, []);

    const handleButtonClick = async (button) => {
        await Promise.all([
          controlsLeft.start({ opacity: 0, x: -50, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } }),
          controlsRight.start({ opacity: 0, x: -50, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } })
        ]);
        setSelectedButton(button);
        await Promise.all([
          controlsLeft.start({ opacity: 1, x: 0, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } }),
          controlsRight.start({ opacity: 1, x: 0, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } })
        ]);
      };

    return (
        <motion.div
            ref={ref}
            className="h-[200vh] md:h-[130vh] lg:h-[150vh] relative bg-white"
            initial={{ opacity: 0, y: 80 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
            transition={{ ease: 'easeInOut', duration: 0.7, delay: 0.2 }}
        >
            <div className="absolute inset-0 flex flex-col text-black">
                <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-loose mb-4 py-4 md:py-8 lg:py-0 pt-8 md:pt-12 lg:pt-16 xl:pt-20 mx-4 md:mx-12 lg:mx-20 xl:mx-28 font-semibold" style={{ lineHeight: '140%' }}>Co-creating the <span className='text-blue-500'>Digital Building Blocks</span></h1>
                <h1 className="text-lg md:text-lg lg:text-xl xl:text-2xl leading-loose mb-4 py-[6px] md:py-4 lg:py-8 mx-4 md:mx-12 lg:mx-20 xl:mx-28 font-semibold" style={{ lineHeight: '140%' }}><span className='text-blue-500'>Five building blocks</span> for Human Potential Development Transformation find the Right fit…</h1>
                <div className="grid grid-cols-1 md:grid-cols-5 gap-4 md:gap-1 lg:gap-6 xl:gap-10 py-4 md:py-2 lg:py-0 xl:py-2 mx-4 md:mx-12 lg:mx-20 xl:mx-24">
                    {DatasetSec13.map(item => (
                        <motion.button
                            key={item.id}
                            className={`text-center  hover:bg-blue-500  hover:text-white md:text-sm lg:text-md font-semibold py-3 md:px-2 xl:px-6 rounded-xl whitespace-nowrap border ${selectedButton === item.id ? 'bg-blue-500 text-white border-blue-500 hover:border-transparent' : 'text-blue-500 bg-white border-blue-500 hover:border-transparent'} transition duration-300 ease-in-out`}
                            onClick={() => handleButtonClick(item.id)}
                            
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        
                        >
                            {item.heading}
                        </motion.button>
                    ))}
                </div>
                <motion.div
                    initial={{ opacity: 0.8 }}
                    animate={fadeIn ? { opacity: 1 } : { opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="grid grid-cols-12 gap-4 my-8 md:my-20"
                >
                    {/* Left Section */}
                    <div className="col-span-12 md:col-span-6 text-left pt-8 md:pt-20 mx-4 d:mx-8 lg:mx-20 xl:mx-28 md:my-8 lg:my-12 xl:my-20">
                        <motion.div
                            initial={{ opacity: 0.8, y: 20 }}
                            animate={controlsLeft}
                            transition={{ duration: 0.5 }}
                            className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-8"
                        >
                            {DatasetSec13[selectedButton - 1].heading}
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0.8, y: 20 }}
                            animate={controlsLeft}
                            transition={{ duration: 0.5 }}
                            className="text-lg md:text-lg lg:text-xl xl:text-2xl"
                            style={{ lineHeight: '140%' }}
                        >
                            {DatasetSec13[selectedButton - 1].description}
                        </motion.div>
                    </div>
                    {/* Right Section */}
                    <motion.div
                        initial={{ opacity: 0.8, x: 20 }}
                        animate={controlsRight}
                        transition={{ duration: 0.5 }}
                        className="col-span-12 md:col-span-6 flex justify-center items-center md:mr-12"
                    >
                        <img src={DatasetSec13[selectedButton - 1].image} alt="Right Section Image" className="w-full md:w-full xl:w-4/5" />
                    </motion.div>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default Section13;
