import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import sampleImage from '../../../assets/HPDSxMenu/Section12image1.png';
import DatasetSec12 from './DatasetSec6';

const Section12 = () => {
  const [expandedCard, setExpandedCard] = useState(1);

  const handleCardClick = (cardIndex) => {
    if (expandedCard === cardIndex) {
      setExpandedCard(0); // Collapse the card if it's already expanded
    } else {
      setExpandedCard(cardIndex);
    }
  };

  return (
    <div className="  bg-white text-black">
      <div className='px-4 md:px-8 lg:px-12 xl:px-20 py-12 md:py-16'>
        {/* Heading */}
        <h2 className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl px-4 md:px-4 xl:px-8 font-semibold my-8 md:my-8 lg:my-10 xl:my-12 w-full md:w-full lg:w-4/5" style={{ lineHeight: '140%' }}>HPDSx Platform <span className='text-blue-400'>Co-design</span> Engagements</h2>



        {/* Grid */}
        <div className="grid grid-cols-12 gap-6 md:gap-2 lg:gap-4 xl:gap-8 md:my-6 lg:my-8 xl:my-12">
          {/* Right Section */}
          <div className="col-span-12 md:col-span-6">
            {/* Cards */}
            <div className="space-y-4">
              {DatasetSec12.map(card => (
                <div key={card.id} className={`px-4 md:px-6 md:py-6 xl:px-8 xl:py-8 border-l-4 ${expandedCard === card.id ? 'border-blue-800' : 'border-transparent'} border-b md:p-2 xl:p-4`}>
                  <div className="flex items-center justify-between cursor-pointer" onClick={() => handleCardClick(card.id)}>
                    <h4 className="text-xl md:text-2xl lg:text-3xl font-semibold"><span className='text-black font-bold'>{card.title}</span></h4>
                    <FontAwesomeIcon icon={expandedCard === card.id ? faAngleUp : faAngleDown} />
                  </div>
                  <div className={expandedCard === card.id ? 'opacity-100 max-h-96 overflow-hidden transition-opacity duration-1000 ease-in' : 'opacity-0 max-h-0 overflow-hidden transition-opacity duration-1000 ease-in-out'}>
                    {/* Card Details */}
                    <p className='md:text-lg lg:text-xl xl:text-2xl mt-4' style={{ lineHeight: '140%' }}>{card.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Left Section */}


          <div className="col-span-12 md:col-span-6  md:ml-6 lg:ml-8 xl:ml-10">
            {expandedCard !== 0 && (
              <img src={DatasetSec12.find(card => card.id === expandedCard).image} alt="Card Image" className="w-full" />
            )}
          </div>
        </div>



      </div>
    </div>
  );
};

export default Section12;
