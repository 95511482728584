import React from "react";
import ArticleHeader from "../components/Posts/Articles/ArticleHeader";
import Articlefirst from "../components/Posts/Articles/ArticleFirst";
import Articlesecond from "../components/Posts/Articles/ArticleSecond";
import Blogartthird from "../components/Posts/Blogs/BlogSection3";
import BlogFourthSection from "../components/Posts/Blogs/BlogFourthSection";
import ArticleThird from "../components/Posts/Articles/ArticleThird";


export default function Article() {
  return (
    <div className="flex flex-col">
      <>
        <ArticleHeader />
        <Articlefirst />
        <Articlesecond />
        <ArticleThird />
        <Blogartthird />
        <BlogFourthSection />
      </>
    </div>
  );
}
