
import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../About Hpds/imageCarousel.css"
import sectionImage from '../../../assets/HPDSxEngine/Section8image1.png'; // Import your section image

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble} from '@fortawesome/free-solid-svg-icons';

import img1 from "../../../assets/Internship/Section2image1.png";
import img2 from "../../../assets/Internship/Section2image2.png";
import img3 from "../../../assets/Internship/Section2image3.png";
import img4 from "../../../assets/Internship/Section2image4.png";


const Section8 = () => {
    const dataset = [
        {
            image_url: img1,
            title: <span className='text-blue-500'>Elevating Your Future with Practical Experience at HPDS </span>,
            description:
                "You'll get a taste of professional life, allowing you to get ahead in your career before you've even left your Institutions. ",
        },
        {
            image_url: img2,
            title: <span className='text-green-500'>Elevating Your Future with Practical Experience at HPDS</span>,
            description:
                "You will learn from experienced colleagues and meet clients representing a variety of industries and environments as we help you make a smooth transition from student to professional. ",
        },
        {
            image_url: img3,
            title: <span className='text-yellow-500'>Elevating Your Future with Practical Experience at HPDS</span>,
            description:
                "Descriptions Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skills.",
        },
        {
            image_url: img4,
            title: <span className='text-red-500'>Elevating Your Future with Practical Experience at HPDS </span>,
            description:
                "Our structured programs provide a collaborative, open culture where you will have exposure to everything that goes on in a global, fast-paced organisation like HPDS Trust and its Technology Partners.",
        },
   
    ];

    const [currentItemIndex, setCurrentItemIndex] = useState(0);
    const innerContainerRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [animateText, setAnimateText] = useState(false);

    useEffect(() => {
        const innerContainer = innerContainerRef.current;

        const handleScroll = () => {
            const scrollPosition = innerContainer.scrollTop;
            const containerHeight = innerContainer.clientHeight;
            const totalHeight = innerContainer.scrollHeight;

            if (scrollPosition + containerHeight >= totalHeight) {
                innerContainer.scrollTop = 0;
                setCurrentItemIndex((prevIndex) => (prevIndex + 1) % dataset.length);
                setIsLoaded(false);
            }
        };

        innerContainer.addEventListener('scroll', handleScroll);
        return () => {
            innerContainer.removeEventListener('scroll', handleScroll);
        };
    }, [dataset.length]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoaded(true);
        }, 500);

        return () => clearTimeout(timeout);
    }, [currentItemIndex]);

    useEffect(() => {
        if (isLoaded) {
            setAnimateText(true);
            const timeout = setTimeout(() => {
                setAnimateText(false);
            }, 1000); // Adjust the duration as needed
            return () => clearTimeout(timeout);
        }
    }, [isLoaded]);
    return (
        <section className="  py-4 md:py-8 lg:py-10 xl:py-12 px-4 md:px-0">
            <div className="text-center mb-2 md:mb-4 lg:mb-6 xl:mb-8 ">
                
                <p className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold  pt-8 md:pt-12 lg:pt-12 xl:pt-16"><span className='mx-4'><FontAwesomeIcon icon={faCheckDouble} /></span>INDUCTION</p>
            </div>
            

            <div className=" md:px-4 lg:px-12 xl:px-32 md:pb-6 xl:pb-8  xl:py-16 lg:py-8 md:py-4">
                <div className="flex-col justify-center items-center gap-2 md:gap-5 flex xl:px-28 lg:px-20 md:px-12">

                </div>
                <div className="flex flex-col md:flex-row gap-4 py-4 md:py-12">
                    {/* Left Section for Images */}
                    <div className="w-full md:w-2/5   md:px-6 lg:px-8 xl:px-10  " >
                        <img
                            src={isLoaded ? dataset[currentItemIndex].image_url : ""}
                            alt={``}
                            className={`w-full object-contain mt-10 lg:mt-0  h-60 md:h-80 lg:h-full  ${isLoaded ? "fade-in" : ""}`}
                        />
                    </div>

                    {/* Right Section for Text, Description, and Progress Bar */}
                    <div className="w-full md:w-3/5 relative" ref={innerContainerRef} style={{ overflowY: 'scroll', height: window.innerWidth >= 1024 ? '500px' : window.innerWidth >= 768 ? '400px' : '300px', scrollbarWidth: 'none', '-ms-overflow-style': 'none' }}>
                        <div className >
                            <div className={` mt-12 md:mt-0 mx-12 text-md md:text-xl lg:text-2xl xl:text-4xl font-bold ${isLoaded ? 'slide-in' : ''} ${animateText ? 'fade-in' : ''}`}>
                                {isLoaded && dataset[currentItemIndex].title}
                            </div>
                            <div className={` mx-12 text-sm md:text-md lg:text-lg xl:text-xl text-gray-600 my-4 ${isLoaded ? 'slide-in' : ''} ${animateText ? 'fade-in' : ''}`}>
                                {isLoaded && dataset[currentItemIndex].description}
                            </div>
                        </div>

                        {/* Vertical Progress Bar */}
                        <div className="absolute top-10 right-200 h-full flex flex-col items-center" >
                            {/* Big Circle */}
                            <div className="w-9 h-9 bg-white border border-4 border-gray-200 rounded-full relative">
                                <div className="w-3 h-3 bg-blue-500 rounded-full absolute top-2 left-2"></div>
                            </div>

                            {/* Small Dots and Connecting Lines */}
                            {dataset.map((item, index) => (
                                <div key={index} className="flex flex-col items-center">
                                    {index !== 0 && index < 2 ? (
                                        <div className="w-4 h-4 rounded-full bg-blue-500 mb-4"></div>
                                    ) : null}

                                    {/* Connecting Line (except for the last item) */}
                                    {index < 1 && (
                                        <div className="w-[2px]  h-[200px] md:h-[300px] lg:h-[400px] bg-blue-500">
                                            {index === 0 ? (
                                                <hr className="border-dotted border-2 border-white h-full" />
                                            ) : null}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section8;
