import React from "react";
import LeftSideImage from "../Templates/LeftSideImage";
import smmain from "../../../assets/HomePage/Institution/imain.webp";

const Section1 = () => {
  const data = {
    title: <>Save time with enhanced tools for experts and beginners</>,
    description: (
      <>
        Achieve excellence through personalized assessments, offering accurate
        measures with our <span className="font-bold">enhanced tools</span> for
        informed decision-making and strategic planning.
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <LeftSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section1;
