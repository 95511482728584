import React from "react";
import Home from "../../components/ContactUs/Home";

export default function ContactUs() {
  return (
    <div className="flex flex-col">
      <Home />
    </div>
  );
}
