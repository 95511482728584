import React, { useState } from 'react';

// Import background image
import backgroundImage from '../../../assets/HPDSxEngine/Section5imagebg.png';
import Cardimg1 from "../../../assets/SkillCollaboratory/Section11cardimage.png"

// Placeholder dataset for button data
const buttonData = [
  { id: 1, image: Cardimg1, text: <><span className='font-semibold'>Address the transitional needs of students from K12 to Professional Degree a pre- and early-semester academic transition support programs </span>- Goal-setting, independent learning,  self-evaluation, self-awareness, academic performance, college testing and assessment, clustering of academic courses, adjust to the rigors of the college curriculum , connect students with their majors and faculty, develop and promote study skills, build confidence by making clarity in concept for personal and professional growth, etc…</> },
  { id: 2, image: Cardimg1, text: <><span className='font-semibold'>Further their competencies in Academic skill development programs </span>- Individual’s academic success on his or her skills and abilities to attend to tasks and teachers and classroom expectations with minimal distraction, skill and abilities required to acquire necessary information, complete assignments, and participate in classroom activities and discussions, academic learning, study skills, memory techniques, etc…</> },
  { id: 3, image: Cardimg1, text: <><span className='font-semibold'>Professional Guidance for the students in their Career-Path development through Career Counseling and grooming  programs </span>- Opportunity to explore a variety of careers using knowledge they have gained about their interests and skills through assessment components and structured activities, developing career awareness, helps students identifies what they are good at; understand how their skills, talents, and interests translate into work; and find the education and training they need to work in the existing job market.</> },
  { id: 4, image: Cardimg1, text: <><span className='font-semibold'>Promote mental health, emotional resilience and wellness through Counseling and Psychological programs </span>- Conversation with a trained professional about the issues related to your mental or emotional health in the service of gaining greater understanding of yourself, relieving emotional distress, making desired changes in your life and improving daily functioning and satisfaction, improving self-image, stress management, etc….</> }
];

const Section11 = () => {
  const [selectedButton, setSelectedButton] = useState(buttonData[0]);

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  return (
    <section className="py-16" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className=" ">
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 text-white text-center xl:px-24 lg:px-20 md:px-12 px-4 py-12 ">It recommends that the <span className='text-yellow-400'>Knowledge, Skills, Competencies</span> and <span className='text-yellow-400'>Inventiveness</span> of the workforce to be constantly updated through effective lifelong learning.</h2>
        <div className="bg-white  rounded-lg xl:mx-24 lg:mx-20 md:mx-12 mx-4">
          <div className="grid grid-cols-12 ">
            {/* Left Section */}
            <div className="col-span-12 md:col-span-8 p-6 md:p-4 lg:p-4 xl:p-8">
              <p className='text-lg md:text-lg lg:text-xl xl:text-2xl '>{selectedButton.text}</p>
            </div>
            {/* Right Section */}
            <div className="col-span-12 md:col-span-4 p-6 md:p-2 lg:p-3 xl:p-4">
              <img src={selectedButton.image} alt="Button Image" className="w-full h-auto" />
            </div>
          </div>

        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-3 lg:gap-4 xl:gap-12 mt-8 md:mt-12 lg:mt-20 bg-white justify-around mx-4 md:mx-24 lg:mx-36 xl:mx-48 rounded-2xl">
          {/* Buttons */}
          {buttonData.map(button => (
            <button
              key={button.id}
              className={`p-6 md:p-4 lg:p-6 xl:p-8 rounded-lg ${selectedButton === button ? 'border-b-4  border-b-yellow-500 border-r-2 border-r-gray-200' : ''}`}
              onClick={() => handleButtonClick(button)}
            >
              <img src={button.image} alt={button.text} className="w-full h-32" />
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section11;
