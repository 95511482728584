import img1 from "../../../assets/SME/step05.png";
import img2 from "../../../assets/SME/step04.png";
import img3 from "../../../assets/SME/step03.png";
import img4 from "../../../assets/SME/step02.png";
import img5 from "../../../assets/SME/step01.png";

const section6Data = [
  {
    title: "Subject Choice",
    color: "red-600",
    imagePath: img1,
    startText: "You Start Here",
    description: (
      <>
        Explain little bit about this section
        <br />
        Explain little bit about this section
      </>
    ),
  },
  {
    title: "Skills Choice",
    color: "green-600",
    imagePath: img2,
    startText: "Your Skill Development Start Here",
    description: (
      <>
        Explain little bit about this section
        <br />
        Explain little bit about this section
      </>
    ),
  },
  {
    title: "Learning Choice",
    color: "orange-600",
    imagePath: img3,
    startText: "Your Learning Start Here",
    description: (
      <>
        Explain little bit about this section
        <br />
        Explain little bit about this section
      </>
    ),
  },
  {
    title: "Career Choice",
    color: "yellow-500",
    imagePath: img4,
    startText: "Your Career Start Here",
    description: (
      <>
        Explain little bit about this section
        <br />
        Explain little bit about this section
      </>
    ),
  },
  {
    title: "Enterprising Choice",
    color: "blue-500",
    imagePath: img5,
    startText: "Your Brand Start Here",
    description: (
      <>
        Explain little bit about this section
        <br />
        Explain little bit about this section
      </>
    ),
  },
];

export default section6Data;
