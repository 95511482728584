import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import section2Data from "./section2Data";
import search from "../../../assets/Competitions/search.jpg";

const Section2 = () => {

  const timeLeftColor = (timeLeft) => {
    const daysLeft = parseInt(timeLeft, 10);

    if (daysLeft >= 7) {
      return "text-green-600";
    } else if (daysLeft > 2 && daysLeft <= 7) {
      return "text-yellow-500";
    } else if (daysLeft <= 2) {
      return "text-red-600";
    }

    return "";
  };

  return (
    <div className="max-w-screen-xl  xl:mx-28 lg:mx-24 md:mx-16 py-3 md:py-12 bg-white relative">
      <div className="flex flex-col">
        <div className="flex flex-row gap-0 justify-between">
          <div className="text-md xl:text-3xl lg:text-3xl md:text-2xl font-semibold font-Poppins">
            <div className="hidden md:block">
              Think Skill India
              <span className="font-bold ml-2.5">Active Competitions</span>
            </div>
            <div className="block md:hidden">
              <div className="font-bold text-lg ml-6">Active Competitions</div>
            </div>
          </div>
          <div>
            <div className="hidden md:block">
              <div className="w-10 md:w-56 h-10 px-4 py-2.5 bg-neutral-100 rounded-lg ">
                <div className="flex flex-row">
                  <div className="opacity-60 tracking-tight text-slate-900 font-Poppins text-sm text-base justify-start items-center gap-2 inline-flex">
                    <img src={search} alt="search" />
                    Search competitions
                  </div>
                </div>
              </div>
            </div>
            <div className="block md:hidden">
              <div className="flex flex-row">
                <div className="mr-4 w-24 h-8 px-2 py-1 opacity-60 text-sm bg-neutral-300 text-black rounded-full">
                  <FontAwesomeIcon icon={faSearch} className="mr-2" />
                  Search
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-screen-xl flex flex-wrap mt-2 md:mt-6 mx-6 md:mx-0">
          {section2Data.map((section) => (
            <div
              key={section.id}
              className={`sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 ${timeLeftColor(
                section.timeLeft
              )}`}
            >
              <div className="flex-col justify-start items-start mx-2 my-4 bg-slate-100 rounded-lg hover:shadow-2xl cursor-pointer">
                <img
                  className="w-full h-full relative rounded-lg object-cover"
                  src={section.image}
                  alt={section.title}
                />
                <div className="text-center py-4 tracking-tight text-black xl:text-sm lg:text-sm md:text-xs">
                  <div className="my-1 font-bold">{section.title}</div>
                  <div className="font-semibold">
                    <span className="text-stone-400">Prize Pool: </span>
                    {section.prizeValue}
                  </div>
                  <div
                    className={`font-semibold ${timeLeftColor(
                      section.timeLeft
                    )} mt-1`}
                  >
                    {section.timeLeft}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="cursor-pointer text-blue-600 text-center font-bold mt-4">
          See all
        </div>
      </div>
    </div>
  );
};

export default Section2;
