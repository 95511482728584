import React from "react";

const CoverIndex = () => {
  const indexData = [
    "Interest Inventories",
    "Aptitude Assessments",
    "Work Values Inventories",
    "Skills Assessments",
    "Personality Typing",
    "Career Maturity/Beliefs",
    "Employability Potential Matrix",
    "Job Values Inventory",
    "Work Values Clarification",
    "Student Assessment Information",
    "Student Care Information",
    "Skill Score Information",
    "Student Demographic Information",
    "Assessment Reports and Analytics",
    "Skill Occupation Data",
    "National Student Electronic Skill Record",
    "Other Education Qualification Record",
  ];

  return (
    <div className="w-full px-6 py-8 xl:px-28 lg:px-20 md:px-12 xl:py-16 lg:py-16 md:py-12  bg-neutral-100">
      <div className="flex flex-col items-start">
        <div className="text-zinc-800 text-opacity-80 text-lg md:text-2xl font-semibold md:leading-9 tracking-widest">
          INDEX
        </div>
        <div className="text-zinc-800 text-xl md:text-4xl font-semibold">
          Professional development
        </div>
      </div>
      <div className="grid grid-cols-3 gap-5 lg:gap-x-16 xl:gap-x-48 mt-5">
        {indexData.map((section, index) => (
          <div key={index} className="flex-col justify-start items-start">
            <div className="text-blue-500 text-xs md:text-lg font-light md:font-normal">{section}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoverIndex;
