import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img from "../../../assets/MainMenu/Section2image.png";

const Section2 = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Only trigger animation once
    threshold: 0.2 // Trigger animation when 20% of the section is visible
  });

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0, y: 80 ,scale: 0.8 }}
      animate={inView ? { opacity: 1, y: 0 , scale: 1 } : { opacity: 0, y: 80 ,scale: 0.8 }}
      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }} // Adjust transition properties as needed
      className="bg-white pt-10"
    >
      {/* Image */}
      <div className="flex justify-center">
        <img src={img} alt="Image" className="w-full" />
      </div>
    </motion.section>
  );
};

export default Section2;
