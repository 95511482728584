import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import backgroundImage from '../../../assets/HPDSxEngine/Section11imagebg.png';

const Section11 = () => {
  const { ref, inView } = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.section
      className="h-[190vh] md:h-[140vh] lg:h-[125vh] xl:h-[110vh] bg-gray-200 md:bg-cover lg:bg-center pb-8 md:pb-12 py-12 md:py-0 lg:py-12 lg:pb-12 pt-12 md:pt-0 xl:pt-12 px-4 md:px-0"
      style={{ backgroundImage: `url(${backgroundImage})` }}
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeInOut' } },
        hidden: { opacity: 0, y: 100 }
      }}
    >
      <div className="mx-auto py-24 md:py-56 pb-12 md:pb-12 pt-12 md:pt-0 lg:pt-32 xl:pt-40">
        <motion.h1
          className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-black mb-4 px-4 md:px-24 lg:px-32 xl:px-40"
          style={{ lineHeight: '140%' }}
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeInOut' } },
            hidden: { opacity: 0, y: 50 }
          }}
        >
          Navigating the Digital Wave: <span className='font-semibold'>Transformative Pedagogies for Higher Education in the Fourth Industrial Revolution</span>
        </motion.h1>
        
        <motion.p
          className="text-md md:text-lg lg:text-xl text-black px-4 md:px-24 lg:px-32 xl:px-40"
          style={{ lineHeight: '140%' }}
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeInOut', delay: 0.2 } },
            hidden: { opacity: 0, y: 50 }
          }}
        >
          Education is fundamental in preparing today’s learners for an increasingly uncertain and changing world and needs to keep pace with the changing industries which are augmented and transformed by digital tools and technologies. The expanding horizon of learning possibilities that has opened up in response to the complex affordances of contemporary digital landscapes add new dimensions to thinking about the core educational purposes of a ‘higher’ education. In this environment educational institutions need pedagogical responses that satisfy the drive for flexibility, while also equipping learners with the critical, creative thinking, communicative and collaborative skills that enable them to adapt to and thrive in unpredictable situations and scenarios. By aligning teaching and learning methods with the skills needed in the future, universities can be sure they are successfully preparing their students for the fourth industrial revolution.
        </motion.p>
      </div>
    </motion.section>
  );
};

export default Section11;
