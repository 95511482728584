import e1 from "../../../../assets/Hpdsx/Section8_Img1.svg";
import e2 from "../../../../assets/Hpdsx/Section8_Img2.svg";
import e3 from "../../../../assets/Hpdsx/Section8_Img3.svg";
import e4 from "../../../../assets/Hpdsx/Section8_Img4.svg";

const eightSectionData = [
  {
    id: 1,
    name: "Mentor",
    imageUrl: e1,
  },
  {
    id: 2,
    name: "Coach",
    imageUrl: e2,
  },
  {
    id: 3,
    name: "Counselor",
    imageUrl: e3,
  },
  {
    id: 4,
    name: "Guide",
    imageUrl: e4,
  },
];

export default eightSectionData;
