import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";

export default function Sections() {
  return (
    <div className="flex flex-col">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <div className="text-center text-stone-900 text-xl xl:text-4xl lg:text-3xl md:text-2xl  font-semibold py-8 md:py-12 xl:py-20">
        <span className="font-bold">Connect</span> with{" "}
        <span className="font-bold">Our Expert</span>
      </div>
      <Section5 />
    </div>
  );
}
