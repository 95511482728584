import CardImage1 from '../../../assets/Platform/Section5_img1.png';
import CardImage2 from '../../../assets/Platform/Section5_img2.png';
import CardImage3 from '../../../assets/Platform/Section5_img3.png';

export const cardData = [
  {
    image: CardImage1,
    title: "Intuitive yet powerful user experience",
    
  },
  {
    image: CardImage2,
    title: "Enriched and integrated whole health views",
    
  },
  {
    image: CardImage3,
    title: "Flexible technology for complex requirements",
    
  },
];

export default cardData;