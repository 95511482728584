import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import CoverSideImg from "../../../assets/SME/InstitutionCoverImg.png";

const LandingCover = () => {
  return (
    <div className="w-full grid grid-cols-12 gap-0 md:gap-8 xl:gap-20 bg-blue-950 pl-2 pt-12 xl:px-12 xl:py-1 lg:px-10 lg:py-8 md:px-8 md:py-4 ">
      <div className="px-3 text-white md:px-0 order-2 md:order-1 col-span-12 md:col-span-4 flex flex-col cursor-pointer">
        <div className="hidden md:block -mt-10 -ml-5">
          <div class="w-24 h-24 bg-white bg-opacity-0 rounded-full border-8 border-white border-opacity-50"></div>
          <div className="w-12 h-12 bg-white bg-opacity-0 rounded-full border-8 border-white border-opacity-50"></div>
        </div>
        <div className="mb-0 md:mb-2 pt-3 md:pt-8 lg:pt-16 xl:pt-48 pb-4 md:pb-1 xl:pb-4 font-semibold text-2xl xl:text-4xl md:text-xl lg:text-3xl">
          Think Skill India for Institution
        </div>
        <div className="text-xs md:text-xs lg:text-md xl:text-lg lg:text-md md:text-sm">
          <div>
            To create an impact on various types of people, society, industry,
            economy, and the future; to transform their careers, influence the
            world, elevate their businesses, and shape their futures as Subject
            Matter Experts on the Think Skill India Platform.
          </div>
        </div>
        <div className=" md:ml-0 mt-4 mb-6 md:my-8 px-4 py-2 w-fit hover:bg-white hover:text-blue-950 cursor-pointer rounded-full border border-white hover:border-violet-600 flex items-center justify-start ">
          <div className="text-xs font-bold  ">Get Started</div>
          <FontAwesomeIcon icon={faChevronRight} className=" ml-2" />
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-12 md:col-span-8 flex flex-col justify-end items-end xl:py-12 md:py-1 lg:py-8">
        <div className="w-full">
          <img
            src={CoverSideImg}
            alt="blank"
            className="w-full"
          />
        </div>
        <div class="hidden xl:block xl:-mt-16 -mr-12 w-64 h-72 relative">
          <div class="w-16 h-16 left-[113px] top-0 absolute bg-white bg-opacity-0 rounded-full border-8 border-white border-opacity-50"></div>
          <div class="w-10 h-10 left-0 top-[96px] absolute bg-white bg-opacity-0 rounded-full border-8 border-white border-opacity-50"></div>
          <div class="w-4 h-4 left-[72px] top-[73px] absolute bg-white bg-opacity-0 rounded-full border-8 border-white border-opacity-50"></div>
          <div class="w-36 h-36 left-[69px] top-[88px] absolute bg-white bg-opacity-0 rounded-full border-8 border-white border-opacity-50"></div>
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
