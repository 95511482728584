import React from "react";
import LandingCover from "../../components/Competitions/LandingCover";
import Section1 from "../../components/Competitions/Section1";
import Section2 from "../../components/Competitions/Section2";
import Section3 from "../../components/Competitions/Section3";

export default function Scientist() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <Section1 />
      <Section2 />
      <Section3 />
    </div>
  );
}
