import React from 'react';

const Section1 = () => {
  const backgroundColor = '#0086E8';
  return (
    <section className=" py-16 pb-20 md:pb-60" style={{ background: backgroundColor }}>
      {/* Headings */}
      <h1 className="text-white text-center text-2xl md:text-3xl font-bold mb-8" >THINKSKILLINDIA Platform</h1>
      <h2 className="text-white text-center text-3xl md:text-5xl font-bold mb-4">A Nation of Opportunity: </h2>
      <h3 className="text-yellow-400  text-center text-3xl md:text-5xl font-bold mb-4">Building India's 21st Century Workforce​</h3>

      {/* Cards */}
      <div className="flex justify-between my-12 mx-2 md:mx-8 lg:mx-12 xl:mx-28 text-white">
        {/* First Card */}
        <div className="w-1/2 bg-transparent border-2 border-white rounded-lg p-3 md:p-2 lg:p-2 xl:p-4 mr-4 text-center px-2 md:px-4 lg:px-12 xl:px-16 ">
          {/* Content of the first card */}
          <div className='lg:text-3xl font-bold py-8'> Our Mission</div>
          <div className='lg:text-2xl py-4'style={{ lineHeight: '140%' }}>Our mission is to improve people's potential and help enough to achieve with they want.</div>
        </div>

        {/* Second Card */}
        <div className="w-1/2 bg-transparent border-2 border-white rounded-lg p-3 md:p-2 lg:p-2 xl:p-4 mr-4 text-center px-2  lg:px-12 xl:px-16" >
        <div className='lg:text-3xl font-bold py-8'> Our Vision</div>
        <div className='lg:text-2xl py-4'style={{ lineHeight: '140%' }}>Our vision is to enable a great life with greater opportunities for a better society today and tomorrow.</div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
