import React from 'react';

const RoundPill = ({ digit, style }) => {
  return (
    <div className={`w-8 h-8 rounded-full ${style} font-bold flex items-center justify-center`}>
      {digit}
    </div>
  );
};

export default RoundPill;