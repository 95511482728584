import img1 from "../../../../assets/Hpdsx/Section2_Img1.png";
import img2 from "../../../../assets/Hpdsx/Section2_Img2.png";
import img3 from "../../../../assets/Hpdsx/Section2_Img3.png";
import img4 from "../../../../assets/Hpdsx/Section2_Img4.png";
import img5 from "../../../../assets/Hpdsx/Section2_Img5.png";

const cardData = [
  {
    id: 1,
    title: "Getting Started",
    bgColor: "bg-violet-200",
    heading: "My Subject Choice",
    description: "Click to see all the subjects for learning",
    image: img1,
  },
  {
    id: 2,
    title: "Tutorial",
    bgColor: "bg-teal-200",
    heading: "My Skill Choice",
    description: "Click here to learn skill and Compete",
    image: img2,
  },
  {
    id: 3,
    title: "Thrive",
    bgColor: "bg-emerald-200",
    heading: "My Learning Choice",
    description: "Choose your mode and way of learning",
    image: img3,
  },
  {
    id: 4,
    title: "Tips",
    bgColor: "bg-rose-200",
    heading: "My Career Choice",
    description: "Choose your career guidance mode",
    image: img4,
  },
  {
    id: 5,
    title: "Inspiring Now",
    bgColor: "bg-yellow-200",
    heading: "My Enterprise Choice",
    description: "Choose your further enterprising choice",
    image: img5,
  },
];

export default cardData;
