import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import backgroundImage from '../../../assets/HPDSxMenu/Section9imagebg.png';

const Section9 = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Allow animation to trigger multiple times
    threshold: 0.3, // Adjust as needed
  });

  return (
    <div className="relative">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      
      {/* Content */}
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.8, ease: 'easeInOut', delay: 0.4 }}
        className="relative z-10 flex flex-col items-center justify-center py-8 md:py-4 lg:py-8 xl:py-16"
      >
        {/* Text */}
        <div className='text-white leading-relaxed text-center text-xl md:text-3xl italic mx-8 md:mx-12 lg:mx-20 xl:mx-32' style={{ lineHeight: '140%' }}>
          World is struggling with problems… millions are at <span className='text-yellow-600 font-semibold'>a crossroads in life</span>​ who are affected by <span className='text-yellow-600 font-semibold'>any</span> human potential <span className='text-yellow-600 font-semibold'>crisis</span> and <span className='text-yellow-600 font-semibold'>feeling helpless</span> ​to what can we do to <span className='text-yellow-600 font-semibold'>secure the future</span>
        </div>
      </motion.div>
    </div>
  );
};

export default Section9;
