import React from "react";
import LandingCover from "../../components/Speaker/LandingCover";
import SpeakerMotivate from "../../components/Speaker/SpeakerMotivate";
import SpeakerSection2 from "../../components/Speaker/SpeakerSection2";
import SpeakerSection1 from "../../components/Speaker/SpeakerSection1";
import SpeakerSection3 from "../../components/Speaker/SpeakerSection3";


export default function Scientist() {
  return (
    <div className="flex flex-col">
      <LandingCover />
       <SpeakerMotivate/>
      <SpeakerSection1 />
      <SpeakerSection2 />
       <SpeakerSection3 /> 
      
    </div>
  );
}
