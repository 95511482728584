import BlogImage from "../../../../assets/Blogs/blogartfourth.jpg";
import MobileIcon from "../../../../assets/Blogs/MobileIcon.jpg";
const section4Data = [
  {
    id: 1,
    name: "Avaneet Kaur",
    story: "Hi there! I’m Avaneet Kaur, and let me tell you how blogging has transformed my life. Professionally, it helped me establish myself as an authority in my field. Through consistent writing and sharing my expertise in digital marketing, I gained recognition and landed freelance opportunities with major companies. Personally, blogging became my creative outlet, allowing me to express my thoughts and connect with like-minded individuals. It boosted my confidence and communication skills, enabling me to network effectively. Overall, blogging has not only expanded my professional horizons but also enriched my personal growth journey.",
    img: BlogImage,
    img2: MobileIcon,
    type: "Customer stories",
    slideNumber: "O1"
  },
  {
    id: 2,
    name: "Jitesh Kadiyala",
    story: "Hi there! I’m Avaneet Kaur, and let me tell you how blogging has transformed my life. Professionally, it helped me establish myself as an authority in my field. Through consistent writing and sharing my expertise in digital marketing, I gained recognition and landed freelance opportunities with major companies. Personally, blogging became my creative outlet, allowing me to express my thoughts and connect with like-minded individuals. It boosted my confidence and communication skills, enabling me to network effectively. Overall, blogging has not only expanded my professional horizons but also enriched my personal growth journey.",
    img: BlogImage,
    img2: MobileIcon,
    type: "Customer Review",
    slideNumber: "O2"
  },
  {
    id: 3,
    name: "Piyush Kumar",
    story: "Hi there! I’m Avaneet Kaur, and let me tell you how blogging has transformed my life. Professionally, it helped me establish myself as an authority in my field. Through consistent writing and sharing my expertise in digital marketing, I gained recognition and landed freelance opportunities with major companies. Personally, blogging became my creative outlet, allowing me to express my thoughts and connect with like-minded individuals. It boosted my confidence and communication skills, enabling me to network effectively. Overall, blogging has not only expanded my professional horizons but also enriched my personal growth journey.",
    img: BlogImage,
    img2: MobileIcon,
    type: "My Review",
    slideNumber: "O3"
  },
  {
    id: 4,
    name: "Vaibhav Gupta",
    story: "Hi there! I’m Avaneet Kaur, and let me tell you how blogging has transformed my life. Professionally, it helped me establish myself as an authority in my field. Through consistent writing and sharing my expertise in digital marketing, I gained recognition and landed freelance opportunities with major companies. Personally, blogging became my creative outlet, allowing me to express my thoughts and connect with like-minded individuals. It boosted my confidence and communication skills, enabling me to network effectively. Overall, blogging has not only expanded my professional horizons but also enriched my personal growth journey.",
    img: BlogImage,
    img2: MobileIcon,
    type: "Customer stories",
    slideNumber: "O4"
  }
];

export default section4Data;
