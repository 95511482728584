import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import CoverSideImg from "../../../assets/Competitions/SideCoverImg.png";

const LandingCover = () => {
  return (
    <div className="max-w-screen-xl xl:h-[80vh] lg:h-[70vh] md:h-[60vh] flex bg-white px-6 xl:pl-36 xl:pr-12 md:px-8 md:mx-4">
      <div className="w-full h-full flex flex-col md:flex-row gap-6">
        <div className="flex flex-col flex-1 xl:py-16 lg:py-16 md:py-8 lg:my-16 md:my-6">
          <div className="mb-2 pb-2 font-semibold text-2xl xl:text-4xl md:text-xl lg:text-3xl pt-12 lg:pt-1 md:pt-6 cursor-pointer">
            <div className="mb-1">
              Upskill your knowledge, by competing with your peers
            </div>
          </div>
          <div className="mb-1 font-normal text-stone-900 text-sm xl:text-lg lg:text-md md:text-xs cursor-pointer">
            <div>
              Sharpen your expertise through friendly competition! Join peers 
              in challenging contests to upgrade your skills and knowledge,
              fostering growth and camaraderie.
            </div>
          </div>
          <div className=" mt-4 px-4 py-2 w-fit hover:bg-blue-500 hover:text-white cursor-pointer rounded-full border border-black hover:border-white flex items-center justify-start ">
            <div className="text-xs font-bold ">Browse all Competitions</div>
            <FontAwesomeIcon icon={faChevronRight} className=" ml-2" />
          </div>
        </div>
        <div className="flex-1 flex justify-center items-center">
          <div
            className="w-80 h-56 bg-contain xl:w-[480px] xl:h-[380px] lg:w-[520px] lg:h-[460px] md:w-[380px] md:h-[280px] xl:bg-cover lg:bg-cover md:bg-cover bg-no-repeat mt-10 xl:ml-12 lg:ml-1 md:ml-6 xl:mt-2 md:mt-8"
            style={{ backgroundImage: `url(${CoverSideImg})` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
