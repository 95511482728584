import React from 'react'
import BgImg from "../../assets/team_hero_bg.jpg";
import Grid_Rx4 from '../components/Templates/Grid_Rx4';
import photo1 from '../../assets/photo1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'


const TSITeam = () => {
  const director_board = {
    title: 'Board of Director',
    members: [
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'CEO & Founder of Think skill India',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Project Manager',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Product Designer',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'UI Designer',
      },
    ]
  };
  const advisory_team = {
    title: 'Advisory team',
    members: [
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Ux Designer',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Ux Designer (Intern)',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Front-end Developer',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Front-end Developer',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Ux Designer',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Ux Designer (Intern)',
      },
    ]
  };
  const collaborators = {
    title: 'Collaborators',
    members: [
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'UI Designer',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Software Developers',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Project Manager',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Product Designer',
      },
    ]
  };
  const leadership = {
    title: 'Leadership',
    members: [
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Ux Designer',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Ux Designer (Intern)',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Front-end Developer',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Front-end Developer',
      },
    ]
  };
  const product_team = {
    title: 'Product Team',
    members: [

      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Software Developers',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Project Manager',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'Product Designer',
      },
      {
        img: photo1,
        title: 'Jane Cooper',
        subTtl: 'UI Designer',
      },
    ]
  };
  return (
    <div>


      <div className='py-8 md:py-20 w-full ' style={{ backgroundImage: `url(${BgImg})` }}>
        <div className="text-center text-white lg:w-9/12 w-11/12 font-semibold md:text-4xl text-2xl my-7 mx-auto">Think Skill India Team</div>
        <div className="text-center text-white lg:w-9/12 w-11/12 text-xs md:text-base mx-auto">Welcome to the heart and soul of ThinkSkillInida! Our dynamic and dedicated team is the driving force behind our success, bringing together diverse talents and shared passion to achieve extraordinary results. Explore the profiles of the incredible individuals who make up our team. Each member brings a wealth of experience and a commitment to excellence, contributing to the collaborative spirit that defines us.</div>
      </div>

      <Grid_Rx4 data={director_board} />
      <Grid_Rx4 data={advisory_team} />
      <Grid_Rx4 data={collaborators} />
      <Grid_Rx4 data={leadership} />
      <Grid_Rx4 data={product_team} />

      <div className="bg-neutral-100 md:py-20 py-7 w-full">
        <div className='text-center lg:w-7/12 md:w-9/12 w-11/12 mx-auto'>

          <div className=" font-semibold text-4xl my-7 ">Join Our Think Skill India Family as Team</div>
          <div className="">Join us on our mission as we continue to redefine what's possible. Together, we are more than a team — we are a force for positive change</div>
          <button className='py-1 px-4 rounded-full my-4 border border-black border-solid hover:bg-neutral-100 hover:shadow-md'>Join us Now <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>



    </div>
  )
}

export default TSITeam