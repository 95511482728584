import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Image from "../../../../assets/Blogs/BlogSection3Img.png";

const BlogSection3 = () => {
  return (
    <div className="w-full h-full px-6 py-8 xl:px-28 lg:px-20 md:px-12 xl:pt-20 lg:py-16 md:py-12 bg-slate-100 flex flex-col md:flex-row justify-start items-start gap-6 md:gap-32">
      <div className="w-full md:w-auto flex-col justify-start items-start gap-4 order-2 md:order-none">
        <div className="text-stone-900 text-2xl xl:text-4xl lg:text-3xl md:text-2xl font-semibold mb-8 xl:pt-12 lg:pt-8 md:pt-6 cursor-pointer">
          Join Interactive Discussion Board
        </div>
        <div className="self-stretch text-stone-900 xl:text-lg lg:text-md md:text-xs cursor-pointer">
          Online forums designed for sharing thoughts, seeking advice, and discussing diverse topics
        </div>
        <div className="mt-4 px-4 py-2 w-fit hover:bg-blue-400 hover:text-white cursor-pointer rounded-full border border-black hover:border-white flex items-center justify-start">
          <div className="text-xs font-bold">
            Join Now <span className="text-red-500 ml-1">free!</span>
          </div>
          <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
        </div>
      </div>
      <img
        className="h-full w-full xl:w-[500px] lg:w-[500px] md:w-[330px] xl:h-96 lg:h-[300px] md:h-56 rounded-xl hover:shadow-2xl hover:border-gray-300"
        src={Image}
        alt="Blog Section 3 Image"
      />
    </div>
  );
};

export default BlogSection3;
