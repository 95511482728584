import React from "react";
import LeftSideImage from "../../components/Templates/LeftSideImage";
import img_3 from "../../../assets/LocalActivities/img_3.jpg";

const Section3 = () => {
  const data = {
    title: <>Discover Local Engagement</>,
    description: (
      <>
        We're dedicated to offering a variety of local activities, including
        campaigns, alongside meditation sessions. Whether it's a charity
        fundraiser or a community-driven campaign for change, our mission is to
        introduce you to experiences that resonate with your interests and
        foster connections within your community.
      </>
    ),
    imageUrl: img_3,
  };

  return (
    <div>
      <LeftSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section3;
