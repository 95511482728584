
import React from 'react'

const Grid_Rx4 = ({ data }) => {
  return (
    <div className="flex flex-col items-center justify-center lg:w-8/12 md:w-10/12 w-11/12 mx-auto bg-white">
      <div className="pt-12 items-start text-center mx-auto">
        <div className="font-semibold text-4xl">{data.title}</div>
      </div>
      <div class="grid md:grid-cols-4 grid-cols-2 lg:gap-12 gap-2 md:gap-4 justify-center w-full  my:py-16 py-4">
        {data.members.map(d => (
            <div className=" w-full h-full overflow-visible">
              <div className="flex justify-center rounded-lg">
                <img
                  src={d.img}  // Replace with the actual image URL
                  alt="Profile Image"
                  className=" "
                />
              </div>

              <div className="text-center text-xl mt-5 md:text-base">
                <h3 className="font-semibold">{d.title}</h3>
              </div>

              <div className="text-center mt-1 text-[#1E1E1E]">
                <p className="">{d.subTtl}</p>
              </div>
            </div>
        ))}
      </div>
    </div>
  )
}

export default Grid_Rx4