import img1 from "../../../assets/TalentCollaboratory/PlatformApps/platformApp1.png"
import img2 from "../../../assets/TalentCollaboratory/PlatformApps/platformApp2.png"
import img3 from "../../../assets/TalentCollaboratory/PlatformApps/platformApp3.png"
import img4 from "../../../assets/TalentCollaboratory/PlatformApps/platformApp4.png"
import img5 from "../../../assets/TalentCollaboratory/PlatformApps/platformApp5.png"

import img6 from "../../../assets/TalentCollaboratory/PlatformApps/platformApp6.png"
import img7 from "../../../assets/TalentCollaboratory/PlatformApps/platformApp7.png"

const data = [
  {
    img: img1,
    desc: " Develops team capability in line with business plan and organizational vision.",
    color: "#5C5BB7",
  },
  {
    img: img2,
    desc: "Coaching & developing individuals and teams for high performance",
    color: "#A53C03",
  },
  {
    img: img3,
    desc: "Creates a culture of high performance & people management that is intolerant of poor performance",
    color: "#3C6E56",
  },
  {
    img: img4,
    desc: "Fosters an organisational culture that is positive about change and committed to delivery of the strategic vision",
    color:   "#E71FA9",
  },
  {
    img: img5,
    desc: "Acts as a passionate and inspiring advocate for change",
    color:  "#A53C03",
  },
  {
    img: img6,
    desc: "Aligns functions and resources & deploys people and skills optimally to achieve strategic priorities",
    color: "#3C6E56",
  },
  {
    img: img7,
    desc: "Manage change effectively, identifying resistance, building engagement and involvement, and rewarding innovation",
    color:  "#E71FA9",
  }
 
];

export default data;
