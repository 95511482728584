
import SampleImage1 from "../../../assets/HPDSxEngine/Section5image1.png"
import SampleImage2 from "../../../assets/HPDSxEngine/Section5image2.png"
import SampleImage3 from "../../../assets/HPDSxEngine/Section5image3.png"

const dataset = [
  {
    id: 1,
    title: <>Talent Collaboratory Hub</>,
    description: <>HPDS Collaboratory enables learning journeys for talent preparation to participate in communities and workplaces.</>,
    image: SampleImage1 
  },
  {
    id: 2,
    title: <>Personalized Learning Development</>,
    description: "Personalize development for skills, roles, and goals, make learning powerful relevant and connected by meeting youth.",
    image: SampleImage2 
  },
  {
    id: 3,
    title: <>Passion-Driven Skills</>,
    description: "Empowering to explore and engage with passions to weave learning into Data-driven skills intelligence in collective intelligence systems.",
    image: SampleImage3 
  },
];

export default dataset;
