import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img2 from "../../../assets/MainMenu/gridImage1.png"
import img3 from "../../../assets/MainMenu/gridImage2.png"
import img4 from "../../../assets/MainMenu/gridImage3.png"
import img5 from "../../../assets/MainMenu/gridImage4.png"
import img6 from "../../../assets/MainMenu/gridImage5.png"
import img7 from "../../../assets/MainMenu/gridImage6.png"
import img8 from "../../../assets/MainMenu/gridImage7.png"
import img9 from "../../../assets/MainMenu/gridImage8.png"
import img10 from "../../../assets/MainMenu/gridImage9.png"
import img11 from "../../../assets/MainMenu/gridImage10.png"
const Section19 = () => {
  const backgroundColor = '#0086E8';
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.17 
  });

  return (
    <motion.section
      ref={ref}
      className="bg-blue-500 text-white py-12 md:py-24"
      style={{ background: backgroundColor }}
      initial={{ opacity: 0, y: 80 }} // Initial state (not in view)
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }} // Animate when in view
      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }} // Transition with ease-in-out
    >
      <div className=" ">
        <div className=" mb-8">
          <p className="text-2xl md:text-4xl font-bold px-4 md:px-12 lg:px-28" style={{ lineHeight: '140%' }}>ThinkSkillIndia platform is the <span className='text-yellow-400'>disruption driver</span> creating the foundation for <span className='text-yellow-400'>transforming schools </span>and universities into a high-tech industry.</p>
        </div>
       <div className="grid grid-cols-1 md:grid-cols-5 gap-4 px-4 md:px-12 lg:px-28 pt-10 text-sm  text-black">
        {/* First row */}
 
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-2 lg:p-4 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-blue-200">
          Outstanding User Experience
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-2 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-pink-200 md:mt-20">
          Development of Personal, Professional, Career and Enterprise Skills
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-2 lg:p-4 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-amber-200">
          Access to Learning, Facilitation and content
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-2 lg:p-4 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-green-200  md:mt-20">
          Focus on New Research
        </div>
        <div className="col-span-1  text-md md:text-lg font-semibold md:p-2 lg:p-4 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-red-200">
          Global Partnership
        </div>
 
        {/* Second row */}
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img2}
          />
        </div>
        <div className="col-span-1 border-2  rounded  shadow text-center h-56 bg-gray-200">
          <img
            className="w-full h-full object-cover"
            src={img3}
          />
        </div>
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img4}
          />
        </div>
        <div className="col-span-1 border-2  rounded  shadow text-center h-56 bg-gray-200">
          <img
            className="w-full h-full object-cover"
            src={img5}
          />
        </div>
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img6}
          />
        </div>
        {/* Third row */}
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-2 lg:p-4 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-lime-200  md:-mt-20">
          Generating and Applying new models and mindset
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-2 lg:p-4 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-sky-200">
          Creative approaches for 21st Century Skills
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-2 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-cyan-200  md:-mt-20">
          Competence development, Intervention and Consultation
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-2 lg:p-4 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-orange-200">
          Social Responsibility
        </div>
        <div className="col-span-1 text-md md:text-lg font-semibold  md:p-2 lg:p-4 rounded shadow text-center md:h-32 lg:h-32 xl:h-32 bg-pink-200  md:-mt-20">
          Global Impact
        </div>
        {/* Fourth row */}
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img7}          />
        </div>
        <div className="col-span-1 border-2  rounded  shadow text-center h-56 bg-gray-200">
          <img
            className="w-full h-full object-cover"
            src={img8}          />
        </div>
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img9}          />
        </div>
        <div className="col-span-1 border-2  rounded  shadow text-center h-56 bg-gray-200">
          <img
            className="w-full h-full object-cover"
            src={img10}          />
        </div>
        <div className="col-span-1  border-2  rounded shadow text-center h-56 bg-blue-200  md:-mt-20">
          <img
            className="w-full h-full object-cover"
            src={img11}          />
        </div>
      </div>
      </div>
    </motion.section>
  );
};

export default Section19;
