import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img1 from '../../../assets/MainMenu/Section7image1.png';
import img2 from '../../../assets/MainMenu/Section7image2.png';
import img3 from '../../../assets/MainMenu/Section7image3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';

const Section7 = () => {
    // Use useInView to detect when the section is in view
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.25,
    });

    return (
        <motion.section
            initial={{ opacity: 0, y: 80 }} // Initial animation state (section sliding from bottom)
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }} // Trigger animation when section is in view
            transition={{ ease: 'easeInOut', duration: 0.7, delay: 0.2 }} // Animation transition properties
            className="w-full py-12 px-4 md:px-12 lg:px-20 xl:px-28"
            ref={ref} // Assign the ref obtained from useInView
        >
            <div className="max-w-screen">
                <div className="text-center mb-8">
                    <h2 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">Purpose of THINKSKILLINDIA Platform</h2>
                </div>
                <div className="grid grid-cols-12 gap-4">
                    {/* Card 1 */}
                    <motion.div
                        initial={{ opacity: 0, x: -30, y: -40 }} // Initial animation state (Card 1 sliding from top left)
                        animate={inView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x: -30, y: -40 }} // Trigger animation when section is in view
                        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Animation transition properties
                        className="col-span-12 md:col-span-4"
                    >
                        <div className="bg-[#C9FFF7] p-4 md:p-3 lg:p-6 rounded-3xl shadow pt-12 pb-20">
                            <h3 className="md:text-3xl lg:text-4xl font-bold mb-6 text-center">Better Skills</h3>
                            <img src={img1} alt="Card 1" className="w-full mb-6 rounded" />
                            <ul className="list-none space-y-2 pl-0 text-lg md:text-lg lg:text-xl">
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />Sectoral Strategy</li>
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />Establishing the function</li>
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />Support Human Resources</li>
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />Multi-disciplinary Activities</li>
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />Building a global team</li>
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />Skills instruments</li>
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />Promotes Youth Employment</li>
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />Skill Transparency and Information</li>
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />Create High Level Skill Ecosystem</li>
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />Foster Entrepreneurship</li>
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />Analytical Potential</li>
                                <li><FontAwesomeIcon icon={faSquare} className="text-[#00D2B4] mr-2" />National Skill Policies</li>
                            </ul>
                        </div>
                    </motion.div>
                    {/* Card 2 */}
                    <motion.div
                        initial={{ opacity: 0, y: 40 }} // Initial animation state (Card 2 sliding from bottom)
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 40 }} // Trigger animation when section is in view
                        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Animation transition properties
                        className="col-span-12 md:col-span-4"
                    >
                        <div className="bg-[#DDE1FF] p-4 md:p-3 lg:p-6 rounded-3xl shadow pt-12 pb-20">
                            <h3 className="md:text-3xl lg:text-4xl font-bold mb-6 text-center">Better Jobs</h3>
                            <img src={img2} alt="Card 2" className="w-full mb-6 rounded" />
                            <ul className="list-none space-y-2 pl-0 text-lg md:text-lg lg:text-xl">
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#384AEA]" />Making the work more systematic</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#384AEA]" />Core Specialist Skills that learner to learn</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#384AEA]" />Building excellence in management</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#384AEA]" />Building Environment, safety and multi-disciplinary programmes</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#384AEA]" />Creating transparency</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#384AEA]" />Creating Ratings</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#384AEA]" />High Value-added jobs</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#384AEA]" />Skills for competitiveness</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#384AEA]" />Lifelong Skills strategy</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#384AEA]" />Indicators of skills</li>
                            </ul>
                        </div>
                    </motion.div>
                    {/* Card 3 */}
                    <motion.div
                        initial={{ opacity: 0, x: 30, y: -40 }} // Initial animation state (Card 3 sliding from top right)
                        animate={inView ? { opacity: 1, x: 0, y: 0 } : { opacity: 0, x: 30, y: -40 }} // Trigger animation when section is in view
                        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Animation transition properties
                        className="col-span-12 md:col-span-4"
                    >
                        <div className="bg-[#FFCFDD] p-4 md:p-3 lg:p-6 rounded-3xl shadow pt-12 pb-20">
                            <h3 className="md:text-3xl lg:text-4xl font-bold mb-6 text-center">Better Lives</h3>
                            <img src={img3} alt="Card 3" className="w-full mb-6 rounded" />
                            <ul className="list-none space-y-2 pl-0 text-lg md:text-lg lg:text-xl">
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#E00043]" />Focus on building excellence in Professional Practice Framework</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#E00043]" />Develop multi disciplinary experience</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#E00043]" />Developing a Sustainable sourcing strategy</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#E00043]" />Improve the interfaces between people seeking a route into industry</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#E00043]" />Transforming Performance</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#E00043]" />Responsibility and ownership of Skills</li>
                                <li><FontAwesomeIcon icon={faSquare} className="mr-2 text-[#E00043]" />Building New Industry value Chain</li>
                            </ul>
                        </div>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    );
};

export default Section7;
