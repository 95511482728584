import React from 'react';

import Section1image from '../../../assets/Focus/Section4image1.png';

const Section4 = () => {
  return (
    <div className='w-full'>
      <div className='max-w-screen'>
    <div className=" md:h-[100vh] lg:h-[116vh]  xl:h-[144vh] w-full flex justify-center items-center bg-white font-semibold ">
      <div className="leading-relaxed text-black px-4 md:px-4 lg:px-12 xl:px-20 ">
        
        <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl mt-8 md:mt-12 lg:mt-16 xl:mt-20   md:px-4 lg:px-12 xl:px-20 text-green-500 font-semibold leading-loose text-justify" style={{ lineHeight: '140%' }}>When <span className='text-red-500'>women feel confident</span> about <span className='text-red-500'>their bodies, their worthiness</span>, and <span className='text-red-500'>their intelligence</span>, they <span className='text-red-500'>can go</span> into the world and <span className='text-red-500'>express themselves</span> without intimidation. We believe there’s no better time than now.
</div>
        
        <img src={Section1image} alt="" className="mt-8 " />
        
      </div>
    </div>
    </div>
    </div>
  );
};

export default Section4;