import React from 'react';
import backgroundImage from '../../../assets/Enterprise/Section2imagebg.png'; // Import the background image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import icon from "../../../assets/Enterprise/Section2icon.png"
import img from "../../../assets/Support/Section3image.png"

const Section3 = () => {
    return (
        <section className="relative bg-cover bg-center py-24" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="container px-24 text-black">

                <h1 className="text-5xl text-center font-bold px-24 mb-8 text-blue-400"style={{ lineHeight: '140%' }}>New Member’s and Organisation</h1>

                <div className="grid grid-cols-12 gap-8 pt-20">
                    {/* Image on the left */}
                    <div className="col-span-3">
                        <img src={img} alt="Image" className="w-full h-auto" />
                    </div>
                    {/* Cards on the right */}
                    <div className="col-span-9">
                        <div className="grid grid-cols-2 gap-8">
                            {/* Card 1 */}
                            <div className="bg-white rounded-lg p-4 flex flex-col justify-between ">
                                <img src={icon} alt="Card 1 Image" className="w-16 h-16 mb-4" />
                                <h3 className="text-xl font-semibold mb-2"style={{ lineHeight: '140%' }}>Unions, Industry and Trades</h3>
                                <div className="flex items-center justify-between w-full pt-8">
                                    
                                <button className="text-blue-500 hover:text-blue-700 focus:outline-none">
                                        <span className='mr-4'><button className="text-blue-500 hover:text-blue-700 focus:outline-none"><FontAwesomeIcon icon={faArrowRight} className='bg-blue-700 text-white rounded-lg p-[4px] '/></button></span>
                                        Join Now
                                    </button>
                                    <a href="#" className="text-black underline hover:text-blue-700">Learn More</a>
                                </div>
                            </div>

                            {/* Card 2 */}
                            <div className="bg-white rounded-lg p-4 flex flex-col justify-between ">
                            <img src={icon} alt="Card 2 Image" className="w-16 h-16 mb-4" />
                                <h3 className="text-xl font-semibold mb-2"style={{ lineHeight: '140%' }}>Workforce Development, New Employees</h3>
                                <div className="flex items-center justify-between w-full pt-8">
                                    
                                <button className="text-blue-500 hover:text-blue-700 focus:outline-none">
                                        <span className='mr-4'><button className="text-blue-500 hover:text-blue-700 focus:outline-none"><FontAwesomeIcon icon={faArrowRight} className='bg-blue-700 text-white rounded-lg p-[4px] '/></button></span>
                                        Join Now
                                    </button>
                                    <a href="#" className="text-black underline hover:text-blue-700">Learn More</a>
                                </div>
                            </div>

                            {/* Card 3 */}
                            <div className="bg-white rounded-lg p-4 flex flex-col justify-between ">
                            <img src={icon} alt="Card 3 Image" className="w-16 h-16 mb-4" />
                                <h3 className="text-xl font-semibold mb-2"style={{ lineHeight: '140%' }}>​Unemployed and Underemployed Individuals</h3>
                                <div className="flex items-center justify-between w-full pt-8">
                                    
                                    <button className="text-blue-500 hover:text-blue-700 focus:outline-none">
                                        <span className='mr-4'><button className="text-blue-500 hover:text-blue-700 focus:outline-none"><FontAwesomeIcon icon={faArrowRight} className='bg-blue-700 text-white rounded-lg p-[4px] '/></button></span>
                                        Join Now
                                    </button>
                                    <a href="#" className="text-black underline hover:text-blue-700">Learn More</a>
                                </div>
                            </div>

                            {/* Card 4 */}
                            <div className="bg-white rounded-lg p-4 flex flex-col justify-between ">
                            <img src={icon} alt="Card 4 Image" className="w-16 h-16 mb-4" />
                                <h3 className="text-xl font-semibold mb-2"style={{ lineHeight: '140%' }}>Youth and Students</h3>
                                <div className="flex items-center justify-between w-full pt-8">
                                    
                                <button className="text-blue-500 hover:text-blue-700 focus:outline-none">
                                        <span className='mr-4'><button className="text-blue-500 hover:text-blue-700 focus:outline-none"><FontAwesomeIcon icon={faArrowRight} className='bg-blue-700 text-white rounded-lg p-[4px] '/></button></span>
                                        Join Now
                                    </button>
                                    <a href="#" className="text-black underline hover:text-blue-700">Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section3;
