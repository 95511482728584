import Section4Image1 from "../../../assets/HPDSxEngine/Section9image1.png";
import Section4Image2 from "../../../assets/HPDSxEngine/Section9image2.png";
import Section4Image3 from "../../../assets/HPDSxEngine/Section9image3.png";

import b1 from "./newImages/b1.png";
import b2 from "./newImages/b2.png";
import b3 from "./newImages/b3.png";
import b4 from "./newImages/b4.png";
import b5 from "./newImages/b5.png";

const DatasetSec4 = {
  buttons: [
    {
      id: 1,
      heading: "Setting Frameworks",
      description:
        "Develop appropriate evaluation processes and to act as knowledge hubs to inform pedagogy, training, policy development and project delivery of HPDSx framework.",
      image: b1,
    },
    {
      id: 2,
      heading: "Building Architecture",
      description:
        "To establish a HPDSx platform for the skill development agenda for a collaborative architecture to capture, coordinate and enhance existing effort with educational institutions, Industry bodies, Sector Specific Skill Development Bodies, Govt. Bodies, Not For Profit Organisations, Universities in India and Other Countries.",
      image: b2,
    },
    {
      id: 3,
      heading: "Lifelong Learning",
      description:
        "To stimulate in our students 21st century skills set a lifelong thirst for knowledge and learning and to encourage a pioneering, innovative and independent attitude and an aspiration to achieve success within and beyond the Education and Learning to upward mobility in the new economy.",
      image: b3,
    },
    {
      id: 4,
      heading: "Innovation Hub",
      description:
        "To foster a vibrant, successful and Interactive Skill Development Research Community that generates ideas and discoveries, creates new fields of knowledge and makes a difference to the societal, cultural, environmental, health and wealth development of India and global communities",
      image: b4,
    },
    {
      id: 5,
      heading: "Bigger Perspective",
      description:
        "To ensure our knowledge, ideas, skills and expertise are transformed into advice and opinion, innovation, intellectual property, enterprise and wealth, thereby realizing national and international objectives and enriching Govt, Business Organisations, Educational Institutions and by large to Society.",
      image: b5,
    },
  ],
};

export default DatasetSec4;
