import React from "react";
import Section1 from "../../components/Carrer/Section1";
import Section2 from "../../components/Carrer/Section2";
import Section3 from "../../components/Carrer/Section3";
import Section4 from "../../components/Carrer/Section4";
import Section5 from "../../components/Carrer/Section5";
import Section6 from "../../components/Carrer/Section6";
import Section7 from "../../components/Carrer/Section7";
import Section8 from "../../components/Carrer/Section8";
import Section9 from "../../components/Carrer/Section9";
import Section10 from "../../components/Carrer/Section10";

import Section11 from "../../components/Carrer/Section11";


 const Carrer = () => {
    return (
        <div className="flex flex-col font-poppins">
            <Section1/>
            <Section2/>
            <Section3/>
            <Section4/>
            <Section5/>
            <Section6/>
            <Section7/>
            <Section8 /> 
            <Section9 />
            <Section10/>
            <Section11/>
    </div>
  )
}

export default Carrer

