import React from 'react';
import icon1 from "../../../assets/SkillCollaboratory/Section8image1.png";
import icon2 from "../../../assets/SkillCollaboratory/Section8image2.png";
import cardimg from "../../../assets/SkillCollaboratory/Section8image.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCirclePlay, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
// Import background image
import backgroundImage from '../../../assets/SkillCollaboratory/Section8imagebg.png';

const Section8 = () => {

    const handleInputChange = (e) => {
        console.log("Button pressed");
    };
    return (
        <section className="py-8 xl:px-24 lg:px-12 md:px-8 px-4">
            <div className=" ">
                <h2 className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mb-4 text-center " >The platform has major component of the drive to boost competitiveness, productivity and innovation capability of students</h2>
                <div className="text-left mb-8 pt-12">
                    <p className="text-lg md:text-xl lg:text-2xl text-justify">Platform enables STUDENTS need to fundamental change to become academic level life-long learning and development providers for students as well as its alumnus.</p>
                    <p className="text-lg md:text-xl lg:text-2xl mb-4 md:mb-6 lg:mb-12 text-justify">Articulated sequences of rigorous academic and career courses, which commences with the 1st semester students and leading to 6 industry-recognized certificates at the end of the program</p>
                </div>
                <div className="relative" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="absolute inset-0 bg-opacity-75 "></div> {/* Dark overlay */}
                    <div className="relative z-10">
                        <h3 className="text-2xl font-bold text-white mb-4">First Grid</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-4 mb-2">
                            {/* First Grid - First Card */}
                            <div className="col-span-1  text-white p-4" style={{ backgroundColor: 'rgb(91, 70, 255)' }}>
                                <div className='flex flex-col xl:p-4'>
                                    <div>
                                        <img src={icon1} className="mb-4" />
                                    </div>

                                    <div className='md:text-lg lg:text-xl font-bold mb-4'>
                                        Employability Skills Certification
                                    </div>

                                    <div className='text-sm lg:text-md font-medium '>
                                        Employability Skills Certification Summary describes the Employability Skills requirements at a qualification level. These summaries are the minimum, mandatory Employability Skills requirement for accredited courses and may be developed at any stage of the course development process.
                                    </div>
                                </div>
                            </div>
                            {/* First Grid - Second Card */}
                            <div className="col-span md:col-span-2  text-white order-2 lg:order-1">
                                <img src={cardimg} className='h-full' />
                            </div>

                            <div className="col-span-1   p-4 order-1 lg:order-2" style={{ backgroundColor: 'rgb(239, 242, 251)' }}>
                                <div className='flex flex-col xl:p-4'>
                                    <div>
                                        <img src={icon1} className="mb-4" />
                                    </div>

                                    <div className='md:text-lg lg:text-xl font-bold mb-4'>
                                        Employability Profiling Certifications
                                    </div>

                                    <div className='text-sm lg:text-md font-medium '>
                                        Employability Profiling Certifications have been created which indicate the skills that typically can be developed through the study or particular subjects. The profiles are for use by institution/ individuals/ organisations in support of their promotion of employability skills development, to the benefit of current and prospective students and employers alike. A student may then map these examples against the list of qualities and attributes typically sought by employers, so enabling the learning experiences to be translated into language helpful to employers.
                                    </div>
                                </div>
                            </div>
                            {/* First Grid - Image */}

                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-4">
                            <div className="col-span-1  text-white p-4" style={{ backgroundColor: 'rgb(91, 70, 255)' }}>
                                <div className='flex flex-col xl:p-4'>
                                    <div>
                                        <img src={icon1} className="mb-4" />
                                    </div>

                                    <div className='md:text-lg lg:text-xl font-bold mb-4'>
                                        Career Readiness Certificate
                                    </div>

                                    <div className='text-sm lg:text-md font-medium '>
                                        Career Readiness Certificate is a portable credential that confirms to employers that an individual possesses the basic workplace skills required for common job.
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1   p-4" style={{ backgroundColor: 'rgb(239, 242, 251)' }}>
                                <div className='flex flex-col xl:p-4'>
                                    <div>
                                        <img src={icon1} className="mb-4" />
                                    </div>

                                    <div className='md:text-lg lg:text-xl font-bold mb-4'>
                                        Workplace Readiness Certification
                                    </div>

                                    <div className='text-sm lg:text-md font-medium '>
                                        Workplace Readiness Certification is to help people transition smoothly to work, retain employment, and successfully cope with work and family issues. It covers tackling the tough Skills about people; about whom they are and where they want to go; about recognizing strengths and realizing potential.
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1  text-white p-4" style={{ backgroundColor: 'rgb(91, 70, 255)' }}>
                                <div className='flex flex-col xl:p-4'>
                                    <div>
                                        <img src={icon1} className="mb-4" />
                                    </div>

                                    <div className='md:text-lg lg:text-xl font-bold mb-4'>
                                        Professional Skill Certification
                                    </div>

                                    <div className='text-sm lg:text-md font-medium '>
                                        Professional Skill Certification with professional skills is perceived to be an essential component of ‘Graduateness’. This is especially true of professional degrees, which provide a gateway to professional practice and are accredited by professional societies. The professional skills required include: determining lines of enquiry, collaborative team working, project planning and management, communication, presentation and leadership.
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1  p-4" style={{ backgroundColor: 'rgb(239, 242, 251)' }}>
                                <div className='flex flex-col xl:p-4'>
                                    <div>
                                        <img src={icon1} className="mb-4" />
                                    </div>

                                    <div className='md:text-lg lg:text-xl font-bold mb-4'>
                                        Career Management Certification
                                    </div>

                                    <div className='text-sm lg:text-md font-medium '>
                                        Career Management Certification is respond to the current market and help students develop and manage their careers. Students are ultimately responsible for identifying and securing their own professional career based upon their individual needs, desires, and fit within a corporate culture.
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className=" lg:mt-6 md:mt-4 mt-2 flex flex-col justify-center items-center">
                    <div className="xl:py-4 lg:py-3 py-2 font-semibold">

                        <button
                            className="text-white bg-blue-500 px-6 py-3  xl:text-lg lg:text-base md:text-sm text-xs rounded-full hover:border hover:border-blue-500 hover:text-blue-500 hover:bg-transparent"
                            type="submit"
                            onClick={handleInputChange}
                        >
                            Get Started Now
                            <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
                        </button>
                    </div>
                    <div className="text-xs text-center">Get started in less than 60 Seconds • Cancel anytime</div>
                </div>
                {/* Background Image */}
            </div>
        
        </section >
    );
};

export default Section8;
