import React from 'react'
import S1 from '../../assets/createEvent_img.png'
import S2 from '../../assets/createEvent_img2.jpg'
import S3 from '../../assets/createEvent_img1.png'
import sm_img1 from '../../assets/create_event_sm_img1.png'
import sm_img2 from '../../assets/create_event_sm_img2.png'
import sm_img3 from '../../assets/create_event_sm_img3.png'
import sm_img4 from '../../assets/create_event_sm_img4.png'
import SlideWithImage from "../components/Templates/SlideWithImage"
import HeroSection from "../components/CreateEvents/HeroSection"
import FlexSlide from '../components/CreateEvents/FlexSlide'

const CreateEvents = () => {

  const slideInfo1={
    img:S1,
    title: <div className=''>Take advantage of our Seamless Event Planning</div>,
    subTit: <div className="font-normal">From seamless online arrangements to seamless offline experiences, we cater to all your event needs. Embrace flexibility with our support for last-minute rescheduling, ensuring your event's success, no matter the changes</div>,
  };

  const slideInfo2={
    img:S2,
    title: 'Expert Alliances: Co-Hosting Events with Experts.',
    subTit: <div className="font-normal">Adding a co-host, you can significantly enrich your event's value, offering a diverse experience that resonates more profoundly with your audience</div>,
  };

  const slideInfo3={
    img:S3,
    title: <div className='font-normal'><strong className='font-semibold'>Simplifying Your Event's </strong> Volunteering Needs</div>,
    subTit: <div className="font-normal ">Leverage an eager and available audience keen on volunteering allowing you to focus on the heart of the event.</div>,
  };

  const slideInfo4={    
    title: <div className='font-normal'>Publishing your <strong className='font-semibold'>Events </strong>just takes few steps</div>,
    data: [
      {img: sm_img1, title: <div className='text-base text-black'>Register yourself as Speaker</div>},
      {img: sm_img2, title: <div className='text-base text-black'>Provide details of your Events</div>},
      {img: sm_img3, title: <div className='text-base text-black'>Invite other Contributor/Co-host</div>},
      {img: sm_img4, title: <div className='text-base text-black'>Publish your Events</div>},
    ]
  };

  
  return (
    <div>
      <HeroSection/>
      <SlideWithImage slideInfo={slideInfo1} contStyle ={`bg-white  md:py-20 py-7`}/>
      <SlideWithImage slideInfo={slideInfo2} contStyle ={`text-white  md:py-20 py-7 flex-row-reverse create-event-bg-img`}/>
      <FlexSlide slideInfo={slideInfo4} contStyle ={`create-event-bg-img1`}/>
      <SlideWithImage slideInfo={slideInfo3} contStyle ={`bg-white  md:py-20 py-7 flex-row-reverse`}/>
    </div>
  )
}

export default CreateEvents