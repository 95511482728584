import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import CoverImg from "../../../assets/SME/StudentCoverImg.png";

export default function LandingCover() {
  return (
    <div
      className={
        "px-6 py-8 xl:px-28 lg:px-20 md:px-12 xl:pt-24 lg:py-16 md:py-12 flex flex-col items-center justify-center gap-4"
      }
    >
      <div className="text-stone-900 text-center xl:text-3xl lg:text-3xl md:text-2xl text-lg font-semibold cursor-pointer">
        Think Skill India for <b>Students</b>
      </div>
      <div className="text-xs mb-6 text-center text-stone-900 xl:text-lg  lg:text-sm md:text-xs cursor-pointer">
        To create an impact on various types of people, society, industry,
        economy, and the future <br/> to transform their careers, influence the
        world, elevate their businesses, and shape their futures as <br/> Subject
        Matter Experts on the Think Skill India Platform.
      </div>
      <div className=" md:ml-0 mt-3 mb-6 px-4 py-2 w-fit hover:bg-black hover:text-neutral-100 cursor-pointer rounded-full border border-black hover:border-neutral-100 flex items-center justify-start ">
        <div className="text-xs font-bold  ">Get Started</div>
        <FontAwesomeIcon icon={faChevronRight} className=" ml-2" />
      </div>

      <img className="w-96 md:w-full md:h-full" src={CoverImg} />
    </div>
  );
}
