import React, { useState } from 'react';
import img1 from "../../../assets/SkillCollaboratory/Section6image1.png"

// Sample dataset for buttons
const buttonsDataset = [
  {
    title: 'Button 1',
    heading: 'Academic and Acquired skills',
    description: 'Aligned with academic and relevant technical knowledge and skills needed for further education and for careers in current or emerging professions with competency-based learning that contributes to academic knowledge, higher-order reasoning and problem-solving skills, work attitudes, general employability, technical and occupation-specific skills, and knowledge of all aspects of an industry, including entrepreneurship. ',
    image: img1,
  },
  {
    title: 'Button 2',
    heading: 'Individualised Profiling',
    description: 'It provides a ‘one-stop-shop’ to assist students with all of their potentially problematic interactions with the academic, employability and work skill building. An integral part of the development of the activity led process is ensuring that the individuals are given the best opportunities to learn.',
    image: img1,
  },
  {
    title: 'Button 3',
    heading: 'Developmental Learning',
    description: 'MANAGEMYSKILL  for 21  Century Skills  advocates for the 21st century readiness of every Indian students providing variety in learning activities, offering opportunities for students to learn at their own pace, encouraging collaborative work, focusing on problem-solving, and encouraging student involvement in assessment.',
    image: img1,
  },
  {
    title: 'Button 4',
    heading: 'Graduate employability skills',
    description: 'HPDSx is equipping learners with the critical, creative thinking, communicative and collaborative skills that enable them to adapt to and thrive in unpredictable situations and scenarios by aligning teaching and learning methods with the skills needed in the future and preparing students for the fourth industrial revolution.',
    image: img1,
  },
  {
    title: 'Button 5',
    heading: 'Career and Workplace specific skills',
    description: 'HPDSx Platform  calls for sequenced programs of study, more effective student planning and greater accountability for results, match between the workforce skills employers’ value and the skills that young people have that are readily transferable to any college or career path.',
    image: img1,
  },
 
];

const Section6 = () => {
  const [selectedButton, setSelectedButton] = useState(buttonsDataset[0]); // Initialize with the first button

  return (
    <section className="py-16 px-4 md:px-8 lg:px-16 xl:px-24">
      <div className="">
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 text-center">Empowering Graduates: The <span className='text-blue-500'> HPDSx Platform's Path to Professional Success</span></h2>
        <p className="text-lg md:text-lg lg:text-xl xl:text-2xl text-gray-700 mb-8 text-center py-4">HPDSx Platform engages, enables, empowers graduates with the skills that are highly regarded by employers and to attain interesting employment, and build their professional career to contribute to the country’s prosperity and social capital.</p>
        
        <div className="grid grid-cols-12 gap-2 lg:gap-4 mb-8">
          {/* Left Column for Button Titles */}
          <div className="col-span-12 md:col-span-3 lg:col-span-2">
            {buttonsDataset.map(button => (
              <div key={button.title} className="mb-4 ">
                <button
                  className={`text-md text-left font-semibold mb-2 px-4 ${selectedButton.title === button.title ? 'text-blue-600 , border-l-4 border-blue-600 ' : ''}`}
                  onClick={() => setSelectedButton(button)}
                >
                  {button.heading}
                </button>
              </div>
            ))}
          </div>

          {/* Middle Column for Button Details */}
          <div className="col-span-12 md:col-span-4 lg:col-span-5">
            <h3 className="md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-2">{selectedButton.heading}</h3>
            <p className="text-gray-700 mb-2 text-justify md:text-md lg:text-lg xl:text-xl">{selectedButton.description}</p>
          </div>

          {/* Right Column for Button Images */}
          <div className="col-span-12 md:col-span-5">
            <img src={selectedButton.image} alt={selectedButton.title} className="w-full h-72" />
          </div>
        </div>

        <p className="text-lg md:text-lg lg:text-xl xl:text-2xl text-gray-700 lg:mb-2 xl:mb-8 text-center xl:py-4 px-4 md:px-12 lg:px-32 xl:px-40 font-semibold">In classrooms, students typically work on simple assignments that emphasize short-term content memorization; they work alone, write for the teacher lone, and rarely make presentations</p>
      </div>
    </section>
  );
};

export default Section6;
