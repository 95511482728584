import React from 'react'

import img2 from "../../../assets/img2.png"
import img3 from '../../../assets/img3.png'
import img4 from '../../../assets/img4.png'
import img5 from '../../../assets/img5.png'
import img6 from '../../../assets/img6.png'
import img7 from '../../../assets/img7.png'
import img8 from '../../../assets/img8.png'
import img9 from '../../../assets/img9.png'

const Interest = () => {
  const interests = [
    { img: img2, text: 'Innovators Hub' },
    { img: img3, text: 'Knowledge Workers' },
    { img: img4, text: 'Enterpreneurs' },
    { img: img5, text: 'Mental Health & Wellness' },
    { img: img6, text: 'Open Source Collaboration' },
    { img: img7, text: 'Practical Education Camp' },
    { img: img8, text: 'Meditation & Yoga' },
    { img: img9, text: 'Global Experts' },
  ];


  return (
    <div className='justify-center lg:mt-20 mt-10'>
      <div className='text-3xl text-center w-11/12 lg:w-6/12 md:w-9/12 m-auto '>
        Engage in Communities Curated to <strong> Suit Your Unique Interests </strong>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 lg:py-14 md:py-10 lg:w-9/12 w-11/12 mx-auto">
        {interests.map(i => (
          <div class="mx-auto overflow-hidden">
            <img class="lg:w-60 md:w-40 mx-auto mt-4 mb-2 rounded-md" src={i.img} alt="Square Image"/>
              <p class="text-xl md:text-sm my-4 font-semibold ">{i.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Interest