import Section2image1 from '../../../assets/HPDSxMenu/Section2image1.png';
import Section2image2 from '../../../assets/HPDSxMenu/Section2image2.png';
import Section2image3 from '../../../assets/HPDSxMenu/Section2image3.png';



const DatasetSec2 = [
    {
      id: 1,
      title: <>Founding <span className='text-blue-500'>Philosophy</span></>,
      description: <>The traditional <span className='text-red-500'>world of collaboration is changing</span>. If you’re ready to <span className='text-yellow-400'>build the future</span>, we have a <span className='text-green-500'>platform to help you</span>.​
     
      </>,
      image: Section2image1
    },
    {
      id: 2,
      title: <><span className='font-bold'>Value</span></>,
      description: <> Strengthening the <span className='text-green-500'>Bridge Between Academic and the Industry</span> Through the HPDSx Platform <span className='text-green-500'>Collaboration Plan Design Model.</span> ​</>,
      image: Section2image2
    },
    {
      id: 3,
      title: <>Guiding <span className='text-blue-500'>Principle</span></>,
      description:<> ​It relates to the needs of your PROSPECTIVE EMPLOYER, INSTITUTION, FACULTY, STUDENTS, PARENTS, ALMUNI, and should consider a wider societal context.</>,
      image: Section2image3
    }
  ];
  
  export default DatasetSec2;
  