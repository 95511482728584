import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar&Footer/Navbar';
import Footer from '../components/Navbar&Footer/Footer';

function ProtectedLayout({handleLogout}) {
  return (
    <div>
        <Navbar handleLogout={handleLogout}/>
        {/* You can include any common UI elements for protected routes here, such as a sidebar or a top bar */}
        <Outlet />  {/* This will render the matched child route */}
        <Footer />
    </div>
  );
}

export default ProtectedLayout;
