import React from 'react'
import { Link } from 'react-router-dom'

const FootContent = () => {
  return (
    <div className="flex md:flex-row flex-col gap-10 md:gap-4 py-8 justify-between text-sm w-full px-12">
      <div className="flex flex-col gap-2 hover-blue-elements">
        <h4 className="font-medium">Membership</h4>
        <Link to="/students">Students</Link>
        <Link to="/subject-matter-experts">Subject matter Experts</Link>
        <Link to="/organizations">Organizations</Link>
        <Link to="/institutions">Educational Institutions</Link>
        <Link to="/institutions">Communities</Link>
        <Link to="/institutions">Societies</Link>
        <Link to="/institutions">Non Profit Organizations</Link>
        <Link to="/counsils">Councils</Link>
      </div>

      <div className="flex flex-col gap-2 hover-blue-elements">
        <h4 className="font-medium">Create Your Content</h4>
        <Link to="/create/program">Make your Own Program</Link>
        <Link to="/create/events">Host your Event</Link>
        <Link to="/create/competitions">Create a Competition</Link>
        <Link to="/start-a-chapter">Start a Chapter</Link>
        <Link to="/create/post">Create a Post</Link>
        <Link to="/community">Build your Community</Link>
      </div>

      <div className="flex flex-col gap-2 hover-blue-elements">
        <h4 className="font-medium">Join As An Expert</h4>
        <Link to="/instructor">Instructor</Link>
        <Link to="/coach">Coach</Link>
        <Link to="/mentor">Mentor</Link>
        <Link to="/guide">Guide</Link>
        <Link to="/counselor">Counselor</Link>
        <Link to="/scientist">HPDS Scientist</Link>
      </div>

      <div className="flex flex-col gap-2 hover-blue-elements">
        <h4 className="font-medium">Products & Platforms</h4>
        <Link to="/edu-collaboratory">Educational Technical Platform</Link>
        <Link to="/talent-collaboratory">Digital Learning Platform</Link>
        <Link to="/transtech-collaboratory">TransTech Platform</Link>
        <Link to="/talent-collaboratory">SME Platform</Link>
        <Link to="/skill-collaboratory">Skill Connect Platform</Link>
        <Link to="/clubs">Student Connect Portal</Link>
        <Link to="/chapters">Community Comerece Platform</Link>
        <Link to="/inter-intra-organization-collaborations">Skill Profile Platform</Link>
        <Link to="/become-a-speaker">Global Academician Platform</Link>
        <Link to="/become-a-volunteer">Assessment Platform</Link>
        <Link to="/become-a-volunteer">Compitions platform</Link>
      </div>

      <div className="flex flex-col gap-2 hover-blue-elements">
        <h4 className="font-medium">Get involved</h4>
        <Link to="/conferences">Conferences</Link>
        <Link to="/workshops">Workshops</Link>
        <Link to="/summits">Summits</Link>
        <Link to="/seminars">Seminars</Link>
        <Link to="/competitions">Competitions</Link>
        <Link to="/clubs">Local Activities</Link>
        <Link to="/chapters">Publications</Link>
        <Link to="/inter-intra-organization-collaborations">Inter & intra Organization Collaborations</Link>
        <Link to="/become-a-speaker">Teacher</Link>
        <Link to="/become-a-volunteer">Subject Matter Expert</Link>
        <Link to="/become-a-speaker">Become a Talk Speaker</Link>
        <Link to="/become-a-volunteer"> Become a Volunteer</Link>
        
       
      </div>

      <div className="flex flex-col gap-2 hover-blue-elements">
        <h4 className="font-medium">Company</h4>
        <Link to="/about-hpds">About HPDS</Link>
        <Link to="/main-menu">About ThinkSkillIndia</Link>
        <Link to="/mission-vision">Mission & Vision</Link>
        <Link to="/change-agent">Be A Change agent</Link>
        <Link to="/team">Team</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/support">Support</Link>
        <Link to="/support">Partners</Link>
        <Link to="/support">Donate</Link>
        <Link to="/career">Career</Link>
        <Link to="/focus">Our Disruptive focus</Link>
        <Link to="/enterprise">Our social enterprise</Link>
        <Link to="/collab">collaborate wit us</Link>
        
      </div>


    </div>
  )
}

export default FootContent