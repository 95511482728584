import React from "react";
import coachSection2Data from "./coachSection2Data";

const CoachSection2 = () => {
  return (
    <div className="w-full h-full px-12 py-2 xl:px-28 xl:py-8 lg:px-24 lg:py-8 md:px-16 md:py-4 bg-white grid grid-cols-3 justify-center items-center gap-6 md:gap-16">
      <div className="col-span-3 text-center text-stone-900 text-xl xl:text-4xl md:text-2xl lg:text-3xl font-semibold font-segoe-ui cursor-pointer">
        Key areas that you'll be impacting
      </div>
      <div className="col-span-3 h-full grid grid-cols-3 gap-8">
        {coachSection2Data.map((item, index) => (
          <div
            key={index}
            className="col-span-3 md:col-span-1 flex-col justify-start items-center md:items-start gap-4 inline-flex"
          >
            <img
              className=" w-full h-full xl:w-96 xl:h-56 lg:w-96 lg:h-56 md:w-64 md:h-36 object-cover rounded-lg hover:shadow-lg"
              src={item.imageUrl}
              alt={`Image ${index + 1}`}
            />
            <div className="text-stone-900 text-xs xl:text-lg lg:text-md md:text-sm font-semibold font-segoe-ui cursor-pointer">
              {item.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoachSection2;
