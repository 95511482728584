import React from 'react';
import backgroundImage from '../../../assets/Council/Section3imagebg.png'; // Import the background image
import img from '../../../assets/Council/Section3image1.png';
import Section4Card11 from "../../../assets/HPDSxEngine/Section4Card11.png"
import Section4Card12 from "../../../assets/HPDSxEngine/Section4Card12.png"
import Section4Card13 from "../../../assets/HPDSxEngine/Section4Card13.png"
import Section4Card14 from "../../../assets/HPDSxEngine/Section4Card14.png"
import Section4Card15 from "../../../assets/HPDSxEngine/Section4Card15.png"

const Section3 = () => {
  return (
    <section className="py-16 xl:py-24 bg-gray-100 px-0 lg:px-16 xl:px-24" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="  ">
        <div className="md:p-2 lg:p-3 xl:p-4 rounded-lg ">
          <div className="grid grid-cols-12 gap-4 xl:gap-8 px-2 xl:px-4 ">
            {/* Left side */}
            <div className="col-span-12 md:col-span-7 lg:col-span-6 ">
              <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-2 mr-4"style={{ lineHeight: '140%' }}>Platform Customised for Council’s Goals</h2>
              <p className="text-sm md:text-md lg:text-lg xl:text-xl text-gray-600 mb-4 mr-4" style={{ lineHeight: '140%' }}>Unveiling the Essence: Navigating 21st Century Philosophy, Individual Mindset, Behaviors, Skills, and Capabilities</p>
              <hr className="border-t border-gray-300 mb-4 py-8 xl:py-12" />
              <div className="grid grid-cols-2 gap-2 xl:gap-4 pr-4">
                {/* Row 1 */}
                <div className="col-span-2 md:col-span-1  flex flex-row ">
                <img src={Section4Card11} alt="Image 1" className="w-16 h-16 rounded-md" />
                  <p className="text-sm xl:text-lg font-semibold mx-2 pt-6 md:pt-0 justify-center items-center">Member Login/Signup Page</p>
                </div>
                <div className="col-span-2 md:col-span-1  flex flex-row">
                <img src={Section4Card12} alt="Image 1" className="w-16 h-16 rounded-md" />
                  <p className="text-sm xl:text-lg font-semibold mx-2 pt-6 md:pt-0 justify-center items-center">Onboarding experience </p>
                </div>
                {/* Row 2 */}
                <div className="col-span-2  md:col-span-1 flex flex-row">
                <img src={Section4Card13} alt="Image 1" className="w-16 h-16 rounded-md" />
                  <p className="text-sm xl:text-lg font-semibold mx-2 pt-6 md:pt-0 justify-center items-center">Member management system </p>
                </div>
                <div className="col-span-2 md:col-span-1  flex flex-row">
                <img src={Section4Card14} alt="Image 1" className="w-16 h-16 rounded-md" />
                  <p className="text-sm xl:text-lg font-semibold mx-2 pt-6 md:pt-0 justify-center items-center">Council Landing Page </p>
                </div>
                {/* Row 3 */}
                <div className="col-span-2 md:col-span-1  flex flex-row">
                <img src={Section4Card15} alt="Image 1" className="w-16 h-16 rounded-md" />
                  <p className="text-sm xl:text-lg font-semibold mx-2 pt-6 md:pt-0 justify-center items-center">Newsletter System </p>
                </div>
                
              </div>
            </div>
            {/* Right side */}
            <div className="col-span-12 md:col-span-5 lg:col-span-6 pr-4">
              <img src={img} alt="Image" className="w-full xl:w-full h-auto rounded-lg" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
