import React from "react";
import right_img from "../../../assets/Communities/img_1.png";
import img_2 from "../../../assets/Communities/img_2.png";

const LandingCover = () => {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };
  return (
    <div className="w-full h-auto grid grid-cols-12 bg-gradient-to-t from-violet-900 to-violet-700 gap-0 p-3 md:p-5 px-4 md:px-10">
      <div className=" px-4 md:px-5  text-white lg:px-16 order-2 md:order-1 col-span-12 md:col-span-6 flex flex-col justify-center items-start cursor-pointer ">
        <div className=" font-bold text-2xl md:text-3xl lg:text-4xl xl:text-6xl leading-tight md:leading-tight lg:leading-tight xl:leading-tight">
          Communities
        </div>
        <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl  py-5 font-normal">
          As the world's largest Transformative and Future Driven Organisation,
          HPDS offers many ways to get involved with technical and local
          communities.
        </div>
        <div className="text-xs lg:text-lg xl:text-xl md:text-sm py-5 ">
          <button
            className="text-white bg-violet-500 px-8 py-3 rounded-md hover:border-2  hover:bg-transparent"
            type="submit"
            onClick={handleInputChange}
          >
            Explore communities
          </button>
        </div>
        <div className="flex flex-row justify-stretch items-center text-xs lg:text-sm xl:text-sm md:text-sm ">
          <img src={img_2} alt="blank" className="w-2/5 lg:w-3/5 " />
          <div className=" pl-3 pr-2">25+ </div>
          <div className=""> Communities </div>
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-12 md:col-span-6 flex flex-col justify-center items-center py-10 xl:py-12 ">
        <div className="w-full md:w-4/5 ">
          <img src={right_img} alt="blank" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
