import React from "react";
import item_1 from "../../../assets/Socities/item_1.jpg";
import item_2 from "../../../assets/Socities/item_2.jpg";
import item_3 from "../../../assets/Socities/item_3.jpg";

function Section4() {
  const data = {
    topic1: (
      <>
        Organize Environmental<br></br> Conservation Movements Together
      </>
    ),
    items: [
      {
        img: item_1,
        name: "Recycling Program",
      },
      {
        img: item_2,
        name: "Energy Efficiency Measures",
      },
      {
        img: item_3,
        name: "Waste Reduction Strategies",
      },
    ],
  };

  return (
    <div className="w-full px-8 py-10 xl:px-28 xl:py-36 lg:px-20 md:px-12 lg:py-24 md:py-16 bg-gradient-to-tl from-violet-900 to-blue-400 ">
      <p className=" text-xl md:text-3xl lg:text-4xl xl:text-6xl font-semibold text-center text-white mb-6 md:mb-12 xl:mb-20">
        Organize Environmental<br></br>Conservation Movements Together
      </p>

      <div className="grid grid-cols-12 gap-8">
        {data.items.map((item, index) => (
          <div key={index} className="col-span-12 md:col-span-4">
            <div
              className="w-64 md:w-full h-48 xl:h-64 rounded-3xl relative overflow-hidden"
              style={{
                backgroundImage: `url(${item.img})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              {/* Blurry and transparent white background */}
              <div className="absolute bottom-0 w-full p-3 bg-white text-white backdrop-blur-sm bg-opacity-0 rounded-3xl">
                <div className="font-bold text-md md:text-xl ">{item.name}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Section4;
