import React from 'react';

import DatasetSec5 from './DatasetSec3';

const Section5 = () => {
  

  return (
    <div
      className="py-12 md:py-36 mb-36 md:mx-2 mx-4 lg:mx-8 xl:mx-20 md:-mt-20"
      
    >
      <div className='text-2xl md:tex-3xl lg:text-4xl xl:text-5xl py-4 text-center font-bold '>Mental <span className='text-blue-500'>Health Hub</span></div>

      <div className='text-lg md:tex-xl lg:text-2xl xl:text-3xl text-center py-2 font-medium'style={{ lineHeight: '140%' }}>The UPMYMIND platform will provide an interactive web portal for youth, family members, clinicians, service providers, researchers, and policy makers to access shared data, analytics, and AI tools for optimizing mental health care.</div>

      {/* Cards */}
      <div className="grid md:grid-cols-7 grid-cols-1 gap-0 py-4">
        {DatasetSec5.map((card, index) => (
          <div key={card.id} className={`col-span-1 px-4 md:px-0 md:text-sm lg:text-md xl:text-lg ${index === 6 ? 'border-r-0' : ''}`} style={{ height: '12rem', '@media (min-width: 768px)': { height: '28rem' } }}>
            <div className={`md:p-4 flex flex-col items-center border-r border-gray-500 ${index % 2 === 1 && index !== 6 ? 'md:py-24 lg:py-40' : ''}`}>
              <p className="text-center md:text-sm lg:text-md xl:text-lg mb-4">{card.description}</p>
              <img src={card.image} alt={`Card ${card.id}`} className="w-24 h-24 md:w-40 md:h-40 object-contain" />
            </div>
          </div>
        ))}
      </div>

      {/* Description */}
      
    </div>
  );
};

export default Section5;