import React from "react";
import localActivityData from "./localActivitiesDataset";
import Grid from "../Templates/Grid";

const Section5 = () => {
  return (
    <div className="py-10">
      <Grid title={localActivityData.topic} data={localActivityData.items} />
    </div>
  );
};

export default Section5;
