import React from "react";
import SlideWithImage from "../components/Templates/SlideWithImage";
import S1 from "../../assets/img10.png";
import S2 from "../../assets/img11.png";
import S3 from "../../assets/img12.png";
import Interest from "../components/Community/Interest";
import ContSlide from "../components/Community/ContSlide";
import HeroSection from "../components/Community/HeroSection";

const Community = () => {
  const slideInfo1 = {
    img: S1,
    title: "Empower Your Growth by being part of the community",
    subTit:
      "Engage in diverse discussions and knowledge sharing, fostering continuous learning and personal development within a supportive community environment",
  };
  const slideInfo2 = {
    img: S2,
    title: "From Finding yourself from a project partner to a Cofounder",
    subTit:
      "Embark on a journey from seeking a project partner to discovering a collaborative cofounder",
  };
  const slideInfo3 = {
    img: S3,
    title: "Resources at your fingertips",
    subTit:
      "Explore an extensive array of readily available resources, conveniently accessible with just a click",
  };
  return (
    <div>
      <HeroSection />
      <Interest />
      <SlideWithImage
        slideInfo={slideInfo1}
        contStyle={`flex-col md:flex-row-reverse bg-slate-100 md:py-20 py-10 `}
      />
      <SlideWithImage
        slideInfo={slideInfo2}
        contStyle={`bg-white md:py-20 py-10`}
      />
      <ContSlide />
      <SlideWithImage
        slideInfo={slideInfo3}
        contStyle={`bg-[#f2f2f2]  md:py-20 py-7 `}
      />
    </div>
  );
};

export default Community;
