import roadmap1 from '../../../../assets/Learn&Grow/roadmap1.png';
import roadmap2 from '../../../../assets/Learn&Grow/roadmap2.png';
import roadmap3 from '../../../../assets/Learn&Grow/roadmap3.png';

const roadmapData = [
    {
    id: 1,
    name: "Innovation",
    bgColor: "bg-emerald-300",
    imageUrl: `${roadmap1}`,
  },
  {
    id: 2,
    name: "Emotional Guidance System",
    bgColor: "bg-green-300",
    imageUrl: `${roadmap2}`,
  },
  {
    id: 3,
    name: "Emotional Guidance System",
    bgColor: "bg-slate-300",
    imageUrl: `${roadmap3}`,
  },
  {
    id: 4,
    name: "Emotional Guidance System",
    bgColor: "bg-red-300",
    imageUrl: `${roadmap1}`,
  },
  {
    id: 5,
    name: "Emotional Guidance System",
    bgColor: "bg-yellow-300",
    imageUrl: `${roadmap2}`,
  },
  {
    id: 6,
    name: "Emotional Guidance System",
    bgColor: "bg-emerald-300",
    imageUrl: `${roadmap3}`,
  },
  {
    id: 7,
    name: "Emotional Guidance System",
    bgColor: "bg-emerald-300",
    imageUrl: `${roadmap1}`,
  },
  {
    id: 8,
    name: "Emotional Guidance System",
    bgColor: "bg-emerald-300",
    imageUrl: `${roadmap2}`,
  },
  {
    id: 9,
    name: "Emotional Guidance System",
    imageUrl: `${roadmap3}`,
  },
  {
    id: 10,
    name: "Emotional Guidance System",
    imageUrl: `${roadmap1}`,
    },
];

export default roadmapData;