import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import CoverSideImg from "../../../assets/SME/CoverImg.png";

const LandingCover = () => {

  return (
    <div className="w-full grid grid-cols-12 gap-0 md:gap-8 xl:gap-20 bg-violet-600 px-0 pt-12 xl:px-20 xl:py-1 lg:px-20 lg:py-8 md:px-12 md:py-4 ">
      <div className="px-3 text-white md:px-0 order-2 md:order-1 col-span-12 md:col-span-4 flex flex-col cursor-pointer">
        <div className="hidden md:block -mt-10 -ml-16">
          <div class="w-24 h-24 bg-white bg-opacity-0 rounded-full border-8 border-white border-opacity-50"></div>
          <div className="w-12 h-12 bg-white bg-opacity-0 rounded-full border-8 border-white border-opacity-50"></div>
        </div>
        <div className="mb-0 md:mb-2 pt-3 xl:pt-16 pb-4 md:pb-1 xl:pb-4 font-semibold text-2xl xl:text-4xl md:text-xl lg:text-3xl">
          Let's become Subject Matter Expert
        </div>
        <div className="text-xs md:text-xs lg:text-md xl:text-lg lg:text-md md:text-sm">
          <div>
            To create an impact on various types of people, society, industry,
            economy, and the future; to transform their careers, influence the
            world, elevate their businesses, and shape their futures as Subject
            Matter Experts on the Think Skill India Platform.
          </div>
        </div>
        <div className=" md:ml-0 mt-4 mb-6 md:my-8 px-4 py-2 w-fit hover:bg-white hover:text-violet-600 cursor-pointer rounded-full border border-white hover:border-violet-600 flex items-center justify-start ">
          <div className="text-xs font-bold  ">Get Started</div>
          <FontAwesomeIcon icon={faChevronRight} className=" ml-2" />
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-12 md:col-span-8 xl:py-16 md:py-16 lg:py-8">
        <div className="w-full h-full">
          <img
            src={CoverSideImg}
            alt="blank"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
