import React from "react";
import platformDataSet from "../DataSet/platformDataset";
import RightImageGrid from "../Templates/RightImageGrid";

const Section4 = () => {
  const data = platformDataSet[15];

  return (
    <div>
      <RightImageGrid
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
        linkColor={data.linkColor}
      />
    </div>
  );
};

export default Section4;
