import image1 from "../../../assets/MainMenu/Section13Image1.png"
import image2 from "../../../assets/MainMenu/Section13Image2.png"
import image3 from "../../../assets/MainMenu/Section13Image3.png"
import image4 from "../../../assets/MainMenu/Section13Image4.png"
import image5 from "../../../assets/MainMenu/Section13Image5.png"
import image6 from "../../../assets/MainMenu/Section13Image6.png"

const DatasetSec4 = {
    buttons: [
        {
            id: 1,
            heading: "Title 1",
            description: "Strengthening Indian education equipping every learner for the 21st Century and pedagogical approaches consistent with the expectations and values of the knowledge society for tomorrow.",
            image: image1
        },
        {
            id: 2,
            heading: "Title 2",
            description: "Providing the blend of content knowledge, specific skills, expertise and literacies necessary to succeed in work and life skills giving emphasis on 21 century learning and beyond.​",
            image: image2
        },
        {
            id: 3,
            heading: "Title 3",
            description: "Supporting economy and nation challenges for skill requirement change in the types of jobs that are needed today, but that young people nowadays also need to be educated for a job that does not yet exist.​",
            image: image3
        },
        {
            id: 4,
            heading: "Title 4",
            description: "Engaging subject matter experts who are required to transfer knowledge, develop behavioral competencies, build skills among technical and non-technical students into knowledge and skilled workers who will be more flexible, analytical, adaptable and multi skilled. ​",
            image: image4
        },
        {
            id: 5,
            heading: "Title 5",
            description: "Enlightening educationalist, institutions, students, industry bodies and policy makers  on real skill  requirement  matching supply to current demand for skills, helping students adjust to change and anticipating and delivering the new and different skills that will be needed in the future. ​",
            image: image5
        },
        {
            id: 6,
            heading: "Title 6",
            description: "Creating a model of new economy and societal framework in career opportunities, living standards and prosperity to country specializing what it does best, productiveness and competitiveness leading to knowledge-based economy and finally skills and globalisation of knowledge in 21st Century ​",
            image: image6
        }
    ]
};

export default DatasetSec4;
