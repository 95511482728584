import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img1 from "../../../assets/MainMenu/Section5image1.png";
import img2 from "../../../assets/MainMenu/Section5image3.png";

const Section5 = () => {
    const backgroundColor = '#0086E8';

    // Use useInView to detect when the section is in view
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.35,
    });

    return (
        <motion.section
            initial={{ opacity: 0, y: 80 }} // Initial animation state (section sliding from bottom)
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }} // Trigger animation when section is in view
            transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }} // Animation transition properties
            className="w-full py-16"
            style={{ background: backgroundColor }}
            ref={ref} // Assign the ref obtained from useInView
        >
            <div className="max-w-screen mx-auto text-white px-4 md:px-12 lg:px-20 xl:px-24">
                <div className="mb-8">
                    <div className="text-xl md:text-xl lg:text-2xl xl:text-3xl text-center font-medium" style={{ lineHeight: '140%' }}>
                        <span className='text-yellow-300'>Fulfillment, Meaning, Belonging, Purpose, Self-Awareness, Self-Knowledge, Connection and the means to find them</span> become increasingly important and valued.
                    </div>
                </div>
                <div className="grid grid-cols-12 gap-3 md:gap-2 lg:gap-4">
                    {/* Card 1 */}
                    <motion.div
                        initial={{ opacity: 0, x: -20 }} // Initial animation state (card sliding from left)
                        animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -20 }} // Trigger animation when section is in view
                        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Animation transition properties
                        className="col-span-12 md:col-span-4"
                    >
                        <div className=" p-6">
                            <img src={img2} alt="Card 1" className="w-full mb-4 rounded" />
                            <div className="text-xl md:text-xl lg:text-2xl xl:text-3xl" style={{ lineHeight: '140%' }}>Millennials focus on purpose and meaning</div>
                        </div>
                    </motion.div>
                    {/* Card 2 */}
                    <motion.div
                        initial={{ opacity: 0, y: 50 }} // Initial animation state (card sliding from bottom)
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }} // Trigger animation when section is in view
                        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Animation transition properties
                        className="col-span-12 md:col-span-4"
                    >
                        <div className="p-6">
                            <img src={img1} alt="Card 2" className="w-full mb-4 rounded" />
                            <div className="text-xl md:text-xl lg:text-2xl xl:text-3xl" style={{ lineHeight: '140%' }}>Technological Unemployment & Power to the Individual.</div>
                        </div>
                    </motion.div>
                    {/* Card 3 */}
                    <motion.div
                        initial={{ opacity: 0, x: 20 }} // Initial animation state (card sliding from right)
                        animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }} // Trigger animation when section is in view
                        transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Animation transition properties
                        className="col-span-12 md:col-span-4"
                    >
                        <div className="p-6">
                            <img src={img2} alt="Card 3" className="w-full mb-4 rounded" />
                            <div className="text-xl md:text-xl lg:text-2xl xl:text-3xl" style={{ lineHeight: '140%' }}>Advances in neuro-behavioural psychology & AI</div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </motion.section>
    );
};

export default Section5;
