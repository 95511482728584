import React from 'react';
import img1 from "../../../assets/Enterprise/Section3image1.png"
import img2 from "../../../assets/Enterprise/Section5image1.png"

const Section3 = () => {
  return (
    <section className="py-12 bg-blue-100">
      <div className="  ">
        <h2 className="text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 px-4 md:px-0 text-center py-12 text-blue-500"style={{ lineHeight: '140%' }}>Unions, Industry and Trades</h2>
        <div className="grid grid-cols-12 gap-2 lg:gap-4  px-4 md:px-12 lg:px-16 xl:px-24 py-4 pb-8">
          {/* Left card */}
          <div className="col-span-12 md:col-span-6">
            <div className="bg-white rounded-lg p-4">
              <img src={img1} alt="Left Image" className="w-full h-auto mb-4" />
              <h3 className="text-xl lg:text-2xl xl:text-3xl font-semibold mb-2 text-green-500"style={{ lineHeight: '140%' }}>Union-Supported Learning</h3>
              <p className="text-md lg:text-lg xl:text-xl "style={{ lineHeight: '140%' }}>THINKSKILLINDIA PLATFORM supports unions and industry by providing a platform that promotes skill development within their sectors. Our system supports social learning, automated enrolments, issuance of certificates, and user management. Through additional programing, such as tutoring, tailored guidance and support to learners, fostering trade, career development, education, and personal growth opportunities.</p>
            </div>
          </div>
          {/* Right card */}
          <div className="col-span-12 md:col-span-6">
            <div className="bg-white rounded-lg p-4">
              <img src={img2} alt="Right Image" className="w-full h-auto mb-4" />
              <h3 className="text-xl lg:text-2xl xl:text-3xl font-semibold mb-2 text-green-500"style={{ lineHeight: '140%' }}>Trade Development Partnership</h3>
              <p className="text-md lg:text-lg xl:text-xl "style={{ lineHeight: '140%' }}>SCC's collaboration with Judith Nyman Secondary School exemplifies its commitment to trade development. Our partnership provides SCC and it's partners access to 17 trade training facilities for skilled trade training and career exploration through such programs as our 'Try a Trade Program</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
