import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import backgroundImage from '../../../assets/ChangeAgent/Section7imagebg.png';
import imageToImport from '../../../assets/ChangeAgent/Section7image1.png';

const Section7 = () => {
    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView) {
            console.log('Section 7 is in view!');
            // You can add additional animations or logic here
        }
    }, [inView]);

    return (
        <motion.section
            className="bg-cover h-[120vh] md:h-[110vh] text-white"
            style={{ backgroundImage: `url(${backgroundImage})` }}
            ref={ref}
            initial={{ opacity: 0, y: 80 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
            transition={{ ease: 'easeInOut', duration: 1.2 , delay:0.5 }}
        >
            <div className='text-center text-3xl md:text-3xl lg:text-4xl xl:text-5xl pt-12 md:pt-12 lg:pt-16 xl:pt-28 pb-8 md:pb-12 font-semibold'> Explore the <span className='text-yellow-400'>HPDSx Engine</span></div>
            <div className="grid grid-cols-12 py-4">
                {/* Left half */}
                <div className="col-span-12 md:col-span-6 flex flex-col justify-center mx-4 md:mx-4 lg:mx-8 xl:mx-16">
                    <h2 className="md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4" style={{ lineHeight: '140%' }}>Get ready for the future of work with <span className='text-yellow-400'>​HPDSx Engine</span></h2>
                    <p className="md:text-lg lg:text-xl" style={{ lineHeight: '140%' }}>Enables learning journeys, career, and prepare and participate in communities and workplaces, automatically personalize development for one’s skills, roles, and goals, make learning more powerful, relevant, and connected by meeting experts where they are and empowering them to explore and engage with their passions, and to weave learning into Data-driven skills intelligence in collective intelligence systems.</p>
                </div>
                {/* Right half */}
                <div className="col-span-12 md:col-span-6 flex justify-center items-center">
                    <img src={imageToImport} alt="Right Image" className="w-full" />
                </div>
            </div>
        </motion.section>
    );
};

export default Section7;
