import Section2Img1 from "../../../assets/Coach/Section2_Img1.jpg";
import Section2Img2 from "../../../assets/Coach/Section2_Img2.jpg";
import Section2Img3 from "../../../assets/Coach/Section2_Img3.jpg";
import Section2Img4 from "../../../assets/Coach/Section2_Img4.jpg";
import Section2Img5 from "../../../assets/Coach/Section2_Img5.jpg";
import Section2Img6 from "../../../assets/Coach/Section2_Img6.jpg";

const coachSection2Data = [
  {
    imageUrl: Section2Img1,
    title: "Designing Your Clients Goal",
  },
  {
    imageUrl: Section2Img2,
    title: "Manifestation of your Clients Dream",
  },
  {
    imageUrl: Section2Img3,
    title: "Helping Clients in the art of storytelling",
  },
  {
    imageUrl: Section2Img4,
    title: "Building Clients Mental Muscle",
  },
  {
    imageUrl: Section2Img5,
    title: "Enhancing your Client Spatial Thinking",
  },
  {
    imageUrl: Section2Img6,
    title: "Shaping Clients Attitude",
  },
];

export default coachSection2Data;
