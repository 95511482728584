import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import imageToImport from '../../../assets/ChangeAgent/Section8image1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalkingLuggage, faCircleChevronRight, faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import CardImage1 from '../../../assets/ChangeAgent/Section8image2.png';
import CardImage2 from '../../../assets/ChangeAgent/Section8image3.png';
import CardImage3 from '../../../assets/ChangeAgent/Section8image4.png';
import CardImage4 from '../../../assets/ChangeAgent/Section8image5.png';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Section8 = () => {
    const controls = useAnimation();
    const { ref, inView } = useInView();
    const [slidesToShow, setSlidesToShow] = useState(4);

    useEffect(() => {
        const updateSlidesToShow = () => {
            const containerWidth = window.innerWidth;
            const newSlidesToShow = containerWidth > 500 ? 4 : 2;
            setSlidesToShow(newSlidesToShow);
        };

        // Call updateSlidesToShow when the window is resized
        window.addEventListener('resize', updateSlidesToShow);

        // Initial call to updateSlidesToShow
        updateSlidesToShow();

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateSlidesToShow);
        };
    }, []);

    useEffect(() => {
        if (inView) {
            controls.start({ opacity: 1, y: 0, transition: { duration: 0.9, ease: 'easeInOut' , delay:0.4 } });
        } else {
            controls.start({ opacity: 0, y: 80 });
        }
    }, [controls, inView]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: slidesToShow
    };

    const sliderRef = useRef();

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    return (
        <motion.section
            ref={ref}
            className="py-8 md:py-12"
            initial={{ opacity: 0, y: 80 }}
            animate={controls}
        >
            <div className=" ">
                <h2 className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 md:mb-8 text-center"><span className='mx-[2px] md:mx-4'><FontAwesomeIcon icon={faPersonWalkingLuggage} size="lg" /></span>Your <span className='' style={{ color: 'rgb(0, 134, 232)' }}>Career</span></h2>
                <div className="grid grid-cols-12 mx-4 md:mx-8 lg:mx-12 xl:mx-20">
                    {/* Left side */}
                    <div className="col-span-12 md:col-span-6 flex flex-col justify-center">
                        <h3 className="md:text-lg lg:text-xl xl:text-2xl  font-medium mb-4"style={{ lineHeight: '140%' }}>The reality of building capacity for the 21st century is that we do not know what the work of the future will be like or how technology will influence health and financial issues for all of us.</h3>
                        <p className="md:text-lg lg:text-xl xl:text-2xl"style={{ lineHeight: '140%' }}><span className='text-blue-500'>Digital Career</span> possibilities keep you <span className='' style={{ color: 'rgb(0, 134, 232)' }}>connected</span> with <span className='' style={{ color: 'rgb(0, 134, 232)' }}>people</span> around the world with a new <span className='' style={{ color: 'rgb(0, 134, 232)' }}>wave of global assignments.</span></p>
                    </div>
                    {/* Right side */}
                    <div className="col-span-12 md:col-span-6 flex justify-center items-center my-4 md:my-0">
                        <img src={imageToImport} alt="Right Image" className="w-full lg:w-4/5 " />
                    </div>
                </div>
                {/* Heading after grid */}
                <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mt-[4px] md:mt-4 xl:mt-8 mx-4 md:mx-8 lg:mx-12 xl:mx-20 py-8 md:py-12">One Qualification, Many Asset Building</h2>



                <div className="md:py-2 lg:py-4 md:px-16 lg:px-20 h-[70vh] mb-20 relative">
                    {/* faCircleChevronLeft icon on the left side of the first card */}
                    <FontAwesomeIcon
                        icon={faCircleChevronLeft}
                        size="2x"
                        className="absolute left-0 md:left-12 lg:left-20 transform -translate-x-full top-1/2 -translate-y-1/2 text-black cursor-pointer hover:text-blue-500"
                        onClick={goToPrev}
                    />

                    {/* faCircleChevronRight icon on the right side of the fourth card */}
                    <FontAwesomeIcon
                        icon={faCircleChevronRight}
                        size="2x"
                        className="absolute  right-80 md:right-20 transform translate-x-full top-1/2 -translate-y-1/2 text-black cursor-pointer hover:text-blue-500"
                        onClick={goToNext}
                    />

                    <Slider {...settings} className='gap-2' ref={sliderRef}>


                        <div>
                            <div className="bg-white border-r-2 border-gray-500 p-3 pb-44">
                                <h3 className="text-xl font-semibold mb-2"></h3>
                                <p className="text-md lg:text-xl text-center lg:px-4 xl:lg:px-8 xl:px-12">Power of
                                    your own fully <span className='' style={{ color: 'rgb(0, 134, 232)' }}>SCALABLE BUSINESS PROGRAMS</span>​</p>
                                <img src={CardImage1} className="w-40 mb-2 mx-auto my-10" />

                            </div>
                        </div>
                        <div>
                            <div className="bg-white  border-r-2 border-gray-500 p-3 py-28 ">
                                <h3 className="text-xl font-semibold mb-2"></h3>
                                <p className="text-md lg:text-xl text-center lg:px-4 xl:px-12">Life skills
                                    assets that <span className='' style={{ color: 'rgb(0, 134, 232)' }}>GENERATE INCOME </span>
                                    for ever​</p>
                                <img src={CardImage2} className="w-40 mb-2 mx-auto my-10" />

                            </div>
                        </div>

                        <div>
                            <div className="bg-white  border-r-2 border-gray-500 p-3 pb-44">
                                <h3 className="text-xl font-semibold mb-2"></h3>
                                <p className="text-md lg:text-xl text-center lg:px-4 xl:px-12">Flexible 
                                <span className='' style={{ color: 'rgb(0, 134, 232)' }}> WORK ARRANGEMENTS </span>
                                     with multiple organisation​</p>
                                <img src={CardImage3} className="w-40 mb-2 mx-auto my-10" />

                            </div>
                        </div>

                        <div>
                            <div className="bg-white  border-r-2 border-gray-500 p-3 py-28">
                                <h3 className="text-xl font-semibold mb-2"></h3>
                                <p className="text-md lg:text-xl text-center lg:px-6 xl:px-14">Marketable <span className='' style={{ color: 'rgb(0, 134, 232)' }}>technical </span>
                                    skills for the 21st century​​</p>
                                <img src={CardImage4} className="w-40 mb-2 mx-auto my-10" />

                            </div>
                        </div>

                        <div>
                            <div className="bg-white  border-r-2 border-gray-500 p-3 pb-44">
                                <h3 className="text-xl font-semibold mb-2"></h3>
                                <p className="text-md lg:text-xl text-center lg:px-4 xl:px-12">Power of
                                    your own fully <span className='' style={{ color: 'rgb(0, 134, 232)' }}>scalable business</span>
                                    programs​</p>
                                <img src={CardImage2} className="w-40 mb-2 mx-auto my-10" />

                            </div>
                        </div>

                        <div>
                            <div className="bg-white  border-r-2 border-gray-500 p-3 py-28">
                                <h3 className="text-xl font-semibold mb-2"></h3>
                                <p className="text-md lg:text-xl text-center lg:px-6 xl:px-14">Marketable <span className='' style={{ color: 'rgb(0, 134, 232)' }}>TECHNICAL SKILLS </span>
                                    for the 21st century​​</p>
                                <img src={CardImage4} className="w-40 mb-2 mx-auto my-10" />

                            </div>
                        </div>




                    </Slider>
                </div>

            </div>
        </motion.section>
    );
};

export default Section8;
