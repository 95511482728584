import React from 'react';
import card1 from "../../../assets/TranstechCollaboratory/Section6image1.png"
import card2 from "../../../assets/TranstechCollaboratory/Section6image2.png"
import card3 from "../../../assets/TranstechCollaboratory/Section6image3.png"
import card4 from "../../../assets/TranstechCollaboratory/Section6image4.png"
import card5 from "../../../assets/TranstechCollaboratory/Section6image5.png"
import card6 from "../../../assets/TranstechCollaboratory/Section6image6.png"

const Section6 = () => {
  return (
    <section className="py-12 xl:px-28 lg:px-20 md:px-12 px-4  ">
      <div className=" text-center">
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-8 ">Building <span className='text-blue-500'>Futures Together</span></h2>
        <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-gray-700 mb-8"style={{ lineHeight: '140%' }}><span className='text-blue-500'>Supporting Kids, Young, Adults and Aged people</span> Struggling...<span className='text-blue-500'>helping</span> Individual and organizations build the Missing Learning Layer and infrastructure to deliver <span className='text-blue-500'>Service through 5 core offerings</span></p>
        
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 ">
          {/* Image 1 */}
          <div className="col-span-1">
            <img src={card1} alt="Image 1" className="w-full h-auto" />
          </div>

          {/* Image 2 */}
          <div className="col-span-1 md:col-span-2">
            <img src={card2} alt="Image 2" className="w-full " />
          </div>

          {/* Image 3 */}
          <div className="col-span-1">
            <img src={card3} alt="Image 3" className="w-full h-auto" />
          </div>

          
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-4">
          
          {/* Image 4 */}
          <div className="col-span-1">
            <img src={card4} alt="Image 4" className="w-full h-auto" />
          </div>

          <div className="col-span-1">
            <img src={card5} alt="Image 4" className="w-full h-auto" />
          </div>

          <div className="col-span-1">
            <img src={card6} alt="Image 4" className="w-full h-auto" />
          </div>
        </div>

        <p className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-gray-700 mt-8 font-medium" style={{ lineHeight: '140%' }}>Lets consider <span className='text-blue-500'>“How to handle your anxiety or fear”</span> as an individual skill. This is the world of your personal life, your feelings and your emotions towards psychological fitness. On other part lets think about <span className='text-blue-500'>“How to prepare for your exams”</span> as an academic skill. This is more on your learning, education and career. Both skills are the world of action. Each one of them is inter linked and integrated for success. A student needs to develop skills and attitudes which help students to be more effective to find the fulfillment.</p>
      </div>
    </section>
  );
};

export default Section6;
