import React from "react";
import card1 from "./Images/Icons2/icons.png";
import card2 from "./Images/Icons2/icons-1.png";
import card3 from "./Images/Icons2/icons-2.png";
import card4 from "./Images/Icons2/icons-3.png";
import card5 from "./Images/Icons2/icons-4.png";
import card6 from "./Images/Icons2/icons-5.png";
import card7 from "./Images/Icons2/icons-6.png";
import card8 from "./Images/Icons2/icons-7.png";
import card9 from "./Images/Icons2/icons-8.png";
import card10 from "./Images/Icons2/icons-9.png";
import card11 from "./Images/Icons2/icons-10.png";
import card12 from "./Images/Icons2/icons-11.png";
import card13 from "./Images/Icons2/icons-12.png";
import card14 from "./Images/Icons2/icons-13.png";
import card15 from "./Images/Icons2/icons-14.png";
import card16 from "./Images/Icons2/icons-15.png";

// Sample dataset
const cardData = [
  { id: 1, image: card1, title: "Embedding Mental Fitness Journeys" },
  {
    id: 2,
    image: card2,
    title: "Encourage students an attitude of learner responsibility",
  },
  { id: 3, image: card3, title: "Providing variety in learning activities" },
  {
    id: 4,
    image: card4,
    title:
      "Assist students to recognise and articulate the employability skills",
  },
  {
    id: 5,
    image: card5,
    title: "High quality professional staff guidance for students",
  },
  {
    id: 6,
    image: card6,
    title: "Mind Development services to work more closely with experts",
  },
  { id: 7, image: card7, title: "Reviewing non-curricular activities" },
  { id: 8, image: card8, title: "Regular mental health audit" },
  { id: 9, image: card9, title: "Mind Health Journey Management" },
  { id: 10, image: card10, title: "Coping up Strategies for Mind Development" },
  {
    id: 11,
    image: card11,
    title: "Expert Support and Developmental Functionalities  ",
  },
  { id: 12, image: card12, title: "Mentoring and coaching functionality" },
  {
    id: 13,
    image: card13,
    title: "Mental Wellness Fitness Guides and Tools",
  },
  {
    id: 14,
    image: card14,
    title: "Mental Health Fitness Guides and Tools",
  },
  { id: 15, image: card15, title: "Mental Wellbeing Fitness Guides and Tools" },
  { id: 16, image: card16, title: "Action Platform" },
];

const Section10 = () => {
  return (
    <section className="py-24">
      <div className=" text-center px-4 md:px-12 lg:px-24 xl:px-36">
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold mb-4 md:px-16">
          HPDSx Engine use AI to empowers organisation to{" "}
          <span className="text-blue-500" style={{ lineHeight: "140%" }}>
            Detect, Map, Infer, Update & Maintain skills data
          </span>{" "}
        </h2>
        <p className="text-lg text-gray-700 mb-8 mt-4">
          Plus Funnels To Get Customers To Find You
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2  ">
          {cardData.map((card) => (
            <div
              key={card.id}
              className="text-center bg-gray-100 p-3 md:p-4 lg:p-6 xl:p-12 justify-center items-center hover:bg-slate-200 cursor-pointer   "
            >
              <img
                src={card.image}
                alt={card.title}
                className="mx-auto hover:text-white"
              />
              <h3 className="text-md font-semibold mt-4">{card.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section10;
