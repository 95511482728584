import React from "react";
import BgImg from "../../../assets/Coach/BgImgLandingSection.jpg";
import CoverSideImg from "../../../assets/Coach/CoachSideCoverImg.jpg";

const LandingSection = () => {
  const bgImageStyle = {
    backgroundImage: `url(${BgImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <div
      className="grid grid-cols-2 w-full h-full xl:px-24 xl:py-16 lg:px-8 lg:py-8 md:px-4 md:py-4"
      style={bgImageStyle}
    >
      <div className="order-2 md:order-1 col-span-2 md:col-span-1 place-content-start flex flex-col text-white pl-6">
        <div className="lg:mb-8 pt-8 md:pt-16 pb-2 font-semibold font-Segoe UI text-xl xl:text-4xl md:text-xl lg:text-3xl cursor-pointer">
          <div className="mb-2">
            Lend a hand in unlocking the
            <br /> power of{" "}
            <span style={{ fontWeight: "bold" }}>
              Subconscious
              <br />
              mind
            </span>
          </div>
        </div>
        <div className="mb-1 font-normal font-Segoe UI text-sm xl:text-lg lg:text-md md:text-xs cursor-pointer">
          <div className="hidden md:block">
            Join us to delve into the latent power of the subconscious
            <br />
            mind and unlock its transformative capabilities, impacting
            <br />
            personal growth and self-discovery together
          </div>
          <div className="block md:hidden tracking-tight mb-14">
            Join us to delve into the latent power of the subconscious
            mind and unlock its transformative capabilities, impacting
            personal growth and self-discovery together
          </div>
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-2 md:col-span-1 flex justify-start items-start py-0 md:py-8 pl-0 md:pl-6 pr-0 md:pr-4">
        <div className="w-[584px] h-84 rounded-xl hover:shadow-xl">
          <img
            src={CoverSideImg}
            alt="blank"
            className="w-full h-full object-cover rounded-none md:rounded-xl"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingSection;
