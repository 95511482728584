import t1 from "../../../../assets/Hpdsx/Section1_Img1.svg";
import t2 from "../../../../assets/Hpdsx/Section1_Img2.svg";
import t3 from "../../../../assets/Hpdsx/Section1_Img3.svg";
import t4 from "../../../../assets/Hpdsx/Section1_Img4.png";

const firstSectionData = [
  {
    id: 1,
    title: "Goal Setting",
    bgColor: "bg-emerald-300",
    imageUrl: t1,
  },
  {
    id: 2,
    title: "Success Profiler",
    bgColor: "bg-white",
    imageUrl: t2,
  },
  {
    id: 3,
    title: "Create your own Path",
    bgColor: "bg-red-200",
    imageUrl: t3,
  },
  {
    id: 4,
    title: "Knowledge Boost",
    bgColor: "bg-stone-200",
    imageUrl: t2,
  },
  {
    id: 5,
    title: "Goal Setting",
    bgColor: "bg-emerald-300",
    imageUrl: t1,
  },
  {
    id: 6,
    title: "Success Profiler",
    bgColor: "bg-white",
    imageUrl: t2,
  },
  {
    id: 7,
    title: "Create your own Path",
    bgColor: "bg-red-200",
    imageUrl: t3,
  },
  {
    id: 8,
    title: "Knowledge Boost",
    bgColor: "bg-stone-200",
    imageUrl: t2,
  },
  {
    id: 9,
    title: "Goal Setting",
    bgColor: "bg-emerald-300",
    imageUrl: t1,
  },
  {
    id: 10,
    title: "Success Profiler",
    bgColor: "bg-white",
    imageUrl: t2,
  },
  {
    id: 11,
    title: "Create your own Path",
    bgColor: "bg-red-200",
    imageUrl: t3,
  },
  {
    id: 12,
    title: "Knowledge Boost",
    bgColor: "bg-stone-200",
    imageUrl: t2,
  },
];

export default firstSectionData;
