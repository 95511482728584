import React from "react";
import card1 from "./Images/4card1.png"
import card2 from "./Images/4card2.png";
import card3 from "./Images/4card3.png";
import card4 from "./Images/4card4.png";

const Section12 = () => {
  return (
    <section className="py-8">
      <div className=" ">
        <p
          className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold mb-4 px-4 md:px-8 lg:px-12 xl:px-20 text-center"
          style={{ lineHeight: "140%" }}
        >
          It recommends that the{" "}
          <span className="text-blue-500">
            knowledge, skills, competencies,
          </span>{" "}
          and inventiveness of the workforce to be constantly{" "}
          <span className="text-blue-500">updated</span> through effective
          lifelong learning.
        </p>

        <div className="grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8 px-4 md:px-8 lg:px-12 xl:px-20 my-12">
          {/* Card 1 */}
          <div className=" p-4 rounded-lg bg-blue-500">
            <img src={card1} alt="Image 1" className="w-full h-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-white">
              We make you better
            </h3>
            <p className="text-white mb-2">
              So you can do the really awesome stuff
            </p>
            <p className="text-lg text-white mb-4">
              Teacher led instruction interactive
            </p>

            <ul className="text-lg text-white mb-4 list-disc px-8">
              <li>Action Leaning webinars</li>
              <li>Workshops Seminars</li>
              <li>Industry experts</li>
              <li>Case Studies</li>
              <li>Brain Storming </li>
              <li>Discussion Board</li>
              <li>In-take interviews</li>
            </ul>
          </div>

          {/* Card 2 */}
          <div className=" p-4 rounded-lg bg-green-500">
            <img src={card2} alt="Image 2" className="w-full h-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-white">
              Everything you need
            </h3>
            <p className="text-white mb-2">
              Simple, visual, intuitive, accessible yet powerful.
            </p>

            <p className="text-lg text-white mb-4">Personal learning</p>

            <ul className="text-lg text-white mb-4 list-disc px-8">
              <li>Informational interview</li>
              <li>Profiling Building</li>
              <li>Portfolio Development</li>
              <li>Individual Development Plan</li>
              <li>Personal Development Plan </li>

              <li>Written Self-Assessment Collaboration Learning </li>
              <li>General Discussion Forums Community</li>
              <li>Participation Event Participation</li>
            </ul>
          </div>

          {/* Card 3 */}
          <div className=" p-4 rounded-lg bg-yellow-500">
            <img src={card3} alt="Image 3" className="w-full h-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-white">
              Environment you wanted
            </h3>
            <p className="text-white mb-2">
              Be with the right set of people, become your best version
            </p>

            <p className="text-lg text-white mb-4">Group Learning</p>

            <ul className="text-lg text-white mb-4 list-disc px-8">
              <li>General Discussion Forums</li>
              <li>Event Participations</li>
              <li>Social Learning</li>
              <li>Co-Creating Session</li>
              <li>Group Participation</li>
              <li>Work Teams</li>
            </ul>
          </div>

          {/* Card 4 */}
          <div className=" p-4 rounded-lg bg-red-500">
            <img src={card4} alt="Image 4" className="w-full h-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2 text-white">
              Things you didn’t know you’d want
            </h3>
            <p className="text-white mb-2">
              Insights that accelerate your growth mindsets
            </p>
            <p className="text-lg text-white mb-4">Face-to-face session</p>

            <ul className="text-lg text-white mb-4 list-disc px-8">
              <li>Psychological Counseling Guidance Support</li>
              <li>Mentoring</li>
              <li>Self Discovery and Assessment Skills for Life Checklists</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section12;
