import React, { useState, useEffect } from 'react';
import { motion , useAnimation} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons';
import DatasetSec4 from "./Datasetsec4"
import { useInView } from 'react-intersection-observer';

const Section4 = () => {
    const [selectedButton, setSelectedButton] = useState(1);
    const [fadeIn, setFadeIn] = useState(false);
    const { ref, inView } = useInView();
    const controlsLeft = useAnimation();
  const controlsRight = useAnimation();

    useEffect(() => {
        setFadeIn(true); // Set fadeIn to true initially
    }, []);

    const handleButtonClick = async (button) => {
        await Promise.all([
          controlsLeft.start({ opacity: 0, x: -50, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } }),
          controlsRight.start({ opacity: 0, x: -50, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } })
        ]);
        setSelectedButton(button);
        await Promise.all([
          controlsLeft.start({ opacity: 1, x: 0, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } }),
          controlsRight.start({ opacity: 1, x: 0, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } })
        ]);
      };

    return (
        <motion.div
            ref={ref}
            className="w-full bg-[#EFF2FB] p-4 md:p-1"
            initial={{ opacity: 0, y: 80 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
            transition={{ ease: 'easeInOut', duration: 0.7, delay: 0.2 }}
        >
            <div className="max-w-screen-xl mx-auto flex flex-col text-black">
                <h1 className="text-center max-w-2xl mx-auto text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-loose mb-4 py-4 md:py-8 lg:py-0 pt-8 md:pt-12 lg:pt-16 font-bold" style={{ lineHeight: '140%' }}>Dedicated to our Culture</h1>
                <h1 className="text-center mx-auto max-w-screen-lg text-lg md:text-lg lg:text-xl xl:text-2xl leading-loose mb-4 py-[6px] md:py-4 lg:py-8 font-medium" style={{ lineHeight: '140%' }}>Only if we as a civilization live up to our objective. We begin by adopting a growth attitude and learning about everything. Next, we use that way of thinking to get to know our clients, be inclusive and diverse, and collaborate as a team.</h1>
                <h1 className="mx-4 md:mx-12 lg:mx-20 text-lg md:text-2xl lg:text-4xl xl:text-5xl leading-loose mb-4 py-[6px] md:py-4 font-bold" style={{ lineHeight: '140%' }}>Our Cultural <span className='text-blue-500'> Attributes</span></h1>
                <div className="grid grid-cols-1 md:grid-cols-5 gap-4 md:gap-1 lg:gap-6 xl:gap-10 py-4 md:py-2 lg:py-0 xl:py-2 mx-4 md:mx-12 lg:mx-20">
                    {DatasetSec4.map(item => (
                        <motion.button
                            key={item.id}
                            className={`text-center  hover:bg-blue-500  hover:text-white md:text-sm lg:text-md font-semibold py-3 md:px-2 xl:px-6 rounded-xl whitespace-nowrap border ${selectedButton === item.id ? 'bg-blue-500 text-white border-blue-500 hover:border-transparent' : 'text-blue-500 bg-white border-blue-500 hover:border-transparent'} transition duration-300 ease-in-out`}
                            onClick={() => handleButtonClick(item.id)}
                            
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        
                        >
                            {item.heading}
                        </motion.button>
                    ))}
                </div>
                <motion.div
                    initial={{ opacity: 0.8 }}
                    animate={fadeIn ? { opacity: 1 } : { opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="grid grid-cols-12 my-2"
                >
                    {/* Left Section */}
                    <div className="col-span-12 md:col-span-7 text-left mx-4 md:mx-8 lg:mx-20 md:my-8">
                        <motion.div
                            initial={{ opacity: 0.8, y: 20 }}
                            animate={controlsLeft}
                            transition={{ duration: 0.5 }}
                            className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-4"
                        >
                            {DatasetSec4[selectedButton - 1].heading}
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0.8, y: 20 }}
                            animate={controlsLeft}
                            transition={{ duration: 0.5 }}
                            className="text-md md:text-md lg:text-lg xl:text-xl"
                            style={{ lineHeight: '140%' }}
                        >
                            {DatasetSec4[selectedButton - 1].description}
                        </motion.div>
                    </div>
                    {/* Right Section */}
                    <motion.div
                        initial={{ opacity: 0.8, x: 20 }}
                        animate={controlsRight}
                        transition={{ duration: 0.5 }}
                        className="col-span-12 md:col-span-5 flex justify-center items-center mt-4 mx-4 md:mt-0 md:mr-12"
                    >
                        <img src={DatasetSec4[selectedButton - 1].image} alt="Right Section Image" className="w-full" />
                    </motion.div>
                </motion.div>
            </div>
        </motion.div>
    );
};

export default Section4;
