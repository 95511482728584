import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import cardimage from "../../../assets/CustomerSuccess/Section4image1.png"

import image1 from "../../../assets/CustomerSuccess/Section5card1.png"
import image2 from "../../../assets/CustomerSuccess/Section5card2.png"
import image3 from "../../../assets/CustomerSuccess/Section5card3.png"
import image4 from "../../../assets/CustomerSuccess/Section5card4.png"

import icon1 from "../../../assets/CustomerSuccess/Section5icon1.png";
import icon2 from "../../../assets/CustomerSuccess/Section5icon2.png";
import icon3 from "../../../assets/CustomerSuccess/Section5icon3.png";
import icon4 from "../../../assets/CustomerSuccess/Section5icon4.png";


const Section4 = () => {
    return (
        <section className="py-16  xl:px-24 lg:px-20 md:px-12 px-4">
            <div className="">
                <h2 className="text-lg font-bold mb-4 text-blue-500">PROGRAM GUIDANCE</h2>
                <h1 className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mb-6 w-full lg:w-3/4"style={{ lineHeight: '140%' }}>A team of experts, dedicated to you</h1>
                <p className="text-lg md:text-xl lg:text-2xl mb-6 w-full xl:w-5/6"style={{ lineHeight: '140%' }}>Opportunity to Re-engineer and Cultivate/Nurture your Life Journey to exclusive Resources and Expertise</p>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 xl:my-8 lg:h-80">

                <div className="relative overflow-hidden">
                        <img src={image1} alt="Image 1" className="w-full h-full object-cover" />
                        <div className="absolute bottom-32 left-6 lg:left-0 xl:left-6 w-5/6 lg:w-full xl:w-5/6 bg-white opacity-100 transition-all duration-300 ease-in-out transform translate-y-full hover:translate-y-32 hover:opacity-100 rounded-tl-lg rounded-tr-[100px] rounded-br-lg rounded-bl-lg">
                            <div className="p-4">
                                <img src={icon1} className='' />
                                <h2 className="text-lg font-semibold w-5/6">HPDSx Success Management</h2>
                                <p className="text-sm">Use these same insights to provide individualized guidance and support to workers in operations to help them perform and their personal best. </p>
                            </div>
                        </div>
            </div>

            <div className="relative overflow-hidden">
                <img src={image2} alt="Image 1" className="w-full h-full object-cover" />
                <div className="absolute bottom-32 left-6 lg:left-0 xl:left-6 w-5/6 lg:w-full xl:w-5/6 bg-white opacity-100 transition-all duration-300 ease-in-out transform translate-y-full  hover:translate-y-32 hover:opacity-100 rounded-tl-lg rounded-tr-[100px] rounded-br-lg rounded-bl-lg">
                    <div className="p-4">
                    <img src={icon2} className='' />
                        <h2 className="text-lg font-semibold w-5/6">HPDSx Platform Optimization</h2>
                        <p className="text-sm">Use these same insights to provide individualized guidance and support to workers in operations to help them perform and their personal best. </p>
                    </div>
                </div>
            </div>

            <div className="relative overflow-hidden">
                <img src={image3} alt="Image 1" className="w-full h-full object-cover" />
                <div className="absolute bottom-32 left-6 lg:left-0 xl:left-6 w-5/6 lg:w-full xl:w-5/6 bg-white opacity-100 transition-all duration-300 ease-in-out transform translate-y-full  hover:translate-y-32 hover:opacity-100 rounded-tl-lg rounded-tr-[100px] rounded-br-lg rounded-bl-lg">
                    <div className="p-4 ">
                    <img src={icon3} className='' />
                        <h2 className="text-lg font-semibold w-5/6">HPDSx Subject Matter Expert</h2>
                        <p className="text-sm">Use these same insights to provide individualized guidance and support to workers in operations to help them perform and their personal best. </p>
                    </div>
                </div>
            </div>

            <div className="relative overflow-hidden">
                <img src={image4} alt="Image 1" className="w-full h-full object-cover" />
                <div className="absolute bottom-32 left-6 lg:left-0 xl:left-6 w-5/6 lg:w-full xl:w-5/6 bg-white opacity-100 transition-all duration-300 ease-in-out transform translate-y-full  hover:translate-y-32 hover:opacity-100 rounded-tl-lg rounded-tr-[100px] rounded-br-lg rounded-bl-lg">
                    <div className="p-4">
                    <img src={icon4} className='' />
                        <h2 className="text-lg font-semibold w-5/6">HPDSx Program Management</h2>
                        <p className="text-sm">Use these same insights to provide individualized guidance and support to workers in operations to help them perform and their personal best. </p>
                    </div>
                </div>
            </div>
                </div>

            </div>
        </section>
    );
};

export default Section4;
