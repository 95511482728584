import React from "react";
import Section2 from "./Section2";
import Section1 from "./Section1";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import MoreServices from "../SME/MoreServices";

export default function Institution() {
  return (
    <div className="border border-gray-300 bg-white py-6 xl:py-16 rounded-xl shadow-lg m-2 md:m-6 xl:mx-14 lg:m-8 xl:my-10">
      <div className="flex flex-col">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <MoreServices />
      </div>
    </div>
  );
}
