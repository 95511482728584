import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight} from "@fortawesome/free-solid-svg-icons";

const MySection = () => {
  return (
    <section className="py-16 bg-blue-100 px-4 md:px-0">
      <div className=" text-center">
      <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-medium mb-4">Still not sure</h2>
        <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-8">what you're looking for</h1>
        
        <button className=" hover:bg-blue-600 text-white py-2 px-4 rounded focus:outline-none" style={{ backgroundColor: 'rgb(0, 134, 232)' }}>Log In To Contact Support<span className="mx-2"><FontAwesomeIcon icon={faAngleRight} style={{color: "#ffffff",}} /></span></button>
      </div>
    </section>
  );
};

export default MySection;
