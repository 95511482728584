import React from "react";
import card1 from "./Images/Icons/icons.png"
import card2 from "./Images/Icons/icons-1.png"
import card3 from "./Images/Icons/icons-2.png"
import card4 from "./Images/Icons/icons-3.png"
import card5 from "./Images/Icons/icons-4.png"
import card6 from "./Images/Icons/icons-5.png"
import card7 from "./Images/Icons/icons-6.png"
import card8 from "./Images/Icons/icons-7.png"
import card9 from "./Images/Icons/icons-8.png"
import card10 from "./Images/Icons/icons-9.png"
import card11 from "./Images/Icons/icons-10.png"
import card12 from "./Images/Icons/icons-11.png"
import card13 from "./Images/Icons/icons-12.png"
import card14 from "./Images/Icons/icons-13.png"
import card15 from "./Images/Icons/icons-14.png"
import card16 from "./Images/Icons/icons-15.png"
import card17 from "./Images/Icons/icons-16.png"
import card18 from "./Images/Icons/icons-17.png"
import card19 from "./Images/Icons/icons-18.png"
import card20 from "./Images/Icons/icons-19.png"
import card21 from "./Images/Icons/icons-20.png"
import card22 from "./Images/Icons/icons-21.png"
import card23 from "./Images/Icons/icons-22.png"
import card24 from "./Images/Icons/icons-23.png"
import card25 from "./Images/Icons/icons-24.png"
import card26 from "./Images/Icons/icons-25.png"
import card27 from "./Images/Icons/icons-26.png"
import card28 from "./Images/Icons/icons-27.png"
import card29 from "./Images/Icons/icons-28.png"
import card30 from "./Images/Icons/icons-29.png"
import card31 from "./Images/Icons/icons-30.png"
import card32 from "./Images/Icons/icons-31.png"
import card33 from "./Images/Icons/icons-32.png"
import card34 from "./Images/Icons/icons-33.png"
import card35 from "./Images/Icons/icons-34.png"





// Sample dataset
const cardData = [
  { id: 1, image: card1, title: "Intelligence Development" },
  { id: 2, image: card2, title: "Self Discovery Of Mental Ability" },
  { id: 3, image: card3, title: "Intelligent Mindset Scoring" },
  { id: 4, image: card4, title: "Early Warning Systems" },
  { id: 5, image: card5, title: "Behavioral Analytics" },
  { id: 6, image: card6, title: "Detect Mental Health Symptoms" },
  {
    id: 7,
    image: card7,
    title:
      "Create Mental Health Development Plans",
  },
  { id: 8, image: card8, title: "Brain Fitness Activites" },
  { id: 9, image: card9, title: "Develop Fluid Intelligence" },
  { id: 10, image: card10, title: "Develop Cristalised Intelligence" },
  { id: 11, image: card11, title: "Interventions for Enhancing Cognitive Functionalities" },
  { id: 12, image: card12, title: "Build Thinking Abilities" },
  { id: 13, image: card13, title: "Develop Intellectual Capacities" },
  { id: 14, image: card14, title: "Know your Gifted Intelligence" },
  { id: 15, image: card15, title: "Know your Learning Style" },
  { id: 16, image: card16, title: "Develop Success Intelligence" },
  { id: 17, image: card17, title: "Know your level of Practical Intelligence" },
  { id: 18, image: card18, title: "Challenge your Neural Intelligence" },
  { id: 19, image: card19, title: "Expand your Experential Intellegence" },
  { id: 20, image: card20, title: "Cultivate Faculties of Thinking" },
  {
    id: 21,
    image: card21,
    title: "Design & Develop your Inner Intelligence",
  },
  { id: 22, image: card22, title: "Perception Building Exercises" },
  { id: 23, image: card23, title: "Personal File Repository" },
  { id: 24, image: card24, title: "Good Habits Development" },
  { id: 25, image: card25, title: "Interest Specific Mind Development Programs" },
  { id: 26, image: card26, title: "Positive Cognitive Styles development" },
  { id: 27, image: card27, title: "Prepare to lead Productive and fulfilling Lifestyle" },
  { id: 28, image: card28, title: "Lifelong Learning to Update Value Systems" },
  { id: 29, image: card29, title: "Lifelong Learning to Update Value Systems" },
  { id: 30, image: card30, title: "Lifelong Learning to Update Value Systems" },
  { id: 31, image: card31, title: "Lifelong Learning to Update Value Systems" },
  { id: 32, image: card32, title: "Soft Behaviorual Skills Development" },
  { id: 33, image: card33, title: "Mentoring Work and Non-Work Roles" },
  { id: 34, image: card34, title: "Subject Matter Expert Interaction" },
  { id: 35, image: card35, title: "Active Network Development" },
  
];

const Section8 = () => {
  return (
    <section className="py-24">
      <div className=" text-center px-4 md:px-12 lg:px-24 xl:px-36">
        <h2
          className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold mb-4"
          style={{ lineHeight: "140%" }}
        >
          UPMYMIND Mental{" "}
          <span className="text-blue-500">Health Connection Hub</span>{" "}
        </h2>
        <div
          className="text-md md:text-lg lg:text-xl xl:text-2xl xl:px-28 lg:px-20 md:px-12 px-4 py-4"
          style={{ lineHeight: "140%" }}
        >
          The UPMYMIND Mental Health Insight Platform powered by HPDSx Engine,
          will use open data, machine learning and other methods to improve
          communication between youth and families, researchers, clinicians and
          other service providers with diverse mental health experiences and
          specializations.
        </div>
        <p className="text-xl text-blue-500 mb-8 mt-4 font-semibold">
          Building the mindful PEOPLE of the Future
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 ">
          {cardData.map((card) => (
            <div
              key={card.id}
              className="text-center bg-gray-100 p-3 md:p-4 lg:p-6 xl:p-12 justify-center items-center hover:bg-gray-200 cursor-pointer "
            >
              <img
                src={card.image}
                alt={card.title}
                className="mx-auto hover:text-white"
              />
              <h3 className="text-md font-semibold mt-4">{card.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section8;
