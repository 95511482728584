import React from "react";
import logo from "../../../assets/HPDS-logo.png";

import { Link } from "react-router-dom/dist";
import { useState } from "react";
import Hamburger from "./Hamburger.js";
import FootContent from "./FootContent.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";

const MobileNavbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const [isEngineOpen, setIsEngineOpen] = useState(false);
  const [isPlatformOpen, setIsPlatformOpen] = useState(false);
  const [isResOpen, setIsResOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);

  const [isStudentOpen, setIsStudentOpen] = useState(false);
  const [isOrgOpen, setIsOrgOpen] = useState(false);
  const [isInstituteOpen, setIsInstituteOpen] = useState(false);
  const [isSMEOpen, setIsSMEOpen] = useState(false);
  const [isHubOpen , setIsHubOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleEngine = () => {
    setIsEngineOpen(!isEngineOpen);
  };
  const togglePlatform = () => {
    setIsPlatformOpen(!isPlatformOpen);
  };
  const toggleRes = () => {
    setIsResOpen(!isResOpen);
  };
  const toggleSupport = () => {
    setIsSupportOpen(!isSupportOpen);
  };

  const toggleStudent = () => {
    setIsStudentOpen(!isStudentOpen);
  };
  const toggleOrg = () => {
    setIsOrgOpen(!isOrgOpen);
  };
  const toggleInstitute = () => {
    setIsInstituteOpen(!isInstituteOpen);
  };
  const toggleSME = () => {
    setIsSMEOpen(!isSMEOpen);
  };

  const toggleHub = ()=>{
    setIsHubOpen(!isHubOpen);
  }

  const oneBoxStyle = " flex flex-col px-4"
  return (
    <div className="md:hidden absolute top-0 left-0 right-0 z-50 w-full ">

      <div className="bg-white ">
        <div className="mx-auto flex justify-between items-center border-b bordergray-300  h-1/2 p-3">
          <Hamburger />
          <Link className="my-auto" to="/" >
            <div className="px-2 w-28">
              <img src={logo} className="" alt="img" />
            </div>
          </Link>
          <Link href="#" className="  my-auto cursor-pointer  font-medium underline-offset-4" >
            Sign In+
          </Link>

        </div>

        <button className="h-1/2 px-5 p-2 text-sm font-semibold " onClick={toggleMenu}>
          THINKSKILLINDIA {isMenuOpen ? <FontAwesomeIcon className="w-3" icon={faChevronUp} /> : <FontAwesomeIcon className="w-3" icon={faChevronDown} />}

        </button>

        {/* Floating box */}
        {isMenuOpen && (
          <div className="flex flex-col w-full bg-white shadow-lg z-50 p-2 px-5 mob-nav border-b border-black text-sm">
            <Link onClick={toggleEngine} className={`${isEngineOpen ? "text-blue-600 border-dashed border border-black" : ""}`}>
              HPDSx Engine {isEngineOpen ? <FontAwesomeIcon className="w-3" icon={faChevronUp} /> : <FontAwesomeIcon className="w-3" icon={faChevronDown} />}
            </Link>
            <div className={`${oneBoxStyle} ${isEngineOpen ? "block" : "hidden"}`}>
              <Link to = '/hpdsx-engine'>HPDS Engine</Link>
              <Link to = "/hpdsxhub/learnandgrow">Learn and Grow</Link>
            </div>
            
            <Link onClick={toggleHub} className={`${isHubOpen ? "text-blue-600 border-dashed border border-black" : ""}`}>
              HPDSx Hub {isHubOpen ? <FontAwesomeIcon className="w-3" icon={faChevronUp} /> : <FontAwesomeIcon className="w-3" icon={faChevronDown} />}
            </Link>
            <div className={`${oneBoxStyle} ${isSupportOpen ? "block" : "hidden"}`}>

              <Link to="/hpdsxhub">Hpdsx Hub</Link>
            </div>

            <Link onClick={togglePlatform} className={`${isPlatformOpen ? "text-blue-600 border-dashed border border-black" : ""}`}>
              Platform {isPlatformOpen ? <FontAwesomeIcon className="w-3" icon={faChevronUp} /> : <FontAwesomeIcon className="w-3" icon={faChevronDown} />}
            </Link>
            <div className={`${oneBoxStyle} ${isPlatformOpen ? "block" : "hidden"}`}>

              <Link onClick={toggleStudent}>
                For Students {isStudentOpen ? <FontAwesomeIcon className="w-3" icon={faChevronUp} /> : <FontAwesomeIcon className="w-3" icon={faChevronDown} />}
              </Link>
              <div className={`${oneBoxStyle} ${isStudentOpen ? "block" : "hidden"}`}>

                <Link>Create your unique success profiler</Link>
                <Link>Student engagement</Link>
                <Link>Self help care</Link>
                <Link>Expert staff care</Link>
                <Link>Student Walkthrough</Link>
              </div>

              <Link onClick={toggleOrg}>
                For Organization {isOrgOpen ? <FontAwesomeIcon className="w-3" icon={faChevronUp} /> : <FontAwesomeIcon className="w-3" icon={faChevronDown} />}
              </Link>
              <div className={`${oneBoxStyle} ${isOrgOpen ? "block" : "hidden"}`}>

                <Link>Individual Assessment</Link>
                <Link>Student Assessment Model</Link>
                <Link>Global Competency Development</Link>
                <Link>Pick up where you have left</Link>
              </div>

              <Link onClick={toggleInstitute}>
                For Institution {isInstituteOpen ? <FontAwesomeIcon className="w-3" icon={faChevronUp} /> : <FontAwesomeIcon className="w-3" icon={faChevronDown} />}
              </Link>
              <div className={`${oneBoxStyle} ${isInstituteOpen ? "block" : "hidden"}`}>

                <Link>Create your own Digital Learning Platform</Link>
                <Link>Empower your Experts</Link>
                <Link>Student Collaboration</Link>
                <Link>Bring Education Learning more successful together</Link>
              </div>

              <Link onClick={toggleSME}>
                For Subject Matter Expert {isSMEOpen ? <FontAwesomeIcon className="w-3" icon={faChevronUp} /> : <FontAwesomeIcon className="w-3" icon={faChevronDown} />}
              </Link>
              <div className={`${oneBoxStyle} ${isSMEOpen ? "block" : "hidden"}`}>

                <Link>Discover and Eliminate ignorance and weakness early</Link>
                <Link>Support your mind to make a global impact</Link>
                <Link>Reimagine Human life to enable transformation</Link>
                <Link>Connecting with people across the globe, global
                  organizations, and looking at new ways of learning and
                  sharing with each other</Link>
              </div>


            </div>

            <Link onClick={toggleRes} className={`${isResOpen ? "text-blue-600 border-dashed border border-black" : ""}`}>
              Resources {isResOpen ? <FontAwesomeIcon className="w-3" icon={faChevronUp} /> : <FontAwesomeIcon className="w-3" icon={faChevronDown} />}
            </Link>
            <div className={`${oneBoxStyle} ${isResOpen ? "block" : "hidden"}`}>

              <Link>Blogs</Link>
              <Link>Articles</Link>
              <Link>Events</Link>
              <Link>Community</Link>
              <Link>Create your Platform</Link>
              <Link>Create your Content</Link>
              <Link>Create your Event</Link>
            </div>

            
            
          </div>
        )}

        {isMenuOpen && (
          <div className="sticky inset-0 bg-black bg-opacity-50 z-10" onClick={toggleMenu}></div>
        )}



      </div>
    </div>
  )
}

export default MobileNavbar