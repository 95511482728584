import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import CoverSideImg from "../../../assets/Guide/GuideSideImg.png";

const LandingCover = () => {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full mx-auto md:mx-0 h-10 bg-blue-800">
        <div className="text-neutral-50 whitespace-nowrap text-center my-3 text-base text-xs md:text-md font-thin xl:font-light lg:font-extralight md:font-thin">
          Bridge the gap between student and there challenging problems!
        </div>
      </div>
      <div className="w-full h-full xl:h-full lg:h-full flex bg-white xl:pl-28 xl:py-12 lg:pl-20 lg:py-8 md:pl-16 md:py-12">
        <div className="w-full h-full flex flex-col md:flex-row">
          <div className="flex flex-col flex-1 pl-4 py-6 xl:py-24 lg:py-16 md:py-6">
            <div className="mb-2 pb-2 font-semibold text-xl xl:text-4xl md:text-2xl lg:text-3xl cursor-pointer">
              <div className="mb-1">
                Facilitate connection with <br />
                newcomers and navigate <br />
                success together
              </div>
            </div>
            <div className="mb-1 font-normal text-stone-900 text-sm xl:text-lg lg:text-md md:text-xs cursor-pointer">
              <div className="hidden md:block">
                <div>
                  Build bridges with your junior associates, co-navigating a
                  path <br /> of growth and triumph by fostering a connected,
                  supportive <br /> environment.
                </div>
              </div>
              <div className="block md:hidden">
                <div>
                  Build bridges with your junior associates, co-navigating a
                  path of growth and triumph by fostering a connected,
                  supportive environment.
                </div>
              </div>
            </div>
            <div className=" mt-4 px-4 py-2 w-fit hover:bg-blue-500 hover:text-white cursor-pointer rounded-full border border-black hover:border-white flex items-center justify-start ">
              <div className="text-xs font-bold ">Become Guide</div>
              <FontAwesomeIcon icon={faChevronRight} className=" ml-2" />
            </div>
          </div>
          <div className="flex-1 flex justify-start md:justify-end items-center">
            <div
              className="w-72 md:w-full h-72 md:h-full bg-cover md:bg-contain bg-no-repeat mb-6 xl:ml-24 lg:ml-16 md:ml-6"
              style={{ backgroundImage: `url(${CoverSideImg})` }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
