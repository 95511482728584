import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import DatasetSec5 from "./DatasetSec5";

const Section5 = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Only trigger the animation once
    threshold: 0.25, // Adjust as needed
  });

  return (
    <motion.div
      className="py-12 md:py-24 md:mx-2 lg:mx-8 xl:mx-20"
      ref={ref}
      initial={{ opacity: 0, y: 80 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
      transition={{ duration: 0.9, ease: "easeInOut", delay: 0.4 }} // Adjust as needed
    >
      {/* Heading */}
      <h1
        className="text-3xl md:text-2xl lg:text-4xl xl:text-5xl font-bold text-center  mb-8"
        style={{ color: "rgb(0, 134, 232)" }}
      >
        WE BELIEVE THAT
      </h1>

      {/* Description */}
      <p
        style={{ lineHeight: "140%" }}
        className="text-lg md:text-xl lg:text-2xl xl:text-3xl text-center mx-4 md:mx-0 mb-12 leading-relaxed"
      >
        Over the coming 100 years we will experience vastly greater leaps in our
        ability to{" "}
        <span className="" style={{ color: "rgb(0, 134, 232)" }}>
          design
        </span>{" "}
        and{" "}
        <span className="" style={{ color: "rgb(0, 134, 232)" }}>
          change the future
        </span>{" "}
        through{" "}
        <span className="" style={{ color: "rgb(0, 134, 232)" }}>
          advanced technologies
        </span>
        , and these in turn will have a profound{" "}
        <span className="" style={{ color: "rgb(0, 134, 232)" }}>
          impact
        </span>{" "}
        on{" "}
        <span className="" style={{ color: "rgb(0, 134, 232)" }}>
          what it means to be human.
        </span>
      </p>

      {/* Cards */}
      <div className="grid md:grid-cols-6 grid-cols-1 gap-0">
        {DatasetSec5.map((card, index) => (
          <div
            key={card.id}
            className={`col-span-1 px-4 md:px-0 md:text-sm lg:text-md xl:text-lg ${
              index === 5 ? "border-r-0" : ""
            }`}
            style={{
              height: "12rem",
              "@media (min-width: 768px)": { height: "28rem" },
            }}
          >
            <div
              className={`md:p-4 flex flex-col items-center border-r border-gray-500 ${
                index % 2 === 1 && index !== 5 ? "md:pt-28 lg:pt-24" : ""
              }`}
            >
              <p className="text-center md:text-sm lg:text-md xl:text-lg mb-4">
                {card.description}
              </p>
              <img
                src={card.image}
                alt={`Card ${card.id}`}
                className="w-24 h-24 md:w-40 md:h-40 object-contain"
              />
            </div>
          </div>
        ))}
      </div>

      {/* Description */}
      <p
        style={{ lineHeight: "140%" }}
        className="md:text-xl lg:text-2xl xl:text-3xl italic text-center mt-20 md:mt-40 lg:mt-44 leading-relaxed"
      >
        The core of our work is identifying emerging trends and discontinuities
        that will transform individual, global society and the global
        marketplace developing the human potential.
      </p>
    </motion.div>
  );
};

export default Section5;
