import React from "react";
import RightSideImage from "../Templates/RightSideImage";
import smmain from "../../../assets/HomePage/Institution/imain.webp";

const Section1 = () => {
  const data = {
    title: "Align, Assess, Achieve",
    description: (
      <>
        Customizable assessments efficiently evaluate job candidates and
        employee competencies, seamlessly aligning with organizational
        objectives
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <RightSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section1;
