import React, { useEffect , useRef} from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCircleChevronRight, faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons'; // Import arrow icons from FontAwesome
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageToImport1 from '../../../assets/ChangeAgent/Section6image1.png';
import ImageToImport2 from '../../../assets/ChangeAgent/Section6image2.png';
import ImageToImport3 from '../../../assets/ChangeAgent/Section6image3.png';

const Section6 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  const { ref, inView } = useInView();

  const sliderRef = useRef(null);

  useEffect(() => {
    if (inView) {
      console.log('Section 6 is in view!');
      // You can add additional animations or logic here
    }
  }, [inView]);

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };


  return (
    <motion.section
      className="py-8 md:py-20 lg:py-32"
      ref={ref}
      initial={{ opacity: 0, y: 75 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 75 }}
      transition={{ ease: 'easeInOut', duration: 1.5 , delay:0.4 }}
    >
      <div className="text-center">
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-8 md:mb-12">More is <span className='' style={{ color: 'rgb(0, 134, 232)' }}>Possible </span>with HPDSX</h2>
        <div className="flex justify-center mb-0 md:mb-8">
          <motion.div
            className="w-full h-[40vh] md:w-[30vw] md:h-[45vh] border border-4 border-blue-500 pt-8 md:pt-0 p-[2px] md:p-4 rounded-lg mr-4 items-center font-semibold"
            initial={{ opacity: 0, x: -125 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -125 }}
            transition={{ ease: 'easeInOut', duration: 1.5, delay: 0.7 }}
          >
            <p className='text-sm md:text-lg lg:text-xl xl:text-2xl py-0 md:py-4 lg:py-8 xl:py-16'>To help spark real change and unleash human potential around the world connect with others for greater impact, and scale your business to achieve more.</p>
          </motion.div>
          <div className="flex items-center justify-center">
            <FontAwesomeIcon icon={faArrowRight} className="text-blue-500 text-2xl md:text-3xl mx-[2px] md:mx-4" style={{ cursor: 'pointer' }} onClick={goToNext} />
          </div>
          <motion.div
            className="h-[40vh] md:w-[30vw] h-[45vh] border border-4 border-blue-500 p-[2px] md:p-4 rounded-lg  md:ml-4  pt-16 md:py-16 font-semibold"
            initial={{ opacity: 0, x: 125 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 125 }}
            transition={{ ease: 'easeInOut', duration: 1.5, delay: 0.7 }}
          >
            <p className='md:text-2xl '>THROUGH</p>
            <p className='md:text-2xl'>HUMAN</p>
            <p className='md:text-2xl'>POTENTIAL</p>
            <p className='md:text-2xl'>DEVELOPMENT</p>
            <p className='md:text-2xl'>SCIENTISTS</p>
          </motion.div>
        </div>
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl  font-bold mt-8 px-4 md:px-0" style={{ color: 'rgb(0, 134, 232)' }}>Become a Change Leader in 21st century</h2>
        <div className="flex justify-end mt-4 md:mt-8 mx-0 md:mx-20">
          <FontAwesomeIcon icon={faCircleChevronLeft} size="2x" className="mx-4 text-black text-xl md:text-3xl cursor-pointer hover:text-blue-500" onClick={goToPrev} />
          <FontAwesomeIcon icon={faCircleChevronRight} size="2x" className=" mx-4 text-black  text-xl md:text-3xl cursor-pointer hover:text-blue-500" onClick={goToNext} />
        </div>
        <div className='py-4 md:py-16 gap-2'>
          <Slider {...settings} className='gap-2 mx-0 md:gap-24 md:mx-12' ref={sliderRef}>
            <motion.div
              className="rounded-3xl items-center bg-white h-[32vh] md:h-[46vh] lg:h-[62vh] xl:h-[70vh] lg:pb-4 shadow-xl shadow-blue-300"
              initial={{ opacity: 0, scale: 0.9, x: -50 }}
              animate={inView ? { opacity: 1, scale: 1, x: 0 } : { opacity: 0, scale: 0.9, x: -50 }}
              transition={{ ease: 'easeInOut', duration: 1.5 }}
            >
              <img src={ImageToImport1} alt="Card 1" className="h-4/5 lg:h-5/6 w-[30vw] lg:pb-4 " />
              <h3 className="text-md md:text-lg lg:text-3xl font-bold mb-2">Instructor</h3>
            </motion.div>
            <motion.div
              className="rounded-3xl items-center bg-white h-[30vh] md:h-[44vh] lg:h-[60vh] xl:h-[68vh] lg:pb-4 shadow-xl shadow-blue-300"
              initial={{ opacity: 0, scale: 0.9, x: 50 }}
              animate={inView ? { opacity: 1, scale: 1, x: 0 } : { opacity: 0, scale: 0.9, x: 50 }}
              transition={{ ease: 'easeInOut', duration: 1.5 }}
            >
              <img src={ImageToImport2} alt="Card 2" className="h-4/5 lg:h-5/6 w-[30vw] lg:pb-4 " />
              <h3 className="text-md md:text-lg lg:text-3xl font-bold mb-2">Mentor</h3>
            </motion.div>
            <motion.div
              className="rounded-3xl items-center bg-white h-[30vh] md:h-[44vh] lg:h-[60vh] xl:h-[68vh] lg:pb-4 shadow-xl shadow-blue-300"
              initial={{ opacity: 0, scale: 0.9, x: -50 }}
              animate={inView ? { opacity: 1, scale: 1, x: 0 } : { opacity: 0, scale: 0.9, x: -50 }}
              transition={{ ease: 'easeInOut', duration: 1.5 }}
            >
              <img src={ImageToImport3} alt="Card 3" className="h-4/5 lg:h-5/6 w-[30vw] lg:pb-4 " />
              <h3 className="text-md md:text-lg lg:text-3xl font-bold mb-2">Coach</h3>
            </motion.div>
            <motion.div
              className="rounded-3xl items-center bg-white h-[30vh] md:h-[44vh] lg:h-[60vh] xl:h-[68vh] lg:pb-4 shadow-xl shadow-blue-300"
              initial={{ opacity: 0, scale: 0.9, x: 50 }}
              animate={inView ? { opacity: 1, scale: 1, x: 0 } : { opacity: 0, scale: 0.9, x: 50 }}
              transition={{ ease: 'easeInOut', duration: 1.5 }}
            >
              <img src={ImageToImport3} alt="Card 4" className="h-4/5 lg:h-5/6 w-[30vw] lg:pb-4 " />
              <h3 className="text-md md:text-lg lg:text-3xl font-bold mb-2">Card 4</h3>
            </motion.div>
          </Slider>
        </div>
      </div>
    </motion.section>
  );
};

export default Section6;
