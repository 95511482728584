import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import image from "../../../assets/MainMenu/Section19.png"
import img2 from "../../../assets/MainMenu/section20.png"

const Section20 = () => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.2 // Trigger animation when 50% of the section is in view
  });

  return (
    <motion.section
      ref={ref}
      className="w-full py-24"
      initial={{ opacity: 0, y: 80 }} // Initial state (not in view)
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }} // Animate when in view
      transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Transition with ease-in-out
    >
      <div className="max-w-screen">
        {/* Heading */}
        <div className="text-center mb-4 md:mb-8 px-4 md:px-12 lg:px-28">
          <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold">What is the Platform?</h2>
        </div>

        {/* First Image (Slide from Top) */}
        <motion.div
          className="text-center mb-8 max-w-screen-xl mx-auto" 
          initial={{ opacity: 0, y: -50 }} // Initial state (not in view)
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : -50 }} // Animate when in view
          transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Transition with ease-in-out
        >
          <img src={image} alt="Image" className="" />
        </motion.div>

        {/* First grid */}
        <div className="grid grid-cols-12 gap-4 mb-8 px-4 md:px-12 lg:px-28">
          {/* Left section */}
          <div className="col-span-12 md:col-span-6">
            <div className=" p-4 rounded ">
              
              <p className='text-lg md:text-xl lg:text-2xl '>The Platform is an open space that provides a global umbrella for developing social, communication, and team working skills; the ability to learn from experience, control behaviours, and make good choices; and the self-esteem, resilience, and motivation to persist towards goals and overcome setback. </p>
            </div>
          </div>
          {/* Right section */}
          <div className="col-span-12 md:col-span-6">
            <div className=" p-4 rounded ">
              
              <p className='text-lg md:text-xl lg:text-2xl  '>​​The platform provides a space for young people to express their skills and competencies through connection and collaboration. The key focus of youth work is to enable young people to develop holistically, working with them to facilitate their personal, social and educational development, to enable them to develop their voice, influence and place in society, and to reach their full potential.</p>
            </div>
          </div>
        </div>

        {/* Second grid */}
        <div className="grid grid-cols-12 gap-4 px-4 md:px-12 lg:px-28 pt-10" >
          {/* Left side */}
          <div className="col-span-12 md:col-span-6 md:py-6 lg:py-12 xl:py-16">
            <div className="bg-white p-4 lg:px-8 xl:px-10">
              <ul className="list-none space-y-4 xl:space-y-6">
                <li className="flex items-start">
                  <svg className="mt-2 mr-2 flex-shrink-0 h-4 w-4 text-[#0086E8]" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="4"/>
                  </svg>
                  <span className="text-lg md:text-xl lg:text-2xl" style={{ lineHeight: '140%' }}>E-leadership journey at university.</span>
                </li>
                <li className="flex items-start">
                  <svg className="mt-2 mr-2 flex-shrink-0 h-4 w-4 text-[#0086E8]" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="4"/>
                  </svg>
                  <span className="text-lg md:text-xl lg:text-2xl" style={{ lineHeight: '140%' }}>Career, education and training, and informal learning such as e-mentoring and e-coaching</span>
                </li>
                <li className="flex items-start">
                  <svg className="mt-2 mr-2 flex-shrink-0 h-4 w-4 text-[#0086E8]" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="4"/>
                  </svg>
                  <span className="text-lg md:text-xl lg:text-2xl" style={{ lineHeight: '140%' }}>Events and open lectures to stimulate the vision of the students.</span>
                </li>
                <li className="flex items-start">
                  <svg className="mt-2 mr-2 flex-shrink-0 h-4 w-4 text-[#0086E8]" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="4"/>
                  </svg>
                  <span className="text-lg md:text-xl lg:text-2xl" style={{ lineHeight: '140%' }}>Education programmes, specific training courses, or coaching, consulting and the co-creation of knowledge.</span>
                </li>
                <li className="flex items-start">
                  <svg className="mt-2 mr-2 flex-shrink-0 h-4 w-4 text-[#0086E8]" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="4"/>
                  </svg>
                  <span className="text-lg md:text-xl lg:text-2xl" style={{ lineHeight: '140%' }}>Vision for potential for innovation translating into an implementation plan</span>
                </li>
              </ul>
            </div>

          </div>
          {/* Right side */}
          <div className="col-span-12 md:col-span-6 p-8">
            <img src={img2} alt="Image" className="w-full h-full" />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Section20;
