import React from "react";
import LeftSideImage from "../Templates/LeftSideImage";
import smmain from "../../../assets/Publication/Section3_image.png";

const Section3 = () => {
  const data = {
    title: (
      <>
        Tell Your <span className="font-bold">Unique Club Story, Connect</span>{" "}
        with <span className="font-bold">Ease</span>
      </>
    ),
    description: (
      <>
        Elevate your institute's clubs and activities. Our platform simplifies
        management, customizes publications, and enhances visibility. Bring your
        institute's vibrancy to life with effortless documentation
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <LeftSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section3;
