import React from "react";
import img1 from "./newImages/img-1.png";
import img2 from "./newImages/img-2.png";
import img3 from "./newImages/img-3.png";
import img4 from "./newImages/img-4.png";
import img5 from "./newImages/img-5.png";
import img6 from "./newImages/img-6.png";
import img7 from "./newImages/img-7.png";

const data = [
  {
    name: "Recognisation by students and industries",
    image: img5,
  },
  {
    name: "Smooth recruitment by industry ",
    image: img7,
  },
  {
    name: "Network of mutual support",
    image: img6,
  },
  {
    name: "Focus on core learning ",
    image: img1,
  },
  {
    name: "Improve employability engagement",
    image: img2,
  },
  {
    name: "Support the delivery of the required skill",
    image: img3,
  },
  {
    name: "Better Engagement of students & faculty",
    image: img4,
  },
];

const Section2 = () => {
  return (
    <section className="  py-20 pt-20">
      <div className="max-w-screen-xl mx-auto grid grid-cols-12 justify-center items-center">
        {data?.map((e, index) => {
          return (
            <div className="col-span-3 h-96 p-4 ">
              <div className="rounded-lg bg-yellow-400 ">
                <div className="w-full h-48">
                  <img
                    className="w-full h-full object-cover rounded-t-lg"
                    src={e.image}
                  />
                </div>
                <div className="w-full h-32 p-3 text-2xl font-semibold text-black">
                  {e.name}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Section2;
