import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img1 from "../../../assets/MainMenu/Section10image1.png"

import card1 from "../../../assets/MainMenu/Section10card1.png"
import card2 from "../../../assets/MainMenu/Section10card2.png"
import card3 from "../../../assets/MainMenu/Section10card3.png"
import card4 from "../../../assets/MainMenu/Section10card4.png"
import card5 from "../../../assets/MainMenu/Section10card5.png"
import card6 from "../../../assets/MainMenu/Section10card6.png"
import card7 from "../../../assets/MainMenu/Section10card7.png"
import card8 from "../../../assets/MainMenu/Section10card8.png"
import card9 from "../../../assets/MainMenu/Section10card9.png"
import card10 from "../../../assets/MainMenu/Section10card10.png"
import card11 from "../../../assets/MainMenu/Section10card11.png"
import card12 from "../../../assets/MainMenu/Section10card12.png"
import image from "../../../assets/MainMenu/Section10image.png"


const Section10 = () => {
  const data2 = [
    {
      title: (
        <>
          <span className="font-semibold">
            Many students don't know
          </span>{" "}
          what they want to do with their life when they get in
        </>
      ),
      img: card1,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            Emotional support of faculties
          </span>{" "}
          or parents to students hence they get involved in extrajudicial extracurricular activities.
        </>
      ),
      img: card2,
    },
    {
      title: (
        <>
          <span className="font-semibold">Rural and underprivileged </span>
          communities still lack access to quality education
        </>
      ),
      img: card3,
    },
    {
      title: (
        <>
          <span className="font-semibold">Society and our Education system</span>{" "}
          put so much pressure on children that they suffocate and hence could not even study and reach their dreams.
        </>
      ),
      img: card4,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            Self-esteem and Confidence </span>
          got shattered by high expectations and pressure
        </>
      ),
      img: card5,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            Energy to study and even interact
          </span>{" "}
          with anyone, never-ending cycle of feeling worthless and being stressed out
        </>
      ),
      img: card6,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            Curriculam used in schools and colleges
          </span>
          are not in line with the latest developments in technology and society
        </>
      ),
      img: card7,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            Teachers are forced to teach
          </span>{" "}
          and students are forced to learn it's not spontaneous
        </>
      ),
      img: card8,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            {" "}
            Exam oriented teaching and study
          </span>{" "}
          hence the learning is only available with student up to exam and when they go to join industry they are at point zero again.
        </>
      ),
      img: card9,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            {" "}
            Problem is pressure from parents.
          </span>{" "}
          we students have the right attitude to handle pressure but parents are so dumb to handle it
        </>
      ),
      img: card10,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            {" "}
            Lack of awareness -
          </span>{" "}

          Most of students are watching  movie, series nd attend any workshop nt meet senior nd nt want to explore knowledge these things are big problem.
        </>
      ),
      img: card11,
    },
    {
      title: (
        <>
          <span className="font-semibold">
            {" "}
            The high dropout
          </span>{" "}
          rates for students from these communities.
        </>
      ),
      img: card12,
    },
  ];

  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger the animation only once
    threshold: 0.1, // Percentage of the section visible to trigger the animation
  });

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
      className="py-12 md:py-16 lg:py-20 xl:py-28 px-4 md:px-12 lg:px-20 xl:px-28"
    >
      <div className="">
        <div className="mb-8">
          <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">Introducing THINKSKILLINDIA Platform</h2>
        </div>
        <div className="flex flex-col  justify-center items-center mb-8 mt-4">
          <img src={img1} alt="Section 10 Image" className="w-full md:w-auto mb-4 md:mr-8" />
          <div>
            <div className="text-xl lg:text-2xl xl:text-3xl my-6 md:my-4 lg:my-4 xl:my-8 font-medium" style={{ lineHeight: '140%' }}>
              <span className='text-blue-500 text-4xl '>HPDSx</span> is launching THINKSKILLINDIA platform to help our country make the most of our talent advantage, while building a more inclusive economy in the process.
            </div>
            <div className="text-xl lg:text-2xl xl:text-3xl mb-2" style={{ lineHeight: '140%' }}>
              THINKSKILLINDIA platform is a national talent platform that helps fast-growing companies access the talent they need to compete and succeed globally, while creating new career pathways for workers to rapidly transition into high-demand roles.
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-3 md:gap-2 lg:gap-4">
          <div className="col-span-12 md:col-span-6">
            <div className="p-4 md:p-3 lg:p-6">
              <ul className="list-none space-y-2 mt-4 text-lg md:text-xl lg:text-2xl xl:text-3xl">
                <li className="flex items-start">
                  <svg className="mt-5 mr-2 flex-shrink-0 h-6 w-6 text-blue-500" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="4"/>
                  </svg>
                  <span className="py-3 md:py-2 lg:py-3 xl:py-4 text-justify">Access to Skills for Success training tools is increased for the Indian rural community.</span>
                </li>
                <li className="flex items-start">
                  <svg className="mt-5 mr-2 flex-shrink-0 h-6 w-6 text-blue-500" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="4"/>
                  </svg>
                  <span className="py-3 md:py-2 lg:py-3 xl:py-4 text-justify">Increased awareness of SMEs and training providers to integrate the Skills for Success into HR practices.</span>
                </li>
                <li className="flex items-start">
                  <svg className="mt-5 mr-2 flex-shrink-0 h-6 w-6 text-blue-500" fill="currentColor" viewBox="0 0 8 8">
                    <circle cx="4" cy="4" r="4"/>
                  </svg>
                  <span className="py-3 md:py-2 lg:py-3 xl:py-4 text-justify">Participants improved their fundamental and transferable skills in reading, numeracy, problem-solving, communication, collaboration, adaptability, and creativity and innovation.</span>
                </li>
              </ul>

            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="bg-white p-6">
              <img src={image} alt="Right Side Image" className="w-full rounded" />
            </div>
          </div>
        </div>

        <div className="pt-12 w-full h-full grid grid-cols-2 md:grid-cols-3 md:text-sm lg:text-md xl:text-2xl text-xs gap-4 xl:gap-3 lg:gap-4 md:gap-3 ">
          {data2.map((data, index) => (
            <motion.div
            key={index}
            
            className="flex flex-col gap-4 lg:gap-4 xl:gap-4 p-4 border border-gray-50"
            >
              <img
                src={data.img}
                className="xl:w-12 lg:w-9 md:w-6 xl:h-12 lg:h-9 md:h-6 w-6 h-6"
                alt={data.title}
              />
              <div className="text-justify">{data.title}</div>
            </motion.div>
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default Section10;
