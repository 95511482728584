import CardImage1 from "../../../assets/HPDSxMenu/Section5image1.png";
import CardImage2 from "../../../assets/HPDSxMenu/Section5image2.png";
import CardImage3 from "../../../assets/HPDSxMenu/Section5image3.png";
import CardImage4 from "../../../assets/HPDSxMenu/Section5image4.png";
import CardImage5 from "../../../assets/HPDSxMenu/Section5image5.png";
import CardImage6 from "../../../assets/HPDSxMenu/Section5image6.png";

import mentalh1 from "./Images/mentalh1.png";
import mentalh2 from "./Images/mentalh2.png";
import mentalh3 from "./Images/mentalh3.png";
import mentalh4 from "./Images/mentalh4.png";
import mentalh5 from "./Images/mentalh5.png";
import mentalh6 from "./Images/mentalh6.png";
import mentalh7 from "./Images/mentalh7.png";


const DatasetSec5 = [
  {
    id: 1,
    description: (
      <>
        Be good to yourself
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          {" "}
          clearing wrong mindsets
        </span>
      </>
    ),
    image: mentalh1,
  },
  {
    id: 2,
    description: (
      <>
        Self-support from
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          {" "}
          negative effects
        </span>{" "}
        on personal well-being
      </>
    ),
    image: mentalh2,
  },
  {
    id: 3,
    description: (
      <>
        Mechanism for genuine
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          {" "}
          health and wealth creation{" "}
        </span>{" "}
      </>
    ),
    image: mentalh3,
  },
  {
    id: 4,
    description: (
      <>
        Find powerful
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          {" "}
          upmymind tools
        </span>{" "}
        to help you reimagine culture of developmental mindsets
      </>
    ),
    image: mentalh4,
  },
  {
    id: 5,
    description: (
      <>
        Physical, mental and emotional detoxification protection from
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          {" "}
          stress adoption
        </span>
      </>
    ),
    image: mentalh5,
  },
  {
    id: 6,
    description: (
      <>
        Protected from
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          {" "}
          vulnerable to negative
        </span>{" "}
        related consequences in life for propserity
      </>
    ),
    image: mentalh6,
  },
  {
    id: 7,
    description: (
      <>
        Emotional, moral, and spiritual experience, practice and faith
        sustaining for
        <span className=" font-semibold" style={{ color: "rgb(0, 134, 232)" }}>
          {" "}
          physical, mental health and longevity
        </span>
      </>
    ),
    image: mentalh7,
  },
];

export default DatasetSec5;
