import React from 'react';

const Section1 = () => {
  const sectionStyle = {
    background: 'linear-gradient(to top, rgba(0, 101, 138, 1), rgba(0, 176, 240, 1))'
  };

  return (
    <section style={sectionStyle} className="w-full h-screen text-white  xl:py-80 py-52 md:py-64 lg:py-72  ">
      <div className="max-w-screen text-center justify-center items-center">
        <h1 className="text-4xl md:text-6xl lg:text-7xl xl:text-8xl font-bold mb-4 mr-4" style={{ lineHeight: '140%' }}>Collaborations</h1>
        <p className="text-xl md:text-2xl lg:text-3xl xl:text-4xl  mb-8" style={{ lineHeight: '140%' }}>Institutions-industry-Students</p>
        {/* Add more content as needed */}
      </div>
    </section>
  );
};

export default Section1;

// 