import React from "react";
import Section1 from "../../components/SkillCollaboratory/Section1";
import Section2 from "../../components/SkillCollaboratory/Section2";
import Section3 from "../../components/SkillCollaboratory/Section3";
import Section4 from "../../components/SkillCollaboratory/Section4";
import Section5 from "../../components/SkillCollaboratory/Section5";
import Section6 from "../../components/SkillCollaboratory/Section6";
import Section7 from "../../components/SkillCollaboratory/Section7";
import Section8 from "../../components/SkillCollaboratory/Section8";
import Section9 from "../../components/SkillCollaboratory/Section9";
import Section10 from "../../components/SkillCollaboratory/Section10";
import Section11 from "../../components/SkillCollaboratory/Section11";
import Section12 from "../../components/SkillCollaboratory/Section12";
import Section13 from "../../components/SkillCollaboratory/Section13";
import Section14 from "../../components/SkillCollaboratory/Section14";


export const Skill = () => {
    return (
        <div className="flex flex-col font-poppins">
    <Section1/>
    <Section2/>
    <Section3/>
    <Section4/>
    <Section5/>
    <Section6/>
    <Section7/>
    <Section8/>
    <Section9/>
    <Section10/>
    <Section11/>
    <Section12/>
    <Section13/>
    <Section14/>
    </div>
  )
}