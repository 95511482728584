import React from "react";
import imgURL from "../../../assets/Collaboratory/sec7_img1.jpg";
import leftArrow from "../../../assets/Collaboratory/sec8_leftArrow.png";
import rightArrow from "../../../assets/Collaboratory/sec8_rightArrow.png";
import LeftImageSec8 from "./LeftImageSec8";
import RightImageSec8 from "./RightImageSec8";
import image from "../../../assets/TalentCollaboratory/section9image.png"

const data1 = {
  
  item2: (
    <>
      Many systems <span className="text-yellow-400">One user experience</span>
    </>
  ),
  item3: (
    <>
     You've invested in tons of tools and technology for your people and organisation—but do   
      <br /> they all work together? The truth is, there’s a massive void at the center of the workforce
       <br /> engagement and organisational growth ecosystem because there’s no centralized user
      <br/> experience unifying everything. Let’s fill that void.
    </>
  ),
};

const data2 = [
  {
    title: (
      <span className="text-blue-500">Increase Academic Success of Student</span>
    ),

    
    desc: (
      <>
        Individual’s academic success on his or her skills and abilities to attend to tasks and teachers and classroom expectations with minimal distraction, skill and abilities required to acquire necessary information, complete assignments, and participate in classroom activities and discussions, academic learning, study skills, memory techniques, etc…
      </>
    ),
    img: imgURL,
  },
  {
    title: (
      <span className="text-green-500">
       Improving College And Skill Readiness{" "}
      </span>
    ),
    
    desc: (
      <>
        Mapping of taxonomy of careers against appropriate education & skill development for the individuals such as Self-improvement skills, interpersonal skills, communication skills, career-preparation skills, and on-the-job skills which are known as the 21st century skills to survive in the uncertain economic environment.
      </>
    ),
    img: imgURL,
  },
  {
    title: (
      <span className="text-yellow-500">
        Improve Student Success{" "}
      </span>
    ),
    
    desc: (
      <>
        Helps students make a successful transition into the workforce and into personal success, free from limiting habits and preconceptions, make the students understand how the dreams, goals, values and highest aspirations can be integrated into personal, academic and professional life.
      </>
    ),
    img: imgURL,
  },
  {
    title: (
      <span className="text-red-500">
        New Student Development Paradigm
      </span>
    ),
   
    desc: (
      <>
        To develop a clear vision and setting goals for the development of a highly skilled individuals to enable learning journeys, career journey and prepare and participate in communities and workplaces, automatically personalize development for one’s skills, roles, and goals, with more powerful, relevant, and connected by mentors and coaches where they are and empowering them to explore and engage with their passions, and to weave learning into Data-driven skills intelligence in collective intelligence systems.
    </>
    ),
    img: imgURL,
  },
  {
    title: (
      <span className="text-green-500">
        Success Journey Pathways
      </span>
    ),
    
    desc: (
      <>
       our guiding principles and dedicated experts will directly interact with the individuals to develop a personal growth plan for each individuals with effective regular coaching, mentoring and counseling sessions  facilitating aligning making the fatal mistakes made by the individuals.
      </>
    ),
    img: imgURL,
  },
  {
    title: (
      <span className="text-green-500">
        Global Competency Development
      </span>
    ),
    
    desc: (
      <>
        Is a systematic, research-based assessment and skill-building system designed for the young aspirant based on the followings; developing interest for academic skills, adapt to change, develop leadership skills, enhance ability to learn, build teamwork skills, building emotional confidence.
      </>
    ),
    img: imgURL,
  },
  {
    title: <span className="text-red-500">Student Success Profile</span>,
   
    desc: (
      <>
        Student succeess profile based on our commitment to help all students to decide on their future career; plan to acquire the right knowledge, skills and attitude to compete in graduate employment markets globally. Our Student succeess profile programs are fully integrated into skill requirement by the industry and providing high quality professional support for all our students. In addition to one-to-one guidance, it provides access to industry experts for graduate employment, placements and internships
      </>
    ),
    img: imgURL,
  },
  {
    title: <span className="text-blue-500">Preparing 21st Century Minds</span>,
   
    desc: (
      <>
        Learn how to think through and resolve everyday conflicts and get along with others. Focus will include specifi c interpersonal thinking skills that guide behavior. Interactive activities will help participants learn how to apply a problem solving style of talk that helps to prevent and reduce early high-risk behaviors such as physical, verbal, and relational aggression, inability to wait and cope with frustration and social withdrawal.
      </>
    ),
    img: imgURL,
  },
  {
    title: <span className="text-green-500">Develop Skills On Proper Habits Of Mind</span>,
   
    desc: (
      <>
       Individuals collaborate to solve complex problems within the virtual world, such as figuring out what combination of individual skills, proficiencies, and equipment are necessary to conquer and to help students deal with stress, handle a crisis or trauma, cope with the transition to college, gain strength from gender and cultural identity, or manage serious mental illness and many other issues
      </>
    ),
    img: imgURL,
  },
  {
    title: <span className="text-yellow-500">Promoting Social And Emotioanl Learning</span>,
   
    desc: (
      <>
        Addressing common educational and psychosocial problems (e.g., learning problems; language difficulties; attention problems; school adjustment and other life transition problems; attendance problems and dropouts; social, interpersonal, and familial problems; conduct and behavior problems; delinquency and gang-related problems; anxiety problems; affect and mood problems; sexual and/or physical abuse; neglect; substance abuse; psychological reactions to physical status and activity)
      </>
    ),
    img: imgURL,
  },
  
  
  ];

const Section8 = () => {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };

  return (
    <div className="w-full h-full  bg-slate-100 xl:px-24 lg:px-16 px-4 xl:py-24 lg:py-16  md:py-12 py-8">
      <div className=" text-center flex flex-col xl:gap-6 lg:gap-4 md:gap-2 gap-2">
        <div className="flex flex-col md:flex-row justify-between  items-center">
            <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">TRANSFORM BUSINESS PROCESS</li>
            <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">ENGAGE PEOPLE INTEREST</li>
            <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">SAVE VALUABLE TIME</li>
        </div>
        <div className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-blue-500 font-semibold my-2">
          {data1.item2}
        </div>
        <div className="xl:text-3xl lg:text-3xl md:text-base text-sm xl:mb-8 lg:mb-6 md:mb-4 mb-2 "style={{ lineHeight: '140%' }}>
          {data1.item3}
        </div>
        
        <div className='py-4 '>
            <img src={image} className='mx-auto '/>
        </div>
      </div>
      <div>
        {data2.map((item, index) =>
          index % 2 ? (
            <div>
              <RightImageSec8 data={item} />
              {data2.length - 1 !== index && (
                <div>
                  <img
                    src={leftArrow}
                    className="w-1/6 xl:-my-24 lg:-my-16 md:-my-8 -my-4 xl:-ml-12 lg:-ml-10 md:-ml-8 -ml-6"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-end">
              <LeftImageSec8 data={item} />
              {data2.length - 1 !== index && (
                <div>
                  <img
                    src={rightArrow}
                    className="xl:w-4/6 lg:w-3/5 md:w-4/6 w-3/6 xl:-my-24 lg:-my-16 md:-my-8 -my-4 xl:mr-12 lg:mr-6 md:-mr-8 -mr-2"
                  />
                </div>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Section8;
