import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import sampleImage1 from '../../../assets/HPDSxMenu/Section14image1.png';
import sampleImage2 from '../../../assets/HPDSxMenu/Section14image2.png';
import sampleImage3 from '../../../assets/HPDSxMenu/Section14image3.png';
import sampleImage4 from '../../../assets/HPDSxMenu/Section14image4.png';
import importedImage from '../../../assets/ChangeAgent/Section11image1.png';
import image11 from "../../../assets/ChangeAgent/image11.png";
import Section11imagefirst from '../../../assets/ChangeAgent/Section11imagefirst.png';

const Section11 = () => {
    const { ref, inView } = useInView({
        triggerOnce: false, // Allow animation to trigger multiple times
        threshold: 0.2, // Adjust as needed
    });

    const sectionVariants = {
        hidden: { opacity: 0, y: 80 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.9, ease: 'easeInOut' , delay:0.4 },
        },
    };

    const cardVariants = {
        hidden: { opacity: 0, y: -80 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, ease: 'easeInOut' , delay:0.5},
        },
    };

    const imageVariant = {
        hidden: { opacity: 0, y: 80 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, ease: 'easeInOut', delay: 0.3 },
        },
    };

    return (
        <motion.section
            ref={ref}
            className="py-8 md:py-12 lg:py-28"
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            variants={sectionVariants}
        >
            <div className="">
                <div className='flex flex-row justify-center items-center'>
                    <img src={image11} className='w-10 h-10 md:w-16 md:h-16 mx-[8px] mb-4 md:mb-6'></img>
                    <h2 className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-center mb-4 md:mb-8">Your <span className='' style={{ color: 'rgb(0, 134, 232)' }}>Future</span></h2>
                </div>
                
                <div className="mb-4 md:mb-8">
                    {/* Descriptions */}
                    <p className="md:text-xl lg:text-2xl my-4 md:my-2 px-4 md:px-8 lg:px-16 xl:px-32" style={{ lineHeight: '140%' }}>We should educate- all of them - to join in the conversation about those choices and to influence that future.</p>
                    <p className="md:text-xl lg:text-2xl md:my-8 px-4 md:px-8 lg:px-16 xl:px-32" style={{ lineHeight: '140%' }}>Produce an environment capable of supporting wealth creation and social cohesion around the globe engaging your audience using the right platform to deliver the future before anyone else assess Industries and occupation.</p>
                </div>
                
                <div className="grid grid-cols-12 gap-2 md:gap-4 mx-4 md:mx-8 lg:mx-16 xl:mx-32">
                    {/* Left section */}
                    <div className="col-span-12 md:col-span-6">
                        <div className="grid grid-cols-2 gap-4 md:gap-2">
                            {/* Top row */}
                            <motion.div className="col-span-6 flex flex-row" variants={cardVariants}>
                                {/* Card 1 */}
                                <motion.div className="bg-white mt-4 md:mt-2 lg:mt-6  md:p-2 " variants={cardVariants}>
                                    <img src={sampleImage1} alt="Sample Image 1" className="w-16 h-16 object-contain rounded-md mb-4" />
                                    <p className="text-gray-800 md:text-md lg:text-xl xl:text-xl md:leading-tight xl:leading-relaxed" style={{ lineHeight: '140%' }}>Mechanism​ for genuine health and wealth creation​</p>
                                </motion.div>
                                {/* Card 2 */}
                                <motion.div className="bg-white mt-4 md:mt-2 lg:mt-6  md:p-2" variants={cardVariants}>
                                    <img src={sampleImage2} alt="Sample Image 2" className="w-16 h-16 object-contain rounded-md mb-4" />
                                    <p className="text-gray-800 md:text-md lg:text-xl xl:text-xl md:leading-tight xl:leading-relaxed" style={{ lineHeight: '140%' }}>Protected ​from vulnerable​to negative ​work-related consequences</p>
                                </motion.div>
                            </motion.div>
                            {/* Bottom row */}
                            <motion.div className="col-span-6 flex flex-row" variants={cardVariants}>
                                {/* Card 3 */}
                                <motion.div className="bg-white mt-4 md:mt-2 lg:mt-6  md:p-2 " variants={cardVariants}>
                                    <img src={sampleImage3} alt="Sample Image 1" className="w-16 h-16 object-contain rounded-md mb-4" />
                                    <p className="text-gray-800 md:text-md lg:text-xl xl:text-xl md:leading-tight xl:leading-relaxed" style={{ lineHeight: '140%' }}>Emotional, moral, ​and spiritual​ experience, practice,​ and faith sustaining physical, mental ​health and longevity​ in 21st century​</p>
                                </motion.div>
                                {/* Card 4 */}
                                <motion.div className="bg-white mt-4 md:mt-2 lg:mt-6  md:p-2" variants={cardVariants}>
                                    <img src={sampleImage4} alt="Sample Image 2" className="w-16 h-16 object-contain rounded-md mb-4" />
                                    <p className="text-gray-800 md:text-md lg:text-xl xl:text-xl md:leading-tight xl:leading-relaxed" style={{ lineHeight: '140%' }}>To enable young ​women professionals to unleash their talents to help address human potential, leverage 21st century technology in creative ways and help those in need.</p>
                                </motion.div>
                            </motion.div>
                        </div>
                    </div>
                    {/* Right section */}
                    <motion.div className="col-span-12 md:col-span-6 py-8 md:py-20 lg:py-0" variants={imageVariant}>
                        {/* Image goes here */}
                        <img src={importedImage} alt="Right Image" className="w-full" />
                    </motion.div>
                </div>
            </div>
        </motion.section>
    );
};

export default Section11;
