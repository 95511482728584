import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import bgImage from '../../../assets/HPDSxMenu/Section8image1.png';
import DatasetSec8 from './DatasetSec8';

const YourComponent = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Allow animation to trigger multiple times
    threshold: 0.3, // Adjust as needed
  });

  const slideLeftVariants = {
    hidden: { opacity: 0, x: -60 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.9, ease: 'easeInOut', delay: 0.4 },
    },
  };

  const slideRightVariants = {
    hidden: { opacity: 0, x: 60 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.9, ease: 'easeInOut', delay: 0.4 },
    },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      }}
      transition={{ duration: 0.9, ease: 'easeInOut', delay: 0.4 }}
      className="py-8 md:py-20 mx-8 md:mx-8 lg:mx-12 xl:mx-20"
    >
      {/* Heading */}
      <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-center mb-6">
        <span className='mx-6'><FontAwesomeIcon icon={faCheckDouble} style={{color: "#316ed8"}} /></span>
        Our Philosophy in <span className=''style={{ color: 'rgb(0, 134, 232)' }}>Action</span>
      </h1>

      {/* Grid */}
      <div className="grid grid-cols-12 md:gap-4 lg:gap-6 xl:gap-10 mt-8 md:mt-16">
        {/* Left Section */}
        <motion.div
          className="col-span-12 md:col-span-6 lg:col-span-6"
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={slideLeftVariants}
        >
          <img src={bgImage} alt="Background" className="w-full md:h-full" />
        </motion.div>

        {/* Right Section */}
        <motion.div
          className="col-span-12 md:col-span-6 lg:col-span-6 md:py-2 xl:py-4"
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={slideRightVariants}
        >
          {/* Right Section Content */}
          <div>
            {/* Heading */}
            <h2 className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-bold  px-4 md:px-0 mb-2 md:mb-2 lg:mb-4 xl:mb-10" style={{ color: 'rgb(0, 134, 232)' }}>In Search For Happiness In Life</h2>
            
            {/* Subheading */}
            <p className="text-2xl md:text-2xl lg:text-2xl xl:text-3xl font-medium px-4 md:px-0 mb-4 md:mb-2 lg:mb-4">Develop Human Potential to Create</p>
            
            {/* List with Vertical Progress Bar */}
            <ul className="list-disc md:mx-2 lg:mx-4 xl:mx-8 md:mt-6 xl:mt-10">
              {DatasetSec8.map((item, index) => (
                <li key={index} className="relative mb-8">
                  {/* List Text */}
                  <div className='md:text-lg lg:text-xl xl:text-2xl font-medium leading-relaxed'>{item.description}</div>
                </li>
              ))}
            </ul>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default YourComponent;
