import React from "react";
import img1 from "../../../assets/About Hpds/section1Img1.png";
import img2 from "../../../assets/About Hpds/section1Img2.png";
import img3 from "../../../assets/About Hpds/section1Img3.png";

const Section1 = () => {
  const dataset = [
    {
      image_url: img1,
      title: "Creative Will",
      description:
        "Creative will power development to hone your skills and put your laziness on their line in pursuit of real something greater.",
    },
    {
      image_url: img2,
      title: "Conscious Mind",
      description:
        "Conscious mind power empowerment to build your habit and course-correct failures as learning opportunities to make a lasting impact and impression.",
    },
    {
      image_url: img3,
      title: "Core Genius",
      description:
        "Core genius power alignment to make better decisions and eliminate ignorance about most promising paths to share the creations of the best of you leading to new opportunities and explore a broad range of possibilities for growth.",
    },
  ];

  return (
    <div className="px-3 pb-8 xl:px-28 lg:px-20 md:px-12 xl:py-16 lg:py-14 md:py-10">
      <div className="flex-col justify-center items-center gap-2 md:gap-5 flex">
        <div className="text-center text-stone-900 text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold xl:mx-36">
          The best Possible way to Re-engineering the way we think, act and
          communicate
        </div>
        <div className="opacity-60 text-center text-stone-900 text-sm md:text-lg lg:text-2xl xl:text-3xl font-semibold xl:-mx-6">
          Grow your Expertise, take Systematic steps and build on incredible
          paths to Maximise your Potential manage every way to Realise your full
          Potential
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 md:gap-6 xl:gap-12 mt-6 md:mt-16">
        {dataset.map((item, index) => (
          <div key={index} className="col-span-12 md:col-span-4 flex-col my-3 md:my-0">
            <img
              className="w-full h-48 md:h-36 lg:h-44 xl:h-64 rounded-lg"
              src={item.image_url}
              alt={item.title}
            />
            <div className="flex-col mt-3 lg:mt-6 justify-start items-start gap-1 lg:gap-5 flex">
              <div className="text-stone-900 text-lg md:text-lg lg:text-2xl xl:text-3xl font-semibold">
                {item.title}
              </div>
              <div className="text-stone-900 text-sm lg:text-md xl:text-lg">{item.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section1;
