import React from "react";
import LandingCover from "../../components/Conference/LandingCover";
import ConferenceSection1 from "../../components/Conference/ConferenceSecton1";
import ConferenceSection3 from "../../components/Conference/ConferenceSection3";
import ConferenceSection2 from "../../components/Conference/ConferenceSection2";
import ConferenceSection4 from "../../components/Conference/ConferenceSection4";

export default function Scientist() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <ConferenceSection1 />
      <ConferenceSection2 />
      <ConferenceSection3 />
      <ConferenceSection4 />
    </div>
  );
}
