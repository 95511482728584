import React from "react";
import bgimg from "../../../assets/Collaboratory/Section3imagebg.png";
import img1 from "../../../assets/Collaboratory/Section3image1.png";
import img2 from "../../../assets/Collaboratory/Section3image2.png";
import img3 from "../../../assets/Collaboratory/Section3image3.png";
import img4 from "../../../assets/Collaboratory/Section3image4.png";

import mindprocess1 from "./Images/mindProcess1.png"
import mindprocess2 from "./Images/mindProcess2.png"
import mindprocess3 from "./Images/mindProcess3.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCirclePlay,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import image from "../../../assets/TalentCollaboratory/section8image1.png";

const Section4 = () => {
  const sectionStyle = {
    backgroundImage: `url(${bgimg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const handleInputChange = (e) => {
    console.log("Button pressed");
  };

  return (
    <section className="bg-cover bg-center py-8" style={sectionStyle}>
      <div className="flex justify-center pt-8 px-4">
        <img src={img1} alt="Top Image" className="" />
      </div>
      <div className="text-center px-4 md:px-8 lg:px-16 xl:px-24">
        <h2
          className="text-2xl md:text-3xl lg:text-4xl xl:text-4xl font-medium mt-8 text-white  md:px-12 lg:px-24 xl:px-32"
          style={{ lineHeight: "140%" }}
        >
          <span className="text-yellow-400">UPMYMIND’s HUMANCARE</span> Sequence
          contains a configurable mixture of any of the following solutions,
          each drawn{" "}
          <span className="text-yellow-400">
            from the Intelligent COSMIC OPTIMISM Platform
          </span>
        </h2>
        <p
          className="text-lg md:text-lg lg:text-xl xl:text-2xl text-white mt-4 md:px-24 lg:px-32"
          style={{ lineHeight: "140%" }}
        >
          The portal will provide access to AI-based services to help users
          navigate mental health services, personalized tools for monitoring
          mental health, identifying risks, and recommending services, and an
          interactive atlas of youth mental health service demand and
          availability to guide service providers and policymakers.
        </p>

        <div className="py-4">
          <img src={image} className="mx-auto" />
        </div>

        <div className="text-white text-2xl md:text-3xl lg:text-4xl xl:text-4xl font-bold text-center">
          Essentials for{" "}
          <span className="text-yellow-400">Minding Process</span>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 lg:gap-8 mt-8">
          <div className="text-center bg-white rounded-2xl p-4">
            <img src={mindprocess1} alt="Image 1" className="" />
            <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-400">
              My Mental Health
              <br></br>Fitness Tool
            </h3>
            <p className="text-md md:text-lg lg:text-xl text-gray-700 mt-4 text-justify font-medium">
              Use these same insights to provide individualized guidance and
              support to workers in operations to help them perform and their
              personal best. 
            </p>
          </div>
          <div className="text-center bg-white rounded-2xl p-4">
            <img src={mindprocess2} alt="Image 2" className="" />
            <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-400">
              My Wellness Fitness<br></br>Tool
            </h3>
            <p className="text-md md:text-lg lg:text-xl text-gray-700 mt-4 text-justify font-medium">
              uses AI-based insights on how each team member performs when
              actually using the skills in daily operations to enable targeted
              reskilling and upskilling, keeping your team operating at maximum
              productivity
            </p>
          </div>
          <div className="text-center bg-white rounded-2xl p-4">
            <img src={mindprocess3} alt="Image 3" className="" />
            <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-400">
              My Wellbeing Fitness <br></br> Tool
            </h3>
            <p className="text-md md:text-lg lg:text-xl  text-gray-700 mt-4 text-justify font-medium">
              Use these same insights to provide individualized guidance and
              support to workers in operations to help them perform and their
              personal best. .
            </p>
          </div>
        </div>
        <div className="py-8">
          <button
            className="text-white bg-blue-500 px-8 py-4 text-sm md:text-md lg:text-lg rounded-full hover:border hover:border-blue-500 hover:text-blue-500 hover:bg-transparent"
            type="submit"
            onClick={handleInputChange}
          >
            Get Started Now
            <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
          </button>
        </div>

        <div className="xl:text-md lg:text-sm text-xs  text-white">
          Get Started In Less Than 60 Seconds • Cancel Anytime
        </div>
      </div>
    </section>
  );
};

export default Section4;
