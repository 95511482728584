import React from "react";
import img_1 from "../../../assets/Socities/img_2.png";
import cover_bg from "../../../assets/Socities/img_6.jpg";
const Section2 = () => {
  const data = {
    imageUrl: img_1,
  };
  return (
    <div
      className={`w-full px-6 py-10 xl:px-28 xl:py-24 lg:px-20 md:px-12 lg:py-16 md:py-16 grid grid-cols-12 gap-6 md:gap-10 lg:gap-20 xl:gap-28 text-white`}
      style={{
        backgroundImage: `url(${cover_bg})`,
        backgroundSize: "cover", // Maintain original image size
        backgroundPosition: "center", // Center the image
      }}
    >
      <div className=" col-span-12 md:col-span-6 flex-col">
        <div className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-semibold cursor-pointer xl:pt-24">
          <span className="text-white">
            Experience Personalized Guidance and Awakening in the
          </span>{" "}
          <span className="text-yellow-400">comfort of your own space.</span>
        </div>
        <div className="xl:text-2xl lg:text-lg md:text-md text-md cursor-pointer mt-2">
          Our team of HPDS Scientists are globally recognized Awakened Mentors,
          dedicated to guiding you towards better health, happiness, and
          success.
        </div>
      </div>
      <div className="col-span-12 md:col-span-6 flex justify-end items-end">
        <img
          className="w-full h-full md:w-96 md:h-84 lg:w-full lg:h-full"
          src={data.imageUrl}
          alt={data.title}
        />
      </div>
    </div>
  );
};

export default Section2;
