import React from 'react';
import image from "../../../assets/CustomerSuccess/Section3image1.png"

const Section3 = () => {
  return (
    <section className="py-16">
      <div className=" ">
        <h2 className="text-md md:text-lg lg:text-xl font-bold mb-4 px-4 md:px-16 lg:px-20 xl:px-28 text-blue-500" style={{ lineHeight: '140%' }}>24/7 HELP WITH THE HPDSX PRODUCTS & PLATFORMS</h2>
        <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 px-4 md:px-16 lg:px-20 xl:px-28" style={{ lineHeight: '140%' }}>End-to-End Technical support</h1>
        <div className='px-4 md:px-16 lg:px-20 xl:px-32'>
          <div className="mt-20 md:mt-80 relative h-96 w-8 md:h-8 md:w-full bg-gradient-to-r from-blue-400 via-blue-600 to-purple-600 flex justify-between items-start ">
            <div className="absolute bottom-[360px] left-8 w-24 h-[2px] md:bottom-8 md:left-[50px] lg:left-[100px] xl:left-[150px]  md:h-16  md:w-[2px] bg-gradient-to-r from-blue-400 via-blue-600 to-purple-600"></div>
            <div className="absolute bottom-[240px] left-8 w-24 h-[2px] md:bottom-8 md:left-[200px] lg:left-[300px] xl:left-[400px]  md:h-16  md:w-[2px] bg-gradient-to-r from-blue-400 via-blue-600 to-purple-600"></div>
            <div className="absolute bottom-[120px] left-8 w-24 h-[2px] md:bottom-8 md:left-[350px] lg:left-[500px] xl:left-[650px]  md:h-16  md:w-[2px] bg-gradient-to-r from-blue-400 via-blue-600 to-purple-600"></div>
            <div className="absolute bottom-[8px] left-8 w-24 h-[2px] md:bottom-8 md:left-[500px] lg:left-[700px] xl:left-[900px]  md:h-16  md:w-[2px] bg-gradient-to-r from-blue-400 via-blue-600 to-purple-600"></div>
            
            <div className="absolute left-32 md:bottom-24 md:left-[50px] lg:left-[100px] xl:left-[150px] flex items-center justify-center w-8 h-8 bg-gradient-to-r from-blue-400 via-blue-600 to-purple-600 to-purple-500 rounded-full -ml-4">
              <div className='flex flex-col ml-44   md:ml-0 mb-4 md:mb-40 lg:mb-36 text-center'>
              <div className="text-xl md:text-3xl text-gray-400">01</div>
              <div className="text-xs md:text-sm w-24 w-36 font-medium">Contact Support <span className='text-blue-500'>via live</span> chat, phone call, conference call* or email </div>
              </div>
            </div>
            <div className="absolute left-32 bottom-56 md:bottom-24 md:left-[200px] lg:left-[300px] xl:left-[400px] flex items-center justify-center w-8 h-8 bg-gradient-to-r from-blue-400 via-blue-600 to-purple-600 to-purple-500  rounded-full -ml-4">
              <div className='flex flex-col ml-44   md:ml-0 mb-4  md:mb-36 lg:mb-32  text-center '>
              <div className="text-xl md:text-3xl text-gray-400">02</div>
              <div className="text-xs md:text-sm w-36 font-medium">  Track updates & <span className='text-blue-500'>respond directly</span> to support tickets</div>
              </div>
            </div>
            <div className="absolute left-32 bottom-28 md:bottom-24 md:left-[350px] lg:left-[500px] xl:left-[650px] flex items-center justify-center w-8 h-8 bg-gradient-to-r from-blue-400 via-blue-600 to-purple-600 to-purple-500  rounded-full -ml-4">
            <div className='flex flex-col ml-44   md:ml-0 mb-8  md:mb-36 lg:mb-32 text-center '>
              <div className="text-xl md:text-3xl text-gray-400">03</div>
              <div className="text-xs md:text-sm w-36 font-medium">View the <span className='text-blue-500'>status</span> of escalated tickets in <span className='text-blue-500'>real time</span> </div>
              </div>
            </div>
            <div className="absolute left-32 bottom-0 md:bottom-24 md:left-[500px] lg:left-[700px] xl:left-[900px] flex items-center justify-center w-8 h-8 bg-gradient-to-r from-blue-400 via-blue-600 to-purple-600 to-purple-500  rounded-full -ml-4">
              <div className='flex flex-col ml-44   md:ml-0   md:ml-0 md:mb-56 lg:mb-48 text-center text-center'>
              <div className="text-xl md:text-3xl text-gray-400">04</div>
              <div className="text-xs md:text-sm w-36 font-medium">Get a <span className='text-blue-500'>comprehensive view</span> of all past & present support tickets for you or your org</div>
              </div>
            </div>
          </div>
        </div>
        

        <div className=' py-20 '>
            <img src={image} className='w-5/6   mx-auto'/>
        </div>
      </div>
    </section>
  );
};

export default Section3;
