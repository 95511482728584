import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img from "../../../assets/MainMenu/Section11Image.png";

const Section3 = () => {
  const backgroundColor = '#0086E8';
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger the animation only once
    threshold: 0.2, // Percentage of the section visible to trigger the animation
  });

  return (
    <motion.section
      ref={ref}
      className="text-white py-8 md:py-16 lg:py-16 xl:py-20"
      style={{ background: backgroundColor }}
      initial={{ opacity: 0, y: 80 }} // Initial animation state
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }} // Animation when in view
      transition={{ ease: 'easeInOut', duration: 0.5, delay: 0.2 }} // Animation transition
    >
      <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 px-4 md:px-12 lg:px-12 xl:px-20 py-8" style={{ lineHeight: '140%' }}>
        <span className='text-yellow-400'>What</span> is in there for You?
      </h2>
      <div className="grid grid-cols-12 md:gap-4 lg:gap-8">
        {/* Left side with text */}
        <div className="col-span-12 md:col-span-7 lg:col-span-6 px-4 md:px-12 lg:px-12 xl:px-20">
          <motion.div
            initial={{ opacity: 0, y: 80 }} // Initial animation state
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }} // Animation when in view
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Animation transition
          >
            <div className="text-left">
              <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl mb-4 text-justify" style={{ lineHeight: '140%' }}>
                The objective is to <span className='text-yellow-400 font-semibold'> CREATE IMMERSIVE AND INCLUSIVE EXPERIENCES </span>
                that can inspire lifelong learning, stimulate development of essential life skills, and support educators in guiding and nurturing student passions.
              </h3>
              <p className="text-xl md:text-xl lg:text-2xl xl:text-3xl mb-4 text-justify" style={{ lineHeight: '140%' }}>
                Our goal is to galvanize them to create and share in entirely new approaches, to teach and learn through exploration, and to adapt to individual learning needs so they can design, invent and build with technology. In today’s connected age, the possibilities for learning are endless.
              </p>
            </div>
          </motion.div>
        </div>
        {/* Right side with image */}
        <motion.div
          className="col-span-12 md:col-span-5 lg:col-span-6 px-8 lg:px-12 xl:px-16"
          initial={{ opacity: 0, x: -30 }} // Initial animation state
          animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -30 }} // Animation when in view
          transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }} // Animation transition
        >
          <img src={img} alt="Image Description" className="rounded-lg w-full" />
        </motion.div>
      </div>
    </motion.section>
  );
};

export default Section3;
