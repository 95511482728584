import React from 'react';
import backgroundImage from '../../../assets/Carrer/Section1Bg.png'; 
import sectionImage from '../../../assets/Carrer/Section1Image.png'; 

const Section1 = () => {
  return (
    <div 
      className="bg-gray-100 grid grid-cols-12 items-center p-12 h-[70vh]"
      style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
    >
      <div className="col-span-12 md:col-span-7 text-left p-8 md:p-12 lg:py-16 xl:py-20 lg:px-28">
        <div className="text-2xl md:text-3xl lg:text-4xl xl:text-6xl font-bold mb-4 text-[#0086E8] md:max-w-xl" style={{lineHeight: '1.4', fontWeight: '800'}}>Creating Impact in every step</div>
        <button className="bg-[#0086E8] hover:bg-blue-700 text-white md:font-bold md:py-2 md:px-4 rounded-full px-2 py-1">
          Join HPDS  →
        </button>
      </div>
      <div className="col-span-12 md:col-span-5">
        <img src={sectionImage} alt="Visual representation" className="w-full h-full" />
      </div>
    </div>
  );
};

export default Section1;
