import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import Section3Image from '../../../assets/HPDSxEngine/Section3image1.png';
import DatasetSec3 from './DatasetSec3';
import { useInView } from 'react-intersection-observer';

const Section3 = () => {
  const { ref: ref1, inView: inView1 } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const { ref: ref3, inView: inView3 } = useInView();
  const controlsLeft = useAnimation();
  const controlsRight = useAnimation();
  const controlsImg = useAnimation();
  const controlsCards = useAnimation();

  useEffect(() => {
    if (inView1) {
      controlsLeft.start({
        opacity: 1,
        x: 0,
        transition: { ease: 'easeInOut', duration: 0.6, delay: 0.2 }
      });
    }
  }, [controlsLeft, inView1]);

  useEffect(() => {
    if (inView2) {
      controlsRight.start({
        opacity: 1,
        x: 0,
        transition: { ease: 'easeInOut', duration: 0.6, delay: 0.2  }
      });
    }
  }, [controlsRight, inView2]);

  useEffect(() => {
    if (inView3) {
      controlsImg.start({
        scale: 1,
        transition: { ease: 'easeInOut', duration: 0.6, delay: 0.2  }
      });
    }
  }, [controlsImg, inView3]);

  useEffect(() => {
    if (inView3) {
      controlsCards.start({
        opacity: 1,
        x: 0,
        transition: { ease: 'easeInOut', duration: 0.6, delay: 0.2  }
      });
    }
  }, [controlsCards, inView3]);

  return (
    <motion.section
      className="py-8 md:py-12 bg-blue-100"
      initial={{ opacity: 0, y: 80 }}
      animate={inView3 ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
      transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2  }}
      ref={ref3}
    >
      <div className="grid grid-cols-12 gap-2 mx-4 md:mx-4 lg:mx-12 pt-8 md:pt-0 md:py-28">
        <motion.div
          className="col-span-12 md:col-span-6"
          initial={inView1 ? { opacity: 0, x: -50 } : { opacity: 1, x: 0 }}
          animate={controlsLeft}
          ref={ref1}
        >
          <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-blue-500 font-bold mb-8" style={{ lineHeight: '140%' }}>Why Students choose <span className='text-yellow-400 font-bold'>HPDS HumanX Platform</span>​</h1>
        </motion.div>
        <motion.div
          className="col-span-12 md:col-span-6"
          initial={{ opacity: 0, x: 50 }}
          animate={controlsRight}
          ref={ref2}
        >
          <p className="md:text-lg lg:text-xl xl:text-2xl mb-4" style={{ lineHeight: '140%' }}>We have <span className='text-blue-500'>created a safe place for </span>you to begin your <span className='text-blue-500'>self development journey</span> and to connect with Trusted and ethical  practices who we feel offer inspiration, motivation and true value to you.</p>
          <div className="flex flex-row items-center mb-8">
            <button className="bg-black hover:bg-blue-700 text-white py-3 px-4 rounded-3xl mr-4 text-sm">Get Started</button>
            <span className="mr-2"><FontAwesomeIcon icon={faCirclePlay} size="2xl" style={{ color: "#64b7f7" }} /></span>
            <p className="text-sm md:text-md">Watch How It</p>
          </div>
        </motion.div>
      </div>

      <motion.img
        src={Section3Image}
        alt="Description"
        className="max-w-full md:pt-0 lg:pt-2 xl:pt-10 mx-auto"
        initial={{ scale: 0.9 }}
        animate={controlsImg}
        ref={ref3}
      />

      <div className="grid grid-cols-12 mx-4 md:mx-8 lg:mx-24 xl:mx-32 pt-4 md:pt-0 md:-mt-16 lg:-mt-28 xl:-mt-40 pb-20 cursor-pointer">
        {DatasetSec3.map(card => (
          <motion.div
            key={card.id}
            className="col-span-6 md:col-span-3 hover:bg-white"
            initial={{ opacity: 0, x: -50 }}
            animate={controlsCards}
          >
            <div className="border-2 border-blue-400 h-40 p-4 flex items-center justify-center md:text-lg lg:text-xl xl:text-2xl text-blue-400">
              <span className="mr-2"><img src={card.image} alt="Image Description" /></span>
              <p className='md:text-md lg:text-xl'>{card.title}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};

export default Section3;
