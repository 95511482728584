import React from 'react';
import bgimg from "../../../assets/Collaboratory/Section3imagebg.png"
import img1 from "../../../assets/Collaboratory/Section3image1.png"
import img2 from "../../../assets/Collaboratory/Section3image2.png"
import img3 from "../../../assets/Collaboratory/Section3image3.png"
import img4 from "../../../assets/Collaboratory/Section3image4.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight , faCirclePlay ,faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const Section3 = () => {
  const sectionStyle = {
    backgroundImage: `url(${bgimg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const handleInputChange = (e) => {
    console.log("Button pressed");
  };

  return (

    <section className="bg-cover bg-center py-8" style={sectionStyle}>
      <div className="flex justify-center pt-8 px-4">
        <img src={img1} alt="Top Image" className="" />
      </div>
      <div className="text-center px-4 md:px-8 lg:px-16 xl:px-24">
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold mt-8 text-white  md:px-24 lg:px-32"><span className='text-blue-400' style={{ lineHeight: '140%' }}>To be a World Class Institute</span> You love to use HPDSx Engine</h2>
        <p className="text-lg text-white mt-4">See For Yourself…</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 lg:gap-8 mt-8">
          <div className="text-center bg-white rounded-2xl p-4">
            <img src={img2} alt="Image 1" className="" />
            <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-400">Excellence in Education and Learning</h3>
            <p className="text-md md:text-lg lg:text-xl text-gray-700 mt-4 text-justify font-medium">HPDSx platform enables Universities and schools need to fundamental change to become academic level life-long learning and development providers for students as well as its alumnus.</p>
          </div>
          <div className="text-center bg-white rounded-2xl p-4">
            <img src={img3} alt="Image 2" className="" />
            <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-400">Excellence in Research and Development</h3>
            <p className="text-md md:text-lg lg:text-xl text-gray-700 mt-4 text-justify font-medium">HPDSx platform will enable universities and schools to collaborate learning providers include professional or industry academies, chambers of commerce, coaching and consulting organisations and publishers.</p>
          </div>
          <div className="text-center bg-white rounded-2xl p-4">
            <img src={img4} alt="Image 3" className="" />
            <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mt-4 text-blue-400">Excellence in Skill Development & Innovation</h3>
            <p className="text-md md:text-lg lg:text-xl  text-gray-700 mt-4 text-justify font-medium">The HPDSx Platform combines the different dimensions of the skills and competencies in order to monitor and benchmark the potential of the users in open collaborative with Employer, to obtain relevant skills on future of work to meet the skills challenge in the 21st century.</p>
          </div>
        </div>
        <div className='py-8'>
        <button
            className="text-white bg-blue-500 px-8 py-4 text-sm md:text-md lg:text-lg rounded-full hover:border hover:border-blue-500 hover:text-blue-500 hover:bg-transparent"
            type="submit"
            onClick={handleInputChange}
          >
            Get Started Now
            <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
          </button>
          </div>

          <div className="xl:text-md lg:text-sm text-xs  text-white">
          Get Started In Less Than 60 Seconds • Cancel Anytime
        </div>
      </div>
    </section>
  );
};

export default Section3;
