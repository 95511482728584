import React from "react";
import cover_bg from "../../../assets/CreateYourOwnAssessments/cover_bg.jpg";

const LandingCover = () => {
  return (
    <div
      className="w-full h-[120vh] md:h-[100vh] xl:h-[90vh] grid grid-cols-12 gap-0 "
      style={{
        backgroundImage: `url(${cover_bg})`,
        backgroundSize: "cover", // Maintain original image size
        backgroundPosition: "center", // Center the image
      }}
    >
      <div
        className="  h-[120vh] md:h-[100vh] xl:h-[90vh] col-span-8 md:col-span-6 "
        style={{
          backdropFilter: "blur(10px)", // Adjust the blur radius as needed
          backgroundColor: "rgba(55, 57, 68, 0.7)",
        }}
      >
        <div className="flex-col px-4  md:p-4 lg:p-6 pt-12  xl:p-20 text-xl md:text-3xl  lg:text-4xl  xl:text-5xl text-white font-semibold">
          Empower Your Journey:<br></br>
          <span className="font-bold text-yellow-500">Craft Tailored </span>
          <br></br> Assessments for Success with{" "}
          <span className="text-yellow-500 font-bold">HPDS</span>
          <div className="  text-lg md:text-xl lg:text-2xl mt-4 md:mt-6">
            Welcome to HPDS for personalized assessments!
          </div>
          <div className="text-md md:text-xl lg:text-2xl mt-4 md:mt-2">
            Discover the benefits of customized evaluations:
          </div>
          <ul className="list-disc list-inside mt-5 md:mt-0 md:m-5 text-sm md:text-xl lg:text-2xl ">
            <li className="mb-1">designed to meet your needs</li>
            <li className="mb-1">assessing participants</li>
            <li className="mb-1">optimizing efficiency</li>
          </ul>
          <div className=" text-md md:text-xl  lg:text-2xl mt-4 md:mt-0">
            Dive in and unlock the potential of personalized assessments!
          </div>
        </div>
      </div>
      <div className="px-3 text-white md:px-0 order-2 md:order-1 col-span-12 md:col-span-4 flex flex-col cursor-pointer"></div>
    </div>
  );
};

export default LandingCover;
