import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img1 from '../../../assets/MainMenu/Section9image.png';

const Section9 = () => {
    // Use useInView to detect when the section is in view
    const [ref, inView] = useInView({
        triggerOnce: false,
        threshold: 0.02,
    });

    return (
        <motion.section
            initial={{ opacity: 0 }} // Initial animation state (section starts invisible)
            animate={{ opacity: 1 }} // Trigger animation when section is in view
            transition={{ ease: 'easeInOut', duration: 1, delay: 0.2 }} // Animation transition properties
            ref={ref} // Assign the ref obtained from useInView
            className="w-full"
        >
            <div className="max-w-screen">
                <div className="grid grid-cols-12">
                    {/* Left side */}
                    <motion.div
                        
                        className="col-span-6 bg-blue-500 text-white p-6 rounded shadow"
                    >
                        <p className="md:text-5xl lg:text-6xl xl:text-7xl lg:px-8 xl:px-12 lg:pt-4 xl:pt-24 font-bold" style={{ lineHeight: '140%' }}>
                            <span className='text-[#FFD700] block'>Planting the Seeds :</span> How Students Capture Value
                        </p>
                    </motion.div>
                    {/* Right side */}
                    <motion.div
                        
                        className="col-span-6"
                    >
                        <img src={img1} alt="Image" className="w-full h-auto rounded shadow" />
                    </motion.div>
                </div>
            </div>
        </motion.section>
    );
};

export default Section9;
