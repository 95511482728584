import React from "react";
import img_1 from "../../../assets/ThinkSkillIndia/img_1.png";
import img_2 from "../../../assets/ThinkSkillIndia/img_2.jpg";
import cover_bg from "../../../assets/ThinkSkillIndia/cover_bg.jpg";

const LandingCover = () => {
  return (
    <div
      className="w-full py-16 md:px-12 lg:px-24 "
      style={{
        backgroundImage: `url(${cover_bg})`,
        backgroundSize: "cover", // Maintain original image size
        backgroundPosition: "center", // Center the image
      }}
    >
      <div className="flex flex-col justify-center font-semibold text-white  items-center mt-4 md:mt-0 px-4 md:px-10  pb-4 md:pb-4 text-4xl md:text-4xl lg:text-5xl xl:text-6xl cursor-pointer  ">
        <div className=" text-center ">THINSKILLINDIA Collaboratory</div>
        <div
          className=" text-lg md:text-xl lg:text-3xl xl:text-4xl my-4 md:my-6 text-center"
          style={{ lineHeight: "140%" }}
        >
          Helps <span className="text-yellow-500 ">Youth</span> Become What They
          Want to Be
        </div>
        <div
          className="font-bold text-center text-2xl md:text-4xl lg:text-4xl xl:text-5xl mt-8"
          style={{ lineHeight: "140%" }}
        >
          A Nation of Opportunity <br></br>
          <span className="mt-8" style={{ lineHeight: "140%" }}>
            Building Bharat's 21st Century Workforce
          </span>
        </div>
        {/* <img
          src={img_1}
          className="w-full h-full py-16 xl:py-16 lg:py-16 md:py-12 px-4 xl:px-36 lg:px-28 md:px-12 "
        /> */}
        <div className="mt-12">
        <img src={img_2} className="w- h-[500px] object-cover rounded-xl" alt="" />

        </div>
      </div>
    </div>
  );
};

export default LandingCover;
