import img1 from "../../../../assets/Hpdsx/Section3_Img1.svg";
import img2 from "../../../../assets/Hpdsx/Section3_Img2.svg";
import img3 from "../../../../assets/Hpdsx/Section3_Img3.svg";

const thirdSectionData = [
  {
    id: 1,
    title: "Reframe your ambition",
    imageUrl: img1,
  },
  {
    id: 2,
    title: "Roadmap to personal transformation",
    imageUrl: img2,
  },
  {
    id: 3,
    title: "Transform for a better future",
    imageUrl: img3,
  },
];

export default thirdSectionData;
