import React from "react";
import item_1 from "../../../assets/Socities/item_1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

function Section2() {
  const data = {
    topic1: <>Discover Technical Communities</>,
    items: [
      {
        img: item_1,
        name: "Future Networks Community",
        description:
          "The HPDS Future Networks Community is home to academics, scientists, and engineers from business, academia, and government to address the difficulties of developing and deploying future networks.",
      },
      {
        img: item_1,
        name: "Entrepreneurship Community",
        description:
          "The HPDS Entrepreneurship Community is at the forefront of transforming ideas into thriving enterprises. Participants make it a point to assist one another in not only starting but also growing their businesses, as well as to promote networking and mentorship for individuals who are passionate about turning their concept into a reality.",
      },
      {
        img: item_1,
        name: "Life Sciences Community",
        description:
          "The Life Sciences Community promotes collaboration and communication among life science and technology practitioners to progress the application of life sciences, including technology businesses, hospitals, government organisations, pharmaceutical industry researchers, and university researchers.",
      },
      {
        img: item_1,
        name: "Smart Village Community",
        description:
          "HPDS Trust takes a unique approach to assisting the world's energy-poor communities by offering a holistic solution that combines renewable energy, community-based education, and business possibilities.",
      },
    ],
  };
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };

  return (
    <div className="h-auto w-full py-14">
      <div className="max-w-screen-xl  mx-auto px-4 md:px-10 ">
        {/* <p className=" text-5xl font-semibold text-start text-blue-500 my-10 "> */}
        <div className="font-bold w-full text-start text-3xl  md:text-4xl lg:text-4xl lg:text-6xl text-blue-500   py-5 lg:py-10">
          {data.topic1}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2  gap-y-5 lg:gap-y-10 gap-x-5 lg:gap-x-10 ">
          {data.items.map((item, index) => (
            <div key={index}>
              <div className="w-full h-auto px-4 md:px-5 bg-gray-100 rounded-md ">
                <div className="font-semibold text-xl md:text-2xl lg:text-3xl py-5">
                  {item.name}
                </div>
                <div className="grid grid-cols-12 w-full h-auto text-black ">
                  <div className="col-span-12 md:col-span-6 pb-4 py-2">
                    <img src={item.img} alt={item.name} />
                  </div>
                  <div className="text-md md:text-sm  lg:text-md xl:text-lg md:px-2 lg:px-3 mb-5 col-span-12 md:col-span-6 leading-tight overflow-hidden">
                    <div
                      className="h-full "
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 10,
                      }}
                    >
                      {item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className=" text-center pt-16 ">
          <button
            className="text-white bg-blue-500 px-6 py-3 text-sm lg:text-lg rounded-lg hover:border-2 hover:border-blue-500 hover:text-blue-500 hover:bg-transparent"
            type="submit"
            onClick={handleInputChange}
          >
            Explore more
            <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
}
export default Section2;
