import sec4heading1 from "../../../assets/Platform/Section4_heading.png"

import compImg01 from "../../../assets/Platform/compute/Compute-Logos-01-Kubernetes.svg";
import compImg02 from "../../../assets/Platform/compute/Compute-Logos-02-Linux.svg";
import compImg03 from "../../../assets/Platform/compute/Compute-Logos-03-SQLServer.svg";
import compImg04 from "../../../assets/Platform/compute/Compute-Logos-05-FunctionApps.svg";
import compImg05 from "../../../assets/Platform/compute/Compute-Logos-06-AppService.svg";
import compImg06 from "../../../assets/Platform/compute/Windows.svg";
import contImg01 from "../../../assets/Platform/containers/Containers-Logos-01-AppService.svg";
import contImg02 from "../../../assets/Platform/containers/Containers-Logos-02-FunctionApps.svg";
import contImg03 from "../../../assets/Platform/containers/Containers-Logos-03-KubernetesServices.svg";
import contImg04 from "../../../assets/Platform/containers/Containers-Logos-04-ContainerInstances.svg";
import contImg05 from "../../../assets/Platform/containers/Containers-Logos-05-Redhat.svg";
import contImg06 from "../../../assets/Platform/containers/azure-spring-apps.svg";

import wellnessImg01 from "../../../assets/Platform/wellness/first.png";
import wellnessImg02 from "../../../assets/Platform/wellness/second.png";
import wellnessImg03 from "../../../assets/Platform/wellness/third.png";
import wellnessImg04 from "../../../assets/Platform/wellness/fourth.png";
import wellnessImg05 from "../../../assets/Platform/wellness/fifth.png";

import hybrImg01 from "../../../assets/Platform/hybrid/Azure-SQL1.svg";
import hybrImg02 from "../../../assets/Platform/hybrid/Hybrid_multicloud-Logos-02-AzureStack.svg";
import hybrImg03 from "../../../assets/Platform/hybrid/Hybrid_multicloud-Logos-04-Defender.svg";
import hybrImg04 from "../../../assets/Platform/hybrid/Hybrid_multicloud-Logos-05-ExpressRoute.svg";
import hybrImg05 from "../../../assets/Platform/hybrid/Hybrid_multicloud-Logos-06-DevOps.svg";
import hybrImg06 from "../../../assets/Platform/hybrid/microsoft-sentinel.svg";
import iotImg01 from "../../../assets/Platform/iot/IoT-Logos-01-IoTCentral.svg";
import iotImg02 from "../../../assets/Platform/iot/IoT-Logos-02-IoTEdge.svg";
import iotImg03 from "../../../assets/Platform/iot/IoT-Logos-03-Defender.svg";
import iotImg04 from "../../../assets/Platform/iot/IoT-Logos-04-DigitalTwins.svg";
import iotImg05 from "../../../assets/Platform/iot/IoT-Logos-05-AzureSphere.svg";
import iotImg06 from "../../../assets/Platform/iot/IoT-Logos-06-TimeSeriesInsights.svg";

const menus = [
  "Wellness revolution in education and lifelong learning",
  "Reskilling Your Skillset",
  "Customized for you",
  "online interventions",
  
];
const dataSet = [
  {
    bannerImg:
    sec4heading1,
    bannerTitle: "Wellness Revolution in education and lifelong learning",
    bannerDec:
      "The Platform assists with assessment and feedback",
    items: [
      {
        itemImg: wellnessImg01,
        itemTitle: "Youth Wellness Essentials",
        itemDesc:
          "Teach young leaders foundational knowledge (e.g. mental health 101, causes of distress, signs and symptoms",
      },
      {
        itemImg: wellnessImg02,
        itemTitle: "Mastering Essential Skills",
        itemDesc:
          "Train young leaders in relevant leadership skills (e.g. effective public speaking, storytelling, communication, community reflection, action planning)",
      },
      {
        itemImg: wellnessImg03,
        itemTitle: "Microsoft Fabric",
        itemDesc:
          "Bring data and analytics services together on an AI-powered platform.",
      },
      {
        itemImg: wellnessImg04,
        itemTitle: "Empowering Change",
        itemDesc: "Give youth a platform for action (e.g. a talk, a ratified chapter, media, policy forums, councils)",
      },
      {
        itemImg: wellnessImg05,
        itemTitle: "Unite for Better Services and Change",
        itemDesc: "Begin to join together to advocate for better services and systems change in their communities",
      },
      
    ],
  },
  // {
  //   bannerImg:
  //   sec4heading1,
  //   bannerTitle: "Azure OpenAI Service",
  //   bannerDec:
  //     "Apply advanced coding and language models to a variety of use cases.",
  //   items: [
  //     {
  //       itemImg: wellnessImg01,
  //       itemTitle: "Azure Machine Learning",
  //       itemDesc: "Build, train, and deploy machine learning models.",
  //     },
  //     {
  //       itemImg: wellnessImg02,
  //       itemTitle: "Azure AI services",
  //       itemDesc: "Create intelligent apps with APIs and customizable models.",
  //     },
  //     {
  //       itemImg: wellnessImg03,
  //       itemTitle: "Azure AI Vision",
  //       itemDesc:
  //         "Discover computer vision insights from image and video analysis.",
  //     },
  //     {
  //       itemImg: wellnessImg04,
  //       itemTitle: "Azure Cognitive Search",
  //       itemDesc: "Add AI-powered content search to apps.",
  //     },
  //     {
  //       itemImg:wellnessImg05,
  //       itemTitle: "Azure AI Bot Service",
  //       itemDesc: "Design and build enterprise-grade conversational AI bots.",
  //     },
  //     {
  //       itemImg: wellnessImg05,
  //       itemTitle: "Azure Databricks",
  //       itemDesc: "Enable analytics and AI on an open data lake.",
  //     },
  //   ],
  // },
  // {
  //   bannerImg:
  //     'url("https://cdn-dynmedia-1.microsoft.com/is/image/microsoftcorp/products-services-compute-noshade-2x?resMode=sharp2&op_usm=1.5,0.65,15,0&wid=1464&hei=2091&qlt=95&fit=constrain")',
  //   bannerTitle: "Azure Virtual Machines",
  //   bannerDec: "Deploy VMs in seconds.",
  //   items: [
  //     {
  //       itemImg: compImg01,
  //       itemTitle: "Azure Kubernetes Service (AKS)",
  //       itemDesc: "Build and scale apps with managed Kubernetes.",
  //     },
  //     {
  //       itemImg: compImg02,
  //       itemTitle: "Linux virtual machines in Azure",
  //       itemDesc:
  //         "Provision VMs for Ubuntu, Red Hat, SUSE, and other popular distributions.",
  //     },
  //     {
  //       itemImg: compImg03,
  //       itemTitle: "SQL Server on Azure Virtual Machines",
  //       itemDesc: "Get industry-leading performance for SQL.",
  //     },
  //     {
  //       itemImg: compImg04,
  //       itemTitle: "Windows Virtual Machines",
  //       itemDesc: "Deploy scalable, protected virtualized infrastructure.",
  //     },
  //     {
  //       itemImg: compImg05,
  //       itemTitle: "Azure Functions",
  //       itemDesc: "Trigger apps with serverless computing.",
  //     },
  //     {
  //       itemImg: compImg06,
  //       itemTitle: "Azure App Service",
  //       itemDesc: "Expand apps to additional locations.",
  //     },
  //   ],
  // },
  // {
  //   bannerImg:
  //     'url("https://cdn-dynmedia-1.microsoft.com/is/image/microsoftcorp/products-services-containers-noshade-2x?resMode=sharp2&op_usm=1.5,0.65,15,0&wid=1464&hei=2091&qlt=95&fit=constrain")',
  //   bannerTitle: "Azure Kubernetes Service (AKS)",
  //   bannerDec: "Build and scale apps with managed Kubernetes.",
  //   items: [
  //     {
  //       itemImg: contImg01,
  //       itemTitle: "Azure App Service",
  //       itemDesc: "Quickly create powerful cloud apps for web and mobile.",
  //     },
  //     {
  //       itemImg: contImg02,
  //       itemTitle: "Azure Functions",
  //       itemDesc: "Trigger apps with serverless computing.",
  //     },
  //     {
  //       itemImg: contImg03,
  //       itemTitle: "Azure Kubernetes Fleet Manager",
  //       itemDesc: "Seamlessly manage Kubernetes clusters at scale.",
  //     },
  //     {
  //       itemImg: contImg04,
  //       itemTitle: "Azure Container Instances",
  //       itemDesc: "Run containers without managing servers.",
  //     },
  //     {
  //       itemImg: contImg05,
  //       itemTitle: "Azure Red Hat OpenShift",
  //       itemDesc:
  //         "Build, deploy, and scale apps on fully managed OpenShift clusters.",
  //     },
  //     {
  //       itemImg: contImg06,
  //       itemTitle: "Azure Spring Apps",
  //       itemDesc:
  //         "Build or migrate Spring applications with a fully managed service.",
  //     },
  //   ],
  // },
  // {
  //   bannerImg:
  //     'url("https://cdn-dynmedia-1.microsoft.com/is/image/microsoftcorp/products-services-hybrid-multicloud-noshade-2x?resMode=sharp2&op_usm=1.5,0.65,15,0&wid=1464&hei=2091&qlt=95&fit=constrain")',
  //   bannerTitle: "Azure Arc",
  //   bannerDec: "Unify on-premises, hybrid, and cross-cloud infrastructure.",
  //   items: [
  //     {
  //       itemImg: hybrImg01,
  //       itemTitle: "Azure SQL",
  //       itemDesc: "Migrate and modernize your SQL workloads.",
  //     },
  //     {
  //       itemImg: hybrImg02,
  //       itemTitle: "Azure Stack",
  //       itemDesc: "Build apps across on-premises, cloud, and the edge.",
  //     },
  //     {
  //       itemImg: hybrImg03,
  //       itemTitle: "Microsoft Sentinel",
  //       itemDesc:
  //         "Simplify security operations with intelligent security analytics.",
  //     },
  //     {
  //       itemImg: hybrImg04,
  //       itemTitle: "Microsoft Defender for Cloud",
  //       itemDesc: "Protect multicloud and hybrid environments.",
  //     },
  //     {
  //       itemImg: hybrImg05,
  //       itemTitle: "Azure ExpressRoute",
  //       itemDesc: "Create secure, private clouds.",
  //     },
  //     {
  //       itemImg: hybrImg06,
  //       itemTitle: "Azure DevOps",
  //       itemDesc:
  //         "Share code, track work, and ship software with modern dev services.",
  //     },
  //   ],
  // },
  // {
  //   bannerImg:
  //     'url("https://cdn-dynmedia-1.microsoft.com/is/image/microsoftcorp/products-services-iot-noshade-2x?resMode=sharp2&op_usm=1.5,0.65,15,0&wid=1464&hei=2091&qlt=95&fmt=png-alpha&fit=constrain")',
  //   bannerTitle: "Azure IoT Hub",
  //   bannerDec: "Authenticate communication across devices, edge, and cloud.",
  //   items: [
  //     {
  //       itemImg: iotImg01,
  //       itemTitle: "Azure IoT Central",
  //       itemDesc: "Build IoT solutions on a hosted platform.",
  //     },
  //     {
  //       itemImg: iotImg02,
  //       itemTitle: "Azure IoT Edge",
  //       itemDesc: "Deploy cloud intelligence on local edge devices.",
  //     },
  //     {
  //       itemImg: iotImg03,
  //       itemTitle: "Microsoft Defender for IoT",
  //       itemDesc: "Protect IoT and OT devices from threats.",
  //     },
  //     {
  //       itemImg: iotImg04,
  //       itemTitle: "Azure Digital Twins",
  //       itemDesc: "Create models of physical environments.",
  //     },
  //     {
  //       itemImg: iotImg05,
  //       itemTitle: "Azure Sphere",
  //       itemDesc: "Secure your IoT hardware and software.",
  //     },
  //     {
  //       itemImg: iotImg06,
  //       itemTitle: "Azure Time Series Insights",
  //       itemDesc: "Visualize IoT data in real time.",
  //     },
  //   ],
  // },
];

export { menus, dataSet as default };