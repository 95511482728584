import React from 'react'
// import img from '../../assets/start_chap_hero_img.png'
import img from '../../../assets/start_chap_hero_img.png'
const HeroSection = () => {

  return (
    <div className={`md:flex justify-start  md:py-[5vw] py-5 bg-slate-300 md:h-[80vh] my-auto`}>
      <div className=" px-[5vw] items-start w-full md:w-5/12 py-5">

        <div className="lg:text-4xl md:text-3xl mb-4">
          Increase your Global Presence with our <strong className='font-semibold'>Chapter </strong>offering
        </div>
        <div className="lg:text-sm md:text-xs">
          Create unique narratives across blogs and articles, resonating deeply with your audience's interests and passions
        </div>
      </div>

      <div className='w-11/12 md:w-6/12 rounded-md mx-auto md:my-auto'>
        <img src={img} alt="" />
      </div>
    </div>
  )
}

export default HeroSection