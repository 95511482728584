import React from "react";
import sectionsData from "./section6Data";

const Section6 = () => {
  return (
    <div className="px-4 py-12  bg-white flex-col justify-center items-center">
      <div className="flex-col justify-center items-center gap-6">
        <div className="text-center text-slate-950 text-4xl font-semibold ">
          Student Walkthrough
        </div>
        <div className="my-6 text-center text-slate-950 text-lg">
          Think Skill India empowers you to do their best work from anywhere in
          the <br /> world, together in one place.
        </div>
      </div>
      <div className="grid grid-cols-10 gap-6 md:gap-20 lg:gap-16 xl:gap-8 md:mx-6 xl:mx-0">
        {sectionsData.map((section, index) => (
          <div key={index} className="col-span-10 md:col-span-4 lg:col-span-3 xl:col-span-2">
            <img
              className={`w-56 h-56`}
              src={section.imagePath}
              alt={section.title}
            />
            <div className="w-48 mx-4 h-96 -mt-28 opacity-70 bg-black bg-opacity-10 rounded-bl-full rounded-br-full">
              <div className="mt-32 flex-col justify-start items-center gap-4 inline-flex">
                <div className="text-center text-xl text-stone-950 font-bold">
                  {section.title}
                </div>
                <div
                  className={`text-center text-${section.color} text-md font-semibold `}
                >
                  {section.startText}
                </div>
                <div className="px-2.5 text-stone-900 text-sm font-semibold">
                  {section.description}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section6;
