import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import cardimage from "../../../assets/CustomerSuccess/Section4image1.png"

const Section4 = () => {
  return (
    <section className="py-16 bg-gray-100 xl:px-24 lg:px-20 md:px-12 px-4">
      <div className="">
        <h2 className="text-md md:text-lg font-bold mb-4 text-blue-500" style={{ lineHeight: '140%' }}>INTELLIGENCE ADDS SUCCESS IN LIFE</h2>
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 w-full lg:w-3/4" style={{ lineHeight: '140%' }}>Create success journeys and paths as you Mentally Grow</h1>
        <p className="text-lg md:text-xl lg:text-2xl mb-6 w-full lg:w-5/6"style={{ lineHeight: '140%' }}>Opportunity to Re-engineer and Cultivate/Nurture your Life Journey to exclusive Resources and Expertise</p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-3 lg:gap-6 my-12">
          {/* Card 1 */}
          <div className="bg-transparent rounded-lg overflow-hidden ">
            <img src={cardimage} alt="Card 1" className="w-full" />
            <button className="flex justify-center items-center py-2  text-blue-500 focus:outline-none text-center mx-auto">
              
            Explore Available Packages
              <FontAwesomeIcon icon={faAngleRight} className="mr-4 " />
            </button>
          </div>

          {/* Card 2 */}
          <div className="bg-transparent rounded-lg overflow-hidden ">
            <img src={cardimage} alt="Card 2" className="w-full" />
            <button className="flex justify-center items-center py-2  text-blue-500 focus:outline-none text-center mx-auto">
              
            Manage Your Services
              <FontAwesomeIcon icon={faAngleRight} className="mr-4" />
            </button>
          </div>

          {/* Card 3 */}
          <div className="bg-transparent rounded-lg overflow-hidden ">
            <img src={cardimage} alt="Card 3" className="w-full" />
            <button className="flex justify-center items-center py-2  text-blue-500 focus:outline-none text-center mx-auto">
              
            Use Your Success Package
              <FontAwesomeIcon icon={faAngleRight} className="mr-4" />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section4;
