import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ImageToImport from '../../../assets/ChangeAgent/Section5image1.png'; // Import your image

const Section5 = () => {
  const backgroundColor = '#0086E8';
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      console.log('Section 5 is in view!');
      // You can add additional animations or logic here
    }
  }, [inView]);

  return (
    <motion.section
      className="py-8 md:py-28 text-white"
      style={{ background: backgroundColor }}
      ref={ref}
      initial={{ opacity: 0, y: 80 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
      transition={{ ease: 'easeInOut', duration: 1.7 }}
    >
      <div className="justify-center">
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 md:mb-16 mx-4 md:mx-12 lg:mx-16 xl:mx-32" style={{ lineHeight: '140%' }}><span className='text-yellow-400'>Discover</span> the <span className='text-yellow-400'>true potential</span> how to move toward the life's journey to pursue a <span className='text-yellow-400'>Career Opportunity</span>.</h2>
        <motion.img
          src={ImageToImport}
          alt="Image"
          className="md:mx-auto md:w-5/6 lg:w-5/6 xl:w-4/5"
          initial={{ opacity: 0, scale: 0.9, rotate: 0 }}
          animate={inView ? { opacity: 1, scale: 1, rotate: 0 } : { opacity: 0, scale: 0.9, rotate: 0 }}
          transition={{ ease: 'easeInOut', duration: 1.5 , delay:0.4 }}
        />
      </div>
    </motion.section>
  );
};

export default Section5;
