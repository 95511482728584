import React from "react";
import BgImg from "../../../assets/Speaker/landing-image.jpg";
import CoverSideImg from "../../../assets/Speaker/coverimage.png";

const LandingCover = () => {
    return (

        <div
            className=" xl:h-[100vh] lg:h-[80vh] md:h-[70vh] h-[100vh] flex bg-cover bg-center xl:px-24 xl: lg:px-16 lg:py-24 lg:pt-36 md:px-8 md:pt-6 md:pb-0 bg-no-repeat"
            style={{ backgroundImage: `url(${BgImg})` }}
        >
            <div className="max-w-screen-2xl mx-auto">
                <div className="w-full h-full xl:flex xl:flex-row lg:flex lg:flex-row md:flex md:flex-row flex flex-col xl:gap-56 lg:gap-32 md:gap-4">
                    <div className="flex flex-col flex-1 xl:mx-0 lg:mx-0 md:mx-0 ">
                        <div className="mb-4 pb-2 font-semibold text-white xl:text-4xl md:text-2xl lg:text-3xl text-lg lg:pt-1 md:pt-2 md:mb-2 xl:mx-0 lg:mx-0 md:mx-0 mx-2 cursor-pointer">
                            <div className="mb-4 xl:pt-0 lg:pt-0 md:pt-4 pt-4">


                                Igniting Minds and Sharing


                                Expertise
                            </div>


                        </div>
                        <div className="xl:mb-4 lg:mb-4 md:mb-4 font-normal xl:text-lg lg:text-md md:text-xs text-xs cursor-pointer text-white xl:mx-0 lg:mx-0 md:mx-0 mx-2">
                            <div>
                                Join hands with Think Skill India as a talk  speaker and share <br />
                                 your wealth of knowledge.
                            </div>
                        </div>

                        <div className="lg:mt-2 xl:mt-2 md:mt-2 mt-8">
                            <button className="hover:bg-neutral-100 hover:shadow-md bg-transparent text-white xl:px-4 xl:py-2 lg:px-4 md:px-2 md:py-0 px-2 py-2 border border-white rounded-3xl xl:text-md lg:text-xs text-xs md:text-xs hover:bg-white hover:text-black xl:mx-0 lg:mx-0 md:mx-0 mx-2">
                                Become Talk Speaker
                            </button>
                        </div>
                    </div>
                    <div className="flex-1 flex justify-end items-center xl:-mt-64 lg:-mt-80 md:-mt-44 -mt-40 mr-12">
                        <div
                            className=" xl:h-[520px] xl:w-[600px] lg:h-[400px] lg:w-[460px] md:h-[280px] md:w-[320px] h-[300px] w-[320px] xl:bg-cover xl:pt-0 xl:pb-12 lg:bg-cover md:bg-cover bg-contain bg-no-repeat "
                            style={{ backgroundImage: `url(${CoverSideImg})` }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingCover;