import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default function Section6() {
  const [formData, setFormData] = useState({
    societyName: "",
    address: "",
    zipCode: "",
    contactPersonName: "",
    phoneNumber: "",
    email: "",
    city: "",
    state: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // logic for sending the data to server
    console.log("Form submitted:", formData);

    // Reset the form data
    setFormData({
      societyName: "",
      address: "",
      zipCode: "",
      contactPersonName: "",
      phoneNumber: "",
      email: "",
      city: "",
      state: "",
    });

    e.target.reset();
  };
  return (
    <div className="w-full h-full bg-gray-100 py-8 md:py-20">
      <div className="text-center md:pb-10 text-stone-900 text-xl xl:text-6xl lg:text-4xl md:text-3xl font-bold">
        Let’s Build a
        <span className="text-blue-500"> Vibrant Society Together</span>
      </div>
      <div className="py-12 px-0 md:px-4 mx-auto w-5/6 border rounded-t-xl md:rounded-l-xl my-10 bg-violet-100">
        <form onSubmit={handleSubmit}>
          <div className="w-full px-6 py-6 md:py-6 lg:py-4 xl:py-8 flex-col justify-start items-start gap-8 xl:gap-6 inline-flex whitespace-nowrap">
            <div className="flex-col w-full xl:flex-row  justify-between items-start  gap-3 xl:gap-7  inline-flex">
              <div className=" w-full xl:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
                <div className="text-slate-900 font-semibold my-1">
                  Society Name
                </div>
                <input
                  className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                  placeholder="Society Name "
                  type="text"
                  name="societyName"
                  value={formData.societyName}
                  onChange={handleInputChange}
                />
              </div>
              <div className=" w-full xl:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
                <div className="text-slate-900 font-semibold my-1">Address</div>
                <input
                  className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                  placeholder="Address"
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="flex-col w-full xl:flex-row  justify-between items-start  gap-3 xl:gap-7  inline-flex">
              <div className=" w-full xl:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
                <div className="text-slate-900 font-semibold my-1">City</div>
                <input
                  className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                  placeholder="City"
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                />
              </div>
              <div className=" w-full xl:w-1/2 h-auto text-sm flex-col justify-evenly items-stretch inline-flex">
                <div className="text-slate-900 font-semibold my-1  ">State</div>
                <div className="relative">
                  <select
                    className="pl-2 lg:pl-4 pr-8 py-3 w-full shadow-md rounded-full text-gray-400 appearance-none"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled selected>
                      State
                    </option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                  <div className="absolute inset-y-0 right-5 flex items-center pr-2 pointer-events-none">
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="text-slate-950"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-col w-full xl:flex-row  justify-between items-start  gap-3 xl:gap-7  inline-flex">
              <div className=" w-full xl:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
                <div className="text-slate-900 font-semibold my-1">
                  Zip Code
                </div>
                <input
                  className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                  placeholder="Zip Code"
                  type="text"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleInputChange}
                />
              </div>
              <div className=" w-full xl:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
                <div className="text-slate-900 font-semibold my-1">
                  Contact Person Name
                </div>
                <input
                  className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                  placeholder="Contact Person Name"
                  type="text"
                  name="contactPersonName"
                  value={formData.contactPersonName}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="flex-col w-full xl:flex-row  justify-between items-start  gap-3 xl:gap-7  inline-flex">
              <div className=" w-full xl:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
                <div className="text-slate-900 font-semibold my-1">
                  Phone Number
                </div>
                <input
                  className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                  placeholder="Phone Number"
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className=" w-full xl:w-1/2 h-auto flex-col text-sm justify-evenly items-stretch inline-flex">
                <div className="text-slate-900 font-semibold my-1">
                  Email Address
                </div>
                <input
                  className=" pl-2  lg:pl-4  py-3 rounded-full shadow-md justify-start items-center placeholder:text-gray-400 inline-flex"
                  placeholder="xyz12@gmail.com"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <button
              type="submit"
              onClick={handleInputChange}
              className="text-black px-6 py-3 text-xl rounded-2xl font-semibold border-2 border-black border-b-4 hover:bg-neutral-100 hover:shadow-md"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
