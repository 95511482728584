import React from "react";
import imgURL from "../../../assets/Collaboratory/sec7_img1.jpg";
import leftArrow from "../../../assets/Collaboratory/sec8_leftArrow.png";
import rightArrow from "../../../assets/Collaboratory/sec8_rightArrow.png";
import LeftImageSec8 from "./LeftImageSec8";
import RightImageSec8 from "./RightImageSec8";
import image from "../../../assets/TalentCollaboratory/section9image.png";

import platImg1 from "./Images/platImg1.png";
import platImg2 from "./Images/platImg2.png";
import platImg3 from "./Images/platImg3.png";
import platImg4 from "./Images/platImg4.png";
import platImg5 from "./Images/platImg5.png";
import platImg6 from "./Images/platImg6.png";
import platImg7 from "./Images/platImg7.png";

const data1 = {
  item2: (
    <>
      Many systems <span className="text-yellow-400">One user experience</span>
    </>
  ),
  item3: (
    <>
      You've invested in tons of tools and technology for your people and
      organisation—but do
      <br /> they all work together? The truth is, there’s a massive void at the
      center of the workforce
      <br /> engagement and organisational growth ecosystem because there’s no
      centralized user
      <br /> experience unifying everything. Let’s fill that void.
    </>
  ),
};

const data2 = [
  {
    title: <span className="text-blue-500">Goals and Values</span>,

    heading: (
      <>
        UPMYMIND purpose to set clear goals and decide if a goal is important to
        you or not
      </>
    ),
    desc: (
      <>
        UPMYMIND platform will enable to develop intelligent goal system for
        your “whole person” development You will able to discover and create
        goals for character development goals, achievment development goals,
        relationship development goals, destination development goals.
      </>
    ),
    img: platImg1,
  },
  {
    title: (
      <span className="text-green-500">Manage yourself and Your time </span>
    ),
    heading: <>Build your personal excellence and value system.</>,
    desc: (
      <>
        UPMYMIND- AI based engine will make you spend your time doing those
        things you value or that help you to achieve your goals. It will
        classify the activities which fill your time table in terms of there
        importance and urgency. It supports to aim to spend as much time as
        possible doing those things that are important but not urgent
      </>
    ),
    img: platImg2,
  },
  {
    title: (
      <span className="text-yellow-500">Develop your Full Potential </span>
    ),
    heading: (
      <>Build Your mental fitness to gain in this world, with habits of mind </>
    ),
    desc: (
      <>
        Our Generative AI based platform deepens your thinking about what is
        important for the future to nurture new possibilites for your career and
        earning. The intelligent system will enable you to solve problems, to
        make decisions, to generate new ideas and to clarify ambiguities towards
        the world.
      </>
    ),
    img: platImg3,
  },
  {
    title: <span className="text-red-500">Build good Habits of mind</span>,
    heading: <>If you don’t at first succeed, try and try again.</>,
    desc: (
      <>
        The deep learning initiatives, with a systematic approach to acquire the
        kinds of mental habits needed to lead productive, fulfilling lives. The
        models build to develop incrementally intellegence for developing skills
        to apply to focus on developing tasks and to learn something new to
        increase their understanding and mastery of task. This empowers the mind
        to build a strong system of problem solving metacognisation and thinking
        abilities
      </>
    ),
    img: platImg4,
  },
  {
    title: (
      <span className="text-blue-500">Free from Fears, Worries & Anxiety</span>
    ),
    heading: (
      <>
        Develop the mental muscles and capabilities to protect from stress
        adaptation.
      </>
    ),
    desc: (
      <>
        UPMYMIND - task based learning enables self awareness, a managing
        impulsivility and emotions, empathzing and developing social skills to
        handle systematically the challenges. HPDSX engine adopts the individual
        with a mental model a set of teachable, learnable behaviours that makes
        to think and solve any problems and challenges. It provides the mind a
        set of behaviours with a discipline process.
      </>
    ),
    img: platImg5,
  },
  {
    title: (
      <span className="text-green-500">Develop fulfilling relationship</span>
    ),
    heading: (
      <>
        Flourish enormously by improving intelligently with a positive
        behaviour.
      </>
    ),
    desc: (
      <>
        Platform will enable in enhancing the ways individual produce knowledge
        rather than merely reproducing it. The individuals to learn how to
        develop a critical stance with their activities - inquiring, editing,
        thinking flexibly and learning from another person’s perspective. It
        builds the critical attribute of being intelligent and knowing how to
        act on situations. The good habits of mind develops a pattern of
        behaviour that leads to positive actions.
      </>
    ),
    img: platImg6,
  },
  {
    title: (
      <span className="text-yellow-500">
        Take positive control of Present and Future
      </span>
    ),
    heading: (
      <>Transform Mental Models and Habits of mind TO BECOME more SKILLFUL</>
    ),
    desc: (
      <>
        HPDSX engine 5 dimensions contribute to the growth of individual in a
        well structured and systematic manner. The dimensions become the focus
        of the developmental process Users in the platform to progress towards
        the internalisation developing positive mindests and skillsets. The
        intent of the dimensions to provide a success map to use in planning for
        the continual modification of learning, experences and skill
        acquisation,so that the user become more complex, sophisticated, and
        appropriate for users’ development over time
      </>
    ),
    img: platImg7,
  },
];

const Section8 = () => {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };

  return (
    <div className="w-full h-full  bg-slate-100 xl:px-24 lg:px-16 px-4 xl:py-24 lg:py-16  md:py-12 py-8">
      <div className=" text-center flex flex-col xl:gap-6 lg:gap-4 md:gap-2 gap-2">
        <div className="flex flex-col md:flex-row justify-between  items-center">
          <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">
            OPTIMIZE MENTAL WELLBEING
          </li>
          <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">
            ACHIEVE HOLISTIC EXCELLENCE{" "}
          </li>
          <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">
            THRIVE IN LIFE
          </li>
        </div>
        <div className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-blue-500 font-semibold my-2">
          {data1.item2}
        </div>
        <div
          className="xl:text-3xl lg:text-3xl md:text-base text-sm xl:mb-8 lg:mb-6 md:mb-4 mb-2 "
          style={{ lineHeight: "140%" }}
        >
          {data1.item3}
        </div>

        <div className="py-4 ">
          <img src={image} className="mx-auto " />
        </div>
      </div>
      <div>
        {data2.map((item, index) =>
          index % 2 ? (
            <div>
              <RightImageSec8 data={item} />
              {data2.length - 1 !== index && (
                <div>
                  <img
                    src={leftArrow}
                    className="w-1/6 xl:-my-24 lg:-my-16 md:-my-8 -my-4 xl:-ml-12 lg:-ml-10 md:-ml-8 -ml-6"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-end">
              <LeftImageSec8 data={item} />
              {data2.length - 1 !== index && (
                <div>
                  <img
                    src={rightArrow}
                    className="xl:w-4/6 lg:w-3/5 md:w-4/6 w-3/6 xl:-my-24 lg:-my-16 md:-my-8 -my-4 xl:mr-12 lg:mr-6 md:-mr-8 -mr-2"
                  />
                </div>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Section8;
