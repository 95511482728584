import React from "react";
import img_1 from "../../../assets/Communities/img_5.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const Section1 = () => {
  const data = {
    title: <>Alumni Chapters</>,
    description: (
      <>
        HPDS local geographic organizational Chapters provide unique
        opportunities for members to attend technical presentations, create
        strong peer-to-peer connections, and participate in leadership
        opportunities that can make a positive distinction in HPDS members' jobs
        and careers.
      </>
    ),
    imageUrl: img_1,
    buttonContent: <>Join HPDS Community</>,
  };
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };
  return (
    <div className="w-full h-auto my-10 ">
      {/* <div className="font-semibold w-full text-4xl md:text-5xl text-blue-500 px-20  py-10"> */}
      <div className="font-bold w-full text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-blue-500  pl-8   md:px-20 lg:px-24 xl:px-32 py-5 lg:py-10">
        {data.title}
      </div>
      <div className=" px-10 md:px-20 w-full h-auto mx-auto grid grid-cols-12 ">
        <div className="md:col-span-6 col-span-12 leading-normal pt-5 lg:pt-20 text-lg md:text-xl lg:text-2xl  xl:text-3xl  lg:px-4 xl:px-12 text-justify">
          {data.description}
          <div className=" py-8 ">
            <button
              className="text-white bg-blue-500 px-6 py-3 text-sm lg:text-lg rounded-lg hover:border-2 hover:border-blue-500 hover:text-blue-500 hover:bg-transparent"
              type="submit"
              onClick={handleInputChange}
              style = {{backgroundColor : '#0086E8'}}
            >
              Join HPDS Community
              <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
            </button>
          </div>
        </div>
        <div className="md:col-span-6 flex justify-end items-start col-span-12 ">
          <img src={data.imageUrl} className="w-4/5" />
        </div>
      </div>
    </div>
  );
};

export default Section1;
