import React from "react";
import commonDataSet from "../DataSet/commonDataSet";
import LeftSideImageFlexTemplate from "../Templates/LeftSideImageFlexTemplate";

const GuideSection2 = () => {
  const data = commonDataSet[9];

  return (
    <div>
      <LeftSideImageFlexTemplate
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
        bgColor={data.bgColor}
      />
    </div>
  );
};

export default GuideSection2;
