import b1 from "../../../../assets/Hpdsx/Section5_Img1.svg";
import b2 from "../../../../assets/Hpdsx/Section5_Img2.svg";
import b3 from "../../../../assets/Hpdsx/Section5_Img3.svg";

const fifthSectioData = [
  {
    id: 1,
    title: "Create your own Chapter",
    imageUrl: b1,
  },
  {
    id: 2,
    title: "Be a Brand Ambassador",
    imageUrl: b2,
  },
  {
    id: 3,
    title: "Be a Change Agent",
    imageUrl: b3,
  },
];

export default fifthSectioData;
