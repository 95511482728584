import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "tailwindcss/tailwind.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import section1Data from "./section1Data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Section1 = () => {
  const sliderRef = useRef(null);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToScroll: 1,
    slidesToShow: slidesToShow,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1160) {
        setSlidesToShow(3.05); // xl or more
      } else if (window.innerWidth >= 1024) {
        setSlidesToShow(3); // lg
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(3); // md
      } else {
        setSlidesToShow(1); // sm
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMoveLeft = () => {
    if (sliderRef.current && currentSlide > 0) {
      sliderRef.current.slickPrev();
    }
  };

  const handleMoveRight = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className="max-w-screen-xl mx-6 xl:mx-28 lg:mx-24 md:mx-16 py-4 md:py-12 bg-white relative">
      <div className="text-2xl md:text-3xl font-semibold font-Poppins cursor-pointer">Get Started</div>
      <div className="flex items-center justify-center mt-2 md:mt-8">
        {currentSlide > 0 && (
          <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
            <div
              className="w-12 h-12 rounded-full flex items-center justify-center border border-gray-400 shadow-2xl bg-white cursor-pointer text-black"
              onClick={handleMoveLeft}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
            </div>
          </div>
        )}
        <Slider {...settings} className="flex gap-4 w-full" ref={sliderRef}>
          {section1Data.map((section) => (
            <div
              key={section.id}
              className="outline-none focus:outline-none"
            >
              <div className="flex-col justify-start items-start gap-4 mr-8">
                <img
                  className="w-full h-full relative rounded-lg object-cover"
                  src={section.image}
                  alt={section.title}
                />
                <div className="tracking-tight text-black xl:text-md lg:text-lg md:text-sm font-bold">
                  {section.title}
                </div>
                <div className="flex flex-row justify-between">
                  <div className="tracking-tight text-black xl:text-md lg:text-lg md:text-sm font-semibold">
                    {section.category}
                  </div>
                  <div className="tracking-tight text-green-500 xl:text-md lg:text-lg md:text-sm font-semibold">
                    {section.status}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
          <div
            className="w-12 h-12 rounded-full flex items-center justify-center shadow-2xl border border-gray-400 bg-white cursor-pointer text-black"
            onClick={handleMoveRight}
          >
            <FontAwesomeIcon icon={faChevronRight} className="text-lg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
