import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "tailwindcss/tailwind.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import modelData from "./seventhSectionData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const SeventhSection = () => {
  const sliderRef = useRef(null);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToScroll: 1,
    slidesToShow: slidesToShow,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1160) {
        setSlidesToShow(3); // xl or more
      } else if (window.innerWidth >= 1024) {
        setSlidesToShow(3); // lg
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(3); // md
      } else {
        setSlidesToShow(1); // sm
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMoveLeft = () => {
    if (sliderRef.current && currentSlide > 0) {
      sliderRef.current.slickPrev();
    }
  };

  const handleMoveRight = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className="bg-slate-100">
      <div className="max-w-screen-xl mx-6 xl:mx-28 lg:mx-24 md:mx-16 py-12 relative">
        <div className="text-2xl font-semibold font-Poppins mb-6">
          HPDS 5e Model
        </div>
        <div className="flex items-center justify-center relative">
          {currentSlide > 0 && (
            <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
              <div
                className="w-12 h-12 rounded-full flex items-center justify-center hover:border border-gray-400 shadow-lg hover:shadow-2xl  bg-white cursor-pointer text-black"
                onClick={handleMoveLeft}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
              </div>
            </div>
          )}
          <Slider
            {...settings}
            className="flex w-full overflow:hidden"
            ref={sliderRef}
          >
            {modelData.map((model) => (
              <div key={model.id}>
                <div className="w-56 xl:h-[500px] md:w-48 lg:w-56 xl:w-96 mx-8  bg-white border border-gray-200 rounded-lg md:mx-8 hover:shadow-lg">
                  <div className="p-8">
                    <img
                      className="xl:w-full xl:h-full object-cover mb-4"
                      src={model.imageUrl}
                      alt={model.title}
                    />
                    <div className="p-2">
                      <div className="flex justify-center text-base font-Poppins font-semibold text-stone-900 mb-2">
                        {model.title}
                      </div>
                      <p className="text-black text-xs font-light mb-4">
                        {model.description}
                      </p>
                      <div className="flex justify-center iteams-center">
                        <div className="h-8 w-24 px-2 rounded-full border border-neutral-400 flex justify-center items-center ">
                          <div className="text-xs font-bold text-stone-900 cursor-pointer">
                            {model.button}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className="xl:-mr-3 absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
            <div
              className="w-12 h-12 rounded-full flex items-center justify-center shadow-lg hover:shadow-2xl hover:border border-gray-400 bg-white cursor-pointer text-black"
              onClick={handleMoveRight}
            >
              <FontAwesomeIcon icon={faChevronRight} className="text-lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeventhSection;
