import React, { useRef, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import Section10image1 from "../../../assets/HPDSxMenu/Section10image1.png"; // Update the image path

const Section10 = () => {
  const textRef = useRef(null);
  const imageRef = useRef(null);

  const [{ opacity: textOpacity }, setTextSpring] = useSpring(() => ({
    opacity: 0,
  }));
  const [{ opacity: imageOpacity }, setImageSpring] = useSpring(() => ({
    opacity: 0,
  }));

  const handleScroll = () => {
    if (textRef.current && imageRef.current) {
      const textRect = textRef.current.getBoundingClientRect();
      const imageRect = imageRef.current.getBoundingClientRect();

      // Calculate whether text is visible within the viewport
      const textVisible =
        textRect.top < window.innerHeight && textRect.bottom >= 0;

      // Calculate whether image is visible within the viewport
      const imageVisible =
        imageRect.top < window.innerHeight && imageRect.bottom >= 0;

      // Update text opacity based on visibility
      setTextSpring({
        opacity: textVisible ? 1 : 0,
        config: { duration: 1000, delay: 500 },
      });

      // Update image opacity based on visibility
      setImageSpring({
        opacity: imageVisible ? 1 : 0,
        config: { duration: 1000, delay: 1000 },
      });
    }
  };

  // Attach scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // Remove scroll event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="w-full">
      <div className="max-w-screen  py-8 md:py-16">
        {/* Grid */}
        <div className="grid grid-cols-12 ">
          {/* Left Section */}
          <div className="col-span-12 md:col-span-6 flex flex-col items-start mx-4 md:mx-8 lg:mx-12 xl:mx-20 md:py-0 xl:py-12">
            {/* Heading */}
            <animated.h2
              ref={textRef}
              className="text-xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold my-4 md:my-4 lg:my-8 xl:my-12 leading-relaxed xl:mr-12"
              style={{ lineHeight: "140%", opacity: textOpacity }}
            >
              The new era of{" "}
              <span
                className=" font-bold"
                style={{ color: "rgb(0, 134, 232)" }}
              >
                AI-Powered every
              </span>{" "}
              <span
                className=" font-bold"
                style={{ color: "rgb(0, 134, 232)" }}
              >
                Possibility
              </span>{" "}
              to{" "}
              <span
                className=" font-bold"
                style={{ color: "rgb(0, 134, 232)" }}
              >
                Shape Yourself
              </span>
            </animated.h2>

            {/* Subheading */}
            <animated.h3
              ref={textRef}
              className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold md:my-4 xl:my-6 leading-relaxed"
              style={{ lineHeight: "140%", opacity: textOpacity }}
            >
              Intelligent future roadmap model for sharpening your skills and
              learning
            </animated.h3>

            {/* Description */}
            <animated.p
              ref={textRef}
              className=" mb-2   text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold"
              style={{
                lineHeight: "140%",
                opacity: textOpacity,
                color: "rgb(0, 134, 232)",
              }}
            >
              Get a complete picture of your identity
            </animated.p>

            {/* Button */}
            <button className="bg-yellow-400 hover:bg-blue-600 text-white text-md md:text-md lg:text-lg font-semibold px-4 py-2 md:px-6 md:py-2 rounded-2xl md:my-4 lg:my-6 ">
              SEE SOLUTION
            </button>
          </div>

          {/* Right Section */}
          <div className="col-span-12 md:col-span-6 md:mr-10 lg:mr-0 xl:my-12">
            {/* Image */}
            <animated.img
              ref={imageRef}
              src={Section10image1}
              alt="Section 10 Image 1"
              className=" w-full xl:w-5/6 h-full"
              style={{ opacity: imageOpacity }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section10;
