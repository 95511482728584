import React from "react";
import bgImg from "../../../../assets/Hpdsx/Cover-Img.svg";
import SideCoverImg from "../../../../assets/Hpdsx/CoverSideImg.png";
import features from "./coverIconData";

export default function Cover() {
  const bgImageStyle = {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  return (
    <div className="grid grid-cols-12 w-full h-full md:h-screen" style={bgImageStyle}>
      {/* Upper component */}
      <div className="order-1 md:order-1 col-span-12 md:col-span-6 flex flex-row mx-2 xl:ml-28 lg:ml-24 md:ml-16">
        {/* "Create your" text */}
        <div className="text-white mb-1 whitespace-nowrap font-medium text-2xl xl:text-5xl pt-16 xl:pt-48 lg:text-3xl lg:pt-48 md:text-2xl md:pt-36 cursor-pointer">
          <div>Create your image what you</div>
          <div className="flex">
            <div className="mr-2">
              <b>want be</b>
            </div>
            <div>
              We <b>help you</b>
            </div>
          </div>
          <div className="flex">
            <div className="mr-2">
              <b>become</b>
            </div>
            <div>
              what you <b>want be.</b>
            </div>
          </div>
        </div>
      </div>

      {/* SideCoverImg */}
      <div className="order-3 md:order-2 col-span-12 md:col-span-6 items-right flex flex-row">
        <div className="xl:w-[36rem] xl:h-[28rem] xl:mt-16 lg:w-[380px] lg:h-[330px] lg:mt-28 md:w-96 md:h-56 md:ml-16 md:mt-24 md:mr-8 rounded-md">
          <img
            src="https://themindfool.com/wp-content/uploads/2020/06/Self-realization.jpg"
            alt="blank"
            className="w-full h-full rounded-none md:rounded-md shadow-sm object-cover"
          />
        </div>
      </div>

      {/* Features list */}
      <div className="order-2 md:order-3 col-span-12 md:col-span-6 flex-col items-start mb-8 md:mb-0 mb-4 md:-mt-44">
        <div className="text-white whitespace-nowrap font-semibold font-Segoe UI mt-16 mb-6 ml-6 lg:ml-24 lg:mt-8 lg:text-lg md:text-md md:ml-16 md:mt-28 xl:ml-28">
          Start your bright future here
        </div>
        <div className="w-full flex justify-start items-start ml-6 lg:ml-24 md:ml-16 xl:ml-28">
          {features.map((feature) => (
            <div
              key={feature.name}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <div className="bg-contain relative mr-2 md:mr-4 w-6 h-6 xl:w-16 xl:h-16 lg:w-20 lg:h-20 md:h-16 md:w-16">
                <img
                  src={feature.icon}
                  className="pt-0 md:pt-2 bg-white bg-opacity-90 rounded-full shadow object-scale-down lg:object-none xl:object-scale-down w-6 h-6 xl:w-16 xl:h-16 lg:w-20 lg:h-20 md:object-scale-down md:h-16 md:w-16 cursor-pointer hover:shadow-2xl"
                  alt={feature.name}
                />
              </div>
              <div className="hidden md:block">
              <div className="text-center mr-4 text-white text-xs xl:text-sm font-semibold">
                {feature.name}
              </div>
            </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
