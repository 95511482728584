import React from "react";
import LandingCover from "../../components/ThinkSkillIndia/LandingCover";
import Sections from "../../components/ThinkSkillIndia/Sections";

export default function ThinkSkillIndia() {
  return (
    <div className="flex flex-col bg-neutral-100 font-poppins">
      <LandingCover />
      <Sections />
    </div>
  );
}
