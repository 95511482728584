

const dataset = [
    {
      id: 1,
      title: <div className="text-green-500">Make Financial, Emotional, and Physical Investments in your Well-Being</div>,
      description: <div> <span className="block pb-2">Our employees have access to several learning options as their careers develop, including financial education, tuition help, and program-specific training. These allow them to broaden their knowledge on a range of subjects, keep up to date in their area, and never stop learning.​
</span>
We provide hundreds of technical, managerial, and professional development seminars both in-person and online to support employee advancement.  
</div>,

    },
    {
      id: 2,
      title: <div className="text-yellow-500">Grow Professionally and Personally</div>,
      description: "Sample Text ....​",

    },
    {
      id: 3,
      title: <div className="text-red-500">Enjoy having more time to take care of you and your family.</div>,
      description: "Sample text....​",

    },

  ];
  
  export default dataset;