import img1 from "../../../assets/Speaker/Section1_img1.png";
import img2 from "../../../assets/Speaker/Section1_img2.png";
import img3 from "../../../assets/Speaker/Section1_img3.png";
import img4 from "../../../assets/Speaker/Section1_img4.png";
import img5 from "../../../assets/Speaker/Section1_img5.png";
import img6 from "../../../assets/Speaker/Section1_img6.png";
import motivateimg from "../../../assets/Speaker/motivatesec.png"

const speakerData = [
    {
        id: 1,
        title: "Enhanced Communication Skills",
        description:
          "Regular speaking engagements help refine your ability to articulate thoughts clearly and effectively, improving overall communication skills",
        imageUrl: img1,
        alt: "Enhanced Communication Skills",
      },
      {
        id: 2,
        title: "Increased Confidence",
        description:
          "Speaking in front of an audience boosts self-confidence, allowing you to convey ideas persuasively and with authority",
        imageUrl: img2,
        alt: "Increased Confidence",
      },
      {
        id: 3,
        title: "Emotional Intelligence Development",
        description:
          "Engaging with audiences cultivates emotional intelligence, allowing speakers to better understand and connect with people from different backgrounds and perspectives",
        imageUrl: img3,
        alt: "Emotional Intelligence Development",
      },
      {
        id: 4,
        title: "Improvement in Leadership Skills",
        description:
          "Speaking in front of groups helps develop leadership qualities such as confidence, decisiveness, and the ability to inspire and motivate others",
        imageUrl: img4,
        alt: "Improvement in Leadership Skills",
      },
      {
        id: 5,
        title: "Cognitive Enhancement",
        description:
          "Regularly crafting and delivering speeches stimulates cognitive functions, improving memory, critical thinking, and problem-solving abilities",
        imageUrl: img5,
        alt: "Cognitive Enhancement",
      },
      {
        id: 6,
        title: "Collaboration Opportunities",
        description:
          "Public speaking engagements can lead to collaborations with event organizers, fellow speakers, or industry professionals, fostering new partnerships and joint ventures",
        imageUrl: img6,
        alt: "Collaboration Opportunities",
      },
      

];

export default speakerData;