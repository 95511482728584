import InstructorSection1Img from "../../../assets/Instructor/Section1_Img1.jpg";
import InstructorSection3Img from "../../../assets/Instructor/Section3_Img1.jpg";
import CoachSection1Img from "../../../assets/Coach/Section1_Img1.jpg";
import CoachSection3Img from "../../../assets/Coach/Section3_Img1.jpg";
import ScientistSection1Img from "../../../assets/Scientist/Section1_Img1.jpg";
import ScientistSection3Img from "../../../assets/Scientist/Section3_Img1.jpg";
import MentorSection1Img from "../../../assets/Mentor/Section1_Img1.jpg";
import MentorSection3Img from "../../../assets/Mentor/Section3_Img1.jpg";
import GuideSection1Img from "../../../assets/Guide/Section1_Img1.jpg";
import GuideSection2Img from "../../../assets/Guide/Section2_Img1.jpg";
import ConferenceSection1Img from "../../../assets/Conference/Section1_Img1.jpg";
import ConferenceSection3Img from "../../../assets/Conference/Section3_Img1.jpg";
import ConferenceSection4Img from "../../../assets/Conference/Section4_Img1.jpg";
import WorkshopSection1Img from "../../../assets/Workshop/Section1_Img1.jpg";
import WorkshopSection3Img from "../../../assets/Workshop/Section3_Img1.jpg";
import WorkshopSection4Img from "../../../assets/Workshop/Section4_Img1.jpg";
import SeminarSection1Img from "../../../assets/Seminar/Section1_Img1.jpg";
import SeminarSection3Img from "../../../assets/Seminar/Section3_Img1.jpg";
import SeminarSection4Img from "../../../assets/Seminar/Section4_Img1.jpg";
import BlogSection1Img from "../../../assets/Blogs/Blogfirst.jpg";
import articlefirst from "../../../assets/Articles/articlefirst.jpg";
import articlesecond from "../../../assets/Articles/articlesecond.jpg";
import volunteer1 from "../../../assets/Volunteer/Section1_Img1.jpg";
import volunteer2 from "../../../assets/Volunteer/Section3_Img1.jpg";
import volunteer3 from "../../../assets/Volunteer/Section4_Img1.jpg";
import mission1 from "../../../assets/Mission&Vision/Section1_Img1.jpg";
import mission2 from "../../../assets/Mission&Vision/Section2_Img1.jpg";
import mission3 from "../../../assets/Mission&Vision/Section3_Img1.jpg";
import about1 from "../../../assets/About Hpds/section2Img1.png"
import about2 from "../../../assets/About Hpds/section3Img1.png";
import about3 from "../../../assets/About Hpds/section5Img1.png";


const commonDataSet = [
  {
    id: 1,
    title: "Spark curiosity, teach confidently",
    imageUrl: InstructorSection1Img,
    description:
      "Stay at the forefront of your field with our customizable, real-world interface. With intuitive teaching tools and flexible course delivery options for in-classroom, online, and hybrid learning — you can engage all learners, no matter where they are.",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 2,
    title: "Shape careers, including your own",
    imageUrl: InstructorSection3Img,
    description:
      "Let your knowledge become a key in driving the careers of learners, including yours by delving into a supportive community of Think Skill India instructors.",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 3,
    title: "Help in building Personal Skill Matrix",
    imageUrl: CoachSection1Img,
    description:
      "Assistance in constructing  personalized skill matrix, enabling a clear map of strengths and areas for growth, enabling targeted personal development",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 4,
    title:
      "Transform performance and growth for people, teams, and organizations where it matters most",
    imageUrl: CoachSection3Img,
    description: " ",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 5,
    title:
      "We believe that it's not just what you learn that matters, It's how you learn it.",
    imageUrl: ScientistSection1Img,
    description:
      "As a HPDS Scientist you'll discover your prowess and leverage it to achieve your highest goals in life for ACTION, SUCCESS and HAPPINESS",
    bgColor: "slate-100",
    button: false,
    buttonName: "",
  },
  {
    id: 6,
    title: "You grow every day -- and that growth is profound",
    imageUrl: ScientistSection3Img,
    description:
      "Be the heart of our philosophy and transform life personally/professionally anywhere you go.",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 7,
    title: "Provide Invaluable Expertise and Experience",
    imageUrl: MentorSection1Img,
    description:
      "Empower mentees to excel by sharing unparalleled expertise and hands-on experience in your respective field",
    bgColor: "slate-100",
    button: false,
    buttonName: "",
  },
  {
    id: 8,
    title: "Motivate Mentee at every corner",
    imageUrl: MentorSection3Img,
    description:
      "Build a deep personal relationship with the mentee to offer holistic career advice and a personal development plan to cater for the original goal",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 9,
    title:
      "Develop leadership qualities by laying down your experience for your associates ongoing hurdles",
    imageUrl: GuideSection1Img,
    description: " ",
    bgColor: "slate-100",
    button: false,
    buttonName: "",
  },
  {
    id: 10,
    title:
      "Help Students associates design a tried and tested roadmap saving their time and effort",
    imageUrl: GuideSection2Img,
    description:
      "Empowering individuals by crafting proven roadmaps tailored to their needs, streamlining their journey and maximizing efficiency towards success",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 11,
    title: "Insightful Encounters to Renowned Experts and thought leaders",
    imageUrl: ConferenceSection1Img,
    description:
      "Conferences often attract renowned experts and thought leaders as speakers or attendees, offering a rare chance to interact, ask questions, and gain insights directly from these influential individuals",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 12,
    title:
      "Practical Strategies and Cutting-Edge Insights for Professional and Personal Success",
    imageUrl: ConferenceSection3Img,
    description:
      "Acquire practical skills, strategies, and ideas that you can immediately apply in your professional and personal endeavors",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 13,
    title:
      "Stay Ahead: Keeping Abreast of Cutting-Edge Scholarly Research in Your Field",
    imageUrl: ConferenceSection4Img,
    description:
      "Join us in our Conference to gain an edge by staying updated with the latest groundbreaking research papers, ensuring your expertise remains at the forefront of advancements in your field",
    bgColor: "slate-100",
    button: false,
    buttonName: "",
  },
  {
    id: 14,
    title:
      "Immersive Learning through, Interactive Activities, Discussions, and Hands-On Exercises",
    imageUrl: WorkshopSection1Img,
    description:
      "Encourages active participation and foster a deeper understanding of the subject matter. This dynamic learning environment ensures an enriching and enjoyable experience",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 15,
    title: "Building Connections through Workshops and Networking",
    imageUrl: WorkshopSection3Img,
    description:
      "Connect with like-minded individuals, experts, and industry professionals to grow, network, share experiences and collaborate on future endavours.",
    bgColor: "slate-100",
    button: false,
    buttonName: "",
  },
  {
    id: 16,
    title:
      "Immerse yourself in new skills, activities, draw inspiration, and actively seek valuable mentorship",
    imageUrl: WorkshopSection4Img,
    description: " ",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 17,
    title:
      "Opportunity to engage with a diverse panel of knowledgeable speakers",
    imageUrl: SeminarSection1Img,
    description:
      "Engage in stimulating conversations, gain valuable insights, and broaden your perspectives as these seasoned speakers share their wealth of knowledge, offering unique viewpoints on a range of compelling topics",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 18,
    title:
      "Conduct seminars for introducing new technology to your organisation",
    imageUrl: SeminarSection3Img,
    description:
      "Empower your team with the latest tech advancements! Engage in specialized seminars introducing cutting-edge technology to fuel your organization's growth and efficiency",
    bgColor: "slate-100",
    button: false,
    buttonName: "",
  },
  {
    id: 19,
    title:
      "Conduct seminars for Igniting Inspiration and Motivation through Keynotes, Success Stories, and Innovative Ideas",
    imageUrl: SeminarSection4Img,
    description: " ",
    bgColor: "white",
    button: true,
    buttonName: "Conduct Seminar",
  },
  {
    id: 20,
    title: "Encounter Stories, Perspectives and Wisdom across all Topics",
    imageUrl: BlogSection1Img,
    description: "Where your thoughts, ideas and perspective are embraced.",
    bgColor: "white",
    button: true,
    buttonName: "Start Reading",
  },
  {
    id: 21,
    title: "Diverse subjects to meet your interest and curiosity",
    imageUrl: articlefirst,
    description:
      "From art to science, engage with an array of topics, each tailored to spark fascination and cater to varied interests.",
    bgColor: "white",
    button: true,
    buttonName: "View Article",
  },
  {
    id: 22,
    title: "Connect with experts that inspire you through articles.",
    imageUrl: articlesecond,
    description:
      "Engage with your favorite authors on their articles and share your thoughts on the subject",
    bgColor: "white",
    button: true,
    buttonName: "Start Reading",
  },
  {
    id: 23,
    title: "Building Leadership qualities",
    imageUrl: volunteer1,
    description:
      "Through volunteering, you'll learn important life skills such as  responsibility, teamwork, problem-solving, confidence, empathy, communication, and taking initiative",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 24,
    title: "Building Bonds and Strengthening Relationships and Community",
    imageUrl: volunteer2,
    description:
      "Build your connections based on common goals, values, and mutual support, contributing to lasting and meaningful relationships",
    bgColor: "white",
    button: false,
    buttonName: "",
  },
  {
    id: 25,
    title: "Establishing connections and tapping into future opportunities",
    imageUrl: volunteer3,
    description:
      "Get a chance to develop professional networks, allowing access to career advice, mentorship, and potential job leads",
    bgColor: "white",
    button: true,
    buttonName: " ",
  },
  {
    id: 26,
    title: "Our Mission",
    imageUrl: mission1,
    description:
      "Using level of Mental Health, Wellness and Wellbeing education and learning as a commonly accepted proxy for mindsets and skillsets development and taking into account for productivity gains, prosperity and humancare Addressing the global talent crisis That could cost nations trillions of dollars in unrealized annual revenues in 21st Century",
    bgColor: "white",
    button: false,
    buttonName: " ",
  },
  {
    id: 27,
    title: "Mind-set for Changing",
    imageUrl: mission2,
    description:
      "Teaches a clear Mental Thought Dehypnotising from false Beliefs, accepted an Idea and or Word from Yourself, Your teacher, friends, Your parents and finally Advertisements- from any of The Source, and further, If you are convinced The Idea is True.Creating an accessible and sustainable model of character and culture education for Leaders, Teachers, Students, Parents and Communities by aligning and evolving best practices.",
    bgColor: "white",
    button: false,
    buttonName: " ",
  },
  {
    id: 28,
    title: "Compelling Digital Ecosystem",
    imageUrl: mission3,
    description:
      "From kindergarten to retirement, we know it is the progress of individuals that moves society forward. engaging, effective and research-backed online education curriculum is designed to do just that. By connecting education to the real world, we are making sure your learners are set-up for success in the 21st century.",
    bgColor: "slate-100",
    button: false,
    buttonName: " ",
  },
  {
    id: 29,
    title: "Powerful EdTech Partnerships",
    imageUrl: mission2,
    description:
      "ThinkSkillIndias' private-label, digital course platform connects partners to a proven suite of content and services focused on scalability, activation, and multi-medium storytelling. Find out how we are helping forward-leaning organizations power their edtech programs, and learn how ThinkSkillIndia can support your education needs.",
    bgColor: "white",
    button: false,
    buttonName: " ",
  },
  {
    id: 30,
    title: "Networks",
    imageUrl: mission1,
    description:
      "Inspired by the notion that education is the ultimate connector, ThinkSkillIndia has created powerful networks to couple learners with courses to change outcomes, and partners with the tools they need to achieve their objectives.",
    bgColor: "white",
    button: false,
    buttonName: " ",
  },
  {
    id: 31,
    title:
      "Your all-in-one platform for The HPDS Collaboratory: A New Vision for Learning in a Changing India",
    imageUrl: about1,
    description:
      "HPDS Collaboratory enables learning journeys, talent, and prepare and participate in communities and workplaces, automatically personalize development for one’s skills, roles, and goals, make learning more powerful, relevant, and connected by meeting youth where they are and empowering them to explore and engage with their passions, and to weave learning into Data-driven skills intelligence in collective intelligence systems.",
    bgColor: "white",
    button: false,
    buttonName: " ",
  },
  {
    id: 32,
    title: "HPDSx Platform Co-design Engagements",
    imageUrl: about2,
    description:
      "Identify & drive winning mindsets, habits and behaviours for Integrate Social, Emotional, and Cognitive Skills.",
    bgColor: "white",
    button: false,
    buttonName: " ",
  },
  {
    id: 33,
    title: "Better Together",
    imageUrl: about3,
    description:
      "Discover the HPDSx experiences built to bring your favourite HPDSx tools to life.",
    bgColor: "cyan-50",
    button: false,
    buttonName: " ",
  },
];

export default commonDataSet;
