import React from "react";
import LandingCover from "../../components/CreatePlatform/LandingCover";
import PlatformSection1 from "../../components/CreatePlatform/PlatformSection1";
import PlatformSection2 from "../../components/CreatePlatform/PlatformSection2";
import PlatformSection3 from "../../components/CreatePlatform/PlatformSection3";
import PlatformSection4 from "../../components/CreatePlatform/PlatformSection4";
import PlatformSection5 from "../../components/CreatePlatform/PlatformSection5";
import { ScreenSizeProvider } from "../../components/Contexts/ScreenSizeContent";



export default function Scientist() {
    return (
      <div className="flex flex-col">
        <ScreenSizeProvider>
         <LandingCover />
         <PlatformSection1/>
         <PlatformSection2/>
         <PlatformSection3/>
         <PlatformSection4/>
         <PlatformSection5/>
        </ScreenSizeProvider>
      </div>
    );
  }