
import Section15Image from "../../../assets/MainMenu/Section15img.png"
import Section15Image2 from "../../../assets/MainMenu/Section15image2.png";
import Section15Image3 from "../../../assets/MainMenu/Section15image3.png";

const dataset = [
    {
      id: 1,
      title: "Title 1",
      heading:"Actually, there is no scientific evidence showing this relationship.",
      description: "However, research on expert performance showed that LONG-TERM SUCCESS IN ANY DISCIPLINES OF STUDY OR SUBJECT IS DETERMINED BY THE ACTUAL TIME AN INDIVIDUAL WORKS AT HIS OR HER MOST IMPORTANT GOALS ON A WEEKLY BASIS, AND THAT IT TAKES 10 YEARS OF DELIBERATE PRACTICE before a person can really excel in a specific Discipline of Study",
      

      rightimage:Section15Image
    },
    {
      id: 2,
      title: "Title 2",
      heading:"TSIO was built on curating the experiences, resources, and people needed to transform the way young people access and experience learning.",
      description: "TSIO was built on curating the experiences, resources, and people needed to transform the way young people access and experience learning.​",
     
      rightimage:Section15Image2
    },
    {
      id: 3,
      title: "Title 3",
      heading:"To be successful in our daily lives and in a global workforce, INDIANS STUDENTS NEED PATHWAYS TO ACQUIRE EXPERTISE AND FORM MEANINGFUL CONNECTIONS TO PEERS AND MENTORS. ",
      description: "This journey begins with a base of knowledge and abilities that can be augmented and enhanced throughout our lives. Fortunately, advances in learning sciences have provided new insights into how people learn.​​",
      
      rightimage:Section15Image3
    },
    
  ];
  
  export default dataset;