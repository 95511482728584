import React from "react";
import LandingCover from "../../components/Instructor/LandingCover";
import Section1 from "../../components/Instructor/Section1";
import Section3 from "../../components/Instructor/Section3";
import Section2 from "../../components/Instructor/Section2";
import Section4 from "../../components/Instructor/Section4";

export default function General() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
    </div>
  );
}
