import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import img from "../../../assets/MainMenu/Section16image1.png"
import image1 from "../../../assets/MainMenu/Section16-Image1.png"
import image2 from "../../../assets/MainMenu/Section16-Image2.png"
import image3 from "../../../assets/MainMenu/Section16-Image3.png"

const Section3 = () => {
  const { ref, inView } = useInView();

  const data2 = {
    item1: {
      id: 1,
      title: (
        <>
          The platform has been a major component of the drive to boost competitiveness,
          productivity and innovation capability of students, institutions and organisations.
          Platform will enable universities and schools to collaborate learning providers
          include professional or industry academies, chambers of commerce,
          coaching and consulting organisations and publishers.
        </>
      ),
      img: image1,
    },
    item2: {
      id: 2,
      title: (
        <>
          Platform enables Universities and schools need to fundamental change to become
          academic level life-long learning and development providers for students
          as well as its alumnus. A knowledge management system for colleges and universities
          paves the way for significant discoveries, elevated teaching methodologies,
          enhanced intellectual discussion,  and a holistic educational journey.
        </>
      ),
      img: image3,
    },
    item3: {
      id: 3,
      title: (
        <>
          A platform for learning a new skill, that will proceed to figure out the start point, endpoint,
          and the developmental path of transition points, so that learner can get the process right
          from the beginning, on top of understanding it.
        </>
      ),
      img: image2,
    },
  };

  return (
    <motion.section
      className="text-black pb-8"
      
    >
      <motion.div
        
      >
        <div className="mx-auto grid grid-cols-12 ">
          {/* Left side with text */}
          <div className="col-span-12 md:col-span-6 " >
            <img src={img} alt="Image Description" className=" w-full md:w-5/6 rounded-lg " />
          </div>

          {/* Right side with image */}
          <div className="col-span-12 md:col-span-6 py-8 md:py-12 lg:py-12 xl:py-28 px-4 md:px-4 xl:px-24">
            <div className="">
              <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 ">The Role of Platform for Schools and Educators</h2>
              <div className='h-[8px] w-2/5 bg-yellow-400 mb-2 md:mb-4 lg:mb-8'></div>
              <h3 className="text-lg md:text-2xl lg:text-3xl font-semibold mb-2 md:mb-4 " style={{ lineHeight: '140%' }}>Join THINKSKILLINDIA PLATFORM for a rewarding career that comes with higher learning pathways </h3>
              <button className="bg-yellow-400 hover:bg-yellow-700 text-black py-2 px-4 rounded-full">View Details</button>
            </div>
          </div>
        </div>

        <div className="w-full h-full bg-white">
          {/* Item 1 */}
          <motion.div className="flex flex-col md:flex-row xl:text-xl lg:text-lg md:text-sm px-4 md:px-0 ml-0 lg:ml-20 md:ml-0 mb-12 xl:mb-28 lg:mb-24 md:mb-16 pt-12 md:pt-20" >
            <motion.img src={data2.item1.img} className="w-full md:w-1/3 h-full rounded-2xl mb-4 md:mb-0" />
            <motion.div className="flex flex-col justify-center text-justify xl:my-10 lg:my-6 md:my-4 xl:pl-20 lg:pl-12 md:pl-4 xl:pr-20 lg:pr-16 md:pr-12 bg-gradient-to-l to-yellow-400 from-white" >
              <div className="font-bold mb-2 text-base md:text-lg lg:text-xl xl:text-2xl xl:mb-6 lg:mb-4 md:mb-2" style={{lineHeight: '1.5'}}>{data2.item1.title}</div>
            </motion.div>
          </motion.div> 
          
          {/* Item 2 */}
          <motion.div className="flex flex-col md:flex-row xl:text-xl lg:text-lg md:text-sm mr-4 ml-4 md:ml-0 md:mr-20  mb-12 xl:mb-28 lg:mb-24 md:mb-16 pt-12 md:pt-20" >
            <motion.div className="order-2 md:order-1 flex flex-col justify-center text-justify xl:my-10 lg:my-6 md:my-4 xl:pl-20 lg:pl-12 md:pl-4 xl:pr-10 lg:pr-6 md:pr-2 bg-gradient-to-r to-blue-400 from-white" >
              <div className="font-bold mb-2 text-base md:text-lg lg:text-xl xl:text-2xl xl:mb-6 lg:mb-4 md:mb-2"style={{lineHeight: '1.5'}}>{data2.item2.title}</div>
            </motion.div>
            <motion.img src={data2.item2.img} className="order-1 md:order-2 w-full md:w-1/3 h-full mb-4 md:mb-0 rounded-2xl"  />
          </motion.div>
          
          {/* Item 3 */}
          <motion.div className="flex flex-col md:flex-row xl:text-xl lg:text-lg md:text-sm px-4 md:px-0 ml-0 lg:ml-20 md:ml-0 mb-12 xl:mb-28 lg:mb-24 md:mb-16 pt-12 md:pt-20" >
            <motion.img src={data2.item3.img} className="w-full md:w-1/3 h-full rounded-2xl mb-4 md:mb-0"  />
            <motion.div className="flex flex-col justify-center text-justify xl:my-10 lg:my-6 md:my-4 xl:pl-20 lg:pl-12 md:pl-4 xl:pr-20 lg:pr-16 md:pr-12 bg-gradient-to-l to-yellow-400 from-white">
              <div className="font-bold mb-2 text-base md:text-lg lg:text-xl xl:text-2xl xl:mb-6 lg:mb-4 md:mb-2" style={{lineHeight: '1.5'}}>{data2.item3.title}</div>
            </motion.div>
          </motion.div>
          
        </div>
      </motion.div>
    </motion.section>
  );
};

export default Section3;
