import React from 'react';
import backgroundImage from '../../../assets/Enterprise/Section1imagebg.png'; // Import the background image xl view and mobile view done

const Section1 = () => {
  return (
    <section className="relative bg-cover bg-center py-48 md:py-40 lg:py-56" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className=" px-4">
        <h1 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center text-white font-bold justify-center items-center">Our Social Enterprises</h1>
      </div>
    </section>
  );
};

export default Section1;
