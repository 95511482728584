import React from "react";
import LeftSideImage from "../Templates/LeftSideImage";
import smmain from "../../../assets/NonProfitOrganisation/Section3_image.png";

const Section3 = () => {
  const data = {
    description: (
      <>
        Attract volunteers to support your campaigns and local activities
        through the vast pool of talented volunteers at HPDS. Tap into our
        network to recruit passionate individuals eager to contribute to your
        cause.
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <LeftSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section3;
