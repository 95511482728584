import React from "react";
import ShadowCenterCardTemplate from "../Templates/ShadowCenterCardTemplate";
import instructorData from "./instructorData";

const Section2 = () => {
  return (
    <ShadowCenterCardTemplate
      title="Grow as an Instructor"
      data={instructorData}
      showUnderlineStyle={true}
      underlineTop={-4.6}
      responsiveLeftXL="200px" // Set the desired left value for extra-large screens (xl)
      responsiveLeftLG="80px" // Set the desired left value for large screens (lg)
      responsiveLeftMD="60px" // Set the desired left value for medium screens (md)
    />
  );
};

export default Section2;
