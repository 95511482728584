import SampleImage1 from "../../../assets/HPDSxEngine/Section6image1.png"
import SampleImage2 from "../../../assets/HPDSxEngine/Section6image2.png"
import SampleImage3 from "../../../assets/HPDSxEngine/Section6image3.png"


const dataset = [
    {
      id: 1,
      title: <span className="text-blue-500">Edu-Skill Innovate Hub</span>,
      description: <>An Edu-Skill Collaboratory is a game changing <span className="text-blue-400"></span>re-engineering in <span className="text-blue-400">Education</span> and <span className="text-blue-400">Learning</span> and <span className="text-blue-400">high potential development</span> with <span className="text-blue-400">well-being</span>, ued for education and Human Potential Development and for <span className="text-blue-400">Action, Success and Happiness  Think Tank.</span></>,
      image: SampleImage1
    },
    {
      id: 2,
      title: <span className="text-green-500">Integrated Mindset Mastery</span>,
      description: "Identify & drive winning mindsets, habits and behaviors for Integrate Social, Emotional, and Cognitive Skills.",
      image: SampleImage2
    },
    {
      id: 3,
      title: <span className="text-yellow-400">Innovative AI Solutions</span>,
      description: "Our emerging AI product portfolio utilizes the latest innovations in Artificial Intelligence, Machine Learning and Analytics. Our in-depth expertise in multiple verticals deliver positive outcomes to our users.",
      image: SampleImage3
    },
  ];
  
  export default dataset;
  