// Navbar.js

import React from "react";
import logo from "../../../assets/HPDS-logo.png";
import { Link } from "react-router-dom/dist";
import { useState } from "react";
import FootContent from "./FootContent.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import MobileNavbar from "./MobileNavbar.js";
const Navbar = ({ handleLogout }) => {
  const [isEngineOpen, setIsEngineOpen] = useState(false);
  const [isPlatformOpen, setIsPlatformOpen] = useState(false);
  const [isResOpen, setIsResOpen] = useState(false);
  const [isAllOpen, setIsAllOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSupportOpen, setIsSupportOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleEngine = () => {
    setIsPlatformOpen(false);
    setIsAllOpen(false);
    setIsResOpen(false);
    setIsEngineOpen(!isEngineOpen);
  };
  const togglePlatform = () => {
    setIsAllOpen(false);
    setIsResOpen(false);
    setIsEngineOpen(false);
    setIsPlatformOpen(!isPlatformOpen);
  };
  const toggleRes = () => {
    setIsAllOpen(false);
    setIsPlatformOpen(false);
    setIsEngineOpen(false);
    setIsResOpen(!isResOpen);
  };
  const toggleAll = () => {
    setIsResOpen(false);
    setIsPlatformOpen(false);
    setIsEngineOpen(false);
    setIsAllOpen(!isAllOpen);
  };
  const toggleSupport = () => {
    setIsResOpen(false);
    setIsPlatformOpen(false);
    setIsEngineOpen(false);
    setIsAllOpen(false);
  };
  const closetabs = () => {
    setIsResOpen(false);
    setIsPlatformOpen(false);
    setIsEngineOpen(false);
    setIsAllOpen(false);
    setIsSupportOpen(false);
    setMenuOpen(false);
  };

  return (
    <div className="">
      <div
        onClick={closetabs}
        className={`w-full h-full  fixed ${
          isAllOpen ||
          isEngineOpen ||
          isMenuOpen ||
          isPlatformOpen ||
          isResOpen ||
          isSupportOpen
            ? "block"
            : "hidden"
        }`}
      ></div>
      <div className="drop-shadow hidden md:block fixed top-0 left-0 right-0 z-50 w-full">
        <nav className="bg-white text-black lg:px-10 ">
          <div className="flex items-center justify-start">
            <div className="lg:h-16 h-12 border-e bordergray-300 flex lg:px-3">
              <Link
                className="no-underline hover:text-dark hover:no-underline my-auto"
                to="/hpdsx-menu"
              >
                <div className="px-2 md:w-24 lg:w-36">
                  <img src={logo} className="h-full w-full" alt="img" />
                </div>
              </Link>
            </div>

            <div className="w-full flex my-auto justify-between p-2">
              <div className="flex justify-start items-center">
                <Link
                  className=" no-underline hover:no-underline hover:text-blue-500"
                  to={"/"}
                >
                  <div className="md:text-md lg:text-lg font-semibold">
                    THINKSKILLINDIA
                  </div>
                </Link>
                <div
                  className={`md:flex justify-between h-10 content-center hidden  ml-4`}
                >
                  <div className="relative">
                    <div
                      href="#"
                      className={`md:text-sm lg:text-md flex items-center h-10 lg:p-3 p-1  cursor-pointer  font-medium underline-offset-4 hover:text-blue-600 no-underline hover:no-underline ${
                        isEngineOpen
                          ? "text-blue-600 border-dashed border border-black"
                          : ""
                      }`}
                      onClick={toggleEngine}
                    >
                      HPDSx Engine
                      <FontAwesomeIcon
                        className="ml-1 text-xs"
                        icon={faChevronDown}
                      />
                    </div>
                    <div
                      className={` absolute top-10  left-0 z-10 px-8 gap-6  flex flex-col bg-white  w-max lg:p-3 p-1 transition-opacity duration-700 ease-in-out ${
                        isEngineOpen ? "opacity-100" : "hidden"
                      }`}
                    >
                      <Link to="/hpdsx-engine" className="no-underline ">
                        <div className=" hover:text-blue-500">hpdsx Engine</div>
                      </Link>
                      <Link
                        to="/hpdsxhub/learnandgrow"
                        className="no-underline"
                      >
                        <div className=" hover:text-blue-500">
                          Learn and Grow
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="relative">
                    <Link
                      to="/hpdsxhub"
                      className={`md:text-sm lg:text-md flex items-center h-10 lg:p-3 p-1 hover:no-underline cursor-pointer font-medium underline-offset-4 hover:text-blue-600 ${
                        isSupportOpen
                          ? "text-blue-600 border-dashed border border-black"
                          : ""
                      }`}
                    >
                      HPDSx Hub
                    </Link>

                    <div
                      className={`absolute top-10 left-0 z-10 px-8 gap-6 flex flex-col bg-white w-max lg:p-3 p-1 transition-opacity duration-700 ease-in-out ${
                        isSupportOpen ? "opacity-100" : "opacity-0 hidden"
                      }`}
                    >
                      <Link to="/hpdsxhub">Link to HPDSx Hub</Link>
                    </div>
                  </div>

                  <div className="relative">
                    <div
                      href="#"
                      className={`md:text-sm lg:text-md flex items-center h-10 lg:p-3 p-1 cursor-pointer no-underline  font-medium underline-offset-4 hover:text-blue-600 ${
                        isPlatformOpen
                          ? "text-blue-600 border-dashed border border-black"
                          : ""
                      }`}
                      onClick={togglePlatform}
                    >
                      Platform
                      <FontAwesomeIcon
                        className="ml-1 text-xs"
                        icon={faChevronDown}
                      />
                    </div>
                    <div
                      className={`absolute top-10 -left-full text-sm z-10 px-8 gap-5  flex bg-white w-max p-3 transition-opacity duration-700 ease-in-out ${
                        isPlatformOpen ? "opacity-100" : "opacity-0 hidden"
                      }`}
                    >
                      <div className="flex flex-col gap-6 w-40 hover-blue-elements">
                        <h4 className="font-medium">For Students</h4>
                        <Link>Create your unique success profiler</Link>

                        <Link>Student engagement</Link>

                        <Link>Self help care</Link>

                        <Link>Expert staff care</Link>

                        <Link>Student Walkthrough</Link>
                      </div>
                      <div className="flex flex-col gap-6 w-40 hover-blue-elements">
                        <h4 className="font-medium">For Organization</h4>
                        <Link to="/">Individual Assessment</Link>

                        <Link to="/">Student Assessment Model</Link>

                        <Link to="/">Global Competency Development</Link>

                        <Link to="/">Pick up where you have left</Link>
                      </div>
                      <div className="flex flex-col gap-6  w-40 hover-blue-elements">
                        <h4 className="font-medium">For Institution</h4>
                        <Link to="/create-platform">
                          Create your own Digital Learning Platform
                        </Link>

                        <Link to="/">Empower your Experts</Link>

                        <Link to="/">Student Collaboration</Link>

                        <Link to="/">
                          Bring Education Learning more successful together
                        </Link>
                      </div>
                      <div className="flex flex-col gap-6 w-60 hover-blue-elements">
                        <h4 className="font-medium">
                          For Subject Matter Expert
                        </h4>
                        <Link to="/">
                          Discover and Eliminate ignorance and weakness early
                        </Link>

                        <Link to="/">
                          Support your mind to make a global impact
                        </Link>

                        <Link to="/">
                          Reimagine Human life to enable transformation
                        </Link>

                        <Link to="/">
                          Connecting with people across the globe, global
                          organizations, and looking at new ways of learning and
                          sharing with each other
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="relative">
                    <div
                      href="#"
                      className={`md:text-sm lg:text-md flex items-center h-10 lg:p-3 p-1 hover:no-underline cursor-pointer   font-medium underline-offset-4 hover:text-blue-600 ${
                        isResOpen
                          ? "text-blue-600 border-dashed border border-black"
                          : ""
                      }`}
                      onClick={toggleRes}
                    >
                      Resources
                      <FontAwesomeIcon
                        className="ml-1 text-xs"
                        icon={faChevronDown}
                      />
                    </div>
                    <div
                      className={`absolute top-10  left-0 text-sm z-10 px-8 gap-5  flex flex-col bg-white w-max p-3 transition-opacity duration-700 ease-in-out ${
                        isResOpen ? "opacity-100" : "opacity-0 hidden"
                      }`}
                    >
                      <Link to={"/posts/blogs"} className="hover:text-blue-500">
                        Blogs
                      </Link>
                      <Link
                        to={"/posts/articles"}
                        className="hover:text-blue-500"
                      >
                        Articles
                      </Link>
                      <Link to={"events"} className="hover:text-blue-500">
                        Events
                      </Link>
                      <Link to={"/community"} className="hover:text-blue-500">
                        Community
                      </Link>
                      <Link
                        to="/create-platform"
                        className="hover:text-blue-500"
                      >
                        Create your Platform
                      </Link>
                      <Link className="hover:text-blue-500">
                        Create your Content
                      </Link>
                      <Link
                        to={"/create/events"}
                        className="hover:text-blue-500"
                      >
                        Create your Event
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-center">
                {/* <div className={`relative hidden lg:flex`}> */}
                <div
                  href="#"
                  className={`md:text-sm lg:text-md md:flex hidden h-10 items-center my-auto px-2 hover:no-underline cursor-pointer  font-medium underline-offset-4 hover:text-blue-600 ${
                    isAllOpen
                      ? "text-blue-600 border-dashed border border-black"
                      : ""
                  }`}
                  onClick={toggleAll}
                >
                  All HPDS
                  <FontAwesomeIcon
                    className="ml-1 text-xs"
                    icon={faChevronDown}
                  />
                </div>
                {/* <Link href="#" className="md:text-sm lg:text-md  my-auto px-2 cursor-pointer  font-medium underline-offset-4 hover:text-blue-600 focus:bg-white" >
                  Sign In+
                </Link> */}
                <a
                  href="#"
                  className="md:text-sm lg:text-md my-auto px-2 cursor-pointer font-medium underline-offset-4 hover:text-blue-600 focus:bg-white"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default link behavior
                    handleLogout(); // Call the logout function
                  }}
                >
                  Logout
                </a>
              </div>
              <div
                className={`w-full absolute top-12 -left-3 -z-10 px-8 font-normal flex bg-neutral-100  lg:p-3 p-1 transition-opacity duration-700 ease-in-out ${
                  isAllOpen ? "opacity-100" : "opacity-0 hidden"
                }`}
              >
                <FootContent />
              </div>
            </div>
          </div>
        </nav>
      </div>
      <MobileNavbar />
    </div>
  );
};

export default Navbar;
