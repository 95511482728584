import React from "react";
import sectiononeData from "./section1Dataset";
import RightSideImageFlexTemplate from "../Templates/RightSideImageFlexTemplate";

const SpeakerMotivate = () => {
  const data= sectiononeData[0];

  return (
    <div>
      <RightSideImageFlexTemplate
        title={data.titleheading}
        description={data.description}
        imageUrl={data.imageUrl}
        bgColor={data.bgColor}
      />
    </div>
  );
};

export default SpeakerMotivate;