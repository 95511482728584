import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ImageToImport from '../../../assets/ChangeAgent/Section4image1.png'; // Import your image

const Section4 = () => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      console.log('Section 4 is in view!');
      // You can add additional animations or logic here
    }
  }, [inView]);

  return (
    <motion.section
      className="bg-white py-4 md:py-20"
      ref={ref}
      initial={{ opacity: 0, y: 75 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 75, scale: 0.9 }}
      transition={{ ease: 'easeInOut', duration: 1.5 }}
    >
      <div className="px-4 md:pl-12">
        <div className="grid grid-cols-12 gap-4">
          <motion.div
            className="col-span-12 md:col-span-6 lg:py-0 xl:py-20 lg:mx-8 xl:mx-16"
            initial={{ opacity: 0, x: -75 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -75 }}
            transition={{ ease: 'easeInOut', duration: 1.5, delay: 0.5 }}
          >
            <div>
              <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold my-8" style={{ lineHeight: '140%' }}>I am looking for a <span className='' style={{ color: 'rgb(0, 134, 232)' }}>Career</span> in 21st century that will make me <span className='' style={{ color: 'rgb(0, 134, 232)' }}>First in Line</span></h2>
              <p className="md:text-xl lg:text-2xl text-gray-700" style={{ lineHeight: '140%' }}>Supporting students, professionals, administrators, and business experts in coaching, counseling, mentoring and guiding forecasting skills need and skills are deemed to be essential for future life's success</p>
            </div>
          </motion.div>
          <motion.div
            className="col-span-12 md:col-span-6 pb-4 md:pb-0 md:mx-4 lg:mx-8 xl:mx-16"
            initial={{ opacity: 0, x: 75 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 75 }}
            transition={{ ease: 'easeInOut', duration: 1.5, delay: 0.5 }}
          >
            <motion.img
              src={ImageToImport}
              alt="Right Image"
              className="lg:w-full xl:w-4/5"
              initial={{ scale: 0.9, rotate: -10 }}
              animate={inView ? { scale: 1, rotate: 0 } : { scale: 0.9, rotate: -10 }}
              transition={{ ease: 'easeInOut', duration: 1.5 }}
            />
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default Section4;
