import React from "react";
import right_img from "../../../assets/LocalActivities/bg_img.png";
import cover_bg from "../../../assets/LocalActivities/cover_bg.jpg";

const LandingCover = () => {
  return (
    <div
      className="w-full h-[80vh] grid grid-cols-12 gap-0 p-5 px-10"
      style={{
        backgroundImage: `url(${cover_bg})`,
        backgroundSize: "cover", // Maintain original image size
        backgroundPosition: "center", // Center the image
      }}
    >
      <div className="pt-20 px-3  text-black md:px-10 order-2 md:order-1 col-span-12 md:col-span-6 flex flex-col cursor-pointer ">
        <div className="mb-0 md:mb-2 pb-4 md:pb-1 xl:pb-4 font-semibold text-2xl xl:text-6xl md:text-2xl lg:text-4xl leading-tight md:leading-tight lg:leading-tight xl:leading-tight">
          Explore, Connect, <br></br>Make a Difference
        </div>
        <div className="text-lg lg:text-xl xl:text-3xl md:text-lg py-12 ">
          <div>
            Discover events, programs, and projects happening in your locality.
            Engage with initiatives that you love. Embrace them and make a
            difference !!
          </div>
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-12 md:col-span-6 flex flex-col justify-end items-end xl:py-12 md:py-1 lg:py-8">
        <div className="w-4/5 pr-20">
          <img src={right_img} alt="blank" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
