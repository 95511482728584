import React from 'react';
import backgroundImage from '../../../assets/Council/Section1bgimage.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { color } from 'framer-motion';

const Section1 = () => {
  return (
    <section className="relative  bg-cover bg-bottom xl:bg-center py-12" style={{ backgroundImage: `url(${backgroundImage})` , backgroundRepeat: 'no-repeat',  }}>
      <div className="  px-4">
        <div className="flex flex-col md:flex-row items-center">
          {/* Left side */}
          <div className="md:w-1/2 pr-8 py-12 xl:py-56 px-4 xl:px-24">
            <h1 className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl  font-bold  " style={{ lineHeight: '140%' , color : '#0086E8' } }>
            HPDS Councils: Empowering Leaders
for future innovations ​</h1>
            <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-3 px-4 rounded-full text-md lg:mt-2 my-4 md:my-0 " style={{ lineHeight: '140%' }}>
              Join HPDS Council <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </button>
          </div>
          {/* Right side - You can add content here if needed */}
        </div>
      </div>
    </section>
  );
};

export default Section1;
