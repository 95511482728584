import React from "react";
import LeftSideImage from "../../components/Templates/LeftSideImage";
import img_1 from "../../../assets/LocalActivities/img_1.jpg";

const Section1 = () => {
  const data = {
    title: <>Empower Organizers, Amplify Community Initiatives Together.</>,
    description: (
      <>
        Our platform serves as a dynamic stage for organizers to showcase their
        impactful activities. With our tools and resources, organizers can
        spotlight their initiatives, attract participants, and amplify their
        reach within the community. Join us and elevate your cause to new
        heights
      </>
    ),
    imageUrl: img_1,
  };

  return (
    <div>
      <LeftSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section1;
