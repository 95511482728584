import React from 'react'
import bg2 from "../../../assets/container1.png" 
import CentralImage from '../../../assets/cont2.png' 

const ContSlide = () => {
  const title = 'Peer to Peer networking and Validation to excel';
  const subTit = ' fostering peer networking and validation, empowering individuals to excel through collaborative support and mutual validation';
  return (
    <div className="flex flex-col items-center justify-center bg-cover " style={{ backgroundImage: `url(${bg2})` }}>
      <div className="lg:p-10 p-4 items-start lg:w-1/2 md:w-9/12 text-center text-white">
        <div className="font-semibold lg:text-4xl md:text-3xl text-xl lg:my-6 md:my-3">{title}</div>
        <div className=" text-xs md:text-sm font-semibold">{subTit}</div>
      </div>
      <div className='lg:w-4/6 md:w-5/6 '>
        <img src={CentralImage} alt="img" />
      </div>
    </div>
  )
}

export default ContSlide