import React from 'react';

import DatasetSec5 from './DatasetSec3';

const Section5 = () => {
  

  return (
    <div
      className="py-12 md:py-36 mb-32 md:px-2 lg:px-8 xl:px-20"
      
    >
      

      {/* Cards */}
      <div className="grid md:grid-cols-7 grid-cols-1 gap-0">
        {DatasetSec5.map((card, index) => (
          <div key={card.id} className={`col-span-1 px-4 md:px-0 md:text-sm lg:text-md xl:text-lg ${index === 6 ? 'border-r-0' : ''}`} style={{ height: '12rem', '@media (min-width: 768px)': { height: '28rem' } }}>
            <div className={`md:p-4 flex flex-col items-center border-r border-gray-500 ${index % 2 === 1 && index !== 6 ? 'md:py-24 lg:py-40' : ''}`}>
              <p className="text-center md:text-sm lg:text-md xl:text-lg mb-4">{card.description}</p>
              <img src={card.image} alt={`Card ${card.id}`} className="w-24 h-24 md:w-40 md:h-40 object-contain" />
            </div>
          </div>
        ))}
      </div>

      {/* Description */}
      
    </div>
  );
};

export default Section5;
