import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthAmericas, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import DatasetSec9 from './DatasetSec9';

const Section13 = () => {
    const backgroundColor = '#0086E8';
    const [selectedButton, setSelectedButton] = useState(1); // Initially selecting the first button
    const [loaded, setLoaded] = useState(false);
    const { ref, inView } = useInView();
    const controls = useAnimation();
    const contentControls = useAnimation();

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (inView) {
            controls.start({ opacity: 1, y: 0, transition: { duration: 1.5, ease: 'easeInOut', delay: 0.3 } });
        } else {
            controls.start({ opacity: 0, y: 75 });
        }
    }, [controls, inView]);

    useEffect(() => {
        if (loaded) {
            handleButtonClick(selectedButton); // Initially select the first button
        }
    }, [loaded, selectedButton]);

    const handleButtonClick = async (id) => {
        setSelectedButton(id);
        // Add animation logic for button click if needed
        await contentControls.start({ opacity: 0 }); // Fade out content
        await contentControls.start({ opacity: 1 }); // Fade in content
    };

    return (
        <motion.div
            className=" relative"
            initial={{ opacity: 0, y: 75 }}
            animate={controls}
            ref={ref}
        >
            <div className=" flex flex-col text-white" style={{ background: backgroundColor }}>
                <h1 className='text-4xl md:text-5xl text-center py-8 md:py-16'><span className='mx-4'><FontAwesomeIcon icon={faEarthAmericas} style={{ color: 'white' }} /></span>Your <span className='text-yellow-400'>World</span></h1>
                <h1 className="text-lg md:text-lg lg:text-xl xl:text-3xl leading-loose mb-4 md:py-4 lg:py-12 mx-4 md:mx-12 lg:mx-20 xl:mx-28 font-medium" style={{ lineHeight: '140%' }}>The challenge is to prepare human to <span className='text-yellow-400'>think critically</span>, to engage in mental activity, or habits of mind, that “<span className='text-yellow-400'>…use facts to plan</span>, order, and work toward an end; seek meaning or explanations; are self-reflective; and use reason to question claims and <span className='text-yellow-400'>make judgments…</span>”</h1>
                <h1 className="text-lg md:text-lg lg:text-xl xl:text-3xl leading-loose mb-4 md:py-0 mx-4 md:mx-12 lg:mx-20 xl:mx-28 font-medium" style={{ lineHeight: '140%' }}>Extraordinary <span className='text-yellow-400'>era for business and society</span> creating something new everyday. Making difference for others engaging real power to your approach significant impact on future economic growth building a highly skilled work force.</h1>
                <div className="text-lg grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-1 lg:gap-6 xl:gap-10 py-4 md:py-4 lg:py-0 xl:py-4 mx-4 md:mx-12 lg:mx-20 xl:mx-24">
                    {DatasetSec9.map(item => (
                        <motion.button
                            key={item.id}
                            className={`text-center bg-transparent hover:bg-yellow-400 text-yellow-400 hover:text-white md:text-sm lg:text-md font-semibold py-3 md:px-2 xl:px-6 rounded-xl whitespace-nowrap border ${selectedButton === item.id ? 'border-yellow-400 hover:border-transparent' : 'border-yellow-400 hover:border-transparent'} transition duration-300 ease-in-out`}
                            onClick={() => handleButtonClick(item.id)}
                            initial={{ opacity: 0, y: 75 }}
                            animate={controls}
                            transition={{ delay: 0.5 }}
                        >
                            {item.heading}
                        </motion.button>
                    ))}
                </div>
                <div className="grid grid-cols-12 gap-4">
                    <motion.div
                        className="col-span-12 md:col-span-6 text-left mx-2 md:mx-8 lg:mx-20 xl:mx-28 my-8 md:my-20 lg:my-24 xl:my-20"
                        initial={{ opacity: 0, y: -50 }}
                        animate={contentControls}
                        transition={{ duration: 0.8, ease: "easeInOut", delay: 0.3 }}
                    >
                        {loaded && (
                            <>
                                <motion.div
                                    className="text-3xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-8 ml-12 md:ml-0" 
                                >
                                    <span className='mx-4'><FontAwesomeIcon icon={faPeopleGroup} style={{ color: "#f7f9fd" }} /></span>{DatasetSec9[selectedButton - 1].heading}
                                </motion.div>
                                <motion.div
                                    className='text-lg md:text-lg lg:text-xl xl:text-2xl font-medium ml-16 md:ml-4 lg:ml-0'
                                >
                                    {DatasetSec9[selectedButton - 1].description}
                                </motion.div>
                            </>
                        )}
                    </motion.div>
                    <motion.div
                        className="col-span-12 md:col-span-6 flex justify-center items-center mx-4 md:mx-0 md:mr-12 pb-6 md:pb-20"
                        initial={{ opacity: 0, y: 50 }}
                        animate={contentControls}
                        transition={{ duration: 0.8, ease: "easeInOut", delay: 0.3 }}
                    >
                        {loaded && (
                            <motion.img
                                src={DatasetSec9[selectedButton - 1].image}
                                alt="Right Section Image"
                                className="w-full md:w-full xl:w-4/5 mx-auto md:mx-0 mr-24 md:mr-0"
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.8, ease: "easeInOut", delay: 0.3 }}
                            />
                        )}
                    </motion.div>
                </div>
            </div>
        </motion.div>
    );
};

export default Section13
