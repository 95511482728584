import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons';
import DatasetSec4 from './DatasetSec13';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Section13 = () => {
  const { buttons } = DatasetSec4;
  const [selectedButton, setSelectedButton] = useState(buttons[0]);
  const [loaded, setLoaded] = useState(false);
  const controlsLeft = useAnimation();
  const controlsRight = useAnimation();

  useEffect(() => {
    setLoaded(true);
  }, []);

  const { ref, inView } = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const handleButtonClick = async (button) => {
    await Promise.all([
      controlsLeft.start({ opacity: 0, x: -30, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } }),
      controlsRight.start({ opacity: 0, x: -30, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } })
    ]);
    setSelectedButton(button);
    await Promise.all([
      controlsLeft.start({ opacity: 1, x: 0, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } }),
      controlsRight.start({ opacity: 1, x: 0, transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 } })
    ]);
  };

  return (
    <div
      ref={ref}
      className="h-[180vh] md:h-[120vh] lg:h-[140vh] relative"
    >
      <motion.div
        className="absolute inset-0 flex flex-col text-black"
        initial={{ opacity: 1 }}
        animate={controls}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 0.3 }}
      >
        <h1 className="text-xl md:text-3xl lg:text-4xl xl:text-5xl leading-loose py-8 md:py-12 pt-12 md:pt-12 lg:pt-32  lg:py-0  mx-4 md:mx-12 lg:mx-20 xl:mx-28 font-semibold" style={{ lineHeight: '140%' }}>
          <span className='text-black'>THINKSKILLINDIA Platform will be</span>
        </h1>
        <div className="grid xl:grid-cols-6 lg:grid-cols-3  md:grid-cols-3  gap-3 md:gap-2  lg:gap-4 xl:gap-6 md:py-4 mx-4 md:mx-12 lg:mx-20 xl:mx-24">
          {buttons.map(button => (
            <motion.div
              key={button.id}
              className={`text-center cursor-pointer hover:bg-blue-400 text-blue-400 hover:text-white text-md  font-semibold py-3 px-4 rounded-xl whitespace-nowrap border ${selectedButton === button ? 'bg-blue-400 text-white border-blue-400 hover:border-transparent' : 'border-blue-400 hover:border-transparent'} transition duration-300 ease-in-out`}
              onClick={() => handleButtonClick(button)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {button.heading}
            </motion.div>
          ))}
        </div>
        <div className="grid grid-cols-12 gap-4">
          {/* Left Section */}
          <motion.div
            className="col-span-12 md:col-span-7 lg:col-span-6 text-left mx-4 md:mx-12 lg:mx-20 xl:mx-24 my-8 md:my-8 lg:my-20"
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
          >
            {loaded && ( // Only render if loaded is true
              <>
                <motion.div className="md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-8" style={{ lineHeight: '140%' }}
                initial={{ opacity : 1, x: 0}}
                animate={controlsLeft}
                variants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 1.3, ease: 'easeOut', delay: 0.6 } },
                  hidden: { opacity: 0, x: -30 }
                }}
                >
                  <span className='mx-4'>
                    <FontAwesomeIcon icon={faPaintbrush} style={{ color: 'black' }} />
                  </span>
                  {selectedButton.heading}
                </motion.div>
                <motion.div className='md:text-lg lg:text-xl xl:text-3xl font-medium text-justify' style={{ lineHeight: '140%' }}
                initial={{ opacity : 1, x: 0}}
                animate={controlsLeft}
                variants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 1.3, ease: 'easeOut', delay: 0.6 } },
                  hidden: { opacity: 0, x: -30 }
                }}
                >
                  {selectedButton.description}
                </motion.div>
              </>
            )}
          </motion.div>

          {/* Right Section */}
          <motion.div
            className="col-span-12 mx-4 md:mx-0 md:col-span-5 lg:col-span-6 flex justify-center items-center"
            style={{ overflow: 'hidden' }}
            initial={{ opacity : 1, x: 0}}
            animate={controlsRight}
            variants={{
              visible: { opacity: 1, x: 0, transition: { duration: 1.3, ease: 'easeOut', delay: 0.6 } },
              hidden: { opacity: 0, x: 30 }
            }}
          >
            {loaded && ( // Only render if loaded is true
              <motion.img
                src={selectedButton.image}
                alt="Right Section Image"
                className="md:w-full md:mr-16 lg:w-4/5"
                initial={{ opacity: 1, scale: 0.9 }}
                animate = {controlsRight}
                variants={{
                  visible: { opacity: 1, x: 0, transition: { duration: 1.3, ease: 'easeOut', delay: 0.6 } },
                  hidden: { opacity: 0, x: 30 }
                }}
              />
            )}
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Section13;
