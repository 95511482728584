import React from 'react'
import HeroImg from '../../assets/collab_heroImg.jpg'
import S1 from '../../assets/collab_s1.jpg'
import S2 from '../../assets/collab_s2.jpg'
import S3 from '../../assets/collab_s3.jpg'
import gImg1 from '../../assets/chapter_smImg1.png'
import gImg2 from '../../assets/chapter_smImg2.png'
import gImg3 from '../../assets/chapter_smImg3.png'
import SlideWithImage from "../components/Templates/SlideWithImage"
import HeroSection from "../components/Collaboration/HeroSection"
import Grid_2x3 from "../components/Collaboration/Grid_2x3"

const Collaboration = () => {
  const heroSectionInfo = {
    img:HeroImg,
    title: 'Exchange perspective & Collaborate to bring holistic success',
    subTit: <div className="font-normal">Harness the collective power of varied insights, skills, and experiences, culminating in solutions that transcend individual capabilities</div>,
  };

  const slideInfo1={
    img:S1,
    title: <div className='font-normal'>Partnering with Educational Institutions for Live Project Engagement</div>,
    subTit: <div className="font-normal"> Engage with educational institutions to involve aspiring talents in live projects, fostering their career development.</div>,
  };

  const slideInfo2={
    img:S2,
    title: 'Maximizing Efficiency through Intra-Organizational Resource Sharing',
    subTit: <div className="font-normal">Align different departments to break down barriers, enabling information sharing, idea exchange, towards a common goal</div>,
  };

  const slideInfo3={
    img:S3,
    title: 'Increasing Productivity through Inter-Organizational Collaboration for project',
    subTit: <div className="font-normal">Optimizing output by cultivating synergistic inter-organizational collaboration, thereby enhancing project productivity significantly</div>,
  };


  const data = [
    {
      img: gImg1, 
      title: <div className='text-black'>Market Expansion</div>, 
      subTtl: 'Access new markets through shared networks'
    },
    {
      img: gImg2, 
      title: <div className='text-black'>Cost Reduction</div>, 
      subTtl: 'Shared expenses lead to reduced operational costs'
    },
    {
      img: gImg3, 
      title: <div className='text-black'>Agility and Adaptability</div>, 
      subTtl: 'React more effectively to market changes'
    },
    {
      img: gImg1, 
      title: <div className='text-black'>Team Cohesion</div>, 
      subTtl: 'Encourage teamwork and a sense of belonging'
    },
    {
      img: gImg2, 
      title: <div className='text-black'>Conflict Resolution</div>, 
      subTtl: 'Address conflicts through open dialogue'
    },
    {
      img: gImg3, 
      title: <div className='text-black'>Increased Productivity</div>, 
      subTtl: 'Enhanced collaboration leads to higher output'
    },
  ]
  return (
    <div>
      <HeroSection slideInfo={heroSectionInfo} contStyle ={` flex-row-reverse`}/>
      <SlideWithImage slideInfo={slideInfo1} contStyle ={`  md:py-20 py-7 `}/>
      <Grid_2x3 title= {'Key areas that Collaborating Impacts'} data={data}/>
      <SlideWithImage slideInfo={slideInfo2} contStyle ={`bg-neutral-100  md:py-20 py-7 flex-row-reverse`}/>
      <SlideWithImage slideInfo={slideInfo3} contStyle ={`bg-white  md:py-20 py-7`}/>
    </div>
  )
}

export default Collaboration