import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import img1 from "../../../assets/ThinkSkillIndia/img1_sec7.png";
import img2 from "../../../assets/ThinkSkillIndia/img2_sec7.jpg";
import img3 from "../../../assets/ThinkSkillIndia/img3_sec7.jpg";
import img4 from "../../../assets/ThinkSkillIndia/img4_sec7.jpg";

const data1 = {
  title1: (
    <>
      A Blueprint for <span className="text-blue-500">Success</span>
    </>
  ),
  title2: (
    <>
      Will amplify the intensity of the Transformation, varying by time and
      level of impact
    </>
  ),
};
const data2 = {
  item1: {
    id: 1,
    title: (
      <>
        Our <span className="text-blue-500">Goal</span> is to combat the
        backwardness and subjugation of India through the growing population of
        Indian youth.
      </>
    ),
    description: (
      <>
        These results are troubling, especially since people with only basic
        competencies are the most likely to flounder in the{" "}
        <span className="text-blue-500">
          rising high-skill, high-wage service economy.
        </span>
      </>
    ),
    img: img2,
  },
  item2: {
    id: 2,
    title: (
      <>
        Student, in ensuing constant strategic conversations, raising awareness
        on topical and pressing challenges mitigating the growth of India.
      </>
    ),
    description: (
      <>
        To prepare students to be competitive, the nation needs an “NCLB plus”
        agenda that infuses 21st century skills into core academic subjects.
      </>
    ),
    img: img3,
  },
  item3: {
    id: 3,
    title: (
      <>
        Organizing <span className="text-blue-500">solution-based online events</span>, liaising with groups and
        government, as well as engaging other relevant stakeholders.
      </>
    ),
    description: (
      <>
        This is not an either–or agenda: Students can master 21st century skills
        while they learn reading, mathematics, science, writing and other
        school subjects.
      </>
    ),
    img: img4,
  },
};

const Section7 = () => {
  const { ref, inView } = useInView();

  return (
    <motion.div
      className="w-full h-full bg-white"
      ref={ref}
      initial={{ opacity: 0, y: 80 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }}
      transition={{ duration: 0.7, ease: "easeInOut", delay: 0.2 }}
    >
      <div className="flex flex-col md:flex-row ">
        <div className="w-full h-full  xl:p-24 lg:p-16 md:p-8 p-4 xl:pr-10 lg:pr-8 md:pr-6 pr-4 order:1 md:order-1">
          <div className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-bold" style={{ lineHeight: "140%" }}>
            {data1.title1}
          </div>
          <div className=" border-2 border-yellow-400 w-1/2 xl:my-6 lg:my-4 md:my-2 my-2"></div>
          <div className="xl:text-3xl lg:text-2xl md:text-xl text-lg font-semibold" style={{ lineHeight: "140%" }}> 
            {data1.title2}
          </div>
        </div>
        <img
          className="w-full md:w-3/5 ml-0 md:-ml-16 order-2 md:order-2"
          src={img1}
          
        />
      </div>
      <div className="flex flex-col md:flex-row xl:text-3xl lg:text-2xl md:text-lg text-md mr-4 ml-4 mb-8 md:mr-0 md:ml-20 xl:mb-28 lg:mb-24 md:mb-16 ">
        <img
          src={data2.item1.img}
          className="w-full md:w-1/2 lg:w-2/5 h-full rounded-2xl my-8 lg:my-0"
          initial={{ opacity: 0, x: -50, scale: 0.8 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeInOut", delay: 0.5 }}
        />
        <div
          className="flex flex-col xl:my-10 lg:my-6 md:my-8 xl:pl-20 lg:pl-12 md:pl-4 xl:pr-20 lg:pr-16 md:pr-12 text-justify justify-center bg-gradient-to-r from-yellow-400 to-white"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 50 }}
          transition={{ duration: 0.8, ease: "easeInOut", delay: 0.5 }}
        >
          <div className="font-bold xl:mb-6 lg:mb-4 md:mb-2">
            {data2.item1.title}
          </div>
          <div className="font-medium ">{data2.item1.description}</div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row xl:text-3xl lg:text-2xl md:text-lg text-md mr-4 ml-4 mb-8 md:mr-0 md:ml-0 md:mr-20 xl:mb-28 lg:mb-24 md:mb-16 mb-8  text-white">
        <div
          className="flex flex-col text-justify xl:my-10 lg:my-6 md:my-8 xl:pl-32 lg:pl-12 md:pl-8 xl:pr-10 lg:pr-6 md:pr-2 justify-center bg-gradient-to-r to-blue-400 from-slate-100 order-1 md:order-1"
          initial={{ opacity: 0, x: 50}}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 50 }}
          transition={{ duration: 0.8, ease: "easeInOut", delay: 0.5 }}
        >
          <div className="font-bold  xl:mb-6 lg:mb-4 md:mb-2 ">
            {data2.item2.title}
          </div>
          <div className="font-medium ">{data2.item2.description}</div>
        </div>
        <img
          src={data2.item2.img}
          className="w-full md:w-1/2 lg:w-2/5 h-full rounded-2xl my-4 md:my-0 order-2 md:order-2"
          initial={{ opacity: 0, x: 50, scale: 0.8 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 50, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeInOut", delay: 0.5 }}
        />
      </div>

      <div className="flex flex-col md:flex-row xl:text-3xl lg:text-2xl md:text-lg text-md mr-4 ml-4 mb-8 md:mr-0 md:ml-20 xl:mb-28 lg:mb-24 md:mb-16 ">
        <img
          src={data2.item3.img}
          className="w-full md:w-1/2 lg:w-2/5 h-full rounded-2xl my-8 lg:my-0"
          initial={{ opacity: 0, x: -50, scale: 0.8 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeInOut", delay: 0.5 }}
        />
        <div
          className="flex flex-col xl:my-10 lg:my-6 md:my-8 xl:pl-20 lg:pl-12 md:pl-4 xl:pr-20 lg:pr-16 md:pr-12 text-justify justify-center  bg-gradient-to-r from-yellow-400 to-white"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }}
          transition={{ duration: 0.8, ease: "easeInOut", delay: 0.5 }}
        >
          <div className="font-bold xl:mb-6 lg:mb-4 md:mb-2 ">
            {data2.item3.title}
          </div>
          <div className="font-medium ">{data2.item3.description}</div>
        </div>
      </div>
    </motion.div>
  );
};

export default Section7;
