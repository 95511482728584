import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import favicon from "../../../assets/SkillCollaboratory/Section9favicon.png"
import iconcard1 from "../../../assets/SkillCollaboratory/Section9icon1.png";
import iconcard2 from "../../../assets/SkillCollaboratory/Section9icon2.png";
import iconcard3 from "../../../assets/SkillCollaboratory/Section9icon3.png";
import iconcard4 from "../../../assets/SkillCollaboratory/Section9icon4.png";
import iconcard5 from "../../../assets/SkillCollaboratory/Section9icon5.png";
import iconcard6 from "../../../assets/SkillCollaboratory/Section9icon6.png";
import iconcard7 from "../../../assets/SkillCollaboratory/Section9icon7.png";
import iconcard8 from "../../../assets/SkillCollaboratory/Section9icon8.png";
import iconcard9 from "../../../assets/SkillCollaboratory/Section9icon9.png";


// Placeholder dataset for the cards
const cardData = [
  {
    id: 1,
    image1: iconcard1,
    image2: "image2.jpg",
    description: "Total Skill Development Programs: In a comprehensive MANAGEMYSKILL learning system, total skill development will be fully aligned with graduate education and training, workforce and supportive service activities many of which will be provided through One- Stop skill development platform."
  },
  {
    id: 2,
    image1: iconcard2,
    image2: "image2.jpg",
    description: "The overall objective of such a system: seamless career pathways will be developed and offered that make it far easier for graduates and under-graduates—especially those with limited basic skills to advance through progressive levels of the educational system as quickly as possible. Students will gain education and workforce skills of demonstrated value at each level "
  },
  {
    id: 3,
    image1: iconcard3,
    image2: "image2.jpg",
    description: "With the combination of academic success toolkit, career success toolkit and employability success toolkit  will develop the proficiency of  each and every student to be effective and efficient. The entire program centered towards the expectation from the viewpoint of industry on skills building work environment for 21st century skills frameworks and global talent "
  },
  {
    id: 4,
    image1: iconcard4,
    image2: "image2.jpg",
    description: "Skill development program model articulated with sequences of rigorous academic, career and employability skill development courses, which commences with the 1st semester students and leading to an industry-recognized certificate end of 8th semester."
  },
  {
    id: 5,
    image1: iconcard5,
    image2: "image2.jpg",
    description: "Students will be exposed to the blended learning environment and provided with personal learning platform that will connect them to community, social and project learning platforms unlocking their potential with knowledge and skill, building blocks with integrated work experiences, action learning  and student support system. The programs will enable students to combine skill development and learning for the better  jobs and higher levels of education. "
  },
  {
    id: 6,
    image1: iconcard6,
    image2: "image2.jpg",
    description: "The entire program is devised and designed from an intensive study and research of the current life style of students, their thinking pattern, expectations from the perspective of skill building as per the expectation of the work environment.  The program focuses on opportunities for meaningful employment, comprehensive guidance and "
  },
  {
    id: 7,
    image1: iconcard7,
    image2: "image2.jpg",
    description: "Counseling and Mentoring Services will assists student in resolving personal difficulties and in acquiring those skills, attitudes, and resources necessary to both succeed in the college environment and pursue productive and satisfying lives. Counseling Services strives to contribute to the overall educational mission of the Institution by facilitating the psychological, academic, emotional, career, social, and vocational development of students and by serving as mental health, academic, cognitive and enabling, career consultants to the entire campus community. "
  },
  {
    id: 8,
    image1: iconcard8,
    image2: "image2.jpg",
    description: "All eligible students after initial assessment will be allowed a maximum of 30sessions of  instructor led delivery, 18 sessions of online and offline project based and action learning delivery and 12 sessions of face-to-face counseling  per semester total 120 sessions per year or as choosen by the students from various available programs offered. "
  },
  {
    id: 9,
    image1: iconcard9,
    image2: "image2.jpg",
    description: "The structure includes various learning resources. Learners will be encouraged to engage in self-study through homework assignments, working to a blended model. Part of this blend was the move on learner route, an e-learning package designed to help learners prepare for the tests. All Materials are to support blended learning, progress checks, skills critical for progression, interactive learning activities and workplace contextualized resources."
  },
];

const Section9 = () => {
  const [expandedCardId, setExpandedCardId] = useState(null);

  const toggleExpand = (cardId) => {
    setExpandedCardId(cardId === expandedCardId ? null : cardId);
  };

  return (
    <section className="py-16 ">
      <div className=" ">
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 text-center px-4 md:px-12 lg:px-16 xl:px-24">The HPDSx Engine Is The Secret To Developing the Professionals of the Future</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 px-4 md:px-8 lg:px-16 xl:px-20">
          {cardData.map(card => (
            <div key={card.id} className=" p-4 rounded-2xl" style={{ backgroundColor: 'rgb(242, 242, 251)' }}>
              <img src={card.image1} alt="Image 1" className="mb-2" />
              <img src={card.image2} alt="Image 2" className="mb-2" />
              <div className='flex flex-row mb-2'>
                  <img src={favicon} className='w-2 h-4 md:w-4 md:h-6'/>
                  <img src={favicon} className='w-2 h-4 md:w-4 md:h-6'/>
              </div>
              <p className={` ${expandedCardId === card.id ? 'h-auto' : 'h-32 overflow-hidden '}`} style={{textOverflow: 'ellipsis' }}>{card.description}</p>
              {expandedCardId !== card.id && <button className="text-blue-500 mt-4" onClick={() => toggleExpand(card.id)}>Read All</button>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section9;
