import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintbrush } from "@fortawesome/free-solid-svg-icons";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import Section4Image from "../../../assets/HPDSxMenu/Section4image1.png";
import DatasetSec13 from "./DatasetSec13";

const Section13 = () => {
  const backgroundColor = "#0086E8";
  const [selectedButton, setSelectedButton] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView();
  const controls = useAnimation();

  // Simulating image loading delay
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 1000); // Adjust the delay time as needed
    return () => clearTimeout(timeout);
  }, []);

  // Animation control based on visibility
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  // Handle button click with animation
  const handleButtonClick = async (button) => {
    await controls.start({ opacity: 0, x: 0 });
    setSelectedButton(button);
    await controls.start({ opacity: 1, x: 0 });
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -50 },
      }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      className="w-full h-full"
    >
      <div className="w-full" style={{ background: backgroundColor }}>
        <div className="flex flex-col text-white py-12">
          <h1
            className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-loose mb-4 py-8 md:py-0 lg:py-0 mx-4 md:mx-12 lg:mx-20 xl:mx-28 md:pt-16 lg:pt-28 font-semibold"
            style={{ lineHeight: "140%" }}
          >
            To generate{" "}
            <span className="text-yellow-400">YOUR LIFE JOURNEY</span> for any
            purpose, freeing your creativity and saving you time.
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5  gap-3 md:gap-1 lg:gap-6 xl:gap-10  md:py-4  lg:py-0 xl:py-4 mx-4 md:mx-12 lg:mx-20 xl:mx-24">
            {DatasetSec13.map((item) => (
              <motion.button
                key={item.id}
                className={`text-center  hover:bg-yellow-400  hover:text-white md:text-sm lg:text-md font-semibold py-3 md:px-2 xl:px-6 rounded-xl whitespace-nowrap border ${
                  selectedButton === item.id
                    ? "border-yellow-400 bg-yellow-400 text-white hover:border-transparent"
                    : "border-yellow-400 text-yellow-400 hover:border-transparent"
                } transition duration-300 ease-in-out`}
                onClick={() => handleButtonClick(item.id)}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                initial={{ opacity: 0, y: 20 }}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
              >
                {item.heading}
              </motion.button>
            ))}
          </div>
          <div className="grid grid-cols-12 gap-4">
            {/* Left Section */}
            <motion.div
              className="col-span-12 md:col-span-6 text-left  mx-4 md:mx-8 lg:mx-20 xl:mx-28 my-8 md:my-8 lg:my-12 xl:my-20"
              initial={{ opacity: 0, x: -50 }}
              animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
            >
              <div className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-4 md:mb-8">
                <span className="mx-4">
                  <FontAwesomeIcon
                    icon={faPaintbrush}
                    style={{ color: "#f7f9fd" }}
                  />
                </span>
                {DatasetSec13[selectedButton - 1].heading}
              </div>
              <div
                className="text-lg md:text-lg lg:text-xl xl:text-2xl font-medium text-justify"
                style={{ lineHeight: "140%" }}
              >
                {DatasetSec13[selectedButton - 1].description}
              </div>
            </motion.div>
            {/* Right Section */}
            <motion.div
              className="col-span-12 md:col-span-6 flex justify-center items-start pt-20 md:mr-12"
              initial={{ opacity: 0, x: 50 }}
              animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
            >
              <img
                src={DatasetSec13[selectedButton - 1].image}
                alt="Right Section Image"
                className="md:w-full xl:w-4/5 h-80 object-cover rounded-lg"
              />
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Section13;
