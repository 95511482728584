import React from "react";
import backgroundImage from "../../../assets/TalentCollaboratory/Section6imagebg.png"; // Import the background image
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import quote from "./Vector.png";

import data from "./DataFor6Cards";

const Section6 = () => {
  return (
    <section
      className="relative bg-cover bg-center py-16 px-4 md:px-8 lg:px-16 xl:px-24"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className=" ">
        <h2
          className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold mb-4 text-white text-center py-4"
          style={{ lineHeight: "140%" }}
        >
          The HPDSx Engine Is The Secret To{" "}
          <span className="text-yellow-400">
            Re-Engineering Workforce exp, Business exp, customer exp, technology
            exp{" "}
          </span>{" "}
          and <span className="text-yellow-400">knowledge exp</span>{" "}
          transformation
        </h2>
      </div>

      <div className="w-full grid grid-cols-12">
        {data?.map((e, index) => {
          return (
            <div
              key={index}
              className="col-span-12 md:col-span-6 w-full h-full p-6"
            >
              <div className="w-full h-full grid grid-cols-12">
                <div className="col-span-12 md:col-span-6">
                  <img className="w-full h-full object-cover" src={e.img} />
                </div>
                <div className="col-span-12 md:col-span-6">
                  <div
                    className={`w-full h-full  text-white p-2`}
                    style={{ backgroundColor: `${e.color}` }}
                  >
                    <div className="p-2">
                      <img className="w-16 h-10 object-cover" src={quote} />
                    </div>
                    <div className="p-2 text-2xl font-medium">{e.desc}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Section6;
