
import Section4Image from '../../../assets/HPDSxMenu/Section4image1.png';
import p1 from "./p1.png"
import p2 from "./p2.png"
import p3 from "./p3.png"
import p4 from "./p4.png"
import p5 from "./p5.png"



const dataset = [
    {
      id: 1,
      heading: "HPDSX Awakening",
      description: <>Realizing the value, kinds of mental habits needed to lead <span className='text-yellow-400'>productive, attentive, learning goals</span>, to increase the understanding and <span className='text-yellow-400'>master of skills</span> and tasks. Connect with peers, sharpen your skills and learn about the <span className='text-yellow-400'>thinking abilities</span> directly from the experts.
      <div className="md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-yellow-400 py-8" style={{ lineHeight: '140%' }}>
          We make you better
        </div>
        <div className="md:text-xl lg:text-2xl xl:text-3xl text-white py-2" style={{ lineHeight: '140%' }}>
          So you can do the really awesome stuff.
        </div>
      </>,
      image: p1
    },
    {
      id: 2,
      heading: "HPDSX Fueling",
      description: <>
      Deepening thinking about what is important for the future,   when confronted with problems to solve, decisions to make, creative ideas to generate and ambiguities to clarify​​
      <div className="md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-yellow-400 py-8" style={{ lineHeight: '140%' }}>
      Everything you need
        </div>
        <div className="md:text-xl lg:text-2xl xl:text-3xl text-white py-2" style={{ lineHeight: '140%' }}>
        Simple, visual, intuitive, accessible  yet powerful.
        </div>
      </>,
      image:p2
    },
    {
      id: 3,
      heading: "HPDSX Igniting",
      description: <>Progress as they mature, become more skillful, develop greater inclination towards and value for the habits of mind. 
      <div className="md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-yellow-400 py-8" style={{ lineHeight: '140%' }}>
      Help!, I don’t know
        </div>
        <div className="md:text-xl lg:text-2xl xl:text-3xl text-white py-2" style={{ lineHeight: '140%' }}>
        What to do with the rest of my life. ​
Where should I start
        </div>
      </>,
      image:p3
    },
    {
      id: 4,
      heading: "HPDSX Lifting up",
      description: <>​​​​How AI can help you better engage  and provide a map that is useful in planning for the continual modification of learning experiences so they become more complex, sophisticated, and appropriate for development over time 
      <div className="md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-yellow-400 py-8" style={{ lineHeight: '140%' }}>
      Things you don’t know you’d want
        </div>
        <div className="md:text-xl lg:text-2xl xl:text-3xl text-white py-2" style={{ lineHeight: '140%' }}>
        Insights that accelerate your GROWTH MINDSETS
        </div>
      </>,
      image: p4
    },
    {
      id: 5,
      heading: "HPDSX Orbiting",
      description: <>​​The characteristics that will predict success, if they go on to college, if they take a job, if they become active in their community, if they marry and raise a family
      <div className="md:text-2xl lg:text-3xl xl:text-4xl font-semibold text-yellow-400 py-8" style={{ lineHeight: '140%' }}>
      Environment you Wanted
        </div>
        <div className="md:text-xl lg:text-2xl xl:text-3xl text-white py-2" style={{ lineHeight: '140%' }}>
        Be with the right set of people best version 
        </div>
      </>,
      image: p5
    },
  ];
  
  export default dataset;
  