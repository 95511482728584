import React from "react";
import img_1 from "../../../assets/Socities/img_1.png";

const Section1 = () => {
  const data = {
    imageUrl: img_1,
  };
  return (
    <div
      className={`w-full px-6 py-10 xl:px-28 xl:py-24 lg:px-20 md:px-12 lg:py-16 md:py-12 grid grid-cols-12 gap-2 md:gap-12 xl:gap-24`}
    >
      <div className="col-span-12 md:col-span-6">
        <img
          className="w-full h-full md:w-96 md:h-84 xl:w-full xl:h-full"
          src={data.imageUrl}
          alt={data.title}
        />
      </div>
      <div className="col-span-12 md:col-span-6 pt-6 md:pt-16 lg:pt-24 xl:pt-44">
        <div className="flex-col justify-start items-start gap-4 ">
          <div className="text-stone-900 xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-semibold cursor-pointer ">
            <div className="text-blue-500">
              Facilitate Learning and Cultural Experiences
            </div>{" "}
            <div className="text-yellow-400">
              via Virtual and In-person Platforms
            </div>
          </div>
          <div className="text-stone-900 xl:text-2xl lg:text-lg md:text-md text-md cursor-pointer mt-2">
            Choose from a wide range of offering Workshops, Seminars, local
            activities and classes.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
