import React from "react";
import imgURL from "../../../assets/Collaboratory/sec7_img1.jpg";
import leftArrow from "../../../assets/Collaboratory/sec8_leftArrow.png";
import rightArrow from "../../../assets/Collaboratory/sec8_rightArrow.png";
import LeftImageSec8 from "./LeftImageSec8";
import RightImageSec8 from "./RightImageSec8";

import a1 from "./newImages/a1.png"
import a2 from "./newImages/a2.png"
import a3 from "./newImages/a3.png"
import a4 from "./newImages/a4.png"
import a5 from "./newImages/a5.png"
import a6 from "./newImages/a6.png"
import a7 from "./newImages/a7.png"
import a8 from "./newImages/a8.png"
import a9 from "./newImages/a9.png"


const data1 = {
  item2: (
    <>
      Many systems <span className="text-yellow-400">One user experience</span>
    </>
  ),
  item3: (
    <>
      You’ve invested in tons of tools and technology for students — but do they
      all work together?
      <br /> The truth is, there’s a massive void at the center of the higher ed
      digital ecosystem because <br /> there’s no centralized user experience
      unifying everything. Let’s fill that void.
    </>
  ),
};

const data2 = [
  {
    title: (
      <span className="text-blue-500">Outstanding Students Experience</span>
    ),
    desc: (
      <>
        To create the opportunities for our students to have an Exceptional And
        Distinctive Experience which prepares them for life beyond their studies
        and which is the beginning of a positive lifelong relationship with the
        Institution
      </>
    ),
    img: a1,
  },
  {
    title: (
      <span className="text-green-500">
        Development of Personal, Professional, career and Enterprise skills{" "}
      </span>
    ),
    desc: (
      <>
        To Develop The Knowledge, Skills And Self-Confidence that they need to
        make an active contribution to a rapidly changing world. It will
        encourage independent thought, a habit of critique and a commitment to
        learning throughout their lives.
      </>
    ),
    img: a2,
  },
  {
    title: (
      <span className="text-yellow-500">
        Generating and applying new Models and Mindsets{" "}
      </span>
    ),
    desc: (
      <>
        To identify environments and situations that would potentially
        compromise one’s physical health; however, the core of this area of
        development is the ability to identify and access those situations that
        enhance one’s physical and mental health.
      </>
    ),
    img: a3,
  },
  {
    title: (
      <span className="text-red-500">
        Creative approaches for 21st Century Skill Development
      </span>
    ),
    desc: (
      <>
        To develop a clear vision and setting goals for the development of a
        highly skilled individuals to enable learning journeys, career journey
        and prepare and participate in communities and workplaces, automatically
        personalize development for one’s skills, roles, and goals, with more
        powerful, relevant, and connected by mentors and coaches where they are
        and empowering them to explore and engage with their passions, and to
        weave learning into Data-driven skills intelligence in collective
        intelligence systems.
      </>
    ),
    img: a4,
  },
  {
    title: (
      <span className="text-blue-500">
        Access to Learning, Facilitation and Content
      </span>
    ),
    desc: (
      <>
        Re-envision teaching and learning to produce quality outcomes.
        Successful courses taught and ensure the development of an engaging
        student learning experience that integrates content, pedagogy, and
        technology, while maintaining rigorous academic integrity of the course.
      </>
    ),
    img: a5,
  },
  {
    title: (
      <span className="text-green-500">
        Competence Development, Intervention & Consultations
      </span>
    ),
    desc: (
      <>
        To prepare students to shift jobs and careers more frequently, to be
        flexible and adaptable in acquiring job skills, and to integrate and
        focus a changing mix of job-derived and education-based knowledge on
        business processes and problems.
      </>
    ),
    img: a6,
  },
  {
    title: <span className="text-yellow-500">Focusing on New-Research</span>,
    desc: (
      <>
        To make a positive intellectual, educational, economic, scientific and
        cultural contribution to society and to promote understanding of, and
        support for, the University and its work.
      </>
    ),
    img: a7,
  },
  {
    title: <span className="text-red-500">Global Impact</span>,
    desc: (
      <>
        To incorporate internationalization in all areas of the institution
        activity, including by developing a globally relevant curriculum,
        promoting global citizenship and collaborating on teaching, research and
        knowledge exchange with international partners.
      </>
    ),
    img: a8,
  },
  {
    title: <span className="text-blue-500">Global Partnership</span>,
    desc: (
      <>
        To develop and derive benefits from, local and international strategic
        partnerships and collaborations with other leading universities, the
        commercial sector, and governmental and civil society organisations,
        which are built on excellence and driven by shared academic interest in
        skill development
      </>
    ),
    img: a9,
  },
];

const Section8 = () => {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };

  return (
    <div className="w-full h-full  bg-slate-100 xl:px-24 lg:px-16 px-4 xl:py-24 lg:py-16  md:py-12 py-8">
      <div className=" text-center flex flex-col xl:gap-6 lg:gap-4 md:gap-2 gap-2">
        <div className="flex flex-col md:flex-row justify-between  items-center">
          <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">
            TRANSFORM LEARNING PROCESS
          </li>
          <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">
            ENGAGE STUDENT INTEREST{" "}
          </li>
          <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">
            ACCESS GLOBAL JOBS
          </li>
        </div>
        <div className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-blue-500 font-semibold my-2">
          {data1.item2}
        </div>
        <div
          className="xl:text-3xl lg:text-3xl md:text-base text-sm xl:mb-8 lg:mb-6 md:mb-4 mb-2 "
          style={{ lineHeight: "140%" }}
        >
          {data1.item3}
        </div>
      </div>
      <div>
        {data2.map((item, index) =>
          index % 2 ? (
            <div>
              <RightImageSec8 data={item} />
              {data2.length - 1 !== index && (
                <div>
                  <img
                    src={leftArrow}
                    className="w-1/6 xl:-my-24 lg:-my-16 md:-my-8 -my-4 xl:-ml-12 lg:-ml-10 md:-ml-8 -ml-6"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-end">
              <LeftImageSec8 data={item} />
              {data2.length - 1 !== index && (
                <div>
                  <img
                    src={rightArrow}
                    className="xl:w-4/6 lg:w-3/5 md:w-4/6 w-3/6 xl:-my-24 lg:-my-16 md:-my-8 -my-4 xl:mr-12 lg:mr-6 md:-mr-8 -mr-2"
                  />
                </div>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Section8;
