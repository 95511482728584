import studentWellness1 from "./Images/studentwellness1.png";
import studentWellness2 from "./Images/studentwellness2.png";
import studentWellness3 from "./Images/studentwellness3.png";
import studentWellness4 from "./Images/studentwellness4.png";
import studentWellness5 from "./Images/studentwellness5.png";

const DatasetSec4 = {
  buttons: [
    {
      id: 1,
      heading1: "Title",
      heading: "Foster engaging curriculum and learning experiences skills",
      description:
        "Student mental health and wellbeing is supported when curricula and learning experiences afford choice and flexibility in approach, create social connections, build competence and foster intrinsic motivation.",
      image: studentWellness1,
    },
    {
      id: 2,
      heading1: "Title",
      heading:
        "Cultivate supportive social, physical and digital environments ",
      description:
        "The social, physical and virtual environments for student life and academic engagement play a significant part in promoting or undermining student mental health and wellbeing. ",
      image: studentWellness2,
    },
    {
      id: 3,
      heading1: "Title",
      heading: "Strengthen community awareness and actions ",
      description:
        "Mental health and wellbeing is supported when individuals within a community have the information and abilities needed to identify options and make decisions and choices conducive to good health.",
      image: studentWellness3,
    },
    {
      id: 4,
      heading1: "Title",
      heading: "Ensure access to effective services ",
      description:
        "Ensuring that students who may be experiencing mental health difficulties have access to appropriate services and academic adjustments requires not only that those services are in place but also that barriers to access (awareness and perception) are addressed.",
      image: studentWellness4,
    },
    {
      id: 5,
      heading1: "Title",
      heading:
        "Develop students’ mental health knowledge and self-regulatory skills",
      description:
        "All students should have opportunities to develop mental health knowledge and skills within their academic course of study. Students need timely, personalised opportunities to develop relevant skills, such as time and task management, reflection and self-understanding, self-regulation, goal setting and help-seeking.",
      image: studentWellness5,
    },
    // {
    //   id: 6,
    //   heading1:"Title",
    //   heading: "Elevating ",
    //   description: "Transforming the “Career Management” framework and taking the EVM (Economic Value Addition) to new levels​use this information to implement programs that will help children succeed as they progress through the education and professional years.",
    //   image: Section4Image3
    // },
  ],
};

export default DatasetSec4;
