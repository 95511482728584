import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import imgURL from "../../../assets/ThinkSkillIndia/img_3.jpg";

const Section2 = () => {
  const data1 = (
    <>
      A major crisis is looming over organizations and economies throughout the world.
      <br />
      <br />
      By 2030, demand for skilled workers will outstrip supply, resulting in a global talent shortage of more than 85.2 million people.
    </>
  );
  const data2 = {
    item1: imgURL,
    item2: (
      <>
        THINKSKILLINDIA prepares youth for the Economic success of INDIA effectively utilizing intangible assets, such as
        <ul className="list-disc ml-0 lg:ml-4 pl-4 text-yellow-400 font-semibold">
          <li>Knowledge</li>
          <li>Skills</li>
          <li>Innovative Potential</li>
        </ul>
        as the key resource for competitive advantage
      </>
    ),
  };

  const { ref, inView } = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <motion.div
      className="w-full h-full font-medium xl:px-24 lg:px-16 md:px-8 bg-blue-500 text-white xl:py-20 lg:py-16 md:py-12 py-8 px-4"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }}
    >
      <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row xl:gap-8 lg:gap-6 md:gap-4 gap-4">
        <motion.img
          src={data2.item1}
          alt="ThinkSkillIndia"
          className="rounded-3xl w-full md:w-2/5"
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: -60 },
          }}
          transition={{ duration: 0.7, ease: "easeInOut", delay: 0.3 }}
        />

        <motion.div
          className="flex flex-col"
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          transition={{ duration: 0.7, ease: "easeInOut", delay: 0.5 }}
        >
          <motion.div
            className="xl:text-3xl lg:text-2xl md:text-lg text-md"
            style={{ lineHeight: "140%" }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: -60 },
            }}
            transition={{ duration: 0.7, ease: "easeInOut", delay: 0.7 }}
          >
            {data1}
          </motion.div>
          <motion.div
            className="border-2 w-11/12 xl:my-8 lg:my-4 md:my-2 my-4"
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
            transition={{ duration: 0.7, ease: "easeInOut", delay: 0.7 }}
          ></motion.div>
          <motion.div
            className="w-full xl:text-3xl lg:text-2xl md:text-lg text-md h-full col-span-8"
            style={{ lineHeight: "140%" }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 60 },
            }}
            transition={{ duration: 0.7, ease: "easeInOut", delay: 0.9 }}
          >
            {data2.item2}
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Section2;
