import React from "react";
import LandingCover from "../../components/SME/LandingCover";
import SubjectMatterExperts from "../../components/SME/Sme";

export default function Sme() {
  return (
    <div className="flex flex-col bg-neutral-100">
      <LandingCover />
      <SubjectMatterExperts />
    </div>
  );
}
