import Section2image1 from '../../../assets/HPDSxMenu/Section2image1.png';
import Section2image2 from '../../../assets/HPDSxMenu/Section2image2.png';
import Section2image3 from '../../../assets/HPDSxMenu/Section2image3.png';



const DatasetSec2 = [
    {
      id: 1,
      title: <>CENTRALISED <span style={{color : '#0086E8'}}>Announcement Board</span></>,
      description: <> the Announcement Board serves as a central hub for all important updates, events, and opportunities. Stay informed and connected with the latest announcements, news, and initiatives shaping the council</>,
      image: Section2image1
    },
    {
      id: 2,
      title: <>UPCOMING  
      <span style={{color : '#0086E8'}}> CONFERENCES</span></>,
      description: <> the Announcement Board serves as a central hub for all important updates, events, and opportunities. Stay informed and connected with the latest announcements, news, and initiatives shaping the council</>,
      image: Section2image2
    },
    {
      id: 3,
      title: <>Hosting <span style={{color : '#0086E8'}}>Open Challenges</span></>,
      description: " the Announcement Board serves as a central hub for all important updates, events, and opportunities. Stay informed and connected with the latest announcements, news, and initiatives shaping the council",
      image: Section2image3
    },
    {
        id: 4,
        title: <>Post and <span style={{color : '#0086E8'}}>Reviews</span></>,
        description: " the Announcement Board serves as a central hub for all important updates, events, and opportunities. Stay informed and connected with the latest announcements, news, and initiatives shaping the council",
        image: Section2image3
      }
  ];
  
  export default DatasetSec2;
  