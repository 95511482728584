import motivateimg from "../../../assets/Speaker/motivatesec.png"


const motivateData = [
    {
        id: 1,
        titleheading: "Motivate and create a long lasting influence",
        description:
            "Aim to touch hearts and minds by sharing invaluable lessons, powerful strategies, or perspectives that can create a lasting impression",
        imageUrl: motivateimg,
        bgColor: "white",
        button: false,
        buttonName: "",
    },

];
export default motivateData;