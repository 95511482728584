import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Section1image from '../../../assets/HPDSxMenu/Section1image.png';

const Section2 = () => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      console.log('Section 2 is in view!');
      // You can add additional animations or logic here
    }
  }, [inView]);

  return (
    <motion.div
      className="w-full md:h-[110vh] lg:h-[126vh] xl:h-[150vh] w-full flex justify-center items-center font-semibold"
      ref={ref}
      initial={{ opacity: 0, y: 80 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
      transition={{ ease: 'easeInOut', duration: 0.6 }}
    >
      <div className='max-w-screen'>
        <div className="leading-relaxed text-blue-500 text-center px-4 md:px-4 lg:px-8 xl:px-24">
          <div className="text-xl md:text-3xl lg:text-4xl xl:text-5xl mt-12 md:mt-20 lg:mt-24 xl:mt-32 px-8 md:px-0 lg:px-4 xl:px-8 font-semibold leading-loose" style={{ lineHeight: '140%' }}>
            Platform’s values, providing organisations and individuals with guidance on how to approach changes in the way we work and learn.
          </div>
          <motion.img
            src={Section1image}
            alt=""
            className=" pt-8 md:pt-12 lg:pt-16 xl:mt-12 xl:pt-8 mx-auto"
            initial={{ opacity: 0, scale: 0.9, rotate: -2 }}
            animate={inView ? { opacity: 1, scale: 1, rotate: 0 } : { opacity: 0, scale: 0.9, rotate: -2 }}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Section2;
