import React from 'react';
import backgroundImage from '../../../assets/Internship/Section1imagebg.png'; // Import the background image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import img1 from '../../../assets/Internship/Section1image1.png'
import icon1 from "../../../assets/Internship/icon1.png"
import icon2 from "../../../assets/Internship/icon2.png"
import icon3 from "../../../assets/Internship/icon3.png"

const Section1 = () => {
    return (
        <section className="w-full relative bg-cover bg-center py-24" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className=" max-w-screen">
                <h1 className="md:text-2xl lg:text-2xl xl:text-3xl font-bold text-center mb-4 md:mb-8 text-white">INTERNSHIP INDUCTION</h1>
                <p className="md:text-3xl lg:text-3xl xl:text-4xl text-center mb-8 px-4 md:px-12 lg:px-20 xl:px-32 text-white"style={{ lineHeight: '140%' }}>Spending your <span className='text-yellow-500'>Internship at HPDS</span> is a fantastic way to get up close to the world of business, find out what excites and inspires you, and where it could take you. As well as vital work experience, you’ll get a good idea of whether we’re the firm for you.</p>
                <div className="flex justify-center mx-4">
                    <img src={img1} alt="Image Description" className="w-full lg:w-5/6 py-4" />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-3 xl:gap-8 px-4  lg:px-20 xl:px-28 pt-12">
                    {/* Card 1 */}
                    <div className="bg-white rounded-lg p-2 md:p-3 lg:p-4 xl:p-6">
                        <img src={icon1} alt="Card 1 Image" className="w-16 h-16 mb-4" />
                        <h3 className="text-lg lg:text-xl font-semibold mb-2 text-green-500"style={{ lineHeight: '140%' }}>Induction Insight</h3>
                        <p className="text-md lg:text-lg text-gray-500 text-justify"style={{ lineHeight: '140%' }}>Interns receive induction from us both planned and unplanned from the organization in which new interns learn how to function efficiently within a new organizational culture by obtaining the information, values and behavioural skills associated with his or her new role in the organization.</p>
                        <div className="mt-auto flex items-center justify-end">
                            <button className="flex items-center justify-center  w-10 h-10 hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                                <FontAwesomeIcon icon={faArrowRight} className="text-xl" />
                            </button>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="bg-white rounded-lg p-2 md:p-3 lg:p-4 xl:p-6">
                        <img src={icon2} alt="Card 2 Image" className="w-16 h-16 mb-4" />
                        <h3 className="text-lg lg:text-xl font-semibold mb-2 text-green-500"style={{ lineHeight: '140%' }}>Expectation Brief</h3>
                        <p className="text-md lg:text-lg text-gray-500 text-justify"style={{ lineHeight: '140%' }}>Define orientation as a process of informing new interns about what is expected of them in the job and helping them to cope with the stresses of transition.</p>
                        <div className="mt-auto flex items-center justify-end">
                            <button className="flex items-center justify-center  w-10 h-10 hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                                <FontAwesomeIcon icon={faArrowRight} className="text-xl" />
                            </button>
                        </div>
                    </div>
                    {/* Card 3 */}
                    <div className="bg-white rounded-lg p-2 md:p-3 lg:p-4 xl:p-6">
                        <img src={icon3} alt="Card 3 Image" className="w-16 h-16 mb-4" />
                        <h3 className="text-lg lg:text-xl font-semibold mb-2 text-green-500"style={{ lineHeight: '140%' }}>Mentorship Dynamics</h3>
                        <p className="text-md lg:text-lg text-gray-500 text-justify"style={{ lineHeight: '140%' }}>Mentoring as a relationship where a senior, experienced individual provides support, advice and friendship to a younger, less experienced member. It can be both formal and informal and can take different forms.</p>
                        <div className="mt-auto flex items-center justify-end">
                            <button className="flex items-center justify-center  w-10 h-10 hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
                                <FontAwesomeIcon icon={faArrowRight} className="text-xl" />
                            </button>
                        </div>
                    </div>

                </div>


            </div>
        </section>
    );
};

export default Section1;
