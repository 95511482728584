import Networkingimg from "../../../assets/Conference/Section2_Img1.jpg";
import GlobalPerspectiveImg from "../../../assets/Conference/Section2_Img2.jpg";
import ProductServicesImg from "../../../assets/Conference/Section2_Img3.jpg";

const conferenceData = [
  {
    id: 1,
    title: "Networking",
    description:
      "Meeting industry experts, potential collaborators, and like-minded professionals is invaluable for expanding professional networks, fostering partnerships, and discovering new opportunities",
    shadow: false,
    topmargin: false,
    imageUrl: Networkingimg,
    alt: "Networking",
  },
  {
    id: 2,
    title: "Global Perspective",
    description:
      "International conferences bring together participants from around the world, providing a diverse and global perspective on issues, innovations, and best practices in your field.",
    shadow: true,
    topmargin: true,
    imageUrl: GlobalPerspectiveImg,
    alt: "Global Perspective",
  },
  {
    id: 3,
    title: "Showcasing Products/Services",
    description:
      "For businesses, conferences can be a platform to exhibit products, launch services, and gain exposure to potential customers and investors.",
    shadow: false,
    topmargin: false,
    imageUrl: ProductServicesImg,
    alt: "Showcasing Products/Services",
  },
];

export default conferenceData;
