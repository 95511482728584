import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Section15image from '../../../assets/HPDSxMenu/Section15image.png'; // Import the image
import bigWheel from "./bigWheel.png"

const Section15 = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Trigger animation once
    threshold: 0.25, // Trigger animation when 30% of the component is in view
  });

  return (
    <div ref={ref} className="grid grid-cols-12 md:pb-12 py-8 md:py-8 lg:py-8 xl:py-20 md:mx-8 lg:mx-16 xl:mx-20">
      {/* Left Section */}
      <motion.div
        className="col-span-12 md:col-span-8"
        initial={{ opacity: 0, x: -100 }} // Initial position (left side)
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -100 }} // Move to center and fade in when in view
        transition={{ duration: 1, ease: 'easeInOut', delay: 0.5 }} // Transition with delay
      >
        <img src={bigWheel} alt="Left Section Image" className="w-full h-auto" />
      </motion.div>

      {/* Right Section */}
      <motion.div
        className="col-span-12 md:col-span-4 flex justify-center items-center md:mr-0 lg:mr-12"
        initial={{ opacity: 0, x: 100 }} // Initial position (right side)
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 100 }} // Move to center and fade in when in view
        transition={{ duration: 1, ease: 'easeInOut', delay: 0.5 }} // Transition with delay
      >
        <p className="text-center text-4xl  italic font-bold" style={{ color: 'rgb(0, 134, 232)' }}>Write Your Future Story</p>
      </motion.div>
    </div>
  );
};

export default Section15;

