import img1 from "../../../assets/TalentCollaboratory/Hpdsxhub/hdpsxHub1.png";
import img2 from "../../../assets/TalentCollaboratory/Hpdsxhub/hdpsxHub2.png";
import img3 from "../../../assets/TalentCollaboratory/Hpdsxhub/hdpsxHub3.png";
import img4 from "../../../assets/TalentCollaboratory/Hpdsxhub/hdpsxHub4.png";
import img5 from "../../../assets/TalentCollaboratory/Hpdsxhub/hdpsxHub5.png";
import img6 from "../../../assets/TalentCollaboratory/Hpdsxhub/hdpsxHub6.png";

const DatasetSec4 = {
  buttons: [
    {
      id: 1,
      heading: "Skill Lifecycle",
      description:
        "Digitize the entire skills management lifecycle, eliminating spreadsheets and the cumbersome efforts to keep them up-to-date. Instantly visualize the skills matrix for your team, see what skill gaps exist, and take action to fill them.",
      image: img6,
    },
    {
      id: 2,
      heading: "Skill Readiness",
      description:
        "Track your workforce skills and quickly assess team and individual readiness. Visualize team training completion by procedure, by individual, or by team with HPDSxs digital skills matrix.",
      image: img2,
    },
    {
      id: 3,
      heading: "Inuitive Learning",
      description:
        "Expert teaching and use this understanding to shape initial teacher education programs, coaching and mentoring arrangements and ongoing professional development. HPDSx is a multi-annual, multi-thematic initiative that aims to encourage the development, implementation and dissemination of innovative teaching and learning practices ",
      image: img3,
    },
    {
      id: 4,
      heading: "Holistic Facilitation",
      description:
        "Supporting transversal themes, such as digital and online learning, the role of formative assessment and feedback in contemporary facilitation and learning, the development of key competences and skills, identifying new roles and profiles, as well as trends in initial training and the continuing professional development  of employees.",
      image: img4,
    },
    {
      id: 5,
      heading: "Connecting Partners",
      description:
        "Build your interest profile and get matched to research and technologies from institutes that are active, available and currently seeking industry partners.",
      image: img1,
    },
    {
      id: 6,
      heading: "Team Building",
      description:
        "An intelligent matchmaking platform curated and can be managed by in-house experts, that simplifies the initial introduction between teams in various departments and functions",
      image: img5,
    },
  ],
};

export default DatasetSec4;
