import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import img_1 from "../../../assets/ThinkSkillIndia/img_15.jpg";
import img_2 from "../../../assets/ThinkSkillIndia/img_16.jpg";

const data = {
  title: (
    <>
      <span className="text-blue-400">Experience </span> all the benefits of
      THINKSKILLINDIA
      <br /> We’ll help you find the
      <span className="text-blue-400"> best choice </span> for your
      <br />
      <span className="text-blue-400"> needs</span>.
    </>
  ),
  item1: {
    img: img_1,
    desc: (
      <>
        Creating an aligned, 21st-century public education system that prepares
        students, workers, and citizens to triumph in the global skills race is
        the central economic competitiveness issue for the next decade.
      </>
    ),
  },
  item2: {
    img: img_2,
    desc: (
      <>
        A complete engagement platform to measure and improve Economic success
        based on the effective utilization of intangible assets, such as
        knowledge, skills, and innovative potential as the key resource for
        competitive advantage.
      </>
    ),
  },
};

const Section13 = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  return (
    <motion.div
      className="w-full flex flex-col text-justify h-full"
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 0.6, ease: "easeIn", delay: 0.2 }}
    >
      <div className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-bold text-center xl:py-24 lg:py-20 md:py-16 py-8 md:px-0 px-4">
        {data.title}
      </div>

      <div className="flex flex-col xl:text-xl lg:text-lg md:text-sm px-4 mr-6 md:mr-0 md:px-0">
        <div className="flex w-full flex-col md:flex-row xl:h-[60vh] lg:h-[50vh] md:h-[50vh] h-full">
          <div
            className="w-full md:w-[104vw] xl:py-12 lg:py-10 md:py-8 bg-blue-500 relative"
            
          >
            <div className="w-full h-full">
              <img
                src={data.item1.img}
                className="w-full h-full object-cover"
                style={{ position: "relative", left: "50px" }}
              />
            </div>
          </div>
          <motion.div
            className="w-full text-justify xl:px-28 lg:px-24 md:px-16 px-0 flex flex-col justify-center xl:text-2xl lg:text-xl md:text-lg text-md font-semibold md:py-0 py-8"
            initial={{ opacity: 0, y: 80 }}
      animate={{ opacity: inView ? 1 : 0 , y: inView ? 0 : 80 }}
            transition={{ duration: 0.6, ease: "easeOut", delay: 0.5 }}
          >
            {data.item1.desc}
          </motion.div>
        </div>
        <div className="flex w-full flex-col md:flex-row  xl:h-[60vh] lg:h-[50vh] md:h-[50vh] h-full">
          <motion.div
            className="w-full md:w-1/2 text-justify xl:px-28 lg:px-24 md:px-20 px-0 flex flex-col justify-center xl:text-2xl lg:text-xl md:text-md text-md font-semibold md:py-0 py-8"
            initial={{ opacity: 0, y: 80 }}
      animate={{ opacity: inView ? 1 : 0 , y: inView ? 0 : 80 }}
            transition={{ duration: 0.6, ease: "easeOut", delay: 0.5 }}
          >
            {data.item2.desc}
          </motion.div>
          <div
            className="w-full md:w-[57vw] xl:py-12 lg:py-10 md:py-8 bg-yellow-500 relative"
            
      
          >
            <div className="w-full h-full">
              <img
                src={data.item2.img}
                className="w-full h-full object-cover"
                style={{ position: "relative", right: "50px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Section13;
