import React from "react";
import img_1 from "../../../assets/Socities/img_3.jpg";
import cover_bg from "../../../assets/Socities/img_5.png";
const Section3 = () => {
  const data = {
    title: (
      <>
        Attract <span className="text-yellow-400">volunteers to support </span>{" "}
        your <span className="text-yellow-400">campaigns </span> and
        <span className="text-yellow-400"> local activities </span> through the
        vast pool of talented volunteers
        <span className="text-yellow-400"> at HPDS. </span> Tap into our network
        to
        <span className="text-yellow-400"> recruit passionate </span>
        individuals eager
        <span className="text-yellow-400"> to contribute to </span>your cause
        and
        <span className="text-yellow-400"> make a positive </span>impact in your
        community
      </>
    ),
    imageUrl: img_1,
  };
  return (
    <div
      className={`w-full px-4 py-12 xl:px-28 lg:px-20 md:px-12 xl:py-24 lg:py-16 md:py-16 flex flex-col justify-center items-center gap-6 text-white`}
      style={{
        backgroundImage: `url(${cover_bg})`,
        backgroundSize: "contain", // Maintain original image size
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex-col px-4 md:px-28 gap-6">
        <div className="xl:text-5xl text-blue-500 lg:text-3xl md:text-2xl text-sm font-semibold mb-3 md:mb-10 text-center cursor-pointer">
          {data.title}
        </div>
      </div>

      <img
        className="w-full h-full"
        src={data.imageUrl}
        alt={data.title}
      />
    </div>
  );
};

export default Section3;
