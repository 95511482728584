import CardImage1 from '../../../assets/HPDSxMenu/Section5image1.png';
import CardImage2 from '../../../assets/HPDSxMenu/Section5image2.png';
import CardImage3 from '../../../assets/HPDSxMenu/Section5image3.png';
import CardImage4 from '../../../assets/HPDSxMenu/Section5image4.png';
import CardImage5 from '../../../assets/HPDSxMenu/Section5image5.png';
import CardImage6 from '../../../assets/HPDSxMenu/Section5image6.png';


const DatasetSec5 = [
  {
    id: 1,
    description: <>Helping people finding their <span className=' font-semibold' style={{ color: 'rgb(0, 134, 232)' }}>CORE GENIUS</span></>,
    image: CardImage1
  },
  {
    id: 2,
    description: <>Figuring out what you <span className=' font-semibold' style={{ color: 'rgb(0, 134, 232)' }}>LOVE TO DO</span></>,
    image: CardImage2
  },
  {
    id: 3,
    description: <>Creating <span className=' font-semibold' style={{ color: 'rgb(0, 134, 232)' }}>ONE’S IDEAL LIFE</span> in a contest of desire Fulfillment</>,
    image: CardImage3
  },
  {
    id: 4,
    description: <>Organise life bow to <span className=' font-semibold' style={{ color: 'rgb(0, 134, 232)' }}>MAKE A LIVING</span> at it</>,
    image: CardImage4
  },
  {
    id: 5,
    description: <>For greatest possible <span className=' font-semibold' style={{ color: 'rgb(0, 134, 232)' }}>MUTUAL GAIN</span></>,
    image: CardImage5
  },
  {
    id: 6,
    description: <>People of vision <span className=' font-semibold' style={{ color: 'rgb(0, 134, 232)' }}>COMING TOGETHER</span> to share their knowledge and wisdom</>,
    image: CardImage6
  }
];

export default DatasetSec5;
