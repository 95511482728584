import React from 'react';


import DatasetSec2 from './DatasetSec2';

const Section2 = () => {
    return (
        <div className="w-full bg-white grid place-items-center py-12 px-4 md:px-12 lg:px-20 xl:px-28 ">
            <div className="max-w-screen text-blue-500 text-center font-semibold">
                <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl mb-16 md:px-20 lg:px-28 xl:px-80 text-blue-500 py-8  font-bold" style={{ lineHeight: '140%' }}>Make it happen
                    </h1>

                    {DatasetSec2.map((card, index) => (
                  <div key={card.id} className={`grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-0 mt-${index%2 === 1 ? '12' : '12'} md:mt-${index%2 === 1 ? '8' : '16'}`}>
                      {index%2 === 1 ? (
                        <>
                          <div className="order-2 md:order-1 text-left">
                              <img src={card.image} alt="Image" className="mt-4  md:mx-0  w-full lg:w-5/6 " />
                          </div>
                          <div className="order-1 md:order-2 text-left md:ml-4 md:mt-8 lg:ml-12   lg:mt-12 w-full lg:w-5/6  xl:pt-12" >
                              <div className='text-xl md:text-2xl lg:text-3xl xl:text-5xl mb-2 lg:mb-8 font-bold' style={{ lineHeight: '140%' }}>{card.title}</div>
                              <div className='text-lg md:text-lg lg:text-xl xl:text-2xl ' style={{ lineHeight: '140%' }}>{card.description}</div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-left lg:mx-4 xl:mx-12 lg:mt-4 xl:mt-8  w-full  lg:w-5/6 xl:pt-8">
                              <div className='text-xl md:text-2xl lg:text-3xl xl:text-5xl mb-2 lg:mb-8 font-bold' style={{ lineHeight: '140%' }}>{card.title}</div>
                              <div className='text-lg md:text-lg lg:text-xl xl:text-2xl' style={{ lineHeight: '140%' }}>{card.description}</div>
                          </div>
                          <div className="text-right">
                              <img src={card.image} alt="Image" className="mt-4 md:mt-0  lg:ml-12  w-full lg:w-5/6 " />
                          </div>
                        </>
                      )}
                  </div>
                ))}
            </div>
        </div>
    );
};

export default Section2;
