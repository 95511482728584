import React from "react";
import imgURL from "../../../assets/Enterprise/Section6image1.png";
import img2 from "../../../assets/Enterprise/Section6image2.png";
import bgimage from "../../../assets/Council/Section2imagebg.png"

const Section6 = () => {
  const data = {
    item1: (
      <>
        THINKSKILLINDIA Youth Connect programs aim to help young people develop the skills and competencies they need to succeed in their careers and become leaders in their communitie
      </>
    ),
    item2: (
      <>
        These programs focus on providing young people with a broad range of skills development opportunities as well as opportunities to develop their leadership skills through projects, mentorship, and other interactive activities.
      </>
    ),
    item3: (
      <>
        Participating in a youth skills program can be a valuable experience for young people, as it provides them with the chance to learn from experienced professionals, explore different career options, and build their confidence and leadership skills. 
      </>
    ),
    item4: imgURL,
    item5: (
      <>
        These programs can also help young people develop a sense of purpose and direction as they begin to think about their future careers.
      </>
    ),
    item6: (
      <>
       
      </>
    ),
    item7: (
      <>
       
       
      </>
    ),
  };

  const handleInputChange = (e) => {
    console.log("Button pressed");
  };

  return (
    <div
      className="w-full h-full font-bold text-white px-4 lg:px-16 xl:px-24 py-16"
      style={{
        backgroundImage: `url(${bgimage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* <div className="text-3xl text-center">{data1.item1}</div> */}
      {/* <div className="text-4xl text-center">{data1.item2}</div> */}
      <div className=" grid grid-cols-12 mt-16 font-semibold gap-4 text-xs md:text-sm xl:text-base my-10 md:mx-0 "> 
      
        <div className="col-span-12 lg:col-span-3 flex  flex-col gap-12 ">
          <div className=" h-full md:h-1/2 w-full md:w-full rounded-xl  px-4 pb-2 bg-white bg-opacity-10 shadow-lg  ">
            <div className="text-3xl md:text-4xl  lg:text-5xl xl:text-6xl px-3 py-2 text-end text-slate-300">01</div>
            {data.item1}
          </div>

          <div className=" h-full md:h-1/2 w-full md:w-full rounded-xl  px-4 pb-2 bg-white bg-opacity-10 shadow-lg">
            <div className="text-3xl md:text-4xl  lg:text-5xl xl:text-6xl px-3 py-2 text-end text-slate-300">02</div>
            {data.item3}
          </div>
        </div>

        <div className="col-span-12 lg:col-span-6 flex flex-col font-semibold text-4xl xl:text-center ">
          <div className="flex flex-col items-center  md:mr-0">
            
            <img src={img2} alt="underline" className="w-full  " />
          </div>
          
          <div
            className=" w-full  h-48 md:h-80 lg:h-full flex flex-col justify-between rounded-xl "
            style={{
              backgroundImage: `url(${data.item4})`,
              backgroundSize: "cover", 
              backgroundPosition: "top", 
            }}
          >
            <div className="md:text-4xl  lg:text-5xl xl:text-6xl px-3 py-2 text-end text-slate-300 font-semibold">
              {" "}
            </div>
            <div
              className="p-3 text-left xl:text-base rounded-b-xl"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                backdropFilter: "blur(10px)",
              }}
            >
              {data.item6}
            </div>
          </div>
        </div>
        
        <div className="col-span-12 lg:col-span-3 flex flex-col gap-12 mt-8 md:mt-0">
          <div className=" h-full md:h-1/2 w-fullw-1/2 md:w-full rounded-xl  px-4 pb-2 bg-white bg-opacity-10 shadow-lg ">
            <div className="text-3xl md:text-4xl  lg:text-5xl xl:text-6xl px-3 py-2 text-end text-slate-300">03</div>
            {data.item2}
          </div>
          <div className=" h-full md:h-1/2 w-full md:w-full  rounded-xl  px-4 pb-2 bg-white bg-opacity-10 shadow-lg">
            <div className="text-3xl md:text-4xl  lg:text-5xl xl:text-6xl px-3 py-2 text-end text-slate-300">04</div>
            {data.item5}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;