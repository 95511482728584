import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const data1 = (
  <>
    Get Started In Less Than 60 Seconds <br /> • Cancel Anytime
  </>
);
const RightImageSec8 = ({ data }) => {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };
  return (
    <div className="w-full md:w-5/6 mx-auto h-full xl:my-8 lg:my-6 md:my-4 my-2 grid grid-cols-1 md:grid-cols-2 xl:gap-10 lg:gap-8 md:gap-6 gap-4 bg-white xl:p-12 lg:p-8 p-4 rounded-xl">
      <div className="col-span-1 h-full flex flex-col order-2 md:order-1 ">
        <img src={data.img} className="rounded-2xl" />
        <div className="flex flex-col justify-center font-semibold items-center">
          <button
            className="text-white xl:px-6 px-4 py-3 lg:my-6 md:my-4 my-2 xl:text-lg lg:text-base md:text-sm text-xs rounded-full border hover:bg-transparent"
            type="submit"
            onClick={handleInputChange}
            style={{ backgroundColor: "#00B0F0", borderColor: "#00B0F0" }}
          >
            Get Started Now
            <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
          </button>

          <div className="lg:text-sm md:text-xs text-center">{data1}</div>
        </div>
      </div>
      <div className="col-span-1 h-full order-1 md:order-2 ">
        <div className="xl:text-5xl lg:text-4xl md:text-3xl text-xl  font-semibold xl:leading-tight lg:text-tight md:text-tight">
          {data.title}
        </div>

        <div className="xl:text-2xl lg:text-xl md:text-lg text-base xl:py-4 lg:py-2 py-1 font-semibold">
            {data.heading}
        </div>

        <div className="xl:text-2xl lg:text-xl md:text-lg text-base  font-normal">
          {data.desc}
        </div>
      </div>
    </div>
  );
};

export default RightImageSec8;
