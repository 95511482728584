import React from "react";
import cardData from "./secondSectionData";

const SecondSection = () => {
  return (
    <div className="max-w-screen-xl  xl:mx-28 lg:mx-24 md:mx-16 py-6 md:py-12 bg-white relative">
      <div className="font-semibold text-2xl font-Poppins pb-8 ml-6 md:ml-0">
        Your Path of Success
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-1 md:gap-5">
        {cardData.map((card) => (
          <div key={card.id} className="mb-8 hover:shadow-2xl cursor-pointer mr-8">
            <div className="h-72 w-64 md:w-48 lg:48 xl:w-56 bg-white bg-opacity-90 rounded-lg border border-neutral-400 shadow overflow-hidden">
              <div className="p-2 h-1/2">
                <div
                  className={`h-8 px-2 py-2.5 rounded-full justify-center items-center gap-2.5 inline-flex ${card.bgColor}`}
                >
                  <div className="xl:text-sm lg:text-sm md:text-xs font-semibold font-Poppins">
                    {card.title}
                  </div>
                </div>
                <div className="text-md font-semibold text-slate-950 font-Poppins whitespace-nowrap mt-2">
                  {card.heading}
                </div>
                <div className="w-full opacity-60 mb-4 mt-2 text-xs font-normal font-Poppins">
                  {card.description}
                </div>
              </div>
              <div className="w-full h-1/2">
                <img
                  src={card.image}
                  alt="image"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SecondSection;
