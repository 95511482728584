import img1 from "../../../assets/Seminar/Section2_Img1.jpg";
import img2 from "../../../assets/Seminar/Section2_Img2.jpg";
import img3 from "../../../assets/Seminar/Section2_Img3.jpg";
import img4 from "../../../assets/Seminar/Section2_Img4.jpg";
import img5 from "../../../assets/Seminar/Section2_Img5.jpg";
import img6 from "../../../assets/Seminar/Section2_Img6.jpg";

const seminarData = [
  {
    id: 1,
    title: "Legal Seminars",
    description:
      "Lawyers and legal professionals attend seminars to stay updated on changes in laws, discuss legal precedents, and explore emerging legal issues.",
    imageUrl: img1,
    alt: "Legal Seminars",
  },
  {
    id: 2,
    title: "Healthcare Seminars",
    description:
      "Medical professionals attend seminars to learn about new treatments, medical advancements, and best practices in healthcare delivery.",
    imageUrl: img2,
    alt: "Healthcare Seminars",
  },
  {
    id: 3,
    title: "Entrepreneurship and Startups",
    description:
      "Seminars geared towards entrepreneurs provide guidance on starting a business, fundraising, marketing strategies, and navigating the challenges of entrepreneurship.",
    imageUrl: img3,
    alt: "Entrepreneurship and Startups",
  },
  {
    id: 4,
    title: "Cultural Exchange Seminar",
    description:
      "International seminars facilitate cultural exchange, allowing participants to share traditions, ideas, and experiences from different cultures and backgrounds.",
    imageUrl: img4,
    alt: "Cultural Exchange Seminar",
  },
  {
    id: 5,
    title: "Media and Journalism Seminars",
    description:
      "Journalists and media professionals attend seminars to discuss ethical practices, industry challenges, and emerging trends in media.",
    imageUrl: img5,
    alt: "Media and Journalism Seminars",
  },
  {
    id: 6,
    title: "Academic Forums",
    description:
      "Universities and educational institutions organize seminars to discuss research findings, share academic insights, and explore scholarly topics across various disciplines.",
    imageUrl: img6,
    alt: "Academic Forums",
  },
];

export default seminarData;
