import React from "react";
import phone from "../../../assets/ContactUs/PhoneCall.png";
import mail from "../../../assets/ContactUs/Envelope.png";
import location from "../../../assets/ContactUs/MapPinLine.png";
import employee from "../../../assets/ContactUs/illustrator.png"

export default function Section1() {
  return (
    <div className="grid grid-cols-12 gap-0 mx-6 xl:mx-32 lg:mx-16 md:mx-10">
      <div className="col-span-12 md:col-span-5 h-84 xl:h-[500px] lg:h-[500px] md:h-[400px] bg-indigo-950 p-3 md:p-8 lg:p-16 rounded-t-xl md:rounded-l-xl">
        <div className="flex flex-col">
          <div className="text-center md:text-left text-2xl xl:text-3xl lg:text-3xl md:text-xl text-white font-bold mb-8 lg:mb-8">
            Get in Touch
          </div>
          <div className="grid grid-cols-1">
            <div className="col-span-1 ml-2.5 lg:ml-4">
              <div className="flex flex-row items-center gap-2 xl:gap-4">
                <div className="rounded-full w-10 xl:w-16 h-10 xl:h-16 bg-opacity-25 border p-0 xl:p-2.5 flex items-center justify-center">
                  <img src={phone} alt="phn" className="w-8 h-8" />
                </div>
                <div className="flex flex-col">
                  <div className="text-white opacity-60 uppercase tracking-tight text-xs lg:text-sm">
                    Phone Number
                  </div>
                  <div className="flex flex-row  gap-1 xl:gap-2 whitespace-nowrap">
                    <div className="opacity-50 text-white text-xs lg:text-md py-0 lg:py-2">
                      Toll-Free India:
                    </div>
                    <div className="text-white text-xs lg:text-md py-0 lg:py-2">
                      +91 1234567890
                    </div>
                  </div>
                  <div className="flex flex-row gap-1 xl:gap-2">
                    <div className="opacity-50 text-white text-xs lg:text-md">
                      Worldwide:
                    </div>
                    <div className="text-white text-xs lg:text-md">
                      +1 1234567890
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center gap-2 xl:gap-4 my-4">
                <div className="rounded-full w-10 xl:w-16 h-10 xl:h-16 bg-opacity-25 border p-0 xl:p-2.5 flex items-center justify-center">
                  <img src={mail} alt="mail" className="w-8 h-8" />
                </div>
                <div className="flex flex-col">
                  <div className="text-white opacity-60 uppercase tracking-tight text-xs lg:text-sm mt-1.5">
                    Mailing Address
                  </div>
                  <div className="text-white text-sm lg:text-lg py-2">
                    info@hpdstech.com
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-center gap-2 xl:gap-4">
                <div className="rounded-full w-10 xl:w-16 h-10 xl:h-16 bg-opacity-25 border p-0 xl:p-2.5 flex items-center justify-center">
                  <img src={location} alt="location" className="w-8 h-8" />
                </div>
                <div className="flex flex-col">
                  <div className="text-white opacity-60 uppercase tracking-tight text-xs lg:text-sm mt-1.5">
                    Centre and Corporate Office
                  </div>
                  <div className="text-white text-sm lg:text-md py-2">
                    205 Detroit Street, Denver, CO 80206
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-span-12 md:col-span-7 h-84 xl:h-[500px] lg:h-[500px] md:h-[400px] bg-neutral-100
      pt-3 xl:pt-16 lg:pt-16 xl:pl-16 lg:pl-16 md:pt-8 md:pl-8 rounded-b-xl md:rounded-r-xl text-center md:text-left"
      >
        <div className="text-stone-900 text-2xl xl:text-5xl lg:text-4xl md:text-2xl tracking-tight font-bold mb-6">
          Think Skill India Team!
        </div>
        <div className="opacity-60 text-stone-900  text-sm xl:text-2xl lg:text-xl md:text-md font-semibold leading-loose">
          We are located in Hyderabad
          <br />
          Open Monday-Friday
          <br />
          From 9:00am- 5:00pm IST
        </div>
        <div className="xl:ml-80 lg:ml-40 md:ml-24 xl:mt-6 lg:mt-12 md:mt-0">
          <img
            src={employee}
            alt="illustrator"
            className="xl:w-72 xl:h-56 lg:w-72 lg:h-56 md:h-48 md:w-64"
          />
        </div>
      </div>
    </div>
  );
}
