import React from 'react';
import backgroundImage from '../../../assets/Support/Section1imagebg.png'; // Import the background image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const Section1 = () => {
  return (
    <section className="relative bg-cover bg-center py-44" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="container mx-auto px-4 flex flex-col items-center justify-center">
        <h1 className="text-6xl text-center text-blue-500 font-bold mb-8">Welcome to HPDS Support</h1>
        <button className="flex items-center bg-gray-300 border-blue-400 border-2 text-gray-500 hover:text-white py-3 px-6 rounded-full shadow hover:bg-blue-600 ">
          <span className="mr-2 mx-6 text-gray-500 hover:text-white">How Can We Help You</span>
          <FontAwesomeIcon icon={faArrowRight} className="text-blue-500 mx-8" />
        </button>
      </div>
    </section>
  );
};

export default Section1;
