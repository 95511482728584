import image1 from "../../../assets/MainMenu/Section18image-2.png"
import image2 from "../../../assets/MainMenu/section18image-3.png"
import image3 from "../../../assets/MainMenu/section18image-4.png"
import image4 from "../../../assets/MainMenu/section18image-5.png"

const dataset = [
    {
      id: 1,
      title: <>Cultivate the spirit of togetherness</>,
      description: <>CULTIVATE THE SPIRIT OF TOGETHERNESS with intellectual individuals across various Indian universities and other global institutions to achieve a common goal and create capacity for the new generation.</>,
      img: image1,
    },
    {
      id: 2,
      title: <>Youth creating a supportive culture</>,
      description: "Shaping the future of Indian YOUTH CREATING A SUPPORTIVE CULTURE THAT VALUES continuous learning and development.​",
      img: image2,
    },
    {
      id: 3,
      title: <>Learning journey that prepare Youth</>,
      description: "Harness those opportunities with LEARNING JOURNEYS THAT PREPARE YOUTH to participate in modern communities and workplaces.​",
      img: image3,
    },
    {
        id: 4,
        title: <>Increase the overall development of the Youth</>,
      description: "Developments in youth education, employment, mental health, equality and inclusion, peace and security, and political and civic participation in order to INCREASE THE RATE OF OVERALL DEVELOPMENT OF THE YOUTH.​",
        img: image4,
      },
  ];
  
  export default dataset;