import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Section4Img from "../../../assets/Instructor/Section4_Img1.jpg";
import smBgImg from "../../../assets/Instructor/mobileBgImg.png"

const TermsConditionsTemplate = ({ expertType, bgImg }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const bgImageStyle = {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
   const smBgImageStyle = {
     backgroundImage: `url(${smBgImg})`,
     backgroundSize: "contain",
     backgroundPosition: "center",
   };

  return (
    <div className="bg-slate-100 w-full h-full px-2.5 py-4 xl:px-24 xl:py-16 lg:px-8 lg:py-8 md:px-4 md:py-4">
      <div className="grid grid-cols-2 ">
        {/* First Row */}
        <div className="order-2 md:order-1 col-span-2 md:col-span-1 flex justify-start items-start pt-4 md:py-8 px-2.5 md:pl-3 md:pr-4 xl:pr-1 lg:pr-6">
          <div className="w-full xl:w-[550px] h-full rounded-xl">
            <img
              src={Section4Img}
              alt="blank"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
        <div className="order-1 md:order-2 col-span-2 md:col-span-1 place-content-start flex flex-col text-black">
          <div className="ml-3 pt-8 font-semibold font-Segoe UI text-2xl xl:text-4xl lg:text-3xl md:text-xl cursor-pointer">
            <div>Terms and Conditions</div>
            <div className="mt-4 tracking-tight font-normal font-Segoe UI text-xs xl:text-md lg:text-sm md:text-xs">
              {showDetails ? (
                <>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </>
              ) : (
                <>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur...
                </>
              )}
            </div>
            <button
              className="text-blue-500 text-xs xl:text-md lg:text-sm md:text-xs cursor-pointer xl:mt-4 lg:mt-4 md:mt-0 hover:bg-neutral-100 hover:shadow-md"
              onClick={toggleDetails}
            >
              {showDetails ? "Show Less" : "Learn More"}
            </button>
          </div>
        </div>
      </div>

      <div className="order-last">
        <div
          className="md:col-span-2 my-4  pb-56 xl:px-16 lg:px-8 md:px-0 xl:py-16 lg:py-8 md:py-0 w-full h-full bg-contain bg-no-repeat rounded-xl md:hover:shadow-lg md:hover:border border-gray-500"
          style={window.innerWidth < 768 ? smBgImageStyle : bgImageStyle}
        >
          <div className="md:col-span-1 md:place-content-start flex flex-col text-white pb-24">
            <div className="mb-2 mt-6 xl:pt-8 lg:pt-8 md:pt-6 xl:pb-4 lg:pb-4 md:pb-2 md:px-6 md:py-4 font-semibold font-Segoe UI text-xl xl:text-3xl lg:text-2xl md:text-xl">

              <div class="md:hidden block px-6">
                Join Our Think Skill <br />
                India Family as an <br />
                <span className="font-bold">{expertType}</span>
              </div>

              <div class="hidden md:block">
                <div>Join Our Think Skill India</div>
                <div className="flex">
                  <div>Family as an </div>
                  <div className="font-bold ml-2">{expertType}</div>
                </div>
              </div>
            </div>
            <div className="ml-6 md:ml-8 mt-6 md:mt-2 px-4 py-2 w-fit hover:bg-red-400 hover:text-black cursor-pointer rounded-full border border-white hover:border-black flex items-center justify-start ">
              <div className="text-xs font-bold  ">Join us Now</div>
              <FontAwesomeIcon icon={faChevronRight} className=" ml-2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditionsTemplate;
