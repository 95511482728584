import React from 'react';
import DatasetSec5 from './DatasetSec6';

const Section5 = () => {
  const totalCards = DatasetSec5.length;

  return (
    <div className=" md:py-6 mx-4 md:mx-2 lg:mx-8 xl:mx-20 ">
      
    <div className='text-2xl md:text-3xl lg:text-4xl xl:text-5xl mb-4 px-4 md:px-8 lg:px-16 xl:px-24 py-3 md:py-4 lg:py-6 xl:py-8'> TITLE</div>
      {/* Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4  gap-0">
  {DatasetSec5.map((card, index) => (
    <div key={card.id} className={`col-span-1 px-4 md:px-0 md:text-sm lg:text-md xl:text-lg ${index === 3 ? 'border-r-0' : ''}`} style={{ height: '37rem', '@media (min-width: 768px)': { height: '60rem' ,  } }}>
      <div className={`md:p-4 flex flex-col items-center border-r border-gray-500 ${index % 2 === 1 && index !== 5 ? 'pt-0 md:pt-28 lg:pt-32 ' : ''}`}>
        <div className='mb-2 px-12 text-center text-2xl'>{card.title}</div>
        <p className="text-center text-sm  md:text-xs lg:text-md xl:text-md mb-4">{card.description}</p>
        <img src={card.image} alt={`Card ${card.id}`} className="w-24 h-24 md:w-40 md:h-40 object-contain" />
      </div>
    </div>
  ))}
</div>

    </div>
  );
};

export default Section5;
