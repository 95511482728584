import React from 'react'
import CentralImage from '../../../assets/events_slide_img1.jpg' 

const ContSlide = ({title, subTit}) => {
  
  return (
    <div className="flex flex-col items-center justify-center my-5">
      <div className="py-10 items-start text-center mx-auto w-11/12 md:w-9/12">
        <div className="font-semibold text-4xl my-6">{title}</div>
        <div className="text-xs md:text-base">{subTit}</div>
      </div>
      <div className='w-5/6'>
        <img src={CentralImage} alt="img" />
      </div>
    </div>
  )
}

export default ContSlide