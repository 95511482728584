import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import Section12image1 from "../../../assets/HPDSxEngine/Section12image1.png";
import Section12image2 from "../../../assets/HPDSxEngine/Section12image2.png";
import Section12image3 from "../../../assets/HPDSxEngine/Section12image3.png";

const Section12 = () => {
  const { ref, inView } = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  return (
    <motion.section
      className="mx-auto py-24 px-4 md:px-0"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 100 }
      }}
      transition={{ duration: 0.7, ease: 'easeInOut' , delay:0.2}}
    >
      <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl px-4 md:px-12 lg:px-24 xl:px-36 text-center mb-8 text-blue-500 " style={{ lineHeight: '140%' }}>
        Decoding the Skill Evolution: <span className='font-bold'>Navigating the Shifting Job Market Dynamics</span>
      </h1>

      <motion.div
        className="grid grid-cols-12 gap-4 md:gap-3 lg:gap-4 xl:gap-6 justify-center items-center mx-4 md:mx-8 lg:mx-20 xl:mx-32"
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.2,
              delayChildren: 0.5
            }
          }
        }}
      >
        {/* Card 1 */}
        <motion.div className="col-span-12 md:col-span-4"
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: -100 }
          }}transition={{ duration: 0.7, ease: 'easeIn' , delay:0.2}}
        >
          <div className="rounded-lg shadow-md p-4 h-96" style={{ backgroundColor: 'rgb(246, 251, 245)' }}>
            <img src={Section12image1} alt="Card 1" className="mx-auto mb-4 my-20 md:mt-8 lg:mt-12 xl:mt-20" style={{ width: '80px' }} />
            <p className="text-center text-green-700 md:text-md lg:text-lg font-semibold px-2 md:px-4 lg:px-8 xl:px-12" style={{ lineHeight: '140%' }}>Which skills are in demand in both digitally intensive jobs, and more broadly?</p>
          </div>
        </motion.div>

        {/* Card 2 */}
        <motion.div className="col-span-12 md:col-span-4"
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 100 }
          }}transition={{ duration: 0.7, ease: 'easeIn' , delay:0.2}}
        >
          <div className="bg-gray-200 rounded-lg shadow-md p-4 h-96" style={{ backgroundColor: 'rgb(247, 250, 255)' }}>
            <img src={Section12image2} alt="Card 2" className="mx-auto mb-4 my-20 md:mt-8 lg:mt-12 xl:mt-20" style={{ width: '80px' }} />
            <p className="text-center text-blue-700 md:text-md lg:text-lg font-semibold px-2 md:px-4 lg:px-8 xl:px-12" style={{ lineHeight: '140%' }}>How and when do evolving skills change the job market?</p>
          </div>
        </motion.div>

        {/* Card 3 */}
        <motion.div className="col-span-12 md:col-span-4"
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: 100 }
          }}transition={{ duration: 0.7, ease: 'easeIn' , delay:0.2}}
        >
          <div className="bg-blue-500 rounded-lg shadow-md p-4 h-96" style={{ backgroundColor: 'rgb(255, 246, 246)' }}>
            <img src={Section12image3} alt="Card 3" className="mx-auto mb-4 my-20 md:mt-8 lg:mt-12 xl:mt-20" style={{ width: '80px' }} />
            <p className="text-center text-purple-700 md:text-md lg:text-lg font-semibold px-2 md:px-4 lg:px-8 xl:px-12 " style={{ lineHeight: '140%' }}>how do the skills interact, and what do they mean for job seekers and incumbent employees, educators, and employers?​</p>
          </div>
        </motion.div>
      </motion.div>
    </motion.section>
  );
};

export default Section12;
