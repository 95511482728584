import React from "react";
import HeroTemplate from "../Templates/HeroTemplate";
import smmain from "../../../assets/HomePage/Student/stmain.webp";

const Section1 = () => {
  const data = {
    title: "Free or premium Think Skill India has you covered",
    description:
      "Everyone gets cloud storage and essential Think Skill India features on the web, free of charge",
    imageUrl: smmain,
  };

  return (
    <div>
      <HeroTemplate
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section1;
