import React from "react";

const CardGridTemplate = ({ title, data }) => {
  return (
    <div className="max-w-screen-xl mx-auto px-6 py-8 h-full bg-white xl:py-20 py-5 lg:py-12 md:py-4 flex flex-col items-center ">
      <div className="text-center text-stone-900 text-2xl xl:text-4xl lg:text-3xl md:text-2xl font-semibold">
        {title}
      </div>
      <div className="flex max-w-screen-lg mx-auto justify-center items-center flex-wrap mt-6 xl:mt-8 lg:mt-4 md:mt-0">
        <div className="w-full grid grid-cols-12">
          {data.map((item, index) => {
            return (
              <div className="col-span-12 md:col-span-4 p-2 px-4" key={index}>
                <div className="w-full h-72 lg:h-96 flex flex-col justify-center items-center hover:shadow-md hover:rounded-md">
                  <div className="md:h-5/12 w-full flex justify-center items-center">
                    <img
                      className="w-28 h-28 xl:w-48 xl:h-48 lg:w-36 lg:h-36 md:w-24 md:h-24 rounded-full object-cover"
                      src={item.imageUrl}
                      alt={item.alt}
                    />
                  </div>
                  <div className="w-full h-7/12 text-center py-3 px-3">
                    <div className="text-center w-full text-stone-900 text-md xl:text-xl lg:text-lg md:text-sm font-semibold mt-2">
                      {item.title}
                    </div>
                    <div className="w-full text-center text-stone-900 text-xs xl:text-md lg:text-sm md:text-xs">
                      {item.description}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CardGridTemplate;
