import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import img_1 from "../../../assets/ThinkSkillIndia/img_5.jpg";
import img_2 from "../../../assets/ThinkSkillIndia/img_6.jpg";
import img_3 from "../../../assets/ThinkSkillIndia/img_7.jpg";

const data1 = (
  <>
    THINKSKILLINDIA{" "}
    <span className="text-yellow-300">will Work to Deliver </span> ​<br /> major
    Key Objectives over the next 5 Years
  </>
);
const data2 = {
  buttons: [
    {
      id: 1,
      buttonText: <>Outcomes for the Youth</>,
      heading: <>Outcomes for young people</>,
      desc1: (
        <>
          The reality of building capacity for the 21st century is that we do
          not know what the work of the future will be like or how technology
          will influence health and financial issues.
        </>
      ),
      desc2: (
        <>
          The challenge is to prepare men and women to think critically, to
          engage in mental activity, or habits of mind, that
          <span className="text-yellow-300">
            “…use facts to plan, order, and work toward an end; seek meaning or
            explanations; are self-reflective; and use reason to question claims
            and make judgments…
          </span>
        </>
      ),
      image: img_1,
    },
    {
      id: 2,
      buttonText: <>Strengthening the Youth</>,
      heading: <>Strengthening the Youth</>,
      desc1: (
        <>
          Your why is just as important as your what. If you don’t understand
          yourself enough to know why you want to get where you want to go, you
          may not know yourself well enough to help get you there..
        </>
      ),
      desc2: (
        <>
          Whatever your end goal is — 
          <span className="text-yellow-300">
            developing the next life-changing app, fixing a major world problem,
            and making an impact in a particular field
          </span>
          — take some time to find out why you are so drawn to that goal and why
          it means so much to you.​
        </>
      ),
      image: img_2,
    },
    {
      id: 3,
      buttonText: <>Future of Work Skills</>,
      heading: (
        <>Future of work skills development of the youth into workforce.</>
      ),
      desc1: (
        <>
          It may be that our task is not only to prepare men and women to {" "}
          <span className="text-yellow-300">
            “fit into the future” but to shape it.
          </span>
        </>
      ),
      desc2: (
        <>
          “…If the complex questions of the 
          <span className="text-yellow-300">
            future are to be determined…
          </span>{" "}
          by human beings…making one choice rather than another, we should
          educate- all of them - to join in the conversation about those choices
          and to influence that future…”​
        </>
      ),
      image: img_3,
    },
  ],
};

const Section6 = () => {
  const { buttons } = data2;
  const [selectedButton, setSelectedButton] = useState(buttons[0]);
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView();
  const controlsLeft = useAnimation();
  const controlsRight = useAnimation();

  useEffect(() => {
    setLoaded(true);
  }, []);

  const handleButtonClick = async (button) => {
    await Promise.all([
      controlsLeft.start({
        opacity: 0,
        x: -50,
        transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 },
      }),
      controlsRight.start({
        opacity: 0,
        x: -50,
        transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 },
      }),
    ]);
    setSelectedButton(button);
    await Promise.all([
      controlsLeft.start({
        opacity: 1,
        x: 0,
        transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 },
      }),
      controlsRight.start({
        opacity: 1,
        x: 0,
        transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 },
      }),
    ]);
  };

  return (
    <div className="w-full h-full text-white px-4 md:px-8 lg:px-16 xl:px-24 py-16 bg-blue-500">
      <div
        className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold"
        style={{ lineHeight: "140%" }}
      >
        {data1}
      </div>

      <div className="flex grid grid-cols-1 md:grid-cols-3 justify-start gap-4 md:gap-12 lg:gap-6 xl:gap-10 md:py-4 lg:py-8  py-6 ">
        {buttons.map((button) => (
          <button
            key={button.id}
            className={`text-center  hover:bg-yellow-400  hover:text-white text-md font-semibold py-2 px-2 md:py-3 md:px-3 rounded-xl  border ${
              selectedButton.id === button.id
                ? "border-yellow-400 hover:border-transparent text-white bg-yellow-400"
                : "text-yellow-400 border-yellow-400 hover:border-transparent"
            } transition duration-300 ease-in-out`}
            onClick={() => handleButtonClick(button)}
          >
            {button.buttonText}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-12 gap-6 mt-10">
        {/* Left Section */}
        <div
          className="col-span-12 md:col-span-7 lg:col-span-6 "
          initial={{ opacity: 0, x: 0 }}
          animate={controlsLeft}
          transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }}
        >
          {loaded && (
            <>
              <div
                className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-3"
                style={{ lineHeight: "140%" }}
              >
                {selectedButton.heading}
              </div>
              <div
                className=" text-md md:text-lg lg:text-xl xl:text-2xl font-medium"
                style={{ lineHeight: "140%" }}
              >
                {selectedButton.desc1}
              </div>
              <div className="border-2 my-4"></div>
              <div
                className="text-md md:text-lg lg:text-xl xl:text-2xl font-medium"
                style={{ lineHeight: "140%" }}
              >
                {selectedButton.desc2}
              </div>
            </>
          )}
        </div>
        {/* Right Section */}
        <div className="col-span-12 md:col-span-5 lg:col-span-6 flex flex-col justify-center items-center">
          {loaded && (
            <img
              src={selectedButton.image}
              alt="Right Section Image"
              className="md:w-full h-full lg:w-5/6 rounded-xl "
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Section6;
