import React from "react";
import hero_img from "../../../assets/CustomerSuccess/Section1image1.png";
import bgimg from "../../../assets/CustomerSuccess/Section1imagebg.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight} from "@fortawesome/free-solid-svg-icons";

const LandingCover = () => {
  return (
    <div className="text-white w-full  grid grid-cols-12 gap-0 md:gap-4 xl:gap-8 pl-2 px-4 py-16 xl:pt-12  xl:py-12 lg:px-16 lg:py-8 md:px-6  xl:px-24 md:py-4" style={{ backgroundImage: `url(${bgimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="xl:pt-20 px-4 text-white  order-2 md:order-1 col-span-12 md:col-span-6 flex flex-col cursor-pointer  justify-center">
        <div className=" mb-0 md:mb-2 pb-4 md:pb-1 xl:pb-4 font-bold text-3xl md:text-4xl xl:text-6xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight xl:leading-tight"style={{ lineHeight: '140%' }}>
        Welcome to HPDSx User Success HUB
        </div>
        <div className=" text-lg md:text-xl lg:text-2xl xl:text-3xl py-4 lg:py-6" style={{ lineHeight: '140%' }}>
          <div>
          Log in to contact support, view open events, access programs, and more
          </div>
        </div>

        <div >
            <button className="px-4 py-4 md:px-2 md:py-2 lg:py-4 lg:px-4 rounded-lg "style={{ backgroundColor: 'rgb(255, 192, 0)' }}>
            Log into your Hub <span className="mx-2"><FontAwesomeIcon icon={faAngleRight} style={{color: "#ffffff",}} /></span>
            </button>
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-12 md:col-span-6 flex flex-col justify-center items-center py-2 xl:py-4 md:py-1 lg:py-4">
        <div className="w-full">
          <img src={hero_img} alt="blank" className="w-full md:w-5/6" />
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
