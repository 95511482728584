import React from "react";
import Expert from "../../components/hpdsXhub/LearnAndGrow/Expert"
import Header from "../../components/hpdsXhub/LearnAndGrow/Header"
import Learn from "../../components/hpdsXhub/LearnAndGrow/Learn"
import Roadmap from "../../components/hpdsXhub/LearnAndGrow/Roadmap"
import Skill from "../../components/hpdsXhub/LearnAndGrow/Skill";


export default function LearnAndGrow() {
    return (
      <div className="flex flex-col">

        <Header />
        <Skill/>
        <Learn />
        <Roadmap />
        <Expert />
        
      </div>
    );
  }
