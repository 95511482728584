import React from "react";
import flexData from "./thirdSectionData";

const ThirdSection = () => {
  return (
    <div className="max-w-screen-xl mx-6 xl:mx-28 lg:mx-24 md:mx-16 py-6 md:py-12 bg-white">
      <div className="text-2xl font-semibold font-Poppins pb-8">
        Human Potential Development System
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8">
        {flexData.map((item) => (
          <div
            key={item.id}
            className="flex flex-col justify-start items-start gap-4 "
          >
            <img
              className="w-full h-full xl:w-full xl:h-64 lg:w-full lg:h-64 md:w-full md:h-44 object-cover rounded-lg hover:shadow-2xl"
              src={item.imageUrl}
              alt={item.title}
            />
            <div className="text-stone-900 xl:text-xl lg:text-lg md:text-sm font-semibold font-Poppins cursor-pointer">
              {item.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThirdSection;
