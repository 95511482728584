import React, { useState, useEffect } from 'react';
import { motion , useAnimation} from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Section6 = () => {
    const [selectedButton, setSelectedButton] = useState(1);
    const [fadeIn, setFadeIn] = useState(false);
    const { ref, inView } = useInView();




    return (
        <motion.div
            ref={ref}
            className="w-full p-4 bg-[#EFF2FB]"
            initial={{ opacity: 0, y: 80 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 80 }}
            transition={{ ease: 'easeInOut', duration: 0.7, delay: 0.2 }}
        >
            <div className="max-w-screen-xl mx-auto flex flex-col text-black pb-6 md:pb-8 lg:pb-12 xl:pb-16">
                <h1 className="text-center max-w-screen-lg mx-auto text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-loose mb-4 px-4 py-4 md:py-8 lg:py-0 pt-8 md:pt-12 lg:pt-16 font-bold" style={{ lineHeight: '140%' }}>Establishing a diverse and inclusive community for the growth</h1>
                <h1 className="text-center mx-auto max-w-screen-lg text-lg md:text-lg lg:text-xl xl:text-2xl leading-loose mb-4 px-4 py-[6px] md:py-4 lg:py-8 font-medium" style={{ lineHeight: '140%' }}>HPDS brings a diverse spectrum of viewpoints, experiences, and backgrounds to the business by hiring and developing the greatest individuals. Workers who reflect the markets we service provide us with creative ideas, customer insights, and on-the-spot market innovation.</h1>
                <div className='bg-gray-200 mx-4 md:mx-8 h-96 md:h-[80vh] rounded-lg shadow-md border border-gray-300'>
                </div>
            </div>
        </motion.div>
    );
};

export default Section6;
