import React from "react";
import LandingCover from "../../components/Publication/LandingCover";
import Publication from "../../components/Publication/Publication";

export default function Publications() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <Publication />
    </div>
  );
}
