import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import DatasetSec2 from "./DatasetSec2";

const Section2 = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  return (
    <motion.div
      className="w-full grid place-items-center"
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: inView ? 1 : 0 }}
      transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }}
    >
      <div className="max-w-screen-2xl text-black text-center bg-white py-20">
        <div
          className="text-2xl md:text-3xl lg:text-4xl xl:text-4xl mb-16 px-4 md:px-20 lg:px-28 xl:px-80 py-8 md:py-4 font-bold"
          style={{ lineHeight: "140%" }}
        >
          <div>The best possible ways</div>
          <div>to Re-Engineering the way </div>
          <div>
            we
            <span className="" style={{ color: "rgb(0, 134, 232)" }}>
              {" "}
              Think, Act, and Communicate
            </span>
          </div>
        </div>

        {DatasetSec2.map((card, index) => (
          <motion.div
            key={card.id}
            className={`   grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-0 mt-${
              index === 1 ? "12" : "8"
            }`}
            initial={{ opacity: 0, y: 80 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }}
            transition={{ duration: 0.9, ease: "easeInOut", delay: 0.4 }}
          >
            {index === 1 ? (
              <>
                <div className="order-2 md:order-1 text-left">
                  <motion.img
                    src={card.image}
                    alt="Image"
                    className="mt-8 mx-4 md:mx-0 ml-8 md:ml-12 lg:ml-32 w-4/5 md:w-4/5 lg:w-4/5"
                    initial={{ opacity: 0, y: 80 }}
                    animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 0.6 }}
                  />
                </div>
                <div className="order-1 md:order-2 text-left ml-[28px] md:ml-12 lg:ml-12 mx-20 mt-4 md:mt-8 lg:mt-12 w-5/6 md:w-5/6 lg:w-5/6">
                  <motion.div
                    className="text-2xl md:text-xl lg:text-3xl xl:text-5xl mb-4 md:mb-8 font-bold md:mt-8 lg:mt-16 xl:mt-20"
                    style={{ lineHeight: "140%" }}
                    initial={{ opacity: 0, y: 80 }}
                    animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 0.7 }}
                  >
                    {card.title}
                  </motion.div>
                  <motion.div
                    className="text-xl md:text-md lg:text-xl xl:text-3xl text-justify"
                    style={{ lineHeight: "140%" }}
                    initial={{ opacity: 0, y: 80 }}
                    animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 0.8 }}
                  >
                    {card.description}
                  </motion.div>
                </div>
              </>
            ) : (
              <>
                <div className="text-left ml-10 md:ml-12 mt-4 md:mt-8 lg:ml-32 w-5/6 md:w-5/6 lg:w-5/6">
                  <motion.div
                    className="text-xl md:text-xl lg:text-3xl xl:text-5xl mb-4 md:mb-8 font-bold md:mt-12 lg:mt-20 xl:mt-24"
                    style={{ lineHeight: "140%" }}
                    initial={{ opacity: 0, y: 80 }}
                    animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 0.7 }}
                  >
                    {card.title}
                  </motion.div>
                  <motion.div
                    className="text-xl md:text-md lg:text-xl xl:text-3xl text-justify"
                    style={{ lineHeight: "140%" }}
                    initial={{ opacity: 0, y: 80 }}
                    animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 0.8 }}
                  >
                    {card.description}
                  </motion.div>
                </div>
                <div className="text-right">
                  <motion.img
                    src={card.image}
                    alt="Image"
                    className="mx-4 ml-8 md:ml-12 lg:ml-4 w-4/5 md:w-4/5 lg:w-4/5"
                    initial={{ opacity: 0, y: -50, scale: 0.9 }}
                    animate={{ opacity: inView ? 1 : 0, y: 0, scale: 1 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 0.6 }}
                  />
                </div>
              </>
            )}
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default Section2;
