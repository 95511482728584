import React from "react";
import backgroundImage from "../../../assets/TranstechCollaboratory/Section9imagebg.png"; // Import the background image
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import image from "../../../assets/TalentCollaboratory/section8image1.png";
import img1 from "./Images/img.png";
import img2 from "./Images/img-1.png";
import img3 from "./Images/img-2.png";
import img4 from "./Images/img-3.png";

const data = [
  {
    id: 1,
    img: img1,
    color: "#5C5BB7",
    desc: <>The art of using <b>Mental Energy</b> to transform and greatly improve health, beauty, prosperity, loving relationships and the fulfillment of all your desires.</>,
    title: "Manage your conscious mind",
  },
  {
    id: 1,
    img: img2,
    color: "#A53C03",
    desc: <><b>The secret yogic</b> easy-to-follow exercises, meditations, and affirmations, other techniques to tap into the <b>Natural Goodness</b> and <b>Beauty</b> in all of the life – to give you ability to become a radiant being – to make positive ideas and concepts a total reality.</>,
    title: "Manage your creative will",
  },
  {
    id: 1,
    img: img3,
    color: "#3C6E56",
    desc: <><b>A Cohesive set of practices</b> that inspire your <b>Imaginative Mind</b> to look beyond , explore a broad range of <b>Possibilities,</b> identify significant opportunities,  make informed decisions about the most promising paths to pursue, create a shared vision for growth, define pragmatic action plans</>,
    title: "Manage your core genius",
  },
  {
    id: 1,
    img: img4,
    color: "#E71FA9",
    desc: <><b>The secret of health</b> for both <b>Mind</b> and <b>Body</b> is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly.</>,
    title: "Manage your present life",
  },
];

const Section9 = () => {
  return (
    <section
      className="relative bg-cover bg-center py-16 px-4 md:px-8 lg:px-16 xl:px-24"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className=" ">
        <h2
          className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 text-white text-center py-4"
          style={{ lineHeight: "140%" }}
        >
          The elements of our <span className="text-yellow-400">HUMANCARE</span>{" "}
          Sequence
        </h2>

        <div
          className="text-white py-4 text-md md:text-lg lg:text-xl xl:text-2xl text-center xl:px-24 "
          style={{ lineHeight: "140%" }}
        >
          UPMYMIND’s HUMANCARE Sequence contains a configurable mixture of four
          of the following solutions, each drawn from the Intelligent COSMIC
          OPTIMISM Platform, that set you up for success
        </div>

        <div className="py-4">
          <img src={image} className="mx-auto" />
        </div>

        <div className="text-white text-center py-4 xl:text-3xl lg:text-2xl md:text-xl text-lg font-semibold">
          The FOUR superpowers of{" "}
          <span className="text-yellow-400">HUMAN MIND</span>
        </div>

        <div className="w-full grid grid-cols-12">
          {data?.map((e, index) => {
            return (
              <div
                key={index}
                className="col-span-12 md:col-span-6 w-full h-full p-6"
              >
                <div className="w-full h-full grid grid-cols-12">
                  <div className="col-span-12 md:col-span-6">
                    <div
                      className={`w-full h-full  text-white p-2`}
                      style={{ backgroundColor: `${e.color}` }}
                    >
                      <div className="p-2 text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold text-center">{e.title}</div>
                      <div className="p-2 text-center text-md md:text-lg">{e.desc}</div>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <img className="w-full h-full object-cover" src={e.img} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Section9;
