import React, { useState } from 'react';

import Section4Button1 from '../../../assets/HPDSxEngine/Section4Button1.png';
import Section4Button2 from '../../../assets/HPDSxEngine/Section4Button2.png';
import Section4Button3 from '../../../assets/HPDSxEngine/Section4Button3.png';

import DatasetSec4 from './DatasetSec5';

// Import the background image
import backgroundImage from '../../../assets/Council/Section3imagebg.png';

const Section14 = () => {
    const [activeDataset, setActiveDataset] = useState(DatasetSec4[0]);

    const handleButtonClick = (id) => {
      const dataset = DatasetSec4.find(item => item.id === id);
      setActiveDataset(dataset);
    };
  
    // Dataset for the buttons
    const buttonDatasets = [
      { id: 1, image: Section4Button1, title: 'Empowerment through Skill Support' },
      { id: 2, image: Section4Button2, title: 'Career Connection Initiative' },
    ];

  return (
    <section className="py-12 bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="">
        <h2 className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-bold text-black text-center lg:py-12 xl:py-16">​Employment <span className='text-blue-500'>Centers</span></h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-8 md:mt-12 lg:mt-6 xl:mt-4 bg-white mx-2 md:mx-[5px] lg:mx-20 xl:mx-80">
        <div className="col-span-12 md:col-span-6 flex flex-row justify-around p-4 md:p-0 lg:p-2 rounded-xl">
          {buttonDatasets.map(button => (
            <div key={button.id} className="col-span-12 md:col-span-6">
            <div
              className={`text-center cursor-pointer text-xs md:text-sm lg:text-sm hover:bg-blue-400 text-black font-bold py-3 px-4 md:px-8 rounded flex items-center justify-center md:border-r-2  ${activeDataset.id === button.id ? 'bg-blue-400 text-white border-blue-400 hover:border-transparent' : 'border-blue-400 hover:border-transparent'}`}
              onClick={() => handleButtonClick(button.id)}
             
            >
                 <img src={button.image} alt="Image Description" className='mr-2' />
                 {button.title}
               </div>
             </div>
          ))}
        </div>
      </div>

      {activeDataset && (
        <div className="col-span-12 md:col-span-6 bg-white rounded-xl p-2 md:p-4 lg:p-6 my-4 md:my-8 lg:my-12 xl:my-20 mx-4 md:mx-10 lg:mx-20 xl:mx-40">
          <div className="grid grid-cols-12 gap-4 lg:py-8 xl:py-12 " style={{ lineHeight: '140%' }}>
            {/* Left section */}
            <div className="col-span-12 md:col-span-6">
              <h2 className="text-xl md:text-lg lg:text-xl xl:text-2xl font-bold mb-4" >{activeDataset.heading}</h2>
              <p className="text-lg md:text-md lg:text-lg xl:text-xl text-gray-600 mb-4 py-8 md:text-justify">{activeDataset.description}</p>
            </div>
            {/* Right section */}
            <div className="col-span-12 md:col-span-6 flex md:items-center md:justify-center lg:justify-center lg:items-start xl:items-center xl:justify-center">
              <img src={activeDataset.rightimage} alt="Image" className="w-full md:w-5/6 lg:w-full  rounded-lg" />
            </div>
          </div>
        </div>
      )}
      </div>
    </section>
  );
};

export default Section14;
