import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons';
import DatasetSec4 from './DatasetSec7';

const Section13 = () => {
  const { buttons } = DatasetSec4;
  const [selectedButton, setSelectedButton] = useState(buttons[0]); 
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    
    setLoaded(true);
  }, []);

  const handleButtonClick = (button) => {
    setSelectedButton(button);
  };

  return (
    <div className="h-[180vh] md:h-[120vh] lg:h-[130vh] relative ">
      <div className="absolute inset-0 flex flex-col  text-black ">
        <h1 className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl leading-loose py-4 md:py-12 pt-12 md:pt-16 lg:pt-32  lg:py-0  mx-4 md:mx-12 lg:mx-20 xl:mx-28 font-semibold"style={{ lineHeight: '140%' }}>
          <span className='text-black'>Business and Entrepreneurs</span>
        </h1>

        <h1 className='text-2xl md:text-2xl lg:text-3xl xl:text-4xl leading-loose  lg:py-8   mx-4 md:mx-12 lg:mx-20 xl:mx-28 md:text-justify'style={{ lineHeight: '140%' }}>Empower Your Business: Bridge Industry-Education Gaps with THINKSKILLINDIA</h1>
        <div className="grid xl:grid-cols-4 lg:grid-cols-2  md:grid-cols-2  gap-3 md:gap-2  lg:gap-4 xl:gap-6 py-6 md:py-4 mx-4 md:mx-12 lg:mx-20 xl:mx-24">
          {buttons.map(button => (
            <div
              key={button.id}
              className={`text-center cursor-pointer hover:bg-blue-400 text-blue-400 hover:text-white text-md  font-semibold py-3 px-2 lg:px-4 rounded-xl whitespace-nowrap border ${selectedButton === button ? 'bg-blue-400 text-white border-blue-400 hover:border-transparent' : 'border-blue-400 hover:border-transparent'} transition duration-300 ease-in-out`}
              onClick={() => handleButtonClick(button)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {button.heading}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-12 gap-4">
          {/* Left Section */}
          <div className="col-span-12 md:col-span-6 lg:col-span-6 text-left mx-4 md:mx-12 lg:mx-20 xl:mx-24 my-8 md:my-8 lg:my-20 pt-0 xl:pt-24">
            {loaded && ( // Only render if loaded is true
              <>
                <div className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-8"style={{ lineHeight: '140%' }}>
                  <span className='mx-4'>
                    <FontAwesomeIcon icon={faPaintbrush} style={{color:'black'}}  />
                  </span>
                  {selectedButton.heading}
                </div>
                <div className='text-lg md:text-lg lg:text-xl xl:text-2xl font-semibold md:text-justify'style={{ lineHeight: '140%' }}>
                  {selectedButton.description}
                </div>
              </>
            )}
          </div>
          {/* Right Section */}
          <div className="col-span-12 md:col-span-5 lg:col-span-6 flex justify-center items-center">
            {loaded && ( // Only render if loaded is true
              <img
                src={selectedButton.image}
                alt="Right Section Image"
                className="w-full md:w-full md:mr-16 lg:w-4/5"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section13;
