import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import underlinestyle from "../../../assets/Instructor/UnderlineStyle.jpg";

const ShadowCenterCardTemplate = ({
  title,
  data,
  showUnderlineStyle,
  underlineTop,
  responsiveLeftXL,
  responsiveLeftLG,
  responsiveLeftMD,
}) => {
  return (
    <div className="max-w-screen-xl mx-auto h-full bg-white py-6 md:py-8 px-4 flex flex-col items-center">
      <div className="text-center text-stone-900 text-xl xl:text-4xl lg:text-3xl md:text-3xl font-semibold">
        {title}
      </div>
      {showUnderlineStyle && (
        <div
          className={`w-44 h-12 relative xl:left-[${responsiveLeftXL}] lg:left-[${responsiveLeftLG}] md:left-[${responsiveLeftMD}] top-${underlineTop}`}
        >
          <img
            src={underlinestyle}
            alt="Underline Style"
            className="w-full h-full"
          />
        </div>
      )}

      <div className="flex max-w-screen-lg mx-auto justify-center items-center flex-wrap mt-4">
        <div className="w-full grid grid-cols-12">
          {data.map((item, index) => {
            let middlecard = "";
            if (index === 1) {
              middlecard = "mt-0 md:mt-12 shadow-2xl";
            }
            return (
              <div className="col-span-12 md:col-span-4 p-2 px-4" key={index}>
                <div
                  className={`w-full h-72 lg:h-96 flex flex-col justify-center mt-8 md:mt-0  items-center ${middlecard}`}
                >
                  <div className="h-5/12 w-full flex justify-center items-center">
                    <img
                      className="w-28 h-28 xl:w-48 xl:h-48 lg:w-36 lg:h-36 md:w-24 md:h-24 rounded-full object-cover"
                      src={item.imageUrl}
                      alt={item.alt}
                    />
                  </div>
                  <div className="w-full h-7/12 text-center py-3 px-3">
                    <div className="text-center w-full text-stone-900 text-md xl:text-xl lg:text-lg md:text-sm font-semibold mt-2">
                      {item.title}
                    </div>
                    <div className="w-full text-center text-stone-900 text-sm xl:text-md lg:text-sm md:text-xs">
                      {item.description}
                    </div>
                    {item.button && (
                      <div className="flex justify-center">
                      <div className="mt-4 px-4 py-2 w-fit hover:bg-blue-500 hover:text-white cursor-pointer rounded-full border border-black hover:border-white flex items-center justify-center">
                        <div className="text-xs font-bold">{item.buttonName}</div>
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="ml-2"
                        />
                      </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ShadowCenterCardTemplate;
