import React from "react";
import Section1 from "../../components/Focus/Section1";
import Section2 from "../../components/Focus/Section2";
import Section3 from "../../components/Focus/Section3";
import Section4 from "../../components/Focus/Section4";
import Section5 from "../../components/Focus/Section5";



export const Focus = () => {
    return (
        <div className="flex flex-col font-poppins">
    <Section1/>
    <Section2/>
    
    <Section3/> 
     
    <Section4/>
    
    <Section5/> 
    
  
    
    </div>
  )
}

