import React from 'react'
import SlideWithImage from '../components/Templates/SlideWithImage'
import S1 from '../../assets/about_s1.png'
import S2 from '../../assets/about_s2.png'
import S3 from '../../assets/about_s3.png'
import S4 from '../../assets/about_s4.png'
import S5 from '../../assets/about_s5.png'
import img1 from '../../assets/about_img1.png'
import ContSlide from '../components/About/ConstSlide'

const About = () => {

  const slideInfo1 = {
    img: S1,
    title: <div className='mt-5' >ThinkSkillIndia</div>,
    subTit: <div className="space-y-4 font-normal">
      <div className="md:text-4xl text-2xl">Creating Futuristic Skill System for India's Bright Future</div>
      <div className=''>
        ThinkSkillindia Platform will work to deliver major key objective over the next five years
      </div>

      <ol className='list-decimal pl-4 '>
        <li>Outcomes for young people </li>
        <li>Strengthen the youth equipping ot work in partnership with institution, industry and Government </li>
        <li>Future of work skills development of the youth into workforce.</li>
      </ol>
    </div>,
  };
  const slideInfo2 = {
    img: S2,
    title: "A Global Multi-Stakeholder Initiative",
    subTit: <div className="font-normal">
      <p>We are an education technology charity, proud to collaborate with Universities and Institutions with a suitable platform for exhibiting their ideas is a requisite. <br /><br />

        The platform offers young people pathways of living, learning and negotiating transitions to adulthood. It supports and defines life’s journeys for an increasingly complex and challenging world, in which they face greater levels of choice and opportunity to face the unprecedented uncertainty and risk.</p>

      <ul class={`list-disc border-l-2 my-3  py-4  pl-8 space-y-2 brGr1 text-xs md:text-sm lg:text-base`}>
        <li> Cultivate the spirit of togetherness with intellectual individuals across various Indian universities and other global institutions to achieve a common goal and create capacity for the new generation.</li>
        <li>Shaping the future of Indian Youth creating a supportive culture that values continuous learning and development.</li>
        <li>Harness those opportunities with Learning journeys that prepare youth to participate in modern communities and workplaces.</li>
        <li>Developments in youth education, employment, mental health, equality and inclusion, peace and security, and political and civic participation in order to Increase the rate of overall development of the youth.</li>
      </ul>



    </div>,
  };
  const slideInfo3 = {
    img: S3,
    title: "How does the Platform work?",
    subTit: <div className="font-normal">
      <p>As a global multi-stakeholder and solution oriented initiative supporting students and educational institutions in addressing the practical challenges  <strong>The future of work preparing youth</strong>, the Platform will:</p>

      <ul class={`list-disc border-l-2 my-3  py-4  pl-8 space-y-2 brGr2 text-xs md:text-sm lg:text-base`}>
        <li>Support and amplify ongoing national and regional processes.</li>
        <li>Identifying and discussing challenges.</li>
        <li>Sharing progress and setbacks.</li>
        <li>Exchanging on positive practices and effective implementation.</li>
        <li>Build a global network of experts and stakeholders.</li>
        <li>Mind health.</li>
        <li>Education.</li>
        <li>Create capacity building and training opportunities.</li>
        <li>Host peer learning exchanges at global, regional & national levels.</li>
        <li>Exploring opportunities for international cooperation and peer support.</li>
        <li>Advancing together in a collaborative manner.</li>
      </ul>



    </div>,
  };
  const slideInfo4 = {
    img: S4,
    title: "What is the Platform?",
    subTit: <div className="font-normal">
      <p>The platform provides a space for young people to express their skills and competencies through connection and collaboration. The key focus of youth work is to enable young people to develop holistically, working with them to facilitate their personal, social and educational development, to enable them to develop their voice, influence and place in society, and to reach their full potential. <br /><br />

        The platform provides a space for young people to express their skills and competencies through connection and collaboration. The key focus of youth work is to enable young people to develop holistically, working with them to facilitate their personal, social and educational development, to enable them to develop their voice, influence and place in society, and to reach their full potential.</p>

      <ul class={`list-disc border-l-2 my-3  py-4  pl-8 space-y-2 brGr3 text-xs md:text-sm lg:text-base`}>
        <li>E-leadership journey at university.</li>
        <li>Career, education and training, and informal learning such as e-mentoring and e-coaching.</li>
        <li>Events and open lectures to stimulate the vision of the students.</li>
        <li>Education programmes, specific training courses, or coaching.</li>
        <li>Consulting and the co-creation of knowledge.</li>
        <li>Vision for potential for innovation translating into an implementation plan.</li>
      </ul>



    </div>,
  };
  const slideInfo5 = {
    img: S5,
    title: "What is there in for You?",
    subTit: <div className="">
      The role of platform for schools and educators

      <ul class={`list-decimal border-l-2 my-3  py-4  pl-8 space-y-2 brGr4 font-normal text-xs md:text-sm lg:text-base`}>
        <li>The platform has been a major component of the drive to capability of students, institutions, and organizations. The platform will enable universities and schools to collaborate with learning providers, including professional or industry academies, chambers of commerce, coaching and consulting organizations, and publishers.</li>
        <li>Platform enables universities and schools to undergo fundamental changes to become academic-level lifelong learning and development providers for students as well as their alumni. A knowledge management system for colleges and universities paves the way for significant discoveries, elevated teaching methodologies, enhanced intellectual discussion, and a holistic educational journey.</li>
        <li>If I am learning a new skill, I will proceed to figure out the start point, endpoint, and the developmental path of transition points, so that I can get the process right from the beginning, on top of understanding it.</li>
      </ul>



    </div>,
  };




  return (
    <div>
      <SlideWithImage slideInfo={slideInfo1} contStyle={`bg-white  py-4 md:py-16 `} />
      <SlideWithImage slideInfo={slideInfo2} contStyle={`bg-white  flex-row-reverse py-4 md:py-16 `} />
      <div className='w-full'>
        <img src={img1} alt="img" />
      </div>
      <SlideWithImage slideInfo={slideInfo3} contStyle={`bg-white  py-16 `} />
      <SlideWithImage slideInfo={slideInfo4} contStyle={`bg-neutral-100  flex-row-reverse py-4 md:py-16 `} />
      <SlideWithImage slideInfo={slideInfo5} contStyle={`bg-white  py-4 md:py-16 `} />
      <ContSlide />
      <div className='md:p-24 p-8'>
        <div className="font-semibold lg:text-4xl md:text-3xl text-xl ">Looking for more?</div>
        <div className=" text-sm md:text-base">Get help with your transition to thinkskillindia, and make most of HPDSx Engine Experience</div>
      </div>
    </div>
  )
}

export default About