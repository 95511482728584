import React from "react";
import Section1 from "../../components/Collab/Section1";
import Section2 from "../../components/Collab/Section2";
import Section3 from "../../components/Collab/Section3";
import Section4 from "../../components/Collab/Section4";
import Section5 from "../../components/Collab/Section5";
import Section6 from "../../components/Collab/Section6";


export  const Collab = () => {
    return (
        <div className="flex flex-col font-poppins">
    <Section1/>
    <Section2/>
    
    <Section3/>
     
    <Section4/>
    
    <Section5/>  
        
    <Section6/>
    </div>
  )
}

