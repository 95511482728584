import React from 'react'
import Ttl_Img_Card from '../Templates/Ttl_Img_Card'

const Grid_1x3 = ({ title, data }) => {
  return (
    <div className="flex flex-col items-center justify-center py-5 lg:w-8/12 md:w-10/12 mx-auto bg-white">
      <div className="p-5 items-start text-center mx-auto">
        <div className="font-semibold text-4xl my-6 flex-wrap w-11/12">{title}</div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 lg:gap-5 gap-3 pb-10 justify-center md:w-full w-11/12">
        {data.map(d => (
          <div className=''>
            <Ttl_Img_Card img={d.img} title={d.title} subTtl={d.subTtl} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Grid_1x3