import React from "react";
import imgURL from "../../../assets/Collaboratory/sec7_img1.jpg";
import leftArrow from "../../../assets/Collaboratory/sec8_leftArrow.png";
import rightArrow from "../../../assets/Collaboratory/sec8_rightArrow.png";
import LeftImageSec8 from "./LeftImageSec8";
import RightImageSec8 from "./RightImageSec8";
import image from "../../../assets/TalentCollaboratory/section9image.png"

import platformImg1 from "../../../assets/TalentCollaboratory/PlatformImages/talentPlatform1.png"
import platformImg2 from "../../../assets/TalentCollaboratory/PlatformImages/talentPlatform2.png"
import platformImg3 from "../../../assets/TalentCollaboratory/PlatformImages/talentPlatform3.png"
import platformImg4 from "../../../assets/TalentCollaboratory/PlatformImages/talentPlatform4.png"
import platformImg5 from "../../../assets/TalentCollaboratory/PlatformImages/talentPlatform5.png"
import platformImg6 from "../../../assets/TalentCollaboratory/PlatformImages/talentPlatform6.png"
import platformImg7 from "../../../assets/TalentCollaboratory/PlatformImages/talentPlatform7.png"
import platformImg8 from "../../../assets/TalentCollaboratory/PlatformImages/talentPlatform8.png"
import platformImg9 from "../../../assets/TalentCollaboratory/PlatformImages/talentPlatform9.png"


const data1 = {
  
  item2: (
    <>
      Many systems <span className="text-yellow-400">One user experience</span>
    </>
  ),
  item3: (
    <>
     You've invested in tons of tools and technology for your people and organisation—but <br />do they all work together? The truth is, there's a massive void at the center of the <br />workforce engagement and organisational growth ecosystem because there's no centralized <br/>user experience unifying everything. Let's fill that void.
    </>
  ),
};

const data2 = [
  {
    title: (
      <span className="text-blue-500">Outstanding skill Readiness Experience</span>
    ),
    desc: (
      <>
        Digitize and easily manage your skills, who has which skills, analyzing how they are applying those skills on the job and making decisions on how you can better cultivate them through learning opportunities tracking for building workforce.
      </>
    ),
    img: platformImg4,
  },
  {
    title: (
      <span className="text-green-500">
        Organisational Readiness{" "}
      </span>
    ),
    desc: (
      <>
        From talent acquisition to operational efficiency, our comprehensive platform offers real-time insights into your team's capabilities, identifying strengths and areas for improvement.  Ensure your workforce is equipped with actionable data-driven strategies to adapt, positioning your team for success in today's dynamic business landscape.
      </>
    ),
    img: platformImg6,
  },
  {
    title: (
      <span className="text-yellow-500">
        Future work Readiness{" "}
      </span>
    ),
    desc: (
      <>
        Assess, develop, and optimize your team's readiness for emerging trends and technologies Seamlessly integrate skill assessments, competency mapping, and personalized learning pathways to ensure your employees are equipped to tackle new challenges, empowering your organisation to thrive in the fast-paced world of tomorrow's work environment
      </>
    ),
    img: platformImg3,
  },
  {
    title: (
      <span className="text-red-500">
       Career Management
      </span>
    ),
    desc: (
      <>
        Seamlessly integrate skill assessments, identify your strengths, and chart a personalized career trajectory. Utilize real-time analytics to assess your performance, understand industry trends, and receive targeted training recommendations. 
      </>
    ),
    img: platformImg5,
  },
  {
    title: (
      <span className="text-blue-500">
        Real time Leadership Development
      </span>
    ),
    desc: (
      <>
       Leverage real-time analytics to adapt leadership strategies to changing business landscapes and emerging challenges. Equip your leaders with the agility and resilience needed to drive success. Targeted coaching to nurture leadership excellence at every level of your organization
      </>
    ),
    img: platformImg8,
  },
  {
    title: (
      <span className="text-green-500">
        Future Talent Creation
      </span>
    ),
    desc: (
      <>
        Equip your organization with the tools and resources needed to foster a culture of continuous learning and talent development, ensuring a sustainable and prosperous future.Leverage predictive analytics to stay ahead of evolving skill requirements and proactively mold a workforce ready for the challenges of the future
      </>
    ),
    img: platformImg7,
  },
  {
    title: <span className="text-yellow-500">Professional Readiness</span>,
    desc: (
      <>
         To incorporate internationalization in all areas of the institution
        activity, including by developing a globally relevant curriculum,
        promoting global citizenship and collaborating on teaching, research and
        knowledge exchange with international partners.
      </>
    ),
    img: platformImg9,
  },
  {
    title: <span className="text-red-500">Global Partnership</span>,
    desc: (
      <>
       To develop and derive benefits from, local and international strategic
        partnerships and collaborations with other leading universities, the
        commercial sector, and governmental and civil society organisations,
        which are built on excellence and driven by shared academic interest in
        skill development
      </>
    ),
    img: platformImg2,
  },
  {
    title: <span className="text-blue-500">Global partnerships</span>,
    desc: (
      <>
        Global Partnership empowers stakeholders to work together towards common goals. With a focus on diversity, inclusivity, and shared values, unlock the potential for innovation and positive transformation on a global scale. a collaborative platform designed to foster impactful connections with  organizations, institutions, and individuals worldwide to leverage collective expertise and resources for sustainable solutions
      </>
    ),
    img: platformImg1,
  },
];

const Section8 = () => {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };

  return (
    <div className="w-full h-full  bg-slate-100 xl:px-20 lg:px-16 px-4 xl:py-24 lg:py-16  md:py-12 py-8">
      <div className=" text-center flex flex-col xl:gap-6 lg:gap-4 md:gap-2 gap-2">
        <div className="flex flex-col md:flex-row justify-between  items-center">
            <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">TRANSFORM LEARNING PROCESS</li>
            <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">ENGAGE PEOPLE INTEREST </li>
            <li className="text-md md:text-lg lg:text-xl xl:text-2xl ">SAVE VALUABLE TIME</li>
        </div>
        <div className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl text-blue-500 font-semibold my-2">
          {data1.item2}
        </div>
        <div className="xl:text-3xl lg:text-3xl md:text-base text-sm xl:mb-8 lg:mb-6 md:mb-4 mb-2 "style={{ lineHeight: '140%' }}>
          {data1.item3}
        </div>
        
        <div className='py-4 '>
            <img src={image} className='mx-auto '/>
        </div>
      </div>
      <div>
        {data2.map((item, index) =>
          index % 2 ? (
            <div>
              <RightImageSec8 data={item} />
              {data2.length - 1 !== index && (
                <div>
                  <img
                    src={leftArrow}
                    className="w-1/6 xl:-my-24 lg:-my-16 md:-my-8 -my-4 xl:-ml-12 lg:-ml-10 md:-ml-8 -ml-6"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col items-end">
              <LeftImageSec8 data={item} />
              {data2.length - 1 !== index && (
                <div>
                  <img
                    src={rightArrow}
                    className="xl:w-4/6 lg:w-3/5 md:w-4/6 w-3/6 xl:-my-24 lg:-my-16 md:-my-8 -my-4 xl:mr-12 lg:mr-6 md:-mr-8 -mr-2"
                  />
                </div>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Section8;
