import React from "react";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "../CreateYourOwnAssessments/Section6";

// import MoreServices from "../SME/MoreServices";

export default function Publication() {
  return (
    <div className="flex flex-col">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <div className="text-center text-stone-900 text-xl xl:text-4xl lg:text-3xl md:text-2xl  font-semibold xl:px-20 lg:px-12 md:px-8 px-4 md:px-0 py-12">
        Unlock the <span className="font-bold">potential of personalized</span>{" "}
        
        assessments <span className="font-bold">crafted</span> to your needs
        with 
        <span className="font-bold">our platform</span>.
      </div>
      <Section6 />
    </div>
  );
}
