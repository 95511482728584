import React from 'react';
import img from "../../../assets/MainMenu/Section1image.png"

const SectionExtra = () => {
  return (
    <section className="bg-white ">
      {/* Image */}
      <div className="flex justify-center">
        <img src={img} alt="Image" className="w-5/6 md:w-4/5 -mt-20 md:-mt-52" />
      </div>
    </section>
  );
};

export default SectionExtra;
