import React from 'react'
import S1 from '../../assets/create_post_s1.png'
import S2 from '../../assets/create_post_s2.png'
import S3 from '../../assets/create_post_s3.png'
import sm_img1 from '../../assets/create_post_smImg1.png'
import sm_img2 from '../../assets/create_post_smImg2.png'
import sm_img3 from '../../assets/create_post_smImg3.png'
import SlideWithImage from '../components/Templates/SlideWithImage'
import HeroSection from '../components/CreatePost/HeroSection'
import FlexSlide from '../components/CreatePost/FlexSlide'

const CreatePost = () => {

  const slideInfo1={
    img:S1,
    title: <div className=''>Become the source of inspiration for the Young talent</div>,
    subTit: <div className="font-normal text-base">Your story empowers the future, become the catalyst for young minds paving the way for their success.</div>,
  };

  const slideInfo2={
    img:S2,
    title: 'Connect and share your Success stories, interviews, experiences',
    subTit: <div className="font-normal text-base">Be part of a community where you can exchange and showcase your success stories, conduct insightful interviews, and narrate impactful experiences.</div>,
  };

  const slideInfo3={
    img:S3,
    title: 'Harness Collective Wisdom, by Seeking Ideas and validation through post Engagement',
    subTit: <div className="font-normal text-base">Every perspective matters, get your ideas validated, recieve feedback, and flourish through unique interaction on your posts.</div>,
  };

  const slideInfo4={    
    title: <div className='font-normal'>Creating your <strong className='font-semibold'>Post </strong>just takes few steps</div>,
    data: [
      {img: sm_img1, title: <div className='text-base text-black'>Decide the Topic</div>},
      {img: sm_img2, title: <div className='text-base text-black'>Create the topic</div>},
      {img: sm_img3, title: <div className='text-base text-black'>Publish the post</div>},
    ]
  };

  
  return (
    <div className=''>
      <HeroSection/>
      <SlideWithImage slideInfo={slideInfo1} contStyle ={`bg-white  md:py-20 py-7 flex-row-reverse`}/>
      <SlideWithImage slideInfo={slideInfo2} contStyle ={`bg-neutral-100  md:py-20 py-7`}/>
      <SlideWithImage slideInfo={slideInfo3} contStyle ={`bg-white md:py-20 py-7 flex-row-reverse`}/>
      <FlexSlide slideInfo={slideInfo4} contStyle ={`create_post_bg1 w-full `}/>
    </div>
  )
}

export default CreatePost