import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import backgroundImage from '../../../../assets/Articles/articleheaderbg.png';

export default function ArticleHeader() {
  return (
      <div className="w-full bg-right md:bg-top xl:h-[80vh] lg:h-[70vh] flex bg-white pl-6 xl:pl-28 xl:pr-8 lg:px-24 md:px-12" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
        <div className="w-full h-full flex flex-row text-purple-800">
          <div className="flex flex-col flex-1 py-8 xl:py-12 lg:py-16 md:py-6 lg:my-16 md:my-0 ">
            <div className="mb-2 pb-2 font-bold text-xl xl:text-5xl md:text-2xl lg:text-3xl lg:pt-1 md:pt-14 cursor-pointer">
              <div className="mb-1">
                Inspire, Engage and <br />
                 Share your <br />
                 Knowlege, where it <br />
                 matters
              </div>
            </div>
            <div className="mb-1 font-normal text-purple-800 text-sm xl:text-2xl lg:text-md md:text-xs cursor-pointer">
              <div>
                Experience the power of Insightful articles to <br />
                inspire and educate
              </div>
            </div>
            <div className=" mt-4 px-4 py-2 w-fit hover:bg-purple-800 hover:text-white cursor-pointer rounded-full border border-purple-800 hover:border-white flex items-center justify-start ">
              <div className="text-xs font-bold ">Become Guide</div>
              <FontAwesomeIcon icon={faChevronRight} className=" ml-2" />
            </div>
          </div>
        </div>
      </div>
  );
}
