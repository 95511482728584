import competition1 from "../../../assets/Competitions/Section2_Img1.jpg"
import competition2 from "../../../assets/Competitions/Section2_Img2.jpg";
import competition3 from "../../../assets/Competitions/Section2_Img3.jpg";
import competition4 from "../../../assets/Competitions/Section2_Img4.jpg";
import competition5 from "../../../assets/Competitions/Section2_Img5.jpg";
import competition6 from "../../../assets/Competitions/Section3_Img.jpg";


const section2Data = [
  {
    id: 1,
    image: competition1,
    title: "Codeforces Contests",
    prizeValue: "Rs. 5000",
    timeLeft: "1 days to go",
  },
  {
    id: 2,
    image: competition2,
    title: "ACM International Collegiate Programming Contest (ICPC)",
    prizeValue: "Rs. 5000",
    timeLeft: "2 days to go",
  },
  {
    id: 3,
    image: competition3,
    title: "Math Kangaroo",
    prizeValue: "Rs. 5000",
    timeLeft: "7 days to go",
  },
  {
    id: 4,
    image: competition4,
    title: "Google Science Fair",
    prizeValue: "Rs. 5000",
    timeLeft: "5 days to go",
  },
  {
    id: 5,
    image: competition5,
    title: "FIRST Robotics Competition",
    prizeValue: "Rs. 5000",
    timeLeft: "4 days to go",
  },
  {
    id: 6,
    image: competition6,
    title: "National Speech and Debate Association (NSDA) Competitions",
    prizeValue: "Rs. 5000",
    timeLeft: "12 days to go",
  },
  {
    id: 7,
    image: competition6,
    title: "Scholastic Art & Writing Awards",
    prizeValue: "Rs. 5000",
    timeLeft: "20 days to go",
  },
  {
    id: 8,
    image: competition6,
    title: "Global Social Venture Competition",
    prizeValue: "Rs. 5000",
    timeLeft: "2 days to go",
  },
];

export default section2Data;
