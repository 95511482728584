import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faAngleRight } from "@fortawesome/free-solid-svg-icons";
import bgimg from "../../../assets/Collaboratory/Section10imagebg.png"
const Section10 = () => {
    const handleInputChange = (e) => {
        console.log("Button pressed");
      };

  return (
    <section className="relative bg-cover bg-center py-16 px-4 md:px-0" style={{ backgroundImage: `url(${bgimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="text-center">
        <h2 className="text-xl md:text-3xl text-white  mb-4">Try Edu Collaboratory Today!</h2>
        <p className="text-2xl md:text-5xl text-white mb-8 font-bold">Witness awesomeness for Yourself</p>
        
        <div className="xl:mt-8 lg:mt-6 md:mt-4 mt-2 flex flex-col justify-center items-center">
        <div className="xl:py-4 lg:py-3 py-2 font-semibold">
          <button
            className="text-white bg-blue-500 px-6 py-3  xl:text-lg lg:text-base md:text-sm text-xs rounded-full hover:border hover:border-blue-500 hover:text-blue-500 hover:bg-transparent"
            type="submit"
            onClick={handleInputChange}
          >
            Get Started Now
            <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
          </button>
        </div>
        <div className="text-md text-white">Get Started In Less Than 60 Seconds • Cancel Anytime</div>
      </div>
      </div>
    </section>
  );
};

export default Section10;
