import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import imgURL from "../../../assets/ThinkSkillIndia/img_11.jpg";

const Section11 = () => {
  const { ref, inView } = useInView({ threshold: 0.1 });

  const data = {
    item1: <>The Next Stage of students Flourishing is here​</>,
    item2: imgURL,
  };

  return (
    <motion.div
      className="w-full flex flex-col md:flex-row h-[100vh] xl:h-[100vh] lg:h-[80vh] md:h-[70vh]  font-bold flex bg-orange-200"
      ref={ref}
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }}
      transition={{ duration: 0.5, ease: "easeIn", delay: 0.2 }}
    >
      <motion.div
        className="flex-1 flex items-center justify-center xl:text-7xl lg:text-5xl md:text-5xl text-4xl  bg-blue-500 xl:p-16 lg:p-12 md:p-8 p-4 xl:px-36 lg:px-20 md:px-16 xl:leading-tight lg:leading-10 md:leading-10 text-white"
        style={{ lineHeight: "140%" }}
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : -50 }}
        transition={{ duration: 0.5, ease: "easeIn", delay: 0.2 }}
      >
        {data.item1}
      </motion.div>
      <motion.div
        className="xl:block xl:w-[50%] lg:w-[50%] md:w-[50%] w-full xl:h-[100vh] lg:h-[80vh] md:h-[70vh] h-full"
        style={{
          backgroundImage: `url(${imgURL})`,
          backgroundSize: "cover", // Maintain original image size
          backgroundPosition: "center", // Center the image
        }}
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 50 }}
        transition={{ duration: 0.5, ease: "easeIn", delay: 0.2 }}
      ></motion.div>
    </motion.div>
  );
};

export default Section11;
