import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const LeftSideImageFlexTemplate = ({
  title,
  description,
  imageUrl,
  bgColor,
  button,
  buttonName,
}) => {
  return (
    <div
      className={`w-full h-full px-6 py-8 xl:px-28 lg:px-20 md:px-12 xl:pt-20 lg:py-16 md:py-12 bg-${bgColor} flex flex-col md:flex-row justify-start items-start gap-6 md:gap-12`}
    >
      <img
        className="w-full h-full md:w-[330px] xl:w-[500px] lg:w-[500px]  xl:h-96 lg:h-[300px] md:h-56 rounded-xl hover:shadow-2xl hover:border-gray-300"
        src={imageUrl}
        alt={title}
      />
      <div className="flex-col w-full justify-start items-start gap-4 md:pl-8">
        <div className="text-stone-900 xl:text-4xl lg:text-3xl md:text-2xl text-lg font-semibold mb-4 md:mb-8 xl:pt-12 lg:pt-8 md:pt-6 text-left cursor-pointer">
          {title}
        </div>
        <div className="text-xs text-left self-stretch text-stone-900 xl:text-lg lg:text-md md:text-xs cursor-pointer">
          {description}
        </div>
        {button && (
          <div className="flex justify-start">
            <div className="mt-4 px-4 py-2 w-fit hover:bg-blue-500 hover:text-white cursor-pointer rounded-full border border-black hover:border-white flex items-center justify-start">
              <div className="text-xs font-bold">{buttonName}</div>
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeftSideImageFlexTemplate;
