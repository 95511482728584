
import Section7Image1 from "../../../assets/About Hpds/section4Img1.png";
import Section7Image2 from "../../../assets/About Hpds/section4Img2.png";
import Section7Image3 from "../../../assets/About Hpds/section4Img3.png";
import Section7Image4 from "../../../assets/About Hpds/section4Img4.png";
import Section7Image5 from "../../../assets/About Hpds/section4Img5.png";

const dataset = [
    {
      id: 1,
      heading: "My Subjects",
      description: <>AI-based granular Skills Taxonomy and Skills Ontology to build skill-based job architecture for your organization.
      
      </>,
      image: Section7Image1,
    },
    {
      id: 2,
      heading: "My Skills",
      description: <>
      Qualitative insights to enhance your employees’ career profile, and take better decisions for your customers and partners.
      </>,
      image: Section7Image2, // Replace this with the image for HPDSX Fueling
    },
    {
      id: 3,
      heading: "My Learnings",
      description: <>Our platform leverages cutting-edge AI technology to deliver accurate, reliable, and actionable insights into the skills and abilities of candidates and employees.
      </>,
      image: Section7Image3, // Replace this with the image for HPDSX Igniting
    },
    {
      id: 4,
      heading: "My Career",
      description: <>Validation through employee self-rating, manager’s rating, data from LMS/PMS in the flow of work, AI-inferred skills intelligence and skills assessment.
      </>,
      image: Section7Image4, // Replace this with the image for HPDSX Lifting up
    },
    {
      id: 5,
      heading: "My Enterprise",
      description: <>Combine our comprehensive library for 2,500+ pre-built skills assessments and consulting for custom assessments.
      </>,
      image: Section7Image5, // Replace this with the image for HPDSX Orbiting
    },
  ];
  
  export default dataset;
  