import React from 'react'
import Btn_S1 from '../../assets/counselor_hero_img.jpg'
import Btn_S2 from '../../assets/counselor_s4.png'
import S1 from '../../assets/counselor_s1.png'
import S2 from '../../assets/counselor_s2.png'
import S3 from '../../assets/counselor_s3.png'
import gImg1 from '../../assets/counselor_gImg1.png'
import gImg2 from '../../assets/counselor_gImg2.png'
import gImg3 from '../../assets/counselor_gImg3.png'
import gImg4 from '../../assets/counselor_gImg4.png'
import gImg5 from '../../assets/counselor_gImg5.png'
import gImg6 from '../../assets/counselor_gImg6.png'
import SlideWithImage from "../components/Templates/SlideWithImage"
import HeroSection from "../components/Counselor/HeroSection"
import ImpactGrid from '../components/Counselor/ImpactGrid'

const Counselor = () => {
  const heroSectionInfo = {
    img:Btn_S1,
    title: 'Work with individuals in realizing their goal',
    subTit: <div className="font-normal lg:text-base md:text-xs">Engage in Counselor a powerful way to connect with your subject matter gurus in real time</div>,
    btn: {txt: 'Explore Counselor'}
  };

  const btnSlideInfo1 = {
    img:Btn_S2,
    title:<div className=''>Join Our Think Skill India Family as a <strong> Counselor</strong></div>,
    subTit: <div className="font-normal lg:text-base md:text-xs">Join from a wide variety of Counselor catering both the online and offline space</div>,
    btn: {txt: 'View More'}
  };

  const slideInfo1={
    img:S1,
    title: <div className=''>Help in overcoming psychological barriers and empower them in reaching new pinnacle</div>,
  };

  const slideInfo2={
    img:S2,
    title: 'Be the voice in influencing mental and career pathway for the youth',
  };

  const slideInfo3={
    img:S3,
    title: 'Terms and Conditions',
    subTit: <div className="font-normal">Periodically organized contests aimed at evaluating and enhancing your knowledge base</div>,

  };

  const data = [
    {img: gImg1, text: "Legal and Regulatory Compliance"  },
    {img: gImg2, text: "Entrepreneurship and Business"  },
    {img: gImg3, text: "Health and Wellness"  },
    {img: gImg4, text: "Career and Academic"  },
    {img: gImg5, text: "Family and Relationship"  },
    {img: gImg6, text: "Financial Advisory "  },
  ]
  return (
    <div>
      <HeroSection slideInfo={heroSectionInfo} contStyle ={`flex-col-reverse md:flex-row-reverse`}/>
      <SlideWithImage slideInfo={slideInfo1} contStyle ={`bg-neutral-100  md:py-20 py-10 flex-row-reverse`}/>
      <ImpactGrid title= {"Areas that you'll be impacting"} data={data}/>
      <SlideWithImage slideInfo={slideInfo2} contStyle ={`bg-white  md:py-20 py-7 flex-row-reverse`}/>
      <SlideWithImage slideInfo={slideInfo3} contStyle ={`bg-neutral-100 md:py-20 py-7 `}/>
      <div className='bg-neutral-100 pb-10'>

      <SlideWithImage slideInfo={btnSlideInfo1} contStyle ={`btn-white lg:w-10/12 md:w-11/12 m-auto rounded-xl pt-12 events_hero_bg flex-row-reverse text-white`}/>
      </div>
    </div>
  )
}

export default Counselor