import React from 'react';
import Slider from 'react-slick';
import quote from "../../../../assets/Blogs/Screenshot 2023-12-18 231907.png";
import section4Data from './section4Data'; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import MobileSection4 from './MobileSection4';

const Section4 = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const sliderRef = React.useRef(null);

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
     
    <div className='max-w-screen-xl xl:mx-28 lg:mx-16 md:mx-2 py-12'>
      <div className='hidden md:block'>
      <div className='text-center font-semibold xl:text-4xl lg:text-3xl md:text-2xl mx-48 pt-8 pb-16'>
        Real Success Stories: Testimonials from Our Article Contributors
      </div>

      <Slider {...sliderSettings} ref={sliderRef}>
        {section4Data.map((item) => (
          <div key={item.id}>
            <div className="grid grid-rows-4 grid-cols-12 gap-0 ">
              {/* First Column */}
              <div className="col-span-1 row-span-3">
                <div className='grid grid-rows-4'>
                  <div className='col-span-1 row-span-3 place-content-start bg-white text-center flex justify-center items-center'>
                    <div className="-rotate-90 text-black whitespace-nowrap uppercase">
                      {item.type}
                    </div>
                  </div>
                  <div className='col-span-1 row-span-1 bg-blue-600 text-left p-10 cursor-pointer' onClick={handlePrevClick}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                </div>
              </div>

              {/* Second Column */}
              <div className="col-span-3 row-span-3 relative">
                <img className='w-full h-full object-cover' src={item.img} alt='img' />
                <div className='absolute bottom-0 w-full h-[103px] z-10 opacity-60 bg-blue-600'></div>
              </div>

              {/* Third Column */}
              <div className="col-span-7 row-span-3">
                <div className='grid grid-rows-4 h-full'>
                  <div className='row-span-1 bg-white text-left uppercase font-semibold text-3xl p-4 pl-16 pt-12'>{item.name}</div>
                  <div className='row-span-3 bg-black text-center text-white text-lg mt-12'>
                    <img src={quote} alt='quote' className='bg-black text-white w-6 h-6 ml-8 mt-8'/>
                    <div className='flex justify-center items-center tracking-tighter px-6 py-8'>
                      <p className="line-clamp-5">
                        {item.story}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Fourth Column */}
              <div className="col-span-1 row-span-3">
                <div className='grid grid-rows-4 h-full'>
                  <div className='col-span-1 row-span-3 bg-white text-center text-white flex justify-center items-center'></div>
                  <div className='col-span-1 row-span-1 bg-blue-600 text-left p-10 cursor-pointer' onClick={handleNextClick}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                </div>
              </div>

              {/* Slide Number */}
              <div className='mt-12'><span className='text-4xl font-bold font-Poppis'>{item.slideNumber}</span><span className='font-bold text-xl'>/O{section4Data.length}</span></div>
            </div>
          </div>
        ))}
      </Slider>
    </div>

    <div className='md:hidden'>
        <MobileSection4 />
    </div>
    </div>
  );
};

export default Section4;
