import React from "react";
import Section1 from "../../components/Support/Section1";
import Section2 from "../../components/Support/Section2";
import Section3 from "../../components/Support/Section3";
import Section4 from "../../components/Support/Section4";



export const Support = () => {
    return (
        <div className="flex flex-col font-poppins">
     <Section1/>
    <Section2/>
    
    <Section3/>
    <Section4/>
   
    
    </div>
  )
}

