import React from 'react';
import backgroundImage from '../../../assets/Council/Section5imagebg.png'; // Import the background image

import img1 from "../../../assets/Support/rocket.png"
import img2 from "../../../assets/Support/education.png"
import img3 from "../../../assets/Support/user-manual.png"

const SectionWithCards = () => {
  return (
    <section className="relative bg-cover bg-center py-24 px-24" >
      <div className="container mx-auto px-4">
        <h2 className="text-5xl text-blue-400 font-bold mb-8 text-center">For Existing Members</h2>
        <div className="grid grid-cols-2 gap-8 py-8">
          {/* Card 1 */}
          <div className="relative bg-transparent border text-blue-400 border-blue-400  overflow-hidden group transition duration-300 hover:bg-blue-400 hover:text-white
hover:text-white
hover:border-transparent text-center px-12 ">
            <div className="p-6 
">
              <h3 className="text-3xl font-bold   mb-2 ">Students and Professionals</h3>
              <p className="  text-xl ">Log In for all users of the HPDS platform - students, alumni, employers, working professionals offering mentorship etc.</p>
            </div>
          </div>
          {/* Card 2 */}
          <div className="relative bg-transparent border text-blue-400 border-blue-400  overflow-hidden group transition duration-300 hover:bg-blue-400 hover:text-white hover:border-transparent text-center px-12 ">
            <div className="p-6 ">
              <h3 className="text-3xl font-bold   mb-2 ">HPDS Administrators</h3>
              <p className="  text-xl ">Log In for HPDS Client Administration - Institutions, Organisation etc.</p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-4 py-12">
          {/* Card 1 */}
          <div className="col-span-4">
            <div className="flex flex-col items-center justify-center p-8 bg-white rounded-lg shadow-lg border-black border-[2px] text-center">
              <img src={img1} alt="Card Image" className="w-16 h-16 mb-4" />
              <h2 className="text-xl font-bold mb-2 text-blue-500">Get Started</h2>
              <p className="text-lg text-gray-600"style={{ lineHeight: '140%' }}>Onboarding, Expert Asisted help, and learn the basics.</p>
            </div>
          </div>

          {/* Card 2 */}
          <div className="col-span-4">
            <div className="flex flex-col items-center justify-center p-8 bg-white rounded-lg shadow-lg border-black border-[2px] text-center">
              <img src={img2} alt="Card Image" className="w-16 h-16 mb-4" />
              <h2 className="text-xl font-bold mb-2 text-green-500">Tutorials</h2>
              <p className="text-lg text-gray-600"style={{ lineHeight: '140%' }}>Onboarding, Expert Asisted help, and learn the basics.</p>
            </div>
          </div>

          {/* Card 3 */}
          <div className="col-span-4">
            <div className="flex flex-col items-center justify-center p-8 bg-white rounded-lg shadow-lg border-black border-[2px] text-center">
              <img src={img3} alt="Card Image" className="w-16 h-16 mb-4" />
              <h2 className="text-xl font-bold mb-2 text-yellow-500">User Guide</h2>
              <p className="text-lg text-gray-600"style={{ lineHeight: '140%' }}>Onboarding, Expert Asisted help, and learn the basics.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionWithCards;
