import React from "react";
import brandData from "./fifthSectioData";

const FifthSection = () => {
  return (
    <div className="max-w-screen-xl mx-6 xl:mx-28 lg:mx-24 md:mx-16 py-6 md:py-12 bg-white">
      <div className="text-2xl font-semibold font-Poppins pb-8 ">
        Create your own Brand
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6">
        {brandData.map((item) => (
          <div
            key={item.id}
            className="flex flex-col justify-start items-start"
          >
            <img
              className="h-full w-full xl:w-full xl:h-full lg:w-64 lg:h-48 md:w-full md:h-full object-cover rounded-lg hover:shadow-2xl"
              src={item.imageUrl}
              alt={item.title}
            />
            <div className="text-stone-900 xl:text-xl lg:text-lg md:text-sm font-semibold font-Poppins cursor-pointer">
              {item.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FifthSection;
