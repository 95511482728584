import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const HeroSection = ({slideInfo, contStyle }) => {
  return (
    <div className={`flex justify-between ${contStyle} md:py-[5vw]`}>
      <div className='w-full md:w-2/5 rounded-lg'>
        <img src={slideInfo.img} alt='' className=''  />
      </div>
      <div className="py-5 px-[5vw] items-start w-full md:w-1/2 ">
        {slideInfo.icon && (
          <div className='w-14 h-14'>
            <img src={slideInfo.icon} alt='' className='' />
          </div>
        )}
        <div className="font-semibold lg:text-4xl md:text-3xl my-4">{slideInfo.title}</div>
        <div className="text-sm font-semibold">{slideInfo.subTit}</div>
        {slideInfo.btn && (
          <button className='py-1 px-2 md:px-4 rounded-full my-4 border border-black border-solid text-xs md:text-sm hover:bg-neutral-100 hover:shadow-md'  onClick={slideInfo.btn.function}>
            {slideInfo.btn.txt} <FontAwesomeIcon icon={faAngleRight} />
          </button>
        )}
      </div>
    </div>
  )
}

export default HeroSection