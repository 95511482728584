import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import section4Data from './section4Data';
import quote from "../../../../assets/Blogs/quote.jpg";


const MobileSection4 = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "8%",
    arrows: false,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };

  return (
    <div className="w-full h-full py-2 bg-white">
      <div className='text-2xl text-center font-semibold mb-6 px-6'>
        Real Success Stories: Testimonials from Our Article Contributors
      </div>
      <Slider {...sliderSettings}>
        {section4Data.map((testimonial, index) => (
          <div key={index} className="h-full flex flex-col gap-0 ml-6">
            <div className={`w-72 h-96 overflow-hidden`}>
              <div className={`w-64 rounded-t-md h-24 bg-stone-900 flex-col justify-start items-start inline-flex`}>
                <div className='grid grid-cols-12'>
                  <div className='col-span-4'>
                    <img className='mx-3 my-4 w-16 h-16 object-fill rounded-full' src={testimonial.img2} alt='img' />
                  </div>
                  <div className='col-span-8'>
                    <div className='flex flex-col text-white mx-1 my-6'>
                      <div className="text-xs tracking-tighter">{testimonial.type}</div>
                      <div className='text-lg'>{testimonial.name}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`w-64 h-96 bg-neutral-100 px-2 py-1`}>
                <div className='flex flex-col'>
                  <img src={quote} alt='quote' className='bg-black text-white w-6 h-6 ml-1' />
                  <div className='flex justify-center text-xs items-center'>
                      {testimonial.story}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className='flex flex-row justify-center mt-4'>
      <div className="flex justify-center items-center gap-2">
        {section4Data.map((_, dotIndex) => (
          <div
            key={dotIndex}
            className={`w-2.5 h-2.5 rounded-full ${currentSlide === dotIndex ? 'bg-blue-700' : 'bg-neutral-300'}`}
          />
        ))}
        <div className='absolute right-0 mr-3'>
         <div className="flex items-end gap-1 inline-flex mb-1">
        <div className="text-neutral-800 text-xl font-bold">{section4Data[currentSlide].slideNumber}</div>
        <div className="text-neutral-800 text-sm font-bold">/ O{section4Data.length}</div>
      </div>
      </div>
    </div>
      </div>

     
    </div>
  );
};

export default MobileSection4;


