import React from "react";
import LandingCover from "../../components/Mentor/LandingCover";
import MentorSection1 from "../../components/Mentor/MentorSection1";
import MentorSection2 from "../../components/Mentor/MentorSection2";
import MentorSection3 from "../../components/Mentor/MentorSection3";
import MenntorSection4 from "../../components/Mentor/MentorSection4";

export default function Scientist() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <MentorSection1 />
      <MentorSection2 />
      <MentorSection3 />
      <MenntorSection4 />
    </div>
  );
}
