const dataset = [
    {
      id: 1,
      description: <>A model of new economy and societal framework in opportunities</>,
    },
    {
      id: 2,
      description: <>Living standards and prosperity to country specializing what it does best</>,
    },
    {
      id: 3,
      description: <>Productiveness and competitiveness leading to knowledge-based economy and finally</>,
    },
    {
      id: 4,
      description: <>Skills and globalisation of knowledge in 21st Century.</>,
    },
  ];
  
  export default dataset;
  