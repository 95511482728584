import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import imageToImport from '../../../assets/ChangeAgent/Section10image1.png';
import Section10image2 from '../../../assets/ChangeAgent/Section10image2.png';

const Section10 = () => {
    const isSmallScreen = useMediaQuery({ maxWidth: 760 }); 
    const isMediumScreen = useMediaQuery({ minWidth: 768 }); 
    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView) {
            // Start animation when the section is in view
            console.log('Section 10 is in view!');
        }
    }, [inView]);

    return (
        <section ref={ref} className="py-8 md:py-12 lg:py-32">
            <div className="">
                <motion.h2
                    initial={{ opacity: 0, y: 105 }}
                    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 105 }}
                    transition={{ ease: 'easeInOut', duration: 1.7 , delay: 0.5 }}
                    className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl  font-bold text-center mb-8 "
                >
                    <span className='mx-4'><FontAwesomeIcon icon={faBagShopping} style={{color: "#19191a"}} /></span>Your <span className=''  style={{ color: 'rgb(0, 134, 232)' }}>Business</span>
                </motion.h2>
                <div className="grid grid-cols-12 gap-2 md:gap-4 mx-4 md:mx-32 justify-center py-10">
                    {/* Left section */}
                    <motion.div
                        className="col-span-6 w-full md:pl-2 lg:pl-12 justify-end"
                        initial={{ opacity: 0, y: 105 }}
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 105 }}
                        transition={{ ease: 'easeInOut', duration: 1.7 , delay: 0.5}}
                    >
                        <p className="md:text-xl lg:text-2xl xl:text-3xl font-medium" style={{ lineHeight: '140%' }}>Defining and making the impossible possible as a catalyst driving individuals' life, business and economies around the world higher expected returns</p>
                    </motion.div>
                    {/* Right section */}
                    <motion.div
                        className="col-span-6 w-full md:pl-2 lg:pl-12 "
                        initial={{ opacity: 0, y: 105 }}
                        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 105 }}
                        transition={{ ease: 'easeInOut', duration: 1.7 , delay: 0.5 }}
                    >
                        <p className="md:text-xl lg:text-2xl xl:text-3xl font-medium" style={{ lineHeight: '140%' }}>It may be that our task is not only to prepare Human to “fit into the future” but to shape it. “…If the complex questions of the future are to be determined… by human beings…making one choice rather than another</p>
                    </motion.div>
                </div>
                <motion.div
                    initial={{ opacity: 0, y: 105 }}
                    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 105 }}
                    transition={{ ease: 'easeInOut', duration: 1.7 , delay: 0.5 }}
                    className="text-center mt-4 md:mt-8"
                >
                    {isMediumScreen && (
                        <motion.img
                            src={imageToImport}
                            alt="Medium Screen Image"
                            className="w-full h-full"
                            initial={{ scale: 0.9 }}
                            animate={inView ? { scale: 1 } : { scale: 0.7}}
                            transition={{ ease: 'easeInOut', duration: 1.7 , delay: 0.5 }}
                        />
                    )}
                    {isSmallScreen && (
                        <motion.img
                            src={Section10image2}
                            alt="Small Screen Image"
                            className="w-full h-full"
                            initial={{ scale: 0.9 }}
                            animate={inView ? { scale: 1 } : { scale: 0.7 }}
                            transition={{ ease: 'easeInOut', duration: 1.7 , delay: 0.5 }}
                        />
                    )}
                </motion.div>
            </div>
        </section>
    );
};

export default Section10;
