import React from "react";
import platformDataSet from "../DataSet/platformDataset";
import RightImageGrid from "../Templates/RightImageGrid";

const MoreServices = () => {
  const data = platformDataSet[4];

  return (
    <div>
      <RightImageGrid
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
        linkColor={data.linkColor}
      />
    </div>
  );
};

export default MoreServices;
