import React from 'react';
import backgroundImage from '../../../assets/TalentCollaboratory/Section6imagebg.png'; // Import the background image
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";


const Section6 = () => {
    return (
        <section className="relative bg-cover bg-center py-20 px-4 md:px-8 lg:px-16 xl:px-24" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className=" ">
                <h2 className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold mb-4 text-white text-center py-4 md:text-justify" style={{ lineHeight: '140%' }}>MANY STUDENTS ENTER COLLEGE IN WHAT TO AS A STATE OF “IGNORANT CERTAINTY,” BELIEVING THAT KNOWLEDGE IS CERTAIN, BELIEFS ARE EITHER RIGHT OR WRONG, THE AUTHORITIES (E.G., THEIR PROFESSORS AND THE AUTHORS OF THEIR TEXTBOOKS) HAVE THE ANSWERS, AND THEIR JOB IS TO MEMORIZE THOSE ANSWERS AND REPEAT THEM ON TESTS.</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {/* Card 1 with 2 columns */}
                    <div className="col-span-1 bg-white  overflow-hidden">
                        <div className="flex flex-col md:flex-row">
                            <div className="w-full md:w-1/2">
                                <img src="path_to_image1" alt="Image 1" className="w-full h-auto" />
                            </div>
                            <div className="w-full md:w-1/2 p-4 h-full md:h-[50vh] lg:h-[44vh] xl:h-[41vh] " style={{ backgroundColor: 'rgb(92, 91, 183)' }}>

                                <div className="text-white text-sm md:text-md lg:text-md xl:text-xl px-2 md:px-2 lg:px-3 xl:px-4 "><div className='mb-4'><FontAwesomeIcon icon={faPen} rotation={270} style={{ color: "#6f767b", }} /></div>Address the transitional needs of students from K12 to Professional Degree a pre- and early-semester academic transition support programs
                                </div >
                            </div>
                        </div>
                    </div>

                    {/* Card 2 with 1 column */}
                    <div className=" col-span-1 bg-white  overflow-hidden">
                        <div className="flex flex-col md:flex-row">
                            <div className="w-full md:w-1/2">
                                <img src="path_to_image1" alt="Image 1" className="w-full h-auto" />
                            </div>
                            <div className="w-full md:w-1/2 p-4 h-full md:h-[50vh] lg:h-[44vh] xl:h-[41vh] " style={{ backgroundColor: 'rgb(165, 60, 3)' }}>

                                <div className="text-white text-sm md:text-md lg:text-md xl:text-xl px-2 md:px-2 lg:px-3 xl:px-4 "><div className='mb-4'><FontAwesomeIcon icon={faPen} rotation={270} style={{ color: "#6f767b", }} /></div>Further  competencies in Academic skill development programs with student support services
                                </div >
                            </div>
                        </div>
                    </div>

                    {/* Repeat similar structure for other cards */}
                    <div className="col-span-1 bg-white  overflow-hidden">
                        <div className="flex flex-col md:flex-row">
                            <div className="w-full md:w-1/2">
                                <img src="path_to_image1" alt="Image 1" className="w-full h-auto" />
                            </div>
                            <div className="w-full md:w-1/2 p-4 h-full md:h-[45vh] lg:h-[40vh] xl:h-[41vh] " style={{ backgroundColor: 'rgb(60, 110, 86)' }}>

                                <div className="text-white text-sm md:text-md lg:text-md xl:text-xl px-2 md:px-2 lg:px-3 xl:px-4 "><div className='mb-4'><FontAwesomeIcon icon={faPen} rotation={270} style={{ color: "#6f767b", }} /></div>Professional Guidance for the students in their Career-Path development through Career Counseling and grooming  programs
                                </div >
                            </div>
                        </div>
                    </div>


                    <div className="col-span-1 bg-white  overflow-hidden">
                        <div className="flex flex-col md:flex-row">
                            <div className="w-full md:w-1/2">
                                <img src="path_to_image1" alt="Image 1" className="w-full h-auto" />
                            </div>
                            <div className="w-full md:w-1/2 p-4 h-full md:h-[45vh] lg:h-[40vh] xl:h-[41vh]" style={{ backgroundColor: 'rgb(231, 31, 169)' }}>

                                <div className="text-white text-sm md:text-md lg:text-md xl:text-xl px-2 md:px-2 lg:px-3 xl:px-4 "><div className='mb-4'><FontAwesomeIcon icon={faPen} rotation={270} style={{ color: "#6f767b", }} /></div>Build employability and job skill supporting through tutoring, coaching and guidance
                                </div >
                            </div>
                        </div>
                    </div>


                    <div className="col-span-1 bg-white  overflow-hidden">
                        <div className="flex flex-col md:flex-row">
                            <div className="w-full md:w-1/2">
                                <img src="path_to_image1" alt="Image 1" className="w-full h-auto" />
                            </div>
                            <div className="w-full md:w-1/2 p-4 h-full md:h-[50vh] lg:h-[40vh] xl:h-[41vh] " style={{ backgroundColor: 'rgb(165, 60, 3)' }}>

                                <div className="text-white text-sm md:text-md lg:text-md xl:text-xl px-2 md:px-2 lg:px-3 xl:px-4 "><div className='mb-4'><FontAwesomeIcon icon={faPen} rotation={270} style={{ color: "#6f767b", }} /></div>Building specific and generic competencies through providing offline and online programs on  assessments, social learning, project based learning, action learning and CBT e-Learning</div >
                            </div>
                        </div>
                    </div>


                    <div className="col-span-1 bg-white  overflow-hidden">
                        <div className="flex flex-col md:flex-row">
                            <div className="w-full md:w-1/2">
                                <img src="path_to_image1" alt="Image 1" className="w-full h-auto" />
                            </div>
                            <div className="w-full md:w-1/2 p-4 h-full md:h-[50vh] lg:h-[40vh] xl:h-[41vh] " style={{ backgroundColor: 'rgb(60, 110, 86)' }}>

                                <div className="text-white text-sm md:text-md lg:text-md xl:text-xl px-2 md:px-2 lg:px-3 xl:px-4 "><div className='mb-4'><FontAwesomeIcon icon={faPen} rotation={270} style={{ color: "#6f767b", }} /></div>Promote mental health, emotional resilience and wellness through Counseling and Psychological programs in all the years at institutions</div >
                            </div>
                        </div>
                    </div>



                </div>

                <h2 className="text-2xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold mb-4 text-white text-center py-8 md:text-justify"style={{ lineHeight: '140%' }}>MANY STUDENTS ENTER COLLEGE IN WHAT TO AS A STATE OF “IGNORANT CERTAINTY,” BELIEVING THAT KNOWLEDGE IS CERTAIN, BELIEFS ARE EITHER RIGHT OR WRONG, THE AUTHORITIES (E.G., THEIR PROFESSORS AND THE AUTHORS OF THEIR TEXTBOOKS) HAVE THE ANSWERS, AND THEIR JOB IS TO MEMORIZE THOSE ANSWERS AND REPEAT THEM ON TESTS.</h2>
            </div>
        </section>
    );
};

export default Section6;
