import React from "react";
import ShadowCenterCardTemplate from "../../Templates/ShadowCenterCardTemplate";
import blogSecondData from "./blogData"

const BlogSecond = () => {
  return (
    <ShadowCenterCardTemplate
      title="Nurturing Potential through Blogging"
      data={blogSecondData}
      showUnderlineStyle={true} // Set to true to show the underline style, or false to hide
      underlineTop={-4.6}
      responsiveLeftXL="135px" // Set the desired left value for extra-large screens (xl)
      responsiveLeftLG="120px" // Set the desired left value for large screens (lg)
      responsiveLeftMD="100px" // Set the desired left value for medium screens (md)
    />
  );
};

export default BlogSecond;