import React from "react";
import { useState } from "react";
import dataSet3 from "./section3Dataset.js";
import useScreenSize from "../../components/Contexts/ScreenSizeContent";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const bgImageUrl =
    'url(" https://cdn-dynmedia-1.microsoft.com/is/image/microsoftcorp/Desktop-Product-Services-BG-2x?resMode=sharp2&op_usm=1.5,0.65,15,0&wid=3200&hei=2258&qlt=100&fmt=png-alpha&fit=constrain")';

function ProductsAndServices() {

    const screenWidth = window.innerWidth;

    const settings = {
        slidesToShow: screenWidth < 1023 ? 2 : 6, // Show 2 items for screen sizes less than 1023px, otherwise show 6 items
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const [activeIndex, setactiveIndex] = useState(0);
    const screenSize = useScreenSize();
    const [activeButtonIndex, setactiveButtonIndex] = useState(null);
    const [gridOpacity, setGridOpacity] = useState(1);

    //TabClickAnimation
    const handleTabClick = (idx) => {
        // Set opacity to 0 when a menu button is clicked

        setTimeout(() => {
            setGridOpacity(0);
        }, 100);

        setactiveButtonIndex(null);

        setTimeout(() => {
            setactiveIndex(idx);
            setGridOpacity(1); // Set opacity back to 1 after a short delay (you can adjust the delay)
        }, 400);
    };

    const handleButtonClick = (idx) => {
        setactiveButtonIndex(idx);
    };

    return (
        <div
            className="h-auto bg-gray-300"
            style={{
                
                backgroundSize: "cover", // You can customize the background size
                backgroundRepeat: "no-repeat", // You can customize the background repeat
                width: "100%",
                backgroundPosition: "center",
            }}
        >
            {screenSize === "sm" || screenSize === "md" ? (
                <div className="max-w-screen-xl h-full px-6 mx-auto py-28 ">
                    


                    <div
                        className="flex flex-col h-full w-full transition-opacity duration-500 ease-in-out"
                        style={{ opacity: gridOpacity }}
                    >
                        <div
                            className=" rounded-2xl w-full overflow-hidden"
                            style={{
                                
                                backgroundSize: "cover", // You can customize the background size
                                backgroundRepeat: "no-repeat", // You can customize the background repeat
                                width: "100%",
                                //   height: "80%", // Set a specific height, or adjust as needed
                            }}
                        >
                            {/* <h1>This is the left portion {activeIndex} </h1> */}
                            <div className="flex flex-col justify-start items-start text-white p-2  bg-gray-300 opacity-80 h-full w-full ">
                                <h1 className="font-semibold text-3xl text-black">
                                    {dataSet3[activeIndex].bannerTitle}
                                </h1>
                                <p className="text-lg my-6 text-black">{dataSet3[activeIndex].bannerDec}</p>

                            </div>
                        </div>
                        <div className="h-2/3 w-full  ">
                            <div className="grid grid-cols-1 gap-5 my-5 h-full w-full ">
                                {dataSet3[activeIndex].items.map((item, index) => (
                                    <a
                                        // href=""
                                        className="border border-gray-300 rounded-2xl  shadow-md hover:shadow-lg transition duration-300 hover:underline hover:color-blue-600"
                                    >
                                        <div className="flex flex-col justify-between items-start bg-white  h-full p-6 active:bg-purple-50">
                                            <div>
                                                
                                                <img
                                                    src={item.itemImg}
                                                    alt={item.name}
                                                    className="w-14 object-fit  rounded-md  border-blue-100 bg-white"
                                                />
                                                <p className="font-semibold text-xl my-4">
                                                    {item.itemTitle}
                                                </p>
                                                <h1>{item.itemDesc}</h1>
                                            </div>

                                            <button
                                                 className={`mt-4 text-black focus:outline-none border border-[1px] border-black  rounded-2xl px-2 py-[1px] text-sm font-semibold hover:bg-blue-400 hover:border-[1px] hover:scale-110 ${activeButtonIndex === index
                                                    ? "border-black"
                                                    : "border-black"
                                                    }`}
                                                key={index}
                                                onClick={() => handleButtonClick(index)}
                                            >
                                                Read More
                                            </button>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="max-w-screen-2xl h-full px-20 mx-auto py-32 ">
                    

                    <div
                        className="grid grid-cols-4 h-4/5 w-full gap-5 transition-opacity duration-500 ease-in-out"
                        style={{ opacity: gridOpacity }}
                    >
                        <div
                            className="col-span-1 rounded-2xl h-full w-full overflow-hidden"
                            style={{
                                
                                backgroundSize: "cover", // You can customize the background size
                                backgroundRepeat: "no-repeat", // You can customize the background repeat
                                width: "100%",
                                //   height: "80%", // Set a specific height, or adjust as needed
                            }}
                        >
                            {/* <h1>This is the left portion {activeIndex} </h1> */}
                            <div className="flex flex-col justify-start items-start text-white p-6  bg-gray-300 opacity-100 h-full w-full ">
                                <h1 className="font-semibold text-3xl text-black">
                                    {dataSet3[activeIndex].bannerTitle}
                                </h1>
                                <p className="text-xl my-6 text-black">{dataSet3[activeIndex].bannerDec}</p>

                            </div>
                        </div>
                        <div className="col-span-3  ">
                            <div className="grid grid-cols-3 gap-5 h-full w-full ">
                                {dataSet3[activeIndex].items.map((item, index) => (
                                    <a
                                        // href=""
                                        className="border border-gray-300 rounded-2xl  shadow-md hover:shadow-lg transition duration-300 hover:underline hover:color-blue-600"
                                    >
                                        <div className="flex flex-col justify-between items-start bg-white  h-full p-6 active:bg-purple-50">
                                            <div>
                                                
                                                <img
                                                    src={item.itemImg}
                                                    alt={item.name}
                                                    className="w-20 object-fit  rounded-md  border-blue-100 bg-white"
                                                />
                                                <p className="font-semibold text-2xl my-4">
                                                    {item.itemTitle}
                                                </p>
                                                <h1>{item.itemDesc}</h1>
                                            </div>

                                            <button
                                                className={`mt-4 text-black focus:outline-none border border-[1px] border-black  rounded-2xl px-2 py-[1px] text-sm font-semibold hover:bg-blue-400 hover:border-[1px] hover:scale-110 ${activeButtonIndex === index
                                                    ? "border-black"
                                                    : "border-black"
                                                    }`}
                                                key={index}
                                                onClick={() => handleButtonClick(index)}
                                            >
                                                Read More
                                            </button>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProductsAndServices;
