import React from "react";
import LeftSideImage from "../Templates/LeftSideImage";
import smmain from "../../../assets/Publication/Section1_image.png";

const Section1 = () => {
  const data = {
    title: (
      <>
        <span className="font-bold">Connect</span> &{" "}
        <span className="font-bold">Create</span>
      </>
    ),
    description: (
      <>
        Enhance your storytelling journey with professionally crafted content
        for your magazines and activity books for the students.Join us on a
        remarkable journey through school, where the possibilities are endless
        and the adventures are boundless.
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <LeftSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section1;
