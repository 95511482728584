import React, { useState } from 'react';

import Section4Button1 from '../../../assets/HPDSxEngine/Section4Button1.png';
import Section4Button2 from '../../../assets/HPDSxEngine/Section4Button2.png';
import Section4Button3 from '../../../assets/HPDSxEngine/Section4Button3.png';

import DatasetSec4 from './DatasetSec9';

// Import the background image
import backgroundImage from "../../../assets/Council/Section2imagebg.png";

const Section14 = () => {
    const [activeDataset, setActiveDataset] = useState(DatasetSec4[0]); // Set the first dataset as active by default

    const handleButtonClick = (id) => {
      const dataset = DatasetSec4.find(item => item.id === id);
      setActiveDataset(dataset);
    };
  
    // Dataset for the buttons
    const buttonDatasets = [
      { id: 1, image: Section4Button1, title: 'Global Career Trajectory' },
      { id: 2, image: Section4Button2, title: 'Innovative Collaboration Culture' },
    ];

  return (
    <section className="py-20 bg-cover bg-center" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className=" ">
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-white text-center py-10 md:py-12 px-4 md:px-24 lg:px-40">How can you create an impact  beyond internship</h2>
        <div className='text-md md:text-lg lg:text-xl xl:text-2xl text-center px-4 md:px-12 lg:px-24 xl:px-32 text-white pb-8'>We’re driven to create positive progress for our clients, people and society. During your time with us you'll have opportunities to make an impact beyond your day-to-day role - including taking part in our volunteering initiatives, which you can easily create Volunteers Hub.</div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-8 md:mt-12 lg:mt-6 xl:mt-4 bg-white  mx-4 md:mx-12 lg:mx-20 xl:mx-80">
        <div className="col-span-12 md:col-span-6 flex flex-row justify-around md:p-0 lg:p-2 rounded-xl">
          {buttonDatasets.map(button => (
            <div key={button.id} className="col-span-12 md:col-span-6">
              <button onClick={() => handleButtonClick(button.id)} className={`text-center md:text-sm lg:text-sm hover:bg-blue-300 text-black font-bold py-3 px-8 rounded flex items-center justify-center border-r-2 ${activeDataset.id === button.id ? 'bg-blue-300 text-white border-transparent' : ''}`}>
                <img src={button.image} alt="Image Description" className='mr-2' />
                {button.title}
              </button>
            </div>
          ))}
        </div>
      </div>

      {activeDataset && (
        <div className="col-span-12 bg-white rounded-xl p-4 md:p-4 lg:p-6 my-6 md:my-8 lg:my-12 xl:my-20 mx-4 md:mx-12 lg:mx-20 xl:mx-40">
          <div className="grid grid-cols-12 gap-4 py-4 md:py-6 lg:py-8 xl:py-12 " style={{ lineHeight: '140%' }}>
            {/* Left section */}
            <div className="col-span-12 md:col-span-6">
              <h2 className="md:text-2xl lg:text-3xl xl:text-4xl font-bold mb-4 text-justify" >{activeDataset.heading}</h2>
              <p className="md:text-lg lg:text-xl xl:text-2xl text-gray-600 mb-4 py-8 text-justify">{activeDataset.description}</p>
            </div>
            {/* Right section */}
            <div className="col-span-12 md:col-span-6 flex md:items-center md:justify-center lg:justify-center lg:items-start xl:items-center xl:justify-center">
              <img src={activeDataset.rightimage} alt="Image" className="w-full md:w-4/5 lg:w-full  rounded-lg" />
            </div>
          </div>
        </div>
      )}
      </div>
    </section>
  );
};

export default Section14;
