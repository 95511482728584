import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import skillData from "./skillData";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Skill = () => {
  const sliderRef = useRef(null);
  const [slidesToShow, setSlidesToShow] = useState(4);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToScroll: 1,
    slidesToShow: slidesToShow,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1160) {
        setSlidesToShow(4); // xl or more
      } else if (window.innerWidth >= 1024) {
        setSlidesToShow(3); // lg
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(3); // md
      } else {
        setSlidesToShow(2); // sm
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMoveLeft = () => {
    if (sliderRef.current && currentSlide > 0) {
      sliderRef.current.slickPrev();
    }
  };

  const handleMoveRight = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className="max-w-screen-xl mx-6 xl:mx-28 lg:mx-24 md:mx-16 py-6 md:py-12 relative">
      <div className="text-2xl font-semibold font-Poppins pb-4 md:pb-8">
        Know your Skills
      </div>
      {currentSlide > 0 && (
        <div className="xl:-ml-2 absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
          <div
            className="lg:w-12 lg:h-12 md:w-10 md:h-10 w-8 h-8 rounded-full flex items-center justify-center bg-white hover:border border-gray-400 shadow-lg hover:shadow-2xl cursor-pointer text-black"
            onClick={handleMoveLeft}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
          </div>
        </div>
      )}
      <Slider {...settings} className="flex w-full" ref={sliderRef}>
        {skillData.map((skill) => (
          <div key={skill.id} className="w-full outline-none focus:outline-none">
            <div className="flex-col justify-center items-center mx-4">
              <img
                className="w-32 h-32 xl:w-full xl:h-full lg:w-56 lg:h-56 md:h-48 md:w-48 rounded-full"
                src={skill.imageUrl}
                alt={skill.name}
              />
              <div className="text-center text-black xl:text-xl lg:text-lg md:text-sm font-semibold font-['Segoe UI']">
                {skill.name}
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
        <div
          className="lg:w-12 lg:h-12 md:w-10 md:h-10 w-8 h-8 bg-white rounded-full flex items-center justify-center hover:border border-gray-400 shadow-lg hover:shadow-2xl cursor-pointer text-black"
          onClick={handleMoveRight}
        >
          <FontAwesomeIcon icon={faChevronRight} className="text-lg" />
        </div>
      </div>
    </div>
  );
};

export default Skill;

