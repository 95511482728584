import React from 'react';
import backgroundImage from "../../../assets/Speaker/Section2_bgimg.jpg"; // Replace with your actual background image path
import sideImage from '../../../assets/Speaker/Section2_sideimg.jpg'; // Replace with your actual image path

const MyComponent = () => {
  const containerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100vh',
  };

  return (
    <div style={containerStyle} className="xl:flex xl:flex-row lg:flex lg:flex-row md:flex md:flex-row flex flex-col-reverse items-center justify-center p-8 xl:gap-32 lg:gap-16 ">
      {/* Left Section */}
      <div className="flex flex-col justify-center  ">
        
        <div className="text-white pl:12 mr-4 ">
          <div className='xl:text-4xl lg:text-3xl md:text-2xl font-medium mb-8 '>Build a community revolving around shared interests and values</div>
          <div className='xl:text-2xl lg:text-xl md:text-lg '>Attract individuals who resonate with your message,forming a  community around shared  interests, beliefs, or values</div>
        </div>
      </div>

      {/* Right Section */}
      <div className=" xl:pr-16 lg:pr-2 xl:ml-4 lg:ml-8 mb-16">
        <img src={sideImage} alt="Sample" className="xl:w-full xl:h-full lg:h-full lg:w-full md:h-72 md:w-72  h-64 w-64 object-cover rounded-xl" />
      </div>
    </div>
  );
};

export default MyComponent;
