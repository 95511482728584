import React from 'react'
import icon1 from '../../assets/icon5.png'

import S1 from '../../assets/start_chap_s1.jpg'
import S2 from '../../assets/start_chap_s2.png'
import sm_img1 from '../../assets/start_chap_smImg1.png'
import sm_img2 from '../../assets/start_chap_smImg2.png'
import sm_img3 from '../../assets/start_chap_smImg3.png'
import sm_img4 from '../../assets/start_chap_smImg4.png'
import SlideWithImage from '../components/Templates/SlideWithImage'
import HeroSection from '../components/StartChapter/HeroSection'
import FlexSlide from '../components/StartChapter/FlexSlide'

const StartChapter = () => {

  const slideInfo1={
    img:S1,
    title: <div className=''>Catalyze Strategic alliances</div>,
    subTit: <div className="font-normal">Create synergistic alliances with businesses and academia, promoting knowledge exchange and enhancing educational opportunities</div>,
  };

  const slideInfo2={
    img:S2,
    title: 'Forge a strong Alumni network, fostering opportunities for Emerging talent',
    subTit: <div className="font-normal">Nurture a dynamic alumni ecosystem, fostering pathways for emerging talent to access diverse opportunities</div>,
  };



  const slideInfo4={    
    title: <div className='font-normal'>Publishing your <strong className='font-semibold'>Chapter </strong>just takes few steps</div>, 
    icon: icon1,
    data: [
   
      {img: sm_img1, title: <div className='text-base text-black'>Select chapter location</div>},
      {img: sm_img2, title: <div className='text-base text-black'>Create commitee to manage the chapter</div>},
      {img: sm_img3, title: <div className='text-base text-black'>Define the purpose of Chapter</div>},
      {img: sm_img4, title: <div className='text-base text-black'>Start the chapter</div>},
    ]
  };

  
  return (
    <div>
      <HeroSection/>
      <SlideWithImage slideInfo={slideInfo1} contStyle ={`bg-white  md:py-20 py-7 flex-row-reverse`}/>
      <SlideWithImage slideInfo={slideInfo2} contStyle ={`bg-white  md:py-20 py-7`}/>
      <FlexSlide slideInfo={slideInfo4} contStyle ={`start_chap_bg2 w-full`}/>
    </div>
  )
}

export default StartChapter