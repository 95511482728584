import expert1 from '../../../../assets/Learn&Grow/expert1.png';
import expert2 from '../../../../assets/Learn&Grow/expert2.png';
import expert3 from '../../../../assets/Learn&Grow/expert3.png';


const expertData = [
    {
      id: 1,
      title: "Academic Competence",
      imageUrl: `${expert2}`,
    },
    {
      id: 2,
      title: "Employability Competence",
      imageUrl: `${expert1}`,
    },
    {
      id: 3,
      title: "Emotional Competence",
      imageUrl: `${expert3}`,
    },
    {
        id: 4,
        title: "Academic Competence",
        imageUrl: `${expert2}`,
      },
      {
        id: 5,
        title: "Academic Competence",
        imageUrl: `${expert1}`,
      },
      {
        id: 6,
        title: "Academic Competence",
        imageUrl: `${expert3}`,
      },
      {
        id: 7,
        title: "Academic Competence",
        imageUrl: `${expert2}`,
      },
  ];
  export default expertData;