import React from "react";
import BlogHeader from "../components/Posts/Blogs/BlogHeader";
import Blogfirst from "../components/Posts/Blogs/Blogfirst";
import Blogsecond from "../components/Posts/Blogs/Blogsecond";
import Blogartthird from "../components/Posts/Blogs/BlogSection3";
import BlogFourthSection from "../components/Posts/Blogs/BlogFourthSection"

export default function Blogs() {
  return (
    <div className="flex flex-col">
      <>
        <BlogHeader />
        <Blogfirst />
        <Blogsecond />
        <Blogartthird />
        <BlogFourthSection />
      </>
    </div>
  );
}
