import React from "react";
import commonDataSet from "../DataSet/commonDataSet";
import RightSideImageFlexTemplate from "../Templates/RightSideImageFlexTemplate";

const ScientistSection1 = () => {
  const data = commonDataSet[4];

  return (
    <div>
      <RightSideImageFlexTemplate
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
        bgColor={data.bgColor}
      />
    </div>
  );
};

export default ScientistSection1;
