import React from "react";
import commonDataSet from "../DataSet/commonDataSet";
import RightSideImageFlexTemplate from "../Templates/RightSideImageFlexTemplate";

const Section2 = () => {
  const data = commonDataSet[26];

  return (
    <div>
      <RightSideImageFlexTemplate
        title={data.title}
        description={
          <div className="tracking-tight text-md">{data.description}</div>
        }
        imageUrl={data.imageUrl}
        bgColor={data.bgColor}
      />
    </div>
  );
};

export default Section2;
