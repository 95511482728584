import React from "react";
import Section1 from "../../components/ChangeAgent/Section1";
import ImageSection from "../../components/ChangeAgent/Sectionextra";
import Section2 from "../../components/ChangeAgent/Section2";
import Section3 from "../../components/ChangeAgent/Section3";
import Section4 from "../../components/ChangeAgent/Section4";
import Section5 from "../../components/ChangeAgent/Section5";
import Section6 from "../../components/ChangeAgent/Section6";
import Section7 from "../../components/ChangeAgent/Section7";
import Section8 from "../../components/ChangeAgent/Section8";
import Section9 from "../../components/ChangeAgent/Section9";
import Section10 from "../../components/ChangeAgent/Section10";
import Section10extra from "../../components/ChangeAgent/Section10extra";
import Section11 from "../../components/ChangeAgent/Section11";

export const ChangeAgent = () => {
  return (
    <div className="flex flex-col font-poppins">
      <Section1 />
      <ImageSection />
      <Section2 />

      <Section3 />

      <Section4 />

      <Section5 />

      <Section6 />

      <Section7 />

      <Section8 />

      <Section9 />

      <Section10 />
      <Section10extra />
      <Section11 />
    </div>
  );
};
