import KnowledgeImg from "../../../assets/Scientist/Section2_Img1.jpg";
import TeachingImg from "../../../assets/Scientist/Section2_Img2.jpg";
import GuidanceImg from "../../../assets/Scientist/Section2_Img3.jpg";

const scientistData = [
  {
    id: 1,
    title: "Knowledge",
    description:
      "You'll be the source of highest knowledge, wisdom and the stream of awareness and intelligence pertaining to UPMYMIND subjects",
    shadow: false,
    topmargin: false,
    imageUrl: KnowledgeImg,
    alt: "Knowledge",
  },
  {
    id: 2,
    title: "Teaching",
    description:
      "You'll imbibes and holds the teaching, learning and skill imparting assiduous and intellectual power of domain expertise for Human Potential Development",
    shadow: true,
    topmargin: true,
    imageUrl: TeachingImg,
    alt: "Teaching",
  },
  {
    id: 3,
    title: "Guidance",
    description:
      "You'll perform the assigned-tasks, guides individuals like a compass with all the benevolent works promoted and conducted through the interventions",
    shadow: false,
    topmargin: false,
    imageUrl: GuidanceImg,
    alt: "Guidance",
  },
];

export default scientistData;
