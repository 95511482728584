import { createContext, useContext, useState, useEffect } from "react";

// Create a context to hold the screen size information
const ScreenSizeContext = createContext();

// Create a provider component that will wrap our app and manage the screen size state
const ScreenSizeProvider = ({ children }) => {
  // State to hold the current screen size, defaulting to "sm" (small)
  const [screenSize, setScreenSize] = useState("sm");

  // Use the useEffect hook to set up a ResizeObserver for dynamically detecting screen size changes
  useEffect(() => {
    // Create a new ResizeObserver instance with a callback function
    const resizeObserver = new ResizeObserver((entries) => {
      // Iterate through each entry in the entries array
      for (let entry of entries) {
        // Get the width of the observed element (in this case, document.body)
        const width = entry.contentRect.width;

        // Update the screen size based on predefined breakpoints
        if (width >= 1280) {
          setScreenSize("xl");
        } else if (width >= 1024) {
          setScreenSize("lg");
        } else if (width >= 768) {
          setScreenSize("md");
        } else {
          setScreenSize("sm");
        }
      }
    });

    // Observe changes in the width of the document body
    resizeObserver.observe(document.body);

    // Cleanup function: disconnect the ResizeObserver when the component is unmounted
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  // Render the ScreenSizeContext.Provider with the current screen size as the value
  return (
    <ScreenSizeContext.Provider value={screenSize}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

// Create a custom hook (useScreenSize) to easily access the screen size from any component
const useScreenSize = () => {
  return useContext(ScreenSizeContext);
};

// Export the ScreenSizeProvider and useScreenSize hook
export { ScreenSizeProvider, useScreenSize as default };
