import React from "react";
import HeroTemplate from "../Templates/HeroTemplate";
import smmain from "../../../assets/HomePage/Organization/omain.webp";

const Section1 = () => {
  const data = {
    title:
      "Sculpting Futures, Empowering Lives: Think Skill India — Unleashing Education, Elevating Potential with 20+ Features",
    description: "Everyone gets cloud storage and essential Think Skill India features on the web, free of charge",
    imageUrl: smmain,
  };

  return (
    <div>
      <HeroTemplate
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section1;
