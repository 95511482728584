import React from "react";
import Section1 from "../../components/MainMenu/Section1";
import Section2 from "../../components/MainMenu/Section2";
import Section3 from "../../components/MainMenu/Section3";
import Section4 from "../../components/MainMenu/Section4";
import Section5 from "../../components/MainMenu/Section5";
import Section6 from "../../components/MainMenu/Section6";
import Section7 from "../../components/MainMenu/Section7";
import Section8 from "../../components/MainMenu/Section8";
import Section9 from "../../components/MainMenu/Section9";
import Section10 from "../../components/MainMenu/Section10";
import Section11 from "../../components/MainMenu/Section11";
import Section12 from "../../components/MainMenu/Section12";
import Section13 from "../../components/MainMenu/Section13";
import Section14 from "../../components/MainMenu/Section14";
import Section15 from "../../components/MainMenu/Section15";
import Section16 from "../../components/MainMenu/Section16";
import Section17 from "../../components/MainMenu/Section17";
import Section18 from "../../components/MainMenu/Section18";
import Section19 from "../../components/MainMenu/Section19";
import Section20 from "../../components/MainMenu/Section20";
import Section21 from "../../components/MainMenu/Section21";
import Section22 from "../../components/MainMenu/Section22";
import Section23 from "../../components/MainMenu/Section23";
import SectionExtra from "../../components/MainMenu/Sectionextra";


export const MainMenu = () => {
    return (
        <div className="w-full flex flex-col font-poppins">
     <Section1/>
     <SectionExtra/>
      <Section2/>
    
    <Section3/>
     <Section4/>
    
    <Section5/>
    <Section6/>
     <Section7/>
    <Section8/>
     <Section9/>
    <Section10/>
    <Section11/>
    <Section12/>
    <Section13/>
    <Section14/>
    <Section15/>
    <Section16/>
    <Section17/>
    <Section18/> 
    <Section19/>
    <Section20/>
    <Section21/>
    <Section22/>
    
    <Section23/>  
     
    </div>
  )
}
