import React from "react";
import card1 from "../../../assets/Collaboratory/icons.png";
import card2 from "../../../assets/Collaboratory/icons (1).png";
import card3 from "../../../assets/Collaboratory/icons (2).png";
import card4 from "../../../assets/Collaboratory/icons (3).png";
import card5 from "../../../assets/Collaboratory/icons (4).png";
import card6 from "../../../assets/Collaboratory/icons (5).png";
import card7 from "../../../assets/Collaboratory/icons (6).png";
import card8 from "../../../assets/Collaboratory/icons (7).png";
import card9 from "../../../assets/Collaboratory/icons (8).png";
import card10 from "../../../assets/Collaboratory/icons (9).png";
import card11 from "../../../assets/Collaboratory/icons (10).png";
import card12 from "../../../assets/Collaboratory/icons (11).png";
import card13 from "../../../assets/Collaboratory/icons (12).png";
import card14 from "../../../assets/Collaboratory/icons (13).png";
import card15 from "../../../assets/Collaboratory/icons (14).png";
import card16 from "../../../assets/Collaboratory/icons (15).png";
import card17 from "../../../assets/Collaboratory/icons (16).png";
import card18 from "../../../assets/Collaboratory/icons (17).png";
import card19 from "../../../assets/Collaboratory/icons (18).png";
import card20 from "../../../assets/Collaboratory/icons (19).png";
import card21 from "../../../assets/Collaboratory/icons (20).png";
import card22 from "../../../assets/Collaboratory/icons (21).png";
import card23 from "../../../assets/Collaboratory/icons (22).png";
import card24 from "../../../assets/Collaboratory/icons (23).png";
import card25 from "../../../assets/Collaboratory/icons (24).png";
import card26 from "../../../assets/Collaboratory/icons (25).png";
import card27 from "../../../assets/Collaboratory/icons (26).png";
import card28 from "../../../assets/Collaboratory/icons (27).png";
import card29 from "../../../assets/Collaboratory/icons (28).png";
import card30 from "../../../assets/Collaboratory/icons (29).png";
import card31 from "../../../assets/Collaboratory/icons (30).png";

// Sample dataset
const cardData = [
  { id: 1, image: card1, title: "Job Matching Functionality" },
  { id: 2, image: card2, title: "Employee Skill Profile" },
  { id: 3, image: card3, title: "Employee Learning Journey Management" },
  { id: 4, image: card4, title: " Skill Journey Management" },
  { id: 5, image: card5, title: "Skill based Lifelong Learning " },
  { id: 6, image: card6, title: "Skill Performance Analysis" },
  { id: 7, image: card7, title: "Data, Insights and Analytics" },
  {
    id: 8,
    image: card8,
    title: "Algorithm based Roles and Experience Functionality",
  },
  { id: 9, image: card9, title: "Adaptive Content Recommendations" },
  { id: 10, image: card10, title: "AI Based Dynamic Assessment Reccomender" },
  { id: 11, image: card11, title: "Fluid User Experience" },
  { id: 12, image: card12, title: "Career pathing functionality" },
  { id: 13, image: card13, title: "Work Integrated Learning" },
  { id: 14, image: card14, title: "Advanced Search Criterias" },
  { id: 15, image: card15, title: "Content Aggregation" },
  {
    id: 16,
    image: card16,
    title: "Talent Discovery & Alerts and Interventions",
  },
  { id: 17, image: card17, title: "Create Tag and Deploy " },
  { id: 18, image: card18, title: "Map Learning Asessts and Journeys" },
  { id: 19, image: card19, title: "Trackable metadata into Iframes" },
  { id: 20, image: card20, title: "Knowledge check Experience" },
  { id: 21, image: card21, title: "User generated Content" },
  { id: 22, image: card22, title: "Use case  Integration" },
  {
    id: 23,
    image: card23,
    title: "Hosting Events, Virtual Events and Organizing events",
  },
  { id: 24, image: card24, title: "Adaptation, Integration and Subscription" },
  { id: 25, image: card25, title: "Online Discussion Forums and Chats" },
  { id: 26, image: card26, title: "Peer to Peer Learning" },
  { id: 27, image: card27, title: "Recommend Career and Learning Paths" },
  { id: 28, image: card28, title: "Recommended Mentor and Coach" },
  {
    id: 29,
    image: card29,
    title:
      "Dynamic Program Management with Cohort system via Video, chat, polling etc",
  },
  { id: 30, image: card30, title: "Ratings and Promotions" },
  { id: 31, image: card31, title: "Sequence or non-sequenced set management" },
];

const Section5 = () => {
  return (
    <section className="py-24">
      <div className=" text-center px-4 md:px-12 lg:px-24 xl:px-36">
        <h2
          className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold mb-4"
          style={{ lineHeight: "140%" }}
        >
          Addressing ,{" "}
          <span className="text-blue-500">bridging important gaps </span>{" "}
          through scalable{" "}
          <span className="text-blue-500">
            deep learning, data & contextual intelligence
          </span>{" "}
          driven solutions{" "}
        </h2>
        <p className="text-lg text-gray-700 mb-8 mt-4">
          Building the smart organisation of the Future
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 ">
          {cardData.map((card) => (
            <div
              key={card.id}
              className="text-center bg-gray-100 p-3 md:p-4 lg:p-6 xl:p-12 justify-center items-center hover:bg-slate-200 cursor-pointer  "
            >
              <img
                src={card.image}
                alt={card.title}
                className="mx-auto hover:text-white"
              />
              <h3 className="text-md font-semibold mt-4">{card.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Section5;
