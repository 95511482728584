import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import BgImg from "../../../assets/Volunteer/BgImg.png";
import CoverSideImg from "../../../assets/Volunteer/SideCoverImg.png";

const LandingCover = () => {
  return (
    <div
      className=" bg-cover h-"
      style={{ backgroundImage: `url(${BgImg})` }}
    >
      <div className="max-w-screen-xl mx-auto h-full px-6 py-8 xl:px-20 xl:py-16 lg:px-20 lg:py-8 md:px-8 md:py-4">

      <div className="grid grid-cols-12 md:gap-16 lg:gap-20 xl:gap-24">
        <div className="col-span-12 md:col-span-6 text-white xl:mt-1 lg:mt-8">
          <div className="flex flex-col">
            <div className="font-semibold text-xl xl:text-4xl md:text-xl lg:text-3xl pr-6 mb-4 cursor-pointer mt-8 md:mt-20 xl:mt-32 ">
              Empowering Growth and Enhancing Skills through Volunteering
            </div>
            <div className="mb-10 md:mb-0 mt-4 px-4 py-2 w-fit hover:bg-white hover:text-black cursor-pointer rounded-full border border-white hover:border-white flex items-center justify-start ">
              <div className="text-xs font-bold  ">Join as a Volunteer</div>
              <FontAwesomeIcon icon={faChevronRight} className=" ml-2" />
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-6">
            <img
              src={CoverSideImg}
              alt="blank"
              className="w-full h-full object-cover mb-8 md:mb-0"
            />
          </div>
        </div>
      </div>
      </div>
  );
};

export default LandingCover;
