import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaintbrush } from "@fortawesome/free-solid-svg-icons";
import DatasetSec4 from "./DatasetSec10";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Section10 = () => {
  const { buttons = [] } = DatasetSec4;
  const [selectedButton, setSelectedButton] = useState(buttons[0]);
  const [loaded, setLoaded] = useState(false);
  const controlsLeft = useAnimation();
  const controlsRight = useAnimation();

  useEffect(() => {
    setLoaded(true);
  }, []);

  const { ref, inView } = useInView();
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const handleButtonClick = async (button) => {
    await Promise.all([
      controlsLeft.start({
        opacity: 0,
        x: -30,
        transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 },
      }),
      controlsRight.start({
        opacity: 0,
        x: -30,
        transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 },
      }),
    ]);
    setSelectedButton(button);
    await Promise.all([
      controlsLeft.start({
        opacity: 1,
        x: 0,
        transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 },
      }),
      controlsRight.start({
        opacity: 1,
        x: 0,
        transition: { ease: "easeInOut", duration: 0.5, delay: 0.2 },
      }),
    ]);
  };

  return (
    <div
      ref={ref}
      className="h-[370vh] md:h-[230vh] lg:h-[250vh] xl:h-[220vh] relative"
    >
      <motion.div
        className="absolute inset-0 flex flex-col text-black"
        initial={{ opacity: 1 }}
        animate={controls}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 0.3 }}
      >
        <h1
          className="text-xl md:text-3xl lg:text-4xl xl:text-5xl leading-loose py-8  pt-12 md:pt-24 lg:pt-32  lg:py-0  mx-4 md:mx-12 lg:mx-20 xl:mx-28 font-semibold text-center"
          style={{ lineHeight: "140%" }}
        >
          <span className="text-black">
            The HPDSx Engine Is The Secret{" "}
            <span className="text-blue-500">
              To Developing the Professionals of the Future
            </span>
          </span>
        </h1>

        <div
          className="text-lg md:text-xl lg:text-2xl xl:text-3xl  mx-4 md:mx-12 lg:mx-20 xl:mx-28 text-center py-2  md:py-6 lg:py-12"
          style={{ lineHeight: "140%" }}
        >
          Comprehensive solutions for students, parents, educators and
          institution, promoting mental health awareness among students,
          teachers, parents, and employees, reducing social stigma around mental
          health issues, and providing resources for early identification and
          ongoing management of mental health and wellbeing.
        </div>
        <div className="grid xl:grid-cols-7 lg:grid-cols-4  md:grid-cols-3  gap-3 md:gap-2  lg:gap-1 xl:gap-6 md:py-4 mx-4 md:mx-12 lg:mx-20 xl:mx-24">
          {buttons.map((button) => (
            <motion.div
              key={button.id}
              className={`text-center cursor-pointer hover:bg-blue-400 text-blue-400 hover:text-white text-sm  font-semibold py-3 px-3 rounded-xl whitespace-nowrap border ${
                selectedButton === button
                  ? "bg-blue-400 text-white border-blue-400 hover:border-transparent"
                  : "border-blue-400 hover:border-transparent"
              } transition duration-300 ease-in-out`}
              onClick={() => handleButtonClick(button)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {button.heading}
            </motion.div>
          ))}
        </div>
        <div className="grid grid-cols-12 md:gap-3 lg:gap-1">
          {/* Left Section */}
          <motion.div
            className="col-span-12 md:col-span-7 lg:col-span-6 text-left px-4 md:px-0 md:ml-8 lg:ml-16 xl:ml-20 my-8 md:my-8 lg:my-20"
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
          >
            {loaded && ( // Only render if loaded is true
              <>
                <motion.div
                  className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-4"
                  style={{ lineHeight: "140%" }}
                  initial={{ opacity: 1, x: 0 }}
                  animate={controlsLeft}
                  variants={{
                    visible: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.3,
                        ease: "easeOut",
                        delay: 0.6,
                      },
                    },
                    hidden: { opacity: 0, x: -30 },
                  }}
                >
                  {selectedButton.heading1}
                </motion.div>
                <motion.div
                  className="md:text-md lg:text-lg xl:text-xl font-medium text-justify"
                  style={{ lineHeight: "140%" }}
                  initial={{ opacity: 1, x: 0 }}
                  animate={controlsLeft}
                  variants={{
                    visible: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.3,
                        ease: "easeOut",
                        delay: 0.6,
                      },
                    },
                    hidden: { opacity: 0, x: -30 },
                  }}
                >
                  {selectedButton.description}
                </motion.div>
              </>
            )}
          </motion.div>

          {/* Right Section */}
          <motion.div
            className="col-span-12 mx-4 md:mx-0 md:col-span-5 lg:col-span-6 flex justify-center items-center"
            style={{ overflow: "hidden" }}
            initial={{ opacity: 1, x: 0 }}
            animate={controlsRight}
            variants={{
              visible: {
                opacity: 1,
                x: 0,
                transition: { duration: 1.3, ease: "easeOut", delay: 0.6 },
              },
              hidden: { opacity: 0, x: 30 },
            }}
          >
            {loaded && ( // Only render if loaded is true
              <motion.img
                src={selectedButton.image}
                alt="Right Section Image"
                className="w-full h-96 rounded-lg object-cover "
                initial={{ opacity: 1, scale: 0.9 }}
                animate={controlsRight}
                variants={{
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.3, ease: "easeOut", delay: 0.6 },
                  },
                  hidden: { opacity: 0, x: 30 },
                }}
              />
            )}
          </motion.div>
        </div>

        <div className="grid grid-cols-12 md:gap-2 lg:gap-1">
          {/* Left Section */}

          <motion.div
            className="col-span-12 mx-4 md:mx-0 md:col-span-5 lg:col-span-6 flex justify-center items-center"
            style={{ overflow: "hidden" }}
            initial={{ opacity: 1, x: 0 }}
            animate={controlsRight}
            variants={{
              visible: {
                opacity: 1,
                x: 0,
                transition: { duration: 1.3, ease: "easeOut", delay: 0.6 },
              },
              hidden: { opacity: 0, x: 30 },
            }}
          >
            {loaded && ( // Only render if loaded is true
              <motion.img
                src={selectedButton.image2}
                alt="Right Section Image"
                className="w-full h-96 rounded-lg object-cover pl-12"
                initial={{ opacity: 1, scale: 0.9 }}
                animate={controlsRight}
                variants={{
                  visible: {
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1.3, ease: "easeOut", delay: 0.6 },
                  },
                  hidden: { opacity: 0, x: 30 },
                }}
              />
            )}
          </motion.div>

          {/* Right Section */}
          <motion.div
            className="col-span-12 md:col-span-7 lg:col-span-6 text-left px-4 lg:mr-20 my-8 md:my-8 lg:my-20 "
            initial={{ opacity: 0, x: -30 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
          >
            {loaded && ( // Only render if loaded is true
              <>
                <motion.div
                  className="text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-4"
                  style={{ lineHeight: "140%" }}
                  initial={{ opacity: 1, x: 0 }}
                  animate={controlsLeft}
                  variants={{
                    visible: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.3,
                        ease: "easeOut",
                        delay: 0.6,
                      },
                    },
                    hidden: { opacity: 0, x: -30 },
                  }}
                >
                  {selectedButton.heading2}
                </motion.div>
                <motion.div
                  className="md:text-md lg:text-lg xl:text-xl font-medium text-justify"
                  style={{ lineHeight: "140%" }}
                  initial={{ opacity: 1, x: 0 }}
                  animate={controlsLeft}
                  variants={{
                    visible: {
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.3,
                        ease: "easeOut",
                        delay: 0.6,
                      },
                    },
                    hidden: { opacity: 0, x: -30 },
                  }}
                >
                  {selectedButton.description2}
                </motion.div>
              </>
            )}
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Section10;
