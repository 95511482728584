import React from 'react';
import Section2image1 from '../../../assets/HPDSxMenu/Section2image1.png';
import Section2image2 from '../../../assets/HPDSxMenu/Section2image2.png';
import Section2image3 from '../../../assets/HPDSxMenu/Section2image3.png';

import DatasetSec2 from './DatasetSec4';

const Section2 = () => {
    return (
        <div className=" bg-white grid place-items-center py-12">
            <div className="text-black text-center mx-4 md:mx-20">
                <h1 className='text-3xl xl:text-5xl py-4 xl:py-16 xl:-mt-12  xl:mt-0 font-bold' style={{ lineHeight: '140%' }}>Manage the <span style={{color : '#0086E8'}}>Counsils Activities</span></h1>

                {DatasetSec2.map((card, index) => (
                  <div key={card.id} className={`grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-0 mt-${index%2 === 1 ? '12' : '12'} md:mt-${index%2 === 1 ? '8' : '16'}`}>
                      {index%2 === 1 ? (
                        <>
                          <div className="order-2 md:order-1 text-left">
                              <img src={card.image} alt="Image" className="mt-4  md:mx-0  w-full lg:w-5/6 " />
                          </div>
                          <div className="order-1 md:order-2 text-left md:ml-4 md:mt-8 lg:ml-12   lg:mt-12 w-full lg:w-5/6  xl:pt-12" >
                              <div className='text-xl md:text-2xl lg:text-3xl xl:text-5xl mb-2 lg:mb-8 font-bold' style={{ lineHeight: '140%' }}>{card.title}</div>
                              <div className='text-lg md:text-lg lg:text-xl xl:text-2xl ' style={{ lineHeight: '140%' }}>{card.description}</div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-left lg:mx-4 xl:mx-12 lg:mt-4 xl:mt-8  w-full  lg:w-5/6 xl:pt-8">
                              <div className='text-xl md:text-2xl lg:text-3xl xl:text-5xl mb-2 lg:mb-8 font-bold' style={{ lineHeight: '140%' }}>{card.title}</div>
                              <div className='text-lg md:text-lg lg:text-xl xl:text-2xl' style={{ lineHeight: '140%' }}>{card.description}</div>
                          </div>
                          <div className="text-right">
                              <img src={card.image} alt="Image" className="mt-4 md:mt-0  lg:ml-12  w-full lg:w-5/6 " />
                          </div>
                        </>
                      )}
                  </div>
                ))}
            </div>
        </div>
    );
};

export default Section2;
