import React from "react";
import RightSideImage from "../../components/Templates/RightSideImage";
import img_2 from "../../../assets/LocalActivities/img_2.jpg";

const Section2 = () => {
  const data = {
    title: <>Uncover hidden gems and amazing experiences near you</>,
    description: (
      <>
        From quiet meditation sessions to community cleanup drives, our platform
        is here to introduce you to a variety of activities happening in your
        area. We believe in the sense of belonging it brings.
      </>
    ),
    imageUrl: img_2,
  };

  return (
    <div>
      <RightSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section2;
