import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import sampleImage1 from "../../../assets/HPDSxMenu/Section14image1.png";
import sampleImage2 from "../../../assets/HPDSxMenu/Section14image2.png";
import sampleImage3 from "../../../assets/HPDSxMenu/Section14image3.png";
import sampleImage4 from "../../../assets/HPDSxMenu/Section14image4.png";
import sampleImage5 from "../../../assets/HPDSxMenu/Section14image5.png";

const Section14 = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Allow animation to trigger multiple times
    threshold: 0.2, // Adjust as needed
  });

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.4, ease: "easeInOut", delay: 0.1 },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: -80 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, ease: "easeInOut", delay: 0.6 },
    },
  };

  const imageVariant = {
    hidden: { opacity: 0, y: -80 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.9, ease: "easeInOut", delay: 0.5 },
    },
  };

  return (
    <motion.div
      ref={ref}
      className="px-4 py-8 md:px-0 md:py-8 lg:px-3 lg:py-24 xl:px-4 xl:py-32"
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={sectionVariants}
    >
      {/* Heading and Subheading */}
      <h2
        className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-relaxed mx-4 md:mx-4 lg:mx-12 xl:mx-20 font-bold mb-8"
        style={{ lineHeight: "140%" }}
      >
        <span className="" style={{ color: "rgb(0, 134, 232)" }}>
          Comprehensive
        </span>{" "}
        and{" "}
        <span className="" style={{ color: "rgb(0, 134, 232)" }}>
          Intuitive system
        </span>{" "}
        scope of Human Potential Development Science Platform
      </h2>

      <p
        className="text-lg md:text-xl lg:text-2xl xl:text-3xl  leading-relaxed mx-4 md:mx-4 lg:mx-12 xl:mx-20  mb-8 text-justify"
        style={{ lineHeight: "140%" }}
      >
        The nation needs to do a much better job teaching and measuring
        advanced, 21st century skills that are the indispensable currency for
        participation, achievement and competitiveness in the global economy.
      </p>

      <div className="grid grid-cols-12 gap-4 md:gap-2 lg:gap-4 xl:gap-8">
        {/* Left Section */}
        <motion.div className="col-span-12 md:col-span-6 mx-4 md:mx-4 lg:mx-8 xl:mx-16">
          <div className="grid grid-cols-12 gap-2 ">
            {/* Card 1 */}
            <motion.div
              className="col-span-12 flex flex-row"
              variants={cardVariants}
            >
              {/* Card 1 */}
              <motion.div
                className="bg-white md:mt-4 lg:mt-6 xl:p-6 lg:p-4 md:p-2"
                variants={cardVariants}
              >
                <img
                  src={sampleImage1}
                  alt="Sample Image 1"
                  className="w-16 h-16  object-contain rounded-md mb-4"
                />
                <p className="text-gray-800 md:text-lg lg:text-xl xl:text-2xl md:leading-tight xl:leading-relaxed text-justify" >
                  <span className="" style={{ color: "rgb(0, 134, 232)" }}>
                    Every
                  </span>{" "}
                  child and young person is{" "}
                  <span className="" style={{ color: "rgb(0, 134, 232)" }}>
                    entitled
                  </span>{" "}
                  to develop their potential.​
                </p>
              </motion.div>

              {/* Card 2 */}
              <motion.div
                className="bg-white  md:mt-4 lg:mt-6 xl:p-6 lg:p-4 md:p-2"
                variants={cardVariants}
              >
                <img
                  src={sampleImage2}
                  alt="Sample Image 2"
                  className="w-16 h-16  object-contain rounded-md mb-4"
                />
                <p className="text-gray-800 md:text-lg lg:text-xl xl:text-2xl md:leading-tight xl:leading-relaxed font-medium  text-justify">
                  It is important that{" "}
                  <span className="" style={{ color: "rgb(0, 134, 232)" }}>
                    children and young people{" "}
                  </span>{" "}
                  are aware of, and understand, the{" "}
                  <span className="" style={{ color: "rgb(0, 134, 232)" }}>
                    {" "}
                    value of{" "}
                  </span>{" "}
                  human potential.
                </p>
              </motion.div>
            </motion.div>
          </div>

          {/* Card 3 */}
          <motion.div
            className="bg-white  md:mt-4 lg:mt-6 xl:p-6 lg:p-4 md:p-2"
            variants={cardVariants}
          >
            {/* Image */}
            <img
              src={sampleImage3}
              alt="Sample Image 1"
              className="w-16 h-16 object-contain rounded-md mb-4"
            />
            {/* Description */}
            <p className="text-gray-800 md:text-lg lg:text-xl xl:text-2xl md:leading-tight xl:leading-relaxed font-medium  text-justify">
              Adults, Practitioners and Learners TO{" "}
              <span className="" style={{ color: "rgb(0, 134, 232)" }}>
                REFLECT TOGETHER
              </span>{" "}
              ON THEIR Human Potential THAT THEY CONSIDER{" "}
              <span className="" style={{ color: "rgb(0, 134, 232)" }}>
                TO BE IMPORTANT
              </span>{" "}
              IN THEIR LEARNING, LIVES AND WORK.
            </p>
          </motion.div>

          {/* Card 4 */}
          <motion.div
            className="bg-white  md:mt-4 lg:mt-6 xl:p-6 lg:p-4 md:p-2"
            variants={cardVariants}
          >
            {/* Image */}
            <img
              src={sampleImage4}
              alt="Sample Image 1"
              className="w-16 h-16 object-contain rounded-md mb-4"
            />
            {/* Description */}
            <p className="text-gray-800 md:text-lg lg:text-xl xl:text-2xl md:leading-tight xl:leading-relaxed font-medium  text-justify">
              The Human Potential assessment process should{" "}
              <span className="" style={{ color: "rgb(0, 134, 232)" }}>
                help children and young people
              </span>{" "}
              to understand why human potential is important,{" "}
              <span className="" style={{ color: "rgb(0, 134, 232)" }}>
                reflect
              </span>{" "}
              on how they are developing their potential, identify the next
              steps in their development and understand how they have acquired
              the potential that can be used across the academic, career and job
              and in their lives in and outside the classroom and workplace.
            </p>
          </motion.div>
        </motion.div>

        {/* Right Section */}
        <motion.div
          className="col-span-12 md:col-span-6 lg:col-span-6 mt-4 mx-4 md:mt-0  md:mr-0 lg:mr-2 xl:mr-4"
          variants={imageVariant}
        >
          {/* Image */}
          <img
            src={sampleImage5}
            alt="Sample Image 2"
            className="w-full h-full object-contain "
          />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Section14;
