import React from "react";
import LandingCover from "../../components/Institutions/LandingCover";
import Institution from "../../components/Institutions/Institution";

export default function Students() {
  return (
    <div className="flex flex-col bg-blue-950">
      <LandingCover />
      <Institution />
    </div>
  );
}
