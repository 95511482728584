import React from "react";
import ShadowCenterCardTemplate from "../Templates/ShadowCenterCardTemplate";
import conferenceData from "./conferenceData";

const ConferenceSection2 = () => {
  return (
    <ShadowCenterCardTemplate
      title="Conferences provide you with an opportunity for"
      data={conferenceData}
      showUnderlineStyle={false}
      underlineTop={-4.6}
      responsiveLeftXL="130px" // Set the desired left value for extra-large screens (xl)
      responsiveLeftLG="80px" // Set the desired left value for large screens (lg)
      responsiveLeftMD="60px" // Set the desired left value for medium screens (md)
    />
  );
};

export default ConferenceSection2;
