import React from "react";
import CardGridTemplate from "../Templates/CardGridTemplate";
import workshopData from "./workshopData";

export default function WorkshopSection2() {
  return (
    <CardGridTemplate
      title="Key areas that Workshop covers"
      data={workshopData}
    />
  );
}
