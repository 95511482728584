import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

const Section2 = () => {
  const videoId = "7PIji8OubXU"; // Extract the video ID from the YouTube URL
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="w-full h-full xl:px-48 lg:px-36 md:px-12 xl:pb-20 lg:pb-12 md:pb-8 px-4 flex flex-col justify-center items-center -mt-72 md:-mt-40" style={{ backgroundColor: 'rgb(242, 249, 255)' }}>

      <div className="text-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-black font-bold py-4 md:py-10">Title For <span className="text-blue-500">Video</span></div>
      <div className="bg-blue-400 w-full flex justify-center items-center text-white text-xl py-4 font-semibold">
        <FontAwesomeIcon icon={faPlayCircle} className="mr-1 w-3 h-3 md:w-6 md:h-6 " />
        <div className="text-xs md:text-sm">Learn More In The Video Below And Sign Up For Free Today!</div>
      </div>

      <iframe
        className="w-full xl:h-[70vh] lg:h-[60vh] md:h-[50vh] h-[50vh]object-contain"
        src={embedUrl} // Use the embed URL here
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Section2;
