import step2 from '../../../../assets/Learn&Grow/step02.png';
import step3 from '../../../../assets/Learn&Grow/step03.png';
import step4 from '../../../../assets/Learn&Grow/step04.png';
import step5 from '../../../../assets/Learn&Grow/step05.png';

const skillData = [
  {
    id: 1,
    name: "Individual Skills",
    imageUrl: step3,
  },
  {
    id: 2,
    name: "Personals Skills",
    imageUrl: step2,
  },
  {
    id: 3,
    name: "Academic Skills",
    imageUrl: step5,
  },
  {
    id: 4,
    name: "Professional Skill",
    imageUrl: step4,
  },
  {
    id: 5,
    name: "Management Skill",
    imageUrl: step5,
  },
  {
    id: 6,
    name: "Leadership Skills",
    imageUrl: step5,
  },
  {
    id: 7,
    name: "Communication Skills",
    imageUrl: step2,
  },
  {
    id: 8,
    name: "Problem Solving",
    imageUrl: step3,
  },
  {
    id: 9,
    name: "Teamwork",
    imageUrl: step4,
  },
  {
    id: 10,
    name: "Creativity",
    imageUrl: step5,
  },
  {
    id: 11,
    name: "Time Management",
    imageUrl: step2,
  },
  {
    id: 12,
    name: "Adaptability",
    imageUrl: step3,
  },
];

export default skillData;
