import React from "react";
import card_img_1 from "../../../assets/NonProfitOrganisation/Section4_image1.png";
import card_img_2 from "../../../assets/NonProfitOrganisation/Section4_image2.png";
import card_img_3 from "../../../assets/NonProfitOrganisation/Section4_image3.png";
import card_img_4 from "../../../assets/NonProfitOrganisation/Section4_image4.png";

function Section4() {
  const data = {
    topic1: "Sectors influenced by HPDS",
    items: [
      {
        img: card_img_1,
        name: "Digital Equity and Advocacy",
      },
      {
        img: card_img_2,
        name: "Education",
      },
      {
        img: card_img_3,
        name: "Social Service and Humanitarian Response",
      },
      {
        img: card_img_4,
        name: "Workforce Development and Placement",
      },
    ],
  };

  return (
    <div className="h-auto ">
      <div className="max-w-screen bg-white py-4 mx-auto px-8 md:px-10 ">
        <div className=" w-full m-10 mx-auto">
          <p className="text-3xl lg:text-4xl xl:text-5xl font-semibold text-center md:m-10 ">
            {data.topic1}
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2  gap-y-20 gap-x-14 ">
          {data.items.map((item, index) => (
            <div key={index}>
              <div
                className="w-full h-[50vh] rounded-3xl relative overflow-hidden"
                style={{
                  backgroundImage: `url(${item.img})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                {/* Blurry and transparent white background */}
                <div className="absolute bottom-0 w-full h-auto p-3 bg-white text-white backdrop-blur-lg bg-opacity-0 rounded-3xl">
                  <div className="font-bold text-2xl ">{item.name}</div>
                  <div className="border-t-4 border-white w-1/3 my-1"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Section4;
