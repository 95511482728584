import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "tailwindcss/tailwind.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import section3Data from "./section3Data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import search from "../../../assets/Competitions/search.jpg"

const Section1 = () => {
  const sliderRef = useRef(null);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToScroll: 1,
    slidesToShow: slidesToShow,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1160) {
        setSlidesToShow(4); // xl or more
      } else if (window.innerWidth >= 1024) {
        setSlidesToShow(3); // lg
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(3); // md
      } else {
        setSlidesToShow(1); // sm
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMoveLeft = () => {
    if (sliderRef.current && currentSlide > 0) {
      sliderRef.current.slickPrev();
    }
  };

  const handleMoveRight = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div className="max-w-screen-xl xl:mx-28 lg:mx-24 md:mx-16 py-12 bg-white relative">
      <div className="flex flex-row gap-0 justify-between">
        <div className="text-md xl:text-3xl lg:text-3xl md:text-2xl font-semibold font-Poppins">
          <div className="hidden md:block">
            Think Skill India
            <span className="font-bold ml-2.5">Past Competitions</span>
          </div>
          <div className="block md:hidden">
            <div className="font-bold text-lg ml-6">Past Competitions</div>
          </div>
        </div>
        <div>
          <div className="hidden md:block">
            <div className="w-10 md:w-56 h-10 px-4 py-2.5 bg-neutral-100 rounded-lg ">
              <div className="flex flex-row">
                <div className="opacity-60 tracking-tight text-slate-900 font-Poppins text-sm text-base justify-start items-center gap-2 inline-flex">
                  <img src={search} alt="search" />
                  Search competitions
                </div>
              </div>
            </div>
          </div>
          <div className="block md:hidden">
            <div className="flex flex-row">
              <div className="mr-4 w-24 h-8 px-2 py-1 opacity-60 text-sm bg-neutral-300 text-black rounded-full">
                <FontAwesomeIcon icon={faSearch} className="mr-2" />
                Search
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-6 md:mt-8 mx-6 md:mx-0">
        {currentSlide > 0 && (
          <div className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10">
            <div
              className="w-12 h-12 rounded-full flex items-center justify-center border border-gray-400 shadow-2xl bg-white cursor-pointer text-black"
              onClick={handleMoveLeft}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
            </div>
          </div>
        )}
        <Slider {...settings} className="flex w-full" ref={sliderRef}>
          {section3Data.map((section) => (
            <div key={section.id} className="outline-none focus:outline-none">
              <div className="flex-col justify-start items-start mx-2 bg-slate-100 rounded-lg">
                <img
                  className="w-full h-full relative rounded-lg object-cover"
                  src={section.image}
                  alt={section.title}
                />
                <div className="text-center py-4 tracking-tight text-black xl:text-sm lg:text-sm md:text-xs">
                  <div className="my-1 font-bold">{section.title}</div>
                  <div className="font-semibold">
                    <span className="text-stone-400">Prize Pool: </span>
                    {section.prizeValue}
                  </div>
                  <div className="text-emerald-300 font-semibold mt-1">
                    Completed on {section.date}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10">
          <div
            className="w-12 h-12 rounded-full flex items-center justify-center shadow-2xl border border-gray-400 bg-white cursor-pointer text-black"
            onClick={handleMoveRight}
          >
            <FontAwesomeIcon icon={faChevronRight} className="text-lg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
