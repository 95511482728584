import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import right_img from "../../../assets/Socities/right_img.png";
import cover_bg from "../../../assets/Socities/bg_img.jpg";

const LandingCover = () => {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };
  return (
    <div
      className="w-full grid grid-cols-12 gap-6 md:gap-8 xl:gap-12 py-8 md:py-20 px-4 pt-16 xl:px-24 xl:py-20 lg:px-20 lg:py-20 md:px-12 "
      style={{
        backgroundImage: `url(${cover_bg})`,
        backgroundSize: "cover", // Maintain original image size
        backgroundPosition: "center", // Center the image
      }}
    >
      <div className="text-black order-2 md:order-1 col-span-12 md:col-span-6 flex flex-col justify-center items-start cursor-pointer ">
        <div className="mt-4 md:mt-0 px-4 md:px-0 pb-2 md:pb-4 font-semibold text-2xl xl:text-4xl md:text-3xl ">
          21st Century Society<br></br>{" "}
          <div className="text-blue-500 font-bold md:mt-2">
            Transform your Society with our HPDS Framework
          </div>
        </div>
        <div className="text-md lg:text-xl xl:text-3xl md:text-lg px-4 md:px-0 py-3 md:py-5">
          <button
            className="text-white bg-yellow-400 px-3 md:px-6 py-2 md:py-3 text-lg md:text-xl rounded-full hover:border-2 hover:border-yellow-400 hover:text-yellow-400 hover:bg-transparent"
            type="submit"
            onClick={handleInputChange}
          >
            Let's get started
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </button>
        </div>
      </div>

      <div className="col-span-12 order-1 md:order-2 md:col-span-6">
        <img
          src={right_img}
          alt="blank"
          className="w-64 h-48 md:w-full md:h-full"
        />
      </div>
    </div>
  );
};

export default LandingCover;
