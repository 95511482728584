import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import backgroundImage from '../../../assets/MainMenu/Section17imagebg.png';

const Section17 = () => {
  const { ref, inView } = useInView();

  return (
    <div className="relative">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      
      {/* Content */}
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 80 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }}
        transition={{ ease: 'easeInOut', duration: 0.5 , delay:0.2 }}
        className="relative z-10 flex flex-col items-center justify-center py-4 md:py-4 lg:py-8 xl:py-16 "
      >
        {/* Text */}
        <div className='text-white leading-relaxed text-center text-xl md:text-3xl lg:text-3xl mx-4 md:mx-12 lg:mx-20 xl:mx-32' style={{ lineHeight: '140%' }}>
          Pioneering the development of a global initiative to support Indian students across the country and globe.
        </div>
      </motion.div>
    </div>
  );
};

export default Section17;
