import React from 'react'
import SlideWithImage from "../components/Templates/SlideWithImage"
import icon1 from '../../assets/icon1.png'
import icon2 from '../../assets/icon2.png'
import icon3 from '../../assets/icon3.png'
import icon4 from '../../assets/icon4.png'
import S1 from '../../assets/img13.png'
import S2 from '../../assets/img14.png'
import S3 from '../../assets/img15.png'
import S4 from '../../assets/img16.png'
import Grid4Slide from '../components/CreateCompetition/Grid4Slide'
import HeroSection from '../components/CreateCompetition/HeroSection'

const CreateCompetitions = () => {
  const slideInfo1={
    icon: icon1,
    img:S1,
    title: <div className='font-normal'> Identify Potential talents for <strong className='font-semibold'>your objective</strong></div>,
    subTit: <div className="font-normal">From seamless online arrangements to seamless offline experiences, we cater to all your event needs. Embrace flexibility with our support for last-minute rescheduling, ensuring your event's success, no matter the changes</div>,
  };
  const slideInfo2={
    icon: icon2,
    img:S2,
    title: <div className='font-normal'>Wide range of <strong className='font-semibold'>contest offering</strong> for your online/offline space</div>,
    subTit: <div className="font-normal">Adding a co-host, you can significantly enrich your event's value, offering a diverse experience that resonates more profoundly with your audience</div>,
  };
  const slideInfo3={
    icon: icon3,
    img:S3,
    title: <div className='font-normal'> Seek Readily available <strong className='font-semibold'> volunteers for hosting competition</strong></div>,
    subTit: <div className="font-normal">Leverage an eager and available audience keen on volunteering allowing you to focus on the heart of the event.</div>,
  };
  const slideInfo4={
    icon: icon4,
    img:S4,
    title: <div className='font-normal'><strong className='font-semibold'>Simplifying Your Event's</strong> Volunteering Needs</div>,
    subTit: <div className="font-normal">Leverage an eager and available audience keen on volunteering allowing you to focus on the heart of the event.</div>,
  };


  return (
    <div>
      <HeroSection/>
      <SlideWithImage slideInfo={slideInfo1} contStyle ={`bg-white  md:py-20 py-7`}/>
      <SlideWithImage slideInfo={slideInfo2} contStyle ={`bg-white  md:py-20 py-7 flex-row-reverse`}/>
      <SlideWithImage slideInfo={slideInfo3} contStyle ={`bg-white  md:py-20 py-7`}/>
      <Grid4Slide/>
      {/* <SlideWithImage slideInfo={slideInfo4} contStyle ={`bg-white  md:py-20 py-7 flex-row-reverse`}/> */}
    </div>
  )
}

export default CreateCompetitions