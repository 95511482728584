import React from "react";
import icon5 from "../../../assets/icon5.png";
import sm_img1 from "../../../assets/sm_img1.png";
import sm_img2 from "../../../assets/sm_img2.png";
import sm_img3 from "../../../assets/sm_img3.png";
import sm_img4 from "../../../assets/sm_img4.png";

const Grid4Slide = () => {
  const data = {
    title: "Publishing your event just takes few steps",
    icon: icon5,
    items: [
      {
        img: sm_img1,
        text: "Register yourself as speaker",
      },
      {
        img: sm_img2,
        text: "Provide Details of your event",
      },
      {
        img: sm_img3,
        text: "Invite Other Contributer/Co-Host",
      },
      {
        img: sm_img4,
        text: "Publish Your Event",
      },
    ],
  };

  return (
    <div className="flex flex-col justify-center py-5 lg:w-9/12 md:w-11/12 mx-auto">
      <div className="text-3xl font-semibold flex">
        <span className="lg:w-14 md:w-12 sm:w-9 mr-2">
          <img src={data.icon} alt="img" />
        </span>
        {data.title}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 lg:py-14 py-7 text-blue-600 w-11/12 mx-auto">
        {data?.items?.map((e, index) => {
          return (
            <div className="mx-auto overflow-hidden justify-center lg:w-44 md:w-40">
              <div className=" mx-auto mt-4 mb-2 rounded-md">
                <img  src={e.img} alt=""/>
              </div>
              <p className="w-full text-center text-sm my-4 font-semibold ">
                {e.text}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Grid4Slide;
