import React from "react";
import LandingCover from "../../components/Guide/LandingCover";
import GuideSection1 from "../../components/Guide/GuideSection1";
import GuideSection2 from "../../components/Guide/GuideSection2";
import GuideSection3 from "../../components/Guide/GuideSection3";

export default function Scientist() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <GuideSection1 />
      <GuideSection2 />
      <GuideSection3 />
    </div>
  );
}
