import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintbrush } from '@fortawesome/free-solid-svg-icons';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import DatasetSec4 from './DatasetSec4';

const Section4 = () => {
  // Importing data from DatasetSec4
  const { buttons } = DatasetSec4;
  const [selectedButton, setSelectedButton] = useState(buttons[0]);
  const [loaded, setLoaded] = useState(false);
  const { ref, inView } = useInView();
  const controls = useAnimation();

  // Simulating image loading delay
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 1000); // Adjust the delay time as needed
    return () => clearTimeout(timeout);
  }, []);

  // Animation control based on visibility
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  // Handle button click with animation
  const handleButtonClick = async (button) => {
    await controls.start({ opacity: 0, x: -50 });
    setSelectedButton(button);
    await controls.start({ opacity: 1, x: 0 });
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -50 },
      }}
      transition={{ duration: 0.6, ease: 'easeInOut', delay: 0.3 }}
      style={{ width: '100%', height: '100%' }}
    >
      <div className="w-full" style={{ background: '#0086E8' }}>
        <div className="flex flex-col text-white py-20">
          {/* Title and Buttons */}
          {/* Title */}
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9, ease: 'easeInOut', delay: 0.4 }}
            className="text-2xl md:text-3xl lg:text-4xl xl:text-4xl leading-loose mb-4 py-12 md:py-0 lg:py-0 xl:py-8 pt-12 mx-4 md:mx-12 lg:mx-20 xl:mx-36 font-semibold"
            style={{ lineHeight: '140%' }}
          >
            <span className='text-yellow-300'>Grow your Expertise</span>, Take systematic steps and build on incredible paths to <span className='text-yellow-300'>maximize your potential</span>​ Manage every way to realize your full potential
          </motion.h1>
          {/* Buttons */}
          <div className="grid grid-cols-1 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-3 gap-2 md:gap-12 lg:gap-6 xl:gap-10 py-[8px] xl:py-8 md:py-4 mx-4 md:mx-12 lg:mx-20 xl:mx-36">
            {buttons.map(button => (
              <motion.div
              key={button.id}
              className={`text-center cursor-pointer hover:bg-yellow-400  hover:text-white text-md  font-semibold py-3 px-4 rounded-xl whitespace-nowrap border ${selectedButton === button ? 'bg-yellow-400 text-white border-yellow-400 hover:border-transparent' : 'border-yellow-400 text-yellow-400 hover:border-transparent'} transition duration-300 ease-in-out`}
              onClick={() => handleButtonClick(button)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              {button.heading}
            </motion.div>
            ))}
          </div>

          {/* Rendered Data with Animation */}
          <div className="grid grid-cols-12 gap-4">
            {/* Left Section */}
            <motion.div
              className="col-span-12 md:col-span-7 lg:col-span-6 text-left mx-4 md:mx-12 lg:mx-20 xl:mx-32 my-12 md:my-8 lg:my-20"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: loaded ? 1 : 0, x: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
            >
              {loaded && (
                <>
                  <div className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-semibold mb-8 md:pt-12 lg:pt-0" style={{ lineHeight: '140%' }}>
                    <span className='mx-4'>
                      <FontAwesomeIcon icon={faPaintbrush} style={{ color: "#f7f9fd" }} />
                    </span>
                    {selectedButton.heading}
                  </div>
                  <div className='text-lg md:text-lg lg:text-xl xl:text-2xl font-medium text-justify' style={{ lineHeight: '140%' }}>
                    {selectedButton.description}
                  </div>
                </>
              )}
            </motion.div>

            {/* Right Section */}
            <motion.div
              className="col-span-12 md:col-span-5 lg:col-span-6 flex flex-col justify-center items-center"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: loaded ? 1 : 0, x: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
            >
              {loaded && (
                <motion.img
                  src={selectedButton.image}
                  alt="Right Section Image"
                  className="md:w-full h-full lg:w-5/6 rounded-xl "
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, ease: "easeInOut", delay: 0.3 }}
                />
              )}
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Section4;
