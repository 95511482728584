import Section1_img from "../../../assets/Platform/Section1_img.png"


const sectiononeData = [
    {
        id: 1,
        titleheading: "Taking Control of Your Life Online",
        description:
            "Our goal is to empower people by promoting emotional resilience and well-being. In the mental health context, this means that we strive to reduce the gap between people who suffer from mental illness, whether it is diagnosed, long-term, or situational, and the tools and interventions that can help them. We believe that technology-enhanced mental health and well-being interventions can reduce barriers to and increase engagement in activities that promote well-being and self-efficacy.",
        imageUrl: Section1_img,
        bgColor: "white",
        button: false,
        buttonName: "",
    },

];
export default sectiononeData;