import React from "react";
import commonDataSet from "../../DataSet/commonDataSet";
import RightSideImageFlexTemplate from "../../Templates/RightSideImageFlexTemplate";

const ArticleSecond = () => {
  const data = commonDataSet[21];

  return (
    <div>
      <RightSideImageFlexTemplate
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
        bgColor={data.bgColor}
        button={data.button}
        buttonName={data.buttonName}
      />
    </div>
  );
};

export default ArticleSecond;