import ContestImg from "../../../assets/Competitions/Section3_Img.jpg"

const section3Data = [
  {
    id: 1,
    title: "Core77 Design Awards",
    image: ContestImg,
    date: "20 Dec 2023",
    prizeValue: "Rs. 5000",
  },
  {
    id: 2,
    title: "Spelling Bee Competitions",
    image: ContestImg,
    date: "18 Dec 2023",
    prizeValue: "Rs. 5000",
  },
  {
    id: 3,
    title: "Stockholm Junior Water Prize",
    image: ContestImg,
    date: "10 Dec 2023",
    prizeValue: "Rs. 5000",
  },
  {
    id: 4,
    title: "International Eco-Hero Awards",
    image: ContestImg,
    date: "25 Dec 2023",
    prizeValue: "Rs. 5000",
  },
  {
    id: 5,
    title: "Coding Challenge Championship",
    image: ContestImg,
    date: "30 Dec 2023",
    prizeValue: "Rs. 5000",
  },
  {
    id: 6,
    title: "Math Olympiad",
    image: ContestImg,
    date: "5 Jan 2024",
    prizeValue: "Rs. 5000",
  },
  {
    id: 7,
    title: "Science Fair Showcase",
    image: ContestImg,
    date: "15 Jan 2024",
    prizeValue: "Rs. 5000",
  },
  {
    id: 8,
    title: "Innovation Challenge",
    image: ContestImg,
    date: "20 Jan 2024",
    prizeValue: "Rs. 5000",
  },
];

export default section3Data;
