import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import BgImg from "../../../assets/Workshop/CoverBgImg.png";
import CoverSideImg from "../../../assets/Workshop/CoverSideImg.png";

const LandingCover = () => {
  return (
    <div
      className="w-full h-full md:flex bg-contain xl:bg-contain lg:bg-contain md:bg-cover xl:bg-no-repeat bg-center px-0 py-0 xl:px-28 lg:px-20 md:px-12 xl:py-24 lg:py-16 md:py-12"
      style={{ backgroundImage: `url(${BgImg})` }}
    >
      <div className="grid grid-cols-12 gap-4 md:gap-16">
        <div className="col-span-12 md:col-span-6 order-2 md:order-1 text-black xl:mt-1 lg:mt-8 flex flex-col  md:flex-row">
          <div className="px-6 md:px-0">
            <div className="md:w-auto md:pr-16 mb-4 cursor-pointer">
              <div className="font-semibold mb-4 font-Segoe UI text-center md:text-left text-lg xl:text-4xl md:text-xl lg:text-3xl">
                Workshops: Engaging Sessions for Skill Development and
                Mindfulness
              </div>
              <div className="font-Segoe UI text-center md:text-left text-xs xl:text-lg lg:text-md md:text-xs">
                <div>
                  Explore dynamic sessions designed to foster skill development
                  and enrich knowledge. Engage in interactive workshops aimed at
                  enhancing your abilities and expanding your understanding
                </div>
              </div>
              <div className="flex justify-center  md:justify-start">
                <div className="mt-4 px-4 py-2 w-fit hover:bg-blue-500 hover:text-white cursor-pointer rounded-full border border-black hover:border-white flex justify-start">
                  <div className="text-xs font-bold">Workshops</div>
                  <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-6 order-1 md:order-2 flex justify-center">
          <div className="w-full h-full xl:w-[480px] xl:h-[350px] lg:h-full lg:w-full md:w-72 md:h-56 rounded-xl hover:shadow-2xl hover:border-gray-500">
            <img
              src={CoverSideImg}
              alt="blank"
              className="w-full h-full object-cover rounded-xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
