import React from "react";
import LandingCover from "../../components/CreateYourOwnAssessments/LandingCover";
import Assessment from "../../components/CreateYourOwnAssessments/Assessment";

export default function CreateYourOwnAssessments() {
  return (
    <div className="flex flex-col">
      <LandingCover />
      <Assessment />
    </div>
  );
}
