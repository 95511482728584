import React from "react";
import publicationData from "./publicationDataset";
import Grid from "../../components/Templates/Grid";

const Section5 = () => {
  return (
    <div>
      <Grid title={publicationData.topic} data={publicationData.items} />
    </div>
  );
};

export default Section5;
