import React from "react";
import BgImg from "../../../assets/Instructor/BgImgLandingSection.jpg";
import CoverSideImg from "../../../assets/Instructor/CoverSideImg.jpg";

const LandingCover = () => {
  const bgImageStyle = {
    backgroundImage: `url(${BgImg})`,
    backgroundPosition: "center",
  };

  return (
    <div
      className="w-full h-full md:h-[70vh] lg:h-full xl:h-full grid grid-cols-2 gap-0 lg:gap-12 px-0 py-0 xl:px-20 xl:py-16 lg:px-8 lg:py-8 md:px-4 md:py-4 bg-auto md:bg-cover bg-no-repeat"
      style={bgImageStyle}
    >
      <div className="order-2 md:order-1 col-span-2 md:col-span-1 place-content-start flex flex-col text-white pl-2.5 md:pl-8 cursor-pointer">
        <div className="mb-0 md:mb-2 pt-16 md:pt-24 pb-4 whitespace-nowrap font-semibold font-Segoe UI text-2xl xl:text-4xl md:text-2xl lg:text-3xl">
          <div>Educate, Engage, and Excel</div>
          <div className="flex">
            <div>as an</div>
            <div className="font-bold ml-2">Instructor</div>
          </div>
        </div>
        <div className="mb-20 md:mb-1 text-md xl:text-lg lg:text-md md:text-sm">
          <div>
            Be part of esteemed community of Instructors, empowering
            students to attain career success.
          </div>
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-2 md:col-span-1 flex justify-start items-start xl:py-16 md:py-16 lg:py-8 pl-0 md:pl-6 pr-0 md:pr-4">
        <div className="w-full h-full rounded-none md:rounded-xl">
          <img
            src={CoverSideImg}
            alt="blank"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
