import React from "react";

const HeroTemplate = ({ title, description, imageUrl }) => {
  return (
    <div
      className={
        "px-6 py-8 xl:px-28 lg:px-20 md:px-12 xl:py-12 lg:py-16 md:py-12 flex flex-col items-center justify-center gap-4"
      }
    >
      <div className="text-stone-900 text-center xl:text-3xl lg:text-3xl md:text-2xl text-lg font-semibold cursor-pointer">
        {title}
      </div>
      <div className="text-xs mb-6 text-center text-stone-900 xl:text-lg  lg:text-sm md:text-xs cursor-pointer">
        {description}
      </div>

      <img className="w-96 md:w-full md:h-full" src={imageUrl} alt={title} />
    </div>
  );
};

export default HeroTemplate;
