import React from "react";

const RightSideImage = ({ title, description, imageUrl }) => {
  return (
    <div
      className={`w-full h-full px-6 py-8 xl:px-28 lg:px-20 md:px-7 xl:pt-20 lg:py-16 md:py-5 flex flex-col md:flex-row justify-center items-center gap-6 md:gap-12 bg-blue-500 text-white`}
    >
      {title ? (
        <div className="flex-col w-full  gap-4 md:pl-8">
          <div className="xl:text-5xl lg:text-4xl md:text-2xl text-sm font-semibold mb-4 md:mb-8 xl:pt-12 lg:pt-8 md:pt-6 text-left cursor-pointer">
            {title}
          </div>
          <div className=" text-left self-stretch xl:text-2xl lg:text-xl md:text-lg text-sm cursor-pointer">
            {description}
          </div>
        </div>
      ) : (
        <div className=" flex-col text-left justify-center items-center font-semibold text-white xl:text-3xl lg:text-2xl md:text-xl text-sm cursor-pointer">
          {description}
        </div>
      )}

      <img
        className="w-full h-full md:w-[330px] xl:w-[500px] lg:w-[500px]  xl:h-96 lg:h-[300px] md:h-56 rounded-xl"
        src={imageUrl}
        alt={title}
      />
    </div>
  );
};

export default RightSideImage;
