import React from 'react';
import YourImage from '../../../assets/Collaboration/Section6image.png'; // Import your image here

const Section6 = () => {
  return (
    <section className="w-full bg-gray-200 flex justify-center items-center px-4 py-8 md:py-12 lg:py-0 lg:h-screen md:px-12 lg:px-24 ">
      <img src={YourImage} alt="Centered Image" className="w-full max-h-full" />
    </section>
  );
};

export default Section6;
