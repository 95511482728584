import Section4image1 from "../../../assets/HPDSxEngine/Section4image1.png"
import Section4image2 from "../../../assets/HPDSxEngine/Section4image2.png"
import Section4image3 from "../../../assets/HPDSxEngine/Section4image3.png"
import Section4image4 from "../../../assets/HPDSxEngine/Section4image4.png"

import Section4Card11 from "../../../assets/HPDSxEngine/Section4Card11.png"
import Section4Card12 from "../../../assets/HPDSxEngine/Section4Card12.png"
import Section4Card13 from "../../../assets/HPDSxEngine/Section4Card13.png"
import Section4Card14 from "../../../assets/HPDSxEngine/Section4Card14.png"
import Section4Card15 from "../../../assets/HPDSxEngine/Section4Card15.png"

import Section4Card21 from "../../../assets/HPDSxEngine/Section4Card21.png"
import Section4Card22 from "../../../assets/HPDSxEngine/Section4Card22.png"
import Section4Card23 from "../../../assets/HPDSxEngine/Section4Card23.png"
import Section4Card24 from "../../../assets/HPDSxEngine/Section4Card24.png"
import Section4Card25 from "../../../assets/HPDSxEngine/Section4Card25.png"
import Section4Card26 from "../../../assets/HPDSxEngine/Section4Card26.png"
import Section4Card27 from "../../../assets/HPDSxEngine/Section4Card25.png"

import Section4Card31 from "../../../assets/HPDSxEngine/Section4Card31.png"
import Section4Card32 from "../../../assets/HPDSxEngine/Section4Card32.png"
import Section4Card33 from "../../../assets/HPDSxEngine/Section4Card33.png"
import Section4Card34 from "../../../assets/HPDSxEngine/Section4Card34.png"
import Section4Card35 from "../../../assets/HPDSxEngine/Section4Card35.png"
import Section4Card36 from "../../../assets/HPDSxEngine/Section4Card36.png"
import Section4Card37 from "../../../assets/HPDSxEngine/Section4Card37.png"

import Section4Card41 from "../../../assets/HPDSxEngine/Section4Card41.png"
import Section4Card42 from "../../../assets/HPDSxEngine/Section4Card42.png"
import Section4Card43 from "../../../assets/HPDSxEngine/Section4Card43.png"
import Section4Card44 from "../../../assets/HPDSxEngine/Section4Card44.png"


const dataset = [
    {
      id: 1,
      title: "21st Century Philosophy",
      heading:"21st Century Philosophy Individual’s Mindset, Behaviors, Skills and Capabilities",
      description: "Unveiling the Essence: Navigating 21st Century Philosophy, Individual Mindset, Behaviors, Skills, and Capabilities",
      cards: [
        { id: 1, image: Section4Card11, description: "Optimal attitudes" },
        { id: 2, image: Section4Card12, description: "Global competence " },
        { id: 3, image: Section4Card13, description: "Mind workers deeper learning knowledge " },
        { id: 4, image: Section4Card14, description: "Knowledge construction" },
        { id: 5, image: Section4Card15, description: "Life long learning competence  " },
        // Add more cards as needed
      ],

      rightimage:Section4image1
    },
    {
      id: 2,
      title: "Mastering Skills",
      heading:"Mastering skills needs to run the race of life in 21st Century",
      description: "Empowerment for the Modern Era: Mastering Essential Skills to Thrive in the 21st Century",
      cards: [
        { id: 1, image: Section4Card21, description: "Flexible adapt to a changing society" },
        { id: 2, image: Section4Card22, description: "Develop transferable knowledge" },
        { id: 3, image: Section4Card23, description: "Ability to meet complex demands" },
        { id: 4, image: Section4Card24, description: "Ability to exhibit general transfer" },
        { id: 5, image: Section4Card25, description: "Positive Cognitive style " },
        { id: 6, image: Section4Card26, description: "Apply content knowledge" },
        { id: 7, image: Section4Card27, description: "Innovation, creative problem solving" },
        
      ],
      rightimage:Section4image2
    },
    {
      id: 3,
      title: "Skills for Today and Tomorrow",
      heading:"Skills for Today and Tomorrow",
      description: "Equipping you with the skills needed to excel today and adapt for tomorrow's challenges",
      cards: [
        { id: 1, image: Section4Card31, description: "Multiple area of skill and knowledge" },
        { id: 2, image: Section4Card32, description: "Get the job done" },
        { id: 3, image: Section4Card33, description: "Deeply understand the person people" },
        { id: 4, image: Section4Card34, description: "Mastering tasks" },
        { id: 5, image: Section4Card35, description: "Take business forward" },
        { id: 6, image: Section4Card36, description: "Non-routine problem solving" },
        { id: 7, image: Section4Card37, description: "Sense of personal effectiveness" },
        // Add more cards as needed
      ],
      rightimage:Section4image3
    },
    {
      id: 4,
      title: "Skills for High Performance",
      heading:"Skills for High Performance",
      description: "Unlock Your Potential: Elevate Your Performance with Essential Skills",
      cards: [
        { id: 1, image: Section4Card41, description: "Expert decision making" },
        { id: 2, image: Section4Card42, description: "Complex communication" },
        { id: 3, image: Section4Card43, description: "Expert thinking" },
        { id: 4, image: Section4Card44, description: "Strong verbal and quantitative literacy" }
        // Add more cards as needed
      ],
      rightimage:Section4image4
    },
    // Add more datasets for other buttons
  ];
  
  export default dataset;