import React from "react";
import RightSideImage from "../Templates/RightSideImage";
import smmain from "../../../assets/Publication/Section4_image.png";

const Section4 = () => {
  const data = {
    title: (
      <>
        Effortless{" "}
        <span className="font-bold">
          Publishing:
          <br></br>
          Intelligent Tools{" "}
        </span>
        for <span className="font-bold">Novice</span> and{" "}
        <span className="font-bold">Expert College Writers</span>
      </>
    ),
    description: (
      <>
        We simplify the publication process, saving you time and effort. From
        formatting assistance to meticulous editing, ensuring that your work
        stands out
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <RightSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section4;
