import React from "react";
import RightSideImage from "../Templates/RightSideImage";
import smmain from "../../../assets/Publication/Section2_image.png";

const Section1 = () => {
  const data = {
    title: (
      <>
        Express{" "}
        <span className="font-bold">
          {" "}
          Yourself,<br></br> Inspire
        </span>{" "}
        Others
      </>
    ),
    description: (
      <>
        Unleash creativity with customized publications on our platform.
        Personalize magazines, activity books, and more, empowering students to
        share captivating stories and inspire others. Join us in creating unique
        showcases.
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <RightSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section1;
