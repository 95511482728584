import React from "react";
import RightSideImage from "../Templates/RightSideImage";
import smmain from "../../../assets/HomePage/Institution/imain.webp";

const Section4 = () => {
  const data = {
    title: (
      <>
        AI-Powered Reports:
        <br></br>
        <span className="font-bold">Redefining Progress Monitoring</span>
      </>
    ),
    description: (
      <>
        Explore our platform with advanced AI-powered reporting for insightful
        analytics. Enhance user experience, track performance trends, and make
        best decisions.
      </>
    ),
    imageUrl: smmain,
  };

  return (
    <div>
      <RightSideImage
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
      />
    </div>
  );
};

export default Section4;
