import React from "react";
import { useState } from "react";
import { menus } from "./section4Dataset.js";
import dataSet from "./section4Dataset.js";
import useScreenSize from "../../components/Contexts/ScreenSizeContent";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const bgImageUrl =
  'url(" https://cdn-dynmedia-1.microsoft.com/is/image/microsoftcorp/Desktop-Product-Services-BG-2x?resMode=sharp2&op_usm=1.5,0.65,15,0&wid=3200&hei=2258&qlt=100&fmt=png-alpha&fit=constrain")';

function ProductsAndServices() {
    
    const screenWidth = window.innerWidth;
  
    const settings = {
      slidesToShow: screenWidth < 1023 ? 2 : 6, // Show 2 items for screen sizes less than 1023px, otherwise show 6 items
      slidesToScroll: 1,
      infinite: true,
      dots: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

  const [activeIndex, setactiveIndex] = useState(0);
  const screenSize = useScreenSize();
  const [activeButtonIndex, setactiveButtonIndex] = useState(null);
  const [gridOpacity, setGridOpacity] = useState(1);

  //TabClickAnimation
  const handleTabClick = (idx) => {
    // Set opacity to 0 when a menu button is clicked

    setTimeout(() => {
      setGridOpacity(0);
    }, 100);

    setactiveButtonIndex(null);

    setTimeout(() => {
      setactiveIndex(idx);
      setGridOpacity(1); // Set opacity back to 1 after a short delay (you can adjust the delay)
    }, 400);
  };

  const handleButtonClick = (idx) => {
    setactiveButtonIndex(idx);
  };

  return (
    <div
      className="h-auto bg-gray-300"
      style={{
        
        backgroundSize: "cover", // You can customize the background size
        backgroundRepeat: "no-repeat", // You can customize the background repeat
        width: "100%",
        backgroundPosition: "center",
      }}
    >
      {screenSize === "sm" || screenSize === "md" ? (
        <div className="max-w-screen-xl h-full px-6 mx-auto py-28 ">
          <p className="text-sm text-gray-500 tracking-wider font-semibold">
            PRODUCTS AND SERVICES
          </p>
          <h1 className="font-semibold text-2xl my-2">
          The Digital Learning Platform model is reinventing HUMANCARE Development
          </h1>

          <div className="h-auto py-4   ">
          <Slider {...settings}>
      {menus.map((menu, index) => (
        <div
          key={index}
          className={`py-2 px-4 font-semibold rounded-3xl overflow-hidden ${
            activeIndex === index
              ? "border-dotted border-4 bg-blue-500 text-white border-white "
              : "bg-gray-200 text-black "
          }`}
          onClick={() => handleTabClick(index)}
        >
          {menu}
        </div>
      ))}
    </Slider>
          </div>
          <div
            className="flex flex-col h-full w-full transition-opacity duration-500 ease-in-out"
            style={{ opacity: gridOpacity }}
          >
            <div
              className=" rounded-2xl w-full overflow-hidden"
              style={{
                backgroundImage: dataSet[activeIndex].bannerImg,
                backgroundSize: "cover", // You can customize the background size
                backgroundRepeat: "no-repeat", // You can customize the background repeat
                width: "100%",
                //   height: "80%", // Set a specific height, or adjust as needed
              }}
            >
              {/* <h1>This is the left portion {activeIndex} </h1> */}
              <div className="flex flex-col justify-end items-start text-white p-6  bg-gray-700 opacity-80 h-full w-full ">
                <h1 className="font-semibold text-3xl">
                  {dataSet[activeIndex].bannerTitle}
                </h1>
                <p className="text-xl my-6">{dataSet[activeIndex].bannerDec}</p>
                
              </div>
            </div>
            <div className="h-2/3 w-full  ">
              <div className="grid grid-cols-1 gap-5 my-5 h-full w-full ">
                {dataSet[activeIndex].items.map((item, index) => (
                  <a
                    // href=""
                    className="border border-gray-300 rounded-2xl  shadow-md hover:shadow-lg transition duration-300 hover:underline hover:color-blue-600"
                  >
                    <div className="flex flex-col justify-between items-start bg-white rounded-3xl h-full p-6 active:bg-purple-50">
                      <div>
                      <div className="text-blue-400 text-lg mb-4">TRELL</div>
                        <img
                          src={item.itemImg}
                          alt={item.name}
                          className="w-14 object-fit  rounded-md  border-blue-100 bg-white"
                        />
                        <p className="font-semibold text-xl my-4">
                          {item.itemTitle}
                        </p>
                        <h1>{item.itemDesc}</h1>
                      </div>

                      <button
                        className={`mt-4 text-blue-400 focus:outline-none border font-semibold py-2 hover:scale-125 hover:bg-neutral-100 hover:shadow-md ${
                          activeButtonIndex === index
                            ? "border-none "
                            : "border-none"
                        }`}
                        key={index}
                        onClick={() => handleButtonClick(index)}
                      >
                        Explore 
                      </button>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="max-w-screen-2xl h-full px-20 mx-auto py-32 ">
          <p className="text-sm text-gray-500 tracking-wider font-semibold">
            PRODUCTS AND SERVICES
          </p>
          <h1 className="font-semibold text-3xl my-2 w-[700px]">
          The Digital Learning Platform model is reinventing HUMANCARE Development
          </h1>
          <div className="flex space-x-3">
            {menus.map((menu, index) => (
              <button
                className={` py-2 px-4  my-6 font-semibold  rounded-3xl ${
                  activeIndex === index
                    ? " border-dotted border-4 bg-blue-500 text-white border-white "
                    : "bg-gray-200 text-black"
                }`}
                key={index}
                onClick={() => handleTabClick(index)}
              >
                {menu}
              </button>
            ))}
          </div>
          <div
            className="grid grid-cols-4 h-4/5 w-full gap-5 transition-opacity duration-500 ease-in-out"
            style={{ opacity: gridOpacity }}
          >
            <div
              className="col-span-1 rounded-2xl h-full w-full overflow-hidden"
              style={{
                backgroundImage: `url(${dataSet[activeIndex].bannerImg})`,
                backgroundSize: "cover", // You can customize the background size
                backgroundRepeat: "no-repeat", // You can customize the background repeat
                width: "100%",
                //   height: "80%", // Set a specific height, or adjust as needed
              }}
            >
              {/* <h1>This is the left portion {activeIndex} </h1> */}
              <div className="flex flex-col justify-end items-start text-white p-6  bg-gray-700 opacity-60 h-full w-full ">
                <h1 className="font-semibold text-3xl">
                  {dataSet[activeIndex].bannerTitle}
                </h1>
                <p className="text-xl my-6">{dataSet[activeIndex].bannerDec}</p>
                
              </div>
            </div>
            <div className="col-span-3  ">
              <div className="grid grid-cols-3 gap-5 h-full w-full ">
                {dataSet[activeIndex].items.map((item, index) => (
                  <a
                    // href=""
                    className="border border-gray-300 rounded-2xl  shadow-md hover:shadow-lg transition duration-300 hover:underline hover:color-blue-600"
                  >
                    <div className="flex flex-col justify-between items-start bg-white rounded-3xl h-full p-6 active:bg-purple-50">
                      <div>
                        <div className="text-blue-400 text-lg mb-4">TRELL</div>
                        <img
                          src={item.itemImg}
                          alt={item.name}
                          className="w-20 object-fit  rounded-md  border-blue-100 bg-white"
                        />
                        <p className="font-semibold text-2xl my-4">
                          {item.itemTitle}
                        </p>
                        <h1>{item.itemDesc}</h1>
                      </div>

                      <button
                        className={`mt-4 text-blue-400 focus:outline-none border font-semibold hover:scale-110 ${
                          activeButtonIndex === index
                            ? "border-none "
                            : "border-none"
                        }`}
                        key={index}
                        onClick={() => handleButtonClick(index)}
                      >
                        Explore 
                      </button>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductsAndServices;