import SampleImage1 from "../../../assets/Internship/Section3image1.png"
import SampleImage2 from "../../../assets/Internship/Section3image2.png"
import SampleImage3 from "../../../assets/Internship/Section3image3.png"
import SampleImage4 from "../../../assets/Internship/Section3image4.png"


const dataset = [
    {
      id: 1,
      title: <span className="text-yellow-500">Internship Experience</span>,
      description: <>You’ll spend nine to twelve weeks in one of our business areas between Feb and August, whilst getting a taste for what it’s really like to work in the largest professional services firm in the world.</>,
      image: SampleImage1
    },
    {
      id: 2,
      title: <span className="text-yellow-500">Tailored Internship Tracks</span>,
      description: "Each of our internships has a different focus, so your work will differ depending on the kind of role you choose. If it’s UX/UI Design, for example, you’ll be working with a team on the systems design, ways to improve them and reduce risk. In Middleware, its all about working in a team that ADVISES  clients on buying or selling a business for example. Whereas, in Technology, you’re more likely to be in a team designing new systems or developing IT strategy. ​",
      image: SampleImage2
    },
    {
      id: 3,
      title: <span className="text-yellow-400">Collaborative Problem-Solving</span>,
      description: "All of our projects are about problem solving, which happens best when we work collaboratively. Throughout your internship, you’ll connect with colleagues across different teams to tackle challenges for our clients. As well as this, you’ll develop the kind of rounded business skills that will serve you well regardless of where your future takes you.​",
      image: SampleImage3
    },
    {
        id: 4,
        title: <span className="text-yellow-400">Future-Focused Transformation</span>,
        description: "With more projects focused on transformation in nature and the latest digital developments, we approach every client with a clear eye on the future. It’s that vision that enables us to help the biggest businesses make some of their biggest decisions.",
        image: SampleImage4
      },
  ];
  
  export default dataset;
  