import React from 'react'
import Btn_S1 from '../../assets/events_img1.png'
import Btn_S2 from '../../assets/events_img2.jpg'
import S1 from '../../assets/events_img3.jpg'
import S2 from '../../assets/events_img4.jpg'
import S3 from '../../assets/events_img5.jpg'
import BenImg1 from '../../assets/BenImg1.jpg'
import BenImg2 from '../../assets/BenImg2.jpg'
import BenImg3 from '../../assets/BenImg3.jpg'
import BenImg4 from '../../assets/BenImg4.jpg'
import BenImg5 from '../../assets/BenImg5.jpg'
import BenImg6 from '../../assets/BenImg6.jpg'
import SlideWithImage from "../components/Templates/SlideWithImage"
import HeroSection from "../components/CreateProgram/HeroSection"
import ContSlide_Events from '../components/Events/ContSlide_Events'
import Grid_2x3 from "../components/Templates/Grid_2x3"

const Events = () => {
  const heroSectionInfo = {
    img:Btn_S1,
    title: 'Join and Connect with experts on Your favourite Subject',
    subTit: <div className="font-normal text-base">Engage in events a powerful way to connect with your subject matter gurus in real time</div>,
    btn: {txt: 'Explore Events'}
  };

  const btnSlideInfo1 = {
    img:Btn_S2,
    title: 'Events for everyone',
    subTit: <div className="font-normal text-base">Join from a wide variety of events catering both the online and offline space</div>,
    btn: {txt: 'View More'}
  };

  const slideInfo1={
    img:S1,
    title: <div className='font-normal'>Don't Just attend, but be a hand in the <span className='text-custom-green-1'>Success of events.</span></div>,
    subTit: <div className="font-normal">Join the Exclusive team of voulnteers, and play a pivotal role and be a part of the transformation</div>,
  };

  const slideInfo2={
    img:S2,
    title: 'Regularly Held Competitions to assess your knowledge',
    subTit: <div className="font-normal">Periodically organized contests aimed at evaluating and enhancing your knowledge base</div>,
  };

  const slideInfo3={
    img:S3,
    title: 'Invite your peers along with you in the journey',
  };

  const data = [
    {img: BenImg1, title: "Skill Development", subTtl: 'Acquire new skills or enhance existing ones, under the guidance of subject guru'},
    {img: BenImg2, title: "Networking Opportunities", subTtl: 'Meet new people, expand your professional and social networks.'},
    {img: BenImg3, title: "Increased Confidence", subTtl: 'Volunteering can boost self-esteem and confidence allowing you to make a positive impact.'},
    {img: BenImg4, title: "Enhanced Communication Skills", subTtl: 'Engage with diverse groups of people, improving your communication skills.'},
    {img: BenImg5, title: "Leadership Opportunities", subTtl: 'You may have the chance to take on leadership roles, honing your leadership abilities.'},
    {img: BenImg6, title: "Career Exploration", subTtl: 'Explore your potential career paths or industries that you might not have considered before.'},
  ]
  return (
    <div>
      <HeroSection slideInfo={heroSectionInfo} contStyle ={` md:py-20 flex-col md:flex-row-reverse events_hero_bg text-white`}/>
      <SlideWithImage slideInfo={btnSlideInfo1} contStyle ={`bg-white md:py-20 py-10 `}/>
      <SlideWithImage slideInfo={slideInfo1} contStyle ={`bg-white md:py-20 py-10 flex-row-reverse`}/>
      <Grid_2x3 title= {'Harvesting Benefits through Event Involvement'} data={data}/>
      <SlideWithImage slideInfo={slideInfo2} contStyle ={`bg-white  md:py-20 py-10 py-7 flex-row-reverse`}/>
      <ContSlide_Events title={'Present your thought through panel discussions'} imgWid={'w-5/6'} subTit={'Engage in collaborative discussions, presenting your ideas and opinions among a diverse group, fostering insightful exchanges and meaningful dialogues'}/>
      <SlideWithImage slideInfo={slideInfo3} contStyle ={`bg-white  md:py-20 py-10 py-7 flex-row-reverse`}/>
    </div>
  )
}

export default Events