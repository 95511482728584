import React from 'react';
import backgroundImage from '../../../assets/Council/Section5imagebg.png'; // Import the background image

const SectionWithCards = () => {
  return (
    <section className="relative bg-cover bg-center py-12 xl:py-24 px-4 md:px-8 lg:px-16 xl:px-24" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="  md:px-4">
        <h2 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-white font-bold mb-8 text-center" style={{ lineHeight: '140%' }}>Ready to join our council?</h2>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 xl:gap-8">
          {/* Card 1 */}
          <div className="relative bg-transparent border border-white rounded-lg overflow-hidden group transition duration-300 hover:bg-blue-400 hover:border-transparent text-center px-4 xl:px-12">
            <div className="p-3 md:p-6">
              <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-bold text-white mb-2" >Register a Council</h3>
              <p className="text-white text-lg xl:text-xl" style={{ lineHeight: '140%' }}>Join us and embark on a journey of endless possibilities! Register now to start your adventure.</p>
            </div>
          </div>
          {/* Card 2 */}
          <div className="relative bg-transparent border border-white rounded-lg overflow-hidden group transition duration-300 hover:bg-blue-400 hover:border-transparent text-center px-4 xl:px-12">
            <div className="p-3 md:p-6">
              <h3 className="text-xl md:text-xl lg:text-2xl xl:text-3xl font-bold text-white mb-2">Join a Council</h3>
              <p className="text-white text-lg xl:text-xl"style={{ lineHeight: '140%' }}>Join us and embark on a journey of endless possibilities to start your adventure.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionWithCards;
