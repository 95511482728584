import Section2Img1 from "../../../assets/Mentor/Section2_Img1.jpg";
import Section2Img2 from "../../../assets/Mentor/Section2_Img2.jpg";
import Section2Img3 from "../../../assets/Mentor/Section2_Img3.jpg";

const mentoringData = [
  {
    imageUrl: Section2Img1,
    title: "Building and Displaying Leadership Qualities",
  },
  {
    imageUrl: Section2Img2,
    title: "Building Network & Community",
  },
  {
    imageUrl: Section2Img3,
    title: "Developing a better understanding of other Culture and Diversity",
  },
];

export default mentoringData;
