import img1 from "../../../assets/Volunteer/skill-development.jpg";
import img2 from "../../../assets/Volunteer/self-confidence.jpg";
import img3 from "../../../assets/Volunteer/team-work.jpg";
import img4 from "../../../assets/Volunteer/time-management.jpg";
import img5 from "../../../assets/Volunteer/gratitude.jpg";
import img6 from "../../../assets/Volunteer/review.jpg";

const volunteerData = [
  {
    id: 1,
    title: "Skill Development",
    description: "Enhances existing skills and cultivates new ones.",
    imageUrl: img1,
    alt: "Skill Development",
  },
  {
    id: 2,
    title: "Self-Confidence",
    description: "Fosters personal development and boosts self-confidence.",
    imageUrl: img2,
    alt: "Self-Confidence",
  },
  {
    id: 3,
    title: "Teamwork",
    description: "Improves collaboration and teamwork abilities.",
    imageUrl: img3,
    alt: "Teamwork",
  },
  {
    id: 4,
    title: "Time Management",
    description: "Helps in effectively managing time and resources.",
    imageUrl: img4,
    alt: "Time Management",
  },
  {
    id: 5,
    title: "Gratitude and Empathy",
    description:
      "Fosters appreciation and empathy towards others' efforts and situations.",
    imageUrl: img5,
    alt: "Gratitude and Empathy",
  },
  {
    id: 6,
    title: "Learning from Others",
    description:
      "Opportunity to learn from experienced individuals in the field.",
    imageUrl: img6,
    alt: "Learning from Others",
  },
];

export default volunteerData;
