import React from "react";
import commonDataSet from "../DataSet/commonDataSet";
import LeftSideImageFlexTemplate from "../Templates/LeftSideImageFlexTemplate";

const ConferenceSection1 = () => {
  const data = commonDataSet[10];

  return (
    <div>
      <LeftSideImageFlexTemplate
        title={data.title}
        description={data.description}
        imageUrl={data.imageUrl}
        bgColor={data.bgColor}
      />
    </div>
  );
};

export default ConferenceSection1;
