import React from 'react'
import HeroImg from '../../assets/summit_hero_bg.png'
import S1 from '../../assets/summit_s1.png'
import S2 from '../../assets/summit_s2.png'
import S3 from '../../assets/summit_s3.png'
import gImg1 from '../../assets/summit_sm_img1.png'
import gImg2 from '../../assets/summit_sm_img2.png'
import gImg3 from '../../assets/summit_sm_img3.png'
import SlideWithImage from "../components/Templates/SlideWithImage"
import HeroSection from "../components/Summits/HeroSection"
import Grid_1x3 from "../components/Summits/Grid_1x3"

const Summits = () => {
  const heroSectionInfo = {
    img:HeroImg,
    title: 'Global Leaders Unite: Forging Partnerships, Shaping Solutions',
    subTit: <div className="font-normal text-base">Where influential leaders collaborate to forge powerful partnerships and craft innovative solutions for a better world.</div>,
    btn: {txt: 'Browse all Summits'}
  };

  const slideInfo1={
    img:S1,
    title: <div className='font-normal'>Empowering Skills and Mental Wellness in Your Vicinity</div>,
    subTit: <div className="font-normal">From skill development to mental awareness explore the broad range of summits happening around you</div>,
    preTitle: 'Discover Diverse Summits '
  };

  const slideInfo2={
    img:S2,
    title: 'Empowering Entrepreneurs: Maximizing Summit Benefits for Business Success',
    subTit: <div className="">Get an invaluable chance to build connections with a diverse group of professionals, including potential partners, investors, mentors, and industry experts that can foster business growth, collaborations, and mentorship.</div>,
  };

  const slideInfo3={
    img:S3,
    title: 'Represent your organization and bring more strategic connections for your organization to bolster collaborative ventures and amplify its impact in the industry.',
  };

  const data = [
    {
      img: gImg1, 
      title: <div className='text-black'> Access to Exclusive Information</div>, 
      subTtl: 'Gain access to exclusive research, data, or industry reports shared during the summit, providing valuable information not readily available elsewhere.'
    },
    {
      img: gImg2, 
      title: <div className='text-black'> Cross-industry Learning</div>, 
      subTtl: 'Explore how insights and practices from other industries or sectors can be applied innovatively in your own domain, fostering cross-pollination of ideas.'
    },
    {
      img: gImg3, 
      title: <div className='text-black'> Funding Opportunities</div>, 
      subTtl: 'Explore opportunities for funding, grants, or investment in innovative projects or initiatives showcased during the summit.'
    },
  ]
  return (
    <div>
      <HeroSection slideInfo={heroSectionInfo} contStyle ={`pt-4  md:pt-20 flex-col md:flex-row-reverse events_hero_bg text-white`}/>
      <SlideWithImage slideInfo={slideInfo1} contStyle ={`bg-white  py-16 `}/>
      <Grid_1x3 title= {'Summit brings you'} data={data}/>
      <SlideWithImage slideInfo={slideInfo2} contStyle ={`bg-neutral-100  md:py-20 py-7 flex-row-reverse`}/>
      <SlideWithImage slideInfo={slideInfo3} contStyle ={`bg-white  md:py-20 py-7`}/>
    </div>
  )
}

export default Summits