import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import office from "../../../assets/ContactUs/office image.jpg";

import emailjs from "emailjs-com";
import { toast } from "react-toast";

export default function Section2() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    organization: "",
    state: "",
    city: "",
    message: "",
    heardFrom: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the form data via EmailJS
    emailjs
      .send(
        "service_rccaqj2",
        "template_kgh4azo",
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          organization: formData.organization,
          state: formData.state,
          city: formData.city,
          message: formData.message,
          heardFrom: formData.heardFrom,
          to_email: "support@hpdstech.com",
        },
        "iFecSwwXz4cfQJYJ5"
      )
      .then((response) => {
        console.log("Email successfully sent!", response.status, response.text);
        toast.success("Query Sent Successfully");
      })
      .catch((err) => {
        console.error("Failed to send email. Error: ", err);
        toast.error("Failed to send email. Error: ", err);
      });

    // Reset the form data
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      organization: "",
      state: "",
      city: "",
      message: "",
      heardFrom: "",
    });

    // Resets the form fields
    e.target.reset();
  };

  return (
    <div className="flex mt-8 lg:mt-20 rounded-xl p-3 md:p-6 lg:p-6 xl:p-12 shadow-2xl bg-neutral-100 mx-6 xl:mx-32 lg:mx-16 md:mx-10">
      <div className="grid grid-cols-12 gap-0">
        <div className="col-span-12 px-6 xl:px-64 lg:px-44 md:px-32 pt-6 pb-12">
          <div className="flex-col justify-start items-center gap-4 inline-flex">
            <div className="text-center text-stone-900 text-xl xl:text-4xl lg:text-3xl md:text-2xl  font-semibold">
              Looking for a specific answer or to talk to someone?
            </div>
            <div className="opacity-60 text-center text-stone-900 text-xs md:text-sm lg:text-md xl:text-lg font-semibold">
              Let us know, Our team of experts is on hand to answer your
              questions.
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 border rounded-t-xl md:rounded-l-xl">
          <form onSubmit={handleSubmit}>
            <div className="w-full px-6 py-6 md:py-6 lg:py-4 xl:py-8 bg-white flex-col justify-between items-start gap-8 xl:gap-6 inline-flex whitespace-nowrap">
              <div className="flex-col xl:flex-row flex justify-between items-start gap-6 xl:gap-3 inline-flex">
                <div className="h-8 md:h-10 lg:h-12 xl:h-20 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-slate-900 font-semibold text-xs lg:text-sm">
                    First Name
                  </div>
                  <input
                    className="h-6 md:h-8 lg:h-10 xl:h-12 pl-2 lg:pl-4 pr-0 md:pr-20 lg:pr-44 xl:pr-11 py-3 rounded border border-gray-400 justify-start items-center placeholder:text-gray-400 text-base inline-flex"
                    placeholder="Enter your first name"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="h-8 md:h-10 lg:h-12 xl:h-20 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-slate-900 text-xs lg:text-sm font-semibold">
                    Last Name
                  </div>
                  <input
                    className="h-6 md:h-8 lg:h-10 xl:h-12 pl-2 lg:pl-4 pr-0 md:pr-20 lg:pr-44 xl:pr-11 py-3 rounded border border-gray-400 justify-start items-center placeholder:text-gray-400 text-base inline-flex"
                    placeholder="Enter your last name"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="h-8 md:h-10 lg:h-12 xl:h-20 flex-col justify-start items-start gap-2 flex">
                <div className="text-slate-900 text-xs lg:text-sm font-semibold">
                  Email
                </div>
                <input
                  className="h-6 md:h-8 lg:h-10 xl:h-12 pl-2 lg:pl-4 pr-0 md:pr-20 lg:pr-44 xl:pr-72 py-3 bg-white rounded border border-gray-400 justify-start items-center inline-flex placeholder:text-gray-400 text-base"
                  placeholder="Enter your email"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>

              <div className="h-8 md:h-10 lg:h-12 xl:h-20 flex-col justify-start items-start gap-2 flex">
                <div className="text-slate-900 text-xs lg:text-sm font-semibold">
                  College/University/Organization
                </div>
                <input
                  className="h-6 md:h-8 lg:h-10 xl:h-12 pl-2 lg:pl-4 pr-0 md:pr-20 lg:pr-44 xl:pr-72 py-3 bg-white rounded border border-gray-400 justify-start items-center inline-flex placeholder:text-gray-400 text-base"
                  placeholder="Enter your college/university/organization name"
                  type="text"
                  name="organization"
                  value={formData.organization}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex-col xl:flex-row flex justify-between items-start gap-6 xl:gap-3 inline-flex">
                <div className="h-8 md:h-10 lg:h-12 xl:h-20 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-slate-900 font-semibold text-xs lg:text-sm">
                    State
                  </div>
                  <input
                    className="h-6 md:h-8 lg:h-10 xl:h-12 pl-2 lg:pl-4 pr-0 md:pr-20 lg:pr-44 xl:pr-11 py-3 rounded border border-gray-400 justify-start items-center placeholder:text-gray-400 text-base inline-flex"
                    placeholder="Enter your state"
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="h-8 md:h-10 lg:h-12 xl:h-20 flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-slate-900 font-semibold text-xs lg:text-sm">
                    City
                  </div>
                  <input
                    className="h-6 md:h-8 lg:h-10 xl:h-12 pl-2 lg:pl-4 pr-0 md:pr-20 lg:pr-44 xl:pr-11 py-3 rounded border border-gray-400 justify-start items-center placeholder:text-gray-400 text-base inline-flex"
                    placeholder="Enter your city"
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="h-32 xl:h-32 flex-col justify-start items-start gap-2 flex">
                <div className="text-slate-900 text-xs lg:text-sm font-semibold">
                  Message
                </div>
                <textarea
                  className="h-32 xl:h-28 pl-2 lg:pl-4 pr-0 md:pr-20 lg:pr-44 xl:pr-72 pt-3 pb-16 bg-white rounded border border-gray-400 justify-start items-center inline-flex placeholder:text-gray-400 text-base"
                  placeholder="Tell us about your query..."
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </div>

              <div className="h-20 flex-col justify-start items-start gap-2 inline-flex">
                <div className="text-slate-900 text-xs lg:text-sm font-semibold">
                  Where did you hear about us?
                </div>
                <div className="relative">
                  <select
                    className="w-44 md:w-56 lg:w-72 h-12 pl-2 lg:pl-4 pr-16 py-3 bg-white rounded border border-gray-400 text-gray-900 text-base appearance-none"
                    name="heardFrom"
                    value={formData.heardFrom}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled selected>
                      Please select
                    </option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex mr-4 items-center pointer-events-none">
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="text-gray-400"
                    />
                  </div>
                </div>
              </div>

              <div className="h-10 xl:h-14 px-6 py-3 bg-blue-500 rounded-xl justify-center items-center gap-3 inline-flex">
                <button
                  type="submit"
                  className="text-white text-xl font-semibold hover:bg-neutral-100 hover:shadow-md"
                >
                  Submit
                </button>
                <div>
                  <FontAwesomeIcon icon={faArrowRight} className="text-white" />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="col-span-12 md:col-span-6 border rounded-b-xl md:rounded-r-xl">
          <img src={office} alt="office" className="h-full w-full" />
        </div>
      </div>
    </div>
  );
}
