import React from "react";
import Section1 from "../../components/CustomerSuccess/Section1";
import Section2 from "../../components/CustomerSuccess/Section2";
import Section3 from "../../components/CustomerSuccess/Section3";
import Section4 from "../../components/CustomerSuccess/Section4";
import Section5 from "../../components/CustomerSuccess/Section5";
import Section6 from "../../components/CustomerSuccess/Section6";
import Section7 from "../../components/CustomerSuccess/Section7";
import Section8 from "../../components/CustomerSuccess/Section8";
import Section9 from "../../components/CustomerSuccess/Section9";



export const Customer = () => {
    return (
        <div className="flex flex-col font-poppins">
    <Section1/>
    <Section2/>
    <Section3/>
    <Section4/>
    <Section5/>
    <Section6/>
    <Section7/>
    
    </div>
  )
}