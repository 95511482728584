import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import bgImage from "../../../assets/Collaboratory/sec5_img1.jpg";
import arrow from "../../../assets/Collaboratory/sec6_arrow.png";



const withHPDSx = [
  <>Promote diversity and fair treatment for everyone</>,
  <>Encourage others to develop broader skills.</>,
  <>Do the right thing.</>,
  <>learn from experience and setbacks.</>,
  <>Provide balanced, impartial advice.</>,
  <>Do your job to the best of your ability.</>,
  <>Committed to continuously developing your skills and to developing other people.</>,
  <>Respond quickly, flexibly and positively to change using it as an opportunity.</>,
  <>Deliver results.</>,
  <>Know what needs to be done and willingly take responsibility for making it happen.</>,
  <>Have a strong focus on customers and suppliers.
  understand your role in delivering a project.</>,

];

const woHPDSx = [
  <>You are not aware of the needs and values of others.</>,
  <>You work alone, rarely involving others, which threatens the overall group or team aims.</>,
  <>You do not co-operate with other people.</>,
  <>You leave staff and colleagues to struggle.</>,
  <>You blame others for your mistakes.</>,
  <>You do no more than the minimum amount of work.</>,
  <>You constantly check up on your team as you do not trust them to deliver.</>,

];

const data1 = (
  <>
   Do You Have Challenges in building workforce talent, Streamlining Customer Experience  and <span className="text-blue-500">Smooth Integration of AI </span>
   Solutions? 
    {" "}
  </>
);

const data2 = (
  <>
    <span className="text-blue-400"> Creating Impactful</span> and{" "}
    <span className="text-blue-400">  Recreational Working Environment</span> for your people and organisation saving you time and hassle.{" "}
    
  </>
);
const item3 = <>Get Started In Less Than 60 Seconds • Cancel Anytime</>;
const Section5 = () => {
  const handleInputChange = (e) => {
    console.log("Button pressed");
  };
  return (
    <div className="w-full  flex flex-col xl:px-24 lg:px-20 md:px-16 px-4 justify-center items-center py-4 md:py-8 lg:py-16">
      <div className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-bold xl:py-12 lg:py-10  text-center"style={{ lineHeight: '140%' }}>
      With Talent collaboratory  how a skills-based approach can help  <span className="text-blue-400 ">employers expand talent pools and retain great workers—even through economic uncertainty</span>
      </div>

      <div className="flex flex-col xl:px-28 lg:px-16 md:px-12 px-4 items-center justify-center h-auto">

        <div className="grid grid-cols-1 md:grid-cols-2  xl:text-3xl lg:text-2xl md:text-xl text-lg font-semibold  xl:p-8 lg:p-6 md:p-4 p-2 ">

          <div className="col-span-1 xl:p-8 lg:p-6 md:p-4 p-2">
            <p className="">{data1}</p>
            <div className="flex justify-end">
              <img
                src={arrow}
                alt="Image"
                className=" h-full w-1/3 object-contain "
              />
            </div>
          </div>
          <div className="col-span-1 flex flex-col justify-end ">
            <div>{data2}</div>
          </div>
        </div>

      </div>
      <div
        className="h-4/5 flex justify-between items-center text-white rounded-2xl"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* Left Section */}
        <div className="grid-cols-1 md:grid-cols-2 flex flex-col md:flex-row">
          <div className="w-full  h-full font-semibold xl:px-24 lg:px-16 md:px-8 px-2 flex flex-col items-center xl:py-24 lg:py-20 md:py-16 py-2">
            <h2 className="xl:text-4xl lg:text-3xl md:text-2xl text-xl xl:mb-12 lg:mb-10 md:mb-8 mb-4 pt-16">
              With HPDSx
            </h2>
            <ul>
              {withHPDSx.map((list) => (
                <li className="flex items-center xl:text-2xl lg:text-xl md:text-lg text-base xl:mb-8 lg:mb-6 md:mb-4 mb-2 justify-start">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-white bg-green-500 rounded-full p-1 xl:mr-5 lg:mr-4 md:mr-3 mr-2"
                  />
                  <span>{list}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="w-full   h-full font-semibold xl:px-24 lg:px-16 md:px-8 px-2 flex flex-col items-center xl:py-24 lg:py-20 md:py-16 py-4">
            <h2 className="xl:text-4xl lg:text-3xl md:text-2xl text-xl xl:mb-12 lg:mb-10 md:mb-8 mb-6 pt-16 ">
              Without HPDSx
            </h2>
            <ul>
              {woHPDSx.map((list) => (
                <li className="flex items-center xl:text-2xl lg:text-xl md:text-lg text-base xl:mb-8 lg:mb-6 md:mb-4 mb-2 justify-start">
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-white bg-red-500 rounded-full p-[5px] xl:mr-5 lg:mr-4 md:mr-3 mr-2"
                  />
                  <span>{list}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="xl:mt-8 lg:mt-6 md:mt-4 mt-2 flex flex-col justify-center items-center">
        <div className="xl:py-4 lg:py-3 py-2 font-semibold">
          <button
            className="text-white bg-blue-500 px-6 py-3  xl:text-lg lg:text-base md:text-sm text-xs rounded-full hover:border hover:border-blue-500 hover:text-blue-500 hover:bg-transparent"
            type="submit"
            onClick={handleInputChange}
          >
            Get Started Now
            <FontAwesomeIcon icon={faAngleRight} className="ml-2" />
          </button>
        </div>
        <div className="text-xs">{item3}</div>
      </div>
      {/* Background Image */}
    </div>
  );
};

export default Section5;
