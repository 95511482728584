import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import CoverImg1 from "../../../assets/Conference/CoverImg1.png";
import CoverImg2 from "../../../assets/Conference/CoverImg2.png";

const LandingCover = () => {
  return (
    <div className="w-full h-full bg-blue-200">
      <div className="grid grid-cols-12">
        <div className="order-3 md:order-1 col-span-12 md:col-span-6 ">
          <div className="flex flex-col pl-6 py-8 xl:pl-28 xl:pr-6 lg:pl-20 lg:pr-8 xl:py-32 lg:py-36 md:pl-12 md:pr-3 md:py-28">
            <div className="text-blue-700 mb-6 text-xl xl:text-4xl lg:text-3xl md:text-xl font-semibold font-Segoe UI">
              Converge & Innovate: Uniting Minds, Shaping Futures
            </div>
            <div className="hidden md:block">
              <div className="text-blue-700 mb-1 font-semibold text-xs xl:text-md lg:text-sm md:text-xs">
                Join thought leaders and pioneers across diverse fields <br />
                to collaborate, innovate, and forge a path towards a <br />
                transformative future at Conference
              </div>
            </div>
            <div className="block md:hidden">
              <div className="text-blue-700 mb-1 font-semibold text-xs xl:text-md lg:text-sm md:text-xs">
                Join thought leaders and pioneers across diverse fields
                to collaborate, innovate, and forge a path towards a
                transformative future at Conference
              </div>
            </div>

            <div className=" mt-4 px-4 py-2 w-fit hover:bg-white hover:text-white cursor-pointer rounded-full border border-blue-700 hover:border-black flex items-center justify-start ">
              <div className="text-xs font-bold text-blue-700 hover:text-blue-500">
                Browse all Conferences
              </div>
              <FontAwesomeIcon
                icon={faChevronRight}
                className=" text-blue-700 hover:text-blue-500 ml-2"
              />
            </div>
          </div>
        </div>
        <img
          className="order-1 md:order-2 col-span-6 md:col-span-3 h-full w-full xl:object-cover lg:object-fit md:object-fit"
          src={CoverImg1}
          alt="Seminar"
        ></img>
        <img
          className="order-2 md:order-3 col-span-6 md:col-span-3 h-full w-full xl:object-cover lg:object-fit md:object-fit"
          src={CoverImg2}
          alt="Speaker"
        ></img>
      </div>
    </div>
  );
};

export default LandingCover;
