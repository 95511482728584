import React from "react";
import BgImg from "../../../assets/Scientist/BgCoverImg.jpg";
import CoverSideImg from "../../../assets/Scientist/ScientistSideImg.jpg";

const LandingCover = () => {
  return (
    <div
      className="max-w-screen-xl h-full md:h-[70vh] flex bg-contain bg-center mt-16 xl:px-24 xl:py-24 lg:px-16 lg:py-24 lg:pt-36 md:px-8 md:pt-6 md:pb-0 xl:mx-12 xl:mt-16 lg:mx-12 lg:mt-2 md:mr-8 md:mt-0 md:ml-12 md:mb-0"
      style={{ backgroundImage: `url(${BgImg})` }}
    >
      <div className="w-full h-full flex flex-col md:flex-row">
        <div className="flex flex-col flex-1 mx-6">
          <div className="mb-0 md:mb-4 pb-2 font-semibold text-2xl xl:text-4xl md:text-2xl lg:text-3xl lg:pt-1 md:pt-14 cursor-pointer">
            <div className="mb-0.5 md:mb-2">
              <div className="hidden md:block">
                Cultivate the{" "}
                <span className="font-bold">
                  Young Leaders
                  <br />
                </span>
                of tomorrow
              </div>
              <div className="block mb-6 md:hidden">
                Cultivate the{" "}
                <span className="font-bold">
                  Young Leaders{" "}
                </span>
                of tomorrow
              </div>
            </div>
          </div>
          <div className="mb-1 font-normal text-sm xl:text-xl lg:text-md md:text-xs cursor-pointer">
            <div>
              Help young minds become successful learners, responsible citizens,
              and effective contributors
            </div>
          </div>
        </div>
        <div className="flex-1 flex justify-end items-center">
          <div
            className="w-64 h-72 md:w-full xl:h-[580px] lg:h-[400px] md:h-[290px] bg-contain xl:bg-contain lg:bg-cover md:bg-cover bg-no-repeat xl:ml-44 lg:ml-28 md:ml-24 mt-8 lg:pt-8 md:pt-32"
            style={{ backgroundImage: `url(${CoverSideImg})` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
