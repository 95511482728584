import React from "react";
import expertData from "./eightSectionData";

const EightSection = () => {
  return (
    <div className="max-w-screen-xl mx-6 xl:mx-28 lg:mx-24 md:mx-16 py-6 md:py-12 bg-white">
      <div className="text-2xl font-semibold font-Poppins pb-8 text-center md:text-left">
        Learn from Experts
      </div>
      <div className="grid grid-cols-8 gap-6">
        {expertData.map((expert) => (
          <div
            key={expert.id}
            className="col-span-4 md:col-span-2 flex-col flex justify-start items-center cursor-pointer mx-4"
          >
            <img
              className="object-cover rounded-full w-32 h-24 md:w-24 md:h-24 lg:w-36 lg:h-36 xl:w-48 xl:h-48 mb-2 cursor-pointer"
              src={expert.imageUrl}
              alt={expert.name}
            />
            <div className="text-center text-md font-semibold font-Poppins ">
              {expert.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EightSection;
