import React from "react";
import hero_img from "../../../assets/NonProfitOrganisation/image_1.png";
import bgimg from "../../../assets/NonProfitOrganisation/Section1imagebg.png"

const LandingCover = () => {
  return (
    <div className="w-full md:h-[90vh] grid grid-cols-12 gap-0 md:gap-4 xl:gap-8 pl-2 px-4 xl:pt-12 xl:px-12 xl:py-12 lg:px-10 lg:py-8 md:px-8 md:py-4" style={{ backgroundImage: `url(${bgimg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="xl:pt-20 px-4 text-black md:px-5 order-2 md:order-1 col-span-12 md:col-span-6 flex flex-col cursor-pointer  justify-center items-center">
        <div className="mb-0 md:mb-2 pb-4 md:pb-1 xl:pb-4 font-bold text-3xl md:text-4xl xl:text-6xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight xl:leading-tight">
          Our commitment to nonprofits
        </div>
        <div className="text-lg md:text-xl lg:text-2xl xl:text-3xl py-4 lg:py-12">
          <div>
            We’re committed to delivering relevant, affordable and innovative
            Technical/ Functional solutions to help nonprofits tackle the
            world’s biggest challenges.
          </div>
        </div>
      </div>

      <div className="order-1 md:order-2 col-span-12 md:col-span-6 flex flex-col justify-center items-center py-2 xl:py-12 md:py-1 lg:py-8">
        <div className="w-full">
          <img src={hero_img} alt="blank" className="w-full md:w-5/6" />
        </div>
      </div>
    </div>
  );
};

export default LandingCover;
