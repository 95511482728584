import React, { useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import Section16imagebg from '../../../assets/HPDSxMenu/Section16imagebg.png'; // Import the background image

const Section16 = () => {
    const textRef = useRef(null);
    const imageRef = useRef(null);

    const [{ opacity: textOpacity }, setTextSpring] = useSpring(() => ({ opacity: 0}));
    const [{ opacity: imageOpacity }, setImageSpring] = useSpring(() => ({ opacity: 0}));

    const handleScroll = () => {
        const textRect = textRef.current.getBoundingClientRect();
        const imageRect = imageRef.current.getBoundingClientRect();

        // Calculate whether text is visible within the viewport
        const textVisible = textRect.top < window.innerHeight && textRect.bottom >= 0;

        // Calculate whether image is visible within the viewport
        const imageVisible = imageRect.top < window.innerHeight && imageRect.bottom >= 0;

        // Update text opacity based on visibility
        setTextSpring({ opacity: textVisible ? 1 : 0, config: { duration: 1000, delay: 500 } });

        // Update image opacity based on visibility
        setImageSpring({ opacity: imageVisible ? 1 : 0, config: { duration: 1000, delay: 500 } });
    };

    // Attach scroll event listener
    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        // Remove scroll event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <section className="w-full relative h-full max-w-screen">
            {/* Background image */}
            <div className='max-w-screen'>
                <div
                    className="absolute inset-0 bg-cover md:bg-contain lg:bg-cover z-0"
                    style={{ backgroundImage: `url(${Section16imagebg})` }}
                >
                    {/* Overlay */}
                    <div className="absolute inset-0" />
                </div>
                {/* Content */}
                <div className="relative z-20 py-6 md:py-4 lg:py-20 text-center flex flex-col justify-around h-full">
                    {/* Animated content */}
                    <animated.div style={{ opacity: textOpacity }}>
                        {/* Heading */}
                        <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold mb-8 md:px-20 lg:px-44 xl:px-80"style={{ color: 'rgb(0, 121, 255)' }}>We build a movement of Transformation</h2>
                        {/* Card */}
                        <div ref={textRef} className="w-5/6 md:w-4/5 mx-auto bg-white rounded-lg shadow-lg p-6 bg-slate-400 bg-opacity-80 md:mt-0 lg:mt-12 xl:mt-60 flex flex-col justify-end">
                            <p className="text-xl md:text-xl lg:text-2xl text-blue-600 font-semibold">Work for the glory of the country and countrymen. <span className='text-green-700'>Who are your brothers and sisters speaking different dialects</span> <span className='text-red-700'>give due respect to the faiths and aspirations of the people</span> <span className='text-yellow-700'>everyone is here for complete lying the karmic cycle of life</span> countless are the resources of mother earth from whom flow of the rivers of health, wealth and happiness in hundred streams, <span className='text-blue-900'>worship mother land as you worship the cosmic divine power</span> <span className='text-green-700'>from time eternal, the mother earth is giving the life to her children we all owe debt to her…</span>.. Atharva veda 12.1.45​</p>
                        </div>
                    </animated.div>
                    <animated.div style={{ opacity: imageOpacity }}>
                        {/* Placeholder for image (replace with your image component) */}
                        <img ref={imageRef} src={Section16imagebg} alt="Image" style={{ display: 'none' }} />
                    </animated.div>
                </div>
            </div>
        </section>
    );
};

export default Section16;
