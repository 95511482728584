import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "./App.css";
import Navbar from "./app/components/Navbar&Footer/Navbar.js";
import Footer from "./app/components/Navbar&Footer/Footer.js";
import About from "./app/pages/About.js";
import Community from "./app/pages/Community.js";
import CreateCompetitions from "./app/pages/CreateCompetitions.js";
import LearnAndGrow from "./app/pages/hpdsxhub/LearnAndGrow.js";
import HpdsxHub from "./app/pages/hpdsxhub/Hpdsx.js";
import Articles from "./app/pages/Articles.js";
import Blogs from "./app/pages/Blogs.js";
import Chapter from "./app/pages/Chapter.js";
import CreateEvents from "./app/pages/CreateEvents.js";
import Events from "./app/pages/Events.js";
import Home from "./app/pages/home/Home.js";
import CreatePost from "./app/pages/CreatePost.js";
import StartChapter from "./app/pages/StartChapter.js";
import Counselor from "./app/pages/Counselor.js";
import CreateProgram from "./app/pages/CreateProgram.js";

import Instructor from "./app/pages/Instructor/Layout.js";
import Coach from "./app/pages/Coach/Layout.js";
import Scientist from "./app/pages/Scientist/Layout.js";
import Mentor from "./app/pages/Mentor/Layout.js";
import Guide from "./app/pages/Guide/Layout.js";
import Conference from "./app/pages/Conference/Layout.js";
import Workshop from "./app/pages/Workshop/Layout.js";
import Seminar from "./app/pages/Seminar/Layout.js";
import Competitions from "./app/pages/Competitions/Layout.js";
import Volunteer from "./app/pages/Volunteer/Layout.js";
import Summits from "./app/pages/Summits.js";
import Club from "./app/pages/Club.js";
import MissionVision from "./app/pages/Mission&Vision/Layout.js";
import ContactUs from "./app/pages/ContactUs/Layout";
import ThinkSkillIndiaInit from './app/pages/Thinkskillindia.js'


import Speaker from "./app/pages/Speaker/Layout.js";
import Platform from "./app/pages/CreatePlatform/Layout.js";
import TSITeam from "./app/pages/TSITeam.js";
import Collaboration from "./app/pages/Collaboration.js";
// import AboutHpds from "./app/pages/About Hpds/Layout.js";
import SME from "./app/pages/SME/Layout.js";
import Students from "./app/pages/Institutions/Layout.js";
import Organisations from "./app/pages/Oraganisation/Layout.js";
import Student from "./app/pages/Students/Layout.js";
import Section4 from "./app/components/About Hpds/Section4.js";
import CreateYourOwnAssessments from "./app/pages/CreateYourOwnAssessments/Layout.js";
import Publications from "./app/pages/Publication/Layout.js";
import LocalActivities from "./app/pages/LocalActivities/Layout.js";
import NonProfitOrganisation from "./app/pages/NonProfitOrganisation/Layout.js";
import Socities from "./app/pages/Socities/Layout.js";
import Communities from "./app/pages/Communities/Layout.js";
import ThinkSkillIndia from "./app/pages/ThinkSkillIndia/Layout.js";
import Collaboratory from "./app/pages/Collaboratory/Layout.js";
import AboutHpds from "./app/pages/About Hpds/Layout.js"
// import  Section4  from "./app/components/About Hpds/Section4.js"

import { HPDSxMenu } from "./app/pages/HPDSxMenu/HPDSxMenu.js";
import { ChangeAgent } from "./app/pages/ChangeAgent/ChangeAgent.js";
import { Focus } from "./app/pages/Focus/Focus.js";
import {Collab}  from "./app/pages/Collab/Collab.js";
import { HPDSxEngine } from "./app/pages/HPDSxEngine/HPDSxEngine.js";
import { MainMenu } from "./app/pages/MainMenu/MainMenu.js";

import { Counsils } from "./app/pages/Counsils/Counsils.js";
import { Support } from "./app/pages/Support/Support.js";
import { Enterprise } from "./app/pages/Enterprise/Enterprise.js";

import {Internship} from "./app/pages/Internship/Internship.js";
import { Talent } from "./app/pages/TalentCollaboratory/Talent.js";
import { Transtech } from "./app/pages/TranstechCollaboratory/Transtech.js";
import { Skill } from "./app/pages/SkillCollaboratory/Skill.js";
import { Customer } from "./app/pages/CustomerSuccess/Customer.js";
import LoginForm from "./app/pages/LoginForm.js";
import NotFound from "./app/pages/NotFound.js";
import ProtectedLayout from "./app/pages/ProtectedLayout.js";
import ProtectedRoute from "./app/pages/ProtectedRoute.js";
import Carrer from "./app/pages/Carrer/Carrer.js"

function App() {
  const [user, setUser] = useState(null);

  // const login = (username, password) => {
  //   const dummyUsername = 'admin';
  //   const dummyPassword = 'password';
  //   if (username === dummyUsername && password === dummyPassword) {
  //     setUser({username});
  //   } else {
  //     alert('Invalid username or password');
  //   }
  // };

  if (process.env.NODE_ENV === 'production') {
    console.log = console.info = console.debug = console.warn = console.error = () => {};
  }

  const handleLogout = () => {
    setUser(null);
    sessionStorage.removeItem('user'); // Clear the session storage
  };

  useEffect(() => {
    // Check sessionStorage when the app loads
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const handleLogin = async (username, password) => {
    const url = 'https://api.hpdsx.com/api/auth/signin'; // Change to your actual URL
    try {
      if(username!=="Superadmin"){
        alert("Invalid Username")
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();

      if (response.ok) {
        setUser({ username: data.username });  // Adjust based on the response structure
        console.log('Login successful:', data);
        sessionStorage.setItem('user', JSON.stringify({ username: data.username }));
      } else {
        alert(data.message)
        // throw new Error(data.message || 'Failed to login');
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <Router>
      <div className="segeoFont">
        {/* <Navbar /> */}
        <div className="pt-14 ">
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/login" element={user ? <Navigate to="/" /> : <LoginForm login={handleLogin} />} />
            {/* <Route path="/" element={<Home />} /> */}
            <Route element={<ProtectedRoute user={user}><ProtectedLayout handleLogout={handleLogout} /></ProtectedRoute>}>
              <Route path='/' element={<ThinkSkillIndia/>}></Route>
              <Route path="/about-thinkskillindia" element={<About />} />
              <Route path="/community" element={<Community />} />

              {/* trial purpose */}
              <Route path="/animesh" element={<Section4 />} />

              <Route path="/hpdsxhub/learnandgrow" element={<LearnAndGrow />} />
              <Route path="/hpdsxhub" element={<HpdsxHub />} />


              <Route path="/posts/blogs" element={<Blogs />} />
              <Route path="/posts/articles" element={<Articles />}></Route>

              <Route path="/create/events" element={<CreateEvents />} />
              <Route
                path="/create/competitions"
                element={<CreateCompetitions />}
              />
              <Route path="/create/post" element={<CreatePost />} />
              <Route path="/create/program" element={<CreateProgram />} />
              <Route path="/start-a-chapter" element={<StartChapter />} />

              <Route path="/events" element={<Events />} />
              <Route path="/summits" element={<Summits />} />
              <Route path="/chapters" element={<Chapter />} />
              <Route path="/clubs" element={<Club />} />

              <Route path="/team" element={<TSITeam />} />

              <Route path="/counselor" element={<Counselor />} />
              <Route path="/Instructor" element={<Instructor />} />
              <Route path="/Coach" element={<Coach />} />
              <Route path="/Scientist" element={<Scientist />} />
              <Route path="/Mentor" element={<Mentor />} />
              <Route path="/Guide" element={<Guide />} />

              <Route path="/Conferences" element={<Conference />} />
              <Route path="/Workshops" element={<Workshop />} />
              <Route path="/Seminars" element={<Seminar />} />
              <Route path="/Competitions" element={<Competitions />} />
              <Route path="/become-a-volunteer" element={<Volunteer />} />
              <Route path="/mission-vision" element={<MissionVision />} />
              <Route path="/contact" element={<ContactUs />} />

              <Route path="/become-a-speaker" element={<Speaker />} />
              <Route path="/create-platform" element={<Platform />} />
              <Route
                path="/inter-intra-organization-collaborations"
                element={<Collaboration />}
              />

              <Route path="/about-hpds" element={<AboutHpds />} />
              <Route path="/students" element={<Student />} />
              <Route path="/subject-matter-experts" element={<SME />} />
              <Route path="/institutions" element={<Students />} />
              <Route path="/organizations" element={<Organisations />} />
              <Route
                path="/create-assessments"
                element={<CreateYourOwnAssessments />}
              />
              <Route path="/publication" element={<Publications />} />
              <Route path="/local-activities" element={<LocalActivities />} />
              <Route
                path="/non-profit-organisation"
                element={<NonProfitOrganisation />}
              />
              <Route path="/socities" element={<Socities />} />
              <Route path="/communities" element={<Communities />} />
              <Route path="/think-skill-india" element={<ThinkSkillIndia />} />
              <Route path="/edu-collaboratory" element={<Collaboratory />} />
              <Route path="/hpdsx-menu" element= {<HPDSxMenu/>} />

              <Route path="/change-agent"element = {<ChangeAgent/> }/> 
              <Route path="/focus"element={<Focus/>} />

              <Route path="/collab"element={<Collab/>} />

              <Route path="/hpdsx-engine" element={<HPDSxEngine/>} />
              <Route path ="/main-menu" element={<MainMenu/>} />

              <Route path="/counsils" element={<Counsils/>} />

              <Route path ="/enterprise" element={<Enterprise/>} />
              <Route path ="/support" element={<Support/>} />

              <Route path ="/test" element={<Internship/>} />

              <Route path = "/thinkskillindia-initiatives" element = {<ThinkSkillIndia/>} />

              <Route path = "/talent-collaboratory" element = {<Talent/>} />
              <Route path = "/transtech-collaboratory" element = {<Transtech/>} />
              <Route path = "/skill-collaboratory" element = {<Skill/>} />

              <Route path = "/customer-success" element = {<Customer/>} />
              <Route path = "/career" element = {<Carrer/>} />
              
            </Route>
          </Routes>
        </div>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;
