import React from "react";
import img1 from "../../../assets/coverimg_community.png";
import RectPurp from "../../../assets/RectPurp.png";

const HeroSection = ({ slideInfo, contStyle }) => {
  return (
    <div
      className={`flex flex-col-reverse md:flex-row justify-start  bg-gray-300 bg-cover   md:pt-10 xl:h-[78vh]`}
    >
      <div className="w-11/12 md:w-6/12 items-start mx-auto md:mx-0 py-8 ">
        <div className="font-semibold lg:text-4xl md:text-2xl my-6 text-7719AA">
          Connect, Collaborate, Contribute
        </div>
        <div className="">
          Discover the essence of community—where individuals converge, forming
          a collective support system fostering belonging, shared values, and
          collaborative growth.
        </div>
      </div>

      <div className="w-11/12 mx-auto md:w-6/12 items-start pt-4 md:pt-16 ">
        <img src={img1} alt="blank"  className="w-full h-full  md:mb-0 "/> 
      </div>
    </div>
  );
};

export default HeroSection;
