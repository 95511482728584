import React from 'react'
import bg1 from "../../../assets/about_bg1.jpg" 
import CentralImage from '../../../assets/about_img2.png' 

const ContSlide = () => {
  const title = 'Benefits of THINKSKILLINDIA ';
  const subTit = 'We’ll help you find best choice for your need.';
  return (
    <div className="flex flex-col items-center justify-center bg-cover py-10" style={{ backgroundImage: `url(${bg1})` }}>
      <div className="items-start lg:w-1/2 md:w-9/12 text-center text-black lg:my-6 md:my-3">
        <div className="font-semibold lg:text-4xl md:text-3xl text-xl ">{title}</div>
        <div className=" text-sm md:text-base text-gray-600">{subTit}</div>
      </div>
      <div className='lg:w-4/6 md:w-5/6 '>
        <img src={CentralImage} alt="img" />
      </div>
      



    </div>
  )
}

export default ContSlide