import React from 'react';
import backgroundImage from '../../../assets/Collaboration/Section4imagebg.png'; 
import SideImage from "../../../assets/Collaboration/Section4image1.png"

const SectionWithBackgroundImage = () => {
  return (
    <section className="w-full bg-contain " style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="max-w-screen py-8 md:py-20 text-blue-500 ">
        <div className="grid grid-cols-12">
          {/* Left side */}
          <div className="col-span-12 md:col-span-6 flex flex-col justify-center px-4 md:px-6 lg:px-8 xl:px-20">
            <h2 className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-bold mb-4 "style={{ lineHeight: '140%' }}>A powerful tool can change your destiny!</h2>
            <p className="text-xl md:text-xl lg:text-2xl  text-black"style={{ lineHeight: '140%' }}>It will create and develop a research network for <span className='text-red-500'>Human Potential Development</span> Capability Platform across industry sector, education, government, business and civil society to develop research, evaluation, policy and international benchmarking facilitating flows of skills, knowledge, ideas,<span className='text-green-500'> know-how and technology among different stakeholders such as universities and research institutions as Knowledge super-infrastructure for 21st Century.</span></p>
          </div>
          {/* Right side */}
          <div className="col-span-12 md:col-span-6 py-4 md:py-10 lg:py-0 px-4 md:px-6 lg:px-8 xl:px-20">
            <img src={SideImage} alt="Image" className="w-full " />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionWithBackgroundImage;
